import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import HeaderMenu from "../../HeaderMenu/LandingPageNavBar";

import  {createPurchase, updatePurchase, GetSecretValue, createSMMADAccounts, createSMMADCampaigns}  from '../../../graphql/mutations'
import  {getPurchase}  from '../../../graphql/queries'
import { generateClient } from 'aws-amplify/api';
import { UserContext, CognitoGroupContext} from '../../../BusinessLogic/Hooks/UserContext';
import {GetGroupRoleAccess, RhyteItLogging} from '../../../BusinessLogic/UserManagement';

import {motion as m} from "framer-motion";

import {
  Button ,
  Authenticator,
  Grid,
  Fieldset,
  TextField,
  Card,
  Image,
  View,
  Flex,
  Text

} from '@aws-amplify/ui-react';
import styles from './SMMADSetup.module.css';
import SpotLight from '../../Menu/MenuBackground';
import {useNavigate, useLocation } from 'react-router-dom';
import {get_uuidv4} from '../../../BusinessLogic/uuidv4';


import axios from 'axios';


function ErrorFallback({error, resetErrorBoundary}) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}


const SMMADSetup = () => {      

        const client = generateClient();
        const queryParameters = new URLSearchParams(window.location.search)
        const session_id = queryParameters.get("session_id")
        let navigate = useNavigate(); 
        const location = useLocation();
      
 
        var ErrorMessageStr = ""   
       
  
        let [vNewAccount, setvNewAccount] = useState();           
        let [vTwitterUserName, setvTwitterUserName] = useState();   
        let [vTwitterPassword, setvTwitterPassword] = useState(); 
        
        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)  
        const [vCheckOutSession, setvCheckOutSession] = useState(queryParameters.get("session_id"))  
        const [vCheckOutSessionData, setvCheckOutSessionData] = useState()  
        const [vPurchase, setvPurchase] = useState([])  
        const [vSecretValue, setvSecretValue] = useState()  
        const [vInvoice, setvInvoice] = useState()  
        const [vInvoiceData, setvInvoiceData] = useState()  
        const [vKeyUpdate, setvKeyUpdate] = useState()   
        const [vShowNextSteps, setvShowNextSteps] = useState(false)  
               

        const {Users, setUsers} = useContext(UserContext);    
        const {CognitoGroup, setCognitoGroup} = useContext(CognitoGroupContext);       

        useEffect(() => {
      

        },[]) 

        useEffect(() => {     


          if (vCheckOutSession !== null) {

            if (vCheckOutSession.length > 0) {
              
              QuerySecretValue()

            } else {
              
                if (process.env.REACT_APP_ENV === "PRODUCTION") {
                  window.location.href = "https://www.rhyteit.com";
                } else {
                  window.location.href = "https://localhost:3000";
                }

            }         
        } else {
              
                if (process.env.REACT_APP_ENV === "PRODUCTION") {
                  window.location.href = "https://www.rhyteit.com";
                } else {
                  window.location.href = "https://localhost:3000";
                }
                
            }   

        },[vCheckOutSession])  


        useEffect(() => {     

          if (vSecretValue !== undefined) {
            GetCheckOutSession()
          }

        },[vSecretValue])          
        
   

        useEffect(() => {     

          if (vCheckOutSessionData !== undefined) {
            CreatePurchase()
          }


        },[vCheckOutSessionData])   

        useEffect(() => {     

          if (vPurchase !== undefined && vInvoice === undefined) {
              setvInvoice(vPurchase["StripeInvoiceId"])
            } 

        },[vPurchase])                   
        
        useEffect(() => {    


          //console.log("SMMADSetupuseEffect[vInvoice].vPurchase[StripeProductId]: ",vPurchase["StripeProductId"])             
          
          if (vInvoice !== undefined && vPurchase["StripeProductId"] === null) {

            GetInvoice()

          } else if (vPurchase["StripeProductId"] !== undefined) {

            if ((vPurchase["StripeProductId"]).length > 0) {

            //console.log("SMMADSetupuseEffect[vInvoice].vPurchase[StripeProductId]: ",vPurchase["StripeProductId"])           
            setvKeyUpdate(Date.now())
            }

          }

        },[vInvoice])     
        

        useEffect(() => {     

          //console.log("SMMADSetupuseEffect[vInvoiceData].vInvoiceData: ",vInvoiceData)
          if (vInvoiceData !== undefined) {
            UpdatePurchaseWithInvoice()
          }
          
        },[vInvoiceData])          

        async function CreateNewAccount() {
          try {           
              
    
              if (vTwitterPassword !== undefined && vTwitterUserName !== undefined) {
    
              var vUpdateDateTimeNew = new Date().toISOString()          
    
              let AccountItem = {
                  "id": vPurchase["StripeCustomerEmail"],
                  "Account": vPurchase["StripeCustomerEmail"],
                  "AccountType": "Customer",
                  "CampaignsActive": true,
                  "SMMADAccountStatus": "new",
                  "Active": true,
                  "Weight": 0,
                  "PostedCount": 0,
                  "PurchaseId": vPurchase["id"],
                  "ProductId": vPurchase["StripeCustomerEmail"],
                  "TwitterAccount": vTwitterUserName,
                  "TwitterPassword": vTwitterPassword,
                  "TwitterConsumerKey": "",
                  "TwitterConsumerSecret": "",
                  "TwitterBearerToken": "",
                  "TwitterAccessToken": "",
                  "TwitterAccessTokenSecret": "",
                  "TwitterClientId": "",
                  "TwitterClientSecret": "",
                  "TwitterAppName": "",
                  "TwitterAppId": "",
                  "TwitterStatus": "active",
                  "CreationName": "SMMADSetup",
                  "CreationDateTime": vUpdateDateTimeNew,
                  "UpdateName":  "SMMADSetup",
                  "UpdateDateTime": vUpdateDateTimeNew
              }
                  
                  //console.log("SMMADCampaignTable.SaveRow.CampaignItem:  ",CampaignItem);
    
                  const createSMMADAccountsResponse = await client.graphql({
                    query: createSMMADAccounts,
                    variables: {
                      input: AccountItem
                    }
                  });   

                  setvShowNextSteps(true)
                  CreateDefaultCampaign()  
          
                } else {
                  setErrorMessage("Please enter your Twitter ID and Password")
                }
                  
            } catch (err) { 
              RhyteItLogging(Users, "SMMADSetup", "CreateNewAccount",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          }        
        }  
        
        async function CreateDefaultCampaign() {
          try {           
            
              setErrorMessage("")
    
              var vUpdateDateTimeNew = new Date().toISOString()
          
              let CampaignItem = {
                  "id": "Default-" + vPurchase["StripeCustomerEmail"],
                  "Account": vPurchase["StripeCustomerEmail"],
                  "AccountType": "Customer" ,
                  "Campaign": "Default",
                  "Description":"Default",
                  "SMMADCampaignStatus": "new",
                  "ContentTextActive": true,
                  "Active": true,
                  "Weight": 0,
                  "PostedCount": 0,
                  "CreationName": vNewAccount,
                  "CreationDateTime": vUpdateDateTimeNew,
                  "UpdateName": vNewAccount,
                  "UpdateDateTime": vUpdateDateTimeNew,
              }
        
                  
                  const updateSMMADCampaignResponse = await client.graphql({
                    query: createSMMADCampaigns,
                    variables: {
                      input: CampaignItem
                    }
                  });  

                  
                 // GoToLogin()
    
            } catch (err) { 
              RhyteItLogging(Users, "SMMADSetup", "CreateDefaultCampaign",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          }        
        }          

        async function QuerySecretValue() {
          try {              
              
            let vEnvironment = ""
            var vUpdateDateTimeNew = new Date().toISOString()
  
          
            //console.log("SMMADSetupQuerySecretValue.vCheckOutSession: ",vCheckOutSession)
  
            if (vCheckOutSession.includes("test")) {
  
              vEnvironment = "TEST"
  
            } else {
              vEnvironment = "PRODUCTION"
  
            }

            const client = generateClient();
        
              let SecretValueJSON = await client.graphql({
                query: GetSecretValue,
                variables: {
                  Environment: vEnvironment, SecretKey: "STRIPE_SECRET_KEY_NAME"
                }
              });        
              
            var SecretJSON = JSON.parse(SecretValueJSON.data.GetSecretValue);

            setvSecretValue(SecretJSON["SecretValue"])
        
        
            } catch (err) { 
             // console.error("getStripe.GetSecretValue.Error:  " , err); 
            //  console.trace(err);
              return "ERROR"
          }        
        }             

        async function GetCheckOutSession() {

      
        
              let vGetURL = "https://api.stripe.com/v1/checkout/sessions/" + vCheckOutSession
        
              const res = await axios.get(vGetURL, {
                  headers: {
                      'Authorization': `Bearer ${vSecretValue}`,
                      'Content-Type': 'application/x-www-form-urlencoded'
                  }
              })
              .then(response => { 
                 // //console.log(response.data); // Axios uses `data` property, not `json()`
                  setvCheckOutSessionData(response.data)  
              })
              .catch(error => {
                RhyteItLogging(Users, "SMMADSetup", "GetCheckOutSession",  error.toString(),  "{\"ERROR\": \"" + error.toString() + "\"}", 0)

                                  
                  if (process.env.REACT_APP_ENV === "PRODUCTION") {
                    window.location.href = "https://www.rhyteit.com";
                  } else {
                    window.location.href = "https://localhost:3000";
                  }                  

              });        
         
        }          

        async function CreatePurchase() {

          let vEnvironment = ""
          var vUpdateDateTimeNew = new Date().toISOString()

        
          //console.log("SMMADSetupGetCheckOutSession.vCheckOutSessionData: ",vCheckOutSessionData)

          if (vCheckOutSession.includes("test")) {

            vEnvironment = "TEST"

          } else {
            vEnvironment = "PRODUCTION"

          }
          
          
          const getPurchaseResponse = await client.graphql({
            query: getPurchase,
            variables: {
              "id":vCheckOutSessionData["subscription"]
            }
          });  
          
          //console.log("SMMADSetupGetCheckOutSession.getPurchaseResponse: ",getPurchaseResponse["data"]["getPurchase"])
          if (getPurchaseResponse["data"]["getPurchase"]) {

            
          //console.log("SMMADSetupGetCheckOutSession.INSIDE.getPurchaseResponse[data][getPurchase]")


            let vPurchaseItem = {
              "id":getPurchaseResponse["data"]["getPurchase"]["id"],
              "StripePaymentIntent": getPurchaseResponse["data"]["getPurchase"]["StripePaymentIntent"],
              "StripePaymentMethod": getPurchaseResponse["data"]["getPurchase"]["StripePaymentMethod"],
              "StripeCustomerId":getPurchaseResponse["data"]["getPurchase"]["StripeCustomerId"],
              "StripeCustomerEmail":getPurchaseResponse["data"]["getPurchase"]["StripeCustomerEmail"],
              "StripeCustomerName":getPurchaseResponse["data"]["getPurchase"]["StripeCustomerName"],
              "StripeCheckOutSessionId":getPurchaseResponse["data"]["getPurchase"]["StripeCheckOutSessionId"],
              "StripeInvoiceId":getPurchaseResponse["data"]["getPurchase"]["StripeInvoiceId"],
              "StripeSubscriptionId":getPurchaseResponse["data"]["getPurchase"]["StripeSubscriptionId"],
              "StripeSubscriptionItemId": getPurchaseResponse["data"]["getPurchase"]["StripeSubscriptionItemId"],
              "StripeProductId": getPurchaseResponse["data"]["getPurchase"]["StripeProductId"],
              "StripePriceId": getPurchaseResponse["data"]["getPurchase"]["StripePriceId"],
              "StripeStatus": getPurchaseResponse["data"]["getPurchase"]["StripeStatus"],
              "RequestId": getPurchaseResponse["data"]["getPurchase"]["RequestId"],
              "idempotency_key": getPurchaseResponse["data"]["getPurchase"]["idempotency_key"],
              "ReceiptURL": getPurchaseResponse["data"]["getPurchase"]["ReceiptURL"],
              "Last4": getPurchaseResponse["data"]["getPurchase"]["Last4"],
              "PostalCode": getPurchaseResponse["data"]["getPurchase"]["PostalCode"],
              "Product": getPurchaseResponse["data"]["getPurchase"]["Product"],
              "Environment": getPurchaseResponse["data"]["getPurchase"]["Environment"],
              "EmailVerified": getPurchaseResponse["data"]["getPurchase"]["EmailVerified"],
              "PurchaseStatus": getPurchaseResponse["data"]["getPurchase"]["PurchaseStatus"],
              "FileURL": getPurchaseResponse["data"]["getPurchase"]["FileURL"],
              "CreationName":getPurchaseResponse["data"]["getPurchase"]["CreationName"],
              "UpdateName":getPurchaseResponse["data"]["getPurchase"]["UpdateName"],
              "CreationDateTime":getPurchaseResponse["data"]["getPurchase"]["CreationDateTime"],
              "UpdateDateTime":getPurchaseResponse["data"]["getPurchase"]["UpdateDateTime"]
            }             
            
            setvPurchase(vPurchaseItem)

          } else {



            let vPurchaseItem = {
              "id":vCheckOutSessionData["subscription"],
              "StripePaymentIntent": null,
              "StripePaymentMethod": null,
              "StripeCustomerId":vCheckOutSessionData["customer"],
              "StripeCustomerEmail":vCheckOutSessionData["customer_details"]["email"],
              "StripeCustomerName":vCheckOutSessionData["customer_details"]["name"],
              "StripeCheckOutSessionId":vCheckOutSessionData["id"],
              "StripeInvoiceId":vCheckOutSessionData["invoice"],
              "StripeSubscriptionId":vCheckOutSessionData["subscription"],
              "StripeSubscriptionItemId": null,
              "StripeProductId": null,
              "StripePriceId": null,
              "StripeStatus": null,
              "RequestId": null,
              "idempotency_key": null,
              "ReceiptURL": null,
              "Last4": null,
              "PostalCode": null,
              "Product": null,
              "Environment": vEnvironment,
              "EmailVerified": null,
              "PurchaseStatus": null,
              "FileURL": null,
              "CreationName":vCheckOutSessionData["customer_details"]["email"],
              "UpdateName":vCheckOutSessionData["customer_details"]["email"],
              "CreationDateTime":vUpdateDateTimeNew,
              "UpdateDateTime":vUpdateDateTimeNew
            } 

            
        
          //console.log("SMMADSetupGetCheckOutSession.vPurchaseItem: ",vPurchaseItem)

            const createPurchaseResponse = await client.graphql({
              query: createPurchase,
              variables: {
                input: vPurchaseItem
              }
            });  

              setvPurchase(vPurchaseItem)
            }
         
        }             

        async function GetInvoice() {

          let vEnvironment = ""

        
          //console.log("SMMADSetupGetInvoice.vCheckOutSession: ",vCheckOutSession)

          if (vCheckOutSession.includes("test")) {

            vEnvironment = "TEST"

          } else {
            vEnvironment = "PRODUCTION"

          }
          
        
              let vGetURL = "https://api.stripe.com/v1/invoices/" + vInvoice
        
              const res = await axios.get(vGetURL, {
                  headers: {
                      'Authorization': `Bearer ${vSecretValue}`,
                      'Content-Type': 'application/x-www-form-urlencoded'
                  }
              })
              .then(response => { 
                 // //console.log(response.data); // Axios uses `data` property, not `json()`
                  setvInvoiceData(response.data)
              })
              .catch(error => {
                RhyteItLogging(Users, "SMMADSetup", "CreateDefaultCampaign",  error.toString(),  "{\"ERROR\": \"" + error.toString() + "\"}", 0)
              });        
         
        }  

        async function UpdatePurchaseWithInvoice() {

          let vEnvironment = ""
          var vUpdateDateTimeNew = new Date().toISOString()

        
          //console.log("SMMADSetupUpdatePurchaseWithInvoice.vInvoiceData: ",vInvoiceData)


            let vPurchaseItem = {
              "id":vPurchase["id"],
              "StripePaymentIntent":  vInvoiceData["payment_intent"],
              "StripePaymentMethod": null,
              "StripeCustomerId":vPurchase["customer"],
              "StripeCustomerEmail":vPurchase["StripeCustomerEmail"],
              "StripeCustomerName":vPurchase["StripeCustomerName"],
              "StripeCheckOutSessionId":vPurchase["StripeCheckOutSessionId"],
              "StripeInvoiceId":vPurchase["StripeInvoiceId"],
              "StripeSubscriptionId":vPurchase["id"],
              "StripeSubscriptionItemId": vInvoiceData["lines"]["data"][0]["subscription_item"],
              "StripeProductId": vInvoiceData["lines"]["data"][0]["plan"]["product"],
              "StripePriceId": vInvoiceData["lines"]["data"][0]["plan"]["id"],
              "StripeStatus": vInvoiceData["status"],
              "RequestId": null,
              "idempotency_key": null,
              "ReceiptURL": vInvoiceData["hosted_invoice_url"],
              "Last4": null,
              "PostalCode": null,
              "Product": vInvoiceData["lines"]["data"][0]["description"],
              "Environment": vPurchase["Environment"],
              "EmailVerified": null,
              "PurchaseStatus": vInvoiceData["status"],
              "FileURL": null,
              "CreationName":vPurchase["CreationName"],
              "UpdateName":vPurchase["UpdateName"],
              "CreationDateTime":vPurchase["CreationDateTime"],
              "UpdateDateTime":vUpdateDateTimeNew
            } 

            
        
          //console.log("SMMADSetupUpdatePurchaseWithInvoice.vPurchaseItem: ",vPurchaseItem)

          const updatePurchaseResponse = await client.graphql({
            query: updatePurchase,
            variables: {
              input: vPurchaseItem
            }
          });  

        
          //console.log("SMMADSetupUpdatePurchaseWithInvoice.updatePurchaseResponse: ",updatePurchaseResponse["data"]["updatePurchase"])
          setvPurchase(vPurchaseItem)
          setvKeyUpdate(Date.now())
    
         
        }                   

        function OpenInvoice(event) {
          try {
    
              setErrorMessage("") 
    
              if (vPurchase["ReceiptURL"].length > 0) {
                window.open(vPurchase["ReceiptURL"], '_blank');
              } else {                
                setErrorMessage("")  
              }
      
              
            } catch (err) { 
    
              RhyteItLogging(Users, "SMMADSetup", "OpenInvoice",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          }        
        }  
   

        function vTwitterUserName_handleChange(event,data) {
          try {
    
                    
    
            setvTwitterUserName(event.target.value)
             
    
          } catch (err) { 
            RhyteItLogging(Users, "SMMADSetup", "vTwitterUserName_handleChange",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                      }
        }         
        
        function vTwitterPassword_handleChange(event,data) {
          try {
    
                    
    
            setvTwitterPassword(event.target.value)
             
    
          } catch (err) { 
            RhyteItLogging(Users, "SMMADSetup", "vTwitterPassword_handleChange",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                      }
        }           
        
        async function GoToLogin() {
  
          try {         
        
        
  
                  navigate('/AuthenticatorRhyteIt', 
                  {
                    state: {
                        UserId:vPurchase["StripeCustomerEmail"], 
                        UserName:vPurchase["StripeCustomerEmail"],
                        CognitoGroup: "RhyteItSMMADUserGroup",
                        authStatus: "unauthenticated"
                    }
                  })
  
            } catch (err) {//console.log('error TeamsUserId_handleChange')
            //console.error(err, err.stack); 
          }        
      }             

    return ( 
    <m.div
      key="RhyteItSMMADSetup"
      initial={{x: "100%"}}
      animate={{x: "0%"}}
      transition={{duration:0.75, ease: "easeOut"}}
      exit={{ opacity: 1 }}
     >                      
     <SpotLight className={styles.SpotLight} GradientStopColor1="#0000" GradientStopColor2="#000"  GradientStopColor3="#00b1b1"   GradientStopColor4="#8880"/>      

     {vKeyUpdate !== undefined && (
     <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="center"
        wrap="nowrap"
        gap="1rem"
        className={styles.GridContainer}
      >     
          <HeaderMenu  className={styles.RhyteItHeaderMenu}  pageSource="SMMADSetup"/>    
          <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                alignContent="flex-start"
                wrap="nowrap"
                gap="1rem"
                className={styles.H1Row} 
            >  
                
              <h1 className={styles.h1}>Thank you for your purchase!</h1>                                                                                                                                                                                                                                                 
          </Flex>                 
          <div className={styles.headerline} />
          <h2 className={styles.h2}>Ok {vPurchase["StripeCustomerName"]}, let's go get you some fans!</h2>  
          <div className={styles.MessageBox}>{ErrorMessage}</div>   
          { vShowNextSteps=== false && (
          <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            alignContent="center"
            wrap="nowrap"
            gap="1rem"
            className={styles.FlexColumn}
          >    
            <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                alignContent="flex-start"
                wrap="nowrap"
                gap="1rem"
                className={styles.FlexRow} 
            >  
                
                <Text  className={styles.TextLabel}>
                                  Twitter User Name
                </Text>                                         
                <TextField 
                    className={styles.TextField} 
                    name="TwitterUserName"     
                    placeholder="Add X/Twitter User Name"                   
                    defaultValue={vTwitterUserName} 
                    onChange={ (e) => vTwitterUserName_handleChange(e, vTwitterUserName) }/>                                                                                                                                                                                                                                                        
            </Flex>    
            <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                alignContent="flex-start"
                wrap="nowrap"
                gap="1rem"
                className={styles.FlexRow} 
            >  
                
                <Text  className={styles.TextLabel}>
                                  Twitter Password
                </Text>                                         
                <TextField 
                    className={styles.TextField} 
                    name="TwitterPassword"     
                    placeholder="Add X/Twitter Password"                    
                    defaultValue={vTwitterPassword}
                    onChange={ (e) => vTwitterPassword_handleChange(e, vTwitterPassword) } />                                             
                                                                                                                                                                                                                                                                      
            </Flex>    
            <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                alignContent="flex-start"
                wrap="nowrap"
                gap="1rem"
                className={styles.FlexRow} 
            >  
            <Button
                loadingText=""
                ariaLabel=""
                className={styles.Button}
                onClick={(e) => CreateNewAccount(e)}
              >
              Save
              </Button>     
              <Button
                  loadingText=""
                  ariaLabel=""
                  className={styles.Button}
                  onClick={(e) => OpenInvoice(e)}
                >
                View Invoice
                </Button>                                                                
                                                                                                                                                                                                                                                                      
            </Flex>                                        
          </Flex>         
            )}    
        { vShowNextSteps=== true && (
            <Flex
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              alignContent="center"
              wrap="nowrap"
              gap="1rem"
            >        
                <Text  className={styles.CongratulationsText}>
                  Excellent! Time to login and start uploading content!
                </Text> 
                <Text  className={styles.NextStepText}>
                  Next steps: Click on the log in button below
                </Text>   
                <Text  className={styles.NextStepText}>
                  Your user name is: <Text  className={styles.DataText}>{vPurchase["StripeCustomerEmail"]}</Text>
                </Text>     
                <Text  className={styles.NextStepText}>
                  Your user password is:  <Text  className={styles.DataText}>{vTwitterPassword}</Text>
                </Text>    
                <Text  className={styles.NextStepText}>
                  * We sent you an email with your login information as well.
                </Text>    
                <Button
                    loadingText=""
                    ariaLabel=""
                    className={styles.Button}
                    onClick={(e) => GoToLogin()}
                  >
                  Log in
                </Button>                                                                                                                                                                                                                                             
          </Flex>        
            )}                
      </Flex> 
      
        
     )}
    </m.div>                      

    )

  }
 

export default SMMADSetup;