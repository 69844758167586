import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';

import  { listEmailAccountStats}  from '../../graphql/queries'
import { generateClient } from 'aws-amplify/api';

import {ErrorBoundary} from 'react-error-boundary'
import Header from "../RhyteItComponents/Header/Header";
import {motion as m} from "framer-motion";
import TwitterStatsTable from "./TwitterStatsTable";
import { UserContext, CognitoGroupContext} from '../../BusinessLogic/Hooks/UserContext';
import {GetGroupRoleAccess, RhyteItLogging, ReturnUserToLandingPage} from '../../BusinessLogic/UserManagement';
import { fetchAuthSession } from 'aws-amplify/auth';


import {
  Button ,
  Authenticator,
  Autocomplete,
  Flex

} from '@aws-amplify/ui-react';
import styles from './TwitterStats.module.css';

function ErrorFallback({error, resetErrorBoundary}) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}


const TwitterStats = (props) => {       
  
  TwitterStats.propTypes = {
          RedditUser: PropTypes.object
        };

        const vBreadCrumbItems = [{Path: "/", Title:"Home", isCurrent:false},{Path: "/MainMenuV2", Title:"Menu", isCurrent:false},{Path: "/TwitterStats", Title:"Twitter Statistics", isCurrent:true}]
        const client = generateClient();
        const [TwitterStatsRecords, setTwitterStatsRecords] = useState([{
          id:  '',  
          UserId:   '',    
          AccountId:   '',    
          StatDate:   '',    
          TwitterFollowing:   '',    
          TwitterFollowers:   '',    
          TwitterTweets:   '',    
          CreationName:   '',  
          UpdateName:   '',  
          CreationDateTime:   '',  
          UpdateDateTime:   ''
         }])

         const SORT = {
          ASC: 'ASC',
          DESC: 'DESC',
        }

         const [TwitterStatsRecordsData, setTwitterStatsRecordsData] = useState([])

        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   
        let debugConsoleOutPut = true

        const {Users, setUsers} = useContext(UserContext);    
        const {CognitoGroup, setCognitoGroup} = useContext(CognitoGroupContext);       
        const [TwitterStatDate, setTwitterStatDate] = useState([]);     
        const [TwitterAccountIds, setTwitterAccountIds] = useState([]);
        
        const [SearchTerm1, setSearchTerm1] = useState('');
        const [CauseUpdate, setCauseUpdate] = useState('');
        const [ShowTable, setShowTable] = useState(false);
        const [SearchStatDate, setSearchStatDate] = useState('');
        const [nextToken, setnextToken] = useState(undefined)
        const [IsLoading, setIsLoading] = useState(false)

        useEffect(() => {

         

        },[])     
        

           
           useEffect(() => {
             
            (async function() {
              try {  
  
                      if (CognitoGroup !== undefined) {
  
                        let   GetGroupRoleAccessResponse = await GetGroupRoleAccess(CognitoGroup, "rhyteitAdmins")
                        
                        if (GetGroupRoleAccessResponse) {  
                          fetch()  
                        }
                            
  
                      } else {
  
                        const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
  
                        if (idToken !== undefined) {
                          setUsers(idToken.payload.email)
                          setCognitoGroup(accessToken["payload"]["cognito:groups"])
                        } else {
                          
                          ReturnUserToLandingPage(Users, CognitoGroup)
                        }
  
                      }
                    } catch (err) {                  
                      RhyteItLogging(Users, "TwitterStats", "useEffect[CognitoGroup]",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                  }
                }
              )();   
      
             },[CognitoGroup])             
                    
        
        useEffect(() => {


          //console.log("TwitterStats.useEffect[NextToken].Start");   


          const fetch1 = async () => {

          if (nextToken === undefined) {

            //console.log("TwitterStats.useEffect[NextToken].start.nextToken: ", nextToken);  
            
          } else if (nextToken === null) {

            //console.log("TwitterStats.useEffect[NextToken].start.nextToken: ", nextToken);  
            
          } else {
          
            //console.log("TwitterStats.useEffect[NextToken].start.nextToken: ", nextToken.substring(1, 10));  
          
          }

            try {

                  if(nextToken !== null) {
                      setIsLoading(true)

                      var datetime = new Date();
                      const startDate = new Date(datetime.getFullYear(),
                                                  datetime.getMonth(),
                                                  datetime.getDate() - 30).toISOString().slice(0,10);
                                                
                      let limit = 10000000
                      //console.log("TwitterStats.useEffect[NextToken].nextToken: ", nextToken);   
                      
                      const variables = {
                        nextToken,
                        filter: {
                              StatDate: {
                                  gt: startDate
                              }
                        }            
                      }        
                      
                      const TwitterStatsRecordsData2  = await client.graphql({
                        query: listEmailAccountStats,
                        variables: variables
                      });     

                      //console.log("TwitterStats.useEffect[NextToken].elseif.TwitterStatsRecordsData2.length: ", TwitterStatsRecordsData2.data.listEmailAccountStats.items.length);  

                      if (TwitterStatsRecordsData2.data.listEmailAccountStats.nextToken === null) {
                        //console.log("TwitterStats.useEffect[NextToken].elseif.TwitterStatsRecordsData2.nextToken.null: ", TwitterStatsRecordsData2.data.listEmailAccountStats.nextToken);  
                      } else {
                        
                        //console.log("TwitterStats.useEffect[NextToken].elseif.TwitterStatsRecordsData2.nextToken: ", TwitterStatsRecordsData2.data.listEmailAccountStats.nextToken.substring(1, 10));  
                      }

                      let TwitterStatsRecords1 = TwitterStatsRecordsData                   
                          
          
                        TwitterStatsRecordsData2.data.listEmailAccountStats.items.forEach(item => 
                          {   
                        
                            if ([...TwitterStatsRecords1].filter(TwitterStat => item.id === TwitterStat.id)["length"] === 0) {  
                              TwitterStatsRecords1.push(item) 
                            }
      
                          })


                        //console.log("TwitterStats.useEffect[NextToken].elseif.TwitterStatsRecordsData2.data.listEmailAccountStats.nextToken: ", TwitterStatsRecordsData2.data.listEmailAccountStats.nextToken);   
                        //console.log("TwitterStats.useEffect[NextToken].elseif.TwitterStatsRecordsData2.length: ", TwitterStatsRecordsData2.data.listEmailAccountStats.items.length);  
                        //console.log("TwitterStats.useEffect[NextToken].elseif.TwitterStatsRecordsData2.items: ", TwitterStatsRecordsData2.data.listEmailAccountStats.items);  

                        setTwitterStatsRecordsData(TwitterStatsRecords1)  
                        setnextToken(TwitterStatsRecordsData2.data.listEmailAccountStats.nextToken)  

                                            

                      
                  } else if (nextToken === null){      


                    //console.log("TwitterStats.useEffect[NextToken].elseif.nextToken.null: ", nextToken);                                     
                    
                    let TwitterStatsRecords2 = await SortTwitterStatsByDate(TwitterStatsRecordsData)        
                                        
                    //console.log("TwitterStats.useEffect[NextToken].elseif.TwitterStatsRecords2: ", TwitterStatsRecords2.length);               
                    
                    let TwitterStatsFiltered = await [...TwitterStatsRecords2].filter(TwitterStat => TwitterStat.AccountId ===  "All") 
          
                    setTwitterStatsRecords(TwitterStatsFiltered)                    
                    

                    let PopulateTwitterAccountIdListResponse = await PopulateTwitterAccountIdList(TwitterStatsRecords2)
                    let PopulateTwitterStatDatesListResponse = await PopulateTwitterStatDatesList(TwitterStatsFiltered)
                    //console.log("TwitterStats.useEffect[NextToken].elseif.PopulateTwitterAccountIdListResponse: ", PopulateTwitterAccountIdListResponse.length);   )
    
                  }
  
            } catch (err) {         
              RhyteItLogging(Users, "TwitterStats", "useEffect[nextToken]",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
            } finally {
              setIsLoading(false)
            }
          }
        
          fetch1()

        }, [nextToken])        

        useEffect(() => {

          if (Object.keys(TwitterStatsRecords).length > 1) {
            setShowTable(true)
            let vErrorMessage = TwitterStatsRecords.length + " accounts retrieved at: " + new Date().toLocaleString() 
            setErrorMessage(vErrorMessage)            
          } else {

            setErrorMessage("0 accounts retrieved at: " + new Date().toLocaleString())

          }

        },[TwitterStatsRecords])     
        


        function SortTwitterStatsByDate(pTwitterStatsRecords) {
            let vTwitterStatsRecords = pTwitterStatsRecords.sort(NameSortFunction);
            vTwitterStatsRecords.sort(GFG_sortFunction);
            return vTwitterStatsRecords
        //    //console.log(JSON.stringify(pTwitterStatsRecords));
        }

        function SortTwitterStatsByName(pTwitterStatsRecords) {
          let vTwitterStatsRecords = pTwitterStatsRecords.sort(NameSortFunction);

          return vTwitterStatsRecords
      //    //console.log(JSON.stringify(pTwitterStatsRecords));
      }        
        
        function GFG_sortFunction(a, b) {
            let dateA = new Date(a.StatDate).getTime();
            let dateB = new Date(b.StatDate).getTime();
            return dateA < dateB ? 1 : -1;
        };      
          
        function NameSortFunction(a, b) {
          let AccountA = a.AccountId
          let AccountB = b.AccountId
          return AccountA > AccountB ? 1 : -1;
        }; 
        
        const fetch = async () => {

          var datetime = new Date();
          const startDate = new Date(datetime.getFullYear(),
                                      datetime.getMonth(),
                                      datetime.getDate() - 30).toISOString().slice(0,10);


          
          //console.log("TwitterStatsRecordsData1.fetch.Users[0].UserId: ", Users);                                      

          const variables = {
            limit: 1000000,
            filter: {
                  StatDate: {
                      gt: startDate
                  }
            }            
          }

          //console.log("TwitterStats.fetch.variables: ", variables);  
          
          const TwitterStatsRecordsData1  = await client.graphql({
            query: listEmailAccountStats,
            variables: variables
          });
          
          let TwitterStatsRecords = TwitterStatsRecordsData1.data.listEmailAccountStats.items 
     
          setTwitterStatsRecordsData(TwitterStatsRecords)  
          
          setnextToken(TwitterStatsRecordsData1.data.listEmailAccountStats.nextToken)  

          return TwitterStatsRecords          

        }

        async function PopulateTwitterStatDatesList(TwitterStatsRecords) {
          try {

              setErrorMessage("") 

              var TwitterStatsRecordsJSON = {TwitterStatsRecords: [{id:"none", label: "none"}]}


              if (TwitterStatsRecords.length > 0 ) {

                TwitterStatsRecords.forEach(TwitterStatRecord => 
                  {

                    let vItemAlreadyExists = false

                   // //console.log("TwitterStats.PopulateTwitterStatDatesList.TwitterStatsRecordsJSON:  ", TwitterStatsRecordsJSON)

                    TwitterStatsRecordsJSON.TwitterStatsRecords.forEach(pt => 
                        {                                               
                          if (pt.label === new Date(TwitterStatRecord.StatDate).toISOString().slice(0,10)) {                      
                            vItemAlreadyExists = true;
                          }
                        } 
                      )     
                      
                      

                    if(vItemAlreadyExists === false) {

                        let NewTwitterStatDate = {
                          id: new Date(TwitterStatRecord.StatDate).toISOString().slice(0,10),
                          label:  new Date(TwitterStatRecord.StatDate).toISOString().slice(0,10)
                        }   

                        TwitterStatsRecordsJSON.TwitterStatsRecords.push(NewTwitterStatDate);

                      }
                })

                //console.log("------> SubRedditPostsManager.PopulateQueryTermSelectFieldJSON.QueryTermsJSON: ",QueryTermsJSON)


                //PostTitlesJSON.NewsSources = [...PostTitlesJSON.NewsSources].sort((a, b) => (a.NewsSource < b.NewsSource ? -1 : 1));    
                
                
                //console.log("TwitterStats.PopulateTwitterStatDatesList.TwitterStatsRecordsJSON.TwitterStatDate:  ",TwitterStatsRecordsJSON.TwitterStatsRecords)

                setTwitterStatDate(TwitterStatsRecordsJSON.TwitterStatsRecords)

              }


              return TwitterStatsRecordsJSON

            } catch (err) { 

              RhyteItLogging(Users, "TwitterStats", "PopulateTwitterStatDatesList",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)

            console.error(ErrorMessageStr); 
            
            setErrorMessage(ErrorMessageStr)         
          }        
        } 

        async function PopulateTwitterAccountIdList(TwitterStatsRecords) {
          try {

              setErrorMessage("") 

              var TwitterStatsRecordsJSON = {TwitterStatsRecords: [{id:"none", label: "none"}]}


              if (TwitterStatsRecords.length > 0 ) {

                TwitterStatsRecords.forEach(TwitterStatRecord => 
                  {

                    let vItemAlreadyExists = false

                   // //console.log("TwitterStats.PopulateTwitterStatDatesList.TwitterStatsRecordsJSON:  ", TwitterStatsRecordsJSON)

                    TwitterStatsRecordsJSON.TwitterStatsRecords.forEach(pt => 
                        {                                               
                          if (pt.label === TwitterStatRecord.AccountId) {                      
                            vItemAlreadyExists = true;
                          }
                        } 
                      )     
                      
                      

                    if(vItemAlreadyExists === false) {

                        let NewTwitterStatDate = {
                          id: TwitterStatRecord.AccountId,
                          label: TwitterStatRecord.AccountId
                        }   

                        TwitterStatsRecordsJSON.TwitterStatsRecords.push(NewTwitterStatDate);

                      }
                })

                let vAccountListSorted = SortTwitterStatsByName(TwitterStatsRecordsJSON.TwitterStatsRecords)

                setTwitterAccountIds(vAccountListSorted)

              }

              return TwitterStatsRecordsJSON

            } catch (err) { 


              RhyteItLogging(Users, "TwitterStats", "PopulateTwitterAccountIdList",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          }        
        }         
        
        function onChangeAutocomplete(event) {
          setSearchTerm1(event.target.value);
        }

        function onChangeStatDateAutocomplete(event) {
          setSearchStatDate(event.target.value);
        }

        async function onSelectAutocomplete(option) {

          
          onClearStatDateAutocomplete()

          const { label } = option;

          setSearchTerm1(label);          


          try {

            setErrorMessage("") 

            let TwitterStatsFiltered = ""

            TwitterStatsFiltered = await [...TwitterStatsRecordsData].filter(TwitterStat => TwitterStat.AccountId ===  label)            
            
            //console.log("TwitterStats.onSelectAutocomplete.TwitterStatsFiltered:  ",TwitterStatsFiltered)

            setTwitterStatsRecords(TwitterStatsFiltered)


          } catch (err) { 

          ErrorMessageStr = "TwitterStats.onSelectAutocomplete Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }            
        }          

        async function onSelectStatDateAutocomplete(option) {

          
          onClearAutocomplete()

          const { label } = option;

          setSearchStatDate(label);          


          try {

            setErrorMessage("") 

            let TwitterStatsFiltered = ""


            TwitterStatsFiltered = await [...TwitterStatsRecordsData].filter(TwitterStat => new Date(TwitterStat.StatDate).toISOString().slice(0,10) ===  label)            
            
            //console.log("TwitterStats.onSelectAutocomplete.TwitterStatsFiltered:  ",TwitterStatsFiltered)

            setTwitterStatsRecords(TwitterStatsFiltered)


          } catch (err) { 

          ErrorMessageStr = "TwitterStats.onSelectStatDateAutocomplete Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }            
        }    

        function onClearAutocomplete() {
          setSearchTerm1("");
        }                
        
        function onClearStatDateAutocomplete() {
          setSearchStatDate("");
        }            

        function SortByName() {
          // console.log("TwitterStatsTable.SortByName");
           let vTwitterStatsRecords = TwitterStatsRecords.sort(NameSortFunction);
   
           setTwitterStatsRecords(vTwitterStatsRecords) 
       }         
   
   
       function SortByActual() {
         console.log("TwitterStats.SortByActual");
         let vTwitterStatsRecords = TwitterStatsRecords.sort(ActualSortFunction);
         console.log("TwitterStats.SortByActual.vTwitterStatsRecords", vTwitterStatsRecords);
   
         setTwitterStatsRecords(vTwitterStatsRecords) 
         setCauseUpdate(Date.now()) 
       }         
   
       function ActualSortFunction(a, b) {
        // console.log("TwitterStats.ActualSortFunction", a.TwitterFollowers, " > " , b.TwitterFollowers);
         let AccountA = a.TwitterFollowers
         let AccountB = b.TwitterFollowers
         return AccountA < AccountB ? 1 : -1;
       }; 
        
       function SortByTwitterFollowersIncr() {
        // console.log("TwitterStatsTable.SortByTwitterFollowersIncr");
         let vTwitterStatsRecords = TwitterStatsRecords.sort(TwitterFollowersIncrSortFunction);
   
         setTwitterStatsRecords(vTwitterStatsRecords) 
         setCauseUpdate(Date.now()) 
       }         
   
       function TwitterFollowersIncrSortFunction(a, b) {
        // console.log("TwitterStatsTable.TwitterFollowersIncrSortFunction:  ", a.TwitterFollowersIncr, " > " , b.TwitterFollowersIncr);
         let AccountA = a.TwitterFollowersIncr
         let AccountB = b.TwitterFollowersIncr
         return AccountA < AccountB ? 1 : -1;
       }; 
            
       function SortByTwitterFollowersPer() {
        // console.log("TwitterStatsTable.SortByTwitterFollowersPer");
         let vTwitterStatsRecords = TwitterStatsRecords.sort(TwitterFollowersTwitterFollowersPer);
   
         setTwitterStatsRecords(vTwitterStatsRecords) 
         setCauseUpdate(Date.now()) 
       }         
   
       function TwitterFollowersTwitterFollowersPer(a, b) {
        // console.log("TwitterStatsTable.TwitterFollowersTwitterFollowersPer:  ", a.TwitterFollowersPer, " > " , b.TwitterFollowersPer);
         let AccountA = a.TwitterFollowersPer
         let AccountB = b.TwitterFollowersPer
         return AccountA < AccountB ? 1 : -1;
       }; 

    return ( 
          <Authenticator  className={styles.AuthenticatorLogin}  loginMechanisms={['email']} socialProviders={[]} hideSignUp={true}>
              {({ signOut, user }) => (  
                    <div className={styles.SystemAdminBackground}>
                      <ErrorBoundary
                            FallbackComponent={ErrorFallback}
                          >   
                        <Flex
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          alignContent="flex-start"
                          wrap="nowrap"
                          gap="3rem"
                          className={styles.GridContainer}
                        >                             
                                <Header Title="Twitter Statistics" SubTitle="Manage Twitter Accounts" BreadCrumbItems ={vBreadCrumbItems} pageSource="TwitterStats"/>    
                                <Flex
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="stretch"
                                alignContent="flex-start"
                                wrap="nowrap"
                                gap="1rem"
                                className={styles.ToolBar}
                              >           
                                    <Flex
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            alignContent="center"
                                            wrap="nowrap"
                                            gap="2rem"
                                            className={styles.FlexRow} 
                                          >   
                                              <Autocomplete                                  
                                                label="Autocomplete"
                                                options={TwitterStatDate}
                                                placeholder="Select Date"
                                                value={SearchStatDate}
                                                onChange={onChangeStatDateAutocomplete}
                                                onClear={onClearStatDateAutocomplete}
                                                onSelect={onSelectStatDateAutocomplete}
                                                className={styles.amplifyautocomplete}    
                                                />
                                       </Flex>     
                                        <Flex
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            alignContent="center"
                                            wrap="nowrap"
                                            gap="2rem"
                                            className={styles.FlexRow} 
                                          >                                                   
                                                <Autocomplete                                  
                                                    label="Autocomplete"
                                                    options={TwitterAccountIds}
                                                    placeholder="Select Account"
                                                    value={SearchTerm1}
                                                    onChange={onChangeAutocomplete}
                                                    onClear={onClearAutocomplete}
                                                    onSelect={onSelectAutocomplete}
                                                    className={styles.amplifyautocomplete}    
                                                />                                                                                                                                                                                                                                                                                                                                                                                                                 
                                        </Flex>     
                                        <Flex
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            alignContent="center"
                                            wrap="nowrap"
                                            gap="2rem"
                                            className={styles.FlexRow} 
                                          >     
                    
                                          <Button className={styles.Button}
                                            variation="primary"         
                                            onClick={ (e) => SortByActual(e)}
                                            >
                                                  Sort Actuals
                                          </Button>   

                                          <Button className={styles.Button}
                                            variation="primary"         
                                            onClick={ (e) => SortByTwitterFollowersIncr(e)}
                                            >
                                                  Sort #
                                          </Button>   
                                          <Button className={styles.Button}
                                            variation="primary"         
                                            onClick={ (e) => SortByTwitterFollowersPer(e)}
                                            >
                                                  Sort %
                                          </Button>                                             

                                        </Flex>                                                                                                                                              
                                        <div className={styles.MessageBox}>{ErrorMessage}</div>   
                                    </Flex>                     
                                  {ShowTable && (<TwitterStatsTable key={CauseUpdate} TwitterStats={TwitterStatsRecords}/>  )  }  
                      </Flex> 
                      </ErrorBoundary>
                    </div> 
              )}         
          </Authenticator>  
    )

  }
 

export default TwitterStats;