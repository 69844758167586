
import {createContext} from 'react';
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';



export const UserContext = createContext(null)  

export const UserGroup = createContext(null)  

export const UserAuthenticatedContext = createContext(null)  

export const UserSubcribedContext = createContext(null)  

export const CognitoGroupContext = createContext(null) 

export const AuthenticatedContext = createContext(null)  




async function UserContextProvider({ children }) {

    const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
    //console.log("UserContext.UserContextProvider.Users:  ",idToken.payload.email);
    //console.log("UserContext.UserContextProvider.CognitoGroup:  ",accessToken["payload"]["cognito:groups"][0]);

    return (
      <UserContext.Provider value={{"Users": idToken.payload.email, "CognitoGroup": accessToken["payload"]["cognito:groups"][0]}}>
        {children}
      </UserContext.Provider>
    );
  }


  async function CognitoGroupContextProvider({ children }) {

    const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
    //console.log("UserContext.UserContextProvider.Users:  ",idToken.payload.email);
    //console.log("UserContext.UserContextProvider.CognitoGroup:  ",accessToken["payload"]["cognito:groups"][0]);

    return (
      <CognitoGroupContext.Provider value={{"Users": idToken.payload.email, "CognitoGroup": accessToken["payload"]["cognito:groups"][0]}}>
        {children}
      </CognitoGroupContext.Provider>
    );
  }
