import React, { useState, useEffect, useContext, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./Solution.module.css";

import {
  useInView,
  useScroll,
  useTransform,
  motion,
  AnimatePresence,
  useMotionValue,
  useMotionValueEvent,
} from "framer-motion";
import { Button, Flex, Text, Image } from "@aws-amplify/ui-react";
import Background from "../../Backgrounds/BackgroundNoLogo";
import TurboFanBoostButton from "../../Buttons/TurboFanBoostButton";
import {useNavigate,Link } from 'react-router-dom';

const Solution = (props) => {
  Solution.propTypes = {
    UserId: PropTypes.string,
    UserName: PropTypes.string,
  };

  const ref = useRef(null);
  const isInView = useInView(ref);

  useEffect(() => {}, []);

  useEffect(() => {
    // console.log("Solution.useEffect[isInView].isInView: " ,isInView);
  }, [isInView]);

  return (
    <section ref={ref} className={styles.SectionContainer}>
      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        alignContent="flex-start"
        wrap="wrap"
        className={styles.TextFlexColumn}
      >
        <Flex
          direction="row"
          justifyContent="left"
          alignItems="center"
          alignContent="flex-start"
          wrap="wrap"
          className={styles.HeaderTextRow}
        >
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            alignContent="flex-start"
            wrap="nowrap"
            gap="0rem"
            className={styles.MayanNumber}
          >
          <motion.div 
              className={styles.MayanNumber}>       
              <motion.h1  style={{
                  transform: isInView ? "none" : "translateX(-200px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
                  }}
                  className={styles.MayanDot}
              >&#x2022;&#x2022;&#x2022;&#x2022;</motion.h1>
          </motion.div>
          </Flex>
          <motion.div>
            <motion.h1
              style={{
                transform: isInView ? "none" : "translateY(-200px)",
                opacity: isInView ? 1 : 0,
                transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
              }}
              className={styles.HeaderText}
            >
              Focus on the right things
            </motion.h1>
          </motion.div>
        </Flex>
        <Flex
          direction="row"
          justifyContent="left"
          alignItems="center"
          alignContent="flex-start"
          wrap="nowrap"
          className={styles.HeaderSubTextRow}
        >
          <motion.h1
            style={{
              transform: isInView ? "none" : "translateY(100px)",
              opacity: isInView ? 1 : 0,
              transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
            }}
            className={styles.HeaderSubText}
          >
            TurboFanBoost takes the stress out of social media marketing so you
            can create content and engage with your paying fans
          </motion.h1>
        </Flex>  
        <motion.div
        style={{
          transform: isInView ? "none" : "translateX(-200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
        className={styles.ApplicationLinkMotion}
      >  
        <Link className={styles.ApplicationLink} to="/TurboFanBoost">          
            <TurboFanBoostButton  className={styles.TurboFanBoostButton} >  
            </TurboFanBoostButton>
        </Link>  
      </motion.div>   
      </Flex>
      <motion.div
        style={{
          transform: isInView ? "none" : "translateX(-200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
        className={styles.Background}
      >
        <Background backgroundcolor="#9999CC" />
      </motion.div>
    </section>
  );
};

export default Solution;
