import React, { useEffect, Suspense,useState  , useRef, useLayoutEffect} from 'react'
import PropTypes from 'prop-types';
import { Canvas, useThree, useFrame } from '@react-three/fiber'
import RhyteItLogo from './Button3D';
import { Bounds, useBounds, OrbitControls, ContactShadows, PerspectiveCamera } from '@react-three/drei'
import styles from './ButtonContainer.module.css';


const ButtonContainer  = (props) => {
 
 
  ButtonContainer.propTypes = {
    UserId: PropTypes.string,
    UserName: PropTypes.string
  };

  useEffect(() => {

   // console.log("LandingPageV3.useEffect[].RhyteItBlob: ", RhyteItBlob.mesh.position)

    },[])     



    return (

      <div className={styles.RhyteItBlobCanvasDiv}>
      <div  className={styles.RhyteItBlobCanvas}>
      <Canvas>  
      <PerspectiveCamera
        makeDefault
        position={[200, 0.9, 1.8]}
        fov={60}
        zoom={0.9}
      />
      <OrbitControls
        // minZoom={2}
        // maxAzimuthAngle={20}
        enableZoom={true}
        enablePan={true}
        enableRotate={true}
      />
        <ambientLight intensity={0.3} />
          <Suspense fallback={null}>
                  <Bounds fit clip observe margin={10}>
                      <pointLight  intensity={.5} position={[30, 0, 30]} />
                      <RhyteItLogo RotationFactor="1" xPosition="3" yPosition="5"                      
                         />       
                  </Bounds>
                  <ContactShadows rotation-x={Math.PI / 2} position={[0, -35, 0]} opacity={0.2} width={200} height={200} blur={1} far={50} />
          </Suspense>
      </Canvas>
     
      </div>
      </div>
    )
  }

export default ButtonContainer