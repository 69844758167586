
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  CheckboxField,
  TableBody,
  TextField,  
  Menu,
  MenuItem, 
  Divider,
  Image,
  Text
  } from '@aws-amplify/ui-react';
  
  import { Link } from "react-router-dom";
  import { API , graphqlOperation} from 'aws-amplify';
  import * as Mutations from '../../graphql/mutations';  
  import {get_uuidv4} from '../../BusinessLogic/uuidv4';
  import styles from './TwitterStatsRow.module.css';  
  import { UserContext,UserAuthenticatedContext,UserSubcribedContext} from '../../BusinessLogic/Hooks/UserContext';
  //import TwitterStatDetail from "./TwitterStatDetail";

const TwitterStatsRow = (props) => {       

  TwitterStatsRow.propTypes = {
    TwitterStat: PropTypes.object
      };
  



    const [vTwitterStats, setvTwitterStats] = useState(props.TwitterStat)      

    useEffect(() => {

     // //console.log("TwitterStat.useEffect[].props.TwitterStat:  ",props.TwitterStat) 


      },[])  

    useEffect(() => {

      //console.log("vTwitterStat.useEffect[vTwitterStats].vTwitterStats[0].Subscribed:  ",vTwitterStats[0].Subscribed) 

    //  //console.log("vTwitterStat.useEffect[vTwitterStats]vTwitterStats:  ",vTwitterStats) 
      //console.log("vTwitterStats.useEffect[vTwitterStats].vTwitterStats:  ",props.RhyteItUser) 

      },[vTwitterStats])             


  return (
      <TableBody>  
          <React.Fragment>
            <TableRow  className={styles.TableRowBackground} key={vTwitterStats.id}>
              <TableCell as="td" className={styles.TableRowData}>
                <Text className={styles.TextField}>
                {new Date(vTwitterStats.StatDate).toISOString().slice(0,10)}
                </Text>
              </TableCell>
              <TableCell className={styles.TableRowData}>
                <Text className={styles.TextField}>
                  {vTwitterStats.AccountId}
                </Text>
              </TableCell>  
              <TableCell className={styles.TableRowData}>
                <Text className={styles.TextField} >    
                  {vTwitterStats.TwitterFollowers}
                </Text>
              </TableCell> 
              <TableCell className={styles.TableRowData}>
                <Text className={styles.TextField} >
                            {vTwitterStats.TwitterFollowersIncr}
                          </Text>
              </TableCell> 
              <TableCell className={styles.TableRowData}>
                <Text className={styles.TextField} 
                            name="vTwitterStat.TwitterFollowing">    
                            {(vTwitterStats.TwitterFollowersPer*100).toFixed(0)}%
                          </Text>
              </TableCell>  
            </TableRow>  
          </React.Fragment>
      </TableBody>
    
  )
}

export default TwitterStatsRow;