
import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead
  } from '@aws-amplify/ui-react'; 
import PlatformRow from './PlatformRow';
import styles from './PlatformsTable.module.css';


const PlatformsTable = (props) => {       

  PlatformsTable.propTypes = {
      SMPlatforms: PropTypes.array,
      RhyteItUser: PropTypes.array
      };

    let debugConsoleOutPut = true

  

    const [SMPlatforms, setSMPlatforms] = useState(props.SMPlatforms)   
    const [ShowTable, setShowTable] = useState(false);

    useEffect(() => {


    },[])  

    useEffect(() => {

      //console.log("PlatformsTable.useEffect[].props.SMPlatforms:  ",props.SMPlatforms)
      //console.log("PlatformsTable.useEffect[].SMPlatforms ",SMPlatforms) 
      //console.log("vPlatformRows.useEffect[].props.RhyteItUser:  ",props.RhyteItUser) 
      //console.log("vPlatformRows.useEffect[].Time:  ", PlatformRow.PostDateTime.substr(PlatformRow.PostDateTime.indexOf("T")+1, 5))
      //onsole.log("vPlatformRows.useEffect[].indexOf:  ", PlatformRow.PostDateTime.indexOf(":00"))
      if (SMPlatforms.length > 0) {
        setShowTable(true)
      }

    },[SMPlatforms])      



  return (  
      <Table className={styles.Table}   highlightOnHover={true}>   
          <TableHead>          
            <TableRow className={styles.TableHeaderRow}>
              <TableCell className={styles.TableHeaderRowCellsWide} >Platform</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Status</TableCell> 
              <TableCell className={styles.TableHeaderRowCellsActions} colSpan="2" >Actions</TableCell> 
            </TableRow>                                      
          </TableHead>       
          
              {
                ShowTable && SMPlatforms.length > 0 &&  SMPlatforms && SMPlatforms.map((SMMADPlatformRow) => (                      
                  (<PlatformRow   key={SMMADPlatformRow.id}  SMMADPlatformRow={SMMADPlatformRow}/>)        
                  ))
              }                    
        </Table>
    
  )
}

export default PlatformsTable;


