
import React, { useState , useEffect, useRef, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Button,
  SelectField,
  Text,
  Flex,
  Autocomplete, Loader 
  } from '@aws-amplify/ui-react'; 
import BlogCategoryPostHeader from './BlogCategoryPostHeader';
import { generateClient } from 'aws-amplify/api';
import {
  useInView,
  useScroll,
  useTransform,
  motion,
  useAnimation,
} from "framer-motion";
import styles from './BlogCategoryPosts.module.css';
import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
import {
  listBlogPosts,
  listSBSMSubReddits
} from "../../../graphql/queries";
import {
  createBlogPosts,
  deleteBlogPosts
} from "../../../graphql/mutations";


const BlogCategoryPosts = (props) => {       

  BlogCategoryPosts.propTypes = {
      Blog: PropTypes.object,
      CategoryBlogPosts: PropTypes.array,
      Category: PropTypes.string
      };

    let debugConsoleOutPut = true
  

    const [vBlog, setvBlog] = useState(props.Blog)   
    const [ShowTable, setShowTable] = useState(false); 
    const [vCategory, setvCategory] = useState(props.Category); 
    
    var ErrorMessageStr = ""
    const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)  
    
    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);
    const client = generateClient();
    
    const [vBlogPosts, setvBlogPosts] = useState(props.CategoryBlogPosts)   
    const [vBlogPostsSorted, setvBlogPostsSorted] = useState()   
    const [vPostClicked, setvPostClicked] = useState(false)   
    

    
    useEffect(() => {
     // //console.log("BlogCategoryPosts.useEffect[].props.CategoryBlogPosts: " ,props.CategoryBlogPosts);
      

    },[])  

    useEffect(() => {
       if (isInView) {
         mainControls.start("visible");
       }
     }, [isInView]);

     useEffect(() => {
      if (vBlogPosts !== undefined) {
        let vSortedBlogPostsTmp = SortBlogPostsByDate(vBlogPosts)
        setvBlogPostsSorted(vSortedBlogPostsTmp)
      }
    }, [vBlogPosts]);

    useEffect(() => {
      if (vBlogPostsSorted !== undefined) {
       // //console.log("BlogCategoryPosts.useEffect[vBlogPostsSorted].vBlogPostsSorted: " ,vBlogPostsSorted);
      }
    }, [vBlogPostsSorted]);    




    function SortBlogPostsByDate(pBlogPostEditionEmail) {
      pBlogPostEditionEmail.sort(GFG_sortFunction);
      return pBlogPostEditionEmail;
      //    //console.log(""JSON.stringify(pEmails));
    }
  
    function GFG_sortFunction(a, b) {
      let BlogPostCreationDateTimeA = a.CreationDateTime;
      let BlogPostCreationDateTimeB = b.CreationDateTime;
      return BlogPostCreationDateTimeA < BlogPostCreationDateTimeB ? 1 : -1;
    }


    async function PostClickHandler(event, vBlogPost) {
      try {
          //console.log("BlogCategoryPosts.PostClickHandler.vBlogPost: ", vBlogPost );
          setvPostClicked(vPostClicked === true ? false : true);
               
    } catch (err) {
      RhyteItLogging(
        "NA",
        "Blog",
        "BlogCategoryPosts",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
    }

  return (   
    <section ref={ref} className = {styles.Section}>
      {
        vBlog !== undefined  && (                      
        <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
            className={styles.FlexLeftColumn}>   
          
              {
               vBlogPostsSorted !== undefined && vBlog !== undefined && vBlogPostsSorted.map((vBlogPost) => (    
                <BlogCategoryPostHeader key={vBlogPost["BlogPostId"]} BlogPost={vBlogPost}/> 
                          
                  ))
              }    
        </Flex>                 
      )}      
    </section>
  )
}

export default BlogCategoryPosts;


