
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Text,
  TableBody,
  TextField,  
  CheckboxField ,
  Flex,
  Button,
  Image,
  TextAreaField,  
  SelectField,
  Fieldset,
  Table,
  TableHead,
  } from '@aws-amplify/ui-react';

  import  {  }  from '../../../graphql/queries'
  import  { deleteSMMADContent, updateSMMADAccounts, createSMMADAccounts}  from '../../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";

  import styles from './SMMADPostingConfig.module.css';  
  import { UserContext} from '../../../BusinessLogic/Hooks/UserContext';  
  import {get_uuidv4} from '../../../BusinessLogic/uuidv4';

  import {
    GetGroupRoleAccess,
    RhyteItLogging,
    ReturnUserToLandingPage,
  } from "../../../BusinessLogic/UserManagement";
  
  import SMMADPostings from './SMMADPostings'

const SMMADPostingConfig = (props) => {       

  SMMADPostingConfig.propTypes = {
      AccountSelected: PropTypes.object
      };

    const client = generateClient();
  
    const {Users, setUsers} = useContext(UserContext);   
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vAccountSelected, setvAccountSelected] = useState(props.AccountSelected);
    const [vCampaignsActive, setvCampaignsActive] = useState(props.AccountSelected.CampaignsActive)   
    const [vTwitterPostingFrequencyHours, setvTwitterPostingFrequencyHours] = useState(props.AccountSelected.TwitterPostingFrequencyHours)   
    
    

    const [vRecordSaved, setvRecordSaved] = useState(false)      
    const [vRowDeleted, setvRowDeleted] = useState(false)    
    const [vViewPostings, setvViewPostings] = useState(false)    
    const [updatedPostingKey, setupdatedPostingKey] = useState(Date.now())    
     


    useEffect(() => {

       //console.log("SMMADPostingConfig.useEffect[].props.SMMADPostingConfig.Active:  ",props.SMMADPostingConfig.Active) 
      
      },[])  

      useEffect(() => {

        setupdatedPostingKey(Date.now());

      }, [vViewPostings])         


      

   async function SaveRow(event, data) {
      try {           
        
          setErrorMessage("")

          var vUpdateDateTimeNew = new Date().toISOString()
      
          vAccountSelected.UpdateDateTime = vUpdateDateTimeNew       
          vAccountSelected.UpdateName = Users  
          vAccountSelected.SMMADAccountStatus = "Posting Frequency Updated"

          let AccountItem = {
              "id": vAccountSelected.id,
              "Account": vAccountSelected.Account,
              "AccountType": vAccountSelected.AccountType,
              "SMMADAccountStatus": vAccountSelected.SMMADAccountStatus,
              "CampaignsActive": vAccountSelected.CampaignsActive,
              "PostedCount": vAccountSelected.PostedCount,
              "TwitterLastPostDate": vAccountSelected.TwitterLastPostDate,
              "TwitterPostingFrequencyHours": vTwitterPostingFrequencyHours,
              "Weight": vAccountSelected.Weight,
              "ZScore": vAccountSelected.ZScore,
              "PurchaseId": vAccountSelected.PurchaseId,
              "ProductId": vAccountSelected.ProductId,
              "TwitterAccount": vAccountSelected.TwitterAccount,
              "TwitterPassword": vAccountSelected.TwitterPassword,
              "TwitterConsumerKey": vAccountSelected.TwitterConsumerKey,
              "TwitterConsumerSecret": vAccountSelected.TwitterConsumerSecret,
              "TwitterBearerToken": vAccountSelected.TwitterBearerToken,
              "TwitterAccessToken": vAccountSelected.TwitterAccessToken,
              "TwitterAccessTokenSecret": vAccountSelected.TwitterAccessTokenSecret,
              "TwitterClientId": vAccountSelected.TwitterClientId,
              "TwitterClientSecret": vAccountSelected.TwitterClientSecret,
              "TwitterAppName":vAccountSelected.TwitterAppName,
              "TwitterAppId": vAccountSelected.TwitterAppId,
              "TwitterStatus": vAccountSelected.TwitterStatus,
              "Active": vAccountSelected.Active,
              "CreationName": vAccountSelected.CreationName,
              "CreationDateTime": vAccountSelected.CreationDateTime,
              "UpdateName": vAccountSelected.UpdateName,
              "UpdateDateTime": vAccountSelected.UpdateDateTime,
          }

            console.log("SMMADPostingConfig.SaveRow.AccountItem:  ",AccountItem)                      

            if (AccountItem.id === "NEW") {
              AccountItem.id = get_uuidv4
              AccountItem.SMMADAccountStatus = "Created"
              
              const updateSMMADAccountResponse = await client.graphql({
                query: createSMMADAccounts,
                variables: {
                  input: AccountItem
                }
              });    
              setvAccountSelected(updateSMMADAccountResponse.data.createSMMADAccounts)
              setErrorMessage(ErrorMessageStr)
            } else {
  
 
              const updateSMMADAccountResponse = await client.graphql({
                query: updateSMMADAccounts,
                variables: {
                  input: AccountItem
                }
              });  

              //console.log("SMMADPostingConfig.SaveRow.updateSMMADAccountResponse:  ",updateSMMADAccountResponse)  
              setvAccountSelected(updateSMMADAccountResponse.data.updateSMMADAccounts)
              setErrorMessage("Record saved")
  
          }


            setvRecordSaved(true)            

        } catch (err) { 
          ErrorMessageStr = ("SMMADPostingConfig.SaveRow.Error: ", err, err.stack)
          console.error(ErrorMessageStr)
          setErrorMessage(ErrorMessageStr)
      }        
    }      
   
 
 

    function TwitterPostingFrequencyHours_handleChange(event,data) {
      try {

                
                try {              


                  setvTwitterPostingFrequencyHours(event.target.value)
         
        
                } catch (err) { 
                  console.error("SMMADPostingConfig.Campaign_handleChange.Error:  ".err, err.stack); 
              }                  
 
                //console.log("ReportSelected.DashboardHighlevel.length: ",DashboardHighlevel.length); 

      } catch (err) { 
                      console.error('SMMADPostingConfig.Campaign_handleChange.ERROR: ', err, err.stack); 
                  }
    }     

    function time_convert(num)
    { 
      // Calculate the number of hours by dividing num by 60 and rounding down
      var hours = Math.floor(num).toFixed(0);  
      // Calculate the remaining minutes by taking the remainder when dividing num by 60
      var minutes = Math.round((Number(num)-hours) * 60);

      //console.log("SMMADPostingConfig.time_convert.num:  ",num, " - hours:  ",hours, " - minutes:  ",minutes) 

      // Return the result as a string in the format "hours:minutes"
      return hours + " hrs " + minutes + " min";         
    }

    async function ViewPostings(event) {
      try {
        setvViewPostings(!vViewPostings)
  
  
      } catch (err) {
        RhyteItLogging(
          Users,
          "SMMADCampaign",
          "ViewConfig",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }    

  return (
    <Flex
    direction="column"
    justifyContent="flex-start"
    alignItems="stretch"
    alignContent="center"
    wrap="nowrap"
    gap="1rem"
    className={styles.FlexColumn}
  >
    <Table className={styles.RhyteItTable} highlightOnHover={true}>

    <TableHead>          
        <TableRow className={styles.TableHeaderRow}>
          <TableCell className={styles.TableHeaderRowCell} >Account</TableCell> 
          <TableCell className={styles.TableHeaderRowCell} >Campaigns</TableCell> 
          <TableCell className={styles.TableHeaderRowCell} >Twitter Posts</TableCell> 
          <TableCell className={styles.TableHeaderRowCell}>Post Weight</TableCell> 
          <TableCell className={styles.TableHeaderRowCell}>Last Post</TableCell> 
          <TableCell className={styles.TableHeaderRowCell}>~ Next Post (hrs)</TableCell> 
          <TableCell className={styles.TableHeaderRowCell}>Post every (hrs)</TableCell> 
          <TableCell className={styles.TableHeaderRowCell}>Actions</TableCell> 
        </TableRow>                                      
      </TableHead>         
      <TableBody>                         
        <TableRow
          className={styles.TableRow}
        >
        <TableCell>
        <Text className={styles.Text}>
            {vAccountSelected.Account}  
          </Text>  
        </TableCell>
        <TableCell>
            <CheckboxField
                className={styles.CheckboxField}
                name="subscribe-controlled"
                checked={vAccountSelected.CampaignsActive}
                onChange={(e) => setvCampaignsActive(e.target.checked)}
              />                      
          </TableCell>
          <TableCell>
          <Text className={styles.Text}>
              {vAccountSelected.PostedCount}  
            </Text>  
          </TableCell>
          <TableCell>
          <Text className={styles.Text}>
              {vAccountSelected.Weight}  
            </Text>  
          </TableCell>
          <TableCell>
          <Text className={styles.TextDateTime}>
          {new Date(vAccountSelected.TwitterLastPostDate).toLocaleString()}
            </Text>  
          </TableCell>
          <TableCell>
          <Text className={styles.TextDateTime}>
          ~ {time_convert((vAccountSelected.TwitterPostingFrequencyHours-((Date.now() - new Date(vAccountSelected.TwitterLastPostDate))/3600/ 1000)))}
            </Text>  
          </TableCell>               
          <TableCell>
            <TextField 
              className={styles.TextField} 
              name="TwitterPostingFrequencyHours"                       
              defaultValue={vAccountSelected.TwitterPostingFrequencyHours}
              onChange={ (e) => TwitterPostingFrequencyHours_handleChange(e, vAccountSelected.TwitterPostingFrequencyHours) } 
              />                                
          </TableCell>
          <TableCell>
            <Button
              loadingText=""
              ariaLabel=""
              className={styles.Button}
              onClick={(e) => SaveRow(e, vAccountSelected)}
            
            >
              Save
            </Button>
          </TableCell>                    
        </TableRow>
        <TableRow
          className={styles.TableRow}
        >
        <TableCell colSpan="8">
        <Text className={styles.Text}>
            {ErrorMessage}  
          </Text>  
        </TableCell>                        
        </TableRow>        
    </TableBody>    
  </Table>
   <Button
      loadingText=""
      ariaLabel=""
      className={styles.Button}
      onClick={(e) => ViewPostings(e, vAccountSelected)}
    >   
    View past 10 postings
 </Button>
 {vViewPostings === true &&  (
    <SMMADPostings  key={updatedPostingKey} AccountSelected={vAccountSelected} />

 )}
 </Flex>
    
  )
}

export default SMMADPostingConfig;

