
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  CheckboxField,
  TableBody,
  TextField,  
  Menu,
  MenuItem, 
  Button,
  Divider,
  Image
  } from '@aws-amplify/ui-react';
  import {get_uuidv4} from '../../BusinessLogic/uuidv4';
  import styles from './SBSMRSSRow.module.css';  
  import { UserContext,UserAuthenticatedContext,UserSubcribedContext} from '../../BusinessLogic/Hooks/UserContext';
  
  import  { }  from '../../graphql/queries'
  import  { createSBSMNewsRSS, updateSBSMNewsRSS, deleteSBSMNewsRSS}  from '../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';

const SBSMRSSRow = (props) => {       

  SBSMRSSRow.propTypes = {
      SBSMRSSFeedRow: PropTypes.object
      };
  
    const {Users, setUsers} = useContext(UserContext);   
    const [ErrorMessage, setErrorMessage] = useState("")
    let ErrorMessageStr = ""
    let debugConsoleOutPut = true

    const client = generateClient();
    const [RSSFeedRow, setRSSFeedRow] = useState(props.SBSMRSSFeedRow)      

    useEffect(() => {

      //console.log("SBSMRSSRow.useEffect[].props.SBSMNewsQueryTerm:  ",props.SBSMRSSFeedRow) 
      //console.log("SBSMRSSRow.useEffect[].RSSFeedRow:  ",RSSFeedRow) 

      },[])  

    useEffect(() => {

          //console.log("SBSMRSSRow.useEffect[].RSSFeedRow:  ",RSSFeedRow) 
          setErrorMessage("")

      },[RSSFeedRow])             
    
      async function SBSMRSSRow_Delete_Row(event,data) {
      try {

          var today = new Date();

          if (RSSFeedRow.Status !== "NEW") {

        
          const DeleteSBSMNewsRSS = {
            id: RSSFeedRow.id,
            UserId: RSSFeedRow.UserId,
            _version: RSSFeedRow._version
          }
         
          const ReturnMessage = await client.graphql({
            query: deleteSBSMNewsRSS,
            variables: {
              input: DeleteSBSMNewsRSS
            }
          });                

          setRSSFeedRow(RSSFeedRow)
          ErrorMessageStr = "RSS deleted at: " + today.toLocaleString("en-US")
          setErrorMessage(ErrorMessageStr)
        }
                
          } catch (err) { 

          ErrorMessageStr = "SBSMRSSRow.SBSMRSSRow_Delete_Row.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }               
      
    }   

   async function SaveSBSMRSSRow(event, data) {
      try {           
          var today = new Date();
          setErrorMessage("")
          
          var vUpdateDateTime = new Date().toISOString()

          RSSFeedRow.UpdateDateTime = vUpdateDateTime       
          RSSFeedRow.UpdateName = Users[0].UserId       

          if (RSSFeedRow.Status === "NEW") {
            
            const updateSBSMNewsRSSResponse = await client.graphql({
              query: createSBSMNewsRSS,
              variables: {
                input: RSSFeedRow
              }
            });  

            setRSSFeedRow(updateSBSMNewsRSSResponse.data.createSBSMNewsRSS)
            setErrorMessage(ErrorMessageStr)

          } else {


            const updateProductsResponse = await client.graphql({
              query: updateSBSMNewsRSS,
              variables: {
                input: RSSFeedRow
              }
            });  

            setRSSFeedRow(updateProductsResponse.data.updateSBSMNewsRSS)
            setErrorMessage(ErrorMessageStr)

        }
          
          setRSSFeedRow(RSSFeedRow) 
          setErrorMessage(ErrorMessageStr)
     

        } catch (err) { 
          ErrorMessageStr = ("SBSMRSSRow.SaveSBSMRSSRow.Error: ", err, err.stack)
          console.error(ErrorMessageStr)
          setErrorMessage(ErrorMessageStr)
      }        
    }      
     

    function id_handleChange(event, data) {
      try {              

          RSSFeedRow.id = event.target.value
          console.log("SBSMRSSRow.FileURL_handleChange.RSSFeedRow.id: ",RSSFeedRow.id)

          setRSSFeedRow(RSSFeedRow)   

        } catch (err) { 
          console.error("SBSMRSSRow.PostTitle_handleChange.Error:  ".err, err.stack); 
      }        
    }        
  
    function InfoType_handleChange(event, data) {
      try {              

          RSSFeedRow.InfoType = event.target.value
          console.log("SBSMRSSRow.InfoType_handleChange.RSSFeedRow.InfoType: ",RSSFeedRow.InfoType)

          setRSSFeedRow(RSSFeedRow)   

        } catch (err) { 
          console.error("SBSMRSSRow.InfoType_handleChange.Error:  ".err, err.stack); 
      }        
    }        
  
    function RSSLink_handleChange(event, data) {
      try {              

          RSSFeedRow.RSSLink = event.target.value
          console.log("SBSMRSSRow.RSSLink_handleChange.RSSLink: ",RSSFeedRow.RSSLink)

          setRSSFeedRow(RSSFeedRow)   

        } catch (err) { 
          console.error("SBSMRSSRow.RSSLink_handleChange.Error:  ".err, err.stack); 
      }        
    }        
  
    function URL_handleChange(event, data) {
      try {              

          RSSFeedRow.URL = event.target.value
          console.log("SBSMRSSRow.URL_handleChange.RSSFeedRow.URL: ",RSSFeedRow.URL)

          setRSSFeedRow(RSSFeedRow)   

        } catch (err) { 
          console.error("SBSMRSSRow.URL_handleChange.Error:  ".err, err.stack); 
      }        
    }        
  
 
  

  return (
      <TableBody  className={styles.TableBody}>                         
          <TableRow  className={styles.TopRow}   key={RSSFeedRow.id}>  
            <TableCell as="td" >      
              <TextField className={styles.TextField} 
                          name="RSSFeed.id"     
                          color="white"                      
                          defaultValue={RSSFeedRow.id}
                          onChange={ (e) => id_handleChange(e, RSSFeedRow) } />
            </TableCell>                 
            <TableCell as="td">      
              <TextField className={styles.TextField} 
                          name="RSSFeed.InfoType"                           
                          defaultValue={RSSFeedRow.InfoType}
                          onChange={ (e) => InfoType_handleChange(e, RSSFeedRow) } />
            </TableCell>                
            <TableCell as="td">      
              <TextField className={styles.TextField} 
                          name="RSSFeed.RSSLink"                           
                          defaultValue={RSSFeedRow.RSSLink}
                          onChange={ (e) => RSSLink_handleChange(e, RSSFeedRow) } />
            </TableCell>  
                                    
          </TableRow>   
          <TableRow  className={styles.TableRowBackground}>         
            <TableCell as="td">      
              <TextField className={styles.TextField} 
                          name="RSSFeedRow.URL"                           
                          defaultValue={RSSFeedRow.URL}
                          onChange={ (e) => URL_handleChange(e, RSSFeedRow) } />
            </TableCell>          
            <TableCell as="td">      
              <TextField className={styles.TextField} 
                          name="RSSFeedRow.Status"                           
                          defaultValue={RSSFeedRow.Status} />
            </TableCell> 
            <TableCell as="td"  className={styles.RhyteItMenuCell}> 
              <Button
                  loadingText=""
                  ariaLabel=""
                  title="Save Record"
                  className={styles.TableRowButtons}
                  onClick={(e) => SaveSBSMRSSRow(e, RSSFeedRow)}
                >
                S
                </Button>   
                <Button
                  loadingText=""
                  ariaLabel=""
                  className={styles.TableRowButtons}
                  onClick={(e) => SBSMRSSRow_Delete_Row(e, RSSFeedRow)}
                >
                D
                </Button>                 
          </TableCell>                         
          </TableRow>   
      {
        ErrorMessage.length > 0 &&             
          <TableRow  className={styles.ErrorMessageRow}>              
            <TableCell 
              colSpan="3">      
              {ErrorMessage}
            </TableCell>               
          </TableRow>  
      }               
      </TableBody>
    
  )
}

export default SBSMRSSRow;