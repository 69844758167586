
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHead,
  Flex,
  Button,
  Image,
  Text
  } from '@aws-amplify/ui-react';

  import  {  }  from '../../../graphql/queries'
  import  { deleteSMMADContent, updateSMMADContent, createSMMADContent}  from '../../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";

  import styles from './ContentRow.module.css';  
  import { UserContext} from '../../../BusinessLogic/Hooks/UserContext';  
  import { SelectedImageContext} from '../../../BusinessLogic/Hooks/SMMADImageContext';
  
function ContentRow({
  SMMADContentRow,
  HashTagMap,
  ImageToTextRelationship,
  AccountSelected,
  CampaignFrom,
  CampaignTo,
  ClickedImage,
  onClick
}) {       

  ContentRow.propTypes = {
      SMMADContentRow: PropTypes.object,
      AccountSelected: PropTypes.string,
      CampaignFrom: PropTypes.string,
      CampaignTo: PropTypes.string,
      onClick: PropTypes.func,
      };

    const client = generateClient();
  
    const {Users, setUsers} = useContext(UserContext);   
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vContentRow, setvContentRow] = useState(SMMADContentRow)   

    const [vCategory, setvCategory] = useState()   
    const [vActive, setvActive] = useState(SMMADContentRow.Active)   
    
    const [vRowDeleted, setvRowDeleted] = useState(false)     
    
    const [vHashTagMap, setvHashTagMap] = useState(HashTagMap) 
    
    const [vAccountSelected, setvAccountSelected] = useState(AccountSelected)   
    const [vCampaignFrom, setvCampaignFrom] = useState(CampaignFrom)  
    const [vCampaignTo, setvCampaignTo] = useState(CampaignTo)   
    const [vMigrate, setvMigrate] = useState("NA")   
    const [vAddText, setvAddText] = useState(false)   
        
    const vSelectedImage = useContext(SelectedImageContext);

    //console.log("ContentRow.useEffect[].vSelectedImage:  ",vSelectedImage) 

    useEffect(() => {

      
      },[])  

      useEffect(() => {

       //console.log("ContentRow.useEffect[vActive].vActive:  ",vActive) 
       
       },[vActive])      

       useEffect(() => {

  //        //console.log("ContentRow.useEffect[vAddText].vAddText:  ",vAddText) 
        
        },[vAddText])             
       
        useEffect(() => {

          console.log("ContentRow.useEffect[vMigrate].vMigrate:  ",vMigrate) 
          if (vMigrate === "migrate") {
            SaveRow()
          }
                
        },[vMigrate])  

        

      async function SaveRow() {
          try {           
            
              setErrorMessage("")

              var vUpdateDateTimeNew = new Date().toISOString()
          
              vContentRow.UpdateDateTime = vUpdateDateTimeNew       
              vContentRow.UpdateName = Users  
              

              let ContentItem = {
                  "id": vContentRow.id,
                  "Account": vContentRow.Account,
                  "AccountType": vContentRow.AccountType,
                  "Campaign": vCampaignTo,
                  "Category": vCategory,
                  "ContentType": vContentRow.ContentType,
                  "URL": vContentRow.URL,
                  "S3Bucket": vContentRow.S3Bucket,
                  "S3Key": vContentRow.S3Key,
                  "PostedCount":  vContentRow.PostedCount,
                  "Weight":  vContentRow.Weight,
                  "Active":  vContentRow.Active,
                  "SMMADContentStatus":  "Campaign Changed",
                  "CreationName": vContentRow.CreationName,
                  "CreationDateTime": vContentRow.CreationDateTime,
                  "UpdateName": vContentRow.UpdateName,
                  "UpdateDateTime": vContentRow.UpdateDateTime,
              }


              // //console.log("ContentRow.SaveRow.ContentItem:  ",ContentItem)                      

                const updateSMMADContentResponse = await client.graphql({
                  query: updateSMMADContent,
                  variables: {
                    input: ContentItem
                  }
                });  

                setvContentRow(ContentItem)
                setvMigrate("migrated")

            } catch (err) { 
              ErrorMessageStr = ("ContentRow.SaveRow.Error: ", err, err.stack)
              console.error(ErrorMessageStr)
              setErrorMessage(ErrorMessageStr)
          }        
        }      
        

        function ClickOnContentRow(event) {
          try {


              setvMigrate("migrate")
              setErrorMessage("") 
              console.log("RhyteItAccountRow.ClickOnContentRow.event:  ",event) 
      
              
            } catch (err) { 
    
            ErrorMessageStr = "RhyteItAccountRow.OpenPostURL Error: " + err 
    
            console.error(ErrorMessageStr); 
            
            setErrorMessage(ErrorMessageStr)         
          }        
        }  

        
  return (

      <TableRow  className={vMigrate === "migrate" ? styles.TableRowSelected : vMigrate === "migrated" ? styles.TableRowMigrated : styles.TableRowBackground} key={vContentRow.id} 
      onClick={(e) => ClickOnContentRow(e)}>
      <TableCell as="td" className={styles.TableRowData}>
      <Image
                alt=""
                src={vContentRow.URL}  
                objectFit="initial"
                objectPosition="50% 50%"
                className={styles.Image}
              />  
      </TableCell>
      <TableCell className={styles.TableRowData}>
        <Text className={styles.TextField}>
          {vContentRow.Campaign}
        </Text>
      </TableCell>  
      <TableCell className={styles.TableRowData}>
        <Text className={styles.TextField} >    
          {vContentRow.PostedCount}
        </Text>
      </TableCell> 
      <TableCell className={styles.TableRowData}>
        <Text className={styles.TextField} >
                    {vContentRow.Weight}
                  </Text>
      </TableCell> 
      <TableCell className={styles.TableRowData}>
        <Text className={styles.TextField} 
                    name="vTwitterStat.TwitterFollowing">    
                    {new Date(vContentRow.UpdateDateTime).toISOString().slice(0,10)}
                  </Text>
      </TableCell>  
    </TableRow>  
  )
}

export default ContentRow;

