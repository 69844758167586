
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHead,
  Flex,
  Button,
  Image,
  Text
  } from '@aws-amplify/ui-react';

  import  { listSMMADTexts,listSMMADContents  }  from '../../../graphql/queries'
  import  { deleteSMMADCampaigns}  from '../../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";

  import styles from './DeleteCampaignRow.module.css';  
  import { UserContext} from '../../../BusinessLogic/Hooks/UserContext';  
  import { SelectedImageContext} from '../../../BusinessLogic/Hooks/SMMADImageContext';
  
function DeleteCampaignRow({
  SMMADCampaignRow
}) {       

  DeleteCampaignRow.propTypes = {
      SMMADDeleteCampaignRow: PropTypes.object,
      AccountSelected: PropTypes.string,
      CampaignFrom: PropTypes.string,
      CampaignTo: PropTypes.string,
      onClick: PropTypes.func,
      };

    const client = generateClient();
  
    const {Users, setUsers} = useContext(UserContext);   
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vDeleteCampaignRow, setvDeleteCampaignRow] = useState(SMMADCampaignRow)   
    const [vDelete, setvDelete] = useState("NA")    
    
    const [SMContent, setSMContent] = useState([]) 
    const [nextContentToken, setnextContentToken] = useState(undefined)     
    const [IsContentLoading, setIsContentLoading] = useState(false)
    const [vContentLength, setvContentLength] = useState(0)

    
    const [SMText, setSMText] = useState([]) 
    const [nextTextToken, setnextTextToken] = useState(undefined)     
    const [IsTextLoading, setIsTextLoading] = useState(false)
    const [vTextLength, setvTextLength] = useState(0)


    useEffect(() => {
  //    //console.log("DeleteCampaignRow.useEffect[].SMMADCampaignRow:  ",SMMADCampaignRow) 
      GetContent()
      GetText()
      
      },[])  

    useEffect(() => {

     // //console.log("DeleteCampaignRow.useEffect[vDelete].vDelete:  ",vDelete) 
      if (vDelete === "delete") {
        DeleteCampaign()
      }
            
    },[vDelete])  

    useEffect(() => {

      const fetch1 = async () => {


        try {

              if(nextContentToken !== null) {
                setIsContentLoading(true)

                  var datetime = new Date();                                                
                  let limit = 10000000
                  //console.log("TwitterStats.useEffect[NextToken].nextContentToken: ", nextContentToken);   
                  
                  let  variables = {
                    nextContentToken,
                    filter: {
                      Account: {
                              eq: vDeleteCampaignRow["Account"]
                          },
                        Campaign: {
                                eq: vDeleteCampaignRow["Campaign"]
                            }
                    }
                  };   
                  
                  const ContentData2  = await client.graphql({
                    query: listSMMADContents,
                    variables: variables
                  });
    
        
        
                //console.log("Content.GetContent.ContentData2.data.listSMMADContents.nextContentToken:  ",ContentData2.data.listSMMADContents.nextContentToken)

                let ContentData = SMContent                   
                      
      
                ContentData2.data.listSMMADContents.items.forEach(item => 
                  {   
                
                    if ([...ContentData].filter(Content => item.id === Content.id)["length"] === 0) {  
                      ContentData.push(item) 
                    }

                  })


                    //console.log("TwitterStats.useEffect[NextToken].elseif.TwitterStatsRecordsData2.data.listEmailAccountStats.nextContentToken: ", TwitterStatsRecordsData2.data.listEmailAccountStats.nextContentToken);   
                    //console.log("TwitterStats.useEffect[NextToken].elseif.TwitterStatsRecordsData2.length: ", TwitterStatsRecordsData2.data.listEmailAccountStats.items.length);  
                    //console.log("TwitterStats.useEffect[NextToken].elseif.TwitterStatsRecordsData2.items: ", TwitterStatsRecordsData2.data.listEmailAccountStats.items);  

                    setSMContent(ContentData)  
                    setnextContentToken(ContentData2.data.listSMMADContents.nextContentToken)  

                                        

                  
              } else if (nextContentToken === null){          
                
                
                setIsContentLoading(false)

              }

        } catch (err) {
          //console.log(err)
        } finally {
          setIsContentLoading(false)
        }
      }
    
      fetch1()

    }, [nextContentToken])    
    
    useEffect(() => {

      const fetch1 = async () => {


        try {

              if(nextTextToken !== null) {
                setIsTextLoading(true)

                  var datetime = new Date();                                                
                  let limit = 10000000
                  //console.log("TwitterStats.useEffect[NextToken].nextContentToken: ", nextContentToken);   
                  
                  let  variables = {
                    nextTextToken,
                    filter: {
                      Account: {
                              eq: vDeleteCampaignRow["Account"]
                          },
                        Campaign: {
                                eq: vDeleteCampaignRow["Campaign"]
                            }
                    }
                  };   
                  
                  const TextData2  = await client.graphql({
                    query: listSMMADTexts,
                    variables: variables
                  });
    
        
        
                //console.log("Content.GetContent.ContentData2.data.listSMMADTexts.nextTextToken:  ",TextData2.data.listSMMADTexts.nextToken)

                let TextData = SMText                  
                      
      
                TextData2.data.listSMMADTexts.items.forEach(item => 
                  {   
                
                    if ([...TextData].filter(Content => item.id === Content.id)["length"] === 0) {  
                      TextData.push(item) 
                    }

                  })


                    //console.log("TwitterStats.useEffect[NextToken].elseif.TwitterStatsRecordsData2.data.listEmailAccountStats.nextContentToken: ", TwitterStatsRecordsData2.data.listEmailAccountStats.nextContentToken);   
                    //console.log("TwitterStats.useEffect[NextToken].elseif.TwitterStatsRecordsData2.length: ", TwitterStatsRecordsData2.data.listEmailAccountStats.items.length);  
                    //console.log("TwitterStats.useEffect[NextToken].elseif.TwitterStatsRecordsData2.items: ", TwitterStatsRecordsData2.data.listEmailAccountStats.items);  

                    setSMText(TextData)  
                    setnextTextToken(TextData2.data.listSMMADTexts.nextToken)  

                                        

                  
              } else if (nextTextToken === null){          
                
                
                setIsTextLoading(false)

              }

        } catch (err) {
          //console.log(err)
        } finally {
          setIsTextLoading(false)
        }
      }
    
      fetch1()

    }, [nextTextToken])        
      
    useEffect(() => {

      setvContentLength(SMContent.length)
            
    },[SMContent])  


    useEffect(() => {

      setvTextLength(SMText.length)
            
    },[SMText])  

    async function DeleteCampaign() {
      try {

              
              setErrorMessage("")   
              //console.log("SMMADCampaignTable.DeleteCampaign.vCampaignToShow.id:  ",vCampaignToShow[0].id)  


              const DeleteSMMADCampaignRow = {
                id: vDeleteCampaignRow.id,
                _version: vDeleteCampaignRow._version
              }
              

              const vDeleteResponse = await client.graphql({
                query: deleteSMMADCampaigns,
                variables: {
                  input: DeleteSMMADCampaignRow
                }
              });  

              //console.log("SMMADCampaignTable.DeleteCampaign.vDeleteResponse:  ",vDeleteResponse)  

              ErrorMessageStr = vDeleteResponse
              setErrorMessage(ErrorMessageStr)  
              
      
          } catch (err) { 

          ErrorMessageStr = err

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }               
      
    }  

    function ClickOnDeleteCampaignRow(event) {
      try {


          setvDelete("delete")
          setErrorMessage("") 
          //console.log("RhyteItAccountRow.ClickOnDeleteCampaignRow.event:  ",event) 
  
          
        } catch (err) { 

        ErrorMessageStr = "RhyteItAccountRow.OpenPostURL Error: " + err 

        console.error(ErrorMessageStr); 
        
        setErrorMessage(ErrorMessageStr)         
      }        
    }  

    async function GetContent() {
      try {
                setErrorMessage("")
                setSMContent([])
                
             //console.log("Content.GetContent.vAccountSelected:  ",vAccountSelected)
             //console.log("Content.GetContent.vCampaignSelected:  ",vCampaignSelected)
               

               let  variables = {
                  filter: {
                    Account: {
                      eq: vDeleteCampaignRow["Account"]
                    },
                  Campaign: {
                          eq: vDeleteCampaignRow["Campaign"]
                          }
                  }
                };

                const ContentData2  = await client.graphql({
                  query: listSMMADContents,
                  variables: variables
                });
  
      
      
                //console.log("Content.GetContent.ContentData2:  ",ContentData2)

                if (ContentData2.data.listSMMADContents.items.length > 0) {
                    const Content1 = ContentData2.data.listSMMADContents.items 

                    setSMContent(Content1)  
                    
                    setnextContentToken(ContentData2.data.listSMMADContents.nextContentToken)  
       
              }

      } catch (err) { 
                      console.error('Content.GetContent.ERROR: ', err, err.stack); 
                  }
    }     

    async function GetText() {
      try {
                setErrorMessage("")
                setSMText([])
                
             //console.log("Content.GetContent.vAccountSelected:  ",vAccountSelected)
             //console.log("Content.GetContent.vCampaignSelected:  ",vCampaignSelected)
               

               let  variables = {
                  filter: {
                    Account: {
                      eq: vDeleteCampaignRow["Account"]
                    },
                  Campaign: {
                          eq: vDeleteCampaignRow["Campaign"]
                          }
                  }
                };

                const TextData2  = await client.graphql({
                  query: listSMMADTexts,
                  variables: variables
                });
  
      
      
                //console.log("Content.GetContent.TextData2:  ",TextData2)

                if (TextData2.data.listSMMADTexts.items.length > 0) {
                    const Text1 = TextData2.data.listSMMADTexts.items 

                    setSMText(Text1)  
                    
                    setnextTextToken(TextData2.data.listSMMADTexts.nextToken)  
       
              }

      } catch (err) { 
                      console.error('Content.GetContent.ERROR: ', err, err.stack); 
                  }
    }   
        
  return (

      <TableRow  className={vDelete === "delete" ? styles.TableRowSelected : vDelete === "deleted" ? styles.TableRowMigrated : styles.TableRowBackground} key={vDeleteCampaignRow.id} 
      onClick={(e) => ClickOnDeleteCampaignRow(e)}>
      <TableCell className={styles.TableRowData}>
        <Text className={styles.TextField}>
          {vDeleteCampaignRow.Campaign}
        </Text>
      </TableCell>  
      <TableCell className={styles.TableRowData}>
        <Text className={styles.TextField}>
          {vDeleteCampaignRow.SMMADCampaignStatus}
        </Text>
      </TableCell>  
      <TableCell className={styles.TableRowData}>
        <Text className={styles.TextField}>
          {vDeleteCampaignRow.ContentTextActive.toString()}
        </Text>
      </TableCell>  
      <TableCell className={styles.TableRowData}>
        <Text className={styles.TextField}>
          {vContentLength.toString()}
        </Text>
      </TableCell>  
      <TableCell className={styles.TableRowData}>
        <Text className={styles.TextField}>
          {vTextLength.toString()}
        </Text>
      </TableCell>  
      <TableCell className={styles.TableRowData}>
        <Text className={styles.TextField} >    
          {vDeleteCampaignRow.PostedCount}
        </Text>
      </TableCell> 
      <TableCell className={styles.TableRowData}>
        <Text className={styles.TextField} >
                    {vDeleteCampaignRow.Weight}
                  </Text>
      </TableCell> 
      <TableCell className={styles.TableRowData}>
        <Text className={styles.TextField} 
                    name="vTwitterStat.TwitterFollowing">    
                    {new Date(vDeleteCampaignRow.UpdateDateTime).toISOString().slice(0,10)}
                  </Text>
      </TableCell>  
    </TableRow>  
  )
}

export default DeleteCampaignRow;

