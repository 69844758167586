import { loadStripe } from '@stripe/stripe-js';

import  { }  from '../../graphql/queries'
import  { GetSecretValue }  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

let stripePromise;
let debugConsoleOutPut = true

async function QuerySecretValue(pEnvironment, pSecretKey) {
  try {              
      
   // console.log("getStripe.QuerySecretValue.pSecretKey: ",pSecretKey)
    const client = generateClient();

      let SecretValueJSON = await client.graphql({
        query: GetSecretValue,
        variables: {
          Environment: pEnvironment, SecretKey: pSecretKey
        }
      });        
      
    var SecretJSON = JSON.parse(SecretValueJSON.data.GetSecretValue);

    if (debugConsoleOutPut === true) {
  //    console.log("getStripe.SecretValueJSON[SecretValue]: ",SecretJSON["SecretValue"])
    }     

    return SecretJSON["SecretValue"]


    } catch (err) { 
     // console.error("getStripe.GetSecretValue.Error:  " , err); 
    //  console.trace(err);
      return "ERROR"
  }        
}     

async function getStripe(pEnvironment) {


  try {              
      //  console.log("getStripe.getStripe.pEnvironment: ",pEnvironment)
        let vSecretValue = await QuerySecretValue(pEnvironment, "STRIPE_PUBLIC_KEY_NAME") 

        if (debugConsoleOutPut === true) {
    //      console.log("getStripe.getStripe.vSecretValue: ",vSecretValue)
        }    


        if (!stripePromise && vSecretValue !== "ERROR") {
          //stripePromise = loadStripe('pk_test_51LhhPeAbSMFlphAZjaPdgBVJOAwsJqbciaaZksoC5zRclrvVAamfnVPZupNYG6SLGWAbykKpH1YtkHEfnROLv2Z000ZE0S7Kvi');
          stripePromise = loadStripe(vSecretValue);
        }
        return stripePromise;

  } catch (err) { 
    //console.error("getStripe.getStripe.Error:  ", err); 
    return "ERROR";
  }          
};

export default getStripe;

