
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Text,
  TableBody,
  TextField,  
  Button,
  Image
  } from '@aws-amplify/ui-react';

  import  {  }  from '../../../graphql/queries'
  import  { deleteSMMADPlatforms, updateSMMADPlatforms, createSMMADPlatforms}  from '../../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";

  import styles from './PlatformRow.module.css';  
  import { UserContext} from '../../../BusinessLogic/Hooks/UserContext';  

const PlatformRow = (props) => {       

  PlatformRow.propTypes = {
      SMMADPlatformRow: PropTypes.object
      };

    const client = generateClient();
  
    const {Users, setUsers} = useContext(UserContext);   
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""
    let debugConsoleOutPut = true

    const [message, setMessage] = useState('');

    const [vPlatform, setvPlatform] = useState(props.SMMADPlatformRow)      

    useEffect(() => {

       // //console.log("PlatformRow.useEffect[].props.SMMADPlatformRow:  ",props.SMMADPlatformRow) 
      
      },[])  


    function EditPlatformRow(event, data) {
      try {           
        
          setErrorMessage("")


        } catch (err) { 
          ErrorMessageStr = ("vPlatform.EditPlatformRow.Error: ", err, err.stack)
          console.error(ErrorMessageStr)
          setErrorMessage(ErrorMessageStr)
      }        
    }      
    
    async function DeleteRow(event,data) {
      try {

            console.log("PlatformRow.Delete_Row.Users[0].UserId    :  ",Users[0].UserId    ) 

            if (Users[0].UserId === "edwardhawke@rhyteit.com") {

              var elementPos = vPlatform.map(function(x) {return x.id; }).indexOf(data.id);                      
                              
              console.log("PlatformRow.DeleteRow.vPlatform[elementPos].id:  ",vPlatform[elementPos].id  ) 

              const DeletePlatformRow = {
                id: vPlatform[elementPos].id,
                _version: vPlatform[elementPos]._version
              }
              

              const vDeleteResponse = await client.graphql({
                query: deleteSMMADPlatforms,
                variables: {
                  input: DeletePlatformRow
                }
              });  

              console.log("PlatformRow.DeleteRow.vDeleteResponse:  ",vDeleteResponse  ) 
              setvPlatform(vPlatform => vPlatform.filter((data)=> data.id !== vPlatform[elementPos].id ))
              ErrorMessageStr = vDeleteResponse
              setErrorMessage(ErrorMessageStr)      
 
              
          } else {

            ErrorMessageStr = "You do not have permission to delete this Product"
            setErrorMessage(ErrorMessageStr)  

          }
      
          } catch (err) { 

          ErrorMessageStr = "PlatformRow.DeleteRow.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }               
      
    }       

  return (
      <TableBody>           
          <TableRow  className={styles.TableRowBackground} key={vPlatform.id}>
            <TableCell className={styles.TableRowHeadingData} >  
                <Text  className={styles.TextField}>
                                  {vPlatform.Platform}
                </Text>  
              </TableCell>                                        
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {vPlatform.SMMADPlatformsStatus}
                </Text>  
            </TableCell>       
            <TableCell as="td"  className={styles.RhyteItMenuCell}> 
            <Link key={vPlatform.id} isExternal={false} to="/SMMADPlatformDetail" state={{ vPlatform: vPlatform }} >   
                  <Button
                    loadingText=""
                    ariaLabel=""
                    className={styles.RowButtons}
                    onClick={(e) => EditPlatformRow(e, vPlatform)}
                  >
                  E
                  </Button> 
              </Link>                 
            </TableCell>   
            <TableCell as="td"  className={styles.RhyteItMenuCell}> 
                  <Button
                    loadingText=""
                    ariaLabel=""
                    className={styles.RowButtons}
                    onClick={(e) => DeleteRow(e, vPlatform)}
                  >
                  D
                  </Button>                 
            </TableCell>                                                        
          </TableRow>  
      {
        ErrorMessage.length > 0 &&             
          <TableRow  >              
            <TableCell 
              colSpan="7">   
              <Text className={styles.ErrorMessageRow}>  
              {ErrorMessage}
              </Text> 
            </TableCell>               
          </TableRow>  
      }                        
      </TableBody>
    
  )
}

export default PlatformRow;

