
import React, { useState, useEffect } from 'react'
//import './SignUp.css'; 
import {
    TextField, 
    PasswordField,
    Text,
    CheckboxField,
    Button
  } from '@aws-amplify/ui-react';
import PropTypes from 'prop-types';
import HeaderMenu from "../../HeaderMenu/LandingPageNavBar";
import styles from './Overview.module.css';  
import StatusMessageBox from "../../../ui-components/StatusMessageBox";
import {motion as m} from "framer-motion";




const Overview = (props) => {

    Overview.propTypes = {
        UserEmail: PropTypes.string,
        UserId: PropTypes.string,
        UserName: PropTypes.string
      };

 

      var ErrorMessageStr = ""   
      const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   

    useEffect(() => {

        console.log('SignUp.useEffect[].props.UserId:', props.UserId);
                
    },[])      

   
    return (
        <m.div
                key="Demo"
                initial={{y: "100%"}}
                animate={{y: "0%"}}
                transition={{duration:0.75, ease: "easeOut"}}
                exit={{ opacity: 1 }}
               >
            <div>            
            <HeaderMenu pageSource="Application"/> 
            <video 
                    className={styles.RhyteItProcessMapVideo} 
                    src="https://rhyteitvideos.s3.us-west-2.amazonaws.com/RhyteItOverview_2023-06-24_21-32.mp4"  
                    controls 
                    autoPlay 
                    controlsList="nodownload"
                    loop/>
                <StatusMessageBox ErrorMessage={ErrorMessage}></StatusMessageBox> 
  
            </div>
  
            </m.div>
                
    );
}


export default Overview;    