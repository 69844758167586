
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  TableBody,
  Button,
  Link,
  Image,
  Flex
  } from '@aws-amplify/ui-react';


  import  { }  from '../../graphql/queries'
  import  { createSBSMNews, updateSBSMNews}  from '../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';
  
  import {RhyteItLogging} from '../../BusinessLogic/UserManagement';
  
  
  import styles from './SBSMNewsRow.module.css';  

const SBSMNewsRow = (props) => {       

  SBSMNewsRow.propTypes = {
      SBSMArticle: PropTypes.object,
      SBSMLogos: PropTypes.object
      };
  
      const client = generateClient();
    //const {Users, setUsers} = useContext(UserContext);   
    const [ErrorMessage, setErrorMessage] = useState("")
    let ErrorMessageStr = ""
    let debugConsoleOutPut = true
    let vHoursAgo = 0    
    const [vSBSMNewsArticles, setvSBSMNewsArticles] = useState(props.SBSMArticle)   
    const [SBSMLogos, setSBSMLogos] = useState(props.SBSMLogos);  
    const [vSBSMLogo, setvSBSMLogo] = useState();  

    vHoursAgo = Date.now() - vSBSMNewsArticles.PubDate    
    var today = new Date();
    var vPubDate = new Date(vSBSMNewsArticles.PubDate);
    var diffMs = (today - vPubDate); 
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    var vDateAgo  = diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes ago";



    let vNewScore = Number(vSBSMNewsArticles.Score)

    var vSubRow = Number(vNewScore.toFixed(4)) + " | " + vDateAgo + " | " + vSBSMNewsArticles.UpVotes + " votes up | " + vSBSMNewsArticles.DownVotes + " votes down"
    useEffect(() => {

   //   console.log("SBSMNewsRow.useEffect[].SBSMLogos:  ",SBSMLogos) 
      //console.log("SBSMNewsRow.useEffect[].vSBSMNewsArticles:  ",vSBSMNewsArticles) 


      },[])  

    useEffect(() => {

         // //console.log("SBSMNewsRow.useEffect[].vSBSMNewsArticles:  ",vSBSMNewsArticles) 
          
          setErrorMessage("")

      },[vSBSMNewsArticles])             



      useEffect(() => {

        
       // console.log("SBSMNewsRow.useEffect[SBSMLogos].SBSMLogos:  ",SBSMLogos) 

        if (SBSMLogos !== undefined) {

      //    console.log("SBSMNewsRow.useEffect[SBSMLogos].SBSMLogos.typeof:  ",typeof SBSMLogos);
  
          const vSelectedLogo = Object.values(SBSMLogos).filter(logo => {
          //  console.log("SBSMNewsRow.useEffect[SBSMLogos].logo:  ",logo);
            return logo.QueryTerm === props.SBSMArticle.QueryTerm ;
          });

          
        //  console.log("SBSMNewsRow.useEffect[SBSMLogos].vSelectedLogo:  ",vSelectedLogo, " - length:  ",vSelectedLogo.length);

          if (vSelectedLogo.length > 0) {
           // console.log("SBSMNewsRow.useEffect[SBSMLogos].vSelectedLogo:  ",vSelectedLogo[0].LogImageFileLocation);
            let vSleele = vSelectedLogo[0].LogImageFileLocation
            setvSBSMLogo(vSleele)
          }
        }
         
         setErrorMessage("")

     },[SBSMLogos])       
     
     useEffect(() => {

      if (vSBSMLogo !== undefined) {
     //   console.log("SBSMNewsRow.useEffect[vSBSMLogo].vSBSMLogo:  ",vSBSMLogo);

      }
    

    },[vSBSMLogo])          


        
        

    function RecalculateScore() {
      try {

           // //console.log("SBSMNewsRow.SBSMNewsRow_Delete_Row.Users[0].UserId    :  ",Users[0].UserId    ) 
           let dtPubDate = new Date(vSBSMNewsArticles.PubDate)
                
           let vAgeInHours = Math.abs(Date.now() - dtPubDate) / 36e5;                     

           
           let NewScore = ((vSBSMNewsArticles.UpVotes+1)/(vSBSMNewsArticles.DownVotes+2))*(1/((vAgeInHours+2)^1.5))
           vSBSMNewsArticles.Score = Number(NewScore.toFixed(4))
           setvSBSMNewsArticles(vSBSMNewsArticles) 
      
          } catch (err) { 

          ErrorMessageStr = "vSBSMNewsArticles.SBSMNewsRow_Delete_Row.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }               
      
    }   


    function SBSMNewsRow_UpVote(event, data) {
      try {              
          
          if (debugConsoleOutPut === true) {
            //console.log("vSBSMNewsArticles.PostDate_handleChange.event.target.value: ",event.target.value)
          }     

          vSBSMNewsArticles.UpVotes += 1

          setvSBSMNewsArticles(vSBSMNewsArticles) 

          RecalculateScore()
          
          SaveSBSMNewsRow()

          //console.log("vSBSMNewsArticles.SBSMNewsArticle_handleChange.vSBSMNewsArticles[elementPos].SBSMNewsArticle: ",vSBSMNewsArticles[elementPos].SBSMNewsArticle)

        } catch (err) {                 
          RhyteItLogging("UnKnown", "SBSMNewsRow", "SBSMNewsRow_UpVote",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          console.error("SBSMNewsRow.SBSMNewsRow_UpVote.Error:  ".err, err.stack); 
      }        
    }  

    function SBSMNewsRow_DownVote(event, data) {
      try {              
          
          if (debugConsoleOutPut === true) {
            //console.log("vSBSMNewsArticles.PostDate_handleChange.event.target.value: ",event.target.value)
          }     

          vSBSMNewsArticles.DownVotes += 1

          setvSBSMNewsArticles(vSBSMNewsArticles) 
          
          RecalculateScore()
          
          SaveSBSMNewsRow()

          //console.log("vSBSMNewsArticles.SBSMNewsArticle_handleChange.vSBSMNewsArticles[elementPos].SBSMNewsArticle: ",vSBSMNewsArticles[elementPos].SBSMNewsArticle)

        } catch (err) { 
          console.error("vSBSMNewsArticles.PostDate_handleChange.Error:  ".err, err.stack); 
      }        
    }  


    function SaveSBSMNewsRow() {
      try {           
          var today = new Date();
          setErrorMessage("")
          
          var vUpdateDateTime = new Date().toISOString()


          vSBSMNewsArticles.UpdateDateTime = vUpdateDateTime       
          vSBSMNewsArticles.UpdateName = "SBSM news user"       

          if (vSBSMNewsArticles.SBSMNewsArticleStatus === "NEW") {
            vSBSMNewsArticles.SBSMNewsArticleStatus = "Active"



            let createAudienceGeoInfoResponse = client.graphql({                    
              query: createSBSMNews,
              variables:{input: {vSBSMNewsArticles }
            }
          });
            
            ErrorMessageStr = "Article saved at: " + today.toLocaleString("en-US")
            setErrorMessage(ErrorMessageStr)

          } else {

            
            let vNewsItemUpdate = {
              "id": vSBSMNewsArticles["id"],
              "UserId": vSBSMNewsArticles["UserId"],
              "QueryTerm": vSBSMNewsArticles["QueryTerm"],
              "InfoType": vSBSMNewsArticles["InfoType"],
              "Title": vSBSMNewsArticles["Title"],
              "ImageURL": vSBSMNewsArticles["ImageURL"],
              "URL": vSBSMNewsArticles["URL"],
              "Source": vSBSMNewsArticles["Source"],
              "PubDate": vSBSMNewsArticles["PubDate"],
              "SBSMStatus": vSBSMNewsArticles["SBSMStatus"],
              "Penalty": vSBSMNewsArticles["Penalty"],
              "UpVotes": vSBSMNewsArticles["UpVotes"],
              "DownVotes": vSBSMNewsArticles["DownVotes"],
              "AgeInHours": vSBSMNewsArticles["AgeInHours"],
              "Score": vSBSMNewsArticles["Score"],
              "CreationName": vSBSMNewsArticles["CreationName"],
              "UpdateName": vSBSMNewsArticles["UpdateName"],
              "CreationDateTime": vSBSMNewsArticles["CreationDateTime"],
              "UpdateDateTime": vSBSMNewsArticles["UpdateDateTime"]
            }

            console.log("SBSMNewsRow.SaveSBSMNewsRow.vNewsItemUpdate: ",vNewsItemUpdate)

            let createAudienceGeoInfoResponse = client.graphql({                    
              query: updateSBSMNews,
              variables:{input: vNewsItemUpdate
            }
          });
            ErrorMessageStr = "Article saved at: " + today.toLocaleString("en-US")
            setErrorMessage(ErrorMessageStr)

        }
          
          setvSBSMNewsArticles(vSBSMNewsArticles) 
          setErrorMessage(ErrorMessageStr)
     

        } catch (err) { 
          RhyteItLogging("UnKnown", "SBSMNewsRow", "SaveSBSMNewsRow",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          console.error("SBSMNewsRow.SaveSBSMNewsRow.Error:  ".err, err.stack); 
      }        
    }    

   

  return (
      <TableBody>         
            <TableRow  className={styles.TableRowBackgroundTitle}  key={vSBSMNewsArticles.id}>         
              <TableCell colSpan="1"> 
                  <Button
                        loadingText=""
                        ariaLabel=""
                        className={styles.RedditTitleButtons}
                        onClick={(e) => SBSMNewsRow_UpVote(e, vSBSMNewsArticles)}
                      >
                      ▲
                  </Button>   
              </TableCell>   
              <TableCell colSpan="2">     
                <Flex
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  alignContent="center"
                  wrap="nowrap"
                  gap="1rem"
                >      
                <Image
                    alt=""
                    src={vSBSMLogo}  
                    objectFit="initial"
                    objectPosition="50% 50%"
                    className={ styles.PhotoImage}
                  
                  />                                       
                  <Link className={styles.Link} href={vSBSMNewsArticles.URL} isExternal={true} colSpan="3">
                  {vSBSMNewsArticles.Title}
                  </Link>   
              </Flex>    
              </TableCell>                       
            </TableRow>  
            <TableRow  className={styles.TableRowBackground} >                
            <TableCell colSpan="1">    
                <Button
                        loadingText=""
                        ariaLabel=""
                        className={styles.RedditTitleButtons}
                        onClick={(e) => SBSMNewsRow_DownVote(e, vSBSMNewsArticles)}
                      >
                      ▼
                  </Button> 
                  
              </TableCell>     
              <TableCell as="td" colSpan="1" className={styles.SubRow}>      
                {vSubRow}
              </TableCell>                                 
          </TableRow>  
      {
        ErrorMessage.length > 0 &&             
          <TableRow  className={styles.ErrorMessageRow}>              
            <TableCell 
              colSpan="3">      
              {ErrorMessage}
            </TableCell>               
          </TableRow>  
      }               
      </TableBody>
    
  )
}

export default SBSMNewsRow;