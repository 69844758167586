
import React, { useState , useEffect, useContext,useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  } from '@aws-amplify/ui-react';

  import  {listAudienceGeoInfos}  from '../../graphql/queries'
  import { generateClient } from 'aws-amplify/api';

  import styles from './PageViewMap.module.css';  
  import { UserContext} from '../../BusinessLogic/Hooks/UserContext';  
  import 'mapbox-gl/dist/mapbox-gl.css';
  import mapboxgl from  '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
  //import MapboxGLWorker from 'mapbox-gl/dist/mapbox-gl-csp-worker?worker';
  //mapboxgl.workerClass = MapboxGLWorker;
  import ControlPanel from './ControlPanel';
  

const PageViewMap = (props) => {       
  

  PageViewMap.propTypes = {
      RhyteItPageViewMap: PropTypes.object,
      Report: PropTypes.string
      };
    
    const client = generateClient();

    mapboxgl.accessToken = "pk.eyJ1IjoiY3BtY21hbmFtYW4iLCJhIjoiY2xvcWZnaHN0MGh0djJ2bjVodjh2b2p4ayJ9.r2AtpqbnObWL_5gu4fSBPQ";
    const {Users, setUsers} = useContext(UserContext);   
    const [ErrorMessage, setErrorMessage] = useState("")   
    const [vEnvironment, setvEnvironment] = useState(props.Environment)   
    let ErrorMessageStr = ""
    let debugConsoleOutPut = true
    let MAX_ZOOM_LEVEL = 100
    const [message, setMessage] = useState('');
  
    const [MapPoints, setMapPoints] = useState(0)  

    var PageviewGEOJSON = {
                            "type": "FeatureCollection",
                            "features": [
                              {
                                "geometry": { 
                                              "type": "Point", 
                                              "coordinates": [-151.5129, 63.1016, 0.0] 
                                            }
                              }
                            ]
                          }
        
    
    const [MapData, setMapData] = useState(PageviewGEOJSON) 

    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(-0);
    const [lat, setLat] = useState(10.35);
    const [zoom, setZoom] = useState(1.5);    
    const [allDays, useAllDays] = useState(false);
    const [timeRange, setTimeRange] = useState([0, 0]);
    const [selectedTime, selectTime] = useState(new Date("2023-07-01"));
    const [StartDate, setStartDate] = useState(new Date("2023-07-01"))
    const [EndDate, setEndDate] = useState(Date.now());

      useEffect(() => {



            setTimeout(() => {
                
              if (map.current) return; // initialize map only once
                  map.current = new mapboxgl.Map({
                                                  container: mapContainer.current,
                                                  style: "mapbox://styles/mapbox/dark-v9",
                                                  center: [lng, lat],
                                                  zoom: zoom
                                                });



              const sourceObject = map.current.getSource('points');         
              //console.log("PageViewMap.useEffect[].sourceObject:  ",sourceObject)                                           

              map.current.on('load', function() {

                  if (sourceObject !== undefined) {
                    map.current.getSource('points').setData(MapData);
                  } else {
                    
                    map.current.addSource('points', 
                    {
                        'type': 'geojson',
                        'data': MapData
                        }
                      )   
                  }

                  //console.log("PageViewMap.useEffect[].sourceObject_after_onload:  ",sourceObject)    
                    

                  if (map.current.getLayer('pageviews-heat') === undefined) {

                    map.current.addLayer(
                      {
                        'id': 'pageviews-heat',
                        'type': 'heatmap',
                        'source': 'points',
                        'maxzoom': 9,
                        'paint': {
                            // Increase the heatmap weight based on frequency and property magnitude
                            'heatmap-weight': [
                                'interpolate',
                                ['linear'],
                                ['get', 'PageViews'],
                                0,
                                0,
                                3,
                                1
                            ],
                            // Increase the heatmap color weight weight by zoom level
                            // heatmap-intensity is a multiplier on top of heatmap-weight
                            'heatmap-intensity': [
                                'interpolate',
                                ['linear'],
                                ['zoom'],
                                0,
                                1,
                                9,
                                3
                            ],
                            // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
                            // Begin color ramp at 0-stop with a 0-transparancy color
                            // to create a blur-like effect.
                            'heatmap-color': [
                                'interpolate',
                                ['linear'],
                                ['heatmap-density'],
                                0,
                                'rgba(33,102,172,0)',
                                0.2,
                                'rgb(103,169,207)',
                                0.4,
                                'rgb(209,229,240)',
                                0.6,
                                'rgb(253,219,199)',
                                0.8,
                                'rgb(239,138,98)',
                                1,
                                'rgb(178,24,43)'
                            ],
                            // Adjust the heatmap radius by zoom level
                            'heatmap-radius': [
                                'interpolate',
                                ['linear'],
                                ['zoom'],
                                7,
                                10,
                                9,
                                20
                            ],
                            // Transition from heatmap to circle layer by zoom level
                            'heatmap-opacity': [
                                'interpolate',
                                ['linear'],
                                ['zoom'],
                                7,
                                1,
                                9,
                                0
                            ]
                        }
                      },
                        'waterway-label'
                      );

                  }

                  if (map.current.getLayer('pageviews-point') === undefined) {

                    map.current.addLayer(
                        {
                          'id': 'pageviews-point',
                          'type': 'circle',
                          'source': 'points',
                          'minzoom': 7,
                          'paint': {
                              // Size circle radius by earthquake magnitude and zoom level
                              'circle-radius': [
                                  'interpolate',
                                  ['linear'],
                                  ['zoom'],
                                  7,
                                  ['interpolate', ['linear'], ['get', 'PageViews'], 1, 1, 6, 4],
                                  16,
                                  ['interpolate', ['linear'], ['get', 'PageViews'], 1, 5, 6, 50]
                              ],
                              // Color circle by earthquake magnitude
                              'circle-color': [
                                  'interpolate',
                                  ['linear'],
                                  ['get', 'PageViews'],
                                  1,
                                  'rgba(33,102,172,0)',
                                  2,
                                  'rgb(103,169,207)',
                                  3,
                                  'rgb(209,229,240)',
                                  4,
                                  'rgb(253,219,199)',
                                  5,
                                  'rgb(239,138,98)',
                                  6,
                                  'rgb(178,24,43)'
                              ],
                              'circle-stroke-color': 'white',
                              'circle-stroke-width': 1,
                              // Transition from heatmap to circle layer by zoom level
                              'circle-opacity': [
                                  'interpolate',
                                  ['linear'],
                                  ['zoom'],
                                  7,
                                  0,
                                  8,
                                  1
                              ]
                          }
                        },
                        'waterway-label'
                      );  

                  }

                  if (map.current.getLayer('PageViewCities') === undefined) {
                    map.current.addLayer({
                        'id': 'PageViewCities',
                        'type': 'symbol',
                        'source': 'points',
                        'layout': {
                            'text-field': [
                                'format',
                                ['upcase', ['get', 'title']],
                                { 'font-scale': 0.6 }
                              ],
                              'text-variable-anchor': ['bottom'],
                              'text-radial-offset': -0.5,
                              'text-justify': 'center',
                            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold']
                        },
                        'paint': {
                          "text-color": "#00CCCC" 
                        }
                        });       
                  }           

              })      

            }, "1000");     

            (async function() {
              try {          
                
                    let chris = await GetMapData()         //console.log("CustomerPurchases.useEffect[].Users:  ",Users)

                    } catch (err) {
                      console.error('PageViewMap.useEffect[].ERROR: ', err, err.stack); 
                  }
                }
              )();                            


      },[]) 

      const data = useMemo(() => {

        if ( map.current !== null ) {

          let vData = (allDays ? MapData : filterFeaturesByDay(MapData, selectedTime))

        //console.log("PageViewMap.data.useMemo:  ", vData["features"].length)
        if (vData["features"].length > 0) {
        //  //console.log("PageViewMap.data.useMemo.title:  ",vData["features"][0]["properties"]["title"])
         // //console.log("PageViewMap.data.useMemo.PageViews:  ",vData["features"][0]["properties"]["PageViews"])
         // //console.log("PageViewMap.data.useMemo.PVDate:  ",vData["features"][0]["properties"]["PVDate"])

        }

        const sourceObject = map.current.getSource('points');
                
        //console.log("PageViewMap.useEffect[MapData].sourceObject:  ",sourceObject)

        if (sourceObject !== undefined) {
          map.current.getSource('points').setData(allDays ? MapData : filterFeaturesByDay(MapData, selectedTime));
        }        
        
        
        //return allDays ? MapData : filterFeaturesByDay(MapData, selectedTime);

        }

      }, [MapData, allDays, selectedTime]);      

      async function GetMapData() {
        try {
                  setErrorMessage("")
                  setMapData([])
                  PageviewGEOJSON = {
                    "type": "FeatureCollection",
                    "features": []
                  }


                  const variables = {
                    filter: {
                      city: {
                              ne: null
                          }
                    } 
                  }                 
        
                  const MapData2  = await client.graphql({
                    query: listAudienceGeoInfos,
                    variables: variables
                  });

                if (MapData2.data.listAudienceGeoInfos.items.length > 0) {
                    console.log("MapData2.data.listAudienceGeoInfos.items.length: ",MapData2.data.listAudienceGeoInfos.items.length);
                    setMapPoints(MapData2.data.listAudienceGeoInfos.items.length)
                    const vMapData = MapData2.data.listAudienceGeoInfos.items 

                    vMapData.forEach(function (item, index, array) {
                      BuildGeoJSON(item, index, array)
                    })


                    if (debugConsoleOutPut === true) {
                  //   console.log("PageViewMap.GetMapData.PageviewGEOJSON: ",PageviewGEOJSON)
                    }                       

                    setMapData(PageviewGEOJSON) 
                 
          
                }

            return "SUCCESS"

        } catch (err) { 
                        console.error('PageViewMap.GetMapData.ERROR: ', err, err.stack); 
                    }
      }            

      async function BuildGeoJSON(item, index, pMapData) {
        try {           
          
            setErrorMessage("")              


           if (new Date(item.CreationDateTime) > new Date('2023-11-21T10:20:30Z')) {

            console.log("PageViewMap.BuildGeoJSON.item.city: ",item.city, " - CreationDateTime: ",item.CreationDateTime)
            //  //console.log("PageViewMap.BuildGeoJSON.PageviewGEOJSON.typeof: ", typeof PageviewGEOJSON)
            //  //console.log("PageViewMap.BuildGeoJSON.PageviewGEOJSON: ",PageviewGEOJSON["features"].length)
           }

                var vPageViews = pMapData.reduce(function(previousValue, currentObject){
                  return previousValue + ((currentObject["longitude"] === item.longitude && currentObject["latitude"] === item.latitude) ? 1: 0); 
              }, 0);                

                            

                var vCoordinatesExist = PageviewGEOJSON["features"].reduce(function(previousValue, currentObject){
                  return previousValue + ((currentObject["geometry"]["coordinates"][0] === item.longitude && currentObject["geometry"]["coordinates"][1] === item.latitude) ? 1: 0); 
              }, 0);   
              
                var vDate = pMapData.reduce(function(previousDate, currentObject){
                  return (((new Date(previousDate) <= new Date(currentObject["CreationDateTime"])) && currentObject["longitude"] === item.longitude && currentObject["latitude"] === item.latitude) ? new Date(currentObject["CreationDateTime"]).toDateString() : previousDate); 
                }, 0);   
              
           
  
            if (vCoordinatesExist === 0) {
                  let RhyteItPageViewItem = {
                    "type": "Feature",
                    "geometry": {
                      "type": "Point",
                      "coordinates": [
                        item.longitude,
                        item.latitude
                      ]
                    },
                    "properties": {                  
                      'title': item.city,
                      'PageViews': vPageViews,
                      'PVDate': vDate
                    }
                  }      

                  PageviewGEOJSON["features"].push(RhyteItPageViewItem)

            }
            if (debugConsoleOutPut === true) {
            //  console.log("PageviewGEOJSON[features]: ", PageviewGEOJSON["features"])
             }      
            
            setErrorMessage('')
        
  
          } catch (err) { 
            console.error(err)
            ErrorMessageStr = ("PageViewMap.BuildGeoJSON.Error: ", err, err.stack)
            console.error(ErrorMessageStr)
            setErrorMessage(ErrorMessageStr)
        }        
      }
    
      function filterFeaturesByDay(featureCollection, time) {

        
        //console.log("PageViewMap.filterFeaturesByDay.time:  ",time)
        //console.log("PageViewMap.filterFeaturesByDay.featureCollection:  ",featureCollection)

        const date = new Date(time);
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();
        const features = featureCollection.features.filter(feature => {
          
                                //console.log("PageViewMap.filterFeaturesByDay.feature.properties.PVDate:  ",feature.properties.PVDate)
                                const featureDate = new Date(feature.properties.PVDate);
                                  return (
                                    featureDate.getFullYear() === year &&
                                    featureDate.getMonth() === month &&
                                    featureDate.getDate() === day
                                  );
                                });
          //console.log("PageViewMap.filterFeaturesByDay.features:  ",features)
          return {type: 'FeatureCollection', features};
        }
        
  return (
      <div className={styles.mapContainers}>
        <ControlPanel
            startTime={StartDate}
            endTime={EndDate}
            selectedTime={selectedTime}
            allDays={allDays}
            onChangeTime={selectTime}
            onChangeAllDays={useAllDays}
          />       
          <div ref={mapContainer} className={styles.mapContainer} />
      </div>
      
  )
}

export default PageViewMap;

