
import React, { useState , useEffect, useRef, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Button,
  SelectField,
  Flex,
  Autocomplete, Loader 
  } from '@aws-amplify/ui-react'; 
import BlogPost from './BlogPost';
import { generateClient } from 'aws-amplify/api';
import {
  useInView,
  useScroll,
  useTransform,
  motion,
  useAnimation,
} from "framer-motion";
import styles from './BlogPosts.module.css';
import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
import {
  listBlogPosts,
  listSBSMSubReddits
} from "../../../graphql/queries";
import {
  createBlogPosts,
  deleteBlogPosts
} from "../../../graphql/mutations";


const BlogPosts = (props) => {       

  BlogPosts.propTypes = {
      Blog: PropTypes.object,
      Users: PropTypes.string,
      };

    let debugConsoleOutPut = true
  

    const [vBlog, setvBlog] = useState(props.Blog)   
    const [Users, setUsers] = useState(props.Users)   
    const [ShowTable, setShowTable] = useState(false); 
    
    var ErrorMessageStr = ""
    const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)  
    
    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);
    const client = generateClient();
    
    const [vUpdateKey, setvUpdateKey] = useState()   
    const [updatedKey, setupdatedKey] = useState();
    const [BlogPostSelected, setBlogPostSelected] = useState({"Edition":"None"});
    const [BlogPostVisible, setBlogPostVisible] = useState(false);
    const [vBlogPosts, setvBlogPosts] = useState()   

    
    const [vContentHTML, setvContentHTML] = useState('');
    const [vTemplateJSON, setvTemplateJSON] = useState()  
    const [vContentJSON, setvContentJSON] = useState()     

    const [vSubReddits, setvSubReddits] = useState()  
    const [vBlogPostName, setvBlogPostName] = React.useState();
    
    const [vAddBlogPostVisible, setvAddBlogPostVisible] = useState(false);

    useEffect(() => {
      //console.log("BlogPosts.useEffect[isInView].props.Blog: " ,props.Blog);
 

    },[])  

    useEffect(() => {
       if (isInView) {
         mainControls.start("visible");
       }
     }, [isInView]);



    useEffect(() => {
      //console.log("BlogPosts.useEffect[vBlog].vBlog:  ",vBlog)
  
      if (vBlog !== undefined) {

       
        GetBlogPosts()
      }
    }, [vBlog]);

     async function GetBlogPosts() {
      try {
        setErrorMessage("");
        
        //console.log("BlogPostEditorTable.GetBlogPosts.vBlog: ", vBlog);
        let variables;
        if (Users !== "edwardhawke@rhyteit.com") {
          variables = {
            filter: {
              AccountGroup: {
                eq: vBlog["AccountGroup"],
              },
              Blog: {
                eq: vBlog["Blog"],
              },
            },
          };
        } else {
          variables = {};
        }
  
        const listBlogPostsData2 = await client.graphql({
          query: listBlogPosts,
          variables: variables,
        });
  
        //console.log("BlogPostEditorTable.GetBlogPosts.listBlogPosts.data.listBlogPosts.items.length : ", listBlogPostsData2.data.listBlogPosts.items.length );
  
        if (listBlogPostsData2.data.listBlogPosts.items.length > 0) {
          const BlogPostEditorTable1 =
            listBlogPostsData2.data.listBlogPosts.items;
            let vSortedBlogPosts = SortBlogPostsByUpdateDate(BlogPostEditorTable1)
            setvBlogPosts(vSortedBlogPosts)
        } 
  
  
  
      } catch (err) {

        console.error("BlogPosts.GetBlogPosts.Error.message:  ", err["errors"][0]["message"].toString()); 
        
        RhyteItLogging(
          Users,
          "BlogPosts",
          "GetBlogPosts",
          err["errors"][0]["message"].toString(),
          '{"ERROR": "' + err["errors"][0]["message"].toString() + '"}',
          0
        );
        


      }
    }
  
    function SortBlogPostsByUpdateDate(pBlogPost) {
      pBlogPost.sort(GFG_sortFunction);
      return pBlogPost;
      //    //console.log(""JSON.stringify(pEmails));
    }
  
    function GFG_sortFunction(a, b) {
      let UpdateDateTimeA = a.UpdateDateTime;
      let UpdateDateTimeB = b.UpdateDateTime;
      return UpdateDateTimeA < UpdateDateTimeB ? 1 : -1;
    }


    async function CreateBlogPost() {
      try {

        //console.log("BlogPostSubRedditTable.CreateBlogPost.pSubReddit:  ",pSubReddit);
        var vUpdateDateTime = new Date().toISOString()

        let vId = (vBlog["AccountGroup"] + "-" + vBlog["Blog"] + "-" + vUpdateDateTime).replace(/["']/g, "").replace(/\s+/g, '').replace(/[^\w]/g, '');
              
        let BlogPostCreateItem = {
          "id": vId,
          "AccountGroup": vBlog["AccountGroup"],
          "Account": vBlog["Account"],
          "Blog": vBlog["Blog"],
          "PromptName": "",
          "SubRedditList": null,
          "Prompt": "",
          "PromptJSON": null,
          "Active": true,
          "BlogPostStatus": "NEW",
          "CreationName": Users,
          "UpdateName": Users,
          "CreationDateTime": vUpdateDateTime,
          "UpdateDateTime": vUpdateDateTime
        }

         //console.log("BlogPostSubRedditTable.CreateBlogPost.BlogPostCreateItem: ",BlogPostCreateItem)

          let createBlogPostsResponse = await client.graphql({                    
                  query: createBlogPosts,
                  variables:{input: BlogPostCreateItem}            
            }
          )
        
        //console.log("BlogPostSubRedditTable.CreateBlogPost.createBlogPostsResponse: ",createBlogPostsResponse)
        
        GetBlogPosts()

      } catch (err) {
        console.error("BlogPostSubRedditTable.CreateBlogPost.Error:  ", err, err.stack); 
        
        RhyteItLogging(
          Users,
          "BlogPostSubRedditTable",
          "CreateSubReddit",
          err["errors"][0]["message"].toString(),
          '{"ERROR": "' + err["errors"][0]["message"].toString() + '"}',
          0
        );
        
        console.error("BlogPostSubRedditTable.CreateBlogPost.Error.message:  ", err["errors"][0]["message"].toString()); 
      }
    }


 



  return (   
    <section ref={ref} className = {styles.Section}>
      {
        vBlog !== undefined  && (                      
        <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
            className={styles.FlexLeftColumn}>             
        <Flex
               direction="row"
               justifyContent="flex-start"
               alignItems="center"
               alignContent="center"
               wrap="nowrap"
               gap="1rem"
               className={styles.FlexRow}>    
               <Button
                    className={styles.Button}
                    variation="primary"
                    onClick={(e) => CreateBlogPost(e)}
                  >
                    Add
                </Button>             
              <Button
                className={styles.Button}
                variation="primary"
                onClick={(e) => GetBlogPosts(e)}
              >
                Refresh
            </Button> 
        </Flex>                
        <Table className={styles.Table}   highlightOnHover={true}>   
          <TableHead>          
            <TableRow className={styles.TableHeaderRow}>
              <TableCell className={styles.TableHeaderRowCells} >Actions</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Title</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Status</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Updated</TableCell> 
            </TableRow>                                      
          </TableHead>       
          
              {
               vBlogPosts !== undefined && vBlog !== undefined && vBlogPosts.map((vBlogPost) => (                      
                  (<BlogPost   key={vBlogPost.UpdateDateTime}  Users={Users} BlogPost={vBlogPost}/>)        
                  ))
              }                    
        </Table>   
        </Flex>                 
      )}      
    </section>
  )
}

export default BlogPosts;


