
import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead
  } from '@aws-amplify/ui-react'; 
import TwitterStatsRow from './TwitterStatsRow';  
import styles from './TwitterStatsTable.module.css';


const TwitterStatsTable = (props) => {       

  TwitterStatsTable.propTypes = {
      TwitterStats: PropTypes.array,
      RhyteItUser: PropTypes.array
      };

    let debugConsoleOutPut = true

    const [vTwitterStats, setvTwitterStats] = useState(props.TwitterStats)  
    const [vKeyUpdate, setvKeyUpdate] = useState(Date.now())   
  

    useEffect(() => {

     // console.log("TwitterStatsTable.useEffect[].props.TwitterStats.length:  ",props.TwitterStats.length) 
      //console.log("vRhyteItAccounts.useEffect[].props.RhyteItUser:  ",props.RhyteItUser) 
      //console.log("vRhyteItAccounts.useEffect[].Time:  ", RhyteItAccount.PostDateTime.substr(RhyteItAccount.PostDateTime.indexOf("T")+1, 5))
      //onsole.log("vRhyteItAccounts.useEffect[].indexOf:  ", RhyteItAccount.PostDateTime.indexOf(":00"))


      },[])  

      

  

      useEffect(() => {


     //   console.log("TwitterStatsTable.useEffect[vTwitterStats].vTwitterStats:  ",vTwitterStats.length) 
       // setvKeyUpdate(Date.now()) 
  
        },[vTwitterStats])        


             

  return (  
      <Table className={styles.RhyteItTable}   highlightOnHover={true}>   
        <TableHead className={styles.TableHead}>          
            <TableRow className={styles.TableHeaderRow}>
              <TableCell className={styles.TableFollowersTopHeaderRowCells} colSpan="5" >Followers</TableCell> 
            </TableRow>       
            <TableRow className={styles.TableHeaderRow}>
              <TableCell className={styles.TableRecordIdHeaderRowCells} >Date</TableCell>   
              <TableCell className={styles.TableRecordIdHeaderRowCells}                   
                         >id</TableCell> 
              <TableCell className={styles.TableFollowersHeaderRowCells}                   
                         >Actual
              </TableCell> 
              <TableCell className={styles.TableFollowersHeaderRowCells}                 
                          >#</TableCell> 
              <TableCell className={styles.TableFollowersHeaderRowCells}                
                         >%</TableCell> 
            </TableRow>                                      
          </TableHead>       
              {
                props.TwitterStats && props.TwitterStats.map((TwitterStat) => (         
                <TwitterStatsRow  key={TwitterStat.id } TwitterStat={TwitterStat}/>  
                  ))
              }                    
        </Table>
    
  )
}

export default TwitterStatsTable;


