
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  TableBody,
  Button,
  Link,
  Text
  } from '@aws-amplify/ui-react';

  import  { }  from '../../graphql/queries'
  import  { deleteSBSMNews, updateSBSMNews, createSBSMNews}  from '../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';
  
  
  import styles from './SBSMNewsAdminRow.module.css';  
  import { UserContext} from '../../BusinessLogic/Hooks/UserContext';

const SBSMNewsRow = (props) => {       

  SBSMNewsRow.propTypes = {
      SBSMArticle: PropTypes.object
      };
  
    const client = generateClient();
    const {Users, setUsers} = useContext(UserContext);   
    const [ErrorMessage, setErrorMessage] = useState("")
    let ErrorMessageStr = ""
    let debugConsoleOutPut = true
    let vHoursAgo = 0    
    const [vSBSMNewsArticles, setvSBSMNewsArticles] = useState(props.SBSMArticle)   
    vHoursAgo = Date.now() - vSBSMNewsArticles.PubDate    
    var today = new Date();
    var vPubDate = new Date(vSBSMNewsArticles.PubDate);
    var diffMs = (today - vPubDate); 
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    var vDateAgo  = diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes ago";



    let vNewScore = Number(vSBSMNewsArticles.Score)

    var vSubRow = Number(vNewScore.toFixed(4)) + " | " + vDateAgo + " | " + vSBSMNewsArticles.UpVotes + " votes up | " + vSBSMNewsArticles.DownVotes + " votes down"
    useEffect(() => {

      //console.log("SBSMNewsRow.useEffect[].props.SBSMNewsSBSMNewsArticle:  ",props.SBSMArticle) 
       // console.log("SBSMNewsRow.useEffect[].props.SBSMArticle:  ",props.SBSMArticle) 
       // console.log("SBSMNewsRow.useEffect[].vHoursAgo:  ",vHoursAgo) 
       // console.log("SBSMNewsRow.useEffect[].Date.now() :  ",Date.now() ) 
       // console.log("SBSMNewsRow.useEffect[].vSBSMNewsArticles.PubDate :  ",vSBSMNewsArticles.PubDate ) 
       // console.log("SBSMNewsRow.useEffect[].vDateAgo :  ",vDateAgo ) 


      },[])  

    useEffect(() => {

          //console.log("SBSMNewsRow.useEffect[].vSBSMNewsArticles:  ",vSBSMNewsArticles) 
          setErrorMessage("")

      },[vSBSMNewsArticles])             
    
    function SBSMNewsRow_Delete_Row(event,data) {
      try {

           // console.log("SBSMNewsRow.SBSMNewsRow_Delete_Row.Users[0].UserId    :  ",Users[0].UserId    ) 

            if (Users[0].UserId === "edwardhawke@rhyteit.com") {
          
            var today = new Date();

            if (vSBSMNewsArticles.SBSMStatus !== "New") {

              const DeleteSBSMNewsArticle = {
                id: vSBSMNewsArticles.id,
                UserId: vSBSMNewsArticles.UserId,
                _version: vSBSMNewsArticles._version
              }
              


              const ReturnMessage = client.graphql({
                query: deleteSBSMNews,
                variables: {
                  input: DeleteSBSMNewsArticle
                }
              });  

            }
          
            setvSBSMNewsArticles("")
            ErrorMessageStr = "Item deleted at: " + today.toLocaleString("en-US")
            setErrorMessage(ErrorMessageStr)       
              
          } else {

            ErrorMessageStr = "You do not have permission to delete this item"
            setErrorMessage(ErrorMessageStr)  

          }
      
          } catch (err) { 

          ErrorMessageStr = "vSBSMNewsArticles.SBSMNewsRow_Delete_Row.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }               
      
    }   

    function RecalculateScore() {
      try {

           // console.log("SBSMNewsRow.SBSMNewsRow_Delete_Row.Users[0].UserId    :  ",Users[0].UserId    ) 
           let dtPubDate = new Date(vSBSMNewsArticles.PubDate)
                
           let vAgeInHours = Math.abs(Date.now() - dtPubDate) / 36e5;                     

           
           let NewScore = ((vSBSMNewsArticles.UpVotes+1)/(vSBSMNewsArticles.DownVotes+2))*(1/((vAgeInHours+2)^1.5))
           vSBSMNewsArticles.Score = Number(NewScore.toFixed(4))
           setvSBSMNewsArticles(vSBSMNewsArticles) 
      
          } catch (err) { 

          ErrorMessageStr = "vSBSMNewsArticles.SBSMNewsRow_Delete_Row.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }               
      
    }   

    function SBSMNewsRow_UpVote(event, data) {
      try {              
          
          if (debugConsoleOutPut === true) {
            //console.log("vSBSMNewsArticles.PostDate_handleChange.event.target.value: ",event.target.value)
          }     

          vSBSMNewsArticles.UpVotes += 1

          setvSBSMNewsArticles(vSBSMNewsArticles) 

          RecalculateScore()
          
          SaveSBSMNewsRow()

          //console.log("vSBSMNewsArticles.SBSMNewsArticle_handleChange.vSBSMNewsArticles[elementPos].SBSMNewsArticle: ",vSBSMNewsArticles[elementPos].SBSMNewsArticle)

        } catch (err) { 
          console.error("vSBSMNewsArticles.PostDate_handleChange.Error:  ".err, err.stack); 
      }        
    }  

    function SBSMNewsRow_DownVote(event, data) {
      try {              
          
          if (debugConsoleOutPut === true) {
            //console.log("vSBSMNewsArticles.PostDate_handleChange.event.target.value: ",event.target.value)
          }     

          vSBSMNewsArticles.DownVotes += 1

          setvSBSMNewsArticles(vSBSMNewsArticles) 
          
          RecalculateScore()
          
          SaveSBSMNewsRow()

          //console.log("vSBSMNewsArticles.SBSMNewsArticle_handleChange.vSBSMNewsArticles[elementPos].SBSMNewsArticle: ",vSBSMNewsArticles[elementPos].SBSMNewsArticle)

        } catch (err) { 
          console.error("vSBSMNewsArticles.PostDate_handleChange.Error:  ".err, err.stack); 
      }        
    }  

    function SaveSBSMNewsRow() {
      try {           
          var today = new Date();
          setErrorMessage("")
          
          var vUpdateDateTime = new Date().toISOString()


          vSBSMNewsArticles.UpdateDateTime = vUpdateDateTime       
          vSBSMNewsArticles.UpdateName = Users[0].UserId       

          if (vSBSMNewsArticles.SBSMNewsArticleStatus === "NEW") {
            vSBSMNewsArticles.SBSMNewsArticleStatus = "Active"


            const ReturnMessage = client.graphql({
              query: createSBSMNews,
              variables: {
                input: vSBSMNewsArticles
              }
            });  

            ErrorMessageStr = "Article saved at: " + today.toLocaleString("en-US")
            setErrorMessage(ErrorMessageStr)

          } else {

            
            const ReturnMessage = client.graphql({
              query: updateSBSMNews,
              variables: {
                input: vSBSMNewsArticles
              }
            });  

            ErrorMessageStr = "Article saved at: " + today.toLocaleString("en-US")
            setErrorMessage(ErrorMessageStr)

        }
          
          setvSBSMNewsArticles(vSBSMNewsArticles) 
          setErrorMessage(ErrorMessageStr)
     

        } catch (err) { 
          ErrorMessageStr = ("vSBSMNewsArticles.SaveSBSMNewsRow.Error: ", err, err.stack)
          console.error(ErrorMessageStr)
          setErrorMessage(ErrorMessageStr)
      }        
    }    


  return (
      <TableBody>         
            <TableRow  className={styles.TableRowBackgroundTitle}  key={vSBSMNewsArticles.id}>         
              <TableCell colSpan="1"> 
                  <Button
                        loadingText=""
                        ariaLabel=""
                        className={styles.RedditTitleButtons}
                        onClick={(e) => SBSMNewsRow_UpVote(e, vSBSMNewsArticles)}
                      >
                      ▲
                  </Button>   
              </TableCell>                
              <TableCell colSpan="2">                  
                  <Link className={styles.Link} href={vSBSMNewsArticles.URL} isExternal={true} colSpan="3">
                  {vSBSMNewsArticles.Title}
                  </Link>   
              </TableCell>                       
            </TableRow>  
            <TableRow  className={styles.TableRowBackground} >                
            <TableCell colSpan="1">    
                <Button
                        loadingText=""
                        ariaLabel=""
                        className={styles.RedditTitleButtons}
                        onClick={(e) => SBSMNewsRow_DownVote(e, vSBSMNewsArticles)}
                      >
                      ▼
                  </Button> 
                  
              </TableCell>     
              <TableCell as="td" colSpan="1">   
              <Text className={styles.SubRow} >
                {vSubRow}
                </Text>  
              </TableCell>              
              <TableCell as="td"  className={styles.RhyteItMenuCell}> 
                  <Button
                    loadingText=""
                    ariaLabel=""
                    className={styles.RedditTitleButtons}
                    onClick={(e) => SBSMNewsRow_Delete_Row(e, vSBSMNewsArticles)}
                  >
                  D
                  </Button>                 
            </TableCell>                         
          </TableRow>  
      {
        ErrorMessage.length > 0 &&             
          <TableRow  className={styles.ErrorMessageRow}>              
            <TableCell 
              colSpan="3">      
              {ErrorMessage}
            </TableCell>               
          </TableRow>  
      }               
      </TableBody>
    
  )
}

export default SBSMNewsRow;