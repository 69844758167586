import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import {ErrorBoundary} from 'react-error-boundary'
import {motion as m} from "framer-motion";
import { useNavigate, useLocation } from "react-router-dom";

import  {listAudienceGeoInfos, getOnlyFansProspectingProfiles, listMeetings}  from '../../../graphql/queries'
import { generateClient } from 'aws-amplify/api';

import { UserContext} from '../../../BusinessLogic/Hooks/UserContext';
import {ValidateUser} from '../../../BusinessLogic/UserManagement';

import {
  Authenticator,
  Flex,
  Text

} from '@aws-amplify/ui-react';
import styles from './ApplicantDetail.module.css';
import styled from 'styled-components'
import ApplicantDetailTable from './ApplicantDetailTable';
import ApplicantPageViewTable from './ApplicantPageViewTable';
import ApplicantProfileTable from './ApplicantProfileTable';
import ApplicantDetailMeeting from './ApplicantDetailMeeting';

import Header from "../../RhyteItComponents/Header/Header";

function ErrorFallback({error, resetErrorBoundary}) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}
    
const client = generateClient();

const LabelText = styled.div`
                        color: white;
                        font-size: 0.5em !important;
                        `;


const ApplicantDetail = () => {      



        let debugConsoleOutPut = true
        let navigate = useNavigate(); 

        const location = useLocation();
        const { vRhyteItApplicantManagementRow } = location.state;
        const vBreadCrumbItems = [{Path: "/", Title:"Home", isCurrent:false},{Path: "/MainMenuV2", Title:"Menu", isCurrent:false},{Path: "/ApplicantManagement", Title:"Applicants", isCurrent:false},{Path: "/ApplicantDetail", Title:"Applicant", isCurrent:true}]
     

        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)  
        const [PageViewsReturned, setPageViewsReturned] = useState(0)  
        const [PageViewsUpdatedKey, setPageViewsUpdatedKey] = useState(null)  
        const [PageViewsShowTable, setPageViewsShowTable] = useState(false)  
        const [PageViews, setPageViews] = useState([])  
        const [MeetingsUpdatedKey, setMeetingsUpdatedKey] = useState(null)  
        const [MeetingShowTable, setMeetingShowTable] = useState(false)  
        const [Meetings, setMeetings] = useState([])  
        

        const [ProfileUpdatedKey, setProfileUpdatedKey] = useState(null)  
        const [ProfileShowTable, setProfileShowTable] = useState(false)  
        const [Profile, setProfile] = useState([])          

        const {Users, setUsers} = useContext(UserContext);   
        const [RhyteItApplicant, setRhyteItApplicant] = useState(vRhyteItApplicantManagementRow)  
        const [EngagmentScores, setEngagmentScores] = useState([])  

        useEffect(() => {

          (async function() {
            try {  

              //console.log("ApplicantDetailMeeting.useEffect[].vRhyteItApplicantManagementRow:  ",vRhyteItApplicantManagementRow)

              if (Users === undefined) {                    
                //console.log("ApplicantDetailMeeting.useEffect[].Users:  ",Users)
                 let vIsUserSubscribed = await ValidateUser()
                 setUsers(vIsUserSubscribed)
              }
                //console.log("ApplicantDetailMeeting.useEffect[].Users:  ",Users)    

                } catch (err) {
                  console.error('RhyteItApplicants.useEffect[].ERROR: ', err, err.stack); 
              }
            }
          )();   
      

        },[]) 

        useEffect(() => {
            
         // //console.log("ApplicantDetailMeeting.useEffect[RhyteItApplicant].RhyteItApplicant:  ",RhyteItApplicant)     
                
          GetPageViews()
          GetMeeting()

        },[RhyteItApplicant]) 

        useEffect(() => {
            
          // //console.log("ApplicantDetailMeeting.useEffect[RhyteItApplicant].RhyteItApplicant:  ",RhyteItApplicant)     
                 
           GetProfile()  
 
         },[Users]) 

         useEffect(() => {
  
           if (Profile.id !== undefined) {
            GetOnlyFansProspectingProfilesFromS3()
          }                  
 
         },[Profile]) 

         useEffect(() => {            
          //console.log("ApplicantDetailMeeting.useEffect[EngagmentScores].EngagmentScores: ",EngagmentScores.length);
          setProfileUpdatedKey(Date.now())               
          setProfileShowTable(true) 

        },[EngagmentScores])   
        
        useEffect(() => {            
          if (Meetings.id !== undefined) {
            console.log("ApplicantDetail.useEffect[Meetings].Meetings.id: ",Meetings.id);
            setMeetingsUpdatedKey(Date.now())               
            setMeetingShowTable(true) 
          }

        },[Meetings])            
        
                

        async function GetPageViews() {
          try {
                    setErrorMessage("")
                    setPageViews([])

                    const variables = { 
                      filter: {
                                IPv4: {
                                        eq: RhyteItApplicant.ipAddress
                                    }
                              }
                    };              
                    
          
                    const AudienceGeoInfoData2  = await client.graphql({
                      query: listAudienceGeoInfos,
                      variables: variables
                    });
   

                    //console.log("AudienceGeoInfoData2.data.listAudienceGeoInfos: ",AudienceGeoInfoData2.data.listAudienceGeoInfos);
                    //console.log("AudienceGeoInfoData2.data.listAudienceGeoInfos.items: ",AudienceGeoInfoData2.data.listAudienceGeoInfos.items);
                    if (AudienceGeoInfoData2.data.listAudienceGeoInfos.items.length > 0) {
                        //console.log("AudienceGeoInfoData2.data.listAudienceGeoInfos.items.length: ",AudienceGeoInfoData2.data.listAudienceGeoInfos.items.length);
                        setPageViewsReturned(AudienceGeoInfoData2.data.listAudienceGeoInfos.items.length)
                        const AudienceGeoInfoData1 = AudienceGeoInfoData2.data.listAudienceGeoInfos.items 

                        setPageViews(AudienceGeoInfoData1)       
                        setPageViewsUpdatedKey(Date.now())            
                        setPageViewsShowTable(true)
           
                  }

          } catch (err) { 
                          console.error('ApplicantDetailMeeting.GetApplicantDetailMeeting.ERROR: ', err, err.stack); 
                      }
        }      

        async function GetProfile() {
          try {
            if (Users !== undefined) {
                    setErrorMessage("")
                    //console.log("ApplicantDetailMeeting.GetProfile.Users: ",Users);


                        let vOnlyFansName = RhyteItApplicant.SocialMediaProfile.replace("https://www.onlyfans.com/", '')
                        vOnlyFansName = RhyteItApplicant.SocialMediaProfile.replace("http://www.onlyfans.com/", '')
                        vOnlyFansName = RhyteItApplicant.SocialMediaProfile.replace("https://onlyfans.com/", '')

                        const variables = {
                          id: vOnlyFansName, 
                          UserId: Users
                        };
                  
                        const OFProfileExists  = await client.graphql({
                            query: getOnlyFansProspectingProfiles,
                            variables: variables
                          });
        

                        if (OFProfileExists.data.getOnlyFansProspectingProfiles != null) {
                          setProfile(OFProfileExists.data.getOnlyFansProspectingProfiles) 
                        }
                    }


          } catch (err) { 
                          console.error('ApplicantDetailMeeting.GetProfile.ERROR: ', err, err.stack); 
                      }
        }   

        async function GetMeeting() {
          try {   
            
            console.log("ApplicantDetailMeeting.GetMeeting.GetMeeting: ",RhyteItApplicant);                 

            const variables = { 
              filter: {
                ipAddress: {
                                eq: RhyteItApplicant.ipAddress
                            }
                      }
            };              
            
  
            const MeetingsResponse  = await client.graphql({
              query: listMeetings,
              variables: variables
            });


              console.log("ApplicantDetails.GetMeeting.MeetingsResponse.data.listMeetings.items: ",MeetingsResponse.data.listMeetings.items);
              //console.log("AudienceGeoInfoData2.data.listAudienceGeoInfos.items: ",AudienceGeoInfoData2.data.listAudienceGeoInfos.items);
              if (MeetingsResponse.data.listMeetings.items.length > 0) {
                  //console.log("AudienceGeoInfoData2.data.listAudienceGeoInfos.items.length: ",AudienceGeoInfoData2.data.listAudienceGeoInfos.items.length);
                  
                  const MeetingItems = MeetingsResponse.data.listMeetings.items[0]

                  setMeetings(MeetingItems)       
             
    
            }


          } catch (err) { 
                          console.error('ApplicantDetailMeeting.GetMeeting.ERROR: ', err, err.stack); 
                      }
        }           
        
        async function GetOnlyFansProspectingProfilesFromS3() {
          try {
                  
                        //console.log("ApplicantDetailMeeting.GetOnlyFansProspectingProfilesFromS3.Profile.id : ",Profile.id );
                        const url = `https://rhyteitfiles.s3.us-west-2.amazonaws.com/RhyteItEngagementScore.json`

                        const fetchResponse = await fetch(url);
                        let OnlyFansProspectingProfilesData1 = await fetchResponse.json();
                        //console.log("ApplicantDetailMeeting.GetOnlyFansProspectingProfilesFromS3.OnlyFansProspectingProfilesData1.length: ",OnlyFansProspectingProfilesData1.length);
                        let OFItem = OnlyFansProspectingProfilesData1.filter((ele,index) => ele.id=== Profile.id );
                        //let OFItem = OnlyFansProspectingProfilesData1.filter((ele,index) => ele.id==="dkddkdk" );

                        if (OFItem.length > 0) {

                        //console.log("ApplicantDetailMeeting.GetOnlyFansProspectingProfilesFromS3.OFItem[EngagementScore]: ", Math.floor(OFItem[0]["EngagementScore"],2));

                        let newEngagementScore = [{
                                                    EngagementScore: Math.floor(OFItem[0]["EngagementScore"],2),
                                                    Rank: OFItem[0]["Rank"]
                                                  }]

                        setEngagmentScores(newEngagementScore)

                      }
                      
  
          } catch (err) { 
                          console.error('ApplicantDetailMeeting.GetOnlyFansProspectingProfilesFromS3.ERROR: ', err, err.stack); 
                      }
        }          

    return ( 
    <m.div
      key="RhyteItApplicantDetail"
      initial={{y: "100%"}}
      animate={{y: "0%"}}
      transition={{duration:0.75, ease: "easeOut"}}
      exit={{ opacity: 1 }}
     >
      <Authenticator  className={styles.AuthenticatorLogin} socialProviders={[]} hideSignUp={true}>
        {({ signOut, user }) => (   
          <div >
            <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                >   
                 <Flex
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      alignContent="flex-start"
                      wrap="nowrap"
                      gap="3rem"
                      className={styles.GridContainer}
                    >                                       
                        <Header Title="Applicant" SubTitle={RhyteItApplicant.id} BreadCrumbItems ={vBreadCrumbItems} pageSource="RhyteItApplicantDetail"/> 
                        <ApplicantDetailTable RhyteItApplicant={RhyteItApplicant}/>                                                                                             
                        {Meetings !== undefined && (<ApplicantDetailMeeting   key={MeetingsUpdatedKey}  Meetings={Meetings} />)  }                                                                                                                                                       
                        {ProfileShowTable  && Profile !== undefined && EngagmentScores.length > 0 && (<ApplicantProfileTable   key={ProfileUpdatedKey}  Profile={Profile} EngagmentScores={EngagmentScores}/>)  }                                                                                        
                        {PageViewsShowTable && PageViews.length > 0 && (<ApplicantPageViewTable   key={PageViewsUpdatedKey}  PageViews={PageViews} />)  }        
                        <Text className={styles.MessageBox}>{ErrorMessage}</Text>  
                  </Flex> 
            </ErrorBoundary>
          </div>   
        )}         
      </Authenticator>  
    </m.div>                      

    )

  }
  

export default ApplicantDetail;