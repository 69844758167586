
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Text,
  TableBody,
  TextField,  
  Button,
  Image
  } from '@aws-amplify/ui-react';
  import { Amplify } from 'aws-amplify';
  import { Link } from "react-router-dom";

  import  {}  from '../../graphql/queries'
  import  { }  from '../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';
  
  import styles from './CustomerPurchaseRow.module.css';  
  import { UserContext,UserAuthenticatedContext,UserSubcribedContext} from '../../BusinessLogic/Hooks/UserContext';  

const CustomerPurchaseRow = (props) => {       

  CustomerPurchaseRow.propTypes = {
      RhyteItCustomerPurchaseRow: PropTypes.object,
      Environment: PropTypes.string,
      CustomerEmailVerified: PropTypes.string
      };
  
    const client = generateClient();
    const {Users, setUsers} = useContext(UserContext);   
    const [ErrorMessage, setErrorMessage] = useState("")   
    const [vEnvironment, setvEnvironment] = useState(props.Environment)   
    const [CustomerEmailVerified, setCustomerEmailVerified] = useState(props.CustomerEmailVerified);   
    let ErrorMessageStr = ""
    let debugConsoleOutPut = true
    
    const [PreSignedURL, setPreSignedURL] = useState("")  
    const [DownloadButtonEnabled, setDownloadButtonEnabled] = useState(true)  

    const [message, setMessage] = useState('');

    const [vRhyteItCustomerPurchaseRows, setvRhyteItCustomerPurchaseRows] = useState([props.RhyteItCustomerPurchaseRow])      

    useEffect(() => {

        if (CustomerEmailVerified === "Success") {
          setDownloadButtonEnabled(false)
        } else {
          setDownloadButtonEnabled(true)
        }  

        //console.log("CustomerPurchaseRow.useEffect[].props.RhyteItCustomerPurchaseRow:  ",props.RhyteItCustomerPurchaseRow) 
      
      },[])  

    
    function PurchaseCustomerPurchaseRow(event, data) {
      try {           
        
          setErrorMessage("")
          GetS3PresignedURL()

        } catch (err) { 
          ErrorMessageStr = ("vRhyteItCustomerPurchaseRows.EditCustomerPurchaseRow.Error: ", err, err.stack)
          console.error(ErrorMessageStr)
          setErrorMessage(ErrorMessageStr)
      }        
    }       
   
    async function GetS3PresignedURL() {
      try {              
          

          let variables = {
            PurchaseId: vRhyteItCustomerPurchaseRows[0].id,                      
            PurchaseEmail: vRhyteItCustomerPurchaseRows[0].Email,                  
            Product: vRhyteItCustomerPurchaseRows[0].Product,                 
            Environment: vRhyteItCustomerPurchaseRows[0].Environment
          }
    
          let GetS3PresignedURLResponse = await client.graphql({
            query: GetS3PresignedURL,
            input: variables
          });        


        let PresignedURLJson = JSON.parse(GetS3PresignedURLResponse["data"]["GetS3PresignedURL"]);


        setPreSignedURL(PresignedURLJson["PreSignedURL"])
        setErrorMessage("")
        window.open(PresignedURLJson["PreSignedURL"], '_blank');
        

        } catch (err) { 
          console.error("CustomerPurcchaseRow.GetS3PresignedURL.Error:  ",err, err.stack); 
      }        
    }   


  return (
      <TableBody>  
      {
          vRhyteItCustomerPurchaseRows.length > 0 && vRhyteItCustomerPurchaseRows.map((vRhyteItCustomerPurchaseRow) => (  

          
          <TableRow  className={styles.TableRowBackground} key={vRhyteItCustomerPurchaseRow.id}>
            <TableCell className={styles.TableRowHeadingData} >  
                <Text  className={styles.TextField}>
                                  {vRhyteItCustomerPurchaseRow.Product}
                </Text>  
              </TableCell>                                 
            <TableCell as="td"  className={styles.RhyteItMenuCell}> 
                  <Button
                    loadingText=""
                    ariaLabel=""
                    className={styles.RowButtons}
                    onClick={(e) => PurchaseCustomerPurchaseRow(e, vRhyteItCustomerPurchaseRow)}
                  >
                  Download
                </Button>                                 
            </TableCell>                                                      
          </TableRow>  
        ))
      }     
      {
        ErrorMessage.length > 0 &&             
          <TableRow  className={styles.ErrorMessageRow}>              
            <TableCell 
              className={styles.TextField}
              colSpan="3">      
              {ErrorMessage}
            </TableCell>               
          </TableRow>  
      }                        
      </TableBody>
    
  )
}

export default CustomerPurchaseRow;

