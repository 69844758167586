
import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead,
  Image
  } from '@aws-amplify/ui-react'; 
import PrivateKey from './DigitalMarketPlaceRow';
import styles from './DigitalMarketPlaceTable.module.css';


const DigitalMarketPlaceTable = (props) => {       

  DigitalMarketPlaceTable.propTypes = {
      RhyteItDigitalMarketPlace: PropTypes.array,
      Environment: PropTypes.string,
      RhyteItUser: PropTypes.array
      };

    let debugConsoleOutPut = true

  

    const [RhyteItDigitalMarketPlaceTable, setRhyteItDigitalMarketPlaceTable] = useState(props.RhyteItDigitalMarketPlace)   
    const [vEnvironment, setvEnvironment] = useState(props.Environment)   
    const [ShowTable, setShowTable] = useState(false);

    useEffect(() => {

      //console.log("DigitalMarketPlaceTable.useEffect[].props.RhyteItDigitalMarketPlace:  ",props.RhyteItDigitalMarketPlace) 
      setRhyteItDigitalMarketPlaceTable(props.RhyteItDigitalMarketPlace)
      //console.log("DigitalMarketPlaceTable.useEffect[].RhyteItDigitalMarketPlaceTable ",RhyteItDigitalMarketPlaceTable) 
      //console.log("vPrivateKeys.useEffect[].props.RhyteItUser:  ",props.RhyteItUser) 
      //console.log("vPrivateKeys.useEffect[].Time:  ", PrivateKey.PostDateTime.substr(PrivateKey.PostDateTime.indexOf("T")+1, 5))
      //onsole.log("vPrivateKeys.useEffect[].indexOf:  ", PrivateKey.PostDateTime.indexOf(":00"))
      if (Object.keys(RhyteItDigitalMarketPlaceTable).length > 0) {
        setShowTable(true)
      }



    },[])  



  return (  
    <div>
    <Image
        className={styles.Image}
        src="https://cambridge-ai.center/wp-content/uploads/2022/07/logo-stripe.png"
        objectFit="initial"
        backgroundColor="initial"
        onClick={() => alert('📸 Say cheese!')}
      />    
      <Table className={styles.Table}   highlightOnHover={true}>   
          <TableHead>          
            <TableRow className={styles.TableHeaderRow}>
              <TableCell className={styles.TableHeaderRowCellsWide} colSpan="2" >Product</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Price (USD)</TableCell> 
              <TableCell className={styles.TableHeaderRowCellsActions} colSpan="1" >Actions</TableCell> 
            </TableRow>                                      
          </TableHead>       
          
              {
                ShowTable && RhyteItDigitalMarketPlaceTable.length > 0 &&  RhyteItDigitalMarketPlaceTable && RhyteItDigitalMarketPlaceTable.map((RhyteItDigitalMarketPlaceRow) => (                      
                  (<PrivateKey   key={RhyteItDigitalMarketPlaceRow.id}  RhyteItDigitalMarketPlaceRow={RhyteItDigitalMarketPlaceRow} Environment={vEnvironment}/>)        
                  ))
              }                    
        </Table>
        </div>
  )
}

export default DigitalMarketPlaceTable;


