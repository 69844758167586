import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import {ErrorBoundary} from 'react-error-boundary'
import Header from "../../HeaderMenu/LandingPageNavBar";
import  {listProducts}  from '../../../graphql/queries'
import { generateClient } from 'aws-amplify/api';

import {motion as m} from "framer-motion";
import getStripe from '../../Stripe/getStripe';
import DMPDCustomerApplause from '../DMPDCustomerApplause';
import Footer from '../../RhyteItComponents/Footer/Footer';

import {
  Button ,
  Flex,
  TextField,
  Image,
  Text

} from '@aws-amplify/ui-react';
import styles from './TurboFanBoost.module.css';
import { SEO } from '../../SEO/SEO';


function ErrorFallback({error, resetErrorBoundary}) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

const TurboFanBoost = () => {      

        const client = generateClient();

        let debugConsoleOutPut = true


        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   
        const [RecordUpdated, setRecordUpdated] = useState(false)  
        const [UserEmail, setUserEmail] = useState("")  
        
        
        const [InvalidEmail, setInvalidEmail] = useState(false)  
  
        const [RhyteItProduct, setRhyteItProduct] = useState() 
        const [Environment, setEnvironment] = React.useState(process.env.REACT_APP_ENV);  
        const [PaymentLine, setPaymentLine] = useState("")  
        const [DescriptionHtml, setDescriptionHtml] = useState("")  
        const [ThankYou, setThankYou] = useState(false)  

        useEffect(() => {


        //  console.log("TurboFanBoost.useEffect[].RhyteItProduct:  ",RhyteItProduct);

          (async function() {
            try {  

                GetTurboFanBoost()               

                window.scrollTo(0, 0);

                } catch (err) {
                  console.error('RhyteItProducts.useEffect[].ERROR: ', err, err.stack); 
              }
            }
          )();   
      

        },[]) 

        useEffect(() => {


     //     console.log("TurboFanBoost.useEffect[RhyteItProduct].RhyteItProduct:  ",RhyteItProduct);

          if(RhyteItProduct !== undefined) {
                        
              let vPaymentDescription = "Monthly subscription of $" + RhyteItProduct.Price + " (Cancel Anytime)"  

              setPaymentLine(vPaymentDescription)

              Dashboard(RhyteItProduct.Description)

          }

        },[RhyteItProduct]) 

        async function GetTurboFanBoost() {
          try {
                    setErrorMessage("")

  
             //       console.log("TurboFanBoost.GetTurboFanBoost.Start") 
                    
                    let vTurbFanBoost = ""

                    if (Environment === "TEST") {
                      vTurbFanBoost = "TurboFanBoost Test"
                    } else {
                      vTurbFanBoost = "TurboFanBoost"
                    }
                      
                
                    const variables = { 
                      filter: {
                                  Product: {
                                        eq: vTurbFanBoost
                                    }
                              }
                    };              
                    
          
                    let TurboFanBoostData2  = await client.graphql({
                      query: listProducts,
                      variables: variables
                    });
                     
  
             //       console.log("TurboFanBoost.GetTurboFanBoost.TurboFanBoostData2.data.listProducts.items:  ",TurboFanBoostData2.data.listProducts.items)  
  
                    if (TurboFanBoostData2.data.listProducts.items.length > 0) {
                        setRhyteItProduct( TurboFanBoostData2.data.listProducts.items[0])    
            
                  }
  
          } catch (err) { 
                          console.error('TurboFanBoost.GetTurboFanBoost.ERROR: ', err, err.stack); 
                      }
          }            
  

        async function handleCheckout() {

          
          //console.log("TurboFanBoost.handleCheckout.UserEmail: ",UserEmail)
          //console.log("TurboFanBoost.handleCheckout.RhyteItProduct.PaymentMode: ",RhyteItProduct.PaymentMode)


          if (UserEmail !== "") {

              if (UserEmail.indexOf("@") > 2 && UserEmail.indexOf(".") > UserEmail.indexOf("@")+1 && UserEmail.indexOf(" ") < 0) {
                        
                        setInvalidEmail(false)
                        setThankYou(true)
                        window.scrollTo(0, 0);

                        
                        const stripe = await getStripe(Environment);                                  
                        
                        //console.log("TurboFanBoost.handleCheckout.stripe: ",stripe)
                        //console.log("TurboFanBoost.handleCheckout.PriceId:  ",RhyteItProduct.PriceId)     

                        if (stripe !== "ERROR") {

                          const { error } = await stripe.redirectToCheckout({
                            lineItems: [
                              {
                                price: RhyteItProduct.PriceId,
                                quantity: 1,
                              },
                            ],
                            mode: RhyteItProduct.PaymentMode,
                            successUrl: RhyteItProduct.SuccessURL,
                            cancelUrl: RhyteItProduct.CancelURL,
                            customerEmail: UserEmail.replaceAll(' ', ''),
                          });

                          console.warn(error.message);

                      } else {

                        ErrorMessageStr = ("Something went wrong with checkout process. Its not you. Its us. Sigh....")
                        setErrorMessage(ErrorMessageStr)

                      }
                    } 

                          
              } else  {
                setInvalidEmail(true)
                let ErrorMessageStr = "Please enter a valid email"
                setUserEmail(ErrorMessageStr)
              }
        }   
           
           
        function UserEmail_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
                //console.log("AdultContentCreatorSearch.FindField_handleChange.event.target.value: ",event.target.value)
              }
    

              var ErrorMessageStr = ""   
              setErrorMessage(ErrorMessageStr)
              setUserEmail(event.target.value)
    
    
    
            } catch (err) { 
              console.error("AdultContentCreatorSearch.FindField_handleChange.Error:  ".err, err.stack); 
          }        
        }   

        const Dashboard = (pDescription) => {  
          

            let DescriptionArray = pDescription.split('\\n');

            let DescriptionList = [];

            DescriptionArray.forEach((Description, index) => {

              let vDescription = Description.indexOf("<br>")

              if (vDescription > -1) {
                DescriptionList.push(<br/>);

              } else {
                DescriptionList.push(<div className={styles.TextAreaField}>{Description}</div>);

              }
              
            });            

            //console.log("TurboFanBoost.TurboFanBoost.DescriptionList: ",DescriptionList[0]["props"]['children'])

            let DescriptionHtmlString = <div>{DescriptionList}</div>

            setDescriptionHtml(DescriptionHtmlString)
        }


    return ( 
    <m.div
      key="RhyteItTurboFanBoost"
      initial={{y: "100%"}}
      animate={{y: "0%"}}
      transition={{duration:0.75, ease: "easeOut"}}
      exit={{ opacity: 1 }}
     >
     <SEO
         title="TurbFanBoost"
         description='Post to your social accounts while you sleep'
         name='RhyteIt'
         type='product' />               

            <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                >   
                {RhyteItProduct !== undefined && (
                  <Flex
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    alignContent="flex-start"
                    wrap="wrap"
                    className={styles.GridContainer}
                    >                       
                      
                      <Header  className={styles.Header} pageSource="TurboFanBoost"/> 
                      <div className={styles.Space}></div>                                                      
                      <Image
                        className={styles.ProductImage}
                        src={RhyteItProduct.ProductImage}
                        objectFit="initial"
                        backgroundColor="initial"
                        onClick={() => alert('📸 Buy Now!')}
                      />   
                                                             
                      <Text className={styles.ProductName}>
                      {RhyteItProduct.Product}
                      </Text>  
                      
                      {ThankYou === false && (
                      <Flex
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        alignContent="flex-start"
                        wrap="wrap"
                        >                         
                        <Flex
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          alignContent="center"
                          wrap="wrap"
                          gap="1rem"
                          className={styles.FlexRow}
                          >                           
                          <Flex
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="stretch"
                                alignContent="flex-start"
                                wrap="wrap"
                                gap="1rem"
                                >   
                                  <Flex
                                      direction="row"
                                      justifyContent="flex-start"
                                      alignItems="center"
                                      alignContent="center"
                                      wrap="wrap"
                                      gap="1rem"
                                      className={styles.FlexRow}
                                      >                                                           
                                    <Text>
                                      {DescriptionHtml}
                                    </Text> 
                                  </Flex>  
                                  {RhyteItProduct.PaymentMode !=="free" && (
                                  <Flex
                                      direction="row"
                                      justifyContent="flex-start"
                                      alignItems="center"
                                      alignContent="center"
                                      wrap="wrap"
                                      gap="1rem"
                                      className={styles.FlexRow}
                                      > 
                                      <Text className={styles.TextFieldLabelThin}>
                                          Price ($USD):
                                      </Text>                                                              
                                      <Text className={styles.TextFieldThin}>
                                          {PaymentLine}
                                      </Text>  
                                  </Flex> 
                                  )} 
                                  <Flex
                                      direction="row"
                                      justifyContent="flex-start"
                                      alignItems="center"
                                      alignContent="center"
                                      wrap="wrap" 
                                      gap="1rem"
                                      className={styles.FlexRow}
                                      >             
                                    <Text className={styles.TextFieldLabelThin}>
                                        Email:
                                    </Text>                                
                                    <TextField label="Search" 
                                      labelHidden={true} 
                                      className= {InvalidEmail === false ? styles.TextField : styles.InvalidEmailField}
                                      defaultValue={UserEmail}                                                
                                      value={UserEmail}
                                      placeholder='<enter email here>'
                                      onChange={ (e) => UserEmail_handleChange(e, UserEmail) }
                                      />   
                                  </Flex>                                                   
                          </Flex>  
                        </Flex>                              
                        <Flex
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          alignContent="center"
                          wrap="wrap"
                          gap="1rem"
                          className={styles.FlexRow}
                          >        
                          <Button 
                            className={styles.metallicbutton}                                       
                            onClick={ (e) => handleCheckout(e, RhyteItProduct) }
                            isDisabled={ThankYou}
                            variation="primary">
                            {ThankYou === false ? RhyteItProduct.PaymentMode === "free" ? "Free" : "Buy Now!": "Enjoy!"}
                          </Button>
                        {RhyteItProduct.PaymentMode !=="free" && (   
                          <Image
                            className={styles.Image}
                            src="https://cambridge-ai.center/wp-content/uploads/2022/07/logo-stripe.png"
                            objectFit="initial"
                            backgroundColor="initial"
                            onClick={() => alert('📸 BUY NOW!')}
                          />     )     }                               
                        </Flex>     
                        <Flex
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          alignContent="center"
                          wrap="wrap"
                          gap="1rem"
                          className={styles.FlexRow}
                          >  
                            <div className={styles.MessageBox}>{ErrorMessage}</div>                                             
                        </Flex>    
                        <Flex
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          alignContent="center"
                          wrap="wrap"
                          gap="1rem"
                          className={styles.FlexRow}
                          >  
                          <DMPDCustomerApplause Page={RhyteItProduct.Product} ProductId={RhyteItProduct.id}/>                                            
                        </Flex>        
                        <Flex
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          alignContent="center"
                          wrap="wrap"
                          gap="1rem"
                          className={styles.FlexRow}
                          >  
                          <Footer/>                                     
                        </Flex>                     
                      </Flex>     
                      )}    
                      {ThankYou === true && (  
                        <Flex
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        alignContent="flex-start"
                        wrap="wrap"
                        >     
                            <Text className={styles.CongratulationsText}>
                                Time to get you subscribers!! :)
                            </Text>   
                            <Text className={styles.PleaseWaitText}>
                                Please wait!
                                                            </Text>    
                            <Text className={styles.NextStepText}>
                            ...while we transfer you to Stripe for payment processing.
                            </Text>                           

                        </Flex>
                      )}                                                                                       
                  </Flex> 
                )}
                  <Image
                    alt="Amplify logo"
                    src="/images/bee.gif"
                    className={styles.HobnilTheBee}
                    onClick={() => alert('📸 Say cheese!')}
                  />                   
            </ErrorBoundary>
           
        )  
    </m.div>                      

    )

  }
 

export default TurboFanBoost;