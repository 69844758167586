import PropTypes from "prop-types";
import React, { useState, useEffect, useContext, useRef } from "react";

import { listSMMADAccounts } from "../../../graphql/queries";
import { createSMMADAccounts , createSMMADAccountGroups} from "../../../graphql/mutations";
import { generateClient } from "aws-amplify/api";

import Header from "../../RhyteItComponents/Header/Header";
import {
  UserContext,
  CognitoGroupContext,
} from "../../../BusinessLogic/Hooks/UserContext";
import {
  GetGroupRoleAccess,
  RhyteItLogging,
  ReturnUserToLandingPage,
} from "../../../BusinessLogic/UserManagement";
import { fetchAuthSession } from "aws-amplify/auth";
import { motion as m } from "framer-motion";

import {
  Button,
  Flex,
  Divider,
  Label,
  TextField,
  SelectField,
  Text,
  TableCell,
  Table,
  TableBody,
  TableRow,
  Fieldset,
  CheckboxField,
} from "@aws-amplify/ui-react";
import styles from "./SMMADNewAccountSetup.module.css";

const SMMADNewAccountSetup = (props) => {
  SMMADNewAccountSetup.propTypes = {
    ParentAccount:PropTypes.bool,
    AccountGroup:PropTypes.array,
  };

  const client = generateClient();
  const vBreadCrumbItems = [
    { Path: "/", Title: "Home", isCurrent: false },
    { Path: "/MainMenuCustomer", Title: "Menu", isCurrent: false },
    { Path: "/SMMADNewAccountSetup", Title: "TurboFanBoost", isCurrent: true },
  ];

  var ErrorMessageStr = "";
  const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr);

  const [vAccountSaved, setvAccountSaved] = useState(false);
  const [vAccountType, setvAccountType] = useState(false);
  const [vAccountActive, setvAccountActive] = useState(false);
  const [vParentAccount, setvParentAccount] = useState(props.ParentAccount);
  const [vAccountGroup, setvAccountGroup] = useState(props.AccountGroup);

  const [vTurboFanAccount, setvTurboFanAccount] = useState({
    "id": null,
    "Account": null,
    "AccountType": null,
    "MasterAccount": props.ParentAccount,
    "AccountGroup": props.AccountGroup.AccountGroup,
    "CampaignsActive": true,
    "SMMADAccountStatus": "New Parent Account Created",
    "Active": true,
    "Weight": 0,
    "PostedCount": 0,
    "TwitterLastPostDate": null,
    "TwitterPostingFrequencyHours": 999,
    "ZScore": 0,
    "PurchaseId": null,
    "ProductId": null,
    "TwitterAccount": null,
    "TwitterPassword": null,
    "TwitterConsumerKey": null,
    "TwitterConsumerSecret": null,
    "TwitterBearerToken": null,
    "TwitterAccessToken": null,
    "TwitterAccessTokenSecret": null,
    "TwitterClientId": null,
    "TwitterClientSecret": null,
    "TwitterAppName": null,
    "TwitterAppId": null,
    "TwitterStatus": "NEW",
    "CreationName": null,
    "CreationDateTime": null,
    "UpdateName": null,
    "UpdateDateTime": null,
  });

  const { Users, setUsers } = useContext(UserContext);
  const { CognitoGroup, setCognitoGroup } = useContext(CognitoGroupContext);


  useEffect(() => {
    window.scrollTo(0, 0);

    console.log("SMMADNewAccountSetup.useEffect[].Start: ", props.AccountGroup)
    
  }, []);

  useEffect(() => {
    (async function () {
      try {
        if (CognitoGroup !== undefined) {
          let GetGroupRoleAccessResponse = await GetGroupRoleAccess(
            CognitoGroup,
            "RhyteItSMMADUserGroup"
          );
  
        } else {
          const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};

          if (idToken !== undefined) {
            setUsers(idToken.payload.email);
            setCognitoGroup(accessToken["payload"]["cognito:groups"]);
          } else {
            ReturnUserToLandingPage(Users, CognitoGroup);
          }
        }
      } catch (err) {
        RhyteItLogging(
          Users,
          "SMMADNewAccountSetup",
          "useEffect[CognitoGroup]",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    })();
  }, [CognitoGroup]);



  async function SaveAccount(pSMMADAccountStatus) {
    try {
      vTurboFanAccount["Account"] = Users
      var vUpdateDateTimeNew = new Date().toISOString();
      var vCreationDateTime = new Date().toISOString();

      let AccountItem = {
        id: vTurboFanAccount["Account"],
        Account: vTurboFanAccount["Account"],
        AccountType: "Customer",
        MasterAccount: vTurboFanAccount["MasterAccount"],
        AccountGroup: vTurboFanAccount["AccountGroup"],
        CampaignsActive: true,
        SMMADAccountStatus: vTurboFanAccount["SMMADAccountStatus"],
        Active: true,
        Weight: vTurboFanAccount["Weight"],
        PostedCount: vTurboFanAccount["PostedCount"],
        TwitterLastPostDate: vCreationDateTime,
        TwitterPostingFrequencyHours: vTurboFanAccount["TwitterPostingFrequencyHours"],
        ZScore: vTurboFanAccount["ZScore"],

        PurchaseId: vTurboFanAccount["PurchaseId"],
        ProductId: vTurboFanAccount["ProductId"],
        TwitterAccount: vTurboFanAccount["TwitterAccount"],
        TwitterPassword: vTurboFanAccount["TwitterPassword"],
        TwitterConsumerKey: vTurboFanAccount["TwitterConsumerKey"],
        TwitterConsumerSecret: vTurboFanAccount["TwitterConsumerSecret"],
        TwitterBearerToken: vTurboFanAccount["TwitterBearerToken"],
        TwitterAccessToken: vTurboFanAccount["TwitterAccessToken"],
        TwitterAccessTokenSecret: vTurboFanAccount["TwitterAccessTokenSecret"],
        TwitterClientId: vTurboFanAccount["TwitterClientId"],
        TwitterClientSecret: vTurboFanAccount["TwitterClientSecret"],
        TwitterAppName: vTurboFanAccount["TwitterAppName"],
        TwitterAppId: vTurboFanAccount["TwitterAppId"],
        TwitterStatus: vTurboFanAccount["TwitterStatus"],
        CreationName: Users,
        CreationDateTime: vCreationDateTime,
        UpdateName: Users,
        UpdateDateTime: vUpdateDateTimeNew,
      };

      console.log("SMMADNewAccountSetup.SaveRow.AccountItem:  ",AccountItem);

      const createSMMADAccountsResponse = await client.graphql({
        query: createSMMADAccounts,
        variables: {
          input: AccountItem,
        },
      });

    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADNewAccountSetup",
        "SaveAccount",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  async function SaveAccountGroup(pSMMADAccountStatus) {
    try {
      vTurboFanAccount["Account"] = Users
      var vUpdateDateTimeNew = new Date().toISOString();
      var vCreationDateTime = new Date().toISOString();

      let AccountItem = {
        id: vTurboFanAccount["Account"] + " " + vTurboFanAccount["AccountGroup"],
        AccountGroup: vTurboFanAccount["AccountGroup"],
        AccountType: "Customer",
        ParentAccount: vTurboFanAccount["Account"],
        TwitterPostedCount: 0,
        TwitterWeight: 0,
        TwitterZScore: 0,
        TwitterLastPostDate: vCreationDateTime,
        SMMADAccountGroupStatus: "New",
        Active: true,
        CreationName: Users,
        CreationDateTime: vCreationDateTime,
        UpdateName: Users,
        UpdateDateTime: vUpdateDateTimeNew,
      };

      console.log("SMMADNewAccountGroup.SaveRow.AccountItem:  ",AccountItem);

      const createSMMADAccountGroupsResponse = await client.graphql({
        query: createSMMADAccountGroups,
        variables: {
          input: AccountItem,
        },
      });

    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADNewAccountGroup",
        "SaveAccountGroup",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }


  function TwitterAccount_handleChange(event, data) {
    try {


      vTurboFanAccount.TwitterAccount = event.target.value;
     

      setvTurboFanAccount(vTurboFanAccount);
    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADNewAccountSetup",
        "TwitterAccount_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function TwitterPassword_handleChange(event, data) {
    try {
      vTurboFanAccount.TwitterPassword = event.target.value;
      // //console.log("RhyteItAccountDetail.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)

      setvTurboFanAccount(vTurboFanAccount);
    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADNewAccountSetup",
        "TwitterPassword_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function TwitterConsumerKey_handleChange(event, data) {
    try {
      vTurboFanAccount.TwitterConsumerKey = event.target.value;
      // //console.log("RhyteItAccountDetail.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)

      setvTurboFanAccount(vTurboFanAccount);
    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADNewAccountSetup",
        "TwitterConsumerKey_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function TwitterConsumerSecret_handleChange(event, data) {
    try {
      vTurboFanAccount.TwitterConsumerSecret = event.target.value;
      // //console.log("RhyteItAccountDetail.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)

      setvTurboFanAccount(vTurboFanAccount);
    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADNewAccountSetup",
        "TwitterConsumerSecret_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function AccountGroup_handleChange(event, data) {
    try {
      vTurboFanAccount.AccountGroup = event.target.value;
      // //console.log("RhyteItAccountDetail.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)

      setvTurboFanAccount(vTurboFanAccount);
    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADNewAccountSetup",
        "AccountGroup_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }
 

  function TwitterAccessToken_handleChange(event, data) {
    try {
      vTurboFanAccount.TwitterAccessToken = event.target.value;
      // //console.log("RhyteItAccountDetail.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)

      setvTurboFanAccount(vTurboFanAccount);
    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADNewAccountSetup",
        "TwitterAccessToken_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function TwitterAccessTokenSecret_handleChange(event, data) {
    try {
      vTurboFanAccount.TwitterAccessTokenSecret = event.target.value;
      // //console.log("RhyteItAccountDetail.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)

      setvTurboFanAccount(vTurboFanAccount);
    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADNewAccountSetup",
        "TwitterAccessTokenSecret_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function TwitterPostingFrequencyHours_handleChange(event, data) {
    try {

      console.log("SMMADNewAccountSetup.TwitterPostingFrequencyHours_handleChange.vTurboFanAccount: ", vTurboFanAccount)
      console.log("SMMADNewAccountSetup.TwitterPostingFrequencyHours_handleChange.event.target.value: ",event.target.value)
      vTurboFanAccount.TwitterPostingFrequencyHours = parseInt(event.target.value);
      setvTurboFanAccount(vTurboFanAccount);
    } catch (err) {
      console.error(
        "SMMADNewAccountSetup.TwitterPostingFrequencyHours_handleChange.ERROR: ",
        err,
        err.stack
      );
    }
  }

  return (
    <m.div
      key="Search"
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      exit={{ opacity: 1 }}
    >
      
    {vAccountSaved === false && (
      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="center"
        wrap="nowrap"
        gap="1rem"
        className={styles.FlexColumn}
      >
        <legend className={styles.legend}>New Parent Account</legend>
          <Table className={styles.Table}>
            <TableBody>                      
              <TableRow>
                <TableCell className={styles.RowLabelColumn}>
                  <Label className={styles.Label}>RhyteIt Account</Label>
                </TableCell>
                <TableCell>
                <Text className={styles.Text}>
                  {Users}  
              </Text>  
                </TableCell>
              </TableRow>    
              <TableRow>
                <TableCell className={styles.RowLabelColumn}>         
                  <Label   
                    className={styles.Label}
                    >
                      Twitter Username
                  </Label>
                </TableCell>
                <TableCell>
                  <TextField className={styles.TextField} 
                    name="vTurboFanAccount.id"                           
                    defaultValue={vTurboFanAccount.TwitterAccount}
                    onChange={ (e) => TwitterAccount_handleChange(e, vTurboFanAccount) } />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.RowLabelColumn}>         
                  <Label   
                    className={styles.Label}
                    >
                      Twitter Password
                  </Label>
                </TableCell>
                <TableCell>
                  <TextField className={styles.TextField} 
                    name="vTurboFanAccount.EmailAccount"                           
                    defaultValue={vTurboFanAccount.TwitterPassword}
                    onChange={ (e) => TwitterPassword_handleChange(e, vTurboFanAccount) } />
                </TableCell>
              </TableRow>               
              <TableRow>
                <TableCell className={styles.RowLabelColumn}>
                  <Label className={styles.Label}>Twitter Consumer Key</Label>
                </TableCell>
                <TableCell>
                  <TextField
                    className={styles.TextField}
                    name="vTurboFanAccount.TwitterConsumerKey"
                    defaultValue={vTurboFanAccount.TwitterConsumerKey}
                    onChange={(e) =>
                      TwitterConsumerKey_handleChange(e, vTurboFanAccount)
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.RowLabelColumn}>
                  <Label className={styles.Label}>
                    Twitter Consumer Secret
                  </Label>
                </TableCell>
                <TableCell>
                  <TextField
                    className={styles.TextField}
                    name="vTurboFanAccount.TwitterConsumerSecret"
                    defaultValue={vTurboFanAccount.TwitterConsumerSecret}
                    onChange={(e) =>
                      TwitterConsumerSecret_handleChange(e, vTurboFanAccount)
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow className={styles.RowLabelColumn}>
                <TableCell className={styles.RowLabelColumn}>
                  <Label className={styles.Label}>Twitter Access Token</Label>
                </TableCell>
                <TableCell>
                  <TextField
                    className={styles.TextField}
                    name="vTurboFanAccount.TwitterAccessToken"
                    defaultValue={vTurboFanAccount.TwitterAccessToken}
                    onChange={(e) =>
                      TwitterAccessToken_handleChange(e, vTurboFanAccount)
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow className={styles.RowLabelColumn}>
                <TableCell className={styles.RowLabelColumn}>
                  <Label className={styles.Label}>
                    Twitter Access Token Secret
                  </Label>
                </TableCell>
                <TableCell>
                  <TextField
                    className={styles.TextField}
                    name="vTurboFanAccount.TwitterAccessTokenSecret"
                    defaultValue={vTurboFanAccount.TwitterAccessTokenSecret}
                    onChange={(e) =>
                      TwitterAccessTokenSecret_handleChange(
                        e,
                        vTurboFanAccount
                      )
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.RowLabelColumn}>
                  <Label className={styles.Label}>Posting Freq. (hrs)</Label>
                </TableCell>
                <TableCell>
                  <TextField
                    className={styles.TextField}
                    name="vTurboFanAccount.TwitterPostingFrequencyHours"
                    defaultValue={
                      vTurboFanAccount.TwitterPostingFrequencyHours
                    }
                    onChange={(e) =>
                      TwitterPostingFrequencyHours_handleChange(
                        e,
                        vTurboFanAccount
                      )
                    }
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Flex
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            alignContent="center"
            wrap="nowrap"
            gap="3rem"
          >
            <Button
              className={styles.Button}
              variation="primary"
              onClick={(e) => SaveAccount("Saved")}
            >
              Save
            </Button>
          </Flex>
      </Flex>
  )}       
    </m.div>
  );
};

export default SMMADNewAccountSetup;
