
import React, { useState, useEffect } from 'react'
//import isUrlHttp from 'is-url-http';
//import './Application.css'; 
import {
    TextField, 
    PasswordField,
    Text,
    CheckboxField,
    Button
  } from '@aws-amplify/ui-react';
import PropTypes from 'prop-types';
import styles from './Application.module.css';  
import {listApplicants} from '../../graphql/queries'
import {createAudienceGeoInfo, createApplicants}  from '../../graphql/mutations'
import StatusMessageBox from "../../ui-components/StatusMessageBox";
import HeaderMenu from "../HeaderMenu/NavBarClean";
import MenuBackground from '../Menu/MenuBackground';
import {motion as m} from "framer-motion";
import axios from "axios";
import {uuidv4} from "../../BusinessLogic/UtilitiesManagement";
import { get_uuidv4 } from "../../BusinessLogic/uuidv4";

import Footer from '../RhyteItComponents/Footer/FooterClean';
import { generateClient } from 'aws-amplify/api';

const Application = (props) => {

    Application.propTypes = {
        UserEmail: PropTypes.string,
        UserId: PropTypes.string,
        UserName: PropTypes.string
      };

    const client = generateClient();
    const [FirstTouch, setFirstTouch] = useState(true);      
    const [ip, setIP] = useState('');  
    let vIPAddress = ""      


    const [ValidURLFlag, setValidURLFlag] = useState(false); 
    const [NewUser, setNewUser] = useState({
                                id: "",
                                UserId: "",
                                UserEmail: "",
                                UserLastName: "",
                                UserFirstName: "",
                                UserDialCode: "",
                                UserPhone: "",
                                SocialMediaProfile:  "",
                                ipAddress:  "",
                                PrivacyAcknowledgement: "",
                                VerificationCode: "",
                                VerificationStatus: "",
                                Application:  "",
                                Active:  "",
                                CreationName:  "",
                                UpdateName:  "",
                                CreationDateTime:  ""})
                                                                           

    let [Acknowledgement, setAcknowledgement] = useState(false)   
    
    var ErrorMessageStr = ""
    const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)  

    useEffect(() => {

        getData()
        setErrorMessage("")
                
    },[])   

    useEffect(() => {

        //console.log('Application.useEffect[ip].ip:', ip);
        //setErrorMessage(ip)
                
    },[ip])       
    
    const getData = async () => {
        //console.log("LandingPageV4.handleMouseOver.FirstTouch - Before: ", FirstTouch);
        if (FirstTouch === true) {
            //console.log("LandingPageV4.handleMouseOver.FirstTouch: ", FirstTouch);
            setFirstTouch(false);    
            //console.log("LandingPageV4.handleMouseOver.FirstTouch2: ", FirstTouch);
  
            const res = await axios.get('https://geolocation-db.com/json/')
            //console.log("LandingPageV4.getData.res.data: ",res.data );
            //console.log("LandingPageV4.getData.res: ",res );
            setIP(res.data.IPv4)
  
            let DynamoUUID =  await uuidv4()
  
            vIPAddress = res.data.IPv4
  
          if (res.data.IPv4 !== "98.199.173.171") {
  
            let SaveSubReddit = {
            id: DynamoUUID,
            UserId: "edwardhawke@rhyteit.com",
            Page: "Application",
            IPv4: res.data.IPv4,
            city: res.data.city,
            country_code: res.data.country_code,
            country_name: res.data.country_name,
            latitude: res.data.latitude,
            longitude: res.data.longitude,
            postal: res.data.postal,
            state: res.data.state,
            CreationName:  "Application",
            UpdateName: "Application"
            }                      
  
            //console.log("LandingPageV4.getData.SaveSubReddit: " ,SaveSubReddit)
  
            try{
  
                let createAudienceGeoInfoResponse = await client.graphql({                    
                    query: createAudienceGeoInfo,
                    variables:{input: {SaveSubReddit
                    }
                  }
                });
                
                //console.log("LandingPageV4.getData.createAudienceGeoInfoResponse: " ,createAudienceGeoInfoResponse)
  
            } catch (err)  {
            ErrorMessageStr = 'Application.getData.ERROR: ' + err + err.stack
            setErrorMessage(ErrorMessageStr)
            console.error(ErrorMessageStr); 
            }
  
          }
        }
  
    }  

    function UserEmail_handleChange(event, data) {
        try {              
            
            // //console.log('UserEmail_handleChange.data.email:', data.email);

            // //console.log('UserEmail_handleChange.event.target.value:', event.target.value);

            NewUser.UserId = event.target.value;
            
            setNewUser(NewUser)

            } catch (err) { //console.log('error TeamsUserId_handleChange')
            console.error(err, err.stack); 
        }        
    }  
    
    function SocialMediaProfile_handleChange(event, data) {
        try {              
            
            //console.log('UserEmail_handleChange.data.email:', data.email);

            //console.log('SocialMediaProfile_handleChange.event.target.value:', event.target.value);

            NewUser.SocialMediaProfile = event.target.value;
            
            setNewUser(NewUser)

            } catch (err) { //console.log('error TeamsUserId_handleChange')
            console.error(err, err.stack); 
        }        
    }  
    
    async function AcknowledgementChanged(e, data) {
        try {


            //console.log("Application.AcknowledgementChanged.data: ", data)
            //console.log("Application.AcknowledgementChanged.e: ", e)
            //console.log("Application.AcknowledgementChanged.Acknowledgement: ", Acknowledgement)

            setAcknowledgement(Acknowledgement===false ? true : false)
            
            //console.log("Application.AcknowledgementChanged.Acknowledgement2: ", Acknowledgement)

    } catch (err) { //console.log('error fetching Users')
                    console.error(err, err.stack); 
                }
    }          

    async function ProcessApplicant(e, data) {
        try {

            //console.log("Application.ProcessApplicant.data:   ",data)
            let ProcessApplicantErrorMessage = ""
            //console.log("Application.ProcessApplicant.NewUser:   ", NewUser)

            let IPAddressAlreadyExists = await CheckIPAddress()

            if ( IPAddressAlreadyExists === false) {

                let EmailAddressAlreadyExists = await CheckEmailAddress(NewUser.UserId)

                if (EmailAddressAlreadyExists === true) {
                        
                    ProcessApplicantErrorMessage = "You have already applied."
                    setErrorMessage(ProcessApplicantErrorMessage)
    
                } else if (EmailAddressAlreadyExists === false) {
                    
                    User_Add(NewUser);
                }

            }

    } catch (err) { //console.log('error fetching Users')
                    console.error(err, err.stack); 
                }
    }      

    async function CheckIPAddress() {
        try {

            let vCheckIPAddressReturnMessage = false
            //console.log("Application.CheckIPAddress.NewUser: ", NewUser )
            //console.log("Application.CheckIPAddress.NewUser[0].email: ", NewUser.UserId , " - ip address: ", ip)

            const variables = {
                filter: {
                    ipAddress: {
                        eq: ip
                        //eq: "2222"                    
                    }
                }
              };

            const ApplicantIPAddressCheck  = await client.graphql({
                query: listApplicants,
                variables: variables
              });

            const vIPAddressCheck = ApplicantIPAddressCheck.data.listApplicants.items          
            
           //console.log("Application.CheckIPAddress.vIPAddressCheck: ", vIPAddressCheck)

            if (vIPAddressCheck.length > 0) {

                vCheckIPAddressReturnMessage = true
                let fetchUserIdErrorMessage = "You have already applied."
                setErrorMessage(fetchUserIdErrorMessage)

            }
            else if (vIPAddressCheck.length === 0) {                
                vCheckIPAddressReturnMessage = false
            }         
        
    
            //console.log("Application.CheckIPAddress.vCheckIPAddressReturnMessage: ", vCheckIPAddressReturnMessage)
            return vCheckIPAddressReturnMessage

        } catch (err) { //console.log('error fetching Users')
                        console.error(err, err.stack); 
                    }
    }   

    async function CheckEmailAddress(pApplicantEmail) {
        try {

            let vCheckEmailAddressReturnMessage = false
            //console.log("Application.CheckEmailAddress.pApplicantEmail: ", pApplicantEmail)

            const variables = {
                filter: {
                    UserId: {
                        eq: pApplicantEmail
                    }
                }
              };

            const ApplicantEmailAddressCheck  = await client.graphql({
                query: listApplicants,
                variables: variables
              });            


     
            const vEmailAddressCheck = ApplicantEmailAddressCheck.data.listApplicants.items          
            
            if (vEmailAddressCheck.length > 0) {

                vCheckEmailAddressReturnMessage = true
                let fetchUserIdErrorMessage = "You have already applied."
                setErrorMessage(fetchUserIdErrorMessage)

            }
            else if (vEmailAddressCheck.length === 0) {                
                vCheckEmailAddressReturnMessage = false
            }         
        
    
            return vCheckEmailAddressReturnMessage

        } catch (err) { //console.log('error fetching Users')
                        console.error(err, err.stack); 
                    }
    }   

    async function CheckURL(pSBSMURL) {
        try {

            let vCheckEmailAddressReturnMessage = false
            //console.log("Application.CheckURL.pSBSMURL: ",pSBSMURL)

            //vCheckEmailAddressReturnMessage = isUrlHttp(pSBSMURL);

            //console.log("Application.CheckURL.vCheckEmailAddressReturnMessage:  ", vCheckEmailAddressReturnMessage) 
            return vCheckEmailAddressReturnMessage

        } catch (err) { //console.log('error fetching Users')

                        console.error(err); 
                        console.error( 'Application.CheckURL.ERROR:  ', err, err.stack); 
                        setErrorMessage(ErrorMessageStr)                        
                    }
    }  

    async function User_Add(pNewUser) {
        try {

            let ReturnMessage = ""
            //console.log("Application.User_Add.pNewUser:  ", pNewUser)
            //console.log("Application.User_Add.DynamoUUID:  ", DynamoUUID)
            //console.log("Application.User_Add.pNewUser.UserId:  ", pNewUser.UserId)
            //console.log("Application.User_Add.pNewUser.SocialMediaProfile.length    :  ", pNewUser.SocialMediaProfile.length )
            let ValidURL = await ValidateURL(pNewUser.SocialMediaProfile)
            //console.log("Application.User_Add.ValidURL    :  ", ValidURL )
            

            if (ValidURL === true) {
                
                    setValidURLFlag(true)       

                    let vApplicant = {
                        id: pNewUser.UserId,
                        UserId: pNewUser.UserId,
                        UserLastName: "",
                        UserFirstName: "",
                        ipAddress: ip,
                        UserPhone: "+18005555555",
                        UserEmail: pNewUser.UserId,
                        VerificationCode: get_uuidv4(),
                        VerificationStatus: "Unverified",
                        SocialMediaProfile: pNewUser.SocialMediaProfile,
                        PrivacyAcknowledgement: Acknowledgement,
                        EmailTemplate: "Welcome Gift",
                        Application: "New",
                        InOnlyFansProspectingProfile: false,
                        CreationName: pNewUser.UserId,
                        UpdateName: pNewUser.UserId,
                        Active: true
                    }      

                    //let arr = CollabortionToolStatusSchedulers.concat(NewCollabortionToolStatusSchedule);
                    setNewUser(vApplicant);
                    //console.log("Application.User_Add.vApplicant: ",vApplicant)

                        try {

                            const ReturnMessage = await client.graphql({
                                query: createApplicants,
                                variables: {
                                  input: vApplicant
                                }
                              });             
                            //console.log("Application.User_Add.ReturnMessage: ",ReturnMessage)
                            if (ReturnMessage.data.createApplicants.CreationDateTime.length > 0 ) {

                                var vUpdateDateTime = new Date().toISOString()

                                ErrorMessageStr = "Thank you for your application " + ReturnMessage.data.createApplicants.UserId +  ". We look forward to working with you." 
                                setErrorMessage(ErrorMessageStr)
                                
                            }                    

                        } catch (err) { 

                            console.error(err); 
                            ErrorMessageStr = 'Application.User_Add.Mutations.createApplicants.ERROR:  ' + err.errors[0].message + ' - ' + err.lineNumber
                            console.error(ErrorMessageStr); 
                            setErrorMessage(ErrorMessageStr)
                    }     

        }

        if (pNewUser.SocialMediaProfile.length === 0) {
            ErrorMessageStr = "Please enter your adult content profile url"
            console.error(ErrorMessageStr); 
            setErrorMessage(ErrorMessageStr)
            
            setValidURLFlag(false)       

        }




        } catch (err) {   
                ErrorMessageStr = 'Application.User_Add.ERROR:  ' + err 
                console.error(ErrorMessageStr); 
                setErrorMessage(ErrorMessageStr)
        }        
    }     

    async function ValidateURL(pSBSMURL) {
        try {
                let ValidURLResponse = false

                //console.log("Application.ValidateURL.pSBSMURL: ",pSBSMURL)

                let ValidURLScore = pSBSMURL.indexOf("onlyfans.com/") > -1 ? 1 : 0
                ValidURLScore += pSBSMURL.indexOf("fanvue.com/") > -1 ? 1 : 0
                ValidURLScore += pSBSMURL.indexOf("fansly.com/") > -1 ? 1 : 0
                ValidURLScore += pSBSMURL.indexOf("chaterbate.com/") > -1 ? 1 : 0
                ValidURLScore += pSBSMURL.indexOf("sheer.com/") > -1 ? 1 : 0
                ValidURLScore += pSBSMURL.indexOf("onesly.com/") > -1 ? 1 : 0

                
                //console.log("Application.ValidateURL.ValidURLScore: ",ValidURLScore)

                if (ValidURLScore > 0) {

                    ValidURLResponse = true     
                    setValidURLFlag(true)              

                } else {
                        ErrorMessageStr = "Please a valid adult content profile url: onlyfans.com/pinkbunny, sheer.com/lambgirl"
                        setErrorMessage(ErrorMessageStr)
                        setValidURLFlag(false)
            
                }

                return ValidURLResponse

            } catch (err) { //console.log('error fetching Users')
    
                            console.error(err); 
                            console.error( 'Application.CheckURL.ERROR:  ', err, err.stack); 
                            setErrorMessage(ErrorMessageStr)                        
                        }
        }  

    return (
        <m.div
                key="LandingPageV2"
                initial={{y: "100%"}}
                animate={{y: "0%"}}
                transition={{duration:0.75, ease: "easeOut"}}
                exit={{ opacity: 1 }}
               >
            <MenuBackground className={styles.MenuBackground} GradientStopColor1="#0000" GradientStopColor2="#000"  GradientStopColor3="#777"   GradientStopColor4="#8880"/> 
            <HeaderMenu pageSource="Application"/> 
            
            <div className = {styles.SignUpFormDiv}>
                <span className = {styles.TopHeader}/>

                <div  className={styles.IntroductionContainer}>  
                    <Text className = {styles.Introduction}>
                        Please provide us with your email and business URL and we will contact you to set up an appointment to review our services.
                    </Text>    
                    <Text className = {styles.Introduction}>
                        You may unsubscribe to our emails at any time.  
                    </Text>                                                                                                       
                </div>                                                                                     
                <div className = {styles.SignUpContentSection}>
                    <div className = {styles.SignUpContentSectionCenter}>
                       
                        <div className = {styles.SignUpContentSectionCenterPadded}>
                            <div className = {styles.SignUpContentSectionCenterCreateAccount}>
                                 
                                <div className={styles.SignUpContentSectionCenterLeftColumn}>
                                    <div className={styles.SignUpFormContainer}> 
                                        <div className = {styles.HeaderLine}/>                            
                                    </div> 
                                    <form className = {styles.SignUpForm}>
                                        <div  className = {styles.SignUpFormContent}>                                              
                                                <div className={styles.SignUpFormContainer}>
                                                    <TextField  
                                                        className={styles.SignUpFormEmailField}
                                                        type="email" 
                                                        color="white"
                                                        isRequired={true}  
                                                        placeholder="Please enter your email"  
                                                        defaultValue={NewUser.UserId}
                                                        label={
                                                            <Text 
                                                                className={styles.SignUpFormLabel}>
                                                                Email
                                                                <Text as="span" fontSize="0.8rem" color="#CCCBCA">
                                                                    {' '}
                                                                    (required)
                                                                </Text>                                                      
                                                            </Text>
                                                        }
                                                        name="NewUser.Email" 
                                                        onChange={ (e) => UserEmail_handleChange(e) } 
                                                        />                                                    
                                                </div>                                                                               
                                                <span className={styles.SignUpFormSpan}></span>                                        
                                                <div className={styles.SignUpFormContainer}>
                                                    <TextField  
                                                        className={styles.SignUpFormEmailField}
                                                        type="text" 
                                                        color="white"
                                                        isRequired={true}  
                                                        placeholder="Please enter your business URL"  
                                                        defaultValue={NewUser.SocialMediaProfile}
                                                        label={
                                                            <Text 
                                                            className={styles.SignUpFormLabel}>
                                                            Business URL
                                                                <Text as="span" fontSize="0.8rem" color="#CCCBCA">
                                                                    {' '}
                                                                    (required)
                                                                </Text>  
                                                            </Text>
                                                        }
                                                        name="NewUser.SocialMediaProfile" 
                                                        onChange={ (e) => SocialMediaProfile_handleChange(e) } 
                                                        />                                                    
                                                </div>                                                                               
                                                <span className={styles.SignUpFormSpan}></span>         
                                                <div className={styles.SignUpFormContainer}>
                                                    <div className={styles.AcknowledgeRow}>                                                
                                                        <div className={styles.AcknowledgeCheckboxFieldContainer}>
                                                            <CheckboxField className={styles.Checkbox}
                                                                name="subscribe-controlled"
                                                                checked={Acknowledgement}
                                                                size="small"
                                                                onClick={ (e) => AcknowledgementChanged(e) } 
                                                                />                                                                                    
                                                        </div>                                                                                                   
                                                        <div className={styles.AcknowledgeDescriptionContainerColumn}>
                                                            <span className={styles.AcknowledgeDescriptionColumn}> I certify that I have read the <a  target="_blank" rel="noopener noreferrer" className={styles.AcknowledgeDescriptionColumn} href="/PrivacyPolicy">Privacy Policy.</a>                                               
                                                        </span>
                                                        </div>                      
                                                    </div>
                                                </div>
                                                <div className={styles.SignUpButtonContainer} >
                                                    <Button
                                                        className={styles.CheckOutButton}
                                                        loadingText=""
                                                        onClick={ (e) => ProcessApplicant(e, NewUser) } 
                                                        ariaLabel=""
                                                        isDisabled = {Acknowledgement ? false : true}
                                                        >
                                                        Inquire
                                                    </Button>    
                                                </div>  
                                                <div className={styles.StatusContainer} >
                                                    <StatusMessageBox className={styles.StatusMessageBox} ErrorMessage={ErrorMessage}></StatusMessageBox>  
                                                </div>                                                                                                                                                            
                                        </div>
                                    </form>
                                </div>    
                            </div>     
                        </div>       
                    </div>   
                    <span role="presentation" aria-hidden="true"  className = {styles.ColumnSpan}></span>    
                    <div className = {styles.SignUpContentSectionRight}>
                        <div className = {styles.ValuePropAnimatedBorder}>
                            <div className = {styles.InnerBoxBackGround}>
                            </div> 
                            <div className = {styles.InnerBlackBoxBackGround}>
                                <div className = {styles.ValuePropStatementPadding}>
                                    <h2  className={styles.WhatisYourFreedomWorth}>Thank you for expressing your interest in RhyteIt. It is entrepreneurs like yourself that move the world forward.</h2>
                                    <p className={styles.ParagraphText}>As an entrepreneur, your drive and vision are catalysts for progress and innovation. We appreciate your interest and will keep you informed through email regarding the status of your application.</p>
                                    <p className={styles.ParagraphText}>We are eagerly anticipating the opportunity to welcome you as a valued member of our community, and are committed to supporting the growth and success of your business aspirations.</p>
                                </div> 
                            </div> 
                        </div> 
                    </div>
                </div>    
                <div className={styles.ApplicationContainer}>
                    <Footer/>
                </div>
  
            </div>
  
            </m.div>
                
    );
}

export default Application;    