
import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './Villain.module.css';

import { useInView, useScroll, useTransform, motion, useAnimation } from "framer-motion";
//import {useInView} from "react-intersection-observer";

import {
    Button,
    Flex, 
    Text,
    Image
  } from '@aws-amplify/ui-react';
//import Background from "../../Backgrounds/BackgroundNoLogo";
import Background from "../../Backgrounds/BackgroundAnimationTester";




const Villain  = (props) => {
 
    Villain.propTypes = {
        scrollYProgress: PropTypes.object,
  };

  

  const scale = useTransform(props.scrollYProgress, [0.11, 0.15,0.35, 0.45], [0.2, 1, 1, 0.1]);
  const rotate = useTransform(props.scrollYProgress, [0.11, 0.15,0.35, 0.45], [-10, 0, 0, 10 ]);
  

  const ref= useRef(null);
  const isInView = useInView(ref)


  useEffect(() => {

    

    },[]) 


    useEffect(() => {
        
     //   console.log("Villain.useEffect[isInView].isInView: " ,isInView);


        },[isInView]) 
    


  return (
                          
    
                        <motion.section
                            ref={ref}  
                            style={{ scale, rotate }}
                            className={styles.SectionContainer}>  
                           <motion.div style={{
                                transform: isInView ? "none" : "translateX(-200px)",
                                opacity: isInView ? 1 : 0,
                                transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
                                }}                                        
                                    className={styles.Background} > 
                                <Background/>  
                            </motion.div>   

                            <Flex
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                alignContent="flex-start"
                                wrap="wrap"
                                className={styles.TextFlexColumn}
                            >                          
                                <Flex
                                    direction="row"
                                    justifyContent="left"
                                    alignItems="center"
                                    alignContent="flex-start"
                                    wrap="wrap"
                                    className={styles.HeaderTextRow}
                                    >
                                    <motion.div 
                                        className={styles.MayanNumber}>       
                                        <motion.h1  style={{
                                            transform: isInView ? "none" : "translateX(-200px)",
                                            opacity: isInView ? 1 : 0,
                                            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
                                            }}
                                            className={styles.MayanDot}
                                        >&#x2022;&#x2022;&#x2022;</motion.h1>
                                    </motion.div>
                                    <motion.div >       
                                        <motion.h1  style={{
                                            transform: isInView ? "none" : "translateY(-200px)",
                                            opacity: isInView ? 1 : 0,
                                            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
                                            }} 
                                            className={styles.HeaderText}>Meet your biggest competition</motion.h1>  
                                    </motion.div>
                                </Flex>                                                  
                                <Flex
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    alignContent="center"
                                    wrap="nowrap"
                                    className={styles.HeaderSubTextRow}
                                    >    
                                        <motion.h1  
                                            style={{
                                                transform: isInView ? "none" : "translateY(100px)",
                                                opacity: isInView ? 1 : 0,
                                                transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
                                            }} 
                                            className={styles.HeaderSubText}>
                                            The 4 Million adult content creators vying for your audience's attention and wallets
                                        </motion.h1>        
                                </Flex>     
                                       
                            </Flex>  
                        </motion.section>   

        );
  }

export default Villain