
import React, { useState , useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Button,
  Image,
  TableBody,
  TableRow,
  TableCell,
  CheckboxField,
  TextAreaField,
  TextField,
  Text
  } from '@aws-amplify/ui-react';

  import  {  }  from '../../../graphql/queries'
  import {
    createBlogPosts,
    updateBlogPosts,
    deleteBlogPosts
  } from "../../../graphql/mutations";
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";
  import {
    useInView,
    useScroll,
    useTransform,
    motion,
    useAnimation,
  } from "framer-motion";
  import styles from './BlogPost.module.css';  
  import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
  
function BlogPost(
  props
) {       

  BlogPost.propTypes = {
    BlogPost: PropTypes.object,
    Users: PropTypes.string,
    };

    const client = generateClient();
  
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vBlogPost, setvBlogPost] = useState(props.BlogPost) 
    const [vTitle, setvTitle] = useState()  
    const [vIntro, setvIntro] = useState() 
    const [vPicturePrompt, setvPicturePrompt] = useState() 
    const [vPictureURL, setvPictureURL] = useState() 
    const [vBlogPostHTML, setvBlogPostHTML] = useState() 
    const [vBlogPostString, setvBlogPostString] = useState() 
    const [vBlogHTMLBody, setvBlogHTMLBody] = useState() 
    const [vS3Bucket, setvS3Bucket] = useState() 
    const [vS3Key, setvS3Key] = useState() 
    const [vPostURL, setvPostURL] = useState() 

    const [vPostCommentsString, setvPostCommentsString] = useState() 

    

    const [Users, setUsers] = useState(props.Users)   

    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);

    const [vExpandRow, setvExpandRow] = useState(false)    
    let [vBlogPostActive, setvBlogPostActive] = useState(false)   
    let [vPublishVsHide, setvPublishVsHide] = useState("PUBLISH")  
    
  
        


      useEffect(() => {

        console.log("BlogPost.useEffect[].props.BlogPost:  ",props.BlogPost) 

      },[])  

      useEffect(() => {
      //console.log("BlogPost.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);

        if (isInView) {
          mainControls.start("visible");
        }
      }, [isInView]);      

      useEffect(() => {

       //console.log("BlogPost.useEffect[vBlogPost].vBlogPost: " ,vBlogPost);

        if (vBlogPost !== undefined) {
          
          setvTitle(vBlogPost.Title)
          setvIntro(vBlogPost.Intro)
          setvPicturePrompt(vBlogPost.PicturePrompt)
          setvPictureURL(vBlogPost.PictureURL)
          setvBlogPostHTML(vBlogPost.BlogPostHTML)
          setvBlogPostString(vBlogPost.BlogPostString)
          setvBlogHTMLBody(vBlogPost.BlogHTMLBody)
          setvS3Bucket(vBlogPost.S3Bucket)
          setvS3Key(vBlogPost.S3Key)
          setvPostURL(vBlogPost.PostURL)

          setvBlogPostActive(vBlogPost.Active)
          CreatevPostCommentsString()

        }

      }, [vBlogPost]);   
      
      useEffect(() => {
        //console.log("BlogPost.useEffect[vExpandRow].vExpandRow: " ,vExpandRow);
        }, [vExpandRow]);     

      async function ExpandRow(pExpandRow) {
      try {
        let pExpandRowTmp = pExpandRow 
        //console.log("BlogPost.ExpandRow.pExpandRowTmp: " ,pExpandRowTmp);
        setvExpandRow(!pExpandRowTmp)


          } catch (err) { 

          ErrorMessageStr = "BlogPost.PlayEpisode.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }               

      }     


      async function CreatevPostCommentsString() {
        
  
        try {          

          let vPostCommentsStringTmp = ""
          
          //console.log("BlogPost.CreatevPostCommentsString.vBlogPost: " ,vBlogPost);
          //console.log("BlogPost.CreatevPostCommentsString.vBlogPost[PostComments]: " ,vBlogPost["PostComments"]);
          //console.log("BlogPost.CreatevPostCommentsString.vBlogPost[PostComments].typeof: " ,typeof vBlogPost["PostComments"]);

          if (vBlogPost["PostComments"] !== null && vBlogPost["PostComments"] !== undefined) {

            let vPostCommentsJSON = JSON.parse(vBlogPost["PostComments"]);
            //console.log("BlogPost.CreatevPostCommentsString.vPostCommentsJSON.typeof: " ,typeof vPostCommentsJSON);
  

            vPostCommentsJSON.forEach(vPostComment => {

              vPostCommentsStringTmp += vPostComment.subreddit + ","
    
            })

            vPostCommentsStringTmp = vPostCommentsStringTmp.replace(/.$/, '');
         
            setvPostCommentsString(vPostCommentsStringTmp)        

          }
  
          } catch (err) { 
            RhyteItLogging(Users, "BlogPost", "CreatevPostCommentsString",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
            console.error("BlogPost.CreatevPostCommentsString.Error:  ".err, err.stack); 
        }        
      }    

      async function CreatevPostCommentsJSON() {
        
  
        try {          

          const jsonObject = [];
    
          // Split the string by commas to get the key-value pairs
          const pairs = vPostCommentsString.split(',');
          
          pairs.forEach(pair => {

              let vItem = {
                "subreddit": pair,
              }
              
              // Add the key and value to the JSON object
              jsonObject.push(vItem)
          });

          //console.log("BlogPost.CreatevPostCommentsJSON.jsonObject: " ,jsonObject);
          
          return jsonObject
  
          } catch (err) { 
            RhyteItLogging(Users, "BlogPost", "CreatevPostCommentsString",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
            console.error("BlogPost.CreatevPostCommentsString.Error:  ".err, err.stack); 
        }        
      }   

      async function UpdateRecord(pEvent, pStatus) {
        
  
        try {           
            var today = new Date();
            setErrorMessage("")
            
            var vUpdateDateTime = new Date().toISOString()

            let vPostCommentJSON = await CreatevPostCommentsJSON(vPostCommentsString)
            //console.log("BlogPost.UpdateRecord.vPostCommentJSON: ",vPostCommentJSON)

            const vPostCommentJSONString = JSON.stringify(vPostCommentJSON);

            let BlogPostUpdateItem = {
              "id": vBlogPost["id"],
              "AccountGroup": vBlogPost["AccountGroup"],
              "Account": vBlogPost["Account"],
              "Blog": vBlogPost["Blog"],
              "Title": vTitle,
              "Intro": vIntro,
              "PicturePrompt": vPicturePrompt,
              "PictureURL": vPictureURL,
              "BlogPostHTML": vBlogPostHTML,
              "BlogPostString": vBlogPostString,
              "BlogHTMLBody": vBlogHTMLBody,
              "S3Bucket": vS3Bucket,
              "S3Key": vS3Key,
              "PostURL": vPostURL,
              "ReadCount": vBlogPost["ReadCount"],
              "Likes": vBlogPost["Likes"],
              "Unlikes": vBlogPost["Unlikes"],
              "PostCommentsJSON": vBlogPost["PostCommentsJSON"],
              "Reddit": vBlogPost["Reddit"],
              "RedditSubmissionId": vBlogPost["RedditSubmissionId"],
              "Score": vBlogPost["Score"],
              "Comments": vBlogPost["Comments"],
              "Active": vBlogPostActive,
              "BlogPostStatus": pStatus,
              "CreationName": vBlogPost["CreationName"],
              "UpdateName": Users,
              "CreationDateTime": vBlogPost["CreationDateTime"],
              "UpdateDateTime": vUpdateDateTime
            }
    

             console.log("BlogPost.UpdateRecord.BlogPostUpdateItem: ",BlogPostUpdateItem)
    
              let updateBlogPostsResponse = await client.graphql({                    
                      query: updateBlogPosts,
                      variables:{input: BlogPostUpdateItem}            
                }
              )
            
            console.log("BlogPost.SaveBlogPostEditionToSubscriber.updateBlogPostsResponse: ",updateBlogPostsResponse)
    

            setvBlogPost(updateBlogPostsResponse.data.updateBlogPosts)        
  
          } catch (err) { 
            RhyteItLogging(Users, "BlogPost", "Unsubscribe",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
            console.error("BlogPost.Unsubscribe.Error:  ".err, err.stack); 
        }        
      }    
  

      async function DeleteRow(event,data) {
        try {

                
                setErrorMessage("")   

                const DeleteBlogPostRow = {
                  id: vBlogPost.id,
                  _version: vBlogPost._version
                }
                

                const vDeleteResponse = await client.graphql({
                  query: deleteBlogPosts,
                  variables: {
                    input: DeleteBlogPostRow
                  }
                });  

                ErrorMessageStr = vDeleteResponse
                setErrorMessage(ErrorMessageStr)  
  
                
        
            } catch (err) { 

              RhyteItLogging(Users, "BlogPost", "DeleteRow",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
              console.error("BlogPost.DeleteRow.Error:  ".err, err.stack);       
          }               
        
      }       

      async function vPublishVsHideClicked(e, data) {
        try {

            UpdateRecord(e, vPublishVsHide)
            setvPublishVsHide(vPublishVsHide==="PUBLISH" ? "HIDE" : "PUBLISH")
            
            //console.log("Application.vBlogPostActiveChanged.vBlogPostActive2: ", vBlogPostActive)

    } catch (err) { //console.log('error fetching Users')
                    console.error(err, err.stack); 
                }
    }          

    function vIntro_handleChange(event, data) {
      try {


        let vTmp = event.target.value

        setvIntro(vTmp);

      } catch (err) {
        RhyteItLogging(
          Users,
          "BlogPost",
          "vIntro_handleChange",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }            

    function Field_handleChange(event, data, pButtonTitle) {
      try {

        console.log("BlogPost.Field_handleChange.pButtonTitle: " ,pButtonTitle);

        let vTmp = event.target.value
      

        if (pButtonTitle === "vTitle") {
          setvTitle(vTmp)
        } else if (pButtonTitle === "vIntro") {
          setvIntro(vTmp) 
        } else if (pButtonTitle === "vPicturePrompt") {
          setvPicturePrompt(vTmp)    
        } else if (pButtonTitle === "vPictureURL") {
          setvPictureURL(vTmp)     
        } else if (pButtonTitle === "vBlogPostHTML") {
          setvBlogPostHTML(vTmp)    
        } else if (pButtonTitle === "vBlogPostString") {
          setvBlogPostString(vTmp)    
        } else if (pButtonTitle === "vBlogHTMLBody") {
          setvBlogHTMLBody(vTmp)    
        } else if (pButtonTitle === "vS3Bucket") {
          setvS3Bucket(vTmp)    
        } else if (pButtonTitle === "vS3Key") {
          setvS3Key(vTmp)    
        } else if (pButtonTitle === "vPostURL") {
          setvPostURL(vTmp)    
        } 
        

      } catch (err) {
        RhyteItLogging(
          Users,
          "NewsletterEditionRow",
          "Field_handleChange",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }      
    

      return (
        <TableBody>         
          <TableRow  className={vBlogPost.Active === true ? styles.TableRowActive : styles.TableRowInActive}  key={vBlogPost.id}>    
            <TableCell className={styles.TableCellLeftAlign}> 
                <Button
                      loadingText=""
                      ariaLabel=""
                      className={styles.Button}
                      onClick={(e) => ExpandRow(vExpandRow)}
                    >
                    +
                </Button>   
                <Button
                      loadingText=""
                      ariaLabel=""
                      title="Save blog post"
                      className={styles.Button}
                      //onClick={(e) => UpdateRecord(e)}
                      onClick={(e) => UpdateRecord(e, "UPDATED")}
                      
                    >
                    S
                </Button>   
                <Button
                      loadingText=""
                      ariaLabel=""
                      title="Delete blog post"
                      className={styles.Button}
                      onClick={(e) => DeleteRow(e, vBlogPost)}
                    >
                    D
                </Button>      
                <Button
                      loadingText=""
                      title={vPublishVsHide + " blog post"}
                      ariaLabel=""
                      className={styles.Button}
                      //onClick={(e) => UpdateRecord(e)}
                      onClick={(e) => vPublishVsHideClicked(e)}
                      
                    >
                    {vPublishVsHide.charAt(0)}
                </Button>  
            </TableCell>               
            <TableCell className={styles.TableCellLeftAlign}>      
            <Text className={styles.Text}>{vTitle}</Text>
            </TableCell>   
            <TableCell className={styles.TableCell}>  
              <Text
                        className={styles.Text}
                      >
                      {vBlogPost["BlogPostStatus"]}
              </Text>                 
            </TableCell>                                        
            <TableCell className={styles.TableCell}>  
              <Text  className={styles.TextUpdate}>
              {new Date(vBlogPost.UpdateDateTime).toISOString().slice(0, 10) + " " + new Date(vBlogPost.UpdateDateTime).toISOString().slice(11, 16)}
              </Text>                           
            </TableCell>                                          
          </TableRow>    
          {
            vExpandRow === true &&  (                   
              <TableRow  className={styles.TableRowActive}  key={vBlogPost.UpdateDateTime}>     
                <TableCell className={styles.TableCellLeftAlign} colspan="5">
                <Flex
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  alignContent="flex-start"
                  wrap="nowrap"
                  gap="1rem"
                  className={styles.FlexLeftColumn}> 
                  <Text className={styles.RowHeader}>
                      Title
                  </Text> 
                    <TextField
                              className={styles.TextField}
                              name="vIntro"
                              placeholder="Add Title"
                              defaultValue={vTitle}
                              onChange={(e) => Field_handleChange(e, vTitle, "vTitle")}
                            />     
                    <Text className={styles.RowHeader}>
                    Blog Post HTML
                    </Text> 
                    <TextAreaField
                              className={styles.TextAreaField}
                              name="vIntro"
                              placeholder="Add prompt"
                              defaultValue={ vBlogPostHTML}
                              rows={3}
                              resize="vertical"
                              autoResize
                              onChange={(e) => Field_handleChange(e, vBlogPostHTML, "vBlogPostHTML")}
                            />    
                    <Text className={styles.RowHeader}>
                    Blog Post String
                    </Text> 
                    <TextAreaField
                              className={styles.TextAreaField}
                              name="vIntro"
                              placeholder="Add prompt"
                              defaultValue={ vBlogPostString}
                              rows={3}
                              resize="vertical"
                              autoResize
                              onChange={(e) => Field_handleChange(e, vBlogPostString, "vBlogPostString")}
                            /> 
                    <Text className={styles.RowHeader}>
                      Blog HTML Body
                    </Text> 
                    <TextAreaField
                              className={styles.TextAreaField}
                              name="vIntro"
                              placeholder="Add prompt"
                              defaultValue={ vBlogHTMLBody}
                              rows={3}
                              resize="vertical"
                              autoResize
                              onChange={(e) => Field_handleChange(e, vBlogHTMLBody, "vBlogHTMLBody")}
                            />      
                    <Text className={styles.RowHeader}>
                    S3 Bucket
                    </Text> 
                      <TextField
                                className={styles.TextField}
                                name="vIntro"
                                placeholder="Add prompt"
                                defaultValue={ vS3Bucket}
                                onChange={(e) => Field_handleChange(e, vS3Bucket, "vS3Bucket")}
                              />   
                    <Text className={styles.RowHeader}>
                    S3 Key
                    </Text> 
                      <TextField
                                className={styles.TextField}
                                name="vS3Key"
                                placeholder="Add prompt"
                                defaultValue={ vS3Key}
                                onChange={(e) => Field_handleChange(e, vS3Key, "vS3Key")}
                              />                                                                                                                 
                    <Text className={styles.RowHeader}>
                      Picture Prompt
                    </Text> 
                    <TextAreaField
                              className={styles.TextAreaField}
                              name="vIntro"
                              placeholder="Add prompt"
                              defaultValue={ vPicturePrompt}
                              rows={3}
                              resize="vertical"
                              autoResize
                              onChange={(e) => Field_handleChange(e, vPicturePrompt, "vPicturePrompt")}
                            />  
                    <Text className={styles.RowHeader}>
                      Picture URL
                    </Text> 
                      <TextField
                                className={styles.TextField}
                                name="vIntro"
                                placeholder="Add prompt"
                                defaultValue={ vPictureURL}
                                onChange={(e) => Field_handleChange(e, vPictureURL, "vPictureURL")}
                              />     
                    <Text className={styles.RowHeader}>
                      Blog Post URL
                    </Text> 
                      <TextField
                                className={styles.TextField}
                                name="vIntro"
                                placeholder="Add prompt"
                                defaultValue={ vPostURL}
                                onChange={(e) => Field_handleChange(e, vPostURL, "vPostURL")}
                              />                                       
                  </Flex>                      
                </TableCell>                  
              </TableRow>  
            )
          }              
        {
          ErrorMessage.length > 0 &&             
            <TableRow  className={styles.ErrorMessageRow}>              
              <TableCell 
                colSpan="3">      
                {ErrorMessage}
              </TableCell>               
            </TableRow>  
        }               
        </TableBody>
      
    )
  }
export default BlogPost;

