
import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead
  } from '@aws-amplify/ui-react'; 
import PrivateKey from './ProductManagementRow';
import styles from './ProductManagementTable.module.css';


const ProductManagementTable = (props) => {       

  ProductManagementTable.propTypes = {
      RhyteItProductManagement: PropTypes.array,
      RhyteItUser: PropTypes.array
      };

    let debugConsoleOutPut = true

  

    const [RhyteItProductManagementTable, setRhyteItProductManagementTable] = useState(props.RhyteItProductManagement)   
    const [ShowTable, setShowTable] = useState(false);

    useEffect(() => {

      //console.log("ProductManagementTable.useEffect[].props.RhyteItProductManagement:  ",props.RhyteItProductManagement) 
      setRhyteItProductManagementTable(props.RhyteItProductManagement)
      //console.log("ProductManagementTable.useEffect[].RhyteItProductManagementTable ",RhyteItProductManagementTable) 
      //console.log("vPrivateKeys.useEffect[].props.RhyteItUser:  ",props.RhyteItUser) 
      //console.log("vPrivateKeys.useEffect[].Time:  ", PrivateKey.PostDateTime.substr(PrivateKey.PostDateTime.indexOf("T")+1, 5))
      //onsole.log("vPrivateKeys.useEffect[].indexOf:  ", PrivateKey.PostDateTime.indexOf(":00"))
      if (Object.keys(RhyteItProductManagementTable).length > 0) {
        setShowTable(true)
      }



    },[])  



  return (  
      <Table className={styles.Table}   highlightOnHover={true}>   
          <TableHead>          
            <TableRow className={styles.TableHeaderRow}>
              <TableCell className={styles.TableHeaderRowCellsWide} >Product</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Env</TableCell> 
              <TableCell className={styles.TableHeaderRowCellsActions} colSpan="2" >Actions</TableCell> 
            </TableRow>                                      
          </TableHead>       
          
              {
                ShowTable && RhyteItProductManagementTable.length > 0 &&  RhyteItProductManagementTable && RhyteItProductManagementTable.map((RhyteItProductManagementRow) => (                      
                  (<PrivateKey   key={RhyteItProductManagementRow.id}  RhyteItProductManagementRow={RhyteItProductManagementRow}/>)        
                  ))
              }                    
        </Table>
    
  )
}

export default ProductManagementTable;


