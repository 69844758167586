import React, { useEffect, useState,useContext } from 'react'
import PropTypes from 'prop-types';
import {
  Text,
  Flex,
  Divider,
  Image
  } from '@aws-amplify/ui-react';
  import styles from './Footer.module.css';  
  import {useNavigate,Link } from 'react-router-dom';
  import {Auth} from 'aws-amplify';
  import {fetchUserId, IsUserSubscribed} from '../../../BusinessLogic/UserManagement';
  import { UserContext,UserAuthenticatedContext,UserSubcribedContext} from '../../../BusinessLogic/Hooks/UserContext';

const Footer = (props) => {

  Footer.propTypes = {
      pageSource: PropTypes.string
    };




useEffect(() => {
 

     
          
},[])        



// onMouseLeave={ (e) => ActionsContainerHoverAction(e, false) } 

  return (
    <div  className={styles.Footer}>      
      <footer>   
        <Divider className={styles.DividerFront} padding="xs" />     
        <Flex
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          alignContent="flex-start"
          wrap="nowrap"
          gap="3rem"
          className={styles.TopFlexRow}
        >            
          <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
          >           
            <div className={styles.FooterCopyRight}>  
              <a  className={styles.FooterSMLink} href="mailto:support@rhyteit.com">support@rhyteit.com</a>
            </div>
            <div className={styles.FooterCopyRight}> 
              <span className={styles.FooterSMLink} id="copyrightYear">©2023 RhyteIt</span>
            </div>
          </Flex>          
          <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
          >     
            <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                alignContent="flex-start"
                wrap="nowrap"
                gap="2rem"
            >      
              <div className={styles.FooterCopyRight}>  
                <a  className={styles.PageLink} href="mailto:support@rhyteit.com">Privacy Policy</a>
              </div>
              <div className={styles.FooterCopyRight}> 
                <span className={styles.PageLink} id="copyrightYear">Payment & Delivery</span>
              </div>
              <div className={styles.FooterCopyRight}> 
                <span className={styles.PageLink} id="copyrightYear">Terms of Use & Refund</span>
              </div>
              <div className={styles.FooterCopyRight}> 
                <span className={styles.PageLink} id="copyrightYear">About Us</span>
              </div>
              <div className={styles.FooterCopyRight}> 
                <span className={styles.PageLink} id="copyrightYear">Contact Us</span>
              </div>
            </Flex>
            <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                alignContent="flex-start"
                wrap="nowrap"
                gap="2rem"
            >      
              <div className={styles.FooterCopyRight}>                                    
                <Image
                    className={styles.Image}
                    src="https://cambridge-ai.center/wp-content/uploads/2022/07/logo-stripe.png"
                    objectFit="initial"
                    backgroundColor="initial"
                    height="5vh"
                    onClick={() => alert('📸 BUY NOW!')}
                  />   
              </div>
              <div className={styles.FooterCopyRight}>                                
                <Image
                    className={styles.ProductImage}
                    src="https://tweetboost.net/wp-content/themes/likes/assets/img/iconss_2.png"
                    objectFit="initial"
                    backgroundColor="initial"
                    height="5vh"
                    onClick={() => alert('📸 Buy Now!')}
                  />  
              </div>
            </Flex>            
          </Flex>
        </Flex>
      </footer> 
     </div>
  )
}

export default Footer;