
import React, { useState , useEffect, useContext,useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Text,
  TableBody,
  Table
  } from '@aws-amplify/ui-react';
  import * as Queries from '../../graphql/queries'
  import { API , graphqlOperation} from 'aws-amplify';
  import { Link } from "react-router-dom";
  import * as Mutations from '../../graphql/mutations';  
  import {get_uuidv4} from '../../BusinessLogic/uuidv4';
  import styles from './MetricsTable.module.css';  
  import { UserContext} from '../../BusinessLogic/Hooks/UserContext';  
  

const MetricsTable = (props) => {       
  

  MetricsTable.propTypes = {
      RhyteItDashboardContent: PropTypes.array,
      Report: PropTypes.string
      };


      const [ErrorMessage, setErrorMessage] = useState("") 

      const [RhyteItDashboardContent, setRhyteItDashboardContent] = useState(props.RhyteItDashboardContent)
      const [DashboardHighlevel, setDashboardHighlevel]= useState([]);
      const [RecordsReturned, setRecordsReturned] = useState(props.RhyteItDashboardContent.length.toLocaleString())    

      useEffect(() => {


        (async function() {
              try {      

              
              //console.log("MetricsTable.useEffect[].props.RhyteItDashboardContent[10]: ",props.RhyteItDashboardContent[10]); 
              GetLatestData()
          
              } catch (err) {
                console.error('PageViewMap.useEffect[].ERROR: ', err, err.stack); 
            }
          }
        )();           


      },[]) 

      async function GetLatestData() {
        try {
            //console.log("GetLatestData.RhyteItDashboardContent: ",RhyteItDashboardContent);  
           //console.log("MetricsTable.GetLatestData.RhyteItDashboardContent: ",RhyteItDashboardContent[RhyteItDashboardContent.length - 1]);  
           //console.log("MetricsTable.GetLatestData.RhyteItDashboardContent: ",RhyteItDashboardContent[RhyteItDashboardContent.length - 2]);  

            if (RhyteItDashboardContent.length > 0) {

                  let v90DayStats = RhyteItDashboardContent[RhyteItDashboardContent.length - 90]
                  let v60DayStats = RhyteItDashboardContent[RhyteItDashboardContent.length - 60]
                  let v30DayStats = RhyteItDashboardContent[RhyteItDashboardContent.length - 30]
                  let vYesterdayStats = RhyteItDashboardContent[RhyteItDashboardContent.length - 2]
                  let vTodayStats = RhyteItDashboardContent[RhyteItDashboardContent.length - 1]

                  let vDashboardHighlevel = {
                    "Date": vTodayStats["StatDate"],
                    "OF Profiles": vTodayStats["OFProfilesCumm"],
                    "OFProfilesGrowth": (((vTodayStats["OFProfilesCumm"]/vYesterdayStats["OFProfilesCumm"])*100)-100).toFixed(2),
                    "OFProfilesGrowth30": (((vTodayStats["OFProfilesCumm"]/v30DayStats["OFProfilesCumm"])*100)-100).toFixed(0) ,
                    "OFProfilesGrowth60": (((vTodayStats["OFProfilesCumm"]/v60DayStats["OFProfilesCumm"])*100)-100).toFixed(0) ,
                    "OFProfilesGrowth90": (((vTodayStats["OFProfilesCumm"]/v90DayStats["OFProfilesCumm"])*100)-100).toFixed(0) ,
                    "Following On Twitter": vTodayStats["TwitterFollowing"],
                    "TwitterFollowingGrowth": (((vTodayStats["TwitterFollowing"]/vYesterdayStats["TwitterFollowing"])*100)-100).toFixed(2) ,
                    "TwitterFollowingGrowth30": (((vTodayStats["TwitterFollowing"]/v30DayStats["TwitterFollowing"])*100)-100).toFixed(0) ,
                    "TwitterFollowingGrowth60": (((vTodayStats["TwitterFollowing"]/v60DayStats["TwitterFollowing"])*100)-100).toFixed(0) ,
                    "TwitterFollowingGrowth90": (((vTodayStats["TwitterFollowing"]/v90DayStats["TwitterFollowing"])*100)-100).toFixed(0) ,
                    "Twitter Followers": vTodayStats["TwitterFollowers"],
                    "TwitterFollowerGrowth": (((vTodayStats["TwitterFollowers"]/vYesterdayStats["TwitterFollowers"])*100)-100).toFixed(2) ,
                    "TwitterFollowerGrowth30": (((vTodayStats["TwitterFollowers"]/v30DayStats["TwitterFollowers"])*100)-100).toFixed(0) ,
                    "TwitterFollowerGrowth60": (((vTodayStats["TwitterFollowers"]/v60DayStats["TwitterFollowers"])*100)-100).toFixed(0) ,
                    "TwitterFollowerGrowth90": (((vTodayStats["TwitterFollowers"]/v90DayStats["TwitterFollowers"])*100)-100).toFixed(0) ,
                    "Page Views": vTodayStats["PageViewsCumm"],
                    "PageViewsGrowth": (((vTodayStats["PageViewsCumm"]/vYesterdayStats["PageViewsCumm"])*100)-100).toFixed(2) ,
                    "PageViewsGrowth30": (((vTodayStats["PageViewsCumm"]/v30DayStats["PageViewsCumm"])*100)-100).toFixed(0) ,
                    "PageViewsGrowth60": (((vTodayStats["PageViewsCumm"]/v60DayStats["PageViewsCumm"])*100)-100).toFixed(0) ,
                    "PageViewsGrowth90": (((vTodayStats["PageViewsCumm"]/v90DayStats["PageViewsCumm"])*100)-100).toFixed(0) ,
                    "Application Views": vTodayStats["ApplicationCumm"],
                    "ApplicationViewsGrowth": (((vTodayStats["ApplicationCumm"]/vYesterdayStats["ApplicationCumm"])*100)-100).toFixed(2) ,
                    "ApplicationViewsGrowth30": (((vTodayStats["ApplicationCumm"]/v30DayStats["ApplicationCumm"])*100)-100).toFixed(0) ,
                    "ApplicationViewsGrowth60": (((vTodayStats["ApplicationCumm"]/v60DayStats["ApplicationCumm"])*100)-100).toFixed(0) ,
                    "ApplicationViewsGrowth90": (((vTodayStats["ApplicationCumm"]/v90DayStats["ApplicationCumm"])*100)-100).toFixed(0) ,
                    "Applicants": vTodayStats["ApplicantCumm"],
                    "ApplicantsGrowth": (((vTodayStats["ApplicantCumm"]/vYesterdayStats["ApplicantCumm"])*100)-100).toFixed(2) ,
                    "ApplicantsGrowth30": (((vTodayStats["ApplicantCumm"]/v30DayStats["ApplicantCumm"])*100)-100).toFixed(0) ,
                    "ApplicantsGrowth60": (((vTodayStats["ApplicantCumm"]/v60DayStats["ApplicantCumm"])*100)-100).toFixed(0) ,
                    "ApplicantsGrowth90": (((vTodayStats["ApplicantCumm"]/v90DayStats["ApplicantCumm"])*100)-100).toFixed(0) ,
                    "NewOF": vTodayStats["CreateOFCount"],
                    "UpdateOF": vTodayStats["UpdateOFCount"],
                  }
                        
        
                  //console.log("MetricsTable.GetLatestData.vDashboardHighlevel: ",vDashboardHighlevel); 

                  setDashboardHighlevel(vDashboardHighlevel)

                }

        } catch (err) { 
                        console.error('Dashboard.GetLatestData.ERROR: ', err, err.stack); 
                    }
      }      

  return (
      <div>
                        <div className={styles.MessageBox}>{ErrorMessage} Records retrieved: {RecordsReturned}</div>  
                        <div className={styles.ToolBar}> 
                        
                        {DashboardHighlevel.Date && (                            
                            <Table className={styles.Table}> 
                              <TableBody>
                                <TableRow className={styles.TableRow}>  
                                  <TableCell className={styles.TableCellHeader}>      
                                  <Text className={styles.Text}>
                                        Statistic
                                    </Text>          
                                  </TableCell>    
                                  <TableCell className={styles.TableCellHeader}>      
                                  <Text className={styles.Text}>
                                        Today
                                    </Text>          
                                  </TableCell>           
                                  <TableCell className={styles.TableCellHeader}>      
                                  <Text className={styles.Text}>
                                        1 day
                                    </Text>          
                                  </TableCell>      
                                  <TableCell className={styles.TableCellHeader}>      
                                  <Text className={styles.Text}>
                                        30 day
                                    </Text>          
                                  </TableCell>      
                                  <TableCell className={styles.TableCellHeader}>      
                                  <Text className={styles.Text}>
                                        60 day
                                    </Text>          
                                  </TableCell>        
                                  <TableCell className={styles.TableCellHeader}>      
                                  <Text className={styles.Text}>
                                        90 day
                                    </Text>          
                                  </TableCell>   
                                </TableRow>  
                                <TableRow className={styles.TableRow}>  
                                  <TableCell className={styles.TableCell}>      
                                    <Text className={styles.TextOrange}>
                                        New OF Profiles
                                    </Text>          
                                  </TableCell> 
                                  <TableCell className={styles.TableCell}>      
                                    <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["NewOF"].toLocaleString()}
                                    </Text>          
                                  </TableCell>                                           
                                </TableRow>     
                                <TableRow className={styles.TableRow}>  
                                  <TableCell className={styles.TableCell}>      
                                    <Text className={styles.TextOrange}>
                                        OF Profile Updates
                                    </Text>          
                                  </TableCell> 
                                  <TableCell className={styles.TableCell}>      
                                    <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["UpdateOF"].toLocaleString()}
                                    </Text>          
                                  </TableCell>                                           
                                </TableRow>                                  
                                <TableRow className={styles.TableRow}>  
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextOrange}>
                                        OF Profiles
                                    </Text>          
                                  </TableCell>         
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["OF Profiles"].toLocaleString()}
                                    </Text>          
                                  </TableCell>       
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["OFProfilesGrowth"].toLocaleString()} %
                                    </Text>          
                                  </TableCell>     
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["OFProfilesGrowth30"].toLocaleString()} %
                                    </Text>          
                                  </TableCell>      
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["OFProfilesGrowth60"].toLocaleString()} %
                                    </Text>          
                                  </TableCell>        
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["OFProfilesGrowth90"].toLocaleString()} %
                                    </Text>          
                                  </TableCell>     
                                </TableRow>                                  
                                <TableRow className={styles.TableRow}>  
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextOrange}>
                                        Following On Twitter
                                    </Text>          
                                  </TableCell>         
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["Following On Twitter"].toLocaleString()} 
                                    </Text>          
                                  </TableCell>       
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["TwitterFollowingGrowth"].toLocaleString()} %
                                    </Text>          
                                  </TableCell>     
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["TwitterFollowingGrowth30"].toLocaleString()} %
                                    </Text>          
                                  </TableCell>      
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["TwitterFollowingGrowth60"].toLocaleString()} %
                                    </Text>          
                                  </TableCell>        
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["TwitterFollowingGrowth90"].toLocaleString()} %
                                    </Text>          
                                  </TableCell>     
                                </TableRow>                                
                                <TableRow className={styles.TableRow}>  
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextOrange}>
                                      Twitter Followers
                                    </Text>          
                                  </TableCell>         
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["Twitter Followers"].toLocaleString()} 
                                    </Text>          
                                  </TableCell>       
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["TwitterFollowerGrowth"].toLocaleString()} %
                                    </Text>          
                                  </TableCell>     
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["TwitterFollowerGrowth30"].toLocaleString()} %
                                    </Text>          
                                  </TableCell>      
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["TwitterFollowerGrowth60"].toLocaleString()} %
                                    </Text>          
                                  </TableCell>        
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["TwitterFollowerGrowth90"].toLocaleString()} %
                                    </Text>          
                                  </TableCell>     
                                </TableRow>                             
                                <TableRow className={styles.TableRow}>  
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextOrange}>
                                  Page Views
                                    </Text>          
                                  </TableCell>         
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["Page Views"].toLocaleString()} 
                                    </Text>          
                                  </TableCell>       
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["PageViewsGrowth"].toLocaleString()} %
                                    </Text>          
                                  </TableCell>     
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["PageViewsGrowth30"].toLocaleString()} %
                                    </Text>          
                                  </TableCell>      
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["PageViewsGrowth60"].toLocaleString()} %
                                    </Text>          
                                  </TableCell>        
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["PageViewsGrowth90"].toLocaleString()} %
                                    </Text>          
                                  </TableCell>     
                                </TableRow>
                          
                                <TableRow className={styles.TableRow}>  
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextOrange}>
                                  Application Views
                                    </Text>          
                                  </TableCell>         
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["Application Views"]} 
                                    </Text>          
                                  </TableCell>       
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["ApplicationViewsGrowth"]} %
                                    </Text>          
                                  </TableCell>     
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["ApplicationViewsGrowth30"]} %
                                    </Text>          
                                  </TableCell>      
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["ApplicationViewsGrowth60"]} %
                                    </Text>          
                                  </TableCell>        
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["ApplicationViewsGrowth90"]} %
                                    </Text>          
                                  </TableCell>     
                                </TableRow>                                

                                <TableRow className={styles.TableRow}>  
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextOrange}>
                                  Applicants
                                    </Text>          
                                  </TableCell>         
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["Applicants"]} 
                                    </Text>          
                                  </TableCell>       
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["ApplicantsGrowth"]} %
                                    </Text>          
                                  </TableCell>     
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["ApplicantsGrowth30"]} %
                                    </Text>          
                                  </TableCell>      
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["ApplicantsGrowth60"]} %
                                    </Text>          
                                  </TableCell>        
                                  <TableCell className={styles.TableCell}>      
                                  <Text className={styles.TextWhite}>
                                        {DashboardHighlevel["ApplicantsGrowth90"]} %
                                    </Text>          
                                  </TableCell>     
                                </TableRow>                                                              
                            </TableBody>
                          </Table>   
                          )}   
                         
                        </div>  
      </div>
      
  )
}

export default MetricsTable;

