import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';

import  {listApplicants}  from '../../../graphql/queries'
import  {}  from '../../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import Header from "../../RhyteItComponents/Header/Header"
import { UserContext, CognitoGroupContext} from '../../../BusinessLogic/Hooks/UserContext';
import {GetGroupRoleAccess, RhyteItLogging, ReturnUserToLandingPage} from '../../../BusinessLogic/UserManagement';
import { fetchAuthSession } from 'aws-amplify/auth';
import {motion as m} from "framer-motion";
import ApplicantManagementTable from "./ApplicantManagementTable";
 
 
import {
   Flex
} from '@aws-amplify/ui-react';
import styles from './ApplicantManagement.module.css';

const ApplicantManagement = (props) => {       
  
  ApplicantManagement.propTypes = {
          RedditUser: PropTypes.object
        };

    
        const client = generateClient();

        const vBreadCrumbItems = [{Path: "/", Title:"Home", isCurrent:false},{Path: "/MainMenuV2", Title:"Menu", isCurrent:false},{Path: "/ApplicantManagement", Title:"Applicant Management", isCurrent:true}]


        const [RhyteItApplicantManagement, setRhyteItApplicantManagement] = useState([])
          
              
        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   

        const {Users, setUsers} = useContext(UserContext);    
        const {CognitoGroup, setCognitoGroup} = useContext(CognitoGroupContext);    
        const [ApplicantsReturned, setApplicantsReturned] = useState(0);
        const [ShowTable, setShowTable] = useState(false);
        

        const [updatedKey, setupdatedKey] = useState(Date.now())   

        

        useEffect(() => {

   
 
        },[]) 

           
           useEffect(() => {
             
            (async function() {
              try {  
  
                      if (CognitoGroup !== undefined) {
  
                        let   GetGroupRoleAccessResponse = await GetGroupRoleAccess(CognitoGroup, "rhyteitAdmins")
                        
                        if (GetGroupRoleAccessResponse) {  
                          GetApplicantManagement()  
                        }
                            
  
                      } else {
  
                        const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
  
                        if (idToken !== undefined) {
                          setUsers(idToken.payload.email)
                          setCognitoGroup(accessToken["payload"]["cognito:groups"])
                        } else {
                          
                          ReturnUserToLandingPage(Users, CognitoGroup)
                        }
  
                      }
                    } catch (err) {                  
                      RhyteItLogging(Users, "ApplicationManagement", "useEffect[CognitoGroup]",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                  }
                }
              )();   
      
             },[CognitoGroup])           
  
            
        useEffect(() => {
          //console.log("ApplicantManagement.useEffect[RhyteItApplicantManagement].RhyteItApplicantManagement:  ",RhyteItApplicantManagement)
          //console.log("SBSMQueries.useEffect[ApplicantManagementQueryTerms].Object.keys(ApplicantManagementQueryTerms).length:  ",Object.keys(ApplicantManagementQueryTerms).length )
 
           if (Object.keys(RhyteItApplicantManagement).length > 0) {
             setShowTable(true)
           }
 
           setErrorMessage("Applicants retrieved at: " + new Date().toLocaleString())
 
         },[RhyteItApplicantManagement])     



        async function GetApplicantManagement() {
          try {
                    setErrorMessage("")
                    setRhyteItApplicantManagement([])

                    let ApplicantManagementData2  = await client.graphql({
                      query: listApplicants
                    });
      

                    if (ApplicantManagementData2.data.listApplicants.items.length > 0) {
                        //console.log("ApplicantManagementData2.data.listApplicantManagement.items.length: ",ApplicantManagementData2.data.listApplicants.items.length);
                        setApplicantsReturned(ApplicantManagementData2.data.listApplicants.items.length)
                        let ApplicantManagement1 = ApplicantManagementData2.data.listApplicants.items
                        ApplicantManagement1 = ApplicantManagement1.sort(compareByCreateDate)
                        //console.log("ApplicantManagement1: ",ApplicantManagement1[0].InOnlyFansProspectingProfile);
                      
                        setRhyteItApplicantManagement(ApplicantManagement1)       

                        setupdatedKey(Date.now())            
                        setShowTable(true)
           
                  }

          } catch (err) { 
            RhyteItLogging(Users, "SBSMRSS", "GetApplicantManagement",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                      }
        }            
        
        function compareByCreateDate(a, b) {

          if (a.CreationDateTime > b.CreationDateTime) {
            return -1;
          }
          if (a.CreationDateTime < b.CreationDateTime) {
            return 1;
          }
          return 0;

        }
    return ( 
    <m.div
      key="Search"
      initial={{x: "100%"}}
      animate={{x: "0%"}}
      transition={{duration:0.75, ease: "easeOut"}}
      exit={{ opacity: 1 }}
     >
  
          <div className={styles.SystemAdminBackground}>          
                  <div  className={styles.GridContainer}> 
                    <Flex
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="stretch"
                              alignContent="flex-start"
                              wrap="wrap"
                              gap="1rem"
                              className={styles.FlexColumn}
                              >   
                              
                        <Header Title="Applicant Management" SubTitle="Manage RhyteIt Applicants" BreadCrumbItems ={vBreadCrumbItems} pageSource="ApplicantManagement"/>   
                        <div className={styles.ToolBar}>          
                          <div className={styles.ToolBarRow}>                                                                                                                                                                         
                          </div>                                
                          <div className={styles.MessageBox}>{ErrorMessage} Applicants retrieved: {ApplicantsReturned}</div>  
                        </div>             
                        {ShowTable && RhyteItApplicantManagement.length > 0 && (<ApplicantManagementTable   key={updatedKey}  RhyteItApplicantManagement={RhyteItApplicantManagement}/>)  }                                                                                                            
                    </Flex>
                  </div>     
          </div>
    </m.div>                      

    )

  }
 

export default ApplicantManagement;