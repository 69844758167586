import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import {motion as m} from "framer-motion";
import { UserContext} from '../../BusinessLogic/Hooks/UserContext';
import {ValidateUser} from '../../BusinessLogic/UserManagement';

import  { }  from '../../graphql/queries'
import  { updateEmailAccounts, deleteEmailAccounts}  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import {
  Button ,
  Authenticator,
  Table,
  TableRow,
  TableBody,
  TableCell,
  CheckboxField,
  TextField,
  Card,
  Image,
  View,
  Flex,
  Text,
  TextAreaField

} from '@aws-amplify/ui-react';
import styles from './RhyteItAccountSD.module.css';
import styled from 'styled-components'


function ErrorFallback({error, resetErrorBoundary}) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

const LabelText = styled.div`
                        color: white;
                        font-size: 1.5em !important;
                        `;


const RhyteItAccountSD = (props) => {      


      RhyteItAccountSD.propTypes = {
        vRhyteItAccount: PropTypes.object,
        UserEmail: PropTypes.string
      }; 

        const client = generateClient();

        let debugConsoleOutPut = true

        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   
        const [RecordUpdated, setRecordUpdated] = useState(false)  

        let {Users} = useContext(UserContext);   
        const [RhyteItAccount, setRhyteItAccount] = useState(props.vRhyteItAccount) 
        const [RedoImageChecked, setRedoImageChecked] = useState(props.vRhyteItAccount.RedoImage)  
        const [UserEmail, setUserEmail] = useState(props.UserEmail)  

        useEffect(() => {

          (async function() {
            try {  


              if (Users === undefined) {                    
                console.log("RhyteItAccountSD.useEffect[].Users:  ",RhyteItAccount.UpdateName)
                 let vIsUserSubscribed = await ValidateUser()
                 setUserEmail(vIsUserSubscribed)
                //console.log("ProductManagement.useEffect[].Users:  ",Users)

              } else if (Users.length > 0 && Users !== undefined) {

                //console.log("Prod

              }                     


                } catch (err) {
                  console.error('RhyteItAccountSD.useEffect[].ERROR: ', err, err.stack); 
              }
            }
          )();   
      

        },[]) 
        
        function Prompt_handleChange(event, data) {
          try {              

              RhyteItAccount.Prompt = event.target.value
             // //console.log("RhyteItAccountSD.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountSD.Prompt_handleChange.Error:  ".err, err.stack); 
          }        
        }            

        function Model_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("RhyteItAccountSD.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("RhyteItAccountSD.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItAccount.Model = event.target.value
             // //console.log("RhyteItAccountSD.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountSD.Model_handleChange.Error:  ".err, err.stack); 
          }        
        }            

        function ProfileImageURL_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("RhyteItAccountSD.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("RhyteItAccountSD.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItAccount.ProfileImageURL = event.target.value
             // //console.log("RhyteItAccountSD.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountSD.ProfileImageURL_handleChange.Error:  ".err, err.stack); 
          }        
        }          

        function MageSeed_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("RhyteItAccountSD.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("RhyteItAccountSD.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItAccount.MageSeed = event.target.value
             // //console.log("RhyteItAccountSD.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountSD.MageSeed_handleChange.Error:  ".err, err.stack); 
          }        
        } 

        async  function SaveRhyteItAccount(event, data) {
          try {           
            
              setErrorMessage("")
              
              if (debugConsoleOutPut === true) {
                //console.log("RhyteItAccountSD.SaveRhyteItAccount.props.RhyteItUser: ",props.RhyteItUser)
               // //console.log("RhyteItAccountSD.SaveRhyteItAccount.RhyteItAccount: ",RhyteItAccount)
                //console.log("RhyteItAccountSD.SaveRhyteItAccount.event: ",event)
              }
    
           
    
              if (debugConsoleOutPut === true) {
                //console.log("RhyteItAccountSD.SaveRhyteItAccount.new Date().toISOString(): ",new Date().toISOString())
              }
    
              var vUpdateDateTime = new Date().toISOString()
    
              if (debugConsoleOutPut === true) {
               //console.log("RhyteItAccountSD.SaveRhyteItAccount.RhyteItAccount: ",RhyteItAccount)
                //console.log("RhyteItAccountSD.SaveRhyteItAccount.Users: ",Users)
                //console.log("RhyteItAccountSD.SaveRhyteItAccount.RhyteItAccount.RespondedDate Compare: ",RhyteItAccount[elementPos].RespondedDate === "1970-01-01T00:00:00.000Z" )
             }
    
              RhyteItAccount.UpdateDateTime = vUpdateDateTime       
              RhyteItAccount.UpdateName = Users     
              RhyteItAccount.RedoImage = RedoImageChecked   
              RhyteItAccount.AccountStatus   = "Record saved by:  " + UserEmail
              
              if (RhyteItAccount["MediumAccount"] === false || RhyteItAccount["MediumAccount"] === null) {
                RhyteItAccount["MediumAccount"] = ""
              }               
              if (RhyteItAccount["TwitterAccount"] === false || RhyteItAccount["TwitterAccount"] === null) {
                RhyteItAccount["TwitterAccount"] = ""
              } 
              if (RhyteItAccount["RedditAccount"] === false || RhyteItAccount["RedditAccount"] === null) {
                RhyteItAccount["RedditAccount"] = ""
              } 
              if (RhyteItAccount["OnlyFansAccount"] === false || RhyteItAccount["OnlyFansAccount"] === null) {
                RhyteItAccount["OnlyFansAccount"] = ""
              } 
              if (RhyteItAccount["InstagramAccount"] === false || RhyteItAccount["InstagramAccount"] === null) {
                RhyteItAccount["InstagramAccount"] = ""
              } 
              if (RhyteItAccount["ChromiumProfile"] === false || RhyteItAccount["ChromiumProfile"] === null) {
                RhyteItAccount["ChromiumProfile"] = ""
              } 
                  
    
              let vRhyteItAccountItem = {
                "id": RhyteItAccount.id,
                "UserId": RhyteItAccount.UserId,
                "Password": RhyteItAccount.Password,
                "FirstName": RhyteItAccount.FirstName,
                "LastName": RhyteItAccount.LastName,
                "Name": RhyteItAccount.Name,
                "NameNonSpaced": RhyteItAccount.NameNonSpaced,  
                "BirthMonth": RhyteItAccount.BirthMonth,
                "BirthDay": RhyteItAccount.BirthDay,
                "BirthYear": RhyteItAccount.BirthYear,
                "StartDate": RhyteItAccount.StartDate,
                "EndDate": RhyteItAccount.EndDate,
                "EmailAccountProvider": RhyteItAccount.EmailAccountProvider,
                "EmailAccount": RhyteItAccount.EmailAccount,
                "ChromiumProfile": RhyteItAccount.ChromiumProfile,
                "TwitterAccount": RhyteItAccount.TwitterAccount,
                "TwitterPassword": RhyteItAccount.TwitterPassword,
                "TwitterConsumerKey": RhyteItAccount.TwitterConsumerKey,
                "TwitterConsumerSecret": RhyteItAccount.TwitterConsumerSecret,
                "RedditAccount": RhyteItAccount.RedditAccount,
                "OnlyFansAccount": RhyteItAccount.OnlyFansAccount,
                "MediumAccount": RhyteItAccount.MediumAccount,
                "InstagramAccount": RhyteItAccount.InstagramAccount,
                "VerificationProvider": RhyteItAccount.VerificationProvider,
                "VerificationCode": RhyteItAccount.VerificationCode,
                "OpenAIOrgKey": RhyteItAccount.OpenAIOrgKey,
                "OpenAIapiKey": RhyteItAccount.OpenAIapiKey,
                "AccountStatus": RhyteItAccount.AccountStatus,
                "Prompt":RhyteItAccount.Prompt,
                "Model":RhyteItAccount.Model,
                "ProfileImageURL": RhyteItAccount.ProfileImageURL,
                "MageSeed": RhyteItAccount.MageSeed,
                "Industry": RhyteItAccount.Industry,
                "ProcessGroup": RhyteItAccount.ProcessGroup,
                "RedoImage": RhyteItAccount.RedoImage,
                "CreationName": RhyteItAccount.CreationName,
                "UpdateName": UserEmail,
                "CreationDateTime": RhyteItAccount.CreationDateTime,
                "UpdateDateTime": RhyteItAccount.UpdateDateTime
            }

    
              if (debugConsoleOutPut === true) {
              
                console.log("RhyteItAccountSD.SaveRhyteItAccount.vRhyteItAccountItem: ",vRhyteItAccountItem)
    
              }


              const updateEmailAccountsResponse = await client.graphql({
                query: updateEmailAccounts,
                variables: {
                  input: vRhyteItAccountItem
                }
              });                

              if (debugConsoleOutPut === true) {
              
                console.log("RhyteItAccountSD.SaveRhyteItAccount.updateEmailAccountsResponse.data.updateEmailAccounts: ",updateEmailAccountsResponse.data.updateEmailAccounts)
    
              }

              setRecordUpdated(true)
              ErrorMessageStr = "Updated"
              setErrorMessage(ErrorMessageStr)
              setRhyteItAccount(updateEmailAccountsResponse.data.updateEmailAccounts)
         
    
            } catch (err) { 
              ErrorMessageStr = ("RhyteItAccountSD.SaveRhyteItAccount.Error: ", err, err.stack)
              console.error(ErrorMessageStr)
              setErrorMessage(ErrorMessageStr)
          }        
        }     

        async function DeleteRhyteItAccount(event,data) {
          try {
    

    
              const DeleteRhyteItAccounts = {
                id: RhyteItAccount.id,
                UserId: RhyteItAccount.UserId,
                _version: RhyteItAccount._version
              }
              

              const ReturnMessage = await client.graphql({
                query: deleteEmailAccounts,
                variables: {
                  input: DeleteRhyteItAccounts
                }
              });  

              setRhyteItAccount([])
    
              setErrorMessage("Deleted")         
                    
              } catch (err) { 
    
              ErrorMessageStr = "RhyteItAccountSD.DeleteRhyteItAccount.Error: " + err + " " + err.stack
    
              console.error(ErrorMessageStr); 
              
              setErrorMessage(ErrorMessageStr)         
            }               
          
        }           


                

    return ( 
                    <div  className={styles.GridContainer}>              
                        <div className={styles.Container}>
                          <div className={styles.AccountDetailContainer}>
                            <View
                              className={styles.View}
                            >
                              <Card className={styles.Card}>

                                <Flex direction="row" alignItems="flex-start">
                                  <Flex
                                    direction="column"
                                    alignItems="flex-start"
                                    gap="5vh"
                                  >
                                    <Flex
                                    direction="row"
                                    alignItems="flex-start"
                                    gap="10px">
                                      <Button className={styles.RedditButtons}                                       
                                              onClick={ (e) => SaveRhyteItAccount(e, RhyteItAccount) }
                                              variation="primary">Save</Button>
                                      <Button className={styles.RedditButtons}                                       
                                              onClick={ (e) => DeleteRhyteItAccount(e, RhyteItAccount) }
                                              variation="primary">Delete</Button>
                                    </Flex>  
                                    
                                    <div className={styles.headerline} />                                                                         
                                    <Flex>
                                      <Text size="large" className={RecordUpdated === false ? styles.Text :styles.TextUpdated }>
                                        {RhyteItAccount.AccountStatus}
                                      </Text>
                                      <Text size="large" className={styles.Text}>
                                        {new Date( RhyteItAccount.CreationDateTime).toLocaleString("en-US")}
                                      </Text>
                                      <Text size="large" className={RecordUpdated === false ? styles.Text :styles.TextUpdated  }>
                                        {new Date( RhyteItAccount.UpdateDateTime).toLocaleString("en-US")}
                                      </Text>
                                      <Text size="large" className={styles.Text}>
                                        {RhyteItAccount.UpdateName}
                                      </Text>
                                    </Flex>                                     
                                    <div className={styles.headerline} />                                  
                                    <Table>
                                      <TableBody>
                                        <TableRow>
                                          <TableCell  className={styles.RowLabelColumn}>         
                                            <LabelText   
                                              className={styles.TextFieldLabel}
                                              >
                                                Prompt
                                            </LabelText>
                                          </TableCell>
                                          <TableCell>
                                            <TextAreaField className={styles.TextAreaField} 
                                              name="vRhyteItAccount.Prompt"                           
                                              defaultValue={RhyteItAccount.Prompt}
                                              onChange={ (e) => Prompt_handleChange(e, RhyteItAccount) } />
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell  className={styles.RowLabelColumn}>         
                                            <LabelText   
                                              className={styles.TextFieldLabel}
                                              >
                                                Model
                                            </LabelText>
                                          </TableCell>
                                          <TableCell>
                                            <TextField className={styles.TextField} 
                                              name="vRhyteItAccount.Model"                           
                                              defaultValue={RhyteItAccount.Model}
                                              onChange={ (e) => Model_handleChange(e, RhyteItAccount) } />
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell  className={styles.RowLabelColumn}>         
                                            <LabelText   
                                              className={styles.TextFieldLabel}
                                              >
                                                Image URL
                                            </LabelText>
                                          </TableCell>
                                          <TableCell>
                                            <TextField className={styles.TextField} 
                                              name="vRhyteItAccount.ProfileImageURL"                           
                                              defaultValue={RhyteItAccount.ProfileImageURL}
                                              onChange={ (e) => ProfileImageURL_handleChange(e, RhyteItAccount) } />
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell   className={styles.RowLabelColumn}>         
                                            <LabelText   
                                              className={styles.TextFieldLabel}
                                              >
                                                Mage Seed
                                            </LabelText>
                                          </TableCell>
                                          <TableCell>
                                            <TextField className={styles.TextField} 
                                              name="vRhyteItAccount.MageSeed"                           
                                              defaultValue={RhyteItAccount.MageSeed}
                                              onChange={ (e) => MageSeed_handleChange(e, RhyteItAccount) } />
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell   className={styles.RowLabelColumn}>         
                                            <LabelText   
                                              className={styles.TextFieldLabel}
                                              >
                                                Redo Image
                                            </LabelText>
                                          </TableCell>
                                          <TableCell>
                                            <CheckboxField
                                              name="subscribe-controlled"
                                              checked={RedoImageChecked}
                                              onChange={(e) => setRedoImageChecked(e.target.checked)}
                                            />                                                
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                    
                                  </Flex>
                                </Flex>
                              </Card>
                            </View>           
                          </div>
                          <div className={styles.MessageBox}>{ErrorMessage}</div>                            
                        </div>     
                    </div>    
    )

  }
 

export default RhyteItAccountSD;