import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { API , graphqlOperation} from 'aws-amplify';
import * as Mutations from '../../../graphql/mutations'


import  {createAudienceGeoInfo}  from '../../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import {ErrorBoundary} from 'react-error-boundary'
import HeaderMenu from "../../HeaderMenu/MainHeader";
import {motion as m} from "framer-motion";
import { UserContext,UserAuthenticatedContext,UserSubcribedContext} from '../../../BusinessLogic/Hooks/UserContext';
import axios from 'axios';
import {uuidv4} from "../../../BusinessLogic/UtilitiesManagement";
import HeaderBreadCrumbs from "../BreadCrumbs/HeaderBreadCrumbs";
import HeaderTitles from "../HeaderTitles/HeaderTitles";



import {
  Flex 
} from '@aws-amplify/ui-react';
import styles from './Header.module.css';

function ErrorFallback({error, resetErrorBoundary}) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}


const Header = (props) => {       
  
  Header.propTypes = {
          Title: PropTypes.string,
          SubTitle: PropTypes.string,
          PageSource: PropTypes.string,
          BreadCrumbItems: PropTypes.array
        };
          
              
        const client = generateClient();
        var ErrorMessageStr = ""   
        
        let vIPAddress = ""
        const [FirstTouch, setFirstTouch] = useState(true);
        const [ip, setIP] = useState('');

        useEffect(() => {


        },[]) 
 

    

    return ( 
                    <Flex
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        alignContent="flex-start"
                        wrap="nowrap"
                        gap="1rem"
                      >      
                      <HeaderBreadCrumbs BreadCrumbItems={props.BreadCrumbItems}/>
                      <HeaderMenu  pageSource={props.PageSource}/>
                      <HeaderTitles Title={props.Title} SubTitle={props.SubTitle} />
                    </Flex>         

    )

  }
 

export default Header;