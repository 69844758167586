import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { motion, useAnimate } from "framer-motion";
import styles from "./BackgroundSVGAPI.module.css";
import useWindow from "../../BusinessLogic/Hooks/useWindow";
import { Flex, Image } from "@aws-amplify/ui-react";

const BackgroundSVGAPI = (props) => {
  BackgroundSVGAPI.propTypes = {
    InitiateBackGround: PropTypes.bool,
    scrollYProgress: PropTypes.object,
  };

  var ErrorMessageStr = "";
  const { dimension } = useWindow();

  const [FramerAnimationConfigs, setFramerAnimationConfigs] = useState([]);
  const vCanvas = document.getElementById("lasercanvas");
  const [vLaserTotal, setvLaserTotal] = useState(100);

  
  const [vInitiateBackGround, setvInitiateBackGround] = useState(props.InitiateBackGround);

  let LaserLineArray = [];

  useEffect(() => {
    
  //  console.log("BackgroundSVGAPI.useEffect[].vInitiateBackGround: ",vInitiateBackGround);
    PopulateParameters();
  }, []);

  useEffect(() => {


  }, [dimension]);

  useEffect(() => {
    // console.log("BackgroundSVGAPI.useEffect[vCanvas].vInitiateBackGround: ",vInitiateBackGround);
  //   console.log("BackgroundSVGAPI.useEffect[vCanvas].vCanvas: ",vCanvas);
    // console.log("BackgroundSVGAPI.useEffect[vCanvas].document.documentElement.clientWidth: ",document.documentElement.clientWidth);
     

     

     if (vCanvas !== null && vInitiateBackGround === true) {
    //  console.log("BackgroundSVGAPI.useEffect[vCanvas].vCanvas.width: ",vCanvas.width);
      if (document.documentElement.clientWidth > 500) {
    //    console.log("BackgroundSVGAPI.useEffect[dimension].vCanvas: ", vCanvas.width);
        resizeReset();
        CreateLaserLines();
        animateLaserLines();
      }
    }    

  }, [vCanvas]);

  useEffect(() => {


   // console.log("BackgroundSVGAPI.useEffect[vInitiateBackGround].vInitiateBackGround: ",vInitiateBackGround);
  //  console.log("BackgroundSVGAPI.useEffect[vInitiateBackGround].vCanvas: ",vCanvas);



  }, [vInitiateBackGround]);

  function resizeReset() {

    vCanvas.width = document.documentElement.clientWidth * 1;
    vCanvas.height = document.documentElement.clientHeight * 0.95;

  }

  function PopulateParameters() {
    try {
      let vFMParamsArray = [];

      // Math.random() * (max - min) + min;

      let i = 0;

      while (i < 30) {
        let Scale1 =
          Math.floor(Math.random() * (100 - 0) + 0) * 0.01 +
          Math.floor(Math.random() * (2 - 0) + 0);
        let Scale2 =
          Math.floor(Math.random() * (100 - 0) + 0) * 0.01 +
          Math.floor(Math.random() * (2 - 0) + 0);
        let Scale3 =
          Math.floor(Math.random() * (100 - 0) + 0) * 0.01 +
          Math.floor(Math.random() * (2 - 0) + 0);

        //console.log("BackgroundSVGAPI.PopulateParameters.Scale1:  ",Scale1, " - Scale2:  ",Scale2, " - Scale3:  ",Scale3)

        let opacity1 = Math.floor(Math.random() * (8 - 2) + 2) * 0.1;
        let opacity2 = Math.floor(Math.random() * (8 - 2) + 2) * 0.1;
        let opacity3 = Math.floor(Math.random() * (8 - 2) + 2) * 0.1;

        let rotate1 = Math.floor(Math.random() * (360 - 0) + 0);
        let rotate2 = Math.floor(Math.random() * (360 - 0) + 0);
        let rotate3 = Math.floor(Math.random() * (360 - 0) + 0);

        let blur1 = Math.floor(Math.random() * (20 - 0) + 0);
        let blur2 = Math.floor(Math.random() * (20 - 0) + 0);
        let blur3 = Math.floor(Math.random() * (20 - 0) + 0);

        // console.log("BackgroundSVGAPI.PopulateParameters.blur1:  ",blur1, " - blur2:  ",blur2, " - blur3:  ",blur3)

        let Color1 =
          rotate1 < 120 ? "#99FFFF" : rotate1 < 240 ? "#FFCC99" : "#CCCCFF";
        let Color2 =
          rotate2 < 120 ? "#99FFFF" : rotate2 < 240 ? "#FFCC99" : "#CCCCFF";
        let Color3 =
          rotate3 < 120 ? "#99FFFF" : rotate3 < 240 ? "#FFCC99" : "#CCCCFF";

        // console.log("BackgroundSVGAPI.PopulateParameters.Color1:  ",Color1, " - Color2:  ",Color2, " - Color3:  ",Color3)

        let vFramerAnimationConfigsItem = {
          scale: [Scale1, Scale2, Scale3, Scale1],
          opacity: [opacity1, opacity2, opacity3, opacity1],
          rotate: [rotate1, rotate2, rotate3, rotate1],
          fill: [Color1, Color2, Color3, Color1],
          blur: [blur1, blur2, blur3, blur1],
        };

        i++;
        vFMParamsArray.push(vFramerAnimationConfigsItem);
      }

      setFramerAnimationConfigs(vFMParamsArray);
    } catch (err) {
      ErrorMessageStr =
        ("BackgroundSVGAPI.PopulateParameters.Error: ", err, err.stack);
      console.error(ErrorMessageStr);
    }
  }

  function getRandomInt(min, max) {
    return Math.round(Math.random() * (max - min)) + min;
  }

  class LaserLine {
    constructor() {
      this.reset();
      this.constructed = true;
   
    }

    reset() {
      this.x = Math.round(vCanvas.width / 2);
      this.y = Math.round(vCanvas.height / 2);
      //this.x = 100;
      //this.y = 100;
      this.sx = this.x;
      this.sy = this.y;
      this.size = 5;
      this.angle = 60 * getRandomInt(0, 10);
      this.radian = (Math.PI / 180) * (this.angle + 90);
      this.speed = 20;
      this.maxDistance = 60;
      this.time = 0;
      this.ttl = getRandomInt(180, 300);
    }

    draw() {
      const ctx = vCanvas.getContext("2d");

      let vParticleColor = Math.floor(Math.random() * 3) + 1;

      //console.log("BackgroundSVGAPI.draw.this.x: ", this.x, " - this.y: ", this.y, " - this.size: ", this.size);
      ctx.save();
      ctx.beginPath();
      ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);

      if (vParticleColor === 1) {
        ctx.fillStyle = "#99FFFF";
        ctx.shadowColor = "#99FFFF";
        ctx.shadowBlur = 5;
      } else if (vParticleColor === 2) {
        ctx.fillStyle = "#FFCC99";
        ctx.shadowColor = "#FFCC99";
        ctx.shadowBlur = 5;
      } else if (vParticleColor === 3) {
        ctx.fillStyle = "#CCCCFF";
        ctx.shadowColor = "#CCCCFF";
        ctx.shadowBlur = 5;
      }

      ctx.fill();
      ctx.closePath();
      ctx.restore();
    }
    update() {
      let dx = this.sx - this.x;
      let dy = this.sy - this.y;
      let distance = Math.sqrt(dx * dx + dy * dy);

      if (distance >= this.maxDistance) {
        if (getRandomInt(0, 1)) {
          this.angle += 60;
        } else {
          this.angle -= 60;

          //console.log("BackgroundSVGAPI.CreateLaserLines.update: ", distance," - this.maxDistance: ",this.maxDistance," - this.angle: ",this.angle);
        }

        this.radian = (Math.PI / 180) * (this.angle + 90);
        this.sx = this.x;
        this.sy = this.y;
      }

      this.x += this.speed * Math.sin(this.radian);
      this.y += this.speed * Math.cos(this.radian);
      /** 
      console.log("BackgroundSVGAPI.CreateLaserLines.update.time: ", this.time,  
                  " - ttl: ", this.ttl,
                  " - x: ",this.x,
                  " - vCanvas.width: ",vCanvas.width,
                  " - y: ",this.y,
                  " - vCanvas.height: ",vCanvas.height);  
      */
      if (
        this.time >= this.ttl ||
        this.x < 0 ||
        this.x > vCanvas.width ||
        this.y < 0 ||
        this.y > vCanvas.height
      ) {
        this.reset();
      }

      this.time += 1;

    }
  }

  function CreateLaserLines() {
    //console.log("BackgroundSVGAPI.CreateLaserLines.vLaserTotal: ", vLaserTotal);
    // console.log("BackgroundSVGAPI.CreateLaserLines.LaserLineArray: ", LaserLineArray);
    for (let i = 0; i < vLaserTotal; i++) {
      setTimeout(() => {
        LaserLineArray.push(new LaserLine());
      }, i * 200);
    }

    //console.log("BackgroundSVGAPI.CreateLaserLines.LaserLineArray: ", LaserLineArray);
  }

  function animateLaserLines() {
    const ctx = vCanvas.getContext("2d");
    //ctx.fillStyle = "rgba(0,0,0.5)";
    ctx.fillStyle =  "rgba(255, 255, 255, 0.5)";
    
    ctx.fillRect(0, 0, vCanvas.width, vCanvas.height)
    drawScene();
    requestAnimationFrame(animateLaserLines);
  }

  function drawScene() {
    //console.log("BackgroundSVGAPI.drawScene.LaserLineArray.length: ", LaserLineArray.length);
    for (let i = 0; i < LaserLineArray.length; i++) {
      LaserLineArray[i].update();
      LaserLineArray[i].draw();
    }
  }
  
  return ( 
    <div className={styles.BackgroundContainer} > 
    {vInitiateBackGround === true && (document.documentElement.clientWidth > 500 && (    
      <canvas id="lasercanvas" className={styles.canvas} >
        </canvas>
      )
    )
    }

    {vInitiateBackGround === true && (document.documentElement.clientWidth > 500 && (         
        FramerAnimationConfigs && FramerAnimationConfigs.slice(0, 4).map((FramerAnimationConfig) => (     
            <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
                className={styles.PurpleBlob}
                viewBox="0 0 1200 1080">
                <defs>
                    <filter  id="OuterGlow1">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="20" result="blur" />
                    </filter>
                </defs> 
                <motion.path 
                    class="cls-2" 
                    id="BlobPath"          
                    className={styles.BlobPath}
                    animate={FramerAnimationConfig}
                    transition={{
                            times:[0,1],
                            duration:20,
                            repeat:Infinity,
                            type:"keyframes",
                            ease:"easeInOut",
                          }}
                    d="M188.5,177.5c69.79-67.37,202.86-32.47,233-24,127.14,35.71-40.65,36.66,342,118,156.03,33.17,167.77-44.96,282,9,84.53,39.93,126.52,152.65,121,241-8.85,141.64-141.12,241.45-241,280-172.85,66.71-263.48-44.33-428,41-88.71,46.01-103.24,99.5-177,101-86.06,1.75-173.21-68.9-208-149-16.25-37.41-88.25-99.66-78-239,5.89-80.06,90.13-144.05,100-200,17.83-101.13,27.3-152.22,54-178Z" />
              
            </svg>
        ))      
      )
    )}             
    <img className={styles.Image} src="https://rhyteiticons.s3.us-west-2.amazonaws.com/Backgrounds/Ximena3.png" alt="tetrahedral" />
     

    {vInitiateBackGround === true && (document.documentElement.clientWidth > 500 && (         
        FramerAnimationConfigs && FramerAnimationConfigs.slice(5, 29).map((FramerAnimationConfig) => (     
            <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
                className={styles.PurpleBlob2}
                viewBox="0 0 1200 1080">
                <defs>
                    <filter id="OuterGlow2">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="20" result="blur" />
                    </filter>
                </defs>
                <motion.path 
                    class="cls-2" 
                    id="BlobPath"          
                    className={styles.BlobPath}
                    animate={FramerAnimationConfig}
                    transition={{
                            times:[0,1],
                            duration:40,
                            repeat:Infinity,
                            type:"keyframes",
                            ease:"easeInOut",
                          }}
                    d="M188.5,177.5c69.79-67.37,202.86-32.47,233-24,127.14,35.71-40.65,36.66,342,118,156.03,33.17,167.77-44.96,282,9,84.53,39.93,126.52,152.65,121,241-8.85,141.64-141.12,241.45-241,280-172.85,66.71-263.48-44.33-428,41-88.71,46.01-103.24,99.5-177,101-86.06,1.75-173.21-68.9-208-149-16.25-37.41-88.25-99.66-78-239,5.89-80.06,90.13-144.05,100-200,17.83-101.13,27.3-152.22,54-178Z" />

            </svg>            
          ))      
        )
      )}       
    </div>  
)
};

export default BackgroundSVGAPI;
