import React, { useState, useEffect, useContext, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./NewsLetter.module.css";

import {
  useInView,
  useScroll,
  useTransform,
  motion,
  useAnimation,
} from "framer-motion";
//import {useInView} from "react-intersection-observer";

import { Button, Flex, Text, TextAreaField } from "@aws-amplify/ui-react";


import {listApplicants} from '../../../graphql/queries'
import {createAudienceGeoInfo, createApplicants, createMeetings}  from '../../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';
import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';

const NewsLetter = (props) => {
  NewsLetter.propTypes = {
    scrollYProgress: PropTypes.object,
  };


  const client = generateClient();

  const scale = useTransform(props.scrollYProgress, [0.71, 0.80], [0.02, 2.75]);
  //const rotate = useTransform(props.scrollYProgress, [0.90, 0.95], [0, 360 ]);
  //const opacity = useTransform(props.scrollYProgress, [0.9, 0.95], [1, 1 ]);
  const y = useTransform(props.scrollYProgress, [0.71, 0.80], [-100, 40 ]);

  const [vEmail, setvEmail] = useState(null);


  const refNewsLetterSection = useRef(null);
  const isInView = useInView(refNewsLetterSection);
  var StatusMessageStr = ""
  const [StatusMessage, setStatusMessage] = useState(StatusMessageStr)  

  useEffect(() => {}, []);

  useEffect(() => {
    //console.log("NewsLetter.useEffect[isInView].props.scrollYProgress: ", props.scrollYProgress);

    if (isInView) {
    }
  }, [isInView]);

  useEffect(() => {

 //   console.log("NewsLetter.useEffect[vEmail].vEmail: ", vEmail);

  }, [vEmail]);


  function HandleEmailChange(event, data) {
    try {              

       // //console.log("RhyteItAccountSD.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
 

       //console.log("NewsLetter.HandleEmailChange.event: ", event);
        let vEmailTmp = event.target.value;
        setvEmail(vEmailTmp)  


      } catch (err) { 
        console.error("NewsLetter.HandleEmailChange.Error:  ".err, err.stack); 
    }        
  }    

  async function ProcessApplicant(e, data) {
    try {
        setStatusMessage("")
        
        console.log("NewsLetter.ProcessApplicant.vEmail: ", vEmail);
        let ProcessApplicantStatusMessage = ""

            let EmailAddressAlreadyExists = await CheckEmailAddress(vEmail)
            
            if (EmailAddressAlreadyExists === true) {
        
                    User_Add(vEmail);
                    
                    ProcessApplicantStatusMessage = "Alright! Congratulations on joining our reading list."
                    setStatusMessage(ProcessApplicantStatusMessage)

         }

   

    } catch (err) { //console.log('error fetching Users')
        RhyteItLogging("11111", "NewsLetter", "ProcessApplicant",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                }
    }      


async function CheckEmailAddress(pApplicantEmail) {
    try {

        let vCheckEmailAddressReturnMessage = true
        console.log("Conversation.CheckEmailAddress.pApplicantEmail: ", pApplicantEmail)

        if (pApplicantEmail.length === 0) {
          setStatusMessage("Email address is blank") 
          vCheckEmailAddressReturnMessage = false
        }
        if (pApplicantEmail.search("Email address is blank") > -1) {
          setStatusMessage("Enter valid email address") 
          vCheckEmailAddressReturnMessage = false
        }
        if (pApplicantEmail.search("@") === -1) {
          setStatusMessage("Enter valid email address") 
          vCheckEmailAddressReturnMessage = false
        }
        if (pApplicantEmail.search(".") === -1) {
          setStatusMessage("Enter valid email address") 
          vCheckEmailAddressReturnMessage = false
        }        
   
        console.log("Conversation.CheckEmailAddress.vCheckEmailAddressReturnMessage: ", vCheckEmailAddressReturnMessage)
        return vCheckEmailAddressReturnMessage

    } catch (err) { //console.log('error fetching Users')
        RhyteItLogging("1111", "NewsLetter", "CheckEmailAddress",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                }
}   

async function User_Add(pNewUser) {
    try {

        let ReturnMessage = ""

                let vApplicant = {
                    id: vEmail,
                    UserId: vEmail,
                    UserLastName: "",
                    UserFirstName: "",
                    ipAddress:"N/A",
                    UserPhone: "+18005555555",
                    UserEmail: vEmail,
                    SocialMediaProfile: "N/A",
                    PrivacyAcknowledgement: true,
                    EmailTemplate: "Newsletter",
                    Application: "Newsletter subscriber",
                    InOnlyFansProspectingProfile: false,
                    CreationName: vEmail,
                    UpdateName: vEmail,
                    Active: true,
                    NewsLetterStatus: "subscribed",
                    NewsLetterConsent: true
                }      

                    try {

                        const ReturnMessage = await client.graphql({
                            query: createApplicants,
                            variables: {
                              input: vApplicant
                            }
                          });            

                    } catch (err) { 

                        RhyteItLogging("11111", "NewsLetter", "User_Add",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                }     






    } catch (err) {   
            RhyteItLogging("11111", "NewsLetter", "User_Add",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
            StatusMessageStr = 'Conversation.User_Add.ERROR:  ' + err 
            console.error(StatusMessageStr); 
    }        
}  


  return (
    <section ref={refNewsLetterSection} className={styles.SectionContainer} >   
      <motion.img src={"https://rhyteiticons.s3.us-west-2.amazonaws.com/Backgrounds/TurquoiseCircle.svg"} 
            alt="Your SVG" 
            className={styles.Circle}     
            style={{ scale,  y, originY: 0.1 }} />
      <Flex
        direction="column"
        justifyContent="start"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
        className={styles.TextFlexColumn}
      >
          <Flex
            direction="column"
            justifyContent="start"
            alignItems="center"
            alignContent="center"
            wrap="nowrap"
            className={styles.HeaderDiv}
          >     
            <h1 className={styles.Title}>bECOME AN eXPERT</h1>
            <h1 className={styles.Text}>Learn how to dominate the subscription based social media industry</h1>
            <h1 className={styles.Text}>Stay up to date with the latest adult content creator trends</h1>
            <h1 className={styles.Text}>Case studies on how our customers are using RhyteIt to increase their fanbase</h1>
            <h1 className={styles.Text}>Preview of tools we are working on to help you achieve your ambitions</h1>
            <h1 className={styles.Text}>Interviews with adult content creators, management agencies and anyone else who will talk to us</h1>
            <Flex
            direction="row"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            wrap="wrap"
            className={styles.EmailDiv}
            >              
              <TextAreaField className={styles.TextAreaField} 
                    name="vRhyteItAccount.Prompt"   
                    isRequired={true}            
                    placeholder="Your email address..."
                    defaultValue={vEmail}
                    onChange={ (e) => HandleEmailChange(e, vEmail) } />  
              <Flex
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="start"
                  wrap="nowrap"
                  className={styles.ButtonRowDiv}
                  onClick={ (e) => ProcessApplicant(e, vEmail) } 
                  >                      
                  <h1 className={styles.ButtonText}>Subscribe</h1>
                  <img 
                  className={styles.ButtonArrow} src="https://rhyteiticons.s3.us-west-2.amazonaws.com/Backgrounds/Arrow.svg" alt="Test"/>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            direction="column"
            justifyContent="start"
            alignItems="center"
            alignContent="center"
            wrap="nowrap"
            gap="1rem"
            className={styles.CaveatDiv}
          >        
            <h1 className={styles.caveats}>* We are lucky if we get one newsletter out a week :)</h1>
            <h1 className={styles.caveats}>* You can unsubscribe anytime</h1>
            <h1 className={styles.StatusMessage}>{StatusMessage}</h1>
          </Flex>   
      </Flex>

    </section>
  );
};

export default NewsLetter;
