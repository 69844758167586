
import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead
  } from '@aws-amplify/ui-react'; 
import ApplicantProfileRow from './ApplicantProfileRow';
import styles from './ApplicantProfileTable.module.css';


const ApplicantProfileTable = (props) => {       

  ApplicantProfileTable.propTypes = {
      Profile: PropTypes.object,
      EngagmentScores: PropTypes.object,
      RhyteItUser: PropTypes.array
      };

    let debugConsoleOutPut = true

  

    const [RhyteItApplicantProfileTable, setRhyteItApplicantProfileTable] = useState(props.Profile)  
    const [EngagmentScores, setEngagmentScores] = useState(props.EngagmentScores)  
    const [ShowTable, setShowTable] = useState(false);

    useEffect(() => {

      //console.log("ApplicantProfileTable.useEffect[].props.Profile:  ",props.Profile) 
      setRhyteItApplicantProfileTable(props.Profile)
      //console.log("ApplicantProfileTable.useEffect[].EngagmentScores ",EngagmentScores) 
      //console.log("vApplicantProfileRows.useEffect[].props.RhyteItUser:  ",props.RhyteItUser) 
      //console.log("vApplicantProfileRows.useEffect[].Time:  ", ApplicantProfileRow.PostDateTime.substr(ApplicantProfileRow.PostDateTime.indexOf("T")+1, 5))
      //onsole.log("vApplicantProfileRows.useEffect[].indexOf:  ", ApplicantProfileRow.PostDateTime.indexOf(":00"))
        setShowTable(true)



    },[])  



  return (  
    
    <div>
    <div className={styles.TableHeaderRowCells}>{EngagmentScores.length}</div>
      <Table className={styles.Table}   highlightOnHover={true}>   
          <TableHead>          
            <TableRow className={styles.TableHeaderRow}>
              <TableCell className={styles.TableHeaderRowCells} >Name</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Twitter</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Photos</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Videos</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Likes</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Streams</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Fans</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Price</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Rank</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Score</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Updates</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Last Update</TableCell> 
            </TableRow>                                      
          </TableHead>       
          
              {
                ShowTable && RhyteItApplicantProfileTable !== undefined && EngagmentScores.length > 0 &&                     
                  (<ApplicantProfileRow   key={RhyteItApplicantProfileTable.id}  RhyteItApplicantProfileRow={RhyteItApplicantProfileTable}  EngagmentScores={EngagmentScores[0]}/>)   
              }                    
        </Table>
        </div>
  )
}

export default ApplicantProfileTable;


