
import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead
  } from '@aws-amplify/ui-react'; 
import SBSMQueryTermRow from './SBSMQueryTermRow';  
import styles from './SBSMQueryTermTable.module.css';


const SBSMQueryTermTable = (props) => {       

  SBSMQueryTermTable.propTypes = {
      SBSMNewsQueryTerms: PropTypes.array,
      RhyteItUser: PropTypes.array
      };

    let debugConsoleOutPut = true


    const [updatedKey, setupdatedKey] = useState(Date.now())  
    const [SBSMNewsQueryTerms, setSBSMNewsQueryTerms] = useState(Object.entries(props.SBSMNewsQueryTerms))
    const [ShowTable, setShowTable] = useState(false);

    useEffect(() => {

        //console.log("SBSMQueryTermTable.useEffect[].props.SBSMQueryTerms:  ",props.SBSMNewsQueryTerms) 
        //console.log("SBSMQueryTermTable.useEffect[].Object.keys(SBSMNewsQueryTerms).length:  ",Object.keys(SBSMNewsQueryTerms).length)
        //console.log("SBSMQueryTermTable.useEffect[].Object.Array.isArray(SBSMNewsQueryTerms):  ",Array.isArray(SBSMNewsQueryTerms))
         

        if (Object.keys(SBSMNewsQueryTerms).length > 0) {
          setShowTable(true)
        }

      },[])  

      useEffect(() => {

        //console.log("SBSMQueryTermTable.useEffect[].SBSMNewsQueryTerms:  ",SBSMNewsQueryTerms) 
  
      },[SBSMNewsQueryTerms])        

  return ( 
    <div>
      <Table className={styles.RhyteItTable}   highlightOnHover={true}>   
          <TableHead>          
            <TableRow className={styles.TableHeaderRow}>
              <TableCell className={styles.TableHeaderRowCells}colSpan="1" >Query Term</TableCell>   
              <TableCell className={styles.TableHeaderRowCells} >Status</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Logo</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} colSpan="1" >Actions</TableCell> 
            </TableRow>                                      
          </TableHead>       
              {
                ShowTable === true && Array.isArray(SBSMNewsQueryTerms)
                ? SBSMNewsQueryTerms.map((SBSMQueryTerm) => (         
                <SBSMQueryTermRow  key={SBSMQueryTerm[1].id} SBSMQueryTerm={SBSMQueryTerm[1]}/>))  : null
                 
              }                    
        </Table>
      </div> 
    
  )
}

export default SBSMQueryTermTable;


