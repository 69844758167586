import React, { useState, useEffect, createContext, useContext } from "react";
import PropTypes from "prop-types";
import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHead,
  Flex,
} from "@aws-amplify/ui-react";
import ContentRow from "./ContentRow";
import styles from "./ContentTable.module.css";
import { SelectedImageContext } from "../../../BusinessLogic/Hooks/SMMADImageContext";

const ContentTable = (props) => {
  ContentTable.propTypes = {
    AccountSelected: PropTypes.string,
    CampaignFrom: PropTypes.string,
    CampaignTo: PropTypes.string,
    SMContent: PropTypes.array,
    RhyteItUser: PropTypes.array,
  };

  let debugConsoleOutPut = true;

  const [SMContent, setSMContent] = useState(props.SMContent);
  const [ShowTable, setShowTable] = useState(false);

  const [vAccountSelected, setvAccountSelected] = useState(
    props.AccountSelected
  );
  const [vCampaignFrom, setvCampaignFrom] = useState(
    props.vCampaignFrom
  );
  const [vCampaignTo, setvCampaignTo] = useState(
    props.CampaignTo
  );
  const [vUpdateKey, setvUpdateKey] = useState();
  const [vClickedImage, setvClickedImage] = useState(SMContent[0].id);
  let ErrorMessageStr = "";
  useEffect(() => {}, []);

  useEffect(() => {
    //console.log("ContentTable.useEffect[].props.SMContent:  ",props.SMContent)
    //console.log("ContentTable.useEffect[].SMContent ",SMContent)
    //console.log("vContentRows.useEffect[].props.RhyteItUser:  ",props.RhyteItUser)
    //console.log("vContentRows.useEffect[].Time:  ", ContentRow.PostDateTime.substr(ContentRow.PostDateTime.indexOf("T")+1, 5))
    //onsole.log("vContentRows.useEffect[].indexOf:  ", ContentRow.PostDateTime.indexOf(":00"))
    if (SMContent.length > 0) {
      setShowTable(true);
      setvUpdateKey(Date.now());
    }
  }, [SMContent]);

  useEffect(() => {
    //console.log("ContentTable.useEffect[vClickedImage].vClickedImage:  ",vClickedImage)
    setvUpdateKey(Date.now());
  }, [vClickedImage]);

  async function ClickImage(event, pSMMADContentRowId) {
    try {
      //console.log("ContentTable.ClickImage.pSMMADContentRowId:  ",pSMMADContentRowId)
      setvClickedImage(pSMMADContentRowId);
    } catch (err) {
      ErrorMessageStr =
        "ContentTable.ClickImage.Error: " + err + " " + err.stack;

      console.error(ErrorMessageStr);
    }
  }

  const handleClick = (item) => {
    alert(`You clicked on ${item}`);
  };

  return (
    <SelectedImageContext.Provider value={vClickedImage}>
      <Table className={styles.RhyteItTable} highlightOnHover={true}>
        <TableHead className={styles.TableHead}>
          <TableRow className={styles.TableHeaderRow}>
            <TableCell
              className={styles.TableHeaderRowCell}
            >
              Image
            </TableCell>
            <TableCell
              className={styles.TableHeaderRowCell}
            >
              Campaign
            </TableCell>
            <TableCell className={styles.TableHeaderRowCell}>
            Posted #
            </TableCell>
            <TableCell className={styles.TableHeaderRowCell}>
              Weight
            </TableCell>
            <TableCell className={styles.TableHeaderRowCell}>
            Last Post
            </TableCell>
          </TableRow>
        </TableHead> 
        <TableBody>  

        {vClickedImage &&
          vUpdateKey &&
          ShowTable &&
          SMContent.length > 0 &&
          SMContent &&
          SMContent.map((SMMADContentRow) => {
            return (
              <ContentRow
                key={SMMADContentRow.id}
                ImageToTextRelationship={props.ImageToTextRelationship}
                SMMADContentRow={SMMADContentRow}
                AccountSelected={vAccountSelected}
                CampaignFrom={vCampaignFrom}
                CampaignTo={vCampaignTo}
                ClickedImage={vClickedImage}
                onClick={() => setvClickedImage(SMMADContentRow.id)}
              />
            );
          })}
          </TableBody>
      </Table>
    </SelectedImageContext.Provider>
  );
};

export default ContentTable;
