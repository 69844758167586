
import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  Label,
  Badge,
  Text,
  Flex,
  SelectField,
  Button, Fieldset,
  TextField
  } from '@aws-amplify/ui-react'; 
import SMMADCampaignRow from './SMMADCampaignRow';
import styles from './SMMADCampaignTable.module.css';
import  { listSMMADCampaigns, listSMMADHashTags }  from '../../../graphql/queries'
import  { deleteSMMADCampaigns, updateSMMADCampaigns, createSMMADCampaigns}  from '../../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';
import SMMADCampaignNewField from "./SMMADCampaignNewField";

import SMMADContent from "../SMMADContent/Content";
import SMMADText from "../SMMADText/SMMADText";


const SMMADCampaignTable = (props) => {       

  SMMADCampaignTable.propTypes = {
      AccountSelected: PropTypes.string,      
      AccountGroup: PropTypes.string
      };

    let debugConsoleOutPut = true

  

    var ErrorMessageStr = ""   
    const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)

    const client = generateClient();
    const [vSMMADCampaign, setvSMMADCampaign] = useState([]) 
    const [vAccountSelected, setvAccountSelected] = useState(props.AccountSelected)  
    const [vAccountGroup, setvAccountGroup] = useState(props.AccountGroup)      
    const [vCampaignSelected, setvCampaignSelected] = useState()   
    const [vCampaignToShow, setvCampaignToShow] = useState()
  
    const [CampaignActivateButtonText, setCampaignActivateButtonText] = useState("Start Posting")   
    const [vContentTextActive, setvContentTextActive] = useState(true)   
    const [SMHashTag, setSMHashTag] = useState([])  

    const [vCampaign, setvCampaign] = useState("")   
    const [vAddNewCampaignVisible, setvAddNewCampaignVisible] = useState(false)  
    const [vCampaignHelpBadgeHovered, setvCampaignHelpBadgeHovered] = useState(false)

    const [vShowPostCountConfig, setvShowPostCountConfig] = useState(false)  
    const [vMaxPostCount, setvMaxPostCount] = useState(999)  
       
    
    

    useEffect(() => {

      
      //console.log("SMMADCampaignTable.useEffect[].props.AccountSelected:  ",props.AccountSelected);
      
      (async function() {
        try {  
       
          GetSMMADCampaigns()

            } catch (err) {
              console.error('SMMADCampaign.useEffect[].ERROR: ', err, err.stack); 
          }
        }
      )();   
  

    },[])  

    useEffect(() => {

      
   
      if (vSMMADCampaign !== undefined) {

        if (vSMMADCampaign.length > 0) {
        //  //console.log("SMMADCampaignTable.useEffect[vSMMADCampaign].vSMMADCampaign:  ",vSMMADCampaign)
          //console.log("SMMADCampaignTable.useEffect[vSMMADCampaign].vAccountSelected:  ",vAccountSelected)

          if (vAccountSelected !== "edwardhawke@rhyteit.com") {         

            setvCampaignSelected("Default")

          }

        }
        
      }

    },[vSMMADCampaign]) 

    useEffect(() => {

     // //console.log("SMMADCampaignTable.useEffect[vCampaignSelected].vCampaignSelected:  ",vCampaignSelected);

      if (vCampaignSelected !== undefined && vSMMADCampaign.length > 0) {    

        setvCampaignToShow(vSMMADCampaign.filter((campaign) => campaign["Campaign"] === vCampaignSelected)); 
       

      }


     },[vCampaignSelected])       
       
    useEffect(() => {
      
      //console.log("SMMADCampaignTable.useEffect[vCampaignToShow].vCampaignToShow:  ",vCampaignToShow);
      //console.log("SMMADCampaignTable.useEffect[vCampaignToShow].vMaxPostCount:  ",vMaxPostCount);
      if (vCampaignToShow !== undefined && vCampaignToShow.length > 0) {


        let vCampaignStatus = (vCampaignToShow[0]["Active"] === true ?  "Stop Posting" :  "Start Posting" )
        
        setCampaignActivateButtonText(vCampaignStatus)

        
        if (vCampaignToShow[0]["PostCountBeforeExpiry"] !== null) {
          //console.log("SMMADCampaignTable.useEffect[vCampaignToShow].vCampaignToShow[0][PostCountBeforeExpiry]:  ",vCampaignToShow[0]["PostCountBeforeExpiry"]);

          setvMaxPostCount(vCampaignToShow[0]["PostCountBeforeExpiry"])

        } 

        let vCTA = false

        if (vCampaignToShow[0].hasOwnProperty("ContentTextActive")) {
          if (vCampaignToShow[0].ContentTextActive === null) {      
            vCTA = false
          } else {
            vCTA = vCampaignToShow[0].ContentTextActive
          }

        } else {            
          vCTA = false
        }      
        
        //console.log("SMMADCampaignTable.useEffect[vCampaignToShow].vCTA:  ",vCTA);
        setvContentTextActive(vCTA)
      }


     },[vCampaignToShow])  

     
     useEffect(() => {

      //console.log("SMMADCampaignTable.useEffect[vMaxPostCount].vMaxPostCount:  ",vMaxPostCount);

    },[vMaxPostCount]) 


     useEffect(() => {

      if (vAddNewCampaignVisible === false) {
        GetSMMADCampaigns()
        GetHashTag()      
      }

    },[vAddNewCampaignVisible]) 
     
    async function GetSMMADCampaigns() {
      try {
                setErrorMessage("")
                setvSMMADCampaign([])
                
                //console.log("SMMADCampaignTable.GetSMMADCampaigns.vAccountSelected:  ",vAccountSelected)
                let variables 

                variables = {
                  filter: {
                    Account: {
                            eq: vAccountSelected
                        }
                  }
                };

                const SMMADCampaignData2  = await client.graphql({
                  query: listSMMADCampaigns,
                  variables: variables
                });
  
      
                if (SMMADCampaignData2.data.listSMMADCampaigns.items.length > 0) {

                    const SMMADCampaign1 = SMMADCampaignData2.data.listSMMADCampaigns.items
                    
                    
                    //console.log("SMMADCampaignTable.GetSMMADCampaigns.SMMADCampaignData2.data.listSMMADCampaigns.items:  ",SMMADCampaignData2.data.listSMMADCampaigns.items)


                    setvSMMADCampaign(SMMADCampaign1)  
       
                }


      } catch (err) { 
                      console.error('SMMADCampaign.GetSMMADCampaign.ERROR: ', err, err.stack); 
                  }
    }   
  
    async function SaveRow() {
      try {           
          
          //console.log("SMMADCampaignTable.SaveRow.vCampaignToShow:  ",vCampaignToShow[0]);


          var vUpdateDateTimeNew = new Date().toISOString()
      
          vCampaignToShow[0].UpdateDateTime = vUpdateDateTimeNew       
          vCampaignToShow[0].UpdateName = vAccountSelected  
          vCampaignToShow[0].Active = (CampaignActivateButtonText === "Start Posting" ?  true  :  false  )


          let CampaignItem = {
              "id": vCampaignToShow[0].id,
              "Account": vCampaignToShow[0].Account,
              "AccountType": vCampaignToShow[0].AccountType,
              "AccountGroup": vCampaignToShow[0].AccountGroup,
              "MasterAccount": vCampaignToShow[0].MasterAccount,
              "Campaign": vCampaignToShow[0].Campaign,
              "Description": vCampaignToShow[0].Description,
              "ContentTextActive": vContentTextActive,
              "SMMADCampaignStatus": "Updated",
              "Active": vCampaignToShow[0].Active,
              "Weight": vCampaignToShow[0].Weight,
              "ZScore": vCampaignToShow[0].ZScore,
              "LastPostDate": vCampaignToShow[0].LastPostDate,
              "PostedCount": vCampaignToShow[0].PostedCount,
              "CreationName": vCampaignToShow[0].CreationName,
              "CreationDateTime": vCampaignToShow[0].CreationDateTime,
              "UpdateName": vCampaignToShow[0].UpdateName,
              "UpdateDateTime": vCampaignToShow[0].UpdateDateTime,
          }
              
              //console.log("SMMADCampaignTable.SaveRow.CampaignItem:  ",CampaignItem);

              const updateSMMADCampaignResponse = await client.graphql({
                query: updateSMMADCampaigns,
                variables: {
                  input: CampaignItem
                }
              });  

              //console.log("SMMADCampaignTable.SaveRow.CampaignActivateButtonText:  ",CampaignActivateButtonText)  
              let vCampaignStatus = (CampaignActivateButtonText === "Start Posting" ?   "Stop Posting" :  "Start Posting" )

              //console.log("SMMADCampaignTable.SaveRow.vCampaignStatus:  ",vCampaignStatus)  
              //setvCampaignToShow(updateSMMADCampaignResponse.data.updateSMMADCampaigns)
              setCampaignActivateButtonText(vCampaignStatus)
              
        } catch (err) { 
          ErrorMessageStr = ("SMMADCampaignTable.SaveRow.Error: ", err, err.stack)
          console.error(ErrorMessageStr)
          setErrorMessage(ErrorMessageStr)
      }        
    }      
    
    async function DeleteCampaign(event,data) {
      try {

              
              setErrorMessage("")   
              //console.log("SMMADCampaignTable.DeleteCampaign.vCampaignToShow.id:  ",vCampaignToShow[0].id)  


              const DeleteSMMADCampaignRow = {
                id: vCampaignToShow[0].id,
                _version: vCampaignToShow[0]._version
              }
              

              const vDeleteResponse = await client.graphql({
                query: deleteSMMADCampaigns,
                variables: {
                  input: DeleteSMMADCampaignRow
                }
              });  

              //console.log("SMMADCampaignTable.DeleteCampaign.vDeleteResponse:  ",vDeleteResponse)  

              ErrorMessageStr = vDeleteResponse
              setErrorMessage(ErrorMessageStr)  
              GetSMMADCampaigns()
              
      
          } catch (err) { 

          ErrorMessageStr = err

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }               
      
    }  

    async function GetHashTag() {
      try {
                setErrorMessage("")
                setSMHashTag([])

                const variables = {
                  filter: {
                    Account: {
                            eq: vAccountSelected
                        }
                  }
                };

                const HashTagData2  = await client.graphql({
                  query: listSMMADHashTags,
                  variables: variables
                });
  
      

                if (HashTagData2.data.listSMMADHashTags.items.length > 0) {

                    const HashTag1 = HashTagData2.data.listSMMADHashTags.items 
                    //console.log("SMMADHashTags.GetHashTag.HashTag1:  ",HashTag1)     

                    setSMHashTag(HashTag1)  
       
              }

      } catch (err) { 
                      console.error('SMMADCampaignTable.GetHashTag.ERROR: ', err, err.stack); 
                  }
    }            

    async function AddCampaignRow() {
      try {              
          
        setvCampaign(null)
        let vNewValue = (vAddNewCampaignVisible === false ? true : false)
        setvAddNewCampaignVisible(vNewValue)


        } catch (err) { 
          console.error("SMMADCampaignTable.AddCampaignRow.Error:  ".err, err.stack); 
      }        
    }  

    async function ShowPostCountConfig() {
      try {              
          
        let vNewValue = (vShowPostCountConfig === false ? true : false)
        setvShowPostCountConfig(vNewValue)


        } catch (err) { 
          console.error("SMMADCampaignTable.ShowPostCountConfig.Error:  ".err, err.stack); 
      }        
    }      

    const handleMouseOver = () => {

      let vCampaignHelpBadgeHoveredtmp = (vCampaignHelpBadgeHovered === true ? false : true)

      setvCampaignHelpBadgeHovered(vCampaignHelpBadgeHoveredtmp);
    };

    async function vMaxPostCountChange(event,data) {
 

              
              setErrorMessage("")   
              //console.log("SMMADCampaignTable.DeleteCampaign.vCampaignToShow.id:  ",vCampaignToShow[0].id)  

              try {              

                let vMaxPostCountTmp = event.target.value
                setvMaxPostCount(vMaxPostCountTmp)
       
          } catch (err) { 

          ErrorMessageStr = err

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }               
      
    }  

    async function SaveCampaign(event, data) {
      try {           
        
          setErrorMessage("")

          //console.log("SMMADCampaignTable.SaveCampaign.vCampaignToShow:  ",vCampaignToShow)  

          var vUpdateDateTimeNew = new Date().toISOString()
      
          vCampaignToShow[0]["UpdateDateTime"] = vUpdateDateTimeNew       
          vCampaignToShow[0]["UpdateName"] = vAccountSelected       
          vCampaignToShow[0]["PostCountBeforeExpiry"] = vMaxPostCount      
          vCampaignToShow[0]["SMMADCampaignStatus"] = "UPDATED PostCountBeforeExpiry"  

          let CampaignItem = {
              "id": vCampaignToShow[0]["id"],
              "Account": vCampaignToShow[0]["Account"],
              "AccountType": vCampaignToShow[0]["AccountType"],
              "AccountGroup": vCampaignToShow[0]["AccountGroup"],
              "MasterAccount": vCampaignToShow[0]["MasterAccount"],
              "TwitterPostingFrequencyHours": vCampaignToShow[0]["TwitterPostingFrequencyHours"],
              "Campaign": vCampaignToShow[0]["Campaign"],
              "Description": vCampaignToShow[0]["Description"],
              "SMMADCampaignStatus": vCampaignToShow[0]["SMMADCampaignStatus"],
              "PostCountBeforeExpiry":vCampaignToShow[0]["PostCountBeforeExpiry"],
              "Active": vCampaignToShow[0]["Active"],
              "Weight": vCampaignToShow[0]["Weight"],
              "ZScore": vCampaignToShow[0]["ZScore"],
              "LastPostDate": vCampaignToShow[0]["LastPostDate"],
              "PostedCount": vCampaignToShow[0]["PostedCount"],
              "CreationName": vCampaignToShow[0]["CreationName"],
              "CreationDateTime": vCampaignToShow[0]["CreationDateTime"],
              "UpdateName": vCampaignToShow[0]["UpdateName"],
              "UpdateDateTime": vCampaignToShow[0]["UpdateDateTime"],
          }
   
 
              CampaignItem.SMMADCampaignStatus = "Updated"
              const updateSMMADCampaignResponse = await client.graphql({
                query: updateSMMADCampaigns,
                variables: {
                  input: CampaignItem
                }
              });  

              
              //console.log("SMMADCampaignRow.SaveRow.updateSMMADCampaignResponse:  ",updateSMMADCampaignResponse)  
              setvCampaignToShow(vCampaignToShow)
              setErrorMessage(ErrorMessageStr)
  
        } catch (err) { 
          ErrorMessageStr = ("SMMADCampaignRow.SaveRow.Error: ", err, err.stack)
          console.error(ErrorMessageStr)
          setErrorMessage(ErrorMessageStr)
      }        
    }       
    

  return (  
  <Flex
    direction="column"
    justifyContent="flex-start"
    alignItems="stretch"
    wrap="nowrap"
    gap="1rem"
    className={styles.GridContainer}
    key={Date.now()+1}
  >   

      <Badge variation="info" 
            size="small"
            className={vCampaignHelpBadgeHovered === false ? styles.BadgeHelp : styles.BadgeHelphover}            
            onClick={handleMouseOver}>
            <Flex
              direction="row"
              justify="center"
              alignItems="center"
              align="flex-start"
              wrap="nowrap"
              gap="1rem"

            >                
            {
              vCampaignHelpBadgeHovered === false &&  (     
                <Text className={styles.BadgeHelpQuestionMark}>?</Text> )
            }                
            {
              vCampaignHelpBadgeHovered === true &&  (              
              <Flex
                direction="row"
                justify="center"
                alignItems="center"
                align="flex-start"
                wrap="nowrap"
                gap="1rem"
  
              >   
                  <Text className={styles.BadgeHelpQuestionMark}>?</Text> 
                <Flex
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    wrap="nowrap"
                    gap="0.25rem" 
                    className={styles.BadgeHelpFlexColumnText}
                  > 
                  <Text 
                    className={vCampaignHelpBadgeHovered === true ? styles.BadgeHelpText.transition : styles.BadgeHelpText}>You can group your posts into campaigns.
                  </Text>          
                  <Text 
                    className={vCampaignHelpBadgeHovered === true ? styles.BadgeHelpText.transition : styles.BadgeHelpText}>If you do not want a campaign to run for awhile, you can deactivate it. 
                  </Text>   
                  <Text 
                    className={vCampaignHelpBadgeHovered === true ? styles.BadgeHelpText.transition : styles.BadgeHelpText}>You can also delete the campaign and all its posts.
                  </Text>                                                                                                                                                                                                                                           
                </Flex>                                                                                                                                                                                                                                          
            </Flex>  
              )
            }                
                                                                                                                                                                                                                                  
            </Flex>    
      </Badge>      
      {
        vSMMADCampaign.length > 0 && vAddNewCampaignVisible === false &&  ( 
            <Flex
              direction="row"
              justify="flex-start"
              alignItems="center"
              align="flex-start"
              wrap="nowrap"
              gap="1rem"
            >    
                <SelectField
                    className={styles.SelectField}
                    value={vCampaignSelected}
                    placeholder="Select Campaign"
                    onChange={(e) => setvCampaignSelected(e.target.value)}>
                      {
                      vSMMADCampaign !== undefined && vSMMADCampaign.map((SMCampaign) => (                      
                        ( <option 
                          className={styles.SelectFieldOption} key={SMCampaign.Campaign} value={SMCampaign.Campaign} label={SMCampaign.Campaign}>
                            {SMCampaign.Campaign}
                          </option>)        
                        ))
                    }   
                </SelectField>                                                                                                                                                                                                                        
            </Flex>    
          )   
      }    
      <Flex
        direction="row"
        justify="flex-start"
        alignItems="center"
        align="flex-start"
        wrap="nowrap"
        gap="1rem"
      >       
            <Button className={styles.Button}
                    variation="primary"         
                    onClick={ (e) => AddCampaignRow(e)}
                    >
                  {vAddNewCampaignVisible === false ? "Add Campaign" : "Select Campaign" }
            </Button> 
            {
              vCampaignSelected !== undefined  &&     (     
                <Fieldset  direction="row" 
                gap="1rem">                                   
                  <Button className={styles.Button}
                    variation="primary"         
                    onClick={(e) => SaveRow()}
                    >
                      {CampaignActivateButtonText}
                  </Button>  
                  <Button className={styles.Button}
                    variation="primary"         
                    onClick={ (e) => DeleteCampaign(e,vCampaignSelected)}
                    >
                      Delete
                  </Button>  
                  <Button className={styles.Button}
                    variation="primary"         
                    onClick={ (e) => ShowPostCountConfig(e,vCampaignSelected)}
                    >
                    Max Post Count
                  </Button>  
                </Fieldset> 
                )     
              }                  
      </Flex>  
      
      {  vShowPostCountConfig === true  &&     (   
      <Flex
        direction="row"
        justify="flex-start"
        alignItems="center"
        align="flex-start"
        wrap="nowrap"
        gap="1rem"
      >     
          <Fieldset  direction="row" gap="1rem">      

          <Flex
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    wrap="nowrap"
                    gap="0.25rem" 
                    className={styles.BadgeHelpFlexColumnText}
                  >                                          

            <Text className={styles.InstructionText}>Please enter the Maximum number of times you want each picture to be posted:</Text>
 
            <Flex
              direction="row"
              justify="flex-start"
              alignItems="center"
              align="flex-start"
              wrap="nowrap"
              gap="1rem"
            >                 
            <TextField 
              className={styles.TextField} 
              name="vMaxPostCount"                       
              defaultValue={vMaxPostCount}
              onChange={ (e) => vMaxPostCountChange(e, vMaxPostCount) } 
              />         
            <Button className={styles.Button}
              variation="primary"         
              onClick={ (e) => SaveCampaign(e, vCampaignSelected)}
              >
              Save
            </Button>   
            </Flex>   
            </Flex>  
          </Fieldset>   
      </Flex>  
                )     
              }                    

      {
        vAddNewCampaignVisible === true && (  
              <SMMADCampaignNewField 
                  className={styles.CampaignField}
                  AccountSelected={vAccountSelected}
                  AccountGroup={vAccountGroup}   />  
          )
      }   
      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        wrap="nowrap"
        gap="1rem"
        className={styles.FlexColumnContentText}
        key={Date.now()+1}
      >   
      {
        vCampaignSelected !== undefined  && vCampaignToShow !== undefined  &&  vContentTextActive !== null  && (
                      <Fieldset 
                      className={styles.Fieldset}
                      size="small">  
                              <legend>Content</legend>      
                        {vCampaignSelected !== undefined  && (<SMMADContent key={vCampaignToShow.id} AccountSelected={vAccountSelected}   AccountGroup={vAccountGroup}  CampaignSelected={vCampaignSelected}  ImageToTextRelationship={vContentTextActive} HashTagMap={SMHashTag}/>)  }                                                                                                                                                                                                                                                                                                                                         
                        
                        </Fieldset>  
          )  
      }       
              
      {
        vCampaignSelected !== undefined  && vCampaignToShow !== undefined  &&  vContentTextActive !== null  && vContentTextActive === false && (
            <Fieldset 
              className={styles.Fieldset}
              size="small">  
              <legend>Text</legend>                                                              
            {vCampaignSelected !== undefined  && (<SMMADText  key={vCampaignToShow.id} AccountSelected={vAccountSelected}  CampaignSelected={vCampaignSelected}  HashTagMap={SMHashTag}  ContentId="NA"/>)} 
          
            </Fieldset>
          )  
      }       
      </Flex> 
 </Flex>      
    
  )
}

export default SMMADCampaignTable;


