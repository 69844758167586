

import {listSBSMNews, listSBSMNewsQueryTerms} from '../graphql/queries'
import { generateClient } from 'aws-amplify/api';

import {RhyteItLogging} from '../BusinessLogic/UserManagement';

export async function  GetSBSMNewsArticlesAd(pNextToken) {
    try {
            const client = generateClient();
            let variables = ""

            let nextToken = pNextToken
         //   console.log("SBSMNewsManagement.GetSBSMNewsArticlesAd.pNextToken:  ",pNextToken); 
         var date = new Date();
         date.setDate(date.getDate() - 1);
         var vUpdateDateTime = date.toISOString()

            if (pNextToken === undefined) {

              variables = {
                filter: {
                  CreationDateTime: {
                    gt: vUpdateDateTime
                }
                }
              };

            } else {
              
              variables = {
                nextToken,
                filter: {
                  CreationDateTime: {
                    gt: vUpdateDateTime
                }
                }
              };

            }


            const SBSMNewsArticlesData  = await client.graphql({
                query: listSBSMNews,
                variables: variables
              });        

            const SBSMNewsArticles = SBSMNewsArticlesData.data.listSBSMNews.items 

            const ReturnNextToken = SBSMNewsArticlesData.data.listSBSMNews.nextToken

            return {Articles: SBSMNewsArticles, nextToken: ReturnNextToken}

    } catch (err) { 
      RhyteItLogging(null, "SBSMNewsManagement", "GetSBSMNewsArticlesAd",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                }
  } 
