import PropTypes from "prop-types";
import React, { useState, useEffect, useRef,useCallback } from "react";
import { motion as m } from "framer-motion";
import styles from "./ForegroundCanvasErase.module.css";
import useWindow from "../../BusinessLogic/Hooks/useWindow";
import {
  useInView,
  useScroll,
  useTransform,
  motion,
  useAnimation,
} from "framer-motion";

const ForegroundCanvasErase = (props) => {
  ForegroundCanvasErase.propTypes = {
    backgroundcolor: PropTypes.string,
  };

  const { dimension } = useWindow();
  const canvasRef  = useRef();
  const [context, setContext] = useState(null);
  var vRenderCounts = 0

  var vAnimationCount = useRef(0);
  var vIntervals = []

  const ref = useRef(null);
  const isInView = useInView(ref);
  const img = new Image();


  useEffect(() => {

           

  }, []);

  useEffect(() => {
    if (dimension.width > 0) init();
  }, [dimension]);


  useEffect(() => {

    //console.log("ForegroundCanvasErase.useEffect[isInView].vAnimationCount:  ",vAnimationCount.current) 
    //console.log("ForegroundCanvasErase.useEffect[isInView].navigator.language:  ",navigator.language) 
    
    if (isInView && vAnimationCount.current ===0 ) {

      if (canvasRef.current && vAnimationCount.current === 0) {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        setContext(ctx);
      }
    }

  }, [isInView]);

  

  useEffect(() => {

        //console.log("ForegroundCanvasErase.useEffect[context].vAnimationCount:  ",vAnimationCount.current) 
        if (vAnimationCount.current === 0 ) {

          let x =  setTimeout(IterateAnimation, 2000)
        }

}, [context]);


  const init = () => {

    const ctx = canvasRef.current.getContext("2d");
    //ctx.fillStyle = "#CC6633";

    const grd = ctx.createRadialGradient(0, 0, dimension.width, dimension.width, dimension.height, 100);
    grd.addColorStop(0, "#CCCCFF");
    grd.addColorStop(1, "#CCCCFF");
    
    // Draw a filled Rectangle
    ctx.fillStyle = grd;                  
    
    //console.log("ForegroundCanvasErase.init.dimension.width:  ",dimension.width, " - height:  ",dimension.height) 
    
    ctx.fillRect(0,0,dimension.width,dimension.height);
    ctx.globalAlpha = 0.2;
    ctx.globalCompositeOperation = "destination-out";
  };


  function render(vclientX, vclientY) {

      vRenderCounts += 1

      const ctx = canvasRef.current.getContext("2d");
      ctx.beginPath();
      img.onload = () => {

        vclientX = (vclientX + Math.floor(Math.random() * 100))-200
        vclientY = (vclientY + Math.floor(Math.random() * 100))-100       
        
        ctx.drawImage(img, vclientX, vclientY);

      }

      
      //img.src = "https://thumbs.onlyfans.com/public/files/thumbs/c144/a/a4/a4u/a4uoj9btfwyrqqvz5p7puyjjwiyskxcr1684331376/6141333/avatar.jpg";        
      img.src = "https://rhyteiticons.s3.us-west-2.amazonaws.com/WebPageArtWork/Silhouette.svg";   

  };

  function IterateAnimation() {
      try {

        
        let vclientX = Math.floor(Math.random() * dimension.width) + 1;
        let vclientY = Math.floor(Math.random() * dimension.height) + 1;
        let vAnimationFrameId = ""
        let vIntervalDelay = 50
        let vAnimationComplete = false
        let vIntervalId = ""
        let vCurrentTime = new Date().getTime();
        // Check if null context has been replaced on component mount

        
      //console.log("ForegroundCanvasErase.IterateAnimation.context:  ",context) 

        if (context) {
            
            while (vAnimationCount.current < 800 && vAnimationComplete === false) {            
    

                //console.log("ForegroundCanvasErase.IterateAnimation.vAnimationCount:  ",vAnimationCount.current, " - vclientY:  ",vclientX, " - vclientY:  ",vclientY, " - vAnimationComplete:  ",vAnimationComplete) 
              
                vIntervalId = setInterval(render, vIntervalDelay, vclientX, vclientY);
                vIntervals.push(vIntervalId)
                //console.log("ForegroundCanvasErase.IterateAnimation.vIntervalId.setInterval:  ",vIntervalId) 
                vclientX = Math.floor(Math.random() * dimension.width) + 1;
                vclientY = Math.floor(Math.random() * dimension.height) + 1;
                vIntervalDelay +=10
                vAnimationCount.current += 1

            }

            vIntervalId = setInterval(stopAllAnimations, 30000, vIntervals);

    
            vAnimationComplete = true
            //console.log("ForegroundCanvasErase.IterateAnimation.vAnimationCount.FINAL:  ",vAnimationCount.current, " - vclientY:  ",vclientX, " - vclientY:  ",vclientY) 
            
    
          }
  

      } catch (err) { 
        console.error("ForegroundCanvasErase.IterateAnimation.err:  ",err)   
                  }
  }         


  function stopAllAnimations() {

    vIntervals.forEach((interval) => {

        //console.log("ForegroundCanvasErase.stopAllAnimations.clearInterval:  ",interval)       
        clearInterval(interval);
    })


  };

  return (
    <div className={styles.BackgroundContainer}
      ref={ref}>
      {dimension.width === 0 && <div className={styles.Rectangle}/>}
      <canvas ref={canvasRef} id="eraseforeground"  height={dimension.height} width={dimension.width} />
    </div>
  );
};

export default ForegroundCanvasErase;
