
import React, { useState , useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Button,
  Image,
  TableBody,
  TableRow,
  TableCell,
  Text,
  TextField,
  TextAreaField,
  Fieldset
  } from '@aws-amplify/ui-react';

  import  {listNewsletters  }  from '../../../graphql/queries'
  import  { deleteContent, updateNewsletterEdition, updateNewsletter }  from '../../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";
  import {
    useInView,
    useScroll,
    useTransform,
    motion,
    useAnimation,
  } from "framer-motion";
  import styles from './NewsletterEditionRow.module.css';  
  import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
  import NewsletterEditionPreview from './NewsletterEditionPreview';
  
function NewsletterEditionRow(
  props
) {       

  NewsletterEditionRow.propTypes = {
    Newsletter: PropTypes.object,
    NewsletterEdition: PropTypes.object,
    Users: PropTypes.string,
    };

    const client = generateClient();
  
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vNewsletter, setvNewsletter] = useState(props.Newsletter)  
    const [vNewsletterEdition, setvNewsletterEdition] = useState()   
    const [vPixelTrackerAPIURL, setvPixelTrackerAPIURL] = useState();

    const [vNewsletterEditionUpdateDateTime, setvNewsletterEditionUpdateDateTime] = useState();
    
    const [Users, setUsers] = useState(props.Users)   

    const [vTemplateJSON, setvTemplateJSON] = useState()  
    const [vViewContentTemplate, setvViewContentTemplate] = useState(false)  
    const [vContentJSON, setvContentJSON] = useState()  

    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);

    const [vPreviewHTML, setvPreviewHTML] = useState(false)   
        
    const [htmlContent, setHtmlContent] = useState('');
    const [vContentHTML, setvContentHTML] = useState('');
    
    const [vIntroductionExpand, setvIntroductionExpand] = useState(false)   
    const [vInThisIssueExpand, setvInThisIssueExpand] = useState(false)   
    const [vPodcastExpand, setvPodcastExpand] = useState(false)   
    const [vFeaturedArticleExpand, setvFeaturedArticleExpand] = useState(false)   
    const [vSuccessStoryExpand, setvSuccessStoryExpand] = useState(false)    
    const [vQuickTipsExpand, setvQuickTipsExpand] = useState(false)     
    const [vTemplateExpand, setvTemplateExpand] = useState(false)     

    const [vExpandCollapseButtonText, setvExpandCollapseButtonText] = useState("Expand All")   
    const [vIntroductionCollapseExpandIcon, setvIntroductionCollapseExpandIcon] = useState("+")   
    const [vInThisIssueCollapseExpandIcon, setvInThisIssueCollapseExpandIcon] = useState("+")  
    const [vPodcastCollapseExpandIcon, setvPodcastCollapseExpandIcon] = useState("+")  
    const [vFeaturedArticleCollapseExpandIcon, setvFeaturedArticleCollapseExpandIcon] = useState("+")  
    const [vSuccessStoryCollapseExpandIcon, setvSuccessStoryCollapseExpandIcon] = useState("+")  
    const [vQuickTipsCollapseExpandIcon, setvQuickTipsCollapseExpandIcon] = useState("+") 
    const [vTemplateCollapseExpandIcon, setvTemplateCollapseExpandIcon] = useState("+") 

      useEffect(() => {

        //console.log(""NewsletterEditionRow.useEffect[].props.Newsletter:  ",props.Newsletter) 

      },[])  

      useEffect(() => {
      ////console.log("NewsletterEditionRow.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);

        if (isInView) {
          mainControls.start("visible");
        }
      }, [isInView]);      

      useEffect(() => {
      ////console.log("NewsletterEditionRow.useEffect[vExpandRow].vExpandRow: " ,vExpandRow);
      }, [vPreviewHTML]);   

      useEffect(() => {
        
        if (vNewsletter !== undefined) {
           
          //console.log("NewsletterEditionRow.useEffect[vNewsletter].vNewsletter[TemplateJSON].typeof: ", typeof vNewsletter["TemplateJSON"]);
          let vTemplateJSONTmp = JSON.parse(vNewsletter["TemplateJSON"])
          if (vTemplateJSONTmp !== undefined) {
            setvTemplateJSON(vTemplateJSONTmp)
          }


        }

      }, [vNewsletter]);         

      useEffect(() => {
        
        if (vTemplateJSON !== undefined) {           
          if('PixelTrackerAPIURL' in vTemplateJSON){
            //console.log("vTemplateJSON[PixelTrackerAPIURL]: ", vTemplateJSON["PixelTrackerAPIURL"]);
            setvPixelTrackerAPIURL(vTemplateJSON["PixelTrackerAPIURL"])
          } else {
            setvPixelTrackerAPIURL("")
          }

          setvNewsletterEdition(props.NewsletterEdition)

        }

      }, [vTemplateJSON]);          

      useEffect(() => {
        
        if (vNewsletterEdition !== undefined) {

          setvContentHTML(vNewsletterEdition["ContentHTML"])

          if('ContentJSON' in vNewsletterEdition){
           
          //console.log("NewsletterEditionRow.useEffect[vNewsletterEdition].vNewsletterEdition[ContentJSON].typeof: ", typeof vNewsletterEdition["ContentJSON"]);
          setvContentJSON(JSON.parse(vNewsletterEdition["ContentJSON"]))         

        }

        setvNewsletterEditionUpdateDateTime(vNewsletterEdition["UpdateDateTime"]);

      }

      }, [vNewsletterEdition]);   

      useEffect(() => {
        
        if (vContentJSON !== undefined && vTemplateJSON !== undefined) {

          //console.log(""NewsletterEditionRow.useEffect[vContentJSON].vContentJSON: ", vContentJSON);
          //console.log(""NewsletterEditionRow.useEffect[vContentJSON].vContentJSON: ", vContentJSON["Sections"][0]["In This Issue"]["Text"]);

          setvViewContentTemplate(true)

        }

      }, [vContentJSON]);    

      async function GetNewsletters() {
        try {
          setErrorMessage("");
          
          //console.log("NewsletterEditionRow.GetNewsletters.Users: ", Users);
          let variables;
            variables = {
              filter: {
                id: {
                  eq: vNewsletter["id"],
                },
              },
            };
    
          const listNewslettersData2 = await client.graphql({
            query: listNewsletters,
            variables: variables,
          });
    
          //console.log("NewsletterEditionRow.GetNewsletters.listNewsletters.data.listNewsletters.items.length : ", listNewslettersData2.data.listNewsletters.items.length );
    
          if (listNewslettersData2.data.listNewsletters.items.length > 0) {
            const NewsletterManager1 =
              listNewslettersData2.data.listNewsletters.items;
              setvNewsletter(NewsletterManager1)
          } 
    
    
    
        } catch (err) {
          RhyteItLogging(
            Users,
            "NewsletterEditionRow",
            "GetNewsletters",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }      

      async function UpdateNewsletter() {
        try {
          
          //console.log("NewsletterEditionRow.UpdateNewsletter.vNewsletter:  ",vNewsletter);
    
          var vUpdateDateTimeNew = new Date().toISOString();
    
          let NewletterItem = {
            id: vNewsletter["id"],
            Newsletter: vNewsletter["Newsletter"],
            Description: vNewsletter["Description"],
            User: vNewsletter["User"],
            TemplateJSON: vNewsletter["TemplateJSON"],
            AccountGroup: vNewsletter["AccountGroup"],
            NewsletterDate: vNewsletter["NewsletterDate"],
            Edition:vNewsletter["Edition"],
            Content: vNewsletter["Content"],
            S3Bucket: vNewsletter["S3Bucket"],
            S3Key: vNewsletter["S3Key"],
            SendCount: vNewsletter["SendCount"],
            OpenCount: vNewsletter["OpenCount"],
            ClickCount: vNewsletter["ClickCount"],
            NewsletterStatus: vNewsletter["NewsletterStatus"],
            Active:vNewsletter["Active"],
            CreationName: vNewsletter["CreationName"],
            CreationDateTime: vNewsletter["CreationDateTime"],
            UpdateName: Users,
            UpdateDateTime: vUpdateDateTimeNew,
          };
    
          ////console.log("NewsletterManagerTable.SaveRow.CampaignItem:  ",CampaignItem);
    
          const updateNewsletterResponse = await client.graphql({
            query: updateNewsletter,
            variables: {
              input: NewletterItem,
            },
          });

          
          //console.log("NewsletterEditionRow.UpdateNewsletter.updateNewsletterResponse:  ",updateNewsletterResponse);

        } catch (err) {
          RhyteItLogging(
            Users,
            "NewsletterEditionRow",
            "UpdateNewsletter",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }

      async function UpdateNewsletterEdition() {
        
  
        try {           
            var today = new Date();
            setErrorMessage("")
            
            var vUpdateDateTime = new Date().toISOString()
  
  
            vNewsletterEdition.UpdateDateTime = vUpdateDateTime       
            vNewsletterEdition.UpdateName = Users           
        
            
            console.log("NewsletterEditionRow.UpdateNewsletterEdition.vContentJSON: ",vContentJSON)

            const vContentJSONString = JSON.stringify(vContentJSON);   
                
            let vNewsletterEditionUpdateItem = {
              "id": vNewsletterEdition["id"],
              "AccountGroup": vNewsletterEdition["AccountGroup"],
              "CampaignName": vNewsletterEdition["CampaignName"],
              "Newsletter": vNewsletterEdition["Newsletter"],
              "User": vNewsletterEdition["User"],
              "NewsletterDate": vNewsletterEdition["NewsletterDate"],
              "Edition": vNewsletterEdition["Edition"],
              "ContentHTML": vContentHTML,
              "S3HtmlLocation": vNewsletterEdition["S3HtmlLocation"],
              "S3Bucket": vNewsletterEdition["S3Bucket"],
              "S3Key": vNewsletterEdition["S3Key"],
              "ContentJSON": vContentJSONString,
              "NewsletterEditionStatus": vNewsletterEdition["NewsletterEditionStatus"],
              "SendCount": vNewsletterEdition["SendCount"],
              "OpenCount": vNewsletterEdition["OpenCount"],
              "ClickCount": vNewsletterEdition["ClickCount"],
              "Active": vNewsletterEdition["Active"],
              "CreationName": vNewsletterEdition["CreationName"],
              "UpdateName": vNewsletterEdition["UpdateName"],
              "CreationDateTime": vNewsletterEdition["CreationDateTime"],
              "UpdateDateTime": vNewsletterEdition["UpdateDateTime"]
            }
  
             //console.log("NewsletterEditionRow.Unsubscribe.vNewsletterEditionUpdateItem: ",vNewsletterEditionUpdateItem)
  
              let updateNewsletterEditionResponse = await client.graphql({                    
                      query: updateNewsletterEdition,
                      variables:{input: vNewsletterEditionUpdateItem}            
                }
              )
            
            console.log("NewsletterEditionRow.Unsubscribe.updateNewsletterEditionResponse: ",updateNewsletterEditionResponse)

            setvNewsletterEdition(updateNewsletterEditionResponse.data.updateNewsletterEdition)        
  
          } catch (err) { 
            RhyteItLogging(Users, "NewsletterEditionRow", "Unsubscribe",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
            console.error("NewsletterEditionRow.Unsubscribe.Error:  ".err, err.stack); 
        }        
      }    
  
      function vPixelTrackerAPIURL_handleChange(event,data) {
        try {
      
                    setvPixelTrackerAPIURL(event.target.value)
                      
  
        } catch (err) { 
                        console.error('NewsletterEditionRow.vPixelTrackerAPIURL_handleChange.ERROR: ', err, err.stack); 
                    }
      }  

      async function SetupPreview() {
        
  
        try {           
            var today = new Date();
            setErrorMessage("")
            
            var vUpdateDateTime = new Date().toISOString()
  
  
            vNewsletterEdition.UpdateDateTime = vUpdateDateTime       
            vNewsletterEdition.UpdateName = Users 
          
            const vContentJSONString = JSON.stringify(vContentJSON);   
                
            let vNewsletterEditionUpdateItem = {
              "id": vNewsletterEdition["id"],
              "AccountGroup": vNewsletterEdition["AccountGroup"],
              "CampaignName": vNewsletterEdition["CampaignName"],
              "Newsletter": vNewsletterEdition["Newsletter"],
              "User": vNewsletterEdition["User"],
              "NewsletterDate": vNewsletterEdition["NewsletterDate"],
              "Edition": vNewsletterEdition["Edition"],
              "ContentHTML": vContentHTML,
              "S3HtmlLocation": vNewsletterEdition["S3HtmlLocation"],
              "S3Bucket": vNewsletterEdition["S3Bucket"],
              "S3Key": vNewsletterEdition["S3Key"],
              "ContentJSON": vContentJSONString,
              "NewsletterEditionStatus": vNewsletterEdition["NewsletterEditionStatus"],
              "SendCount": vNewsletterEdition["SendCount"],
              "OpenCount": vNewsletterEdition["OpenCount"],
              "ClickCount": vNewsletterEdition["ClickCount"],
              "SubscriptionSource": vNewsletterEdition["SubscriptionSource"],
              "UnsubscribeReason": "Administrative",
              "Geolocation": vNewsletterEdition["Geolocation"],
              "SubscriptionPreferences": vNewsletterEdition["SubscriptionPreferences"],
              "BounceReason": vNewsletterEdition["BounceReason"],
              "Active": vNewsletterEdition["Active"],
              "CreationName": vNewsletterEdition["CreationName"],
              "UpdateName": vNewsletterEdition["UpdateName"],
              "CreationDateTime": vNewsletterEdition["CreationDateTime"],
              "UpdateDateTime": vNewsletterEdition["UpdateDateTime"]
            }
  
            //console.log("NewsletterEditionRow.SetupPreview.vNewsletterEditionUpdateItem: ",vNewsletterEditionUpdateItem)
  
            setvNewsletterEdition(vNewsletterEditionUpdateItem)        
  
          } catch (err) { 
            RhyteItLogging(Users, "NewsletterEditionRow", "SetupPreview",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
            console.error("NewsletterEditionRow.SetupPreview.Error:  ".err, err.stack); 
        }        
      }    
  
      function PreviewHTML(event,pPreviewHTML) {
        try {

          //console.log("NewsletterEditionRow.PreviewHTML.pPreviewHTML: ",pPreviewHTML)
          //console.log("NewsletterEditionRow.PreviewHTML.vPreviewHTML: ",vPreviewHTML)
          
          SetupPreview()

          let vPreviewHTMLTmp = !vPreviewHTML

          setvPreviewHTML(vPreviewHTMLTmp)
                      
  
        } catch (err) { 
                        console.error('NewsletterEditionRow.PreviewHTML.ERROR: ', err, err.stack); 
                    }
      }   

      function IntroductionTitle_handleChange(event, data) {
        try {

          let vIntroTitleTmp = event.target.value

          let vContentJSONTmp = vContentJSON

          vContentJSONTmp["Sections"][5]["Introduction"]["Title"] = vIntroTitleTmp         

          setvContentJSON(vContentJSONTmp);

        } catch (err) {
          RhyteItLogging(
            Users,
            "NewsletterEditionRow",
            "Introduction_handleChange",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }

      function Introduction_handleChange(event, data) {
        try {

          let vIntroTitleTmp = event.target.value

          let vContentJSONTmp = vContentJSON

          vContentJSONTmp["Sections"][5]["Introduction"]["Text"] = vIntroTitleTmp         

          setvContentJSON(vContentJSONTmp);

        } catch (err) {
          RhyteItLogging(
            Users,
            "NewsletterEditionRow",
            "Introduction_handleChange",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }

      function InThisIssue_handleChange(event, data) {
        try {

          let vTmp = event.target.value

          let vContentJSONTmp = vContentJSON

          vContentJSONTmp["Sections"][0]["In This Issue"]["Text"] = vTmp         

          setvContentJSON(vContentJSONTmp);

        } catch (err) {
          RhyteItLogging(
            Users,
            "NewsletterEditionRow",
            "InThisIssue_handleChange",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }   

      function PodcastEpisodeTitle_handleChange(event, data) {
        try {

         
          let vTmp = event.target.value

          let vContentJSONTmp = vContentJSON

          vContentJSONTmp["Sections"][4]["Podcast"]["Title"] = vTmp         

          setvContentJSON(vContentJSONTmp);



        } catch (err) {
          RhyteItLogging(
            Users,
            "NewsletterEditionRow",
            "InThisIssue_handleChange",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }      

      function PodcastEpisodeURL_handleChange(event, data) {
        try {


          let vTmp = event.target.value

          let vContentJSONTmp = vContentJSON

          vContentJSONTmp["Sections"][4]["Podcast"]["AVURL"] = vTmp         

          setvContentJSON(vContentJSONTmp);



        } catch (err) {
          RhyteItLogging(
            Users,
            "NewsletterEditionRow",
            "InThisIssue_handleChange",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }    

      function PodcastBannerURL_handleChange(event, data) {
        try {

          let vTmp = event.target.value

          let vContentJSONTmp = vContentJSON

          vContentJSONTmp["Sections"][4]["Podcast"]["ImageURL"] = vTmp         

          setvContentJSON(vContentJSONTmp);


        } catch (err) {
          RhyteItLogging(
            Users,
            "NewsletterEditionRow",
            "PodcastBannerURL_handleChange",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }    

      function FeaturedArticleTitle_handleChange(event, data) {
        try {

          let vTmp = event.target.value

          let vContentJSONTmp = vContentJSON

          vContentJSONTmp["Sections"][1]["Featured Article"]["Title"] = vTmp         

          setvContentJSON(vContentJSONTmp);

        } catch (err) {
          RhyteItLogging(
            Users,
            "NewsletterEditionRow",
            "FeaturedArticleTitle_handleChange",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }      

      function ArticleText_handleChange(event, data) {
        try {

          let vTmp = event.target.value

          let vContentJSONTmp = vContentJSON

          vContentJSONTmp["Sections"][1]["Featured Article"]["Text"] = vTmp         

          setvContentJSON(vContentJSONTmp);

        } catch (err) {
          RhyteItLogging(
            Users,
            "NewsletterEditionRow",
            "FeaturedArticleTitle_handleChange",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }      

      function QuickTipTitle_handleChange(event, data) {
        try {

          console.log("NewsletterEditionRow.QuickTipTitle_handleChange.event.target.value: ",event.target.value)
          let vTmp = event.target.value

          let vContentJSONTmp = vContentJSON

          vContentJSONTmp["Sections"][2]["Quick Tips"]["Title"] = vTmp         

          setvContentJSON(vContentJSONTmp);

        } catch (err) {
          RhyteItLogging(
            Users,
            "NewsletterEditionRow",
            "QuickTipTitle_handleChange",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }      

      function QuickTipText_handleChange(event, data) {
        try {


          console.log("NewsletterEditionRow.QuickTipText_handleChange.event.target.value: ",event.target.value)

          let vTmp = event.target.value

          let vContentJSONTmp = vContentJSON

          vContentJSONTmp["Sections"][2]["Quick Tips"]["Text"] = vTmp         

          setvContentJSON(vContentJSONTmp);

        } catch (err) {
          RhyteItLogging(
            Users,
            "NewsletterEditionRow",
            "QuickTipText_handleChange",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }      

      function SuccessStoryTitle_handleChange(event, data) {
        try {

          console.log("NewsletterEditionRow.SuccessStoryTitle_handleChange.event.target.value: ",event.target.value)
          let vTmp = event.target.value

          let vContentJSONTmp = vContentJSON

          vContentJSONTmp["Sections"][3]["Success Story"]["Title"] = vTmp         

          setvContentJSON(vContentJSONTmp);

        } catch (err) {
          RhyteItLogging(
            Users,
            "NewsletterEditionRow",
            "SuccessStoryTitle_handleChange",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }      

      function SuccessStoryText_handleChange(event, data) {
        try {


          console.log("NewsletterEditionRow.QuickTipText_handleChange.event.target.value: ",event.target.value)

          let vTmp = event.target.value

          let vContentJSONTmp = vContentJSON

          vContentJSONTmp["Sections"][3]["Success Story"]["Text"] = vTmp         

          setvContentJSON(vContentJSONTmp);

        } catch (err) {
          RhyteItLogging(
            Users,
            "NewsletterEditionRow",
            "SuccessStoryText_handleChange",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }      

      function IntroductionExpandHandler(event, data) {
        try {


          console.log("NewsletterEditionRow.IntroductionExpandHandler.event: ",event)
          //console.log("NewsletterEditionRow.IntroductionExpandHandler.event.target.value: ",event.target.value)

          let vTmp = !vIntroductionExpand

          let vIcon = "-"

          if (vTmp === true) {
            vIcon = "-"
          } else {
            vIcon = "+"
          }

          setvIntroductionCollapseExpandIcon(vIcon)

          setvIntroductionExpand(vTmp);

        } catch (err) {
          RhyteItLogging(
            Users,
            "NewsletterEditionRow",
            "IntroductionExpandHandler",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }   

      function vInThisIssueExpandHandler(event, data) {
        try {


          let vTmp = !vInThisIssueExpand 
          let vIcon = "-"

          if (vTmp === true) {
            vIcon = "-"
          } else {
            vIcon = "+"
          }

          setvInThisIssueCollapseExpandIcon(vIcon)

          setvInThisIssueExpand(vTmp);

        } catch (err) {
          RhyteItLogging(
            Users,
            "NewsletterEditionRow",
            "vInThisIssueExpandHandler",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }   

      function vPodcastExpandExpandHandler(event, data) {
        try {


          let vTmp = !vPodcastExpand
          let vIcon = "-"

          if (vTmp === true) {
            vIcon = "-"
          } else {
            vIcon = "+"
          }

          setvPodcastCollapseExpandIcon(vIcon)

          setvPodcastExpand(vTmp);

        } catch (err) {
          RhyteItLogging(
            Users,
            "NewsletterEditionRow",
            "vPodcastExpandExpandHandler",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }   

      function vFeaturedArticleExpandHandler(event, data) {
        try {


          let vTmp = !vFeaturedArticleExpand
          let vIcon = "-"

          if (vTmp === true) {
            vIcon = "-"
          } else {
            vIcon = "+"
          }

          setvFeaturedArticleCollapseExpandIcon(vIcon)

          setvFeaturedArticleExpand(vTmp);

        } catch (err) {
          RhyteItLogging(
            Users,
            "NewsletterEditionRow",
            "vFeaturedArticleExpandHandler",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }       
      
      function vSuccessStoryExpandHandler(event, data) {
        try {


          let vTmp = !vSuccessStoryExpand
          let vIcon = "-"

          if (vTmp === true) {
            vIcon = "-"
          } else {
            vIcon = "+"
          }

          setvSuccessStoryCollapseExpandIcon(vIcon)

          setvSuccessStoryExpand(vTmp);

        } catch (err) {
          RhyteItLogging(
            Users,
            "NewsletterEditionRow",
            "vSuccessStoryExpandHandler",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }    
      
      function vQuickTipsExpandHandler(event, data) {
        try {


          let vTmp = !vQuickTipsExpand
          let vIcon = "-"

          if (vTmp === true) {
            vIcon = "-"
          } else {
            vIcon = "+"
          }

          setvQuickTipsCollapseExpandIcon(vIcon)

          setvQuickTipsExpand(vTmp);

        } catch (err) {
          RhyteItLogging(
            Users,
            "NewsletterEditionRow",
            "vQuickTipsExpandHandler",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }   
      
      function TemplateExpandHandler(event, data) {
        try {


          let vTmp = !vTemplateExpand
          let vIcon = "-"

          if (vTmp === true) {
            vIcon = "-"
          } else {
            vIcon = "+"
          }

          setvTemplateCollapseExpandIcon(vIcon)

          setvTemplateExpand(vTmp);

        } catch (err) {
          RhyteItLogging(
            Users,
            "NewsletterEditionRow",
            "TemplateExpandHandler",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }    

      function vExpandCollapseAll(event, data) {
        try {

          let vButtonText = ""
          let vExColValue = true
          let vExColIcon = "+"
          
          if (vExpandCollapseButtonText === "Expand All"){
            vExColValue = true
            vButtonText = "Collapse All"
            vExColIcon = "-"
          } else {
            vExColValue = false
            vButtonText = "Expand All"
            vExColIcon = "+"
          }
          setvIntroductionExpand(vExColValue);
          setvInThisIssueExpand(vExColValue);
          setvPodcastExpand(vExColValue);
          setvFeaturedArticleExpand(vExColValue);
          setvSuccessStoryExpand(vExColValue);
          setvQuickTipsExpand(vExColValue);
          setvExpandCollapseButtonText(vButtonText);

          
          setvIntroductionCollapseExpandIcon(vExColIcon)
          setvInThisIssueCollapseExpandIcon(vExColIcon)
          setvPodcastCollapseExpandIcon(vExColIcon)
          setvFeaturedArticleCollapseExpandIcon(vExColIcon)
          setvSuccessStoryCollapseExpandIcon(vExColIcon)
          setvQuickTipsCollapseExpandIcon(vExColIcon)

        } catch (err) {
          RhyteItLogging(
            Users,
            "NewsletterEditionRow",
            "vQuickTipsExpandHandler",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }         
      
      function Field_handleChange(event, data, pButtonTitle) {
        try {

          console.log("NewsletterEditionRow.IntroductionButtonTitle_handleChange.pButtonTitle: " ,pButtonTitle);

          let vTmp = event.target.value

          let vContentJSONTmp = vContentJSON

          if (pButtonTitle === "IntroductionButtonTitle") {
            vContentJSONTmp["Sections"][5]["Introduction"]["ButtonTitle"] = vTmp   
          } else if (pButtonTitle === "IntroductionButtonURL") {
            vContentJSON["Sections"][5]["Introduction"]["ButtonURL"]    = vTmp        
          } else if (pButtonTitle === "PodcastButtonTitle") {
            vContentJSON["Sections"][4]["Podcast"]["ButtonTitle"]    = vTmp        
          } else if (pButtonTitle === "PodcastButtonURL") {
            vContentJSON["Sections"][4]["Podcast"]["ButtonURL"]    = vTmp        
          } else if (pButtonTitle === "SuccessStoryButtonTitle") {
            vContentJSON["Sections"][3]["Success Story"]["ButtonTitle"]    = vTmp        
          } else if (pButtonTitle === "SuccessStoryButtonURL") {
            vContentJSON["Sections"][3]["Success Story"]["ButtonURL"]    = vTmp        
          }

          


          setvContentJSON(vContentJSONTmp);

        } catch (err) {
          RhyteItLogging(
            Users,
            "NewsletterEditionRow",
            "Field_handleChange",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }      

      function Template_handleChange(event, data) {
        try {


          let vTmp = event.target.value

          setvContentHTML(vTmp);

        } catch (err) {
          RhyteItLogging(
            Users,
            "NewsletterEditionRow",
            "Template_handleChange",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }            

      

      return (
        <div  ref={ref}  className={styles.Background} 
          key={vNewsletter.id} >
          {
            vViewContentTemplate === true && ( 
            <Flex
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                alignContent="flex-start"
                wrap="wrap"
                gap="1rem"
                className = {styles.FlexColumn}>  
                <Flex
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  alignContent="center"
                  wrap="nowrap"
                  gap="3rem"
                  className={styles.NewNewsletterRow}
                >                
                      <Button
                            className={styles.Button}
                            variation="primary"
                            onClick={(e) => UpdateNewsletterEdition(e, vPreviewHTML)}
                          >
                            Save
                      </Button>    
                      <Text
                                className={styles.TextFieldTitle}
                              >
                              {new Date(vNewsletterEdition["UpdateDateTime"]).toISOString().slice(0, 10)} {new Date(vNewsletterEdition["UpdateDateTime"]).toISOString().slice(11, 16)}
                      </Text>
                      <Button
                            className={styles.Button}
                            variation="primary"
                            onClick={(e) => vExpandCollapseAll(e, vPreviewHTML)}
                          >
                            {vExpandCollapseButtonText}
                      </Button>                                         
                </Flex> 
                <Fieldset
                  legend="Template"
                  variation="outlined"
                  size="large"
                  direction="column"
                  className={styles.Fieldset}
                  >     
                  <div 
                  className={styles.ExpandCollapseIcon}
                  onClick={(e) => TemplateExpandHandler(e)}>{vTemplateCollapseExpandIcon}</div>                
                  {vTemplateExpand === true &&  (     
                    <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      alignContent="center"
                      wrap="nowrap"
                      gap="3rem"
                      className={styles.NewNewsletterRow}
                    >                                                   
                    <Text
                        className={styles.TextFieldTitle}
                      >
                        Template
                    </Text>    
                    <TextAreaField
                      className={styles.TextAreaField}
                      name="vContentHTML"
                      placeholder="Add Template"
                      defaultValue={ vContentHTML}
                      rows={20}
                      resize="vertical"
                      autoResize
                      onChange={(e) => Template_handleChange(e, vContentHTML)}
                    />      
                    </Flex>  
                  )}                                           
                </Fieldset>  
                <Fieldset
                  legend="Introduction"
                  variation="outlined"
                  size="large"
                  direction="column"
                  className={styles.Fieldset}
                  >     
                  <div 
                  className={styles.ExpandCollapseIcon}
                  onClick={(e) => IntroductionExpandHandler(e)}>{vIntroductionCollapseExpandIcon}</div>                
                  {vIntroductionExpand === true &&  (     
                    <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      alignContent="center"
                      wrap="nowrap"
                      gap="3rem"
                      className={styles.NewNewsletterRow}
                    >                                                   
                    <Text
                        className={styles.TextFieldTitle}
                      >
                        Title
                    </Text>    
                    <TextField
                      className={styles.TextField}
                      name="vIntroductionTitle"
                      placeholder="Add Introduction Title"
                      defaultValue={ vContentJSON["Sections"][5]["Introduction"]["Title"]}
                      onChange={(e) => IntroductionTitle_handleChange(e,  vContentJSON["Sections"][5]["Introduction"]["Title"])}
                    />      
                    </Flex> 
                  )}
                  {vIntroductionExpand === true &&  (     
                    <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      alignContent="center"
                      wrap="nowrap"
                      gap="3rem"
                      className={styles.NewNewsletterRow}
                    >                                                   
                    <Text
                        className={styles.TextFieldTitle}
                      >
                        Text
                    </Text>    
                    <TextAreaField
                      className={styles.TextAreaField}
                      name="vIntroduction"
                      placeholder="Enter newsletter introduction"
                      defaultValue={ vContentJSON["Sections"][5]["Introduction"]["Text"]}
                      rows={20}
                      resize="vertical"
                      autoResize
                      onChange={(e) => Introduction_handleChange(e,  vContentJSON["Sections"][5]["Introduction"]["Text"])}
                    />      
                    </Flex> 
                  )}
                  {vIntroductionExpand === true &&  (     
                    <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      alignContent="center"
                      wrap="nowrap"
                      gap="3rem"
                      className={styles.NewNewsletterRow}
                    >                                                   
                    <Text
                        className={styles.TextFieldTitle}
                      >
                        Button Title
                    </Text>    
                    <TextField
                      className={styles.TextField}
                      name="vIntroductionTitle"
                      placeholder="Add Introduction Button Title"
                      defaultValue={ vContentJSON["Sections"][5]["Introduction"]["ButtonTitle"]}
                      onChange={(e) => Field_handleChange(e,  vContentJSON["Sections"][5]["Introduction"]["ButtonTitle"], "IntroductionButtonTitle")}
                    />   
                    </Flex> 
                  )}  
                  {vIntroductionExpand === true &&  (     
                    <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      alignContent="center"
                      wrap="nowrap"
                      gap="3rem"
                      className={styles.NewNewsletterRow}
                    >                                                   
                    <Text
                        className={styles.TextFieldTitle}
                      >
                        Button URL
                    </Text>    
                    <TextField
                      className={styles.TextField}
                      name="vIntroductionTitle"
                      placeholder="Add Introduction Button Title"
                      defaultValue={ vContentJSON["Sections"][5]["Introduction"]["ButtonURL"]}
                      onChange={(e) => Field_handleChange(e,  vContentJSON["Sections"][5]["Introduction"]["ButtonURL"], "IntroductionButtonURL")}
                    />   
                    </Flex> 
                  )}                                   
                </Fieldset>  
                <Fieldset
                  legend="In This Issue"
                  variation="outlined"
                  size="large"
                  direction="column"
                  className={styles.Fieldset}
                  >         
                  <div 
                  className={styles.ExpandCollapseIcon}
                  onClick={(e) => vInThisIssueExpandHandler(e)}>{vInThisIssueCollapseExpandIcon}</div>
                  {vInThisIssueExpand === true &&  (   
                  <Flex
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    alignContent="center"
                    wrap="nowrap"
                    gap="3rem"
                    className={styles.NewNewsletterRow}
                  >                                                                                            
                  <Text
                      className={styles.TextFieldTitle}
                    >
                      Text
                  </Text>    
                  <TextAreaField
                    className={styles.TextAreaField}
                    name="vInThisIssue"
                    placeholder="Enter In this issue"
                    defaultValue={vContentJSON["Sections"][0]["In This Issue"]["Text"]}
                    rows={8}
                    resize="vertical"
                    autoResize
                    onChange={(e) => InThisIssue_handleChange(e, vContentJSON["Sections"][0]["In This Issue"]["Text"])}
                  />    
                  </Flex>                 
                )}
                </Fieldset>  
                <Fieldset
                legend="Podcasting"
                variation="outlined"
                size="large"
                direction="column"
                className={styles.Fieldset}
                >            
                <div 
                className={styles.ExpandCollapseIcon}
                onClick={(e) => vPodcastExpandExpandHandler(e)}>{vPodcastCollapseExpandIcon}</div>
                  {vPodcastExpand === true &&  (        
                  <Flex
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    alignContent="center"
                    wrap="nowrap"
                    gap="3rem"
                    className={styles.NewNewsletterRow}
                  >                  
                    <Text
                          className={styles.TextFieldTitle}
                        >
                          Title
                      </Text>    
                      <TextField
                      className={styles.TextField}
                      name="vPodcastEpisodeTitle"
                      placeholder="Add Podcast episode title"
                      defaultValue={ vContentJSON["Sections"][4]["Podcast"]["Title"]}
                      onChange={(e) => PodcastEpisodeTitle_handleChange(e,  vContentJSON["Sections"][4]["Podcast"]["Title"])}
                    />   
                  </Flex>                  
                  )}       
                  {vPodcastExpand === true &&  (  
                  <Flex
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    alignContent="center"
                    wrap="nowrap"
                    gap="3rem"
                    className={styles.NewNewsletterRow}
                  >                  
                    <Text
                          className={styles.TextFieldTitle}
                        >
                          Episode URL
                      </Text>    
                      <TextField
                      className={styles.TextField}
                      name="vPodcastEpisodeURL"
                      placeholder="Add Podcast episode url"
                      defaultValue={ vContentJSON["Sections"][4]["Podcast"]["AVURL"]}
                      onChange={(e) => PodcastEpisodeURL_handleChange(e,  vContentJSON["Sections"][4]["Podcast"]["AVURL"])}
                    />   
                  </Flex>                   
                  )}       
                  {vPodcastExpand === true &&  (  
                  <Flex
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    alignContent="center"
                    wrap="nowrap"
                    gap="3rem"
                    className={styles.NewNewsletterRow}
                  >                  
                    <Text
                          className={styles.TextFieldTitle}
                        >
                          Banner URL
                      </Text>    
                      <TextField
                      className={styles.TextField}
                      name="vPodcastBannerURL"
                      placeholder="Add Banner URL"
                      defaultValue={vContentJSON["Sections"][4]["Podcast"]["ImageURL"]}
                      onChange={(e) => PodcastBannerURL_handleChange(e, vContentJSON["Sections"][4]["Podcast"]["ImageURL"])}
                    />   
                  </Flex>                   
                  )}

                  {vPodcastExpand === true &&  (     
                    <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      alignContent="center"
                      wrap="nowrap"
                      gap="3rem"
                      className={styles.NewNewsletterRow}
                    >                                                   
                    <Text
                        className={styles.TextFieldTitle}
                      >
                        Button Title
                    </Text>    
                    <TextField
                      className={styles.TextField}
                      name="vIntroductionTitle"
                      placeholder="Add Introduction Button Title"
                      defaultValue={ vContentJSON["Sections"][4]["Podcast"]["ButtonTitle"]}
                      onChange={(e) => Field_handleChange(e,  vContentJSON["Sections"][4]["Podcast"]["ButtonTitle"], "PodcastButtonTitle")}
                    />   
                    </Flex> 
                  )}  
                  {vPodcastExpand === true &&  (     
                    <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      alignContent="center"
                      wrap="nowrap"
                      gap="3rem"
                      className={styles.NewNewsletterRow}
                    >                                                   
                    <Text
                        className={styles.TextFieldTitle}
                      >
                        Button URL
                    </Text>    
                    <TextField
                      className={styles.TextField}
                      name="vIntroductionTitle"
                      placeholder="Add Introduction Button Title"
                      defaultValue={ vContentJSON["Sections"][4]["Podcast"]["ButtonURL"]}
                      onChange={(e) => Field_handleChange(e,  vContentJSON["Sections"][4]["Podcast"]["ButtonURL"], "PodcastButtonURL")}
                    />   
                    </Flex> 
                  )}                  
                </Fieldset>
                <Fieldset
                  legend="Featured Article"
                  variation="outlined"
                  size="large"
                  direction="column"
                  className={styles.Fieldset}
                >                
                <div 
                  className={styles.ExpandCollapseIcon}
                  onClick={(e) => vFeaturedArticleExpandHandler(e)}>
                    {vFeaturedArticleCollapseExpandIcon}
                </div>
                {vFeaturedArticleExpand === true &&  (    
                  <Flex
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    alignContent="center"
                    wrap="nowrap"
                    gap="3rem"
                    className={styles.NewNewsletterRow}
                  >                  
                    <Text
                          className={styles.TextFieldTitle}
                        >
                        Title
                      </Text>    
                      <TextField
                      className={styles.TextField}
                      name="vPodcastEpisodeTitle"
                      placeholder="Add article title"
                      defaultValue={ vContentJSON["Sections"][1]["Featured Article"]["Title"]}
                      onChange={(e) => FeaturedArticleTitle_handleChange(e,  vContentJSON["Sections"][1]["Featured Article"]["Title"])}
                    />   
                  </Flex>  
                  )}      
                {vFeaturedArticleExpand === true &&  (  
                  <Flex
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    alignContent="center"
                    wrap="nowrap"
                    gap="3rem"
                    className={styles.NewNewsletterRow}
                  >                  
                    <Text
                          className={styles.TextFieldTitle}
                        >
                          Article
                      </Text>    
                      <TextAreaField
                          className={styles.TextAreaField}
                          name="vInThisIssue"
                          placeholder="Enter article text"
                          defaultValue={vContentJSON["Sections"][1]["Featured Article"]["Text"]}
                          rows={8}
                          resize="vertical"
                          autoResize
                          onChange={(e) => ArticleText_handleChange(e, vContentJSON["Sections"][1]["Featured Article"]["Text"])}
                      />      
                  </Flex>  
                )}       
                </Fieldset>     
                <Fieldset
                  legend="Quick Tip"
                  variation="outlined"
                  size="large"
                  direction="column"
                  className={styles.Fieldset}
                >                    
                <div 
                  className={styles.ExpandCollapseIcon}
                  onClick={(e) => vQuickTipsExpandHandler(e)}>
                    {vQuickTipsCollapseExpandIcon}
                </div>
                  {vQuickTipsExpand === true &&  (    
                    <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      alignContent="center"
                      wrap="nowrap"
                      gap="3rem"
                      className={styles.NewNewsletterRow}
                    >                  
                      <Text
                            className={styles.TextFieldTitle}
                          >
                          Title
                        </Text>    
                        <TextField
                        className={styles.TextField}
                        name="vPodcastEpisodeTitle"
                        placeholder="Add Podcast episode title"
                        defaultValue={ vContentJSON["Sections"][2]["Quick Tips"]["Title"]}
                        onChange={(e) => QuickTipTitle_handleChange(e,  vContentJSON["Sections"][2]["Quick Tips"]["Title"])}
                      />   
                    </Flex>  
                  )}      
                  {vQuickTipsExpand === true &&  (         
                    <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      alignContent="center"
                      wrap="nowrap"
                      gap="3rem"
                      className={styles.NewNewsletterRow}
                    >                  
                      <Text
                            className={styles.TextFieldTitle}
                          >
                            Quick Tip
                        </Text>    
                        <TextAreaField
                            className={styles.TextAreaField}
                            name="vInThisIssue"
                            placeholder="Enter article text"
                            defaultValue={vContentJSON["Sections"][2]["Quick Tips"]["Text"]}
                            rows={8}
                            resize="vertical"
                            autoResize
                            onChange={(e) => QuickTipText_handleChange(e, vContentJSON["Sections"][2]["Quick Tips"]["Text"])}
                        />      
                    </Flex>  
                  )}              
                </Fieldset>             
                <Fieldset
                  legend="Success Story"
                  variation="outlined"
                  size="large"
                  direction="column"
                  className={styles.Fieldset}
                  >                      
                  <div 
                    className={styles.ExpandCollapseIcon}
                    onClick={(e) => vSuccessStoryExpandHandler(e)}>
                      {vSuccessStoryCollapseExpandIcon}
                  </div>
                  {vSuccessStoryExpand === true &&  (       
                  <Flex
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    alignContent="center"
                    wrap="nowrap"
                    gap="3rem"
                    className={styles.NewNewsletterRow}
                  >                  
                    <Text
                          className={styles.TextFieldTitle}
                        >
                        Title
                      </Text>    
                      <TextField
                      className={styles.TextField}
                      name="vPodcastEpisodeTitle"
                      placeholder="Add Success Story Title title"
                      defaultValue={ vContentJSON["Sections"][3]["Success Story"]["Title"]}
                      onChange={(e) => SuccessStoryTitle_handleChange(e,  vContentJSON["Sections"][3]["Success Story"]["Title"])}
                    />   
                  </Flex>   
                  )}         
                   {vSuccessStoryExpand === true &&  (       
                  <Flex
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    alignContent="center"
                    wrap="nowrap"
                    gap="3rem"
                    className={styles.NewNewsletterRow}
                  >                  
                    <Text
                          className={styles.TextFieldTitle}
                        >
                          Text
                      </Text>    
                      <TextAreaField
                          className={styles.TextAreaField}
                          name="vInThisIssue"
                          placeholder="Enter Success Story Text"
                          defaultValue={vContentJSON["Sections"][3]["Success Story"]["Text"]}
                          rows={8}
                          resize="vertical"
                          autoResize
                          onChange={(e) => SuccessStoryText_handleChange(e, vContentJSON["Sections"][3]["Success Story"]["Text"])}
                      />      
                  </Flex>  
                  )}    
                  {vSuccessStoryExpand === true &&  (     
                    <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      alignContent="center"
                      wrap="nowrap"
                      gap="3rem"
                      className={styles.NewNewsletterRow}
                    >                                                   
                    <Text
                        className={styles.TextFieldTitle}
                      >
                        Button Title
                    </Text>    
                    <TextField
                      className={styles.TextField}
                      name="vIntroductionTitle"
                      placeholder="Add Introduction Button Title"
                      defaultValue={ vContentJSON["Sections"][3]["Success Story"]["ButtonTitle"]}
                      onChange={(e) => Field_handleChange(e,  vContentJSON["Sections"][3]["Success Story"]["ButtonTitle"], "SuccessStoryButtonTitle")}
                    />   
                    </Flex> 
                  )}  
                  {vSuccessStoryExpand === true &&  (     
                    <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      alignContent="center"
                      wrap="nowrap"
                      gap="3rem"
                      className={styles.NewNewsletterRow}
                    >                                                   
                    <Text
                        className={styles.TextFieldTitle}
                      >
                        Button URL
                    </Text>    
                    <TextField
                      className={styles.TextField}
                      name="vIntroductionTitle"
                      placeholder="Add Introduction Button Title"
                      defaultValue={ vContentJSON["Sections"][3]["Success Story"]["ButtonURL"]}
                      onChange={(e) => Field_handleChange(e,  vContentJSON["Sections"][3]["Success Story"]["ButtonURL"], "SuccessStoryButtonURL")}
                    />   
                    </Flex> 
                  )}             
                </Fieldset> 
                <Flex
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  alignContent="center"
                  wrap="nowrap"
                  gap="3rem"
                  className={styles.NewNewsletterRow}
                >                                                           
                    <Button
                          className={styles.Button}
                          variation="primary"
                          onClick={(e) => PreviewHTML(e, vPreviewHTML)}
                        >
                          Preview
                    </Button>                                       
                </Flex>          
            </Flex>
            )}
            {vPreviewHTML === true &&  (
              <NewsletterEditionPreview  key={vNewsletterEdition.id}  Users={Users} Newsletter={vNewsletter} NewsletterEdition={vNewsletterEdition}/>
            )}
                        
        </div>
 
    )
  }
export default NewsletterEditionRow;

