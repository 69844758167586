import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {motion as m} from "framer-motion";
import Marquee from "react-fast-marquee";
import styled, { keyframes } from "styled-components";
import styles from './TreeGrowth.module.css';
import {
  Image
} from '@aws-amplify/ui-react';
const TreeGrowth = (props) => {       
  
  TreeGrowth.propTypes = {
          RedditUser: PropTypes.object
        };



        const [OnlyFansProspectingProfilesData, setOnlyFansProspectingProfilesData] = useState([])              
              

        useEffect(() => {

          (async function() {
            try {  

                    GetOnlyFansProspectingProfilesFromS3()


                } catch (err) {
                  console.error('TreeGrowth.useEffect[].ERROR: ', err, err.stack); 
              }
            }
          )();   
      

        },[]) 

     
        async function GetOnlyFansProspectingProfilesFromS3() {
          try {


                    const url = `https://rhyteitfiles.s3.us-west-2.amazonaws.com/ScollingData/OFProfiles.json`

                    const fetchResponse = await fetch(url);
                    let OnlyFansProspectingProfilesData1 = await fetchResponse.json();

                    setOnlyFansProspectingProfilesData(OnlyFansProspectingProfilesData1)                    


  
          } catch (err) { 
                          console.error('TreeGrowth.GetOnlyFansProspectingProfiles.ERROR: ', err, err.stack); 
                      }
        }  
        
        function OpenPostURL(event,pPostURL) {
          try {
    
              if (pPostURL.length > 0) {
                window.open(pPostURL, '_blank');
              }
            } catch (err) { 
    
            console.error(err); 
             
          }        
        }  


    return ( 
            <div className={styles.MarqueeWrapper}> 
              <div
                className={styles.MarqueeBlock}
              >        
                <div
                  className={styles.MarqueeInner}
                >                
                {
                    OnlyFansProspectingProfilesData.map && OnlyFansProspectingProfilesData.map((vOnlyFansProfile) => (                
                    <div className={styles.ImageContainer} 
                    key={vOnlyFansProfile.id}> 
                      <Image
                        src={vOnlyFansProfile.OnlyFansProfilePictureURL}
                        className={styles.PhotoImage}
                        onClick={ (e) => OpenPostURL(e, vOnlyFansProfile.OnlyFansProfileURL) }   
                      />
                    </div>  

                  ))
                }    
                </div>   
              </div>    
            </div>  
    )

  }
 

export default TreeGrowth;