
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Text,
  TableBody,
  TextField,  
  Button,
  Image
  } from '@aws-amplify/ui-react';

  import  {  }  from '../../graphql/queries'
  import  { deleteRhyteItEmails, updateRhyteItEmails}  from '../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";

  import styles from './EmailRow.module.css';  
  import { UserContext} from '../../BusinessLogic/Hooks/UserContext';  

const EmailRow = (props) => {       

  EmailRow.propTypes = {
      Email: PropTypes.object
      };

    const client = generateClient();
  
    const {Users, setUsers} = useContext(UserContext);   
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""
    let debugConsoleOutPut = true

    const [message, setMessage] = useState('');

    const [Email, setEmail] = useState(props.Email)      

    useEffect(() => {

       // //console.log("EmailRow.useEffect[].props.Email:  ",props.Email) 
      
      },[])  

      async function UpdateEmailStatus(pStatus) {
        try {
  
  
              if (Users === "edwardhawke@rhyteit.com") {
  
                
  
                var vUpdateDateTime = new Date().toISOString()
                
                Email.UpdateDateTime = vUpdateDateTime       
                Email.UpdateName = Users
  
                  let EmailItem = {
                    "id": Email.id,
                    "EmailAccount": Email.EmailAccount,
                    "From": Email.From,
                    "To": Email.To,
                    "Subject": Email.Subject,
                    "S3Bucket": Email.S3Bucket,
                    "S3Key": Email.S3Key,
                    "EmailText":  Email.EmailText,
                    "EmailHtml": Email.EmailHtml,
                    "EmailRawText": Email.EmailRawText,
                    "EmailStatus": pStatus,
                    "ContentType": Email.ContentType,
                    "Size": Email.Size,
                    "SizeUnits": Email.SizeUnits,
                    "ContentTransferEncoding": Email.ContentTransferEncoding,
                    "InReplyTo": Email.InReplyTo,
                    "MessageID": Email.MessageID,
                    "CharSet": Email.CharSet,
                    "CreationName": Email.CreationName,
                    "CreationDateTime": Email.CreationDateTime,
                    "UpdateName": Email.UpdateName,
                    "UpdateDateTime": Email.UpdateDateTime,
                }
  
                //console.log("EmailDetail.UpdateToReadStatus.EmailItem    :  ",EmailItem    ) 
  
                const updateRhyteItEmailsResponse = await client.graphql({
                  query: updateRhyteItEmails,
                  variables: {
                    input: EmailItem
                  }
                });  
  
                setEmail(updateRhyteItEmailsResponse.data.updateRhyteItEmails)
                setErrorMessage(ErrorMessageStr)
      
   
                
            } else {
  
              ErrorMessageStr = "You do not have permission to update email"
              setErrorMessage(ErrorMessageStr)  
  
            }
        
            } catch (err) { 
  
            ErrorMessageStr = "EmailDetail.UpdateToReadStatus.Error: " + err 
  
            console.error(err); 
            
            setErrorMessage(ErrorMessageStr)         
          }               
        
      }    

 

    function EditEmailRow(event, data) {
      try {           
        
          setErrorMessage("")


        } catch (err) { 
          ErrorMessageStr = ("Email.EditEmailRow.Error: ", err, err.stack)
          console.error(ErrorMessageStr)
          setErrorMessage(ErrorMessageStr)
      }        
    }      
    



  return (
      <TableBody>  
        
          <TableRow  className={Email.EmailStatus === "Processed" ?  styles.TableRowProcessed 
                                  : Email.EmailStatus === "read" ?  styles.TableRowRead 
                                  : Email.EmailStatus === "draft" ?  styles.TableRowDraft
                                  : Email.EmailStatus === "SEND" ?  styles.TableRowSend 
                                  : Email.EmailStatus === "delete" ?  styles.TableRowDelete 
                                  : Email.EmailStatus === "sent" ?  styles.TableRowSent 
                                  : styles.TableRowBackground} key={Email.id}>
            <TableCell className={styles.TableRowHeadingData} >  
                <Text  className={styles.TextField}>
                                  {Email.From}
                </Text>  
              </TableCell>                                        
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {Email.To}
                </Text>  
            </TableCell>                                          
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {Email.Subject}
                </Text>  
            </TableCell>                    
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextFieldRecieved}>
                                  {new Date(Email.CreationDateTime).toLocaleString()}
                </Text>  
            </TableCell>       
            <TableCell as="td"  className={styles.RhyteItMenuCell}> 
            <Link key={Email.id} isExternal={false} to="/EmailDetail" state={{ vEmail: Email }} >   
                  <Button
                    loadingText=""
                    ariaLabel=""
                    className={styles.RowButtons}
                    onClick={(e) => EditEmailRow(e, Email)}
                  >
                  E
                  </Button> 
              </Link>                 
            </TableCell>   
            <TableCell as="td"  className={styles.RhyteItMenuCell}> 
                  <Button
                    loadingText=""
                    ariaLabel=""
                    className={styles.RowButtons}
                    onClick={(e) => UpdateEmailStatus("delete") }
                  >
                  D
                  </Button>                 
            </TableCell>                                                        
          </TableRow>  
      {
        ErrorMessage.length > 0 &&             
          <TableRow  >              
            <TableCell 
              colSpan="7">   
              <Text className={styles.ErrorMessageRow}>  
              {ErrorMessage}
              </Text> 
            </TableCell>               
          </TableRow>  
      }                        
      </TableBody>
    
  )
}

export default EmailRow;

