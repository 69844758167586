import React, { useState, useEffect, useContext, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./LetsTalk.module.css";
import Background from "./LetsTalkBackgroundV2";

import {
  useInView,
  useScroll,
  useTransform,
  motion,
  useAnimation,
} from "framer-motion";
//import {useInView} from "react-intersection-observer";

import { Button, Flex, Text, TextAreaField } from "@aws-amplify/ui-react";


import Footer from "../../RhyteItComponents/Footer/Footer";

const LetsTalk = (props) => {
  LetsTalk.propTypes = {
    scrollYProgress: PropTypes.object,
  };


  const refLetsTalkSection = useRef(null);
  const isInView = useInView(refLetsTalkSection);

  useEffect(() => {}, []);

  useEffect(() => {
    //console.log("LetsTalk.useEffect[isInView].props.scrollYProgress: ", props.scrollYProgress);

    if (isInView) {
    }
  }, [isInView]);

  return (
    <section ref={refLetsTalkSection} className={styles.SectionContainer}>
      <Background className={styles.Background} />
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="start"
        alignContent="center"
        wrap="nowrap"
        className={styles.TextFlexColumn}
      >
        <a id="" href="mailto:support@rhyteit.com" className={styles.Title}>
          {" "}
          Let's Talk.{" "}
        </a>
        <Footer  className={styles.Footer}/>
      </Flex>
    </section>
  );
};

export default LetsTalk;
