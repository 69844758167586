import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext, useRef } from 'react';

import  { listBatchJobs, listEmailAccounts }  from '../../../graphql/queries'
import  { createBatchJobs }  from '../../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import Header from "../../RhyteItComponents/Header/Header";

import { UserContext, CognitoGroupContext} from '../../../BusinessLogic/Hooks/UserContext';
import {GetGroupRoleAccess, RhyteItLogging, ReturnUserToLandingPage} from '../../../BusinessLogic/UserManagement';
import { fetchAuthSession } from 'aws-amplify/auth';

import {motion as m} from "framer-motion";
import SMMADBatchJobsTable from "./SMMADBatchJobsTable";
import {get_uuidv4} from '../../../BusinessLogic/uuidv4';


import {
  Button , Flex, SelectField,Text
} from '@aws-amplify/ui-react';
import styles from './SMMADBatchJobs.module.css';

const SMMADBatchJobs = (props) => {       
  
  SMMADBatchJobs.propTypes = {
          RedditUser: PropTypes.object
        };

        const client = generateClient();
        const [vSMMADBatchJobs, setvSMMADBatchJobs] = useState([]) 
        const vBreadCrumbItems = [{Path: "/", Title:"Home", isCurrent:false},{Path: "/MainMenuV2", Title:"Menu", isCurrent:false},{Path: "/SMMADBatchJobs", Title:"SMMADBatchJobs", isCurrent:true}]
     
        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr) 

        const {Users, setUsers} = useContext(UserContext);    
        const {CognitoGroup, setCognitoGroup} = useContext(CognitoGroupContext);        
        const [SMMADBatchJobsReturned, setSMMADBatchJobsReturned] = useState(0);
        const [ShowTable, setShowTable] = useState(false);
        const [vFromAccount, setvFromAccount] = useState();
        const [vToAccount, setvToAccount] = useState();
        const [vFromAccountSelected, setvFromAccountSelected] = useState();
        const [vToAccountSelected, setvToAccountSelected] = useState();
        
        

        const [updatedKey, setupdatedKey] = useState(Date.now())   

        useEffect(() => {


        },[]) 


           useEffect(() => {
             
            (async function() {
              try {  
  
                      if (CognitoGroup !== undefined) {
  
                        let   GetGroupRoleAccessResponse = await GetGroupRoleAccess(CognitoGroup, "rhyteitAdmins")
                        
                        if (GetGroupRoleAccessResponse) {  
                          GetRhyteItAccountRecords()
                          GetSMMADBatchJobs()
                        }
                            
  
                      } else {
  
                        const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
  
                        if (idToken !== undefined) {
                          setUsers(idToken.payload.email)
                          setCognitoGroup(accessToken["payload"]["cognito:groups"])
                        } else {
                          
                          ReturnUserToLandingPage(Users, CognitoGroup)
                        }
  
                      }
                    } catch (err) {                  
                      RhyteItLogging(Users, "SMMADBatchJobs", "useEffect[CognitoGroup]",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                  }
                }
              )();   
      
             },[CognitoGroup])           
  

        useEffect(() => {
          //console.log("SMMADBatchJobs.useEffect[SMSMMADBatchJobs].SMSMMADBatchJobs:  ",SMSMMADBatchJobs)
          //console.log("SBSMQueries.useEffect[SBSMNewsQueryTerms].Object.keys(SBSMNewsQueryTerms).length:  ",Object.keys(SBSMNewsQueryTerms).length )
 
           if (vSMMADBatchJobs.length > 0) {
             setShowTable(true)                  
             setupdatedKey(Date.now())    
           }
 
           setErrorMessage("SMMADBatchJobs retrieved at: " + new Date().toLocaleString())
 
         },[vSMMADBatchJobs]) 
         
        async function GetRhyteItAccountRecords() {
          try {
                    setErrorMessage("")
                    setvFromAccount([])
                    setvToAccount([])
    

                    const variables = {
                      filter: {
                        UserId: {
                                eq: Users
                            }
                      }
                    };
      
                  const RhyteItAccountRecordsData  = await client.graphql({
                      query: listEmailAccounts,
                      variables: variables
                    });                   


                    const RhyteItAccountRecords = RhyteItAccountRecordsData.data.listEmailAccounts.items 
                    
                    let RhyteItAccountRecordsSorted = await SortAccountsByName(RhyteItAccountRecords)  
                    
                    setvFromAccount(RhyteItAccountRecordsSorted)
                    setvToAccount(RhyteItAccountRecordsSorted)
                    
                    return RhyteItAccountRecords
  
          } catch (err) {        
            RhyteItLogging(Users, "SMMADBatchJobs", "GetRhyteItAccountRecords",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                      }
        } 
        
    function SortAccountsByName(pRhyteItAccounts) {
      pRhyteItAccounts.sort(GFG_sortFunction);
          return pRhyteItAccounts
      //    //console.log(JSON.stringify(pEmails));
      }

    function GFG_sortFunction(a, b) {
        let RhyteItAccountA = a.id;
        let RhyteItAccountB = b.id;
        return RhyteItAccountA > RhyteItAccountB ? 1 : -1;
    };             

        async function GetSMMADBatchJobs() {
          try {
                    setErrorMessage("")
                    setvSMMADBatchJobs([])

                    const variables = {
                      filter: {
                        Account: {
                                eq: Users
                            }
                      }
                    };

                    const SMMADBatchJobsData2  = await client.graphql({
                      query: listBatchJobs,
                      variables: variables
                    });
      
          

                    if (SMMADBatchJobsData2.data.listBatchJobs.items.length > 0) {
                        //console.log("SMMADBatchJobsData2.data.listBatchJobs.items.length: ",SMMADBatchJobsData2.data.listBatchJobs.items.length);
                        setSMMADBatchJobsReturned(SMMADBatchJobsData2.data.listBatchJobs.items.length)
                        const SMMADBatchJobs1 = SMMADBatchJobsData2.data.listBatchJobs.items 

                        let SMMADBatchJobsSorted = await SortJobsByDate(SMMADBatchJobs1)  
                        setvSMMADBatchJobs(SMMADBatchJobsSorted)  
           
                  }

          } catch (err) { 
            RhyteItLogging(Users, "SMMADBatchJobs", "GetSMMADBatchJobs",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                      }
        }   

        function SortJobsByDate(pBatchJobs) {
          pBatchJobs.sort(DateSortFunction);
              return pBatchJobs
          //    //console.log(JSON.stringify(pEmails));
          }
    
        function DateSortFunction(a, b) {
            let RhyteItAccountA = a.CreationDateTime;
            let RhyteItAccountB = b.CreationDateTime;
            return RhyteItAccountA < RhyteItAccountB ? 1 : -1;
        };       


        function FromAccountSelected(pAccount) {
          try {
    
                    setvFromAccountSelected(pAccount)
     
                    console.log("SMMADBatchJobs.FromAccountSelected.pAccount: ",pAccount); 
    
          } catch (err) { 
            RhyteItLogging(Users, "SMMADBatchJobs", "FromAccountSelected",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                      }
        }   

        function ToAccountSelected(pAccount) {
          try {
    
                    setvToAccountSelected(pAccount)
     
                    console.log("SMMADBatchJobs.ToAccountSelected.pAccount: ",pAccount); 
    
          } catch (err) { 
            RhyteItLogging(Users, "SMMADBatchJobs", "ToAccountSelected",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                      }
        } 

        async function CopyCampaign(event, data) {
          try {           
            
              setErrorMessage("")
    
              var vUpdateDateTimeNew = new Date().toISOString()
          
              var idString = await get_uuidv4()

              let BatchJobItem = {
                  "id": idString,
                  "BatchJobName": "CopySMMADCampaignToAnotherUser",
                  "Account": Users,
                  "Service": "Lambda",
                  "JobJSON": "{\"FromAccount\": \"" + vFromAccountSelected + "\", \"ToAccount\": \"" + vToAccountSelected+ "\"}",
                  "BatchJobStatus":  "new",
                  "CreationName": Users,
                  "CreationDateTime": vUpdateDateTimeNew,
                  "UpdateName": Users,
                  "UpdateDateTime": vUpdateDateTimeNew,
              }
              const createBatchJobsResponse = await client.graphql({
                query: createBatchJobs,
                variables: {
                  input: BatchJobItem
                }
              });  
    
    
            } catch (err) { 
              RhyteItLogging(Users, "SMMADBatchJobs", "CopyCampaign",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          }        
        }      
                
    return ( 
    <m.div
      key="Search"
      initial={{x: "100%"}}
      animate={{x: "0%"}}
      transition={{duration:0.75, ease: "easeOut"}}
      exit={{ opacity: 1 }}
     >
                          
                  <Flex
                      direction="column"
                      justifySMMADBatchJobs="flex-start"
                      alignItems="stretch"
                      alignSMMADBatchJobs="flex-start"
                      wrap="nowrap"
                      gap="3rem"
                      className={styles.GridContainer}
                    >     
                      <Header Title="Batch Job Management" SubTitle="Manage Batch Job" BreadCrumbItems ={vBreadCrumbItems} pageSource="SMMADBatchJobs"/>  
                      <Flex
                          direction="row"
                          justifySMMADBatchJobs="flex-start"
                          alignItems="stretch"
                          alignSMMADBatchJobs="flex-start"
                          wrap="nowrap"
                          gap="3rem"
                        >   
                        
                      <SelectField
                          className={styles.SelectField}
                          value={vFromAccountSelected}
                          placeholder="From Account"
                          onChange={(e) => FromAccountSelected(e.target.value)}>
                        {
                          vFromAccount !== undefined && vFromAccount.map((FromAccount) => (                      
                            ( <option 
                              className={styles.SelectFieldOption} value={FromAccount.EmailAccount} label={FromAccount.id}>
                                {FromAccount.id}
                              </option>)        
                            ))
                        }   
                      </SelectField>         
                      <SelectField
                          className={styles.SelectField}
                          value={vToAccountSelected}
                          placeholder="To Account"
                          onChange={(e) => ToAccountSelected(e.target.value)}>
                        {
                          vToAccount !== undefined && vToAccount.map((ToAccount) => (                      
                            ( <option 
                              className={styles.SelectFieldOption} value={ToAccount.EmailAccount} label={ToAccount.id}>
                                {ToAccount.id}
                              </option>)        
                            ))
                        }   
                      </SelectField>                                                 
                        <Button className={styles.StorageManagerButton}
                        variation="primary"         
                        onClick={ (e) => CopyCampaign(e)}
                        >
                              Copy
                        </Button>            
                        <Button className={styles.StorageManagerButton}
                                    variation="primary"                                  
                                    onClick={ (e) => GetSMMADBatchJobs() }
                                    >
                              Refresh
                        </Button>                                                                                                                                                                                                                                                                
                      </Flex>                                
                      <div className={styles.MessageBox}>{ErrorMessage} SMMADBatchJobs retrieved: {SMMADBatchJobsReturned}</div> 
                       {vSMMADBatchJobs.length > 0 && (<SMMADBatchJobsTable   key={updatedKey}  SMBatchJobs={vSMMADBatchJobs}/>)  }                                                                                                           
                                      
                    </Flex>    
    </m.div>                      

    )

  }
 

export default SMMADBatchJobs;