import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';

import  { listSMMADContents }  from '../../../graphql/queries'
import  { }  from '../../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import ContentTable from "./ContentTable";
import { UserContext} from '../../../BusinessLogic/Hooks/UserContext';


import {
  Button, Flex,Badge, Divider, Text, Fieldset 
} from '@aws-amplify/ui-react';
import styles from './Content.module.css';
import { StorageManager } from '@aws-amplify/ui-react-storage';

const Content = (props) => {       
  
  Content.propTypes = {
          AccountSelected: PropTypes.string,
          AccountGroup: PropTypes.string,
          CampaignSelected: PropTypes.string,
          HashTagMap: PropTypes.array,
          ImageToTextRelationship: PropTypes.bool
        };

        const client = generateClient();
        const [SMContent, setSMContent] = useState([]) 
        const [vAccountSelected, setvAccountSelected] = useState(props.AccountSelected) 
        const [vAccountGroup, setvAccountGroup] = useState(props.AccountGroup)     
        const [vCampaignSelected, setvCampaignSelected] = useState(props.CampaignSelected)    
        const [vHashTagMap, setvHashTagMap] = useState(props.HashTagMap) 
        
      
        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   
      
        const {Users, setUsers} = useContext(UserContext);         

        const [updatedKey, setupdatedKey] = useState(Date.now())   
        const [ShowUpload, setShowUpload] = useState(false)   
        
        const [vContentHelpBadgeHovered, setvContentHelpBadgeHovered] = useState(false) 
        const [nextToken, setnextToken] = useState(undefined) 
        const [IsLoading, setIsLoading] = useState(false)
        const [vSortNewestToOldest, setvSortNewestToOldest] = useState(true)
        const [vSortButtonText, setvSortButtonText] = useState("Upload date \u21E9")

        const [vSortLastPostDateDesc, setvSortLastPostDateDesc] = useState(true)
        const [vPostDateSortButtonText, setvPostDateSortButtonText] = useState("Post Date \u21E9")

        const [vSortPostCountDesc, setvSortPostCountDesc] = useState(true)
        const [vSortPostCountButtonText, setvSortPostCountButtonText] = useState("Post # \u21E9")

        const [vSortWeightDesc, setvSortWeightDesc] = useState(true)
        const [vSortHighestWeightPostButtonText, setvSortHighestWeightPostButtonText] = useState("Weight \u21E9")

     
        const [vSortButtonStyle, setvSortButtonStyle] = useState("CreationDateTime")   

        useEffect(() => {

       //   //console.log("Content.useEffect[].vHashTagMap:  ",vHashTagMap)
          if (vCampaignSelected !== undefined) {
            GetContent()  
          }

      

        },[]) 
          

        useEffect(() => {
         // //console.log("Content.useEffect[SMContent].SMContent:  ",SMContent)
          //console.log("SBSMQueries.useEffect[SBSMNewsQueryTerms].Object.keys(SBSMNewsQueryTerms).length:  ",Object.keys(SBSMNewsQueryTerms).length )
 
           if (SMContent.length > 0) {         
             setupdatedKey(Date.now())    
           }
 
         },[SMContent])    

         useEffect(() => {
          // //console.log("Content.useEffect[SMContent].SMContent:  ",SMContent)
           //console.log("SBSMQueries.useEffect[SBSMNewsQueryTerms].Object.keys(SBSMNewsQueryTerms).length:  ",Object.keys(SBSMNewsQueryTerms).length )
  

          },[vSortNewestToOldest])            


         useEffect(() => {
            // //console.log("Content.useEffect[SMContent].SMContent:  ",SMContent)
            //console.log("SBSMQueries.useEffect[SBSMNewsQueryTerms].Object.keys(SBSMNewsQueryTerms).length:  ",Object.keys(SBSMNewsQueryTerms).length )
    
    
            if (IsLoading === false && SMContent.length> 0) {

            let vSMContent = SMContent.sort( SortNewestToOldest );
            setSMContent(SMContent)  
            
            }
    
          },[IsLoading])            

         useEffect(() => {

          const fetch1 = async () => {


            try {

                  let ContentData = null

                  if(nextToken !== null) {
                    setIsLoading(true)

                      var datetime = new Date();                                                
                      let limit = 10000000
                      //console.log("TwitterStats.useEffect[NextToken].nextToken: ", nextToken);   
                      
                      let  variables = {
                        nextToken,
                        filter: {
                          Account: {
                                  eq: vAccountSelected
                              },
                            Campaign: {
                                    eq: vCampaignSelected
                                }
                        }
                      };   
                      
                      const ContentData2  = await client.graphql({
                        query: listSMMADContents,
                        variables: variables
                      });
        
            
            
                    //console.log("Content.GetContent.ContentData2.data.listSMMADContents.nextToken:  ",ContentData2.data.listSMMADContents.nextToken)

                    ContentData = SMContent                   
                          
          
                    ContentData2.data.listSMMADContents.items.forEach(item => 
                      {   
                    
                        if ([...ContentData].filter(Content => item.id === Content.id)["length"] === 0) {  
                          ContentData.push(item) 
                        }
  
                      })


                        //console.log("TwitterStats.useEffect[NextToken].elseif.TwitterStatsRecordsData2.data.listEmailAccountStats.nextToken: ", TwitterStatsRecordsData2.data.listEmailAccountStats.nextToken);   
                        //console.log("TwitterStats.useEffect[NextToken].elseif.TwitterStatsRecordsData2.length: ", TwitterStatsRecordsData2.data.listEmailAccountStats.items.length);  
                        //console.log("TwitterStats.useEffect[NextToken].elseif.TwitterStatsRecordsData2.items: ", TwitterStatsRecordsData2.data.listEmailAccountStats.items);  

                        setSMContent(ContentData)  
                        setnextToken(ContentData2.data.listSMMADContents.nextToken)  

                                            

                      
                  } else if (nextToken === null){          
                    
                    
                    setIsLoading(false)
             
                                                          
    
                  }
  
            } catch (err) {
              //console.log(err)
            } finally {
              setIsLoading(false)           
            }
          }
        
          fetch1()

        }, [nextToken])   
                
        function SortNewestToOldest( a, b ) {
          if ( a.CreationDateTime > b.CreationDateTime ){
            return -1;
          }
          if ( a.CreationDateTime < b.CreationDateTime ){
            return 1;
          }
          return 0;
        }

        function SortOldestToNewest ( a, b ) {
          if ( a.CreationDateTime < b.CreationDateTime ){
            return -1;
          }
          if ( a.CreationDateTime > b.CreationDateTime ){
            return 1;
          }
          return 0;
        }

        function SortLastPostDesc( a, b ) {
          if ( a.LastPostDate > b.LastPostDate ){
            return -1;
          }
          if ( a.LastPostDate < b.LastPostDate ){
            return 1;
          }
          return 0;
        } 

        function SortLastPostAsc ( a, b ) {
          if ( a.LastPostDate < b.LastPostDate ){
            return -1;
          }
          if ( a.LastPostDate > b.LastPostDate ){
            return 1;
          }
          return 0;
        }        
                      
        function SortWeightDesc( a, b ) {
          if ( a.Weight > b.Weight ){
            return -1;
          }
          if ( a.Weight < b.Weight ){
            return 1;
          }
          return 0;
        }
           
        function SortWeightAsc ( a, b ) {
          if ( a.Weight < b.Weight ){
            return -1;
          }
          if ( a.Weight > b.Weight ){
            return 1;
          }
          return 0;
        }        
       
        function SortPostCountDesc( a, b ) {
          if ( a.PostedCount > b.PostedCount ){
            return -1;
          }
          if ( a.PostedCount < b.PostedCount ){
            return 1;
          }
          return 0;
        }  

        function  SortPostCountAsc ( a, b ) {
          if ( a.PostedCount < b.PostedCount ){
            return -1;
          }
          if ( a.PostedCount > b.PostedCount ){
            return 1;
          }
          return 0;
        }       

        async function RefreshData() {
          try {
                    setErrorMessage("")

                              
                    //console.log("Content.RefreshData.Users:  ",Users)
                    if (Users !== undefined) {
                      GetContent()  
                    }

          } catch (err) { 
                          console.error('Content.RefreshData.ERROR: ', err, err.stack); 
                      }
        }     
        
        async function ShowUploadButton() {
          try {
                    let vShowUploadButton = (ShowUpload === true ? false : true)
                    setShowUpload(vShowUploadButton)

                              
          } catch (err) { 
                          console.error('Content.RefreshData.ERROR: ', err, err.stack); 
                      }
        }       
        
        async function GetContent() {
          try {
                    setErrorMessage("")
                    setSMContent([])
                    
                 //console.log("Content.GetContent.vAccountSelected:  ",vAccountSelected)
                 //console.log("Content.GetContent.vCampaignSelected:  ",vCampaignSelected)
                   

                   let  variables = {
                      filter: {
                        Account: {
                                eq: vAccountSelected
                            },
                          Campaign: {
                                  eq: vCampaignSelected
                              }
                      }
                    };

                    const ContentData2  = await client.graphql({
                      query: listSMMADContents,
                      variables: variables
                    });
      
          
          
                    //console.log("Content.GetContent.ContentData2.length:  ",ContentData2.data.listSMMADContents.items.length)

                    if (ContentData2.data.listSMMADContents.items.length > 0) {
                        const Content1 = ContentData2.data.listSMMADContents.items 

                        setSMContent(Content1)  
                                   
                    }

                    if (ContentData2.data.listSMMADContents.nextToken !== null) {
                      
                      setnextToken(ContentData2.data.listSMMADContents.nextToken)  
         
                  }                    

          } catch (err) { 
                          console.error('Content.GetContent.ERROR: ', err, err.stack); 
                      }
        }            

        const processFile = ({ file, key }) => {

          //console.log("Content.processFile.file:  ",file) 
          //console.log("Content.processFile.key:  ",key) 
          let vUserEmail = vAccountSelected.replace("@", "").replace(".", "").replace("_", "");
          //console.log("Content.processFile.vKey:  ",vKey) 
          key = "SMMADContentUploads/" + vUserEmail + "_" + file.name
          let vCategories = ""

          vHashTagMap.forEach((item) => {
            vCategories += item["HashTag"] + ","
          });

          vCategories = vCategories.substring(0, vCategories.length-1); 

          
          //console.log("Content.processFile.vCategories:  ",vCategories) 

          return {
            file,
            key,
            metadata: {
              id: key,
              Account: vAccountSelected,
              AccountGroup: vAccountGroup,
              Campaign: vCampaignSelected,
              HashTags: vCategories
            },
          };
        };

        async function FileUploaded(e)    {
          try {
                 
                  //console.log("Content.FileUploaded.e:  ",e["key"])
                  setShowUpload(false)
            

          } catch (err) { 
                          console.error('Content.FileUploaded.ERROR: ', err, err.stack); 
                      }
        }      
        
        const handleMouseOver = () => {

          let vContentHelpBadgeHoveredtmp = (vContentHelpBadgeHovered === true ? false : true)
    
          setvContentHelpBadgeHovered(vContentHelpBadgeHoveredtmp);
        };
          
        async function SortDataByCreation()    {
          try {
                 
            //console.log("Content.SortDataByCreation.vSortNewestToOldest: ", vSortNewestToOldest);   
                                    
   
            if (vSortNewestToOldest === true && SMContent.length> 0) {
              
              setvSortNewestToOldest(false)
              let vSMContent = SMContent.sort( SortOldestToNewest );
              //setSMContent(vSMContent)  
              setvSortButtonText("Upload date \u21E7")
             
            } else if (vSortNewestToOldest === false && SMContent.length> 0) {
              setvSortNewestToOldest(true)
  
              let vSMContent = SMContent.sort( SortNewestToOldest );
              //setSMContent(vSMContent)  
              setvSortButtonText("Upload date \u21E9")
  
            }

            setvSortButtonStyle("CreationDateTime")       
            

          } catch (err) { 
                          console.error('Content.FileUploaded.ERROR: ', err, err.stack); 
                      }
        }            
              
        async function SortDataByLastPost()    {
          try {
            //console.log("Content.SortDataByLastPost.vSortLastPostDateDesc: ", vSortLastPostDateDesc);   
                                    
   
            if (vSortLastPostDateDesc === true && SMContent.length> 0) {
              
              setvSortLastPostDateDesc(false)
              let vSMContent = SMContent.sort( SortLastPostAsc );
              //setSMContent(vSMContent)  
              setvPostDateSortButtonText("Post Date \u21E7")
             
            } else if (vSortLastPostDateDesc === false && SMContent.length> 0) {
              setvSortLastPostDateDesc(true)
  
              let vSMContent = SMContent.sort( SortLastPostDesc );
              //setSMContent(vSMContent)  
              setvPostDateSortButtonText("Post Date \u21E9")
  
            }
  

            setvSortButtonStyle("LastPostDate")  

          } catch (err) { 
            console.error('Content.FileUploaded.ERROR: ', err, err.stack); 
        }
        }            

        async function SortDataByWeight()    {
          try {
            //console.log("Content.SortDataByWeight.SortDataByWeight: ", vSortLastPostDateDesc);   
                                    
            

            if (vSortWeightDesc === true && SMContent.length> 0) {
              
              setvSortWeightDesc(false)
              let vSMContent = SMContent.sort( SortWeightAsc );
              //setSMContent(vSMContent)  
              setvSortHighestWeightPostButtonText("Weight \u21E7")
            
            } else if (vSortWeightDesc === false && SMContent.length> 0) {
              setvSortWeightDesc(true)

              let vSMContent = SMContent.sort( SortWeightDesc );
              //setSMContent(vSMContent)  
              setvSortHighestWeightPostButtonText("Weight \u21E9")

            }

            

            setvSortButtonStyle("Weight")  

          } catch (err) { 
            console.error('Content.FileUploaded.ERROR: ', err, err.stack); 
        }
        }            

        async function SortDataByPostCount()    {
          try {
            //console.log("Content.SortDataByWeight.vSortPostCountDesc: ", vSortPostCountDesc);   
                                    
            

            if (vSortPostCountDesc === true && SMContent.length> 0) {
              
              setvSortPostCountDesc(false)
              let vSMContent = SMContent.sort( SortPostCountAsc );
              //setSMContent(vSMContent)  
              setvSortPostCountButtonText("# Posts \u21E7")
            
            } else if (vSortPostCountDesc === false && SMContent.length> 0) {
              setvSortPostCountDesc(true)

              let vSMContent = SMContent.sort( SortPostCountDesc );
              //setSMContent(vSMContent)  
              setvSortPostCountButtonText("# Posts \u21E9")

            }

            

            setvSortButtonStyle("PostCount") 

          } catch (err) { 
            console.error('Content.FileUploaded.ERROR: ', err, err.stack); 
        }
        }            


    return (     
      <div>        
                    
                  <Flex
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      alignContent="flex-start"
                      wrap="nowrap"
                      gap="3rem"
                      className={styles.GridContainer}
                    >    
                    <Badge variation="info" 
                          size="small"
                          className={vContentHelpBadgeHovered === false ? styles.BadgeHelp : styles.BadgeHelphover}            
                          onClick={handleMouseOver}>
                          <Flex
                            direction="row"
                            justify="center"
                            alignItems="center"
                            align="flex-start"
                            wrap="nowrap"
                            gap="1rem"

                          >                
                          {
                            vContentHelpBadgeHovered === false &&  (     
                              <Text className={styles.BadgeHelpQuestionMark}>?</Text> )
                          }                
                          {
                            vContentHelpBadgeHovered === true &&  (              
                            <Flex
                              direction="row"
                              justify="center"
                              alignItems="center"
                              align="flex-start"
                              wrap="nowrap"
                              gap="1rem"
                
                            >   
                                <Text className={styles.BadgeHelpQuestionMark}>?</Text> 
                              <Flex
                                  direction="column"
                                  justifyContent="flex-start"
                                  alignItems="stretch"
                                  wrap="nowrap"
                                  gap="0.25rem" 
                                  className={styles.BadgeHelpFlexColumnText}
                                > 
                                <Text 
                                  className={vContentHelpBadgeHovered === true ? styles.BadgeHelpText.transition : styles.BadgeHelpText}>Upload pictures from your phone/computer. 
                                </Text>          
                                <Text 
                                  className={vContentHelpBadgeHovered === true ? styles.BadgeHelpText.transition : styles.BadgeHelpText}>** Click refresh until your picture shows up.
                                </Text>   
                                <Text 
                                  className={vContentHelpBadgeHovered === true ? styles.BadgeHelpText.transition : styles.BadgeHelpText}>Click on picture to add caption.
                                </Text>                                                                                                                                                                                                                                           
                              </Flex>                                                                                                                                                                                                                                          
                          </Flex>  
                            )
                          }                
                                                                                                                                                                                                                                                
                          </Flex>    
                    </Badge>                       
                    <Flex
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            alignContent="center"
                            wrap="nowrap"
                            gap="3rem"
                            className={styles.FlexRow}
                          >                         
                          <Button className={styles.Button}
                                        variation="primary"                           
                                        onClick={ (e) => ShowUploadButton(e)}
                                        >
                                  Upload Content
                          </Button> 
                              
                          <Button className={styles.Button}
                                            variation="primary"                                  
                                            onClick={ (e) => RefreshData() }
                                            >
                                      Refresh
                          </Button>                                                                                                                                                                                                                                      
                    </Flex> 
                    <Flex
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            alignContent="center"
                            wrap="nowrap"
                            gap="3rem"
                            className={styles.FlexRow}
                          >                         
                          <Button className={vSortButtonStyle === "CreationDateTime" ? styles.ButtonSelected : styles.Button }
                                            variation="primary"                                  
                                            onClick={ (e) => SortDataByCreation() }
                                            >
                                      {vSortButtonText}
                          </Button>     
                          <Button className={vSortButtonStyle === "LastPostDate" ? styles.ButtonSelected : styles.Button }
                                            variation="primary"                                  
                                            onClick={ (e) => SortDataByLastPost() }
                                            >
                                      {vPostDateSortButtonText}
                          </Button>       
                          <Button className={vSortButtonStyle === "Weight" ? styles.ButtonSelected : styles.Button }
                                            variation="primary"                                  
                                            onClick={ (e) => SortDataByWeight() }
                                            >
                                      {vSortHighestWeightPostButtonText}
                          </Button>         
                          <Button className={vSortButtonStyle === "PostCount" ? styles.ButtonSelected : styles.Button }
                                            variation="primary"                                  
                                            onClick={ (e) => SortDataByPostCount() }
                                            >
                                     {vSortPostCountButtonText}
                          </Button>                                                                                                                                                                                                                                                         
                    </Flex>                                      
                    {ShowUpload && (   
                      <Flex
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          alignContent="flex-start"
                          wrap="nowrap"
                          gap="3rem"
                        >   
                        <StorageManager
                          acceptedFileTypes={['image/*', 'video/*']}
                          accessLevel="guest"
                          showThumbnails={true}
                          processFile={processFile}
                          className={styles.StorageManager1}
                          displayText={{
                            // some text are plain strings
                            dropFilesText: 'drag-and-drop here',
                            browseFilesText: 'Open file picker',
                            // others are functions that take an argument
                            getFilesUploadedText(count) {
                              return `${count} images uploaded`;
                            },
                          }}  
                          onUploadSuccess={ (e, key) => FileUploaded(e, key)}           
                          components={{
                          FilePicker({ onClick }) {
                            return (
                              <Button className={styles.Button}
                                      variation="primary" onClick={onClick}>
                                Browse Files
                              </Button>
                            );
                          }
                        }}                                                  
                        />   
                                                                                                                                                                                                                                                             
                      </Flex>
                    
                    )}  
                    {SMContent.length > 0 &&  (   
                        <Flex
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="stretch"
                            alignContent="flex-start"
                            wrap="nowrap"
                            gap="3rem"
                            className={styles.GridContainer}
                          >                                                                                   
                            <ContentTable key={vSortButtonText} AccountGroup={vAccountGroup} SMContent={SMContent} HashTagMap={vHashTagMap}   AccountSelected={vAccountSelected}  ImageToTextRelationship={props.ImageToTextRelationship} CampaignSelected={vCampaignSelected} /> 
                      
                           
                          </Flex>
                      )}       
                  </Flex>                       
            </div>     

    )

  }
 

export default Content;