
import React, { useState , useEffect, useRef, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Button,
  SelectField,
  Flex,
  Autocomplete, Loader 
  } from '@aws-amplify/ui-react'; 
import BlogEntry from './BlogEntry';
import { generateClient } from 'aws-amplify/api';
import {
  useInView,
  useScroll,
  useTransform,
  motion,
  useAnimation,
} from "framer-motion";
import styles from './BlogEntries.module.css';
import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
import {
  listBlogPosts,
  listSBSMSubReddits
} from "../../../graphql/queries";
import {
  createBlogPosts,
  deleteBlogPosts
} from "../../../graphql/mutations";


const BlogEntries = (props) => {       

  BlogEntries.propTypes = {
      Blog: PropTypes.object,
      SearchTerm: PropTypes.string
      };

    let debugConsoleOutPut = true
  

    const [vBlog, setvBlog] = useState(props.Blog)   
    const [ShowTable, setShowTable] = useState(false); 
    const [vSearchTerm, setvSearchTerm] = useState(props.SearchTerm); 
    
    var ErrorMessageStr = ""
    const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)  
    
    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);
    const client = generateClient();
    
    const [vBlogPosts, setvBlogPosts] = useState()   

    
    useEffect(() => {
      //console.log("BlogEntries.useEffect[isInView].props.Blog: " ,props.Blog);
 

    },[])  

    useEffect(() => {
       if (isInView) {
         mainControls.start("visible");
       }
     }, [isInView]);



    useEffect(() => {
      //console.log("BlogEntries.useEffect[vBlog].vSearchTerm: " ,vSearchTerm);
      if (vBlog !== undefined && vSearchTerm === undefined) {


        setvBlogPosts()
        GetBlogPosts()

       
      }
    }, [vBlog]);

    useEffect(() => {
      
      //console.log("BlogEntries.useEffect[vSearchTerm].vSearchTerm: " ,vSearchTerm);

      if (vSearchTerm !== undefined) {

        setvBlogPosts()
        SearchBlogPosts()

       
      }

    }, [vSearchTerm]);    

    


     async function GetBlogPosts() {
      try {
        setErrorMessage("");
        
       // //console.log("BlogEntries.GetBlogPosts.vBlog: ", vBlog);
        let variables = {
            filter: {
              Blog: {
                eq: vBlog["Blog"],
              },
              Active: {
                eq: true,
              },
            },
          };
  
        const listBlogPostsData2 = await client.graphql({
          query: listBlogPosts,
          variables: variables,
        });
  
        //console.log("BlogEntries.GetBlogPosts.listBlogPosts.data.listBlogPosts.items.length : ", listBlogPostsData2.data.listBlogPosts.items.length );
  
        if (listBlogPostsData2.data.listBlogPosts.items.length > 0) {
          const BlogEntries1 =
            listBlogPostsData2.data.listBlogPosts.items;
            let vSortedBlogPosts = SortBlogPostsByDate(BlogEntries1)
            setvBlogPosts(vSortedBlogPosts)
        } 
  
  
  
      } catch (err) {

        console.error("BlogEntries.GetBlogPosts.Error.message:  ", err["errors"][0]["message"].toString()); 
        
        RhyteItLogging(
          "NA",
          "BlogEntries",
          "GetBlogPosts",
          err["errors"][0]["message"].toString(),
          '{"ERROR": "' + err["errors"][0]["message"].toString() + '"}',
          0
        );
        


      }
    }
  
    async function SearchBlogPosts() {
      try {
        setErrorMessage("");
        
        //console.log("BlogEntries.SearchBlogPosts.vBlog: ", vBlog);
        //console.log("BlogEntries.SearchBlogPosts.vSearchTerm: ", vSearchTerm);
        let variables = {
            filter: {
              Blog: {
                eq: vBlog["Blog"],
              },
              BlogPostString: {
                contains: vSearchTerm,
              },
            },
          };
  
        const listBlogPostsData2 = await client.graphql({
          query: listBlogPosts,
          variables: variables,
        });
  
        //console.log("BlogEntries.SearchBlogPosts.listBlogPosts.data.listBlogPosts.items.length : ", listBlogPostsData2.data.listBlogPosts.items.length );
  
        if (listBlogPostsData2.data.listBlogPosts.items.length > 0) {
          const BlogEntries1 =
            listBlogPostsData2.data.listBlogPosts.items;
            let vSortedBlogPosts = SortBlogPostsByDate(BlogEntries1)
            setvBlogPosts(vSortedBlogPosts)
        } 
  
  
  
      } catch (err) {

        console.error("BlogEntries.SearchBlogPosts.Error.message:  ", err["errors"][0]["message"].toString()); 
        
        RhyteItLogging(
          "NA",
          "BlogEntries",
          "SearchBlogPosts",
          err["errors"][0]["message"].toString(),
          '{"ERROR": "' + err["errors"][0]["message"].toString() + '"}',
          0
        );
        


      }
    }

    function SortBlogPostsByDate(pBlogPostEditionEmail) {
      pBlogPostEditionEmail.sort(GFG_sortFunction);
      return pBlogPostEditionEmail;
      //    //console.log(""JSON.stringify(pEmails));
    }
  
    function GFG_sortFunction(a, b) {
      let BlogPostCreationDateTimeA = a.CreationDateTime;
      let BlogPostCreationDateTimeB = b.CreationDateTime;
      return BlogPostCreationDateTimeA < BlogPostCreationDateTimeB ? 1 : -1;
    }




  return (   
    <section ref={ref} className = {styles.Section}>
      {
        vBlog !== undefined  && (                      
        <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
            className={styles.FlexLeftColumn}>   
          
              {
               vBlogPosts !== undefined && vBlog !== undefined && vBlogPosts.map((vBlogPost) => (                      
                  (<BlogEntry   key={vBlogPost.UpdateDateTime}  BlogPost={vBlogPost}/>)        
                  ))
              }    
        </Flex>                 
      )}      
    </section>
  )
}

export default BlogEntries;


