import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import {motion as m} from "framer-motion";
import Marquee from "react-fast-marquee";
import styles from './SubredditTicker.module.css';
import {RhyteItLogging} from '../../BusinessLogic/UserManagement';
import { UserContext, CognitoGroupContext} from '../../BusinessLogic/Hooks/UserContext';
import {
  Image, Text, Flex
} from '@aws-amplify/ui-react';

const SubredditTicker = (props) => {       
  
  SubredditTicker.propTypes = {
          RedditUser: PropTypes.object
        };
        
        const [SubRedditsReturned, setSubRedditsReturned] = useState()  
        const [SubRedditsData, setSubRedditsData] = useState([])   
        const {Users, setUsers} = useContext(UserContext);    
        const {CognitoGroup, setCognitoGroup} = useContext(CognitoGroupContext);                
              

        useEffect(() => {

          (async function() {
            try {  

              GetSubRedditsFromS3()


                } catch (err) {
                  console.error('SBSMLogoTicker.useEffect[].ERROR: ', err, err.stack); 
              }
            }
          )();   
      

        },[]) 

        async function GetSubRedditsFromS3() {
          try {

                    let vOFJSON = ""

                    const url = `https://rhyteitfiles.s3.us-west-2.amazonaws.com/ScollingData/SubReddits.json`

                    const fetchResponse = await fetch(url);
                    let SubRedditsData1 = await fetchResponse.json();
                    //console.log("GetSubRedditsFromS3.SubRedditsData.length: ",SubRedditsData1.length);

                    setSubRedditsData(SubRedditsData1)                    

                    let vSubRedditTotal = SubRedditsData1.length
                    setSubRedditsReturned(vSubRedditTotal.toLocaleString())               
      

  
          } catch (err) { 
            RhyteItLogging(Users, "SubredditTicker", "GetSubRedditsFromS3",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                      }
        }  


    return ( 
        <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              gap="3rem"
            >               
            {
                      SubRedditsData !== undefined && SubRedditsData.map((SubReddit) => (  
                        <div className={styles.ImageContainer} id={SubReddit.id}> 
                            <Text   
                              className={styles.Text}
                              >
                                r/{SubReddit.subreddit}
                            </Text>                          
                          </div>   

                ))
              }    
      </Flex>  
    )

  }
 

export default SubredditTicker;