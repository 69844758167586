
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Text,
  TableBody,
  Table,
  TableHead,
  Image,
  CheckboxField
  } from '@aws-amplify/ui-react';

  import  {  }  from '../../../graphql/queries'
  import  { deleteSMMADContent, updateSMMADContent, createSMMADContent}  from '../../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";

  import styles from './SMMADPostingRow.module.css';  
  import { UserContext} from '../../../BusinessLogic/Hooks/UserContext';  
  import { SelectedImageContext} from '../../../BusinessLogic/Hooks/SMMADImageContext';
  import SMMADText from "../SMMADText/SMMADText";

  import {
    GetGroupRoleAccess,
    RhyteItLogging,
    ReturnUserToLandingPage,
  } from "../../../BusinessLogic/UserManagement";
  
function SMMADPostingRow(props) {       

  SMMADPostingRow.propTypes = {
      vSMMADPostingRow: PropTypes.object,
      };

    const client = generateClient();
  
    const {Users, setUsers} = useContext(UserContext);   
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vSMMADPostingRow, setvSMMADPostingRow] = useState(props.vSMMADPostingRow)   


    //console.log("SMMADPostingRow.useEffect[].vSelectedImage:  ",vSelectedImage) 

    useEffect(() => {

       //console.log("SMMADPostingRow.useEffect[].vSelectedImage:  ",vSelectedImage) 
       //console.log("SMMADPostingRow.useEffect[].ClickedImage:  ",ClickedImage) 
       //console.log("SMMADPostingRow.useEffect[].onClick:  ",onClick) 
      
      },[])  


      function OpenTwitterPostURL(pTwitterPostURL) {
        try {
  
          console.log("SMMADPostingRow.OpenTwitterURL.pTwitterHandle:  ",pTwitterPostURL) 
  
            if (pTwitterPostURL.length > 0) {
              window.open(pTwitterPostURL, '_blank');
            } 
    
            
          } catch (err) { 
  
            RhyteItLogging(Users, "OFProfileCard", "OpenTwitterURL",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
               
        }        
      }  


  return (
                
            <TableRow key={vSMMADPostingRow.id}  className={styles.TableRow}
            
            onClick={(e) => OpenTwitterPostURL(vSMMADPostingRow.PostURL)}>  
            <TableCell className={styles.TableCellHeader}>            
            <Text className={styles.Text}>
            {new Date(vSMMADPostingRow.PostDateTime).toLocaleDateString() }  
              </Text>        
            </TableCell>         
            <TableCell className={styles.TableCellHeader}>      
            <Text className={styles.Text}>
            {new Date(vSMMADPostingRow.PostDateTime).toLocaleTimeString()}  
              </Text>          
            </TableCell>          
            <TableCell className={styles.TableCellHeader}>      
            <Text className={styles.Text}>
            {vSMMADPostingRow.TimeDiff}  
              </Text>          
            </TableCell>   
            <TableCell className={styles.TableCellHeader}>      
            <Text className={styles.Text}>
            {vSMMADPostingRow.SubscribersGrowth}  
              </Text>         
              </TableCell>    
            <TableCell className={styles.TableCellHeader}>      
            <Text className={styles.Text}>
            {vSMMADPostingRow.Weight}  
              </Text>          
            </TableCell>  
          </TableRow> 
  )
}

export default SMMADPostingRow;

