import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { useInView, useScroll, useTransform, motion, useAnimation } from "framer-motion";
import styles from "./BackgroundFailure.module.css";

import BackgroundAurora from "./BackgroundAurora";
import { Button, Flex, Text, Image } from "@aws-amplify/ui-react";

const BackgroundFailure = (props) => {
  BackgroundFailure.propTypes = {
    backgroundcolor: PropTypes.string,
  };

  const [vBGColor, setvBGColor] = useState(props.backgroundcolor);
  const ref= useRef(null);
  const { scrollYProgress } = useScroll({
    target:ref,
    offset:["start start", "start end"]
  });

  const backgroundY =useTransform(scrollYProgress, [0,1], ["0%", "100%"])
  const textY =useTransform(scrollYProgress, [0,1], ["-10%", "100%"])

  useEffect(() => {}, []);

  return (
    <div 
      ref={ref}
      className={styles.BackgroundContainer}>
      <img
        alt=""
        src="https://rhyteiticons.s3.us-west-2.amazonaws.com/Backgrounds/ParallaxBackground.png"
        className={styles.PhotoImage2}
      />
      <motion.img
        alt=""
        src="https://rhyteiticons.s3.us-west-2.amazonaws.com/Backgrounds/ParallaxText.png"
        style={{y:textY}}
        className={styles.PhotoImageText}
      />           
      <img
        alt=""
        src="https://rhyteiticons.s3.us-west-2.amazonaws.com/Backgrounds/ParallaxForeground.png"
        className={styles.PhotoImage1}
      />      
    </div>
  );
};

export default BackgroundFailure;
