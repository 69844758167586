
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Text,
  TableBody,
  TextField,  
  CheckboxField,
  Button,
  Image
  } from '@aws-amplify/ui-react';
  import { Amplify } from 'aws-amplify';
  import { Link } from "react-router-dom";
  import {deleteApplicants, createOnlyFansProspectingProfiles} from '../../../graphql/mutations'; 
  import {getOnlyFansProspectingProfiles } from '../../../graphql/queries' 
  
  import { generateClient } from 'aws-amplify/api';
  import styles from './ApplicantManagementRow.module.css';  
  import { UserContext,UserAuthenticatedContext,UserSubcribedContext} from '../../../BusinessLogic/Hooks/UserContext';  
  import ApplicantDetail from "./ApplicantDetail";
  import moment from 'moment';  
  import {ValidateUser} from '../../../BusinessLogic/UserManagement';

const ApplicantManagementRow = (props) => {       

  ApplicantManagementRow.propTypes = {
      RhyteItApplicantManagementRow: PropTypes.object
      };
  
    const {Users, setUsers} = useContext(UserContext);   
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""
    let debugConsoleOutPut = true
    var ScreenLoaded = false
    const [message, setMessage] = useState('');
    const client = generateClient();
    const [vRhyteItApplicantManagementRows, setvRhyteItApplicantManagementRows] = useState([props.RhyteItApplicantManagementRow])   
    const [OFProfileExists, setOFProfileExists] = useState(props.RhyteItApplicantManagementRow.InOnlyFansProspectingProfile === null ? false : props.RhyteItApplicantManagementRow.InOnlyFansProspectingProfile)   
       

    useEffect(() => {

      (async function() {
        try {  


                if (Users === undefined) {                    
                  //console.log("Dashboard.useEffect[].Users:  ",Users)
                    let vIsUserSubscribed = await ValidateUser()
                    setUsers(vIsUserSubscribed)
                    //console.log("Dashboard.useEffect[].vIsUserSubscribed:  ",vIsUserSubscribed)

                }                    

                //console.log("Dashboard.useEffect[].Users:  ",Users)

            } catch (err) {
              console.error('Dashboard.useEffect[].ERROR: ', err, err.stack); 
          }
        }
      )();  

      },[])  

      useEffect(() => {

         // //console.log("ApplicantManagementRow.useEffect[OFProfileExists].OFProfileExists:  ",OFProfileExists) 
          CheckIfOFExists()
         
         },[OFProfileExists])        

    function EditApplicantManagementRow(event, data) {
      try {           
        
          setErrorMessage("")


        } catch (err) { 
          ErrorMessageStr = ("vRhyteItApplicantManagementRows.EditApplicantManagementRow.Error: ", err, err.stack)
          console.error(ErrorMessageStr)
          setErrorMessage(ErrorMessageStr)
      }        
    }      
    
    async function DeleteRow(event,data) {
      try {

            //console.log("ApplicantManagmentRow.Delete_Row.Users    :  ",Users    ) 

            if (Users === "edwardhawke@rhyteit.com") {

              var elementPos = vRhyteItApplicantManagementRows.map(function(x) {return x.id; }).indexOf(data.id);                      
                              
              //console.log("ApplicantManagementRow.DeleteRow.vRhyteItApplicantManagementRows[elementPos].id:  ",vRhyteItApplicantManagementRows[elementPos].id  ) 

              const DeleteApplicantManagementRow = {
                id: vRhyteItApplicantManagementRows[elementPos].id,
                UserId: vRhyteItApplicantManagementRows[elementPos].UserId,
                _version: vRhyteItApplicantManagementRows[elementPos]._version
              }
              
              const vDeleteResponse = await client.graphql({
                query: deleteApplicants,
                variables: {
                  input: DeleteApplicantManagementRow
                }
              });  

              
              //console.log("ApplicantManagementRow.DeleteRow.vDeleteResponse:  ",vDeleteResponse  ) 
              setvRhyteItApplicantManagementRows(vRhyteItApplicantManagementRows => vRhyteItApplicantManagementRows.filter((data)=> data.id !== vRhyteItApplicantManagementRows[elementPos].id ))
              ErrorMessageStr = vDeleteResponse
              setErrorMessage(ErrorMessageStr)      
 
              
          } else {

            ErrorMessageStr = "You do not have permission to delete this Applicant"
            setErrorMessage(ErrorMessageStr)  

          }
      
          } catch (err) { 

          ErrorMessageStr = "ApplicantManagementRow.DeleteRow.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }               
      
    }       


    async function CheckIfOFExists() {
      try {
                setErrorMessage("")

                let vOnlyFansName = vRhyteItApplicantManagementRows[0].SocialMediaProfile.replace("https://www.onlyfans.com/", '')
                vOnlyFansName = vRhyteItApplicantManagementRows[0].SocialMediaProfile.replace("http://www.onlyfans.com/", '')
                vOnlyFansName = vRhyteItApplicantManagementRows[0].SocialMediaProfile.replace("https://onlyfans.com/", '')
                
              if (OFProfileExists === false && vOnlyFansName !== null && vOnlyFansName !== "") {
                
                  //console.log("ApplicantManagementRow.CheckIfOFExists.vOnlyFansName: ",vOnlyFansName, " - Users:  ", Users);
                  
                    let OFProfileExists = await client.graphql({
                      query: getOnlyFansProspectingProfiles,
                      variables: {
                          id: vOnlyFansName,
                          UserId: Users
                      }
                    }
                  )
                    //console.log("ApplicantManagementRow.CheckIfOFExists.vOnlyFansName2: ", vOnlyFansName, " - OFProfileExists2: ",OFProfileExists);
           //         //console.log("ApplicantManagement.CreateOFProfiles.OFProfileExists.data.getOnlyFansProspectingProfiles: ",OFProfileExists.data.getOnlyFansProspectingProfiles);


                if (OFProfileExists.data.getOnlyFansProspectingProfiles != null) {
                  setOFProfileExists(true)
                  vRhyteItApplicantManagementRows[0].InOnlyFansProspectingProfile = true
                }
              }
                
                

      } catch (err) { 
                      console.error('ApplicantManagementRow.CheckIfOFExists.ERROR: ', err); 
                  }
    }      

    async function CreateOFProfile(event, pRhyteItApplicantManagementRow) {
      try {
                setErrorMessage("")

                let vOnlyFansName = vRhyteItApplicantManagementRows[0].SocialMediaProfile.replace("https://www.onlyfans.com/", '')
                vOnlyFansName = vRhyteItApplicantManagementRows[0].SocialMediaProfile.replace("http://www.onlyfans.com/", '')
                vOnlyFansName = vRhyteItApplicantManagementRows[0].SocialMediaProfile.replace("https://onlyfans.com/", '')

                if (vRhyteItApplicantManagementRows[0].SocialMediaProfile.includes("onlyfans.com/") === false) {
                  vRhyteItApplicantManagementRows[0].SocialMediaProfile = "http://www.onlyfans.com/" + vRhyteItApplicantManagementRows[0].SocialMediaProfile
                }
                
                //console.log("ApplicantManagement.CreateOFProfiles.vOnlyFansName: ",vOnlyFansName, " - Users:  ", Users);
                let createOnlyFansProspectingProfilesResponse = ""
                var vUpdateDateTime = new Date().toISOString()
                

                let NewOFProfile = {
                      id: vOnlyFansName,
                      UserId: Users,
                      OnlyFansProfileURL: vRhyteItApplicantManagementRows[0].SocialMediaProfile,
                      OnlyFansProfilePictureURL: null,
                      OnlyFansChatURL: null,
                      OnlyFansId: vOnlyFansName,
                      OnlyFansName: vOnlyFansName,
                      OFSP_Photos: 0,
                      OFSP_Videos: 0,
                      OFSP_Likes: 0,
                      OFSP_Streams: 0,
                      OFSP_Fans: 0,
                      PaidFree: 0,
                      Description: null,
                      Location: null,
                      Subscribed: false,
                      AdSent: false,
                      Responded: false,
                      RespondedDate: null,
                      Demo: false,
                      DemoDate: null,
                      CollectionAccount: Users,
                      RhyteItCustomer: false,
                      RhyteItCustomerDate: null,
                      RhyteItDAOMember: false,
                      RhyteItDAOMemberDate: null  ,
                      RhyteItUserId: null  ,
                      LinkTree:null  ,
                      Twitter: null  ,
                      Telegram: null  ,
                      Fansly: null  ,
                      Instagram: null  ,
                      Reddit: null  ,
                      Email: vRhyteItApplicantManagementRows[0].UserEmail  ,
                      Beacons: null  ,
                      TikTok: null  ,
                      AllMyLinks: null  ,
                      UpdateCounter: 1,
                      CreationName: Users,
                      UpdateName: Users,
                      CreationDateTime: vUpdateDateTime,
                      UpdateDateTime: vUpdateDateTime
                }    

            
        
                createOnlyFansProspectingProfilesResponse = await client.graphql({
                  query: createOnlyFansProspectingProfiles,
                  variables: {
                    input: NewOFProfile
                  }
                });                  
                
                //console.log("ApplicantManagement.CreateOFProfiles.createOnlyFansProspectingProfilesResponse: ",createOnlyFansProspectingProfilesResponse);

                if (createOnlyFansProspectingProfilesResponse.data.createOnlyFansProspectingProfiles != null) {
                  setOFProfileExists(true)
                  vRhyteItApplicantManagementRows[0].InOnlyFansProspectingProfile = true
                }

                setvRhyteItApplicantManagementRows(vRhyteItApplicantManagementRows)
                setErrorMessage("OnlyFans Profile Created")
                
                

      } catch (err) { 
                      console.error('ApplicantManagement.CreateOFProfiles.ERROR: ', err, err.stack); 
                  }
    }     


  return (
      <TableBody>  
      {
          vRhyteItApplicantManagementRows.length > 0 && vRhyteItApplicantManagementRows.map((vRhyteItApplicantManagementRow) => (  

          
          <TableRow  className={styles.TableRowBackground} key={vRhyteItApplicantManagementRow.id}>
            <TableCell className={styles.TableRowData} >  
                <Text  className={styles.TextField}>
                                  {vRhyteItApplicantManagementRow.id}
                </Text>  
              </TableCell>                                        
            <TableCell className={styles.TableRowData}>   
                <Link  className={styles.Link} isExternal={true} to={vRhyteItApplicantManagementRow.SocialMediaProfile}>
                                  {vRhyteItApplicantManagementRow.SocialMediaProfile}
                </Link>  
            </TableCell>                                      
            <TableCell className={styles.TableRowData}>   
                <CheckboxField
                    name="subscribe-controlled"
                    checked={OFProfileExists}
                    onChange={(e) => setOFProfileExists(e.target.checked)}
                  />                        
            </TableCell>                                         
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {vRhyteItApplicantManagementRow.Application}
                </Text>  
            </TableCell>                                       
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {moment(vRhyteItApplicantManagementRow.CreationDateTime).utc().format("YYYY-MM-DD").toLocaleString()}
                </Text>  
            </TableCell>                                       
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {moment(vRhyteItApplicantManagementRow.UpdateDateTime).utc().format("YYYY-MM-DD").toLocaleString()}
                </Text>  
            </TableCell>       
            <TableCell as="td"  className={styles.RhyteItMenuCell}> 
            <Link key={vRhyteItApplicantManagementRow.id} isExternal={false} to="/ApplicantDetail" state={{ vRhyteItApplicantManagementRow: vRhyteItApplicantManagementRow }} >   
                  <Button
                    loadingText=""
                    ariaLabel=""
                    className={styles.RowButtons}
                    onClick={(e) => EditApplicantManagementRow(e, vRhyteItApplicantManagementRow)}
                  >
                  E
                  </Button> 
              </Link>                 
            </TableCell>   
            <TableCell as="td"  className={styles.RhyteItMenuCell}> 
                  <Button
                    loadingText=""
                    ariaLabel=""
                    className={styles.RowButtons}
                    onClick={(e) => DeleteRow(e, vRhyteItApplicantManagementRow)}
                  >
                  D
                  </Button>                 
            </TableCell>    
            <TableCell as="td"  className={styles.RhyteItMenuCell}> 
                  <Button
                    loadingText=""
                    ariaLabel=""
                    className={styles.RowButtons}
                    disabled={OFProfileExists}
                    onClick={(e) => CreateOFProfile(e, vRhyteItApplicantManagementRow)}
                  >
                  OF
                  </Button>                 
            </TableCell>                                                        
          </TableRow>  
        ))
      }     
      {
        ErrorMessage.length > 0 &&             
          <TableRow  >              
            <TableCell 
              colSpan="7">   
              <Text className={styles.ErrorMessageRow}>  
              {ErrorMessage}
              </Text> 
            </TableCell>               
          </TableRow>  
      }                        
      </TableBody>
    
  )
}

export default ApplicantManagementRow;

