import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import { UserContext} from '../../../BusinessLogic/Hooks/UserContext';

import {
  Flex, Breadcrumbs
} from '@aws-amplify/ui-react';
import styles from './HeaderTitles.module.css';

function ErrorFallback({error, resetErrorBoundary}) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}


const HeaderTitles = (props) => {       
  
  HeaderTitles.propTypes = {
          Title: PropTypes.string,
          SubTitle: PropTypes.string

        };


        useEffect(() => {

          
            //    console.log("HeaderTitles.useEffect[].BreadCrumbItems: ",BreadCrumbItems)
        

        },[]) 
 

    

    return ( 
          <div className={styles.BreadcrumbsContainer}>                             
            <h1 className={styles.h1}>{props.Title}</h1>  
              <div className={styles.headerline} />
            <h2 className={styles.h2}>{props.SubTitle}</h2>  
          </div>                  

    )

  }
 

export default HeaderTitles;