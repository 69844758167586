import PropTypes from "prop-types";
import React, { useState, useEffect, useContext, useRef } from "react";

import { generateClient } from "aws-amplify/api";

import Header from "../../RhyteItComponents/Header/Header";
import {
  UserContext,
  CognitoGroupContext,
} from "../../../BusinessLogic/Hooks/UserContext";
import {
  GetGroupRoleAccess,
  RhyteItLogging,
  ReturnUserToLandingPage,
} from "../../../BusinessLogic/UserManagement";
import { fetchAuthSession } from "aws-amplify/auth";
import { motion as m } from "framer-motion";
import MenuBackground from "../../Menu/MenuBackground";
import { get_uuidv4 } from "../../../BusinessLogic/uuidv4";
import { useLocation } from "react-router-dom";

import {
  Button,
  Fieldset,
  SelectField,
  Flex,
  Text,
  TextField,
  CheckboxField
} from "@aws-amplify/ui-react";
import styles from "./NewsletterEditor.module.css";

import NewsletterEditorTable from "./NewsletterEditorTable";

const NewsletterEditor = (props) => {
  NewsletterEditor.propTypes = {
    Newsletter: PropTypes.object
  };

  const client = generateClient();
  const [vNewsletterEditor, setvNewsletterEditor] = useState([]);
  const location = useLocation();
  const { NewsletterSelected } = location.state || {};
  

  const vBreadCrumbItems = [
    { Path: "/", Title: "Home", isCurrent: false },
    { Path: "/MainMenuV2", Title: "Menu", isCurrent: false },
    { Path: "/NewsletterEditor", Title: "NewsletterEditor", isCurrent: true },
  ];

  var ErrorMessageStr = "";
  const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr);

  const { Users, setUsers } = useContext(UserContext);
  const { CognitoGroup, setCognitoGroup } = useContext(CognitoGroupContext);

  const [updatedKey, setupdatedKey] = useState();
      
  let [vNewsletter, setvNewsletter] = useState();
  

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("NewsletterEditor.useEffect[].NewsletterSelected:  ",NewsletterSelected)
    if(NewsletterSelected !== undefined) {
      setvNewsletter(NewsletterSelected)
    }
  }, []);

  useEffect(() => {
    (async function () {
      try {
        if (CognitoGroup !== undefined) {
          let GetGroupRoleAccessResponse = await GetGroupRoleAccess(
            CognitoGroup,
            "RhyteItSMMADUserGroup"
          );

        } else {
          const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};

          if (idToken !== undefined) {
            setUsers(idToken.payload.email);
            setCognitoGroup(accessToken["payload"]["cognito:groups"]);
          } else {
            ReturnUserToLandingPage(Users, CognitoGroup);
          }
        }
      } catch (err) {
        RhyteItLogging(
          Users,
          "NewsletterEditor",
          "useEffect[CognitoGroup]",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    })();
  }, [CognitoGroup]);

  useEffect(() => {
   // console.log("NewsletterEditor.useEffect[vNewsletter].vNewsletter:  ",vNewsletter)

    if (vNewsletter !== undefined) {
      setupdatedKey(Date.now());
    }
  }, [vNewsletter]);

  return (
    <m.div
      key="Search"
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      exit={{ opacity: 1 }}
    >
      <MenuBackground
        className={styles.MenuBackground}
        GradientStopColor1="#0000"
        GradientStopColor2="#000"
        GradientStopColor3="#00CCCC"
        GradientStopColor4="#8880"
      />

      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="center"
        wrap="nowrap"
        gap="1rem"
        className={styles.FlexColumn}
      >
        <Header
          key={Date.now()}
          Title="Newsletter Editor"
          SubTitle="Edit your newsletter"
          BreadCrumbItems={vBreadCrumbItems}
          pageSource="NewsletterEditor"
        />
         {
          vNewsletter !== undefined && Users !== undefined && updatedKey !== undefined && ( 
            <NewsletterEditorTable key={updatedKey} Users={Users} Newsletter={vNewsletter}/>
            )
          }    
      </Flex>
    </m.div>
  );
};

export default NewsletterEditor;
