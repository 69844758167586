import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { uploadData } from 'aws-amplify/storage';

import  { }  from '../../graphql/queries'
import  { createAudienceGeoInfo, createRhyteItEmails, updateRhyteItEmails, deleteRhyteItEmails}  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import {ErrorBoundary} from 'react-error-boundary'
import Header from "../RhyteItComponents/Header/Header";
import {motion as m} from "framer-motion";
import { UserContext} from '../../BusinessLogic/Hooks/UserContext';
import {ValidateUser} from '../../BusinessLogic/UserManagement';
import { useNavigate, useLocation } from "react-router-dom";
import EmailAttachementUploadTable from './EmailAttachementUploadTable';

import {
  Button ,
  Authenticator,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TextAreaField,
  VisuallyHidden,
  Card,
  TextField,
  View,
  Flex,
  Text,
  DropZone

} from '@aws-amplify/ui-react';
import styles from './EmailReply.module.css';
import styled from 'styled-components'
import {get_uuidv4} from '../../BusinessLogic/uuidv4';


function ErrorFallback({error, resetErrorBoundary}) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

const LabelText = styled.div`
                        color: white;
                        font-size: 0.5em !important;
                        `;


const EmailReply = (props) => {      

        const client = generateClient();

        const vBreadCrumbItems = [{Path: "/", Title:"Home", isCurrent:false},{Path: "/MainMenuV2", Title:"Menu", isCurrent:false},{Path: "/EmailManagement", Title:"EmailManagement", isCurrent:false},{Path: "/EmailReply", Title:"Email", isCurrent:true}]
     
        let debugConsoleOutPut = true
        let navigate = useNavigate(); 

        const location = useLocation();
        const { vEmail } = location.state;


        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)    
        const [RecordUpdated, setRecordUpdated] = useState(false)  
        const [ReplyText, setReplyText] = useState("")         
 

        const {Users, setUsers} = useContext(UserContext);   
        const [Email, setEmail] = useState(vEmail)  
        const [ReplyEmail, setReplyEmail] = useState(vEmail)  

        const [EmailAttachements, setEmailAttachements] = useState([]);
        const hiddenInput = useRef(null);

        const [ShowEmailAttachementsUploadTable, setShowEmailAttachementsUploadTable] = useState(false)  
        const [EmailAttachementUpdateKey, setEmailAttachementUpdateKey] = useState(false)  
        

        useEffect(() => {

          (async function() {
            try {  


              if (Users === undefined) {                    
                //console.log("EmailReply.useEffect[].Users:  ",Users)
                 let vIsUserSubscribed = await ValidateUser()
                 setUsers(vIsUserSubscribed)
              }
                //console.log("EmailReply.useEffect[].Users:  ",Users)          


                } catch (err) {
                  console.error('Emails.useEffect[].ERROR: ', err, err.stack); 
              }
            }
          )();   

          ArrangeReply()
      

        },[]) 


        useEffect(() => {
            
         // console.log("EmailReply.useEffect[ReplyEmail].ReplyEmail:  ",ReplyEmail)       

        },[ReplyEmail]) 
        
        useEffect(() => {
            
          console.log("EmailReply.useEffect[EmailAttachements].EmailAttachements:  ",EmailAttachements) 
          ProcessAttachements()      

        },[EmailAttachements]) 
                

        const onFilePickerChange = (event) => {
          console.log("EmailReply.onFilePickerChange.event.target:  ",event.target) 
          
          const { files  } = event.target;
          
          console.log("EmailReply.onFilePickerChange.files :  ",files ) 

          if (!files  || files.length === 0) {
            return;
          }
          
          console.log("EmailReply.onFilePickerChange.Array.from(vEmailAttachements):  ",Array.from(files )) 

          setEmailAttachements(Array.from(files ));
          
        };
      

        function ArrangeReply() {
          try {           
            
              setErrorMessage("")
              let updateRhyteItEmailsResponse = "" 
              

              var vUpdateDateTime = new Date().toISOString()
      
              Email.UpdateDateTime = vUpdateDateTime       
              Email.UpdateName = Users[0].UserId
  
              var DynamoUUID =  get_uuidv4()
    
              let EmailItem = {
                  "id": DynamoUUID,
                  "EmailAccount": Email.To,
                  "From": Email.To,
                  "To": Email.From,
                  "Subject": "re: " + Email.Subject,
                  "S3Bucket": Email.S3Bucket,
                  "S3Key": null,
                  "ReplyText": Email.EmailText,
                  "EmailText":  null,
                  "EmailHtml": null,
                  "EmailRawText": null,
                  "EmailStatus": "SEND",
                  "ContentType": Email.ContentType,
                  "Size": Email.Size,
                  "SizeUnits": Email.SizeUnits,
                  "ContentTransferEncoding": Email.ContentTransferEncoding,
                  "InReplyTo": Email.id,
                  "MessageID": DynamoUUID,
                  "CharSet": Email.CharSet,
                  "CreationName": Email.To,
                  "CreationDateTime": vUpdateDateTime,
                  "UpdateName": Email.To,
                  "UpdateDateTime": vUpdateDateTime,
              }
               
                setReplyEmail(EmailItem)
                setErrorMessage(ErrorMessageStr)
    
  

 
              setRecordUpdated(true)
              //ErrorMessageStr = "Product saved at: " + vUpdateDateTime.toLocaleString("en-US")
              setErrorMessage("")
         
    
            } catch (err) { 
              console.error(err)
              ErrorMessageStr = ("EmailReply.SendReply.Error: ", err, err.stack)
              console.error(ErrorMessageStr)
              setErrorMessage(ErrorMessageStr)
          }        
        }     
        
        function ReplyText_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("EmailReply.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("EmailReply.Subscribed_handleChange.event: ",event)
              }
    
    
              let vReplyText = event.target.value
             // //console.log("EmailReply.Subscribed_handleChange.RhyteItProduct[elementPos].Subscribed: ",RhyteItProduct[elementPos].Subscribed)
    
              setReplyText(vReplyText)   
    
     
    
            } catch (err) { 
              console.error("EmailReply.ReplyText_handleChange.Error:  ".err, err.stack); 
          }        
        }  

        function OriginalText_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("EmailReply.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("EmailReply.Subscribed_handleChange.event: ",event)
              }
    
    
              ReplyEmail.ReplyText = event.target.value
              // //console.log("EmailReply.Subscribed_handleChange.RhyteItProduct[elementPos].Subscribed: ",RhyteItProduct[elementPos].Subscribed)
     
              setReplyEmail(ReplyEmail) 
     
    
            } catch (err) { 
              console.error("EmailReply.ReplyText_handleChange.Error:  ".err, err.stack); 
          }        
        }     
        

        function Subject_handleChange(event, data) {
          try {              
    
              ReplyEmail.Subject = event.target.value

              setReplyEmail(ReplyEmail)     
    
            } catch (err) { 
              console.error("EmailReply.Subject_handleChange.Error:  ".err, err.stack); 
          }        
        }                
        
        

        async function SaveEmail(event, data) {
          try {           
            
              setErrorMessage("")
              let updateRhyteItEmailsResponse = "" 
              

              var vUpdateDateTime = new Date().toISOString()
      
              Email.UpdateDateTime = vUpdateDateTime       
              Email.UpdateName = Users
  
              var DynamoUUID =  get_uuidv4()
    
              let EmailItem = {
                  "id": ReplyEmail.id,
                  "EmailAccount": ReplyEmail.EmailAccount,
                  "From": ReplyEmail.From,
                  "To": ReplyEmail.To,
                  "Subject": ReplyEmail.Subject,
                  "S3Bucket": ReplyEmail.S3Bucket,
                  "S3Key": ReplyEmail.S3Key,
                  "EmailText":  ReplyText,
                  "ReplyText":  ReplyEmail.ReplyText,
                  "EmailHtml": ReplyEmail.EmailHtml,
                  "EmailRawText": ReplyEmail.EmailRawText,
                  "EmailStatus": "draft",
                  "ContentType": ReplyEmail.ContentType,
                  "Size": ReplyEmail.Size,
                  "SizeUnits": ReplyEmail.SizeUnits,
                  "ContentTransferEncoding": ReplyEmail.ContentTransferEncoding,
                  "InReplyTo": ReplyEmail.InReplyTo,
                  "MessageID": ReplyEmail.MessageID,
                  "CharSet": ReplyEmail.CharSet,
                  "CreationName": ReplyEmail.CreationName,
                  "CreationDateTime": ReplyEmail.CreationDateTime,
                  "UpdateName": ReplyEmail.UpdateName,
                  "UpdateDateTime": ReplyEmail.UpdateDateTime,
              }


                console.log("EmailReply.SendReply.EmailItem:  ",EmailItem)                      

                const createRhyteItEmailsResponse = await client.graphql({
                  query: createRhyteItEmails,
                  variables: {
                    input: EmailItem
                  }
                });  


                console.log("EmailReply.SendReply.createRhyteItEmailsResponse:  ",createRhyteItEmailsResponse)  

              navigate('/EmailManagement', 
                {
                }
              );                
         
    
            } catch (err) { 
              console.error(err)
              ErrorMessageStr = ("EmailReply.SendReply.Error: ", err, err.stack)
              console.error(ErrorMessageStr)
              setErrorMessage(ErrorMessageStr)
          }        
        }     
        
        async function SendReply(event, data) {
          try {           
            
              setErrorMessage("")
              let updateRhyteItEmailsResponse = "" 
              

              var vUpdateDateTime = new Date().toISOString()
      
              Email.UpdateDateTime = vUpdateDateTime       
              Email.UpdateName = Users
  
              var DynamoUUID =  get_uuidv4()
    
              let EmailItem = {
                  "id": ReplyEmail.id,
                  "EmailAccount": ReplyEmail.EmailAccount,
                  "From": ReplyEmail.From,
                  "To": ReplyEmail.To,
                  "Subject": ReplyEmail.Subject,
                  "S3Bucket": ReplyEmail.S3Bucket,
                  "S3Key": ReplyEmail.S3Key,
                  "EmailText":  ReplyText,
                  "ReplyText":  ReplyEmail.ReplyText,
                  "EmailHtml": ReplyEmail.EmailHtml,
                  "EmailRawText": ReplyEmail.EmailRawText,
                  "EmailStatus": ReplyEmail.EmailStatus,
                  "ContentType": ReplyEmail.ContentType,
                  "Size": ReplyEmail.Size,
                  "SizeUnits": ReplyEmail.SizeUnits,
                  "ContentTransferEncoding": ReplyEmail.ContentTransferEncoding,
                  "InReplyTo": ReplyEmail.InReplyTo,
                  "MessageID": ReplyEmail.MessageID,
                  "CharSet": ReplyEmail.CharSet,
                  "CreationName": ReplyEmail.CreationName,
                  "CreationDateTime": ReplyEmail.CreationDateTime,
                  "UpdateName": ReplyEmail.UpdateName,
                  "UpdateDateTime": ReplyEmail.UpdateDateTime,
              }


                console.log("EmailReply.SendReply.EmailItem:  ",EmailItem)                      

                const createRhyteItEmailsResponse = await client.graphql({
                  query: createRhyteItEmails,
                  variables: {
                    input: EmailItem
                  }
                });  


                console.log("EmailReply.SendReply.createRhyteItEmailsResponse:  ",createRhyteItEmailsResponse)  

              navigate('/EmailManagement', 
                {
                }
              );                
         
    
            } catch (err) { 
              console.error(err)
              ErrorMessageStr = ("EmailReply.SendReply.Error: ", err, err.stack)
              console.error(ErrorMessageStr)
              setErrorMessage(ErrorMessageStr)
          }        
        }     
        
        async function DeleteEmail(event,data) {
          try {
    

    
              const DeleteEmails = {
                id: Email.id,
                _version: Email._version
              }
             
              const ReturnMessage = await client.graphql({
                query: deleteRhyteItEmails,
                variables: {
                  input: DeleteEmails
                }
              });                
    
              setEmail([])
    
              setErrorMessage("Deleted")         
                    
              } catch (err) { 
    
              ErrorMessageStr = "EmailReply.DeleteEmail.Error: " + err + " " + err.stack
    
              console.error(ErrorMessageStr); 
              
              setErrorMessage(ErrorMessageStr)         
            }               
          
        }           

        function GoToEmailManagement()  {

          navigate('/EmailManagement', 
          {
            state: {
                RedditUser:Users
            }
          })

        }      

        async function ProcessAttachements() {

          try {

            EmailAttachements.map((file) => (     
       
      
              UploadAttachement(file)           
            
            ))               

          } catch (error) {
            console.log('Error : ', error);
          }
          
          console.log("EmailReply.ProcessAttachements.pFile: "); 
          setEmailAttachementUpdateKey(new Date().toISOString())
          setShowEmailAttachementsUploadTable(true)


        }   

        async function UploadAttachement(pFile) {
          
          console.log("EmailReply.UploadAttachement.pFile: ", pFile);  

          try {

            let vFileName = "EmailAttachements/" + ReplyEmail.id + '_' + pFile.name

            const result = await uploadData({
              key: vFileName,
              data: pFile
            }).result;
            console.log('Succeeded: ', result);
          } catch (error) {
            console.log('Error : ', error);
          }
        }     

    return ( 
    <m.div
      key="RhyteItEmailReply"
      initial={{y: "100%"}}
      animate={{y: "0%"}}
      transition={{duration:0.75, ease: "easeOut"}}
      exit={{ opacity: 1 }}
     >
      <Authenticator  className={styles.AuthenticatorLogin} socialProviders={[]} hideSignUp={true}>
        {({ signOut, user }) => (   
          <div className={styles.SystemAdminBackground}>
            <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                >   
                    <div  className={styles.GridContainer}>       
                      <Header Title="Email" SubTitle={ReplyEmail.From + " - " + ReplyEmail.Subject} BreadCrumbItems ={vBreadCrumbItems} pageSource="EmailReply"/>       
                        <div className={styles.FormContainer}>
                          <View
                            className={styles.View}
                            width="100%"
                          >
                            <Card className={styles.Card} width="100%">
                              <Flex direction="row" alignItems="flex-start">
                                <Flex
                                  direction="column"
                                  alignItems="flex-start"
                                  gap="2em"
                                >
                                <Flex>
                                  <Button className={styles.RedditButtons}                                       
                                          onClick={ (e) => SendReply(e, Email) }
                                          variation="primary">Send</Button>
                                  <Button className={styles.RedditButtons}                                       
                                          onClick={ (e) => DeleteEmail(e, Email) }
                                          variation="primary">Delete</Button>
                                  <Button className={styles.RedditButtons}                                       
                                          onClick={ (e) => SaveEmail(e, Email) }
                                          variation="primary">Save</Button>
                                  <Button className={styles.RedditButtons}                                       
                                          onClick={ (e) => GoToEmailManagement(e, Email) }
                                          variation="primary">Back</Button>
                                  <Button className={styles.RedditButtons}                                       
                                          onClick={ (e) => GoToEmailManagement(e, Email) }
                                          variation="primary">Add Attachement</Button>
                                </Flex>       
                                  <Flex>      
                                    <Text   
                                      className={styles.TextFieldHeaderLabel}
                                      >
                                        Created:
                                    </Text>
                                    <Text size="small" className={styles.Text}>
                                      {new Date( ReplyEmail.CreationDateTime).toLocaleString("en-US")}
                                    </Text>    
                                    <Text   
                                      className={styles.TextFieldHeaderLabel}
                                      >
                                        Updated:
                                    </Text>
                                    <Text size="small" className={RecordUpdated === false ? styles.Text :styles.TextUpdated  }>
                                      {new Date( ReplyEmail.UpdateDateTime).toLocaleString("en-US")}
                                    </Text>
                                  </Flex>
                                  <Table>
                                    <TableBody>                                

                                      <TableRow className={styles.TableRow}>
                                        <TableCell className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              From
                                          </Text>
                                        </TableCell>
                                        <TableCell className={styles.RowData}>     
                                          <Text   
                                            className={styles.TextField}
                                            >
                                              {ReplyEmail.From}
                                          </Text>
                                        </TableCell>
                                        <TableCell className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              To
                                          </Text>
                                        </TableCell>
                                        <TableCell className={styles.RowData}>     
                                          <Text   
                                            className={styles.TextField}
                                            >
                                              {ReplyEmail.To}
                                          </Text>
                                        </TableCell>
                                      </TableRow>                                      
                                      <TableRow className={styles.TableRow}>
                                        <TableCell className={styles.RowLabelColumn} >         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              Subject
                                          </Text>
                                        </TableCell>
                                        <TableCell colspan="3">
                                          <TextField   
                                              className={styles.TextField}
                                              onChange={ (e) => Subject_handleChange(e, Email) }               
                                              defaultValue={ReplyEmail.Subject}/>                             
                                        </TableCell>
                                      </TableRow>                                 
                                      <TableRow>
                                        <TableCell className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              Reply
                                          </Text>   
                                        </TableCell>
                                        <TableCell colspan="3"> 
                                        <TextAreaField 
                                            className={styles.TextAreaField} 
                                            name="ReplyText"     
                                            placeholder="Enter a description of product"                   
                                            defaultValue={ReplyText}
                                            onChange={ (e) => ReplyText_handleChange(e, ReplyText) }
                                            rows={5}
                                            columns={3}/>                                                       
                                        </TableCell>
                                      </TableRow>    
                                      <TableRow>
                                        <TableCell className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              Original
                                          </Text>   
                                        </TableCell>
                                        <TableCell colspan="3"> 
                                        <TextAreaField 
                                            className={styles.TextAreaField} 
                                            name="ReplyText"     
                                            placeholder="Enter a description of product"                   
                                            defaultValue={ReplyEmail.ReplyText}
                                            onChange={ (e) => OriginalText_handleChange(e, ReplyEmail) }
                                            rows={5}
                                            columns={3}/>                                     
                                        </TableCell>
                                      </TableRow> 
                                      <TableRow className={styles.TableRow}>
                                        <TableCell  className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              MessageID
                                          </Text>
                                        </TableCell>
                                        <TableCell className={styles.RowData}  colspan="3">
                                        <Text   
                                            className={styles.TextField}
                                            >
                                              {ReplyEmail.MessageID}
                                          </Text>
                                        </TableCell>
                                      </TableRow >                                     
                                      <TableRow className={styles.TableRow}>
                                        <TableCell  className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              InReplyTo
                                          </Text>
                                        </TableCell>
                                        <TableCell className={styles.RowData}  colspan="3">
                                        <Text   
                                            className={styles.TextField}
                                            >
                                              {ReplyEmail.InReplyTo}
                                          </Text>
                                        </TableCell>
                                      </TableRow >                                                                             
                                      <TableRow className={styles.TableRow}>
                                        <TableCell  className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              S3Key
                                          </Text>
                                        </TableCell>
                                        <TableCell className={styles.LongCellContent} colspan="3">
                                        <Text   
                                            className={styles.TextField}
                                            >
                                              {ReplyEmail.S3Key}
                                          </Text>
                                        </TableCell>
                                      </TableRow >                                        
                                      <TableRow className={styles.TableRow}>
                                        <TableCell  className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              EmailStatus
                                          </Text>
                                        </TableCell>
                                        <TableCell className={styles.RowData}>
                                        <Text   
                                            className={styles.TextField}
                                            >
                                              {ReplyEmail.EmailStatus}
                                          </Text>
                                        </TableCell>
                                        <TableCell  className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              ContentType
                                          </Text>
                                        </TableCell>
                                        <TableCell className={styles.RowData}>
                                        <Text   
                                            className={styles.TextField}
                                            >
                                              {ReplyEmail.ContentType}
                                          </Text>
                                        </TableCell>
                                      </TableRow >                                        
                                      <TableRow className={styles.TableRow}>
                                        <TableCell  className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              Size
                                          </Text>
                                        </TableCell>
                                        <TableCell className={styles.RowData}>
                                        <Text   
                                            className={styles.TextField}
                                            >
                                              {ReplyEmail.Size}
                                          </Text>
                                        </TableCell>
                                        <TableCell  className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              SizeUnits
                                          </Text>
                                        </TableCell>
                                        <TableCell className={styles.RowData}>
                                        <Text   
                                            className={styles.TextField}
                                            >
                                              {ReplyEmail.SizeUnits}
                                          </Text>
                                        </TableCell>
                                      </TableRow >                                       
                                      <TableRow className={styles.TableRow}>
                                        <TableCell  className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              ContentTransferEncoding
                                          </Text>
                                        </TableCell>
                                        <TableCell className={styles.RowData}>
                                        <Text   
                                            className={styles.TextField}
                                            >
                                              {ReplyEmail.ContentTransferEncoding}
                                          </Text>
                                        </TableCell>
                                        <TableCell  className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              CharSet
                                          </Text>
                                        </TableCell>
                                        <TableCell className={styles.RowData}>
                                        <Text   
                                            className={styles.TextField}
                                            >
                                              {ReplyEmail.CharSet}
                                          </Text>
                                        </TableCell>
                                      </TableRow >    
                                    </TableBody>
                                  </Table>                                    
                                  <div ><Text className={styles.MessageBox}>{ErrorMessage}</Text></div>  
                                  {ShowEmailAttachementsUploadTable && EmailAttachements.length > 0 && (<EmailAttachementUploadTable   key={EmailAttachementUpdateKey}  EmailAttachements={EmailAttachements}/>)  }                                                                                                      
                                  <DropZone
                                      onDropComplete={({ acceptedFiles, rejectedFiles }) => {
                                        setEmailAttachements(acceptedFiles);
                                      }}
                                    >
                                      <Flex direction="column" alignItems="center">
                                        <Text>Drag images here or</Text>
                                        <Button size="small" onClick={() => hiddenInput.current.click()}>
                                          Browse
                                        </Button>
                                      </Flex>
                                      <VisuallyHidden>
                                        <input
                                          type="file"
                                          tabIndex={-1}
                                          ref={hiddenInput}
                                          onChange={onFilePickerChange}
                                          multiple={true}
                                        />
                                      </VisuallyHidden>
                                    </DropZone>     
                                </Flex>                                                  
                              </Flex>
                            </Card>
                          </View>           
                        </div>                  
                      </div>  
            </ErrorBoundary>
          </div>   
        )}         
      </Authenticator>  
    </m.div>                      

    )

  }
 

export default EmailReply;