import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import {motion as m} from "framer-motion";
import Marquee from "react-fast-marquee";
import styles from './SBSMNewsTicker.module.css';
import {RhyteItLogging} from '../../BusinessLogic/UserManagement';
import { UserContext, CognitoGroupContext} from '../../BusinessLogic/Hooks/UserContext';
import {
  Image, Text, Flex
} from '@aws-amplify/ui-react';

const SBSMNewsTicker = (props) => {       
  
  SBSMNewsTicker.propTypes = {
          RedditUser: PropTypes.object
        };
        
        const [SBSMNewsReturned, setSBSMNewsReturned] = useState()  
        const [SBSMNewsData, setSBSMNewsData] = useState([])   
        const {Users, setUsers} = useContext(UserContext);    
        const {CognitoGroup, setCognitoGroup} = useContext(CognitoGroupContext);                
              

        useEffect(() => {

          (async function() {
            try {  

              GetSBSMNewsFromS3()


                } catch (err) {
                  console.error('SBSMLogoTicker.useEffect[].ERROR: ', err, err.stack); 
              }
            }
          )();   
      

        },[]) 

        async function GetSBSMNewsFromS3() {
          try {

                    let vOFJSON = ""

                    const url = `https://rhyteitfiles.s3.us-west-2.amazonaws.com/ScollingData/SBSMNews.json`

                    const fetchResponse = await fetch(url);
                    let SBSMNewsData1 = await fetchResponse.json();
                    //console.log("GetSBSMNewsFromS3.SBSMNewsData.length: ",SBSMNewsData1.length);

                    setSBSMNewsData(SBSMNewsData1)                    

                    let vSBSMNewsTotal = SBSMNewsData1.length
                    setSBSMNewsReturned(vSBSMNewsTotal.toLocaleString())               
      

  
          } catch (err) { 
            RhyteItLogging(Users, "SBSMNewsTicker", "GetSBSMNewsFromS3",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                      }
        }  


    return ( 
        <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              gap="1rem"
            >               
            {
                      SBSMNewsData !== undefined && SBSMNewsData.map((SBSMNewItem) => (  
                        <div className={styles.ImageContainer} id={SBSMNewItem.id}> 
                            <Text   
                              className={styles.Text}
                              >
                                {SBSMNewItem.Title}
                            </Text>                          
                          </div>   

                ))
              }    
      </Flex>  
    )

  }
 

export default SBSMNewsTicker;