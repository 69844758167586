import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import HeaderMenu from "../HeaderMenu/LandingPageNavBar";

import RhyteItFloatingIcon from "../LandingPage/BackgroundSpringCanvas";

import  {createPurchase, GetSecretValue}  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import {motion as m} from "framer-motion";

import {
  Button ,
  Authenticator,
  Grid,
  Fieldset,
  TextField,
  Card,
  Image,
  View,
  Flex,
  Text

} from '@aws-amplify/ui-react';
import styles from './DigitalMarketPlaceDetailSuccess.module.css';
import styled from 'styled-components'
import TreeGrowth from '../Experiments/TreeGrowth';
import SpotLight from '../Menu/MenuBackground';

import axios from 'axios';


function ErrorFallback({error, resetErrorBoundary}) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}


const DigitalMarketPlaceDetailSuccess = () => {      

        const client = generateClient();
        const queryParameters = new URLSearchParams(window.location.search)
        const session_id = queryParameters.get("session_id")
      
 
        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)  
        const [vCheckOutSession, setvCheckOutSession] = useState(queryParameters.get("session_id"))  
       
 

        useEffect(() => {     

          //GetCheckOutSession()

        },[]) 


    return ( 
    <m.div
      key="RhyteItDigitalMarketPlaceDetailSuccess"
      initial={{x: "100%"}}
      animate={{x: "0%"}}
      transition={{duration:0.75, ease: "easeOut"}}
      exit={{ opacity: 1 }}
     >                      
     <SpotLight className={styles.SpotLight} GradientStopColor1="#0000" GradientStopColor2="#000"  GradientStopColor3="#00b1b1"   GradientStopColor4="#8880"/>      
          <TreeGrowth className={styles.TreeGrowth}/>     
          <RhyteItFloatingIcon className = {styles.RhyteItFloatingIcon}/>
          <Image
              src="https://rhyteiticons.s3.us-west-2.amazonaws.com/WebPageArtWork/Silhouette.svg"
              className={styles.Silhouette} 
            />
                    <div  className={styles.GridContainer}>              
                      <HeaderMenu  className={styles.RhyteItHeaderMenu}  pageSource="RhyteItDigitalMarketPlaceDetailSuccess"/>
                      
                      <div className={styles.Container}>
                        <div className={styles.Headings} >
                          <h1 className={styles.h1}>Thank you for your purchase!</h1>    
                            <div className={styles.headerline} />
                            <h2 className={styles.h2}>{session_id}</h2>  
                        </div> 
                        <div className={styles.MessageBox}>{ErrorMessage}</div>                            
                      </div>     
                    </div> 
    </m.div>                      

    )

  }
 

export default DigitalMarketPlaceDetailSuccess;