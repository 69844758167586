import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import {ErrorBoundary} from 'react-error-boundary'
import Header from "../RhyteItComponents/Header/Header";


import { UserContext, CognitoGroupContext} from '../../BusinessLogic/Hooks/UserContext';
import {GetGroupRoleAccess, RhyteItLogging, ReturnUserToLandingPage} from '../../BusinessLogic/UserManagement';
import { fetchAuthSession } from 'aws-amplify/auth';
import  { }  from '../../graphql/queries'
import  { deleteSBSMNews}  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import {GetSBSMNewsArticlesAd} from '../../BusinessLogic/SBSMNewsManagement';
import SBSMNewsAdminTable from "./SBSMNewsAdminTable";
import {get_uuidv4} from '../../BusinessLogic/uuidv4';

import {
  Button ,
  Authenticator,
  Autocomplete,
  Flex
} from '@aws-amplify/ui-react';
import styles from './SBSMNewsAdmin.module.css';

function ErrorFallback({error, resetErrorBoundary}) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}


const SBSMNewsAdmin = (props) => {       
  
  SBSMNewsAdmin.propTypes = {
          RedditUser: PropTypes.object
        };

        const [SBSMNewsArticles, setSBSMNewsArticles] = useState([{id: '',
              UserId: '',
              Article:  '',
              InfoType:  '',
              Title: '',
              URL: '',
              Source: '',
              PubDate: '',
              SBSMStatus: '',
              Penalty: '',
              UpVotes: '',
              DownVotes: '',
              AgeInHours: '',
              CreationName:  '',
              UpdateName:  '',
              CreationDateTime:  '',
              UpdateDateTime:  '' }])


              const vBreadCrumbItems = [{Path: "/", Title:"Home", isCurrent:false},{Path: "/MainMenuV2", Title:"Menu", isCurrent:false},{Path: "/SBSMNewsAdmin", Title:"SBSM News Admin", isCurrent:true}]

        const client = generateClient();
        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   
        const [updatedKey, setupdatedKey] = useState(Date.now())   
        let debugConsoleOutPut = true

        const {Users, setUsers} = useContext(UserContext);    
        const {CognitoGroup, setCognitoGroup} = useContext(CognitoGroupContext);       
        const [Article, setArticle] = useState([]);
        const [SearchTerm1, setSearchTerm1] = useState('');
        const [ShowTable, setShowTable] = useState(false); 


        useEffect(() => {


        },[])    
        


           useEffect(() => {
             
            (async function() {
              try {  
  
                      if (CognitoGroup !== undefined) {
  
                        let   GetGroupRoleAccessResponse = await GetGroupRoleAccess(CognitoGroup, "rhyteitAdmins")
                        
                        if (GetGroupRoleAccessResponse) {  
                          GetSBSMNewsArticles()  
                        }
                            
  
                      } else {
  
                        const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
  
                        if (idToken !== undefined) {
                          setUsers(idToken.payload.email)
                          setCognitoGroup(accessToken["payload"]["cognito:groups"])
                        } else {
                          
                          ReturnUserToLandingPage(Users, CognitoGroup)
                        }
  
                      }
                    } catch (err) {                  
                      RhyteItLogging(Users, "SBSMNewsAdmin", "useEffect[CognitoGroup]",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                  }
                }
              )();   
      
             },[CognitoGroup])             
            

        useEffect(() => {
         //console.log("SBSMNewsAdmin.useEffect[SBSMNewsArticles].SBSMNewsArticles:  ",SBSMNewsArticles)
         //console.log("SBSMNewsAdmin.useEffect[SBSMNewsArticles].Object.keys(SBSMNewsArticles).length:  ",Object.keys(SBSMNewsArticles).length )

          if (Object.keys(SBSMNewsArticles).length > 1) {
            setShowTable(true)
          }

          setErrorMessage("SBSM items retrieved at: " + new Date().toLocaleString())

        },[SBSMNewsArticles])              

        useEffect(() => {

        //console.log("SBSMNewsAdmin.useEffect[SearchTerm1].Users:  ",Users)

        if (Users !== undefined) {                    
          GetSBSMNewsArticles()
        }    

        }, [SearchTerm1]);      
          
        useEffect(() => {
      
          //console.log("SBSMNewsAdmin.useEffect[updatedKey].updatedKey:  ",updatedKey)
          //console.log("SBSMNewsAdmin.useEffect[updatedKey].ShowTable:  ",ShowTable)
          //console.log("SBSMNewsAdmin.useEffect[updatedKey].Object.keys(SBSMNewsArticles).length:  ",Object.keys(SBSMNewsArticles).length)

          if (Object.keys(SBSMNewsArticles).length > 1) {
            setShowTable(true)
          }


        }, [updatedKey]);      
  

        async function GetSBSMNewsArticles() {
          try {
                    setErrorMessage("")
          
                    if (debugConsoleOutPut === true) {
                      console.log("SBSMNewsAdmin.GetSBSMNewsArticles.Users[0].UserId: ", Users[0].UserId)
                    }


                    const vSBSMNewsArticles = await GetSBSMNewsArticlesAd(Users[0].UserId)

                    setSBSMNewsArticles(vSBSMNewsArticles)
                                        
                    let PopulateArticlesListResponse = await PopulateArticlesList(vSBSMNewsArticles)

                    setupdatedKey(Date.now())

  
          } catch (err) { 
                          console.error('SBSMNewsAdmin.GetSBSMNewsArticles.ERROR: ', err, err.stack); 
                      }
        }    

        async function PopulateArticlesList(SBSMNewsArticles) {
          try {

              setErrorMessage("") 

              var SBSMNewsArticlesJSON = {SBSMNewsArticles: [{id:"none", label: "none"}]}


              if (SBSMNewsArticles.length > 0 ) {

                SBSMNewsArticles.forEach(Article => 
                  {

                    let vItemAlreadyExists = false

                   // //console.log("SBSMNewsAdmin.PopulateArticlesList.SBSMNewsArticlesJSON:  ", SBSMNewsArticlesJSON)

                    SBSMNewsArticlesJSON.SBSMNewsArticles.forEach(pt => 
                        {                                               
                          if (pt.label === Article.Title) {                      
                            vItemAlreadyExists = true;
                          }
                        } 
                      )     
                      
                      

                    if(vItemAlreadyExists === false) {

                        let NewArticle = {
                          id: Article.id,
                          label: Article.Title
                        }   

                        SBSMNewsArticlesJSON.SBSMNewsArticles.push(NewArticle);

                      }
                })

                //console.log("------> SubRedditPostsManager.PopulateArticleSelectFieldJSON.ArticlesJSON: ",ArticlesJSON)


                //PostTitlesJSON.NewsSources = [...PostTitlesJSON.NewsSources].sort((a, b) => (a.NewsSource < b.NewsSource ? -1 : 1));    
                
                
                //console.log("SBSMNewsAdmin.PopulateArticlesList.SBSMNewsArticlesJSON.Article:  ",SBSMNewsArticlesJSON.SBSMNewsArticles)

                setArticle(SBSMNewsArticlesJSON.SBSMNewsArticles)

              }


              return SBSMNewsArticlesJSON

            } catch (err) { 

            ErrorMessageStr = "SBSMNewsAdmin.PopulateArticlesList.Error: " + err + " " + err.stack

            console.error(ErrorMessageStr); 
            
            setErrorMessage(ErrorMessageStr)         
          }        
        } 

        function DeleteAll() {
          try {

            if (debugConsoleOutPut === true) {
                //console.log("SBSMNewsAdmin.DeleteAllSchedules.SalesMonitors:  ",SalesMonitors) 
              }
              SBSMNewsArticles.forEach(Article => 
                {


                      const ArticleRecord = {
                        id: SBSMNewsArticles.id,
                        _version: SBSMNewsArticles._version
        
                      }    
              
                      const ReturnMessage = client.graphql({
                        query: deleteSBSMNews,
                        variables: {
                          input: ArticleRecord
                        }
                      });  

                        
                        var elementPos = SBSMNewsArticles.map(function(x) {return x.id; }).indexOf(Article.id);
          
                        delete SBSMNewsArticles[elementPos];
          
                      })  
                      
                      GetSBSMNewsArticles() 
                      
                      ErrorMessageStr = "Term deleted successfully"
                      setErrorMessage(ErrorMessageStr)         
                
          } catch (err) { 

          ErrorMessageStr = "SBSMNewsAdmin.DeleteAll.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }        
        }   
                  
        function AddArticle(event) {
            try {              
    
                var DynamoUUID =  get_uuidv4()
    
                var vUpdateDateTime = new Date().toISOString()
    
                if (debugConsoleOutPut === true) {
                    //console.log("SBSMNewsAdmin.AddArticleGenerator.Users:  ", Users)
                }
    
                let NewProfile = {
                      id: DynamoUUID,
                      UserId: Users[0].UserId,
                      Article: '',
                      ArticleStatus: 'NEW',     
                      CreationName: Users[0].UserId,
                      UpdateName: Users[0].UserId,
                      CreationDateTime: vUpdateDateTime,
                      UpdateDateTime: vUpdateDateTime
                }    

   

              let vArraySpot = Object.keys(SBSMNewsArticles).length

              SBSMNewsArticles[vArraySpot] = NewProfile;

    
              setSBSMNewsArticles(SBSMNewsArticles)       
              setupdatedKey(Date.now())

              } catch (err) { 
                console.error("SBSMNewsAdmin.AddArticle.Error:  ".err, err.stack); 
            }        
        }   
        
        function onChangeAutocomplete(event) {
          setSearchTerm1(event.target.value);
        }

        async function onSelectAutocomplete(option) {

          
          //console.log("SBSMNewsAdmin.onSelectAutocomplete.option: ",option)  

          const { label } = option;

          setSearchTerm1(label);          


          try {

            setErrorMessage("") 

            let ArticlesFiltered = ""

            let fetchSBSMNewsArticlesResponse = await GetSBSMNewsArticles();
            //console.log("SBSMNewsAdmin.onSelectAutocomplete.fetchSBSMNewsArticlesResponse:  ",fetchSBSMNewsArticlesResponse)
            //console.log("SBSMNewsAdmin.onSelectAutocomplete.SearchTerm1:  ",SearchTerm1)

          ArticlesFiltered = await [...fetchSBSMNewsArticlesResponse].filter(SBSMArticle => SBSMArticle.Article ===  label)            
            
            //console.log("SBSMNewsAdmin.onSelectAutocomplete.ArticlesFiltered:  ",ArticlesFiltered)

            setSBSMNewsArticles(ArticlesFiltered)


          } catch (err) { 

          ErrorMessageStr = "SBSMNewsAdmin.onSelectAutocomplete Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }            
        }        

        function onClearAutocomplete() {
          setSearchTerm1("");
        }                
        

    return ( 
      <Authenticator  className={styles.AuthenticatorLogin}  loginMechanisms={['email']} socialProviders={[]} hideSignUp={true}>
        {({ signOut, user }) => (   
          <div className={styles.SystemAdminBackground}>
            <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                >   
                <Flex
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  alignContent="flex-start"
                  wrap="wrap"
                  gap="1rem"
                  className={styles.GridContainer}
                >        
                      <Header Title="SBSM News Administration" SubTitle="Manage the SBSM News" BreadCrumbItems ={vBreadCrumbItems} pageSource="SBSMNewsAdmin"/>     
                      <div className={styles.ToolBar}>          
                        <div className={styles.ToolBarRow}>                                                                                         
                            <div className={styles.ToolBarItem}>
                              <Button
                                loadingText=""
                                ariaLabel=""
                                className={styles.RedditTitleButtons}
                                onClick={ (e) => DeleteAll(e)}
                              >
                              Delete All
                              </Button>                                    
                            </div>  
                            <div className={styles.ToolBarItem}>
                              <Button
                                loadingText=""
                                ariaLabel=""
                                className={styles.RedditTitleButtons}
                                onClick={ (e) => AddArticle(e)}
                              >
                              Add Item
                              </Button>                                    
                            </div>                                 
                            <div className={styles.ToolBarItem}>
                              <Button
                                loadingText=""
                                ariaLabel=""
                                className={styles.RedditTitleButtons}
                                onClick={ (e) => GetSBSMNewsArticles(e)}
                              >
                              Refresh Data
                              </Button>                                    
                            </div>      
                            <div  className={styles.ToolBarItem}>
                              <Autocomplete                                  
                                  label="Autocomplete"
                                  options={Article}
                                  placeholder="Find item by title"
                                  value={SearchTerm1}
                                  onChange={onChangeAutocomplete}
                                  onClear={onClearAutocomplete}
                                  onSelect={onSelectAutocomplete}
                                  onSubmit={onSelectAutocomplete}   
                                  className={styles.amplifyautocomplete}    
                              />
                            </div>                                                                                                                          
                          </div>     
                          <div className={styles.TableRowData}>                               
                            <div ErrorMessage={ErrorMessage}>{ErrorMessage}</div>  
                          </div> 
                      </div>             
                      {ShowTable && (<SBSMNewsAdminTable  key={updatedKey} SBSMNewsArticles={SBSMNewsArticles}/>)  }

                </Flex>     
            </ErrorBoundary>
          </div>    
        )}         
      </Authenticator>                     

    )

  }
 

export default SBSMNewsAdmin;