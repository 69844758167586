import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import { SEO } from '../SEO/SEO';
import {ErrorBoundary} from 'react-error-boundary'
import HeaderMenu from "../HeaderMenu/LandingPageNavBar";
import {motion as m} from "framer-motion";

import  { }  from '../../graphql/queries'
import  { createAudienceGeoInfo }  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import {GetSBSMNewsArticlesAd, GetLogos} from '../../BusinessLogic/SBSMNewsManagement';
import SBSMNewsTable from "./SBSMNewsTable";
import SBSMNewsInfoSection from "./SBSMNewsInfoSection";
import { UserContext} from '../../BusinessLogic/Hooks/UserContext';

import axios from 'axios';
import {uuidv4} from "../../BusinessLogic/UtilitiesManagement";

import { Flex
} from '@aws-amplify/ui-react';
import styles from './SBSMNews.module.css';
import {GetSBSMNewsQueryTermsAd} from '../../BusinessLogic/SBSMQueryTermsManagement';

import MenuBackground from '../Menu/MenuBackground';
import {RhyteItLogging} from '../../BusinessLogic/UserManagement';

function ErrorFallback({error, resetErrorBoundary}) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}


const SBSMNews = (props) => {       
  
  SBSMNews.propTypes = {
          RedditUser: PropTypes.object
        };

        const client = generateClient();
        const [FirstTouch, setFirstTouch] = useState(true);      
        const [ip, setIP] = useState('');  
        var vFirstTouch = true

        const [SBSMNewsArticles, setSBSMNewsArticles] = useState([{id: '',
              UserId: '',
              Article:  '',
              InfoType:  '',
              Title: '',
              URL: '',
              Source: '',
              PubDate: '',
              SBSMStatus: '',
              Penalty: '',
              UpVotes: '',
              DownVotes: '',
              AgeInHours: '',
              CreationName:  '',
              UpdateName:  '',
              CreationDateTime:  '',
              UpdateDateTime:  '' }])

        const [SBSMLogos, setSBSMLogos] = useState();   

        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)  
        const [updatedKey, setupdatedKey] = useState(Date.now())   
        let debugConsoleOutPut = true

        const [Article, setArticle] = useState([]);
        const [ShowTable, setShowTable] = useState(false);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState(null);

        useEffect(() => {
          

          (async function() {
            try {  

                  
                  const fetchData = async () => {
                    try {
                        const response = await fetch('https://s3.us-west-2.amazonaws.com/blog.rhyteit.com/ACCLatestNews.json');
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        const jsonData = await response.json();            
                        setSBSMNewsArticles(jsonData["ACCNews"]);
                    } catch (error) {
                        setError(error);
                    } finally {
                        setLoading(false);
                    }
                };
            
                fetchData();

                } catch (err) {              
                  RhyteItLogging("NA", "SBSMNews", "useEffect[]",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
              }
            }
          )();   
      
          
          getData()

        },[]) 

        useEffect(() => {

          if (Object.keys(SBSMNewsArticles).length > 1) {
            
          GetLogos()
          
          }

          setErrorMessage("ACC News at " + new Date().toLocaleString())

        },[SBSMNewsArticles])     
                 
        useEffect(() => {
      
          //console.log("SBSMNews.useEffect[updatedKey].updatedKey:  ",updatedKey)
          //console.log("SBSMNews.useEffect[updatedKey].ShowTable:  ",ShowTable)
          //console.log("SBSMNews.useEffect[updatedKey].Object.keys(SBSMNewsArticles).length:  ",Object.keys(SBSMNewsArticles).length)

          if (Object.keys(SBSMNewsArticles).length > 1) {
            setShowTable(true)
          }


        }, [updatedKey]);      

        useEffect(() => {
      
          //console.log("SBSMNews.useEffect[updatedKey].updatedKey:  ",updatedKey)
          //console.log("SBSMNews.useEffect[updatedKey].ShowTable:  ",ShowTable)
          //console.log("SBSMNews.useEffect[updatedKey].Object.keys(SBSMNewsArticles).length:  ",Object.keys(SBSMNewsArticles).length)

          if (SBSMLogos !== undefined) {
            console.log("SBSMNews.useEffect[SBSMLogos].SBSMLogos:  ",SBSMLogos)
            setupdatedKey(Date.now())
          }


        }, [SBSMLogos]);              
  
        async function GetLogos() {
          try {
                    setErrorMessage("")
          
                    
                    const vLogos = await GetSBSMNewsQueryTermsAd("NA")
                                            
                    console.log("SBSMNews.GetLogos.vLogos:  ",vLogos);

                    setSBSMLogos(vLogos)

                    

  
          } catch (err) { 
            RhyteItLogging("NA", "SBSMNews", "GetSBSMNewsArticles",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                      }
        }    
        
        const getData = async () => {

      
          const vLogos = await GetSBSMNewsQueryTermsAd("NA")                                             
  
          setSBSMLogos(vLogos)                  
 
          if (vFirstTouch === true && process.env.REACT_APP_ENV === "PRODUCTION") {
                 vFirstTouch = false;    
        
              let DynamoUUID =  await uuidv4()
        
              let AudienceGeoItem = {
                id: DynamoUUID,
                UserId: "SBSMNews",
                Page: "SBSMNews",
                IPv4: "1.1.1.1.1",
                city: "SBSMNews",
                country_code: "SBSMNews",
                country_name: "SBSMNews",
                latitude: "37.751" ,
                longitude: "-97.822",
                postal: "NA",
                state: "NA",
                CreationName:  "SBSMNews",
                UpdateName: "SBSMNews"
              }                      
              
              try{
        
                let createAudienceGeoInfoResponse = await client.graphql({                    
                  query: createAudienceGeoInfo,
                  variables:{
                      input: AudienceGeoItem
                    }
              });         
                  
        
              } catch (err)  {
                RhyteItLogging("NA", "SBSMNews", "getData",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
             }
        
          }
        
        }

        async function GetSBSMNewsArticles() {
          try {
                    setErrorMessage("")
          
                    if (debugConsoleOutPut === true) {
                 //     //console.log("SBSMNews.GetSBSMNewsArticles."NA": ", "NA")
                    }
                    let vArticlesTmp = SBSMNewsArticles 

                    let {Articles, nextToken} = await GetSBSMNewsArticlesAd()

                    Articles.forEach(item => 
                      {    
                    
                        if ([...vArticlesTmp].filter(Article => item.id === Article.id)["length"] === 0) {  
                          vArticlesTmp.push(item) 
                        }

                        if (vArticlesTmp.length === 0) {

                          vArticlesTmp.push(...Articles) 

                        }
  
                      })                    

                    let vNextToken = nextToken

                      

                    while (vNextToken !== null) {
                      
                      let {Articles, nextToken} = await GetSBSMNewsArticlesAd(vNextToken)  
                    //  console.log("SBSMNews.GetSBSMNewsArticles.Articles -2:  ",Articles);
                   //   console.log("SBSMNews.GetSBSMNewsArticles.Articles.length - 2:  ",Articles.length);                    
                   //   console.log("SBSMNews.GetSBSMNewsArticles.NextToken - 2:  ",nextToken.substring(nextToken.length - 10)); 
                      Articles.forEach(item => 
                        {    
                      
                          if ([...vArticlesTmp].filter(Article => item.id === Article.id)["length"] === 0) {  
                            vArticlesTmp.push(item) 
                          }

                          if (vArticlesTmp.length === 0) {

                            vArticlesTmp.push(...Articles) 

                          }

                        })    
                      vNextToken = nextToken

                    }
                    
                    let FilterOutBlanks = [...vArticlesTmp].filter(Article => Article.id !== "")

                    let vSortedArticles = FilterOutBlanks.sort( compare );
                    
                    const vLogos = await GetSBSMNewsQueryTermsAd("NA")
                                            
                   // console.log("SBSMNews.GetSBSMNewsArticles.vSortedArticles:  ",vSortedArticles);

                    setSBSMLogos(vLogos)
                    setSBSMNewsArticles(vSortedArticles)

                    setupdatedKey(Date.now())

  
          } catch (err) { 
            RhyteItLogging("NA", "SBSMNews", "GetSBSMNewsArticles",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                      }
        }    
        
        function compare( a, b ) {
          if ( a.Score > b.Score ){
            return -1;
          }
          if ( a.Score < b.Score ){
            return 1;
          }
          return 0;
        }
      
    return ( 
      <m.div
      key="SBSMNews"
      initial={{y: "100%"}}
      animate={{y: "0%"}}
      transition={{duration:0.75, ease: "easeOut"}}
      exit={{ opacity: 1 }}
     >
            <SEO
              title="Find all the latest news, podcasts and videos about the adult content creator industry!"
              description='Live feed'
              name='RhyteIt'
              type='article' />  
            <MenuBackground className={styles.MenuBackground} GradientStopColor1="#0000" GradientStopColor2="#000"  GradientStopColor3="#484890"   GradientStopColor4="#8880"/>                                   

              <Flex
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    alignContent="flex-start"
                    wrap="wrap"
                    gap="2rem"
                    className = {styles.MasterContainer}>                 
                      <HeaderMenu  pageSource="SBSMNews" User={"NA"}/>
                  <Flex
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    alignContent="flex-start"
                    wrap="wrap"
                    gap="1rem"
                    className = {styles.MasterContainer}>                       
                      <h1 className={styles.h1}>Adult Content Creator News</h1>  
                      <div className={styles.headerline} />
                      <h2 className={styles.h2}>The #1 Adult Content Creator News Aggregator!!</h2>  
                      <SBSMNewsInfoSection />
                      <div className={styles.ArticleMessageBox}>{ErrorMessage}</div>  
                      {ShowTable && (<SBSMNewsTable  key={updatedKey} SBSMLogos={SBSMLogos} SBSMNewsArticles={SBSMNewsArticles}/>)  }
                    </Flex> 
                  </Flex> 
          </m.div>          

    )

  }
 

export default SBSMNews;