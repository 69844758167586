
import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead
  } from '@aws-amplify/ui-react'; 
import EmailRow from './EmailRow';
import styles from './EmailTable.module.css';


const EmailTable = (props) => {       

  EmailTable.propTypes = {
      RhyteItEmails: PropTypes.array,
      RhyteItUser: PropTypes.array
      };

    let debugConsoleOutPut = true

  

    const [RhyteItEmails, setRhyteItEmails] = useState(props.RhyteItEmails)   
    const [ShowTable, setShowTable] = useState(false);

    useEffect(() => {

      //console.log("EmailTable.useEffect[].props.RhyteItEmails:  ",props.RhyteItEmails) 
      setRhyteItEmails(props.RhyteItEmails)
      //console.log("EmailTable.useEffect[].RhyteItEmails ",RhyteItEmails) 
      //console.log("vEmailRows.useEffect[].props.RhyteItUser:  ",props.RhyteItUser) 
      //console.log("vEmailRows.useEffect[].Time:  ", EmailRow.PostDateTime.substr(EmailRow.PostDateTime.indexOf("T")+1, 5))
      //onsole.log("vEmailRows.useEffect[].indexOf:  ", EmailRow.PostDateTime.indexOf(":00"))
      if (Object.keys(RhyteItEmails).length > 0) {
        setShowTable(true)
      }



    },[])  



  return (  
      <Table className={styles.Table}   highlightOnHover={true}>   
          <TableHead>          
            <TableRow className={styles.TableHeaderRow}>
              <TableCell className={styles.TableHeaderRowCellsWide} >From</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >To</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Subject</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Recieved</TableCell> 
              <TableCell className={styles.TableHeaderRowCellsActions} colSpan="2" >Actions</TableCell> 
            </TableRow>                                      
          </TableHead>       
          
              {
                ShowTable && RhyteItEmails.length > 0 &&  RhyteItEmails && RhyteItEmails.map((RhyteItEmailsRow) => (                      
                  (<EmailRow   key={RhyteItEmailsRow.id}  Email={RhyteItEmailsRow}/>)        
                  ))
              }                    
        </Table>
    
  )
}

export default EmailTable;


