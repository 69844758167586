
import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead
  } from '@aws-amplify/ui-react'; 
import EmailAttachementRow from './EmailAttachementRow';
import styles from './EmailAttachementTable.module.css';


const EmailAttachementTable = (props) => {       

  EmailAttachementTable.propTypes = {
      EmailAttachements: PropTypes.array,
      RhyteItUser: PropTypes.array
      };

    let debugConsoleOutPut = true

  

    const [EmailAttachements, setEmailAttachements] = useState(props.EmailAttachements)   
    const [ShowTable, setShowTable] = useState(false);

    useEffect(() => {

      //console.log("EmailAttachementTable.useEffect[].props.EmailAttachements:  ",props.EmailAttachements) 
      setEmailAttachements(props.EmailAttachements)
      //console.log("EmailAttachementTable.useEffect[].EmailAttachements ",EmailAttachements) 
      //console.log("vEmailAttachementRows.useEffect[].props.RhyteItUser:  ",props.RhyteItUser) 
      //console.log("vEmailAttachementRows.useEffect[].Time:  ", EmailAttachementRow.PostDateTime.substr(EmailAttachementRow.PostDateTime.indexOf("T")+1, 5))
      //onsole.log("vEmailAttachementRows.useEffect[].indexOf:  ", EmailAttachementRow.PostDateTime.indexOf(":00"))
      if (Object.keys(EmailAttachements).length > 0) {
        setShowTable(true)
      }



    },[])  



  return (  
      <Table className={styles.Table}   highlightOnHover={true}>   
          <TableHead>          
            <TableRow className={styles.TableHeaderRow}>
              <TableCell className={styles.TableHeaderRowCellsWide} >id</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >File Name</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Content Type</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Size</TableCell> 
            </TableRow>                                      
          </TableHead>       
          
              {
                ShowTable && EmailAttachements.length > 0 &&  EmailAttachements && EmailAttachements.map((EmailAttachementsRow) => (                      
                  (<EmailAttachementRow   key={EmailAttachementsRow.id}  EmailAttachement={EmailAttachementsRow}/>)        
                  ))
              }                    
        </Table>
    
  )
}

export default EmailAttachementTable;


