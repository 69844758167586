import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';


import  {createAudienceGeoInfo}  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import Header from "../RhyteItComponents/Header/Header";
import {motion as m} from "framer-motion";
import DashboardContent from "./DashboardContent";
import { UserContext, CognitoGroupContext} from '../../BusinessLogic/Hooks/UserContext';
import {ValidateUser, GetGroupRoleAccess, RhyteItLogging, ReturnUserToLandingPage} from '../../BusinessLogic/UserManagement';
import MenuBackground from '../Menu/MenuBackground';
import PageViewMap from "./PageViewMap";
import MetricsTable from "./MetricsTable";
import { fetchAuthSession } from 'aws-amplify/auth';

import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  SelectField,
  Flex
} from '@aws-amplify/ui-react';
import styles from './Dashboard.module.css';

const Dashboard = (props) => {       
  
  Dashboard.propTypes = {
          RedditUser: PropTypes.object
        };

        const client = generateClient();
        const [RhyteItDashboardContent, setRhyteItDashboardContent] = useState([])
        const vBreadCrumbItems = [{Path: "/", Title:"Home", isCurrent:false},{Path: "/MainMenuV2", Title:"Menu", isCurrent:false},{Path: "/Dashboard", Title:"Dashboard", isCurrent:true}] 
              
        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   
        let debugConsoleOutPut = true

        const {Users, setUsers} = useContext(UserContext);    
        const {CognitoGroup, setCognitoGroup} = useContext(CognitoGroupContext);      
        const [ShowTable, setShowTable] = useState(false);        

        const [updatedKey, setupdatedKey] = useState(Date.now())   

        const [Report, setReport] = useState("MetricsTable");
        const [DashboardHighlevel, setDashboardHighlevel]= useState([]);
        

        useEffect(() => {

        (async function() {
          try {  

                  GetOFStatsFromS3()
                  //console.log("Dashboard.useEffect[].Users:  ",Users)

                  if (Users === undefined) {                    
                    //console.log("Dashboard.useEffect[].Users:  ",Users)
                      let vIsUserSubscribed = await ValidateUser()
                      setUsers(vIsUserSubscribed)
                      //console.log("Dashboard.useEffect[].vIsUserSubscribed:  ",vIsUserSubscribed)

                  }                    

                  //console.log("Dashboard.useEffect[].Users:  ",Users)

              } catch (err) {
                console.error('Dashboard.useEffect[].ERROR: ', err, err.stack); 
            }
          }
        )();   


        },[]) 

        useEffect(() => {
             
             
          (async function() {
            try {  

                    //console.log("EmailManagement.useEffect[CognitoGroup].CognitoGroup:  ",CognitoGroup);
                    if (CognitoGroup !== undefined) {

                  

                                let   GetGroupRoleAccessResponse = await GetGroupRoleAccess(CognitoGroup, "rhyteitAdmins")
                                
                              // console.log("EmailManagement.useEffect[CognitoGroup].GetGroupRoleAccessResponse:  ",GetGroupRoleAccessResponse);
                                if (GetGroupRoleAccessResponse) {     
                    GetOFStatsFromS3()
                  }
            

                  } else {

                    const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
                  // console.log("EmailManagement.useEffect[CognitoGroup].idToken:  ",idToken);
                  // console.log("EmailManagement.useEffect[CognitoGroup].accessToken:  ",accessToken);

                    if (idToken !== undefined) {
                      setUsers(idToken.payload.email)
                      setCognitoGroup(accessToken["payload"]["cognito:groups"])
                    } else {
                      
                      ReturnUserToLandingPage(Users, CognitoGroup)
                    }

          //    ReturnUserToLandingPage(Users, CognitoGroup)
                }
              } catch (err) {                        
                  RhyteItLogging(Users, "Dashboard", "useEffect[CognitoGroup]",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
              }
            }
          )();   
    
           },[CognitoGroup])    

        async function GetOFStatsFromS3() {
          try {
    
                    const url = `https://rhyteitfiles.s3.us-west-2.amazonaws.com/RhyteItOnlyFansGrowth.json`
    
                    const fetchResponse = await fetch(url);
                    let RhyteItOnlyFansGrowth = await fetchResponse.json();
                    //console.log("GetOFStatsFromS3.RhyteItOnlyFansGrowth: ",RhyteItOnlyFansGrowth[RhyteItOnlyFansGrowth.length - 1]);
                    //console.log("GetOFStatsFromS3.RhyteItOnlyFansGrowth.typeof: ",typeof RhyteItOnlyFansGrowth);
                                      
    
                    setRhyteItDashboardContent(RhyteItOnlyFansGrowth)  
                    setShowTable(true)
                    setupdatedKey(Date.now())  
                    setReport("MetricsTable")
    
          } catch (err) { 
            RhyteItLogging(Users, "Dashboard", "GetOFStatsFromS3",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                      }
        }     
        
        function ReportSelected(pReport) {
          try {
   

                    //console.log("ReportSelected.pReport: ",pReport);
                    setShowTable(true)
                    setupdatedKey(Date.now())  
                    setReport(pReport)
     
                    //console.log("ReportSelected.DashboardHighlevel.length: ",DashboardHighlevel.length); 
    
          } catch (err) { 
                          console.error('Dashboard.ReportSelected.ERROR: ', err, err.stack); 
                      }
        }             

    return ( 
    <m.div
      key="Search"
      initial={{x: "100%"}}
      animate={{x: "0%"}}
      transition={{duration:0.75, ease: "easeOut"}}
      exit={{ opacity: 1 }}
     >
  
                    <MenuBackground className={styles.MenuBackground} GradientStopColor1="#0000" GradientStopColor2="#000"  GradientStopColor3="#cc6633"   GradientStopColor4="#8880"/> 
                    <Flex
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      alignContent="flex-start"
                      wrap="nowrap"
                      gap="3rem"
                      className={styles.GridContainer}
                    >                                
                      <Header Title="Dashboard" SubTitle="Visually understand your metrics" BreadCrumbItems ={vBreadCrumbItems} pageSource="Dashboard"/>
                      <SelectField
                          value={Report}
                          onChange={(e) => ReportSelected(e.target.value)}
                          className={styles.SelectField}                                     
                        >
                            <option value="MetricsTable">Summary</option>
                            <option value="OFProfilesCumm">OF Profiles</option>
                            <option value="TwitterFollowers">Twitter Followers</option>
                            <option value="TwitterFollowing">Following on Twitter</option>
                            <option value="PageViewsCumm">Page Views</option>
                            <option value="PageViewMap">Page View Map</option>
                            <option value="ApplicationCumm">Application Views</option>
                            <option value="ApplicantCumm">Applicants</option>
                            

                        </SelectField>   
                        {Report !== "PageViewMap" && Report !== "MetricsTable" && ( 
                          <DashboardContent key={updatedKey}  RhyteItDashboardContent={RhyteItDashboardContent} Report={Report}/>                                                                                  
                        )}
                        {Report === "PageViewMap" && ( 
                          <PageViewMap key={updatedKey} Report={Report}/>                                                                                  
                        )} 
                        {Report === "MetricsTable" && ( 
                          <MetricsTable key={updatedKey}  RhyteItDashboardContent={RhyteItDashboardContent}  Report={Report}/>                                                                                  
                        )}     
                </Flex>    
    </m.div>                      

    )

  }
 

export default Dashboard;