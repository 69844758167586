
import React, { useState , useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Button,
  Image,
  TableBody,
  TableRow,
  TableCell,
  Text,
  TextField
  } from '@aws-amplify/ui-react';

  import  {listNewsletters  }  from '../../../graphql/queries'
  import  { deleteContent, updateNewsletterEdition, updateNewsletter, createNewsletterEditionTracking }  from '../../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";
  import {
    useInView,
    useScroll,
    useTransform,
    motion,
    useAnimation,
  } from "framer-motion";
  import styles from './NewsletterEditionPreview.module.css';  
  import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
  
function NewsletterEditionPreview(
  props
) {       

  NewsletterEditionPreview.propTypes = {
    Newsletter: PropTypes.object,
    NewsletterEdition: PropTypes.object,
    Users: PropTypes.string,
    };

    const client = generateClient();
  
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vNewsletter, setvNewsletter] = useState(props.Newsletter)  
    const [vNewsletterEdition, setvNewsletterEdition] = useState(props.NewsletterEdition)   
    const [vPixelTrackerAPIURL, setvPixelTrackerAPIURL] = useState();
    const [Users, setUsers] = useState(props.Users)   
    
    const [vContentHTML, setvContentHTML] = useState('');
    const [vTemplateJSON, setvTemplateJSON] = useState()  
    const [vContentJSON, setvContentJSON] = useState()  
    const [vSectionJSON, setvSectionJSON] = useState()  
    const [vIntroduction, setvIntroduction] = useState()  
    const [vInThisIssue, setvInThisIssue] = useState()  
    const [vNewsletterEditionTracking, setvNewsletterEditionTracking] = useState()


    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);

    var [htmlContent, setHtmlContent] = useState('');
    const [vS3Key, setvS3Key] = useState('');
    const [vEmailId, setvEmailId] = useState('');

    const buildHtmlContent = () => {
      const html = `
              <!DOCTYPE html>
              <html lang="en">
              <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>` + vNewsletter["Title"] + `</title>
                <style>
                  body {
                    font-family: Arial, sans-serif;
                    background-color: #f4f4f4;
                    margin: 0;
                    padding: 0;
                  }
                  table {
                    max-width: 600px;
                    margin: 0 auto;
                    background-color: #ffffff;
                    border-collapse: collapse;
                    border-spacing: 0;
                  }
                  td {
                    padding: 20px;
                  }
                  h1, h2 {
                    color: #333333;
                  }
                  p {
                    color: #555555;
                    line-height: 1.6;
                  }
                  a {
                    color: #007bff;
                    text-decoration: none;
                  }
                  .button {
                    display: inline-block;
                    padding: 10px 20px;
                    background: linear-gradient(45deg, #99FFFF, #FFCC99, #CCCCFF);
                    color: #020202;
                    text-align: center;
                    border-radius: 5px;
                    text-decoration: none;
                    font-size: 16px;
                  }
                  .footer {
                    font-size: 12px;
                    color: #999999;
                    text-align: center;
                  }
                </style>
              </head>
              <body>
                <img src="` + vTemplateJSON["PixelTrackerAPIURL"] + `" alt="" width="1" height="1" style="display:none;"/>
                <table border="0" cellpadding="0" cellspacing="0">
                  <tr>
                    <td align="center" style="background-color: transparent; color: #ffffff; padding: 30px;">
                      <img src="` + vTemplateJSON["NewsletterBannerURL"] + `" alt="FCC Logo" /> 
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <h2>` + vContentJSON["Sections"][5]["Introduction"]["Title"].replace(/\n/g, "<br/>") + `</h2>
                      <p>` + vContentJSON["Sections"][5]["Introduction"]["Text"].replace(/\n/g, "<br/>") + `</p>
                    </td>
                  </tr>
                  <tr>
                      <td style="background-color: #f4f4f4; padding: 20px;">
                          <a  class="button" href="` + vContentJSON["Sections"][5]["Introduction"]["ButtonURL"].replace(/\n/g, "<br/>") + `" target="_blank" 
                              style="font-size: 16px; font-family: Arial, sans-serif; color: #000000; text-decoration: none; padding: 12px 24px; display: inline-block; border-radius: 25px;">
                              ` + vContentJSON["Sections"][5]["Introduction"]["ButtonTitle"].replace(/\n/g, "<br/>") + `
                          </a>
                      </td>
                  </tr>       

                  <tr>
                    <td>
                      <h2>In This Issue:</h2>
                      <p>` + vContentJSON["Sections"][0]["In This Issue"]["Text"].replace(/\n/g, "<br/>") + `</p>
                    </td>
                  </tr>
                  <tr>
                    <td align="center">
                      <p class="Text"><strong class="Strong">🎧 Listen Now: </strong>`+vContentJSON["Sections"][4]["Podcast"]["Title"]+`</p>
                      <a href="`+vContentJSON["Sections"][4]["Podcast"]["AVURL"]+`" target="_blank" style="text-decoration: none;">
                          <img src="`+vContentJSON["Sections"][4]["Podcast"]["ImageURL"]+`" 
                              alt="Click to Listen to the Podcast" 
                              style="border: 0; display: block; max-width: 100%; height: auto;">
                      </a>         
                    </td>
                  </tr>
                  <tr>
                      <td style="padding: 20px;">
                          <a  class="button" href="`+vContentJSON["Sections"][4]["Podcast"]["ButtonURL"]+`" target="_blank" 
                              style="font-size: 16px; font-family: Arial, sans-serif; color: #000000; text-decoration: none; padding: 12px 24px; display: inline-block; border-radius: 25px;">
                              `+vContentJSON["Sections"][4]["Podcast"]["ButtonTitle"]+`
                          </a>
                      </td>
                  </tr>   
                  <tr>
                    <td style="background-color: #f4f4f4; padding: 20px;">
                      <h2>📈 Featured Article: `+vContentJSON["Sections"][1]["Featured Article"]["Title"]+`</h2>
                      <p>`+vContentJSON["Sections"][1]["Featured Article"]["Text"].replace(/\n/g, "<br/>")+`</p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <h2>🚀 Quick Tips: `+ vContentJSON["Sections"][2]["Quick Tips"]["Title"].replace(/\n/g, "<br/>")+`</h2>
                      <p>`+vContentJSON["Sections"][2]["Quick Tips"]["Text"].replace(/\n/g, "<br/>")+`</p>
                    </td>
                  </tr>

                  <tr>
                    <td style="background-color: #f4f4f4; padding: 20px;">
                      <h2>❤️ Success Story: `+vContentJSON["Sections"][3]["Success Story"]["Title"].replace(/\n/g, "<br/>")+`</h2>
                      <p>`+vContentJSON["Sections"][3]["Success Story"]["Text"].replace(/\n/g, "<br/>")+`</p>
                    </td>
                  </tr>
                      <tr>
                        <td style="background-color: #f4f4f4; padding: 20px;">
                          <a   class="button" href="`+vContentJSON["Sections"][3]["Success Story"]["ButtonURL"]+`" target="_blank" 
                            style="font-size: 16px; font-family: Arial, sans-serif; color: #000000; text-decoration: none; padding: 12px 24px; display: inline-block; border-radius: 25px;">
                            `+vContentJSON["Sections"][3]["Success Story"]["ButtonTitle"]+`
                          </a>          
                      </td>
                      </tr>
                      <tr>
                          <td style="background-color: #f4f4f4; padding: 20px; text-align: center;">
                            <p style="font-size: 14px; color: #999999;">
                              You're receiving this email because you subscribed to the RhyteIt newsletter. If you'd like to update your preferences or unsubscribe, click <a href="` + vTemplateJSON["UnsubscriberURL"] + `" style="color: #007bff; text-decoration: none;">here</a>.
                            </p>
                            <p style="font-size: 14px; color: #999999;">
                              ` + vTemplateJSON["Copyright"] + `<br>
                              ` + vTemplateJSON["Address"] + `<br>
                              <a href="mailto:` + vTemplateJSON["NewsletterEmail"] + `" style="color: #007bff; text-decoration: none;">` + vTemplateJSON["NewsletterEmail"] + `</a> | <a href="#" style="color: #007bff; text-decoration: none;">Privacy Policy</a>
                            </p>
                            <p style="font-size: 12px; color: #999999;">
                              This email was intended for adult content creators. If this isn't you, please <a href="` + vTemplateJSON["UnsubscriberURL"] + `" style="color: #007bff; text-decoration: none;">unsubscribe here</a>.
                            </p>
                          </td>
                        </tr>
                </table>
              </body>
              </html>
      `;
      //console.log("NewsletterEditionPreview.buildHtmlContent.html:  ",html) 
      // Set the dynamically generated HTML content

      setHtmlContent(html.replace(/<SubscriberEmail>/g, Users).replace(/<SubscriberEmailId>/g, vEmailId));
    };
  

      useEffect(() => {
        
       

      },[])  

      useEffect(() => {

        

        if (vNewsletterEdition !== undefined && vNewsletter !== undefined ) {
          CreateMetaData()
        }

        if (vNewsletterEdition !== undefined) {
          setvContentHTML(vNewsletterEdition["ContentHTML"])
          const vContentJSONTmp = JSON.parse(vNewsletterEdition["ContentJSON"]);  
          setvContentJSON(vContentJSONTmp)  

        }

    },[vNewsletterEdition])    

    useEffect(() => {
        
      if (vContentJSON !== undefined) {
         
          //buildHtmlContent()
          ReplaceTemplateVariables()
        }


    }, [vContentJSON]);         



    useEffect(() => {

      //console.log("NewsletterEditionPreview.useEffect[vNewsletterEdition].vNewsletterEdition:  ",vNewsletter) 
      const vTemplateJSONTmp = JSON.parse(vNewsletter["TemplateJSON"]);  
      setvTemplateJSON(vTemplateJSONTmp)

  },[vNewsletter])        
  

    useEffect(() => {

      if (vTemplateJSON !== undefined) {
       // //console.log("NewsletterEditionPreview.useEffect[vTemplateJSON].vTemplateJSON:  ",vTemplateJSON) 
       
      }


    },[vTemplateJSON])        

    async function ReplaceTemplateVariables() {
        
  
      try {           
       
        let vContentHTMLTmp = vContentHTML


        vContentHTMLTmp = vContentHTMLTmp.replace(/<NewsletterTitle>/g, vNewsletter["Newsletter"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<PixelTrackerAPIURL>/g, vTemplateJSON["PixelTrackerAPIURL"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<NewsletterBannerURL>/g, vTemplateJSON["NewsletterBannerURL"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<IntroductionTitle>/g,vContentJSON["Sections"][5]["Introduction"]["Title"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<IntroductionText>/g,vContentJSON["Sections"][5]["Introduction"]["Text"].replace(/\n/g, "<br/>"))
        vContentHTMLTmp = vContentHTMLTmp.replace(/<IntroductionButtonURL>/g,vContentJSON["Sections"][5]["Introduction"]["ButtonURL"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<IntroductionButtonTitle>/g,vContentJSON["Sections"][5]["Introduction"]["ButtonTitle"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<InThisIssueText>/g,vContentJSON["Sections"][0]["In This Issue"]["Text"].replace(/\n/g, "<br/>"))
        vContentHTMLTmp = vContentHTMLTmp.replace(/<PodcastTitle>/g,vContentJSON["Sections"][4]["Podcast"]["Title"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<PodcastAVURL>/g,vContentJSON["Sections"][4]["Podcast"]["AVURL"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<PodcastImageURL>/g,vContentJSON["Sections"][4]["Podcast"]["ImageURL"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<PodcastButtonURL>/g,vContentJSON["Sections"][4]["Podcast"]["ButtonURL"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<PodcastButtonTitle>/g,vContentJSON["Sections"][4]["Podcast"]["ButtonTitle"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<FeaturedArticleTitle>/g,vContentJSON["Sections"][1]["Featured Article"]["Title"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<FeaturedArticleText>/g,vContentJSON["Sections"][1]["Featured Article"]["Text"].replace(/\n/g, "<br/>"))
        vContentHTMLTmp = vContentHTMLTmp.replace(/<QuickTipsTitle>/g,vContentJSON["Sections"][2]["Quick Tips"]["Title"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<QuickTipsText>/g,vContentJSON["Sections"][2]["Quick Tips"]["Text"].replace(/\n/g, "<br/>"))
        vContentHTMLTmp = vContentHTMLTmp.replace(/<SuccessStoryTitle>/g,vContentJSON["Sections"][3]["Success Story"]["Title"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<SuccessStoryText>/g,vContentJSON["Sections"][3]["Success Story"]["Text"].replace(/\n/g, "<br/>"))
        vContentHTMLTmp = vContentHTMLTmp.replace(/<SuccessStoryButtonURL>/g,vContentJSON["Sections"][3]["Success Story"]["ButtonURL"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<SuccessStoryButtonTitle>/g,vContentJSON["Sections"][3]["Success Story"]["ButtonTitle"])

        
        vContentHTMLTmp = vContentHTMLTmp.replace(/<UnsubscriberURL>/g, vTemplateJSON["UnsubscriberURL"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<NewsletterEmail>/g, vTemplateJSON["NewsletterEmail"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<Copyright>/g, vTemplateJSON["Copyright"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<Address>/g, vTemplateJSON["Address"])
        
        
        vContentHTMLTmp = vContentHTMLTmp.replace(/<SubscriberEmail>/g, Users).replace(/<SubscriberEmailId>/g, vEmailId)

        setvContentHTML(vContentHTMLTmp.replace(/<SubscriberEmail>/g, Users).replace(/<SubscriberEmailId>/g, vEmailId));

        } catch (err) { 
          RhyteItLogging(Users, "NewsletterEditionPreview", "UpdateNewsletterEdition",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          console.error("NewsletterEditionPreview.UpdateNewsletterEdition.Error:  ".err, err.stack); 
      }        
    }  


    async function UpdateNewsletterEdition() {
        
  
      try {           
          var today = new Date();
          setErrorMessage("")
          
          var vUpdateDateTime = new Date().toISOString()


          vNewsletterEdition.UpdateDateTime = vUpdateDateTime       
          vNewsletterEdition.UpdateName = Users           
      
          
          //console.log("NewsletterEditionPreview.UpdateNewsletterEdition.vContentJSON: ",vContentJSON)

          const vContentJSONString = JSON.stringify(vContentJSON);   
              
          let vNewsletterEditionUpdateItem = {
            "id": vNewsletterEdition["id"],
            "AccountGroup": vNewsletterEdition["AccountGroup"],
            "CampaignName": vNewsletterEdition["CampaignName"],
            "Newsletter": vNewsletterEdition["Newsletter"],
            "User": vNewsletterEdition["User"],
            "NewsletterDate": vNewsletterEdition["NewsletterDate"],
            "Edition": vNewsletterEdition["Edition"],
            "ContentHTML": vContentHTML,
            "S3HtmlLocation": vNewsletterEdition["S3HtmlLocation"],
            "S3Bucket": vNewsletterEdition["S3Bucket"],
            "S3Key": vNewsletterEdition["S3Key"],
            "ContentJSON": vContentJSONString,
            "NewsletterEditionStatus": "SEND",
            "SendCount": vNewsletterEdition["SendCount"],
            "OpenCount": vNewsletterEdition["OpenCount"],
            "ClickCount": vNewsletterEdition["ClickCount"],
            "Active": vNewsletterEdition["Active"],
            "CreationName": vNewsletterEdition["CreationName"],
            "UpdateName": vNewsletterEdition["UpdateName"],
            "CreationDateTime": vNewsletterEdition["CreationDateTime"],
            "UpdateDateTime": vNewsletterEdition["UpdateDateTime"]
          }

           //console.log("NewsletterEditionPreview.Unsubscribe.vNewsletterEditionUpdateItem: ",vNewsletterEditionUpdateItem)

            let updateNewsletterEditionResponse = await client.graphql({                    
                    query: updateNewsletterEdition,
                    variables:{input: vNewsletterEditionUpdateItem}            
              }
            )
          
          //console.log("NewsletterEditionPreview.UpdateNewsletterEdition.updateNewsletterEditionResponse: ",updateNewsletterEditionResponse)

          setvNewsletterEdition(updateNewsletterEditionResponse.data.updateNewsletterEdition)        
          setErrorMessage("Edition saved")
        } catch (err) { 
          RhyteItLogging(Users, "NewsletterEditionPreview", "UpdateNewsletterEdition",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          console.error("NewsletterEditionPreview.UpdateNewsletterEdition.Error:  ".err, err.stack); 
      }        
    }  

    function convertToISO(datetimeStr) {
      // Create a new Date object from the provided string
      let date = new Date(datetimeStr.replace(' ', 'T') + 'Z'); // Replace the space with 'T' to make it compatible
      
      // Convert it to ISO string
      return date.toISOString();
  }
  
  async function CreateMetaData() {
        
  
    try {           
        setErrorMessage("")
        
        var vUpdateDateTime = new Date().toISOString()
    
        let vId = (vNewsletter["Newsletter"] + "-" + vNewsletter["AccountGroup"] + "-" + vNewsletterEdition["Edition"]+ "-" + Users+ "-" +vUpdateDateTime).replace(/["']/g, "").replace(/\s+/g, '').replace(/[^\w]/g, '');
        let vS3Key = "/"+ (vNewsletter["Newsletter"] + "-" + vNewsletter["AccountGroup"] + "-" + vNewsletterEdition["Edition"]+ "-" + Users).replace(/["']/g, "").replace(/\s+/g, '').replace(/[^\w]/g, '') +"/"+ vId+".html"          
        
        setvEmailId(vId)
        setvS3Key(vS3Key)

      } catch (err) { 
        RhyteItLogging(Users, "NewsletterEditionPreview", "CreateNewsletterEditionTracking",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
        console.error("NewsletterEditionPreview.CreateNewsletterEditionTracking.Error:  ".err, err.stack); 
    }        
  } 


    async function CreateNewsletterEditionTracking() {
        
  
      try {           
          var today = new Date();
          setErrorMessage("")
          
          var vUpdateDateTime = new Date().toISOString()

          vNewsletterEdition.UpdateDateTime = vUpdateDateTime       
          vNewsletterEdition.UpdateName = Users      
          vNewsletterEdition.CreationDateTime = vUpdateDateTime       
          vNewsletterEdition.CreationName = Users                

          let datetimeStr = "1999-01-01 01:00:00";
          let isoDate = convertToISO(datetimeStr);

          let vNewsletterEditionCreateItem = {
            "id": vEmailId,
            "AccountGroup": vNewsletterEdition["AccountGroup"],
            "CampaignName": vNewsletterEdition["CampaignName"],
            "Newsletter": vNewsletterEdition["Newsletter"],
            "Edition": vNewsletterEdition["Edition"],
            "EmailHTML": htmlContent,
            "SESMessageId": "",
            "S3HtmlLocation": "",
            "S3Bucket": "rhyteitnewsletters",
            "S3Key": vS3Key,
            //"RecipientEmail": "RHYTEITEMAILTESTBOUNCE1004241559@gmail.com",
            "RecipientEmail": Users,
            "SendDateTime": isoDate,
            "FirstOpenDateTime": isoDate,
            "OpenDateTime": isoDate,
            "LastClickDateTime": isoDate,
            "UnsubscribeDateTime": isoDate,
            "SendCount": 0,
            "OpenCount": 0,
            "ClickCount": 0,
            "DeliveryStatus": "SEND",
            "BounceReason": "",
            "UnsubscribeStatus": false,
            "AudienceSegment": "Internal",
            "DeviceType": "",
            "UserAgent": "",
            "IPAddress": "",
            "City": "",
            "Country": "",
            "ContentVersion": 1,
            "NewsletterTrackingStatus": "SEND",
            "CreationName": vNewsletterEdition["CreationName"],
            "UpdateName": vNewsletterEdition["UpdateName"],
            "CreationDateTime": vNewsletterEdition["CreationDateTime"],
            "UpdateDateTime": vNewsletterEdition["UpdateDateTime"]
          }

           //console.log("NewsletterEditionPreview.CreateNewsletterEditionTracking.vNewsletterEditionCreateItem: ",vNewsletterEditionCreateItem)

            let createNewsletterEditionTrackingResponse = await client.graphql({                    
                    query: createNewsletterEditionTracking,
                    variables:{input: vNewsletterEditionCreateItem}            
              }
            )
          
          //console.log("NewsletterEditionPreview.CreateNewsletterEditionTracking.createNewsletterEditionTrackingResponse: ",createNewsletterEditionTrackingResponse)

          setvNewsletterEditionTracking(createNewsletterEditionTrackingResponse.data.createNewsletterEditionTracking)        
          setErrorMessage("Email Sent")
        } catch (err) { 
          RhyteItLogging(Users, "NewsletterEditionPreview", "CreateNewsletterEditionTracking",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          console.error("NewsletterEditionPreview.CreateNewsletterEditionTracking.Error:  ".err, err.stack); 
      }        
    } 

      return (
        <div  ref={ref}  className={styles.Background} 
          key={vNewsletterEdition.id} >
          <Flex
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              alignContent="flex-start"
              wrap="wrap"
              gap="1rem"
              className = {styles.FlexColumn}>  
              <Flex
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  alignContent="center"
                  wrap="nowrap"
                  gap="3rem"
                  className={styles.NewNewsletterRow}
                >                
                      <Button
                            className={styles.Button}
                            variation="primary"
                            onClick={(e) => UpdateNewsletterEdition(e)}
                          >
                            Save
                      </Button>    
                      <Text
                                className={styles.TextFieldTitle}
                              >
                              {new Date(vNewsletterEdition["UpdateDateTime"]).toISOString().slice(0, 10)} {new Date(vNewsletterEdition["UpdateDateTime"]).toISOString().slice(11, 16)}
                      </Text>
                      <Button
                            className={styles.Button}
                            variation="primary"
                            onClick={(e) => CreateNewsletterEditionTracking(e)}
                          >
                            Send to test email to: {Users}
                      </Button>        
                      <Text
                                className={styles.TextFieldTitle}
                              >
                              {ErrorMessage}
                      </Text>                                                    
                </Flex>             
            <div
              dangerouslySetInnerHTML={{ __html: vContentHTML }}
            />                                                       
        </Flex>           

        </div>
 
    )
  }

export default NewsletterEditionPreview;

