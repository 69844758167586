import React from 'react'
import styles from './PrivacyPolicy.module.css';
import HeaderMenu from "../HeaderMenu/LandingPageNavBar";

const PrivacyPolicy  = (props) => {

    return (
        <div>
            <HeaderMenu/>
    <div>
        <title>Privacy Policy</title>

        <meta name="description" />
        <meta property="og:locale" content="en-US" />
        <meta property="og:title" content="Privacy Policy" />
        <meta property="og:description" content="Privacy Policy" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://www.rhyteit.com/PrivacyPolicy" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Privacy Policy" />
        <meta name="twitter:description" content="Privacy Policy" />	
    </div>
    <div>

	<main  className = {styles.main}>
        <section className = {styles.B2_text_hero}>
            <div className = {styles.container}>
                <div>
                    <span className = {styles.B2_text_hero}></span>

                    <h1 className = {styles.B2_text_hero__headline}>Privacy Policy</h1>


                    <div className = {styles.B2_text_hero__breaker}></div>
                </div>
            </div>
        </section>

        <section className = {styles.B2_text_hero}>
        <div className = {styles.container}>
                                <div className={styles.SignUpContentSectionCenterLeftColumn}>
                                    <div>
                                        <h2 className={styles.DescriptionTitle}>We will never sell or give your data to anyone.</h2>
                                    </div>                                     
                                    <span className={styles.SignUpFormSpan}></span>
                                    <div>
                                        <h2 className={styles.DescriptionTitle}>When you delete your RhyteIt account, you will never have existed to RhyteIt.</h2>
                                    </div>                                      
                                    <span className={styles.SignUpFormSpan}></span>
                                    <div>
                                        <h2 className={styles.MissYouTitle}>...that doesn't mean we won't miss you.</h2>
                                    </div>                                     
                                </div>   
                        </div>  
        </section>       

        <section className = {styles.C1_one_column_paragraph}>
            <div className = {styles.container}>
                <div>
                    <div className = {styles.C1_one_column_paragraph__content}>
                        <p>At RhyteIt, we recognize and respect your privacy. Therefore, we provide this Privacy Statement to inform you of our policy toward collecting and utilizing information from our users. Please read the Privacy Statement carefully. RhyteIt may update the Privacy Statement from time to time, so please check this site regularly. The Privacy Statement applies only to sites owned by RhyteIt but not to any third party sites that may be linked to an RhyteIt site. Please check those sites for the applicable privacy policy.</p>
                        <h2>Information Placed on Your Computer</h2>
                        <p>As you visit an RhyteIt site, certain recognition data files may be attached to your hard drive. These files are called "cookies" and are used by RhyteIt to customize your visit to the site. Cookies can be erased or blocked from your computer through your web browser. RhyteIt may also collect domain names and gather information such as number of visits, the pages visited, and the average time spent on each page to track traffic patterns throughout the site. This information allows RhyteIt to improve the overall content of the site and increase user satisfaction.</p>
                        <h2>Personally Identifiable Information Collection and Use</h2>
                        <p>At points throughout the site, RhyteIt allows its users to submit personal information in order to request data on RhyteIt or to sign up for mailing lists. This personally identifiable information includes first and last name, street address, email address, telephone number, fax number and company name. Other points on the website allow for direct order of products and ask for a user identification code and password that may require additional personally identifiable information such as your mother's maiden name and social security number. RhyteIt uses your personal information only for the purpose for which you provide it. RhyteIt does not sell or otherwise distribute personally identifiable information from its consumers to third parties.</p>
                        <h2>Access to Personal Data</h2>
                        <p>Users who wish to review the personally identifiable information submitted on the RhyteIt site can do so by contacting RhyteIt.</p>
                        <h2>Request for Opt Out or Modification</h2>
                        <p>At your request, RhyteIt will remove or modify any information submitted by you, as appropriate, from its database. If you wish to opt out, please&nbsp;<a title="Contact Us" href="https://discord.gg/jtzvBYf7cd" rel="noopener">contact us</a>.</p>
                        <p>As mentioned above, RhyteIt does not sell or share personally identifiable information from its users to third parties.</p>
                        <h2>Information from Children</h2>
                        <p>RhyteIt does not direct its websites toward children. RhyteIt does not seek to collect personal information from or about persons under the age of eighteen.</p>
                        <h2>Hyperlinks</h2>
                        <p>For your convenience, this site contains links to other websites, including some sites administered by RhyteIt or administered by other companies on behalf of RhyteIt, as well as to third party and affiliate sites not maintained by RhyteIt. RhyteIt has not reviewed all of these third party sites, does not control, and is not responsible for any of these sites or their content. This Privacy Statement does not apply to these linked websites. Please check each website for its own privacy policy. RhyteIt is not responsible for the privacy practices or the content of linked websites that are not administered by RhyteIt.</p>
                        <h2>Effective Date and Updates</h2>
                        <p>This Privacy Statement is effective as of July 1, 2004. The Privacy Statement is subject to change by RhyteIt at any time. Please check our Privacy Statement regularly.</p>
                        <h2>Identity Theft and Scams</h2>
                        <p><strong>Unfortunately, identity theft and other financial scams are prevalent. It is unlikely that RhyteIt or any of its subsidiaries or agents would solicit you for personal financial information. If you suspect that an e-mail, phone call or letter asking you for personal information, money or temporary employment may be fraudulent and RhyteIt's name is used in connection with that solicitation, or if you believe that your personal information has been compromised by RhyteIt or any of its subsidiaries or agents, please&nbsp;<a title="Contact Us" href="https://discord.gg/jtzvBYf7cd" rel="noopener">contact us</a></strong><strong>.</strong></p>
                    </div>   
                </div>
            </div>
        </section>
	</main>
    </div>
    </div>
);
  }

export default PrivacyPolicy