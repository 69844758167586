import * as React from 'react';
import styles from './ControlPanel.module.css';  

import {
  SliderField,
  StepperField,
  Flex

} from '@aws-amplify/ui-react';

function formatTime(time) {
  const date = new Date(time);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}

function ControlPanel(props) {
  const {startTime, endTime, onChangeTime, allDays, onChangeAllDays, selectedTime} = props;
  const day = 24 * 60 * 60 * 1000;
  const days = Math.round((endTime - startTime) / day);

  //console.log("ControlPanel.days: ",days, " - selectedTime: ",selectedTime, " - startTime: ",startTime)


  var vSelectedTime_date = new Date(selectedTime);
  var vSelectedTime_epoch = vSelectedTime_date.getTime();
  var vStartTime_date = new Date(startTime);
  var vStartTime_epoch = vStartTime_date.getTime();
  const TimeDiff = (vSelectedTime_epoch - vStartTime_epoch);

  const selectedDay = Math.round((TimeDiff) / day);
  //console.log("ControlPanel.selectedDay: ",selectedDay)
  const [index, setIndex] = React.useState(selectedDay);

  const onSelectDay = evt => {
    const daysToAdd = evt.target.value;
    // add selected days to start time to calculate new time

    var vStartTime_date = new Date(startTime);
    var vStartTime_epoch = vStartTime_date.getTime();    
    const newTime = vStartTime_epoch + daysToAdd * day;
    onChangeTime(newTime);
  };

  const onSelectDay2 = evt => {
    
    console.log("ControlPanel.onSelectDay2.evt:  ",evt ) 
    const daysToAdd = evt;
    // add selected days to start time to calculate new time

    var vStartTime_date = new Date(startTime);
    var vStartTime_epoch = vStartTime_date.getTime();    
    const newTime = vStartTime_epoch + daysToAdd * day;
    onChangeTime(newTime);
  };


  return (
    <div className={styles.controlPanel}>
      <div className="input">
      <Flex
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          alignContent="flex-start"
          wrap="nowrap"
          gap="1rem"
          className={styles.Flex}
        >        
        <label>All Days</label>
        <input
          type="checkbox"
          name="allday"
          checked={allDays}
          onChange={evt => onChangeAllDays(evt.target.checked)}
        />   
        {allDays === false && ( 
          <label>{formatTime(selectedTime)}</label>                                                                            
        )}       
         
        </Flex>
      </div>
      {allDays === false && ( 
      <div className={`input ${allDays ? 'disabled' : ''}`}>
        <SliderField
            className={styles.SliderField}
            min={1}
            max={days}
            value={selectedDay}
            labelHidden={true}
            isValueHidden={true}
            step={1}
            onChange={onSelectDay2}
          />  
          <StepperField
            className={styles.StepperField}
            max={days}
            min={1}
            value={selectedDay}
            step={1}
            onStepChange={onSelectDay2}
          />                
      </div>                                                                               
      )}            
      <div className={styles.horizontalLine}/>
    </div>
  );
}

export default React.memo(ControlPanel);