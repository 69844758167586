import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {ErrorBoundary} from 'react-error-boundary'
import HeaderMenu from "../HeaderMenu/LandingPageNavBar";


import  {createCustomerComments}  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import {motion as m} from "framer-motion";
import { useLocation, useSearchParams } from "react-router-dom";
import axios from 'axios';

import {
  Button ,
  TextAreaField,
  Rating,
  Text,
  TextField,
  Flex

} from '@aws-amplify/ui-react';
import styles from './LeaveComment.module.css';
import {get_uuidv4} from '../../BusinessLogic/uuidv4';


function ErrorFallback({error, resetErrorBoundary}) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}



const LeaveComment = (props) => {      


        LeaveComment.propTypes = {
          Page: PropTypes.string
        };



        const client = generateClient();
        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr) 
        const [FormSubmitted, setFormSubmitted] = useState(false)   
        const [queryParameters] = useSearchParams()
  
        const [UserEmail, setUserEmail] = useState("Enter your email address")      
        const [CustomerRating, setCustomerRating] = useState(5)       
        const [Comment, setComment] = useState("")             
        const [IpAddress, setIpAddress] = useState("")  
        
        
        const location = useLocation();
        //const { Page } = location.state;
        const [CommentType, setCommentType] = useState(queryParameters.get("Page"))  

        const [form, setForm] = React.useState({
          id: '',
          UserEmail: '',
          IpAddress: '',
          Rating: 1,
          CommentType: CommentType,
          Comment: '',
          CreationName: '',
          EmailTemplate: CommentType,
          UpdateName: '',
          CreationDateTime: '',
          UpdateDateTime: ''
        });         

        useEffect(() => {

          (async function() {
            try {  
                GetIpAddress()


                } catch (err) {
                  console.error('RhyteItProducts.useEffect[].ERROR: ', err, err.stack); 
              }
            }
          )();   
      

        },[]) 



        async function GetIpAddress(){

  
          const res = await axios.get('https://geolocation-db.com/json/')

          setIpAddress(res.data.IPv4)

        }


        function Email_handleChange(event, data) {
          try {          
    
    
              form.UserEmail = event.target.value
             // //console.log("LeaveComment.Subscribed_handleChange.RhyteItProduct[elementPos].Subscribed: ",RhyteItProduct[elementPos].Subscribed)
    
              setUserEmail(form.UserEmail)      
     
    
            } catch (err) { 
              console.error("LeaveComment.Price_handleChange.Error:  ".err, err.stack); 
          }        
        }    
        
        function Comment_handleChange(event, data) {
          try {          
    
    
              form.Comment = event.target.value
             // //console.log("LeaveComment.Subscribed_handleChange.RhyteItProduct[elementPos].Subscribed: ",RhyteItProduct[elementPos].Subscribed)
    
              setComment(form.Comment)   
    
     
    
            } catch (err) { 
              console.error("LeaveComment.Price_handleChange.Error:  ".err, err.stack); 
          }        
        }    
        

        function Rating_handleChange(event, data) {
          try {          
    
             //console.log("LeaveComment.Rating_handleChange.event.target.value: ",event);
             //console.log("LeaveComment.Rating_handleChange.data: ",data)
    
              form.Rating = form.Rating+data
             // //console.log("LeaveComment.Subscribed_handleChange.RhyteItProduct[elementPos].Subscribed: ",RhyteItProduct[elementPos].Subscribed)
    
              setCustomerRating(form.Rating)   
    
     
    
            } catch (err) { 
              console.error("LeaveComment.Rating_handleChange.Error:  ".err, err.stack); 
          }        
        }           

        async function SaveComment(event, data) {
          try {    
            
              
              setErrorMessage("")
              let createCustomerCommentsResponse = ""

              var vUpdateDateTime = new Date().toISOString()
        
              var DynamoUUID =  get_uuidv4()
    
              let CustomerCommentItem = {
                    "id": DynamoUUID,
                    "UserEmail": UserEmail,
                    "IpAddress": IpAddress,
                    "CommentType": CommentType,
                    "Comment": Comment,
                    "Rating": CustomerRating,
                    "EmailTemplate": CommentType,
                    "Status": "NEW",
                    "CreationName": UserEmail,
                    "UpdateName": UserEmail,
                    "CreationDateTime": vUpdateDateTime,
                    "UpdateDateTime": vUpdateDateTime
              }

              const variables = {
                input: CustomerCommentItem
              };



                createCustomerCommentsResponse  = await client.graphql({
                    query: createCustomerComments,
                    variables: variables
                  });              

              //console.log("LeaveComment.SaveComment.CustomerCommentItem: ",CustomerCommentItem);       
              setFormSubmitted(true)
              setForm(createCustomerCommentsResponse.data.createCustomerComments)
              setErrorMessage(ErrorMessageStr)

              ErrorMessageStr = "Thank you for your comment! We really appreciate it. Even if it is negative, it helps us grow and do better."
              setErrorMessage("")
         
    
            } catch (err) { 
              console.error(err)
              ErrorMessageStr = ("LeaveComment.SaveRhyteItProduct.Error: ", err, err.stack)
              console.error(ErrorMessageStr)
              setErrorMessage(ErrorMessageStr)
          }        
        }     


    return ( 
    <m.div
      key="RhyteItLeaveComment"
      initial={{y: "100%"}}
      animate={{y: "0%"}}
      transition={{duration:0.75, ease: "easeOut"}}
      exit={{ opacity: 1 }}
     >
          <div className={styles.SystemAdminBackground}>
            <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                >   
                    <HeaderMenu  className={styles.RhyteItHeaderMenu}  pageSource="RhyteItLeaveComment"/>
                    <div  className={styles.GridContainer}>              
                      <div className={styles.Container}>
                        <div className={styles.Headings} >
                            <h1 className={styles.h1}>Leave a comment</h1>  
                            <div className={styles.headerline} />
                            <h2 className={styles.h2}>Let us know what you think about:</h2>
                            <h2 className={styles.h2Product}>{queryParameters.get("Page")}</h2>   
                        </div> 
                        <div className={styles.FormContainer}>
                        <form onSubmit={SaveComment}>
                          <Flex
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="stretch"
                                    alignContent="flex-start"
                                    wrap="wrap"
                                    gap="1rem"
                                    className={styles.FlexRowEmail}
                                    >                           
                              <div className={styles.formdiv}>
                                <label className={styles.label} htmlFor="email">Email</label>
                              </div>
                              <div className={styles.formdiv}>
                              {FormSubmitted===false && (
                                <TextField      
                                  className={styles.TextField}   
                                  id="email"
                                  type="text"
                                  value={form.UserEmail}
                                  onChange={(e) => Email_handleChange(e, form) }
                                />)}
                              {FormSubmitted===true && (
                                <Text 
                                  className={styles.Text}  
                                >{form.UserEmail}</Text>)}                              
                              </div>
                              <div className={styles.formdiv}>
                                <label className={styles.label} htmlFor="Comment">Comment</label>
                                {FormSubmitted===false && (
                                  <TextAreaField                              
                                    className={styles.TextAreaField}   
                                    id="Comment"
                                    value={form.Comment}
                                    onChange={(e) => Comment_handleChange(e, form) }
                                  />
                                  )}
                                {FormSubmitted===true && (
                                <Text 
                                  className={styles.Text}  
                                >{form.Comment}</Text>)}    
                              </div>
                              <div className={styles.formdiv}>
                                <label className={styles.label} htmlFor="Rating">Rating: I would recommend RhyteIt to my friends</label>
                              </div>
                              <div  className={styles.formdiv}>
                              {FormSubmitted===false && (
                              <Button className={styles.RatingButton} 
                                    onClick={ (e) => Rating_handleChange(e, -1) }
                                    isDisabled={FormSubmitted}
                                    >NEVER</Button>
                                    )}
                                <Rating
                                  className={styles.Rating}   
                                  maxValue={5}
                                  value={form.Rating}
                                />
                                {FormSubmitted===false && (
                                <Button className={styles.RatingButton}
                                    isDisabled={FormSubmitted}
                                    onClick={ (e) => Rating_handleChange(e, 1) }>ABSOLUTELY</Button>
                                    )}
                              </div>
                              <div className={styles.formdiv}>
                                <Button className={styles.RedditTitleButtons} 
                                        isDisabled={FormSubmitted}
                                        onClick={ (e) => SaveComment(e, form)}>Submit</Button>
                              </div>
                            </Flex>
                          </form>       
                        </div>
                        <div ><Text className={styles.MessageBox}>{ErrorMessage}</Text></div>                            
                      </div>     
                    </div> 
            </ErrorBoundary>
          </div>   
    </m.div>                      

    )

  }
 

export default LeaveComment;