import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { motion as m } from "framer-motion";
import styles from "./TurboFanBoostButton.module.css";
import useWindow from "../../BusinessLogic/Hooks/useWindow";

import {
  useInView,
  useScroll,
  useTransform,
  motion,
  AnimatePresence,
  useMotionValue,
  useMotionValueEvent,
} from "framer-motion";
import { Link, Flex, Text, Image } from "@aws-amplify/ui-react";
import useMousePosition from "../../BusinessLogic/Hooks/useMousePosition";

const TurboFanBoostButton = (props) => {
  TurboFanBoostButton.propTypes = {
    backgroundcolor: PropTypes.string,
  };

  const { dimension } = useWindow();
  const canvas = document.getElementById("canvas");
  const vcanvasBackGround = document.getElementById("canvasBackGround");

  const [vDimensionSet, setvDimensionSet] = useState(false);
  const [vButtonClicked, setvButtonClicked] = useState(false);
  const [vAnimationFrames, setvAnimationFrames] = useState([]);
  const [vAnimationFramesParticles, setvAnimationFramesParticles] = useState([]);
  const [vMouseEnter, setvMouseEnter] = useState(false);


  useEffect(() => {
    setTimeout(() => {
      ClearAnimations()
    }, 5000);
  }, []);

  useEffect(() => {
    if (vcanvasBackGround !== null && vDimensionSet === false) {
      //console.log(
      //  "TurboFanBoostButton.useEffect[dimension].dimension: ",
      //  dimension
      //);
      setvDimensionSet(true);
      initLaserLine();
      animateLaserLine();
    }

   /** 
    * console.log(
      "TurboFanBoostButton.useEffect[dimension].vAnimationFrames.length: ",
      vAnimationFrames.length
    );
    console.log(
    "TurboFanBoostButton.useEffect[dimension].vAnimationFramesParticles.length: ",
    vAnimationFramesParticles.length
  );
   */
  }, [dimension]);

  useEffect(() => {
    //console.log("TurboFanBoostButton.useEffect[vDimensionSet].vDimensionSet: ", vDimensionSet);
  }, [vDimensionSet]);

  useEffect(() => {
    //console.log("TurboFanBoostButton.useEffect[vDimensionSet].vDimensionSet: ", vDimensionSet);

   /** 
    console.log(
      "TurboFanBoostButton.useEffect[vButtonClicked].vAnimationFrames.length: ",
      vAnimationFrames.length
    );
    */
    if (vButtonClicked) {
      ClearAnimations();
    }
  }, [vButtonClicked]);

  useEffect(() => {
    //console.log("TurboFanBoostButton.useEffect[vDimensionSet].vDimensionSet: ", vDimensionSet);

   /** 
    console.log(
      "TurboFanBoostButton.useEffect[vMouseEnter].vMouseEnter: ",
      vMouseEnter
    );
    */
    if (vMouseEnter === true) {
      init();
      animate();
    }
  }, [vMouseEnter]);



  async function ClearAnimations() {
    
   /** 
    console.log("TurboFanBoostButton.ClearAnimations.vcanvasBackGround: ", vcanvasBackGround);
    console.log(
      "TurboFanBoostButton.ClearAnimations.vAnimationFrames.length: ",
      vAnimationFrames.length
    );
    */
    if (vButtonClicked) {
      const ctx1 = vcanvasBackGround.getContext("2d");
      ctx1.clearRect(0, 0, vcanvasBackGround.width, vcanvasBackGround.height);
    }

    vAnimationFrames.forEach((AnimationFrame) => {
      cancelAnimationFrame(AnimationFrame);
    });
  }

  let LaserLineArray;

  class LaserLine {
    constructor() {
      this.vLaserLineWidth = Math.floor(Math.random() * 10) + 1;
      this.vMoveToX = Math.floor(Math.random() * canvas.width) + 1;
      this.vMoveToY = Math.floor(Math.random() * canvas.height) + 1;
      this.vLineToX = Math.floor(Math.random() * canvas.width) + 1;
      this.vLineToY = Math.floor(Math.random() * canvas.height) + 1;
      this.vParticleColor = Math.floor(Math.random() * 3) + 1;
      if (this.vParticleColor === 1) {
        this.vStrokeStyle = "#99FFFF";
      } else if (this.vParticleColor === 2) {
        this.vStrokeStyle = "#FFCC99";
      } else if (this.vParticleColor === 3) {
        this.vStrokeStyle = "#CCCCFF";
      }
    }

    update() {
      this.vMoveToX = Math.floor(Math.random() * canvas.width) + 1;
      this.vMoveToY = Math.floor(Math.random() * canvas.height) + 1;
      this.vLineToX = Math.floor(Math.random() * canvas.width) + 1;
      this.vLineToY = Math.floor(Math.random() * canvas.height) + 1;
      this.vBZEndX = Math.floor(Math.random() * canvas.width) + 1;
      this.vBZEndY = Math.floor(Math.random() * canvas.height) + 1;

      this.vLaserLineWidth = Math.floor(Math.random() * 10) + 1;
    }

    draw() {
      const ctx = vcanvasBackGround.getContext("2d");

      ctx.beginPath(); // Start a new path
      //ctx.translate(this.vMoveToX, this.vMoveToY);
      ctx.moveTo(this.vMoveToX, this.vMoveToY);
      //ctx.lineTo(this.vLineToX, this.vLineToY);
      ctx.bezierCurveTo(this.vMoveToX, this.vMoveToY, this.vLineToX, this.vLineToY, this.vBZEndX, this.vBZEndY);

      ctx.strokeStyle = this.vStrokeStyle;
      ctx.lineWidth = this.vLaserLineWidth;
      ctx.lineCap = 'round';
      ctx.stroke();
      //ctx.scale(2,2);
    }
  }

  function initLaserLine() {
    LaserLineArray = [];
    for (let i = 0; i < 1000; i++) {
      LaserLineArray.push(new LaserLine());
    }
  }

  function animateLaserLine() {
    if (vButtonClicked === false) {
      const ctx = vcanvasBackGround.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      if (LaserLineArray !== undefined) {
        for (let i = 0; i < LaserLineArray.length; i++) {
          LaserLineArray[i].update();
          LaserLineArray[i].draw();
        }
      }

      let vAnimationFrame = requestAnimationFrame(animateLaserLine);
      vAnimationFrames.push(vAnimationFrame);
      setvAnimationFrames(vAnimationFrames)
    } else {
      
    vAnimationFrames.forEach((AnimationFrame) => {

      cancelAnimationFrame(AnimationFrame);
    });
    }

  }

  let particlesArray;

  class Particle {
    constructor() {
      this.x = Math.random() * canvas.width;
      this.y = Math.random() * canvas.height;
      this.size = Math.random() * 5 + 1;
      this.speedX = Math.random() * 3 - 1.5;
      this.speedY = Math.random() * 3 - 1.5;
    }

    update() {
      this.x += this.speedX;
      this.y += this.speedY;

      if (this.size > 0.2) this.size -= 0.1;
    }

    draw() {
      const ctx = canvas.getContext("2d");

      let vParticleColor = Math.floor(Math.random() * 3) + 1;

      if (vParticleColor === 1) {
        ctx.fillStyle = "#00CCCC";
      } else if (vParticleColor === 2) {
        ctx.fillStyle = "#CC6633";
      } else if (vParticleColor === 3) {
        ctx.fillStyle = "#9999CC";
      }

      ctx.beginPath();
      ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
      ctx.closePath();
      ctx.fill();
    }
  }

  function init() {
    particlesArray = [];
    for (let i = 0; i < 1000; i++) {
      particlesArray.push(new Particle());
    }
  }

  function animate() {
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (particlesArray !== undefined) {
      for (let i = 0; i < particlesArray.length; i++) {
        particlesArray[i].update();
        particlesArray[i].draw();
        // Reset particles when they go off-screen
        if (
          particlesArray[i].x < 0 ||
          particlesArray[i].x > canvas.width ||
          particlesArray[i].y < 0 ||
          particlesArray[i].y > canvas.height
        ) {
          particlesArray[i] = new Particle();
        }
      }
    }

    let vAnimationFrame = requestAnimationFrame(animate);
    vAnimationFramesParticles.push(vAnimationFrame);
    setvAnimationFramesParticles(vAnimationFrames)    
  }

  const handleMouseEnter = (event) => {
    try {


   //   console.log("TurboFanBoostButton.handleMouseEnter.event:  ", event);
      ClearAnimations()
      setvMouseEnter(true)
     

    } catch (err) {
      console.log("TurboFanBoostButton.handleMouseEnter:  ", err);
    }
  };

  const handleMouseLeave = (event) => {
   // console.log("TurboFanBoostButton.handleMouseLeave.event: ", event);
    try {


    } catch (err) {
      console.log("TurboFanBoostButton.handleMouseLeave:  ", err);
    }
  };

  const handleMouseClick = (event) => {

    try {
      setvButtonClicked(true);
    } catch (err) {
      console.log("TurboFanBoostButton.handleMouseClick.error:  ", err);
    }
  };

  return ( 
    <div
      onClick={(e) => handleMouseClick(e)}
      onMouseEnter={(e) => handleMouseEnter(e)}
      onMouseLeave={(e) => handleMouseLeave(e)}
      className={styles.ButtonDiv}
    >
      <button className={styles.Button}>
        <div className={styles.ButtonTextDiv}>
          <h1 className={styles.ButtonTextTop}>
            Automate your 
          </h1>
          <h1 className={styles.ButtonText}>
            Social media marketing
          </h1>
        </div>
      </button>
      <canvas
        id="canvasBackGround"
        className={styles.canvasBackGround}
      ></canvas>
      <canvas id="canvas" className={styles.canvas}></canvas>
    </div>   
  );
};

export default TurboFanBoostButton;
