
import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead
  } from '@aws-amplify/ui-react'; 
import ApplicantPageViewRow from './ApplicantPageViewRow';
import styles from './ApplicantPageViewTable.module.css';


const ApplicantPageViewTable = (props) => {       

  ApplicantPageViewTable.propTypes = {
      PageViews: PropTypes.array,
      RhyteItUser: PropTypes.array
      };

    let debugConsoleOutPut = true

  

    const [RhyteItApplicantPageViewTable, setRhyteItApplicantPageViewTable] = useState(props.PageViews)   
    const [ShowTable, setShowTable] = useState(false);

    useEffect(() => {

      //console.log("ApplicantPageViewTable.useEffect[].props.PageViews:  ",props.PageViews) 
      setRhyteItApplicantPageViewTable(props.PageViews)
      //console.log("ApplicantPageViewTable.useEffect[].RhyteItApplicantPageViewTable ",RhyteItApplicantPageViewTable) 
      //console.log("vApplicantPageViewRows.useEffect[].props.RhyteItUser:  ",props.RhyteItUser) 
      //console.log("vApplicantPageViewRows.useEffect[].Time:  ", ApplicantPageViewRow.PostDateTime.substr(ApplicantPageViewRow.PostDateTime.indexOf("T")+1, 5))
      //onsole.log("vApplicantPageViewRows.useEffect[].indexOf:  ", ApplicantPageViewRow.PostDateTime.indexOf(":00"))
      if (Object.keys(RhyteItApplicantPageViewTable).length > 0) {
        setShowTable(true)
      }



    },[])  



  return (  
      <Table className={styles.Table}   highlightOnHover={true}>   
          <TableHead>          
            <TableRow className={styles.TableHeaderRow}>
              <TableCell className={styles.TableHeaderRowCells} >Ipaddress</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Page</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >City</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >State</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Country</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Latitude</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Longitude</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Date</TableCell> 
            </TableRow>                                      
          </TableHead>       
          
              {
                ShowTable && RhyteItApplicantPageViewTable.length > 0 &&  RhyteItApplicantPageViewTable && RhyteItApplicantPageViewTable.map((RhyteItApplicantPageViewRow) => (                      
                  (<ApplicantPageViewRow   key={RhyteItApplicantPageViewRow.id}  RhyteItApplicantPageViewRow={RhyteItApplicantPageViewRow}/>)        
                  ))
              }                    
        </Table>
    
  )
}

export default ApplicantPageViewTable;


