
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  Label,
  Badge,
  Text,
  Flex,
  SelectField,
  Button, Fieldset 
  } from '@aws-amplify/ui-react'; 
import styles from './SMMADOrganizeTable.module.css';
import  { listSMMADCampaigns, listSMMADContents, listSMMADTexts }  from '../../../graphql/queries'
import  {  updateSMMADCampaigns, updateSMMADContent, updateSMMADText}  from '../../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';
import SMMADOrganizeNewField from "./SMMADOrganizeNewField";
import { UserContext} from '../../../BusinessLogic/Hooks/UserContext'; 

import ContentTable from "./ContentTable";

const SMMADOrganizeTable = (props) => {       

  SMMADOrganizeTable.propTypes = {
      AccountSelected: PropTypes.string
      };

    let debugConsoleOutPut = true

  

    var ErrorMessageStr = ""   
    const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)

    const client = generateClient();
    const [vSMMADOrganize, setvSMMADOrganize] = useState([]) 
    const [vAccountSelected, setvAccountSelected] = useState(props.AccountSelected)   
    const [vCampaignSelectedFrom, setvCampaignSelectedFrom] = useState()   
    const [vCampaignSelectedTo, setvCampaignSelectedTo] = useState()   
    const [vCampaignToShow, setvCampaignToShow] = useState()
  
    const [CampaignActivateButtonText, setCampaignActivateButtonText] = useState("Start Posting")   
    const [vContentTextActive, setvContentTextActive] = useState(true)   
    const [SMHashTag, setSMHashTag] = useState([])  

    const [vCampaign, setvCampaign] = useState("")   
    const [vAddNewCampaignVisible, setvAddNewCampaignVisible] = useState(false)  
    const [vCampaignHelpBadgeHovered, setvCampaignHelpBadgeHovered] = useState(false)  
       
    
    const [SMContent, setSMContent] = useState([]) 
    const [vCampaignSelected, setvCampaignSelected] = useState(props.CampaignSelected)    

    const [nextToken, setnextToken] = useState(undefined) 
    const [IsLoading, setIsLoading] = useState(false)
    const {Users, setUsers} = useContext(UserContext); 

    const [SMText, setSMText] = useState([]) 
    const [nextTextToken, setnextTextToken] = useState(undefined)     
    const [IsTextLoading, setIsTextLoading] = useState(false)

    useEffect(() => {

      
      //console.log("SMMADOrganizeTable.useEffect[].props.AccountSelected:  ",props.AccountSelected);
      
      (async function() {
        try {  
       
          GetSMMADCampaigns()

            } catch (err) {
              console.error('SMMADOrganize.useEffect[].ERROR: ', err, err.stack); 
          }
        }
      )();   
  

    },[])  

    useEffect(() => {

      
   
      if (vSMMADOrganize !== undefined) {

        if (vSMMADOrganize.length > 0) {
        //  //console.log("SMMADOrganizeTable.useEffect[vSMMADOrganize].vSMMADOrganize:  ",vSMMADOrganize)
          //console.log("SMMADOrganizeTable.useEffect[vSMMADOrganize].vAccountSelected:  ",vAccountSelected)

          if (vAccountSelected !== "edwardhawke@rhyteit.com") {         

            setvCampaignSelectedFrom("Default")

          }

        }
        
      }

    },[vSMMADOrganize]) 


    useEffect(() => {
            
      //console.log("SMMADOrganizeTable.useEffect[SMContent].SMContent:  ",SMContent)

    },[SMContent])    


    useEffect(() => {

     //console.log("SMMADOrganizeTable.useEffect[vCampaignSelectedFrom].vCampaignSelectedFrom:  ",vCampaignSelectedFrom);

      if (vCampaignSelectedFrom !== undefined && vSMMADOrganize.length > 0) {    

        setvCampaignToShow(vSMMADOrganize.filter((campaign) => campaign["Campaign"] === vCampaignSelectedFrom)); 
        GetContent()
        GetText()

      }


     },[vCampaignSelectedFrom])       
     
  
    useEffect(() => {
      
      //console.log("SMMADOrganizeTable.useEffect[vCampaignToShow].vCampaignToShow:  ",vCampaignToShow);
      if (vCampaignToShow !== undefined && vCampaignToShow.length > 0) {


        let vCampaignStatus = "Move"
        setCampaignActivateButtonText(vCampaignStatus)


        let vCTA = false

        if (vCampaignToShow[0].hasOwnProperty("ContentTextActive")) {
          if (vCampaignToShow[0].ContentTextActive === null) {      
            vCTA = false
          } else {
            vCTA = vCampaignToShow[0].ContentTextActive
          }

        } else {            
          vCTA = false
        }      
        
        //console.log("SMMADOrganizeTable.useEffect[vCampaignToShow].vCTA:  ",vCTA);
        setvContentTextActive(vCTA)
      }


     },[vCampaignToShow])  


     useEffect(() => {

      if (vAddNewCampaignVisible === false) {
        GetSMMADCampaigns()
      }

    },[vAddNewCampaignVisible]) 
     


    useEffect(() => {

      const fetch1 = async () => {


        try {

              if(nextToken !== null) {
                setIsLoading(true)

                  var datetime = new Date();                                                
                  let limit = 10000000
                  //console.log("TwitterStats.useEffect[NextToken].nextToken: ", nextToken);   
                  
                  let  variables = {
                    nextToken,
                    filter: {
                      Account: {
                              eq: vAccountSelected
                          },
                        Campaign: {
                                eq: vCampaignSelectedFrom
                            }
                    }
                  };   
                  
                  const ContentData2  = await client.graphql({
                    query: listSMMADContents,
                    variables: variables
                  });
    
        
        
                //console.log("Content.GetContent.ContentData2.data.listSMMADContents.nextToken:  ",ContentData2.data.listSMMADContents.nextToken)

                let ContentData = SMContent                   
                      
      
                ContentData2.data.listSMMADContents.items.forEach(item => 
                  {   
                
                    if ([...ContentData].filter(Content => item.id === Content.id)["length"] === 0) {  
                      ContentData.push(item) 
                    }

                  })


                    //console.log("TwitterStats.useEffect[NextToken].elseif.TwitterStatsRecordsData2.data.listEmailAccountStats.nextToken: ", TwitterStatsRecordsData2.data.listEmailAccountStats.nextToken);   
                    //console.log("TwitterStats.useEffect[NextToken].elseif.TwitterStatsRecordsData2.length: ", TwitterStatsRecordsData2.data.listEmailAccountStats.items.length);  
                    //console.log("TwitterStats.useEffect[NextToken].elseif.TwitterStatsRecordsData2.items: ", TwitterStatsRecordsData2.data.listEmailAccountStats.items);  

                    setSMContent(ContentData)  
                    setnextToken(ContentData2.data.listSMMADContents.nextToken)  

                                        

                  
              } else if (nextToken === null){          
                
                
                setIsLoading(false)

              }

        } catch (err) {
          //console.log(err)
        } finally {
          setIsLoading(false)
        }
      }
    
      fetch1()

    }, [nextToken])   

    useEffect(() => {

      const fetch1 = async () => {


        try {

              if(nextTextToken !== null) {
                setIsTextLoading(true)

                  var datetime = new Date();                                                
                  let limit = 10000000
                  //console.log("TwitterStats.useEffect[NextToken].nextContentToken: ", nextContentToken);   
                  
                  let  variables = {
                    nextTextToken,
                    filter: {
                      Account: {
                        eq: vAccountSelected
                    },
                  Campaign: {
                          eq: vCampaignSelectedFrom
                      }
                    }
                  };   
                  
                  const TextData2  = await client.graphql({
                    query: listSMMADTexts,
                    variables: variables
                  });
    
        
        
                //console.log("Content.GetContent.ContentData2.data.listSMMADTexts.nextTextToken:  ",TextData2.data.listSMMADTexts.nextToken)

                let TextData = SMText                  
                      
      
                TextData2.data.listSMMADTexts.items.forEach(item => 
                  {   
                
                    if ([...TextData].filter(Content => item.id === Content.id)["length"] === 0) {  
                      TextData.push(item) 
                    }

                  })


                    //console.log("TwitterStats.useEffect[NextToken].elseif.TwitterStatsRecordsData2.data.listEmailAccountStats.nextContentToken: ", TwitterStatsRecordsData2.data.listEmailAccountStats.nextContentToken);   
                    //console.log("TwitterStats.useEffect[NextToken].elseif.TwitterStatsRecordsData2.length: ", TwitterStatsRecordsData2.data.listEmailAccountStats.items.length);  
                    //console.log("TwitterStats.useEffect[NextToken].elseif.TwitterStatsRecordsData2.items: ", TwitterStatsRecordsData2.data.listEmailAccountStats.items);  

                    setSMText(TextData)  
                    setnextTextToken(TextData2.data.listSMMADTexts.nextToken)  

                                        

                  
              } else if (nextTextToken === null){          
                
                
                setIsTextLoading(false)

              }

        } catch (err) {
          //console.log(err)
        } finally {
          setIsTextLoading(false)
        }
      }
    
      fetch1()

    }, [nextTextToken])        

    async function GetSMMADCampaigns() {
      try {
                setErrorMessage("")
                setvSMMADOrganize([])
                
                //console.log("SMMADOrganizeTable.GetSMMADCampaigns.vAccountSelected:  ",vAccountSelected)
                let variables 

                variables = {
                  filter: {
                    Account: {
                            eq: vAccountSelected
                        }
                  }
                };

                const SMMADOrganizeData2  = await client.graphql({
                  query: listSMMADCampaigns,
                  variables: variables
                });
  
      
                if (SMMADOrganizeData2.data.listSMMADCampaigns.items.length > 0) {

                    const SMMADOrganize1 = SMMADOrganizeData2.data.listSMMADCampaigns.items
                    
                    
                    //console.log("SMMADOrganizeTable.GetSMMADCampaigns.SMMADOrganizeData2.data.listSMMADCampaigns.items:  ",SMMADOrganizeData2.data.listSMMADCampaigns.items)


                    setvSMMADOrganize(SMMADOrganize1)  
       
                }


      } catch (err) { 
                      console.error('SMMADOrganize.GetSMMADOrganize.ERROR: ', err, err.stack); 
                  }
    }   

    async function AddCampaignRow() {
      try {              
          
        setvCampaign(null)
        let vNewValue = (vAddNewCampaignVisible === false ? true : false)
        setvAddNewCampaignVisible(vNewValue)


        } catch (err) { 
          console.error("SMMADOrganizeTable.AddCampaignRow.Error:  ".err, err.stack); 
      }        
    }  

    const handleMouseOver = () => {

      let vCampaignHelpBadgeHoveredtmp = (vCampaignHelpBadgeHovered === true ? false : true)

      setvCampaignHelpBadgeHovered(vCampaignHelpBadgeHoveredtmp);
    };
      
    async function MoveAllButton() {
      try {      

        
        //console.log("SMMADOrganizeTable.MoveAllButton.SMContent.length:  ",SMContent.length)

        for (var SMContentItem in SMContent) {
          //console.log("SMMADOrganizeTable.MoveAllButton.SMContent[SMContentItem]:  ",SMContent[SMContentItem])
          MigrateCampaigns(SMContent[SMContentItem])
        }

        for (var SMTextItem in SMText) {
          //console.log("SMMADOrganizeTable.MoveAllButton.SMContent[SMText]:  ",SMContent[SMTextItem])
          MigrateCampaignText(SMText[SMTextItem])
        }

        GetContent()

        


        } catch (err) { 
          console.error("SMMADOrganizeTable.MoveAllButton.Error:  ".err, err.stack); 
      }        
    }  

    async function GetContent() {
      try {
                setErrorMessage("")
                setSMContent([])
                
             //console.log("Content.GetContent.vAccountSelected:  ",vAccountSelected)
             //console.log("Content.GetContent.vCampaignSelected:  ",vCampaignSelected)
               

               let  variables = {
                  filter: {
                    Account: {
                            eq: vAccountSelected
                        },
                      Campaign: {
                              eq: vCampaignSelectedFrom
                          }
                  }
                };

                const ContentData2  = await client.graphql({
                  query: listSMMADContents,
                  variables: variables
                });
  
      
      
                //console.log("Content.GetContent.ContentData2:  ",ContentData2)

                if (ContentData2.data.listSMMADContents.items.length > 0) {
                    const Content1 = ContentData2.data.listSMMADContents.items 

                    setSMContent(Content1)  
                    
                    setnextToken(ContentData2.data.listSMMADContents.nextToken)  
       
              }

      } catch (err) { 
                      console.error('Content.GetContent.ERROR: ', err, err.stack); 
                  }
    }    
    
    async function GetText() {
      try {
                setErrorMessage("")
                setSMText([])
                
             //console.log("Content.GetContent.vAccountSelected:  ",vAccountSelected)
             //console.log("Content.GetContent.vCampaignSelected:  ",vCampaignSelected)
               

               let  variables = {
                  filter: {
                    Account: {
                      eq: vAccountSelected
                    },
                  Campaign: {
                          eq: vCampaignSelectedFrom
                          }
                  }
                };

                const TextData2  = await client.graphql({
                  query: listSMMADTexts,
                  variables: variables
                });
  
      
      
                //console.log("Content.GetContent.TextData2:  ",TextData2)

                if (TextData2.data.listSMMADTexts.items.length > 0) {
                    const Text1 = TextData2.data.listSMMADTexts.items 

                    setSMText(Text1)  
                    
                    setnextTextToken(TextData2.data.listSMMADTexts.nextToken)  
       
              }

      } catch (err) { 
                      console.error('Content.GetContent.ERROR: ', err, err.stack); 
                  }
    }       
   
    async function MigrateCampaigns(pSMContentItem) {
      try {           
        
          setErrorMessage("")

          var vUpdateDateTimeNew = new Date().toISOString()
          //console.log("SMMADOrganizeTable.MigrateCampaigns.pSMContentItem:  ",pSMContentItem)
          //console.log("SMMADOrganizeTable.MigrateCampaigns.vUpdateDateTimeNew:  ",vUpdateDateTimeNew)
          //console.log("SMMADOrganizeTable.MigrateCampaigns.Users:  ",Users)
      
          pSMContentItem.UpdateDateTime = vUpdateDateTimeNew       
          pSMContentItem.UpdateName = Users  

          let ContentItem = {
              "id": pSMContentItem.id,
              "Account": pSMContentItem.Account,
              "AccountType": pSMContentItem.AccountType,
              "Campaign": vCampaignSelectedTo,
              "Category":  pSMContentItem.Category,
              "ContentType": pSMContentItem.ContentType,
              "URL": pSMContentItem.URL,
              "S3Bucket": pSMContentItem.S3Bucket,
              "S3Key": pSMContentItem.S3Key,
              "PostedCount":  pSMContentItem.PostedCount,
              "Weight":  pSMContentItem.Weight,
              "Active":  pSMContentItem.Active,
              "SMMADContentStatus":  "Campaign Changed",
              "CreationName": pSMContentItem.CreationName,
              "CreationDateTime": pSMContentItem.CreationDateTime,
              "UpdateName": pSMContentItem.UpdateName,
              "UpdateDateTime": pSMContentItem.UpdateDateTime,
          }


          // //console.log("SMMADOrganizeTable.MigrateCampaigns.ContentItem:  ",ContentItem)                      

            const updateSMMADContentResponse = await client.graphql({
              query: updateSMMADContent,
              variables: {
                input: ContentItem
              }
            });  

            //setvSMMADOrganizeTable(ContentItem)
           // setvMigrate("migrated")

        } catch (err) { 
          ErrorMessageStr = ("SMMADOrganizeTable.MigrateCampaigns.Error: ", err, err.stack)
          console.error(ErrorMessageStr)
          setErrorMessage(ErrorMessageStr)
      }        
    }          
          
    async function MigrateCampaignText(pSMTextItem) {
      try {           
        
        setErrorMessage("")

        var vUpdateDateTimeNew = new Date().toISOString()
    
        pSMTextItem.UpdateDateTime = vUpdateDateTimeNew       
        pSMTextItem.UpdateName = Users  

        let TextItem = {
            "id": pSMTextItem.id,
            "Account": pSMTextItem.Account,
            "AccountType": pSMTextItem.AccountType,
            "Campaign": vCampaignSelectedTo,
            "Category": pSMTextItem.AccountType,
            "Text": pSMTextItem.AccountType,
            "PostedCount":  pSMTextItem.AccountType,
            "Active":  pSMTextItem.AccountType,
            "SMMADTextStatus":  "Campaign Changed",
            "CreationName": pSMTextItem.AccountType,
            "CreationDateTime": pSMTextItem.AccountType,
            "UpdateName": pSMTextItem.AccountType,
            "UpdateDateTime": pSMTextItem.AccountType,
        }


      

            TextItem.SMMADTextStatus = "Updated"
            const updateSMMADTextResponse = await client.graphql({
              query: updateSMMADText,
              variables: {
                input: TextItem
              }
            });  



        } catch (err) { 
          ErrorMessageStr = ("SMMADOrganizeTable.MigrateCampaignText.Error: ", err, err.stack)
          console.error(ErrorMessageStr)
          setErrorMessage(ErrorMessageStr)
      }        
    }       

  return (  
  <Flex
    direction="column"
    justifyContent="flex-start"
    alignItems="stretch"
    wrap="nowrap"
    gap="1rem"
    className={styles.GridContainer}
    key={Date.now()+1}
  >   

      <Badge variation="info" 
            size="small"
            className={vCampaignHelpBadgeHovered === false ? styles.BadgeHelp : styles.BadgeHelphover}            
            onClick={handleMouseOver}>
            <Flex
              direction="row"
              justify="center"
              alignItems="center"
              align="flex-start"
              wrap="nowrap"
              gap="1rem"

            >                
            {
              vCampaignHelpBadgeHovered === false &&  (     
                <Text className={styles.BadgeHelpQuestionMark}>?</Text> )
            }                
            {
              vCampaignHelpBadgeHovered === true &&  (              
              <Flex
                direction="row"
                justify="center"
                alignItems="center"
                align="flex-start"
                wrap="nowrap"
                gap="1rem"
  
              >   
                  <Text className={styles.BadgeHelpQuestionMark}>?</Text> 
                <Flex
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    wrap="nowrap"
                    gap="0.25rem" 
                    className={styles.BadgeHelpFlexColumnText}
                  > 
                  <Text 
                    className={vCampaignHelpBadgeHovered === true ? styles.BadgeHelpText.transition : styles.BadgeHelpText}>You can group your posts into campaigns.
                  </Text>          
                  <Text 
                    className={vCampaignHelpBadgeHovered === true ? styles.BadgeHelpText.transition : styles.BadgeHelpText}>If you do not want a campaign to run for awhile, you can deactivate it. 
                  </Text>   
                  <Text 
                    className={vCampaignHelpBadgeHovered === true ? styles.BadgeHelpText.transition : styles.BadgeHelpText}>You can also delete the campaign and all its posts.
                  </Text>                                                                                                                                                                                                                                           
                </Flex>                                                                                                                                                                                                                                          
            </Flex>  
              )
            }                
                                                                                                                                                                                                                                  
            </Flex>    
      </Badge>      
      {
        vSMMADOrganize.length > 0 && vAddNewCampaignVisible === false &&  ( 
            <Flex
              direction="row"
              justify="flex-start"
              alignItems="center"
              align="flex-start"
              wrap="nowrap"
              gap="1rem"
            >    
                <SelectField
                    className={styles.SelectField}
                    value={vCampaignSelectedFrom}
                    placeholder="Select From Campaign"
                    onChange={(e) => setvCampaignSelectedFrom(e.target.value)}>
                      {
                      vSMMADOrganize !== undefined && vSMMADOrganize.map((SMCampaign) => (                      
                        ( <option 
                          className={styles.SelectFieldOption} key={SMCampaign.Campaign} value={SMCampaign.Campaign} label={SMCampaign.Campaign}>
                            {SMCampaign.Campaign}
                          </option>)        
                        ))
                    }   
                </SelectField>         
                <SelectField
                    className={styles.SelectField}
                    value={vCampaignSelectedTo}
                    placeholder="Select To Campaign"
                    onChange={(e) => setvCampaignSelectedTo(e.target.value)}>
                      {
                      vSMMADOrganize !== undefined && vSMMADOrganize.map((SMCampaign) => (                      
                        ( <option 
                          className={styles.SelectFieldOption} key={SMCampaign.Campaign} value={SMCampaign.Campaign} label={SMCampaign.Campaign}>
                            {SMCampaign.Campaign}
                          </option>)        
                        ))
                    }   
                </SelectField>                                                                                                                                                                                                                                  
            </Flex>    
          )   
      }    
      <Flex
        direction="row"
        justify="flex-start"
        alignItems="center"
        align="flex-start"
        wrap="nowrap"
        gap="1rem"
      >       
            <Button className={styles.Button}
                    variation="primary"         
                    onClick={ (e) => AddCampaignRow(e)}
                    >
                  {vAddNewCampaignVisible === false ? "Add Campaign" : "Select Campaign" }
            </Button> 
            {
              vCampaignSelectedFrom !== undefined  &&     (     
                <Fieldset  direction="row" 
                gap="1rem">                                   
                  <Button className={styles.Button}
                    variation="primary"         
                    onClick={(e) => MoveAllButton(e)}
                    >
                       Move All
                  </Button>
                </Fieldset> 
                )     
              }                  
      </Flex>    
      {
        vAddNewCampaignVisible === true && (  
              <SMMADOrganizeNewField 
                  className={styles.CampaignField}
                  AccountSelected={vAccountSelected}   />  
          )
      }   
      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        wrap="nowrap"
        gap="1rem"
        className={styles.FlexColumnContentText}
        key={Date.now()+1}
      >   
      {
        vCampaignSelectedFrom !== undefined  && vCampaignToShow !== undefined  &&  vContentTextActive !== null  && (
                      <Fieldset 
                      className={styles.Fieldset}
                      size="small">  
                              <legend>Content</legend>      
                              <Text className={styles.Instruction}>*Select the images you want to migrate</Text>   
                        {vCampaignSelectedFrom !== undefined && vCampaignSelectedTo  !== undefined && SMContent.length > 0 && (<ContentTable key={vCampaignToShow.id} AccountSelected={vAccountSelected} SMContent={SMContent} CampaignFrom={vCampaignSelectedFrom}   CampaignTo={vCampaignSelectedTo} ImageToTextRelationship={vContentTextActive} HashTagMap={SMHashTag}/>)  }                                                                                                                                                                                                                                                                                                                                         
                        
                        </Fieldset>  
          )  
      }       

      </Flex> 
 </Flex>      
    
  )
}

export default SMMADOrganizeTable;


