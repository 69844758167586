
import React, { useState , useEffect, useContext,useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Text,
  Button,
  Table,
  TableHead,
  Flex,
  CheckboxField
  } from '@aws-amplify/ui-react';
  import styles from './SMMADAGAccounts.module.css';  
  
import { generateClient } from 'aws-amplify/api';
import  { listSMMADAccounts }  from '../../../graphql/queries'
import  SMMADAGAccountRow  from './SMMADAGAccountRow'

import {
  GetGroupRoleAccess,
  RhyteItLogging,
  ReturnUserToLandingPage,
} from "../../../BusinessLogic/UserManagement";
  

const SMMADAGAccounts = (props) => {       
  

  SMMADAGAccounts.propTypes = {
      AccountGroup: PropTypes.object,
      AGAccounts: PropTypes.array,
      Users: PropTypes.string,
      RecordsReturned: PropTypes.number, 
      };


      const [vRecordsReturned, setvRecordsReturned] = useState(props.RecordsReturned)    
      const [ErrorMessage, setErrorMessage] = useState("") 

      const [vAccountGroup, setvAccountGroup] = useState(props.AccountGroup)
      const client = generateClient();
      const [vUser, setvUser] = useState(props.Users)
      const [vAGAccounts, setvAGAccounts] = useState(props.AGAccounts)

      useEffect(() => {
        console.log("SMMADAGAccounts.useEffect[].props.RecordsReturned:  ",props.RecordsReturned);

      },[]) 


      

  return (
      <div>
                     
                        <div className={styles.MessageBox}>{ErrorMessage} Records retrieved: {vRecordsReturned}</div>  
                        <div className={styles.ToolBar}> 
                                              
                            <Table className={styles.Table}> 
                            <TableHead>          
                              <TableRow className={styles.TableHeaderRow}>
                                <TableCell className={styles.TableHeaderRowCell} >Details</TableCell> 
                                <TableCell className={styles.TableHeaderRowCell} >Parent</TableCell> 
                                <TableCell className={styles.TableHeaderRowCell} >Account</TableCell> 
                                <TableCell className={styles.TableHeaderRowCell} >X Account</TableCell> 
                                <TableCell className={styles.TableHeaderRowCell} >X Status</TableCell> 
                                <TableCell className={styles.TableHeaderRowCell} >TFB Status</TableCell> 
                                <TableCell className={styles.TableHeaderRowCell} >Posting Freq (hrs)</TableCell> 
                                <TableCell className={styles.TableHeaderRowCell}>Active</TableCell> 
                                <TableCell className={styles.TableHeaderRowCell}>Actions</TableCell> 
                              </TableRow>                                      
                            </TableHead>   
                              {
                                 vAGAccounts && vAGAccounts.map((vAGAccount) => (         
                                      <SMMADAGAccountRow    key={vAGAccount.id} AGAccount={vAGAccount} />
                                  ))
                              }           
                          </Table>   
                         </div>
                        </div>   
   

               

                                  
 
      
  )
}

export default SMMADAGAccounts;

