
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Text,
  TableBody,
  TextField,  
  CheckboxField ,
  Flex,
  Button,
  Image,
  TextAreaField
  } from '@aws-amplify/ui-react';

  import  {  }  from '../../../graphql/queries'
  import  { deleteBatchJobs, updateBatchJobs, createBatchJobs}  from '../../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";

  import styles from './SMMADBatchJobRow.module.css';  
  import { UserContext} from '../../../BusinessLogic/Hooks/UserContext';  
  import {get_uuidv4} from '../../../BusinessLogic/uuidv4';

const SMMADBatchJobRow = (props) => {       

  SMMADBatchJobRow.propTypes = {
      SMMADBatchJobRow: PropTypes.object
      };

    const client = generateClient();
  
    const {Users, setUsers} = useContext(UserContext);   
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vSMMADBatchJobRow, setvSMMADBatchJobRow] = useState(props.SMMADBatchJobRow)      
    const [vDescription, setvDescription] = useState(props.SMMADBatchJobRow.Description)   
    const [vBatchJob, setvBatchJob] = useState(props.SMMADBatchJobRow.BatchJob)   
    const [vActive, setvActive] = useState(props.SMMADBatchJobRow.Active)   
    
    const [vUpdateDateTime, setvUpdateDateTime] = useState(props.SMMADBatchJobRow.UpdateDateTime)  
    const [vStatus, setvStatus] = useState(props.SMMADBatchJobRow.SMMADBatchJobStatus)    
    const [vRecordSaved, setvRecordSaved] = useState(false)      
    const [vRowDeleted, setvRowDeleted] = useState(false)     


    useEffect(() => {

       console.log("SMMADBatchJobRow.useEffect[].props.SMMADBatchJobRow.Active:  ",props.SMMADBatchJobRow.Active) 
      
      },[])  
 
    
    async function DeleteRow(event,data) {
      try {

              
              setErrorMessage("")   

              const DeleteSMMADBatchJobRow = {
                id: vSMMADBatchJobRow.id,
                _version: vSMMADBatchJobRow._version
              }
              

              const vDeleteResponse = await client.graphql({
                query: deleteBatchJobs,
                variables: {
                  input: DeleteSMMADBatchJobRow
                }
              });  

              ErrorMessageStr = vDeleteResponse
              setErrorMessage(ErrorMessageStr)   
              setvRowDeleted(true)   
 
              
      
          } catch (err) { 

          ErrorMessageStr = "SMMADBatchJobRow.DeleteRow.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }               
      
    }       
 



  return (
        
      <TableBody>         
        {
            vRowDeleted === false && (                         
          <TableRow  className={styles.TableRowBackground} key={vSMMADBatchJobRow.id}>                                          
            <TableCell className={styles.TableRowData}>  
                <Text  className={vRecordSaved === false ?  styles.TextField : styles.TextFieldUpdated}>
                                  {vSMMADBatchJobRow.BatchJobName}
                </Text>                                
            </TableCell>                          
            <TableCell className={styles.TableRowData}>  
                <Text  className={vRecordSaved === false ?  styles.TextField : styles.TextFieldUpdated}>
                                  {vSMMADBatchJobRow.JobJSON}
                </Text>                                                        
            </TableCell>                              
            <TableCell className={styles.TableRowData}>   
                <Text  className={vRecordSaved === false ?  styles.TextField : styles.TextFieldUpdated}>
                                  {vSMMADBatchJobRow.BatchJobStatus}
                </Text>  
                
            </TableCell>                            
            <TableCell className={styles.TableRowData}>   
                <Text  className={vRecordSaved === false ?  styles.TextField : styles.TextFieldUpdated}>
                                  {new Date(vUpdateDateTime).toLocaleString()}
                </Text>  
            </TableCell>  
            <TableCell as="td"  className={styles.RhyteItMenuCell}> 
                  <Button
                    loadingText=""
                    ariaLabel=""
                    className={styles.RowButtons}
                    onClick={(e) => DeleteRow(e, vSMMADBatchJobRow)}
                  >
                  D
                  </Button>                 
            </TableCell>                                                        
          </TableRow> 
      )
    }      
      {
        ErrorMessage.length > 0 &&             
          <TableRow  >              
            <TableCell 
              colSpan="7">   
              <Text className={styles.ErrorMessageRow}>  
              {ErrorMessage}
              </Text> 
            </TableCell>               
          </TableRow>  
      }                        
      </TableBody>
    
  )
}

export default SMMADBatchJobRow;

