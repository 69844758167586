
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Text,
  TableBody,
  TextField,  
  Button,
  Image
  } from '@aws-amplify/ui-react';
  import { API , graphqlOperation} from 'aws-amplify';
  import { Link } from "react-router-dom";
  import * as Mutations from '../../../graphql/mutations';  
  import styles from './ApplicantProfileRow.module.css';  
  import { UserContext,UserAuthenticatedContext,UserSubcribedContext} from '../../../BusinessLogic/Hooks/UserContext';  
  import ApplicantDetail from "./ApplicantDetail";
  import moment from 'moment';

const ApplicantProfileRow = (props) => {       

    ApplicantProfileRow.propTypes = {
        RhyteItApplicantProfileRow: PropTypes.object,
        EngagmentScores: PropTypes.array
        };
    
        const {Users, setUsers} = useContext(UserContext);   
        const [ErrorMessage, setErrorMessage] = useState("")   
        let ErrorMessageStr = ""
        let debugConsoleOutPut = true
        const [EngagmentScores, setEngagmentScores] = useState(props.EngagmentScores)  

        const [message, setMessage] = useState('');

        const [vRhyteItApplicantProfileRows, setvRhyteItApplicantProfileRows] = useState([props.RhyteItApplicantProfileRow])      

    useEffect(() => {

       //console.log("ApplicantProfileRow.useEffect[].props.RhyteItApplicantProfileRow:  ",props.RhyteItApplicantProfileRow) 
      
      },[])  




  return (
      <TableBody>  
      {
          vRhyteItApplicantProfileRows.length > 0 && vRhyteItApplicantProfileRows.map((vRhyteItApplicantProfileRow) => (  

          
          <TableRow  className={styles.TableRowBackground} key={vRhyteItApplicantProfileRow.id}>
            <TableCell className={styles.TableRowData} >  
                <Text  className={styles.TextField}>
                                  {vRhyteItApplicantProfileRow.id
                  }
                </Text>  
              </TableCell>                                        
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {vRhyteItApplicantProfileRow.Twitter}
                </Text>  
            </TableCell>                                        
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {vRhyteItApplicantProfileRow.OFSP_Photos}
                </Text>  
            </TableCell>                                         
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {vRhyteItApplicantProfileRow.OFSP_Videos}
                </Text>  
            </TableCell>                                         
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {vRhyteItApplicantProfileRow.OFSP_Likes}
                </Text>  
            </TableCell>                                         
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {vRhyteItApplicantProfileRow.OFSP_Streams}
                </Text>  
            </TableCell>                                        
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {vRhyteItApplicantProfileRow.OFSP_Fans}
                </Text>  
            </TableCell>                                          
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  ${vRhyteItApplicantProfileRow.PaidFree}
                </Text>  
            </TableCell>                                            
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {Math.round(EngagmentScores.Rank*100,2)}%
                </Text>  
            </TableCell>                                             
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {Math.round(EngagmentScores.EngagementScore,2)}
                </Text>  
            </TableCell>                                       
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {vRhyteItApplicantProfileRow.UpdateCounter}
                </Text>  
            </TableCell>                                     
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {moment(vRhyteItApplicantProfileRow.UpdateDateTime).utc().format("YYYY-MM-DD").toLocaleString()}
                </Text>  
            </TableCell>                                                            
          </TableRow>  
        ))
      }                
      </TableBody>
    
  )
}

export default ApplicantProfileRow;

