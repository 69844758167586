import PropTypes from "prop-types";
import React, { useState, useEffect, useRef,useCallback } from "react";
import { motion as m } from "framer-motion";
import styles from "./BackgroundAnimationTester.module.css";
import useWindow from "../../BusinessLogic/Hooks/useWindow";
import {
  useInView,
  useScroll,
  useTransform,
  motion,
  useAnimation,
} from "framer-motion";

const BackgroundAnimationTester = (props) => {
  BackgroundAnimationTester.propTypes = {
    backgroundcolor: PropTypes.string,
  };
  const { dimension } = useWindow();
  const OFcanvasRef  = useRef();
  const [context, setContext] = useState(null);

  var vAnimationCount = useRef(0);

  const OFBGContainerRef = useRef(null);
  const isInView = useInView(OFBGContainerRef);
  const img = new Image();
  const [OnlyFansProspectingProfilesData, setOnlyFansProspectingProfilesData] = useState()    
  const [vOFLength, setvOFLength] = useState(0)   

  var vAnimationFrames = []

  useEffect(() => {

    (async function() {
      try {  

        GetOnlyFansProspectingProfilesFromS3()


          } catch (err) {
            console.error('OFProfileTickerHorizontal.useEffect[].ERROR: ', err, err.stack); 
        }
      }
    )();   

  }, []);

  useEffect(() => {
    
  //  console.log("BackgroundAnimationTester.useEffect[dimension].dimension:  ",dimension) 
    if (dimension.width > 0) {
      init(); 
    }
  }, [dimension]);


  useEffect(() => {



  }, [isInView]);

  

  useEffect(() => {


   // console.log("BackgroundAnimationTester.useEffect[context].context:  ",context, " - OFcanvasRef:  ",OFcanvasRef, " - vOFLength:  ",vOFLength) 

    if (context && OFcanvasRef !== undefined && vOFLength > 0) {
              
      let x =  setTimeout(IterateAnimation, 100)

    }

  }, [context]);

useEffect(() => {

    
 // console.log("BackgroundAnimationTester.useEffect[OnlyFansProspectingProfilesData].OnlyFansProspectingProfilesData:  ",OnlyFansProspectingProfilesData) 

      if (OnlyFansProspectingProfilesData !== undefined && OFcanvasRef !== undefined)  {

        setvOFLength(OnlyFansProspectingProfilesData.length)

      }
           
}, [OnlyFansProspectingProfilesData]);

useEffect(() => {

  init(); 

//  console.log("BackgroundAnimationTester.useEffect[vOFLength].vOFLength:  ",vOFLength) 
 // console.log("BackgroundAnimationTester.useEffect[vOFLength].isInView:  ",isInView) 
//  console.log("BackgroundAnimationTester.useEffect[vOFLength].vAnimationCount.current:  ",vAnimationCount.current) 
//  console.log("BackgroundAnimationTester.useEffect[vOFLength].OFcanvasRef.current:  ",OFcanvasRef.current) 
  
  if (vAnimationCount.current ===0 && vOFLength > 0 ) {

    if (OFcanvasRef.current && vAnimationCount.current === 0) {
      const canvas = OFcanvasRef.current;
      const ctx = canvas.getContext("2d");
      setContext(ctx);
    }


  }

  if (isInView === false && vAnimationFrames.length > 0) {
    cancelAllAnimationFrames()
  }  
 
}, [vOFLength]);


async function GetOnlyFansProspectingProfilesFromS3() {
  try {


            const url = `https://rhyteitfiles.s3.us-west-2.amazonaws.com/ScollingData/OFProfiles.json`

            const fetchResponse = await fetch(url);
            let OnlyFansProspectingProfilesData1 = await fetchResponse.json();

            setOnlyFansProspectingProfilesData(OnlyFansProspectingProfilesData1)                    



  } catch (err) { 
                  console.error('OFProfilePicTicker.GetOnlyFansProspectingProfiles.ERROR: ', err, err.stack); 
              }
}    


  const init = () => {

    //ctx = OFcanvasRef.current.getContext("2d");
    //ctx.fillStyle = "#CC6633";
    //console.log("BackgroundAnimationTester.init.dimension.width:  ",dimension.width) 

    const ctx = OFcanvasRef.current.getContext("2d");
    const grd = ctx.createRadialGradient(0, 0, dimension.width, dimension.width, dimension.height, 100);
    grd.addColorStop(0, "#00CCCC");
    grd.addColorStop(1, "#CC6633");
    
    // Draw a filled Rectangle
    ctx.fillStyle = "#000000";                  
    
    ctx.fillRect(0,0,dimension.width,dimension.height);
    ctx.globalAlpha = 1;
   //ctx.globalCompositeOperation ="difference";
   ctx.globalCompositeOperation = "hard-light";
   //ctx.globalCompositeOperation = "lighten";
   //ctx.globalCompositeOperation = "lighten";
   //ctx.globalCompositeOperation = "lighten";
   //ctx.globalCompositeOperation ="source-out"
  };

  class Particle {
    constructor(x, y) {
      this.x = 40;
      this.y = -100;
      //this.y = Math.floor(Math.random() * dimension.height) + 1;
      this.size = 10;
      this.weight = 1;
      this.directionX = -2;
    }
    update() {
      if (this.y > dimension.height) {
        this.y = 0 - this.size
        this.weight = 2;
        this.x = Math.floor(Math.random() * dimension.width) + 1;
      }
      this.weight += 0.05;
      this.y += 140;
      this.x += this.directionX;
    }
    draw() {

      const ctxImg = OFcanvasRef.current.getContext("2d");
      ctxImg.beginPath();
      //ctx.shadowColor = "black";
      //ctx.shadowBlur = 15;
      //ctx.filter = "blur(1px)";
      img.onload = () => {     
        
        ctxImg.drawImage(img, this.x, this.y);

      }

      let vImage = OnlyFansProspectingProfilesData[Math.floor(Math.random() * vOFLength)]["OnlyFansProfilePictureURL"]
      
      img.src = vImage;       

    }
  }

  const particle1 = new Particle(40,-100)

  function animate() {

    //console.log("BackgroundAnimationTester.animate.OFcanvasRef:  ",OFcanvasRef) 
    if (context && OFcanvasRef.current !== null) {
      particle1.update();
      particle1.draw();
      vAnimationFrames.push(requestAnimationFrame(animate))
    } 

    if (OFcanvasRef.current === null) {
      cancelAllAnimationFrames()
    }

  }

  function IterateAnimation() {
      try {

      //  console.log("BackgroundAnimationTester.IterateAnimation.OFcanvasRef:  ",OFcanvasRef, " - context:  ",context) 
        if (context && OFcanvasRef !== undefined) {
            
          animate();

        }
  

        //<h1 className={styles.EndMessage}>{vEndMessage}</h1>
      } catch (err) { 
        console.error("BackgroundAnimationTester.IterateAnimation.err:  ",err)   
                  }
  }      
  

  function cancelAllAnimationFrames() {
    try {

      vAnimationFrames.forEach((AnimationFrame) => {
        cancelAnimationFrame(AnimationFrame);
      });

      vAnimationFrames = []

      //<h1 className={styles.EndMessage}>{vEndMessage}</h1>
    } catch (err) { 
      console.error("BackgroundAnimationTester.IterateAnimation.err:  ",err)   
                }
}       
  
  

  return (
    <div className={styles.BackgroundContainer}
      ref={OFBGContainerRef}>
      <canvas ref={OFcanvasRef} id="eraseforeground"  height={dimension.height} width={dimension.width} />
    </div>
  );
};

export default BackgroundAnimationTester;
