import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';


import  { }  from '../../graphql/queries'
import  { updateRhyteItEmails, deleteRhyteItEmails,createRhyteItEmails }  from '../../graphql/mutations'
import  { listRhyteItEmailAttachments}  from '../../graphql/queries'
import { generateClient } from 'aws-amplify/api';

import {ErrorBoundary} from 'react-error-boundary'
import Header from "../RhyteItComponents/Header/Header";
import {motion as m} from "framer-motion";
import { UserContext} from '../../BusinessLogic/Hooks/UserContext';
import {ValidateUser} from '../../BusinessLogic/UserManagement';
import { useNavigate, useLocation, Link } from "react-router-dom";
import EmailAttachementTable from './EmailAttachementTable';
import {get_uuidv4} from '../../BusinessLogic/uuidv4';

import {
  Button ,
  Authenticator,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TextAreaField,
  TextField,
  Card,
  Image,
  View,
  Flex,
  Text

} from '@aws-amplify/ui-react';
import styles from './EmailDetail.module.css';


function ErrorFallback({error, resetErrorBoundary}) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}


const EmailDetail = () => {      

        const client = generateClient();

        const vBreadCrumbItems = [{Path: "/", Title:"Home", isCurrent:false},{Path: "/MainMenuV2", Title:"Menu", isCurrent:false},{Path: "/EmailManagement", Title:"EmailManagement", isCurrent:false},{Path: "/EmailDetail", Title:"Email", isCurrent:true}]
     
        let navigate = useNavigate(); 

        const location = useLocation();
        const { vEmail } = location.state;


        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   
        const [RecordUpdated, setRecordUpdated] = useState(false)  
 

        const {Users, setUsers} = useContext(UserContext);   
        const [Email, setEmail] = useState(vEmail)  
        const [EmailAttachements, setEmailAttachements] = useState([])  
        const [ShowEmailAttachementsTable, setShowEmailAttachementsTable] = useState(false)  
        const [EmailAttachementUpdateKey, setEmailAttachementUpdateKey] = useState(false)  
        
        
        

        useEffect(() => {

          (async function() {
            try {  


              if (Users === undefined) {                    
                //console.log("EmailDetail.useEffect[].Users:  ",Users)
                 let vIsUserSubscribed = await ValidateUser()
                 setUsers(vIsUserSubscribed)
              }       


                } catch (err) {
                  console.error('Emails.useEffect[].ERROR: ', err, err.stack); 
              }
            }
          )();   
      

        },[]) 

        useEffect(() => {

          //console.log("EmailDetail.useEffect[Users].Users:  ",Users);

          (async function() {
            try {  
  
                if (Email.EmailStatus === "Processed") {
  
                  let UpdateToReadStatusResponse = await UpdateToReadStatus()   
  
                }

                let GetEmailAttachmentsResponse = await GetEmailAttachments()
  
                } catch (err) {
                  console.error('EmailDetail.useEffect[Email].ERROR: ', err); 
              }
            }
          )();             

      

        },[Users])         

        
        useEffect(() => {

          console.log("EmailDetail.useEffect[Email].Email:  ", Email);               
         
         },[Email])  
        
        async function DeleteEmail(event,data) {
          try {
    

    
              const DeleteEmails = {
                id: Email.id,
                _version: Email._version
              }
             
              const ReturnMessage = await client.graphql({
                query: deleteRhyteItEmails,
                variables: {
                  input: DeleteEmails
                }
              });                
    
              setEmail([])
    
              setErrorMessage("Deleted")         
                    
              } catch (err) { 
    
              ErrorMessageStr = "EmailDetail.DeleteEmail.Error: " + err + " " + err.stack
    
              console.error(ErrorMessageStr); 
              
              setErrorMessage(ErrorMessageStr)         
            }               
          
        }       
        
        function GoToEmailManagement()  {

          navigate('/EmailManagement', 
          {
            state: {
                RedditUser:Users
            }
          })

        }      

        async function UpdateToReadStatus() {
          try {
    
    
                if (Users === "edwardhawke@rhyteit.com") {
    
                  
    
                  var vUpdateDateTime = new Date().toISOString()
                  
                  Email.UpdateDateTime = vUpdateDateTime       
                  Email.UpdateName = Users
    
                    let EmailItem = {
                      "id": Email.id,
                      "EmailAccount": Email.EmailAccount,
                      "From": Email.From,
                      "To": Email.To,
                      "Subject": Email.Subject,
                      "S3Bucket": Email.S3Bucket,
                      "S3Key": Email.S3Key,
                      "EmailText":  Email.EmailText,
                      "EmailHtml": Email.EmailHtml,
                      "EmailRawText": Email.EmailRawText,
                      "EmailStatus": "read",
                      "ContentType": Email.ContentType,
                      "Size": Email.Size,
                      "SizeUnits": Email.SizeUnits,
                      "ContentTransferEncoding": Email.ContentTransferEncoding,
                      "InReplyTo": Email.InReplyTo,
                      "MessageID": Email.MessageID,
                      "CharSet": Email.CharSet,
                      "CreationName": Email.CreationName,
                      "CreationDateTime": Email.CreationDateTime,
                      "UpdateName": Email.UpdateName,
                      "UpdateDateTime": Email.UpdateDateTime,
                  }
    
                  //console.log("EmailDetail.UpdateToReadStatus.EmailItem    :  ",EmailItem    ) 
    
                  const updateRhyteItEmailsResponse = await client.graphql({
                    query: updateRhyteItEmails,
                    variables: {
                      input: EmailItem
                    }
                  });  
    
                  setEmail(updateRhyteItEmailsResponse.data.updateRhyteItEmails)
                  setErrorMessage(ErrorMessageStr)
        
     
                  
              } else {
    
                ErrorMessageStr = "You do not have permission to update email"
                setErrorMessage(ErrorMessageStr)  
    
              }
          
              } catch (err) { 
    
              ErrorMessageStr = "EmailDetail.UpdateToReadStatus.Error: " + err 
    
              console.error(err); 
              
              setErrorMessage(ErrorMessageStr)         
            }               
          
        }         

        async function GetEmailAttachments() {
          try {
    
            setErrorMessage("")
            //console.log("EmailDetail.GetEmailAttachments.Users: ",Users);

                if (Users === "edwardhawke@rhyteit.com") {
    
                      const variables = {
                        limit: 1000000,
                        filter: {
                            EmailId: {
                                  eq: Email.id
                              }
                        }            
                      }
            
                      //console.log("TwitterStats.fetch.variables: ", variables);  
                      
                      const EmailAttachmentsData1  = await client.graphql({
                        query: listRhyteItEmailAttachments,
                        variables: variables
                      });
                      
                      //console.log("EmailAttachmentsData1.data.listRhyteItEmailAttachments.items.length: ",EmailAttachmentsData1.data.listRhyteItEmailAttachments.items.length);
        
                      if (EmailAttachmentsData1.data.listRhyteItEmailAttachments.items.length > 0) {
                        
                        const EmailManagement1 = EmailAttachmentsData1.data.listRhyteItEmailAttachments.items 

                        setEmailAttachements(EmailManagement1)     
                        setShowEmailAttachementsTable(true)
                        setEmailAttachementUpdateKey(new Date().toISOString())
                        let vEmailAttachmentCount = EmailAttachmentsData1.data.listRhyteItEmailAttachments.items.length
                        ErrorMessageStr = "Number attachements with this email: " + vEmailAttachmentCount
                        setErrorMessage(ErrorMessageStr)  

                      }
            
        
                      
                  } else {
        
                    ErrorMessageStr = "No attachments for email"
                    setErrorMessage(ErrorMessageStr)  
        
                  }
              
                  } catch (err) { 
        
                  ErrorMessageStr = "EmailDetail.GetEmailAttachments.Error: " + err 
        
                  console.error(err); 
                  
                  setErrorMessage(ErrorMessageStr)         
                }               
              
        }    

        function EmailText_handleChange(event, data) {
          try {              
              
    
            Email.EmailText = event.target.value
              // //console.log("EmailDetail.Subscribed_handleChange.RhyteItProduct[elementPos].Subscribed: ",RhyteItProduct[elementPos].Subscribed)
     
              setEmail(Email) 
     
    
            } catch (err) { 
              console.error("EmailDetail.OriginalText_handleChange.Error:  ".err, err.stack); 
          }        
        }   

        function ReplyText_handleChange(event, data) {
          try {              
              
    
            Email.ReplyText = event.target.value
              // //console.log("EmailDetail.Subscribed_handleChange.RhyteItProduct[elementPos].Subscribed: ",RhyteItProduct[elementPos].Subscribed)
     
              setEmail(Email) 
     
    
            } catch (err) { 
              console.error("EmailDetail.OriginalText_handleChange.Error:  ".err, err.stack); 
          }        
        }   
        
        async function SaveEmail(event, data) {
          try {           
            
              setErrorMessage("")
              let updateRhyteItEmailsResponse = "" 
              
    
              var vUpdateDateTime = new Date().toISOString()
      
              Email.UpdateDateTime = vUpdateDateTime       
              Email.UpdateName = Users
        
              let EmailItem = {
                  "id": Email.id,
                  "EmailAccount": Email.EmailAccount,
                  "From": Email.From,
                  "To": Email.To,
                  "Subject": Email.Subject,
                  "S3Bucket": Email.S3Bucket,
                  "S3Key": Email.S3Key,
                  "EmailText":  Email.EmailText,
                  "ReplyText":  Email.ReplyText,
                  "EmailHtml": Email.EmailHtml,
                  "EmailRawText": Email.EmailRawText,
                  "EmailStatus": Email.EmailStatus,
                  "ContentType": Email.ContentType,
                  "Size": Email.Size,
                  "SizeUnits": Email.SizeUnits,
                  "ContentTransferEncoding": Email.ContentTransferEncoding,
                  "InReplyTo": Email.InReplyTo,
                  "MessageID": Email.MessageID,
                  "CharSet": Email.CharSet,
                  "CreationName": Email.CreationName,
                  "CreationDateTime": Email.CreationDateTime,
                  "UpdateName": Email.UpdateName,
                  "UpdateDateTime": Email.UpdateDateTime,
              }
    

              if (EmailItem.EmailStatus === "NEW") {        
                  
                EmailItem.EmailStatus = "draft"
  
                const createRhyteItEmailsResponse = await client.graphql({
                  query: createRhyteItEmails,
                  variables: {
                    input: EmailItem
                  }
                });  
    
              } else {

                EmailItem.EmailStatus = "draft"      

                const updateRhyteItEmailResponse = await client.graphql({
                  query: updateRhyteItEmails,
                  variables: {
                    input: EmailItem
                  }
                });                    
              }
    
              navigate('/EmailManagement', 
                {
                }
              );                
         
    
            } catch (err) { 
              console.error(err)
              ErrorMessageStr = ("EmailDetail.SendEmail.Error: ", err, err.stack)
              console.error(ErrorMessageStr)
              setErrorMessage(ErrorMessageStr)
          }        
        }     
        
        async function SendEmail(event, data) {
          try {           
            
              setErrorMessage("")
              let updateRhyteItEmailsResponse = "" 
              
    
              var vUpdateDateTime = new Date().toISOString()
      
              Email.UpdateDateTime = vUpdateDateTime       
              Email.UpdateName = Users
    
              var DynamoUUID =  get_uuidv4()
    
              let EmailItem = {
                  "id": Email.id,
                  "EmailAccount": Email.EmailAccount,
                  "From": Email.From,
                  "To": Email.To,
                  "Subject": Email.Subject,
                  "S3Bucket": Email.S3Bucket,
                  "S3Key": Email.S3Key,
                  "EmailText":  Email.EmailText,
                  "ReplyText":  Email.ReplyText,
                  "EmailHtml": Email.EmailHtml,
                  "EmailRawText": Email.EmailRawText,
                  "EmailStatus": Email.EmailStatus,
                  "ContentType": Email.ContentType,
                  "Size": Email.Size,
                  "SizeUnits": Email.SizeUnits,
                  "ContentTransferEncoding": Email.ContentTransferEncoding,
                  "InReplyTo": Email.InReplyTo,
                  "MessageID": Email.MessageID,
                  "CharSet": Email.CharSet,
                  "CreationName": Email.CreationName,
                  "CreationDateTime": Email.CreationDateTime,
                  "UpdateName": Email.UpdateName,
                  "UpdateDateTime": Email.UpdateDateTime,
              }
        
                console.log("EmailDetail.SendEmail.EmailItem:  ",EmailItem)   

                if (EmailItem.EmailStatus === "NEW") {        
                  
                  EmailItem.EmailStatus = "send"
    
                  const createRhyteItEmailsResponse = await client.graphql({
                    query: createRhyteItEmails,
                    variables: {
                      input: EmailItem
                    }
                  });  
      
                } else {

                  EmailItem.EmailStatus = "send"      
  
                  const updateRhyteItEmailResponse = await client.graphql({
                    query: updateRhyteItEmails,
                    variables: {
                      input: EmailItem
                    }
                  });                    
                }
    
              navigate('/EmailManagement', 
                {
                }
              );                
         
    
            } catch (err) { 
              console.error(err)
              ErrorMessageStr = ("EmailDetail.SendEmail.Error: ", err, err.stack)
              console.error(ErrorMessageStr)
              setErrorMessage(ErrorMessageStr)
          }        
        } 
        
 

    return ( 
    <m.div
      key="RhyteItEmailDetail"
      initial={{y: "100%"}}
      animate={{y: "0%"}}
      transition={{duration:0.75, ease: "easeOut"}}
      exit={{ opacity: 1 }}
     >
      <Authenticator  className={styles.AuthenticatorLogin} socialProviders={[]} hideSignUp={true}>
        {({ signOut, user }) => (   
          <div className={styles.SystemAdminBackground}>
            <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                >   
                    <div  className={styles.GridContainer}>       
                      <Header Title="Email" SubTitle={Email.From + " - " + Email.Subject} BreadCrumbItems ={vBreadCrumbItems} pageSource="EmailDetail"/>       
                        <div className={styles.FormContainer}>
                          <View
                            className={styles.View}
                            width="100%"
                          >
                            <Card className={styles.Card} width="100%">
                              <Flex direction="row" alignItems="flex-start">
                                <Flex
                                  direction="column"
                                  alignItems="flex-start"
                                  gap="2em"
                                >
                                <Flex>
 
                                  <Link key={Email.id} isExternal={false} to="/EmailReply" state={{ vEmail: Email }} >                 
                                    <Button className={styles.RedditButtons}      
                                              variation="primary">Reply</Button>
                                  </Link>                                     
                                  <Button className={styles.RedditButtons}                                       
                                          onClick={ (e) => DeleteEmail(e, Email) }
                                          variation="primary">Delete</Button>
                                  <Button className={styles.RedditButtons}                                       
                                          onClick={ (e) => SaveEmail(e, Email) }
                                          variation="primary">Save</Button>
                                  <Button className={styles.RedditButtons}                                       
                                          onClick={ (e) => SendEmail(e, Email) }
                                          variation="primary">Send</Button>
                                  <Button className={styles.RedditButtons}                                                                         
                                          onClick={ (e) => GoToEmailManagement(e, Email) }
                                          variation="primary">Back</Button>
                                  <Button className={styles.RedditButtons}                                       
                                          onClick={ (e) => GoToEmailManagement(e, Email) }
                                          variation="primary">Attachements</Button>
                                </Flex>       
                                  <Flex>      
                                    <Text   
                                      className={styles.TextFieldHeaderLabel}
                                      >
                                        Created:
                                    </Text>
                                    <Text size="small" className={styles.Text}>
                                      {new Date( Email.CreationDateTime).toLocaleString("en-US")}
                                    </Text>    
                                    <Text   
                                      className={styles.TextFieldHeaderLabel}
                                      >
                                        Updated:
                                    </Text>
                                    <Text size="small" className={RecordUpdated === false ? styles.Text :styles.TextUpdated  }>
                                      {new Date( Email.UpdateDateTime).toLocaleString("en-US")}
                                    </Text>
                                  </Flex>
                                  <Table>
                                    <TableBody>                                 
                                      <TableRow className={styles.TableRow}>
                                        <TableCell  className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              MessageID
                                          </Text>
                                        </TableCell>
                                        <TableCell className={styles.RowData}  colspan="3">
                                        <Text   
                                            className={styles.TextField}
                                            >
                                              {Email.MessageID}
                                          </Text>
                                        </TableCell>
                                      </TableRow >                                     
                                      <TableRow className={styles.TableRow}>
                                        <TableCell  className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              InReplyTo
                                          </Text>
                                        </TableCell>
                                        <TableCell className={styles.RowData}  colspan="3">
                                        <Text   
                                            className={styles.TextField}
                                            >
                                              {Email.InReplyTo}
                                          </Text>
                                        </TableCell>
                                      </TableRow >
                                      <TableRow className={styles.TableRow}>
                                        <TableCell className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              From
                                          </Text>
                                        </TableCell>
                                        <TableCell className={styles.RowData}>     
                                          <Text   
                                            className={styles.TextField}
                                            >
                                              {Email.From}
                                          </Text>
                                        </TableCell>
                                        <TableCell className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              To
                                          </Text>
                                        </TableCell>
                                        <TableCell className={styles.RowData}>     
                                          <Text   
                                            className={styles.TextField}
                                            >
                                              {Email.To}
                                          </Text>
                                        </TableCell>
                                      </TableRow>                                      
                                      <TableRow className={styles.TableRow}>
                                        <TableCell className={styles.RowLabelColumn} >         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              Subject
                                          </Text>
                                        </TableCell>
                                        <TableCell colspan="3">
                                        <Text   
                                            className={styles.TextField}
                                            >
                                              {Email.Subject}
                                          </Text>
                                        </TableCell>
                                      </TableRow>
                                      {Email.EmailText.length > 0 && (
                                      <TableRow>
                                        <TableCell className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              EmailText
                                          </Text>   
                                        </TableCell>
                                        <TableCell colspan="3"> 
                                        <TextAreaField 
                                            className={styles.TextAreaField} 
                                            name="Email.EmailText"                     
                                            defaultValue={Email.EmailText}
                                            onChange={ (e) => EmailText_handleChange(e, Email) }
                                            rows={5}
                                            columns={3}/>                                                       
                                        </TableCell>
                                      </TableRow>  
                                      )  }   
                                      {Email.EmailHtml !== null && (       
                                      <TableRow>
                                        <TableCell className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              EmailHtml
                                          </Text>   
                                        </TableCell>
                                        <TableCell colspan="3"> 
                                        <div
                                              dangerouslySetInnerHTML={{ __html: Email.EmailHtml }}
                                            />                                                 
                                        </TableCell>
                                      </TableRow>     
                                      )  }                                          
                                      {Email.ReplyText !== null && (
                                      <TableRow>
                                        <TableCell className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              Original
                                          </Text>   
                                        </TableCell>
                                        <TableCell colspan="3"> 
                                        <TextAreaField 
                                            className={styles.TextAreaField} 
                                            name="Email.ReplyText"                     
                                            defaultValue={Email.ReplyText}
                                            onChange={ (e) => ReplyText_handleChange(e, Email) }
                                            rows={5}
                                            columns={3}/>                                     
                                        </TableCell>
                                      </TableRow> 
                                      )  }                                                     
                                      <TableRow className={styles.TableRow}>
                                        <TableCell  className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              S3Key
                                          </Text>
                                        </TableCell>
                                        <TableCell className={styles.LongCellContent} colspan="3">
                                        <Text   
                                            className={styles.TextField}
                                            >
                                              {Email.S3Key}
                                          </Text>
                                        </TableCell>
                                      </TableRow >                                        
                                      <TableRow className={styles.TableRow}>
                                        <TableCell  className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              EmailStatus
                                          </Text>
                                        </TableCell>
                                        <TableCell className={styles.RowData}>
                                        <Text   
                                            className={styles.TextField}
                                            >
                                              {Email.EmailStatus}
                                          </Text>
                                        </TableCell>
                                        <TableCell  className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              ContentType
                                          </Text>
                                        </TableCell>
                                        <TableCell className={styles.RowData}>
                                        <Text   
                                            className={styles.TextField}
                                            >
                                              {Email.ContentType}
                                          </Text>
                                        </TableCell>
                                      </TableRow >                                        
                                      <TableRow className={styles.TableRow}>
                                        <TableCell  className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              Size
                                          </Text>
                                        </TableCell>
                                        <TableCell className={styles.RowData}>
                                        <Text   
                                            className={styles.TextField}
                                            >
                                              {Email.Size}
                                          </Text>
                                        </TableCell>
                                        <TableCell  className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              SizeUnits
                                          </Text>
                                        </TableCell>
                                        <TableCell className={styles.RowData}>
                                        <Text   
                                            className={styles.TextField}
                                            >
                                              {Email.SizeUnits}
                                          </Text>
                                        </TableCell>
                                      </TableRow >                                       
                                      <TableRow className={styles.TableRow}>
                                        <TableCell  className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              ContentTransferEncoding
                                          </Text>
                                        </TableCell>
                                        <TableCell className={styles.RowData}>
                                        <Text   
                                            className={styles.TextField}
                                            >
                                              {Email.ContentTransferEncoding}
                                          </Text>
                                        </TableCell>
                                        <TableCell  className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              CharSet
                                          </Text>
                                        </TableCell>
                                        <TableCell className={styles.RowData}>
                                        <Text   
                                            className={styles.TextField}
                                            >
                                              {Email.CharSet}
                                          </Text>
                                        </TableCell>
                                      </TableRow >    
                                    </TableBody>
                                  </Table>     
                                  <div ><Text className={styles.MessageBox}>{ErrorMessage}</Text></div>                            
                                  {ShowEmailAttachementsTable && EmailAttachements.length > 0 && (<EmailAttachementTable   key={EmailAttachementUpdateKey}  EmailAttachements={EmailAttachements}/>)  }                                                                                                      
                              
                                </Flex>
                              </Flex>
                            </Card>
                          </View>           
                        </div>                          
                      </div>  
            </ErrorBoundary>
          </div>   
        )}         
      </Authenticator>  
    </m.div>                      

    )

  }
 

export default EmailDetail;