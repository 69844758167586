import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';


import  {listProducts}  from '../../graphql/queries'
import  { createAudienceGeoInfo}  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import {ValidateUser} from '../../BusinessLogic/UserManagement';
import {motion as m} from "framer-motion";
import DigitalMarketPlaceTable from "./DigitalMarketPlaceTable";
import { UserContext} from '../../BusinessLogic/Hooks/UserContext';
import axios from 'axios';
import {uuidv4} from "../../BusinessLogic/UtilitiesManagement";
import Footer from '../RhyteItComponents/Footer/Footer';
import Header from "../RhyteItComponents/Header/Header";


import {
Flex
} from '@aws-amplify/ui-react';
import styles from './DigitalMarketPlace.module.css';

const DigitalMarketPlace = (props) => {       
  
  DigitalMarketPlace.propTypes = {
          RedditUser: PropTypes.object
        };


        const client = generateClient();
        const [RhyteItDigitalMarketPlace, setRhyteItDigitalMarketPlace] = useState([])   
        const vBreadCrumbItems = [{Path: "/", Title:"Home", isCurrent:false},{Path: "/MainMenuV2", Title:"Menu", isCurrent:false},{Path: "/DigitalMarketPlace", Title:"Digital Market", isCurrent:true}]
     
     
              
        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   

        const {Users, setUsers} = useContext(UserContext);      
        const [ProductsReturned, setProductsReturned] = useState(0);
        const [ShowTable, setShowTable] = useState(false);        

        const [updatedKey, setupdatedKey] = useState(Date.now())   

        const [isChecked, setIsChecked] = React.useState(false);
        const [Environment, setEnvironment] = React.useState(process.env.REACT_APP_ENV);        

        useEffect(() => {

        (async function() {
          try {  

                  if (Users === undefined) {                    
                    //console.log("DigitalMarketPlace.useEffect[].Users:  ",Users)
                      let vIsUserSubscribed = await ValidateUser()
                      setUsers(vIsUserSubscribed)
                    //console.log("DigitalMarketPlace.useEffect[].Users:  ",Users)

                  } else if (Users.length > 0 && Users !== undefined) {

                    //console.log("DigitalMarketPlace.useEffect[].Users:  ",Users)

                    GetDigitalMarketPlace()

                  }                     


              } catch (err) {
                console.error('DigitalMarketPlace.useEffect[].ERROR: ', err, err.stack); 
            }
          }
        )();   


        },[]) 

        useEffect(() => {
        //console.log("DigitalMarketPlace.useEffect[RhyteItDigitalMarketPlace].RhyteItDigitalMarketPlace:  ",RhyteItDigitalMarketPlace)
        //console.log("SBSMQueries.useEffect[SBSMNewsQueryTerms].Object.keys(SBSMNewsQueryTerms).length:  ",Object.keys(SBSMNewsQueryTerms).length )

          if (Users !== undefined) {
          GetDigitalMarketPlace()  
          }

          setErrorMessage("Products retrieved at: " + new Date().toLocaleString())

        },[Users])             

        useEffect(() => {
        //console.log("DigitalMarketPlace.useEffect[RhyteItDigitalMarketPlace].RhyteItDigitalMarketPlace:  ",RhyteItDigitalMarketPlace)
        //console.log("SBSMQueries.useEffect[SBSMNewsQueryTerms].Object.keys(SBSMNewsQueryTerms).length:  ",Object.keys(SBSMNewsQueryTerms).length )

          if (Object.keys(RhyteItDigitalMarketPlace).length > 0) {
            setShowTable(true)
          }

          setErrorMessage("Products retrieved at: " + new Date().toLocaleString())

        },[RhyteItDigitalMarketPlace])     

        useEffect(() => {
        //console.log("DigitalMarketPlace.useEffect[RhyteItDigitalMarketPlace].RhyteItDigitalMarketPlace:  ",RhyteItDigitalMarketPlace)
        //console.log("SBSMQueries.useEffect[SBSMNewsQueryTerms].Object.keys(SBSMNewsQueryTerms).length:  ",Object.keys(SBSMNewsQueryTerms).length )


          setErrorMessage("Products retrieved at: " + new Date().toLocaleString())

        },[isChecked])     
    

        async function GetDigitalMarketPlace() {
        try {
                  setErrorMessage("")
                  setRhyteItDigitalMarketPlace([])

                  const variables = { 
                    filter: {
                              Environment: {
                                    eq: Environment
                                }
                            }
                  };              
                  
        
                  const DigitalMarketPlaceData2  = await client.graphql({
                    query: listProducts,
                    variables: variables
                  });
                   



                  if (DigitalMarketPlaceData2.data.listProducts.items.length > 0) {
                      //console.log("DigitalMarketPlaceData2.data.listDigitalMarketPlace.items.length: ",DigitalMarketPlaceData2.data.listProducts.items.length);
                      setProductsReturned(DigitalMarketPlaceData2.data.listProducts.items.length)
                      const DigitalMarketPlace1 = DigitalMarketPlaceData2.data.listProducts.items 

                      setRhyteItDigitalMarketPlace(DigitalMarketPlace1)       
                      setupdatedKey(Date.now())            
                      setShowTable(true)
          
                }

        } catch (err) { 
                        console.error('DigitalMarketPlace.GetDigitalMarketPlace.ERROR: ', err, err.stack); 
                    }
        }            

        

    return ( 
    <m.div
      key="Search"
      initial={{x: "100%"}}
      animate={{x: "0%"}}
      transition={{duration:0.75, ease: "easeOut"}}
      exit={{ opacity: 1 }}
     >
  
          <div className={styles.SystemAdminBackground}>
                    <Flex direction="column"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          alignContent="flex-start"
                          wrap="wrap"
                          gap="1rem"
                          className={styles.GridContainer}>                               
                          <Header Title="RhyteIt Digital Market" SubTitle="Buy products that will help you grow your subscription based social media business" BreadCrumbItems ={vBreadCrumbItems} pageSource="MarketPlaceDetail"/>              
                          <div className={styles.ToolBar}> 
                              <div className={styles.MessageBox}>{ErrorMessage} Products retrieved: {ProductsReturned}</div>  
                          </div>   
                          {ShowTable && RhyteItDigitalMarketPlace.length > 0 && (<DigitalMarketPlaceTable   key={updatedKey}  RhyteItDigitalMarketPlace={RhyteItDigitalMarketPlace} Environment={Environment}/>)  }                                                                                                                    
                          <Footer/>
                    </Flex>     
          </div>
    </m.div>                      

    )

  }
 

export default DigitalMarketPlace;