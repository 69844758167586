import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import {ErrorBoundary} from 'react-error-boundary'
import Header from "../RhyteItComponents/Header/Header";
import {motion as m} from "framer-motion";
import { UserContext} from '../../BusinessLogic/Hooks/UserContext';
import { useNavigate, useLocation } from "react-router-dom";
import {ValidateUser} from '../../BusinessLogic/UserManagement';

import  { updateEmailAccounts, deleteEmailAccounts}  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';


import {
  Button ,
  Authenticator,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  Card,
  Image,
  View,
  Flex,
  Text,
  TableHead

} from '@aws-amplify/ui-react';
import styles from './RhyteItAccountSocialMedia.module.css';
import styled from 'styled-components'


function ErrorFallback({error, resetErrorBoundary}) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

const LabelText = styled.div`
                        color: white;
                        font-size: 1.5em !important;
                        `;


const RhyteItAccountSocialMedia = (props) => {     

  RhyteItAccountSocialMedia.propTypes = {
          vRhyteItAccount: PropTypes.object,
          UserEmail: PropTypes.string
        }; 

        const client = generateClient();


        let debugConsoleOutPut = true
        let navigate = useNavigate(); 



        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   
        const [RecordUpdated, setRecordUpdated] = useState(false)  
       
        
 

        let {Users} = useContext(UserContext);   
        const [RhyteItAccount, setRhyteItAccount] = useState(props.vRhyteItAccount) 
        const [RedoImageChecked, setRedoImageChecked] = useState(props.vRhyteItAccount.RedoImage)  
        const [UserEmail, setUserEmail] = useState(props.UserEmail)  


        useEffect(() => {

          (async function() {
            try {  


              if (Users === undefined) {                    
                //console.log("ProductManagement.useEffect[].Users:  ",Users)
                 let vIsUserSubscribed = await ValidateUser()
                 setUserEmail(vIsUserSubscribed)
                //console.log("ProductManagement.useEffect[].Users:  ",Users)

              } else if (Users.length > 0 && Users !== undefined) {

                //console.log("Prod

              }                     


                } catch (err) {
                  console.error('RhyteItAccounts.useEffect[].ERROR: ', err, err.stack); 
              }
            }
          )();   
      

        },[]) 


        function EmailAccount_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItAccount.EmailAccount = event.target.value
             // //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountSocialMedia.PostTitle_handleChange.Error:  ".err, err.stack); 
          }        
        }      
        
        function Password_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItAccount.Password = event.target.value
             // //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountSocialMedia.Password_handleChange.Error:  ".err, err.stack); 
          }        
        }       
        
        function TwitterAccount_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItAccount.TwitterAccount = event.target.value
             // //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountSocialMedia.TwitterAccount_handleChange.Error:  ".err, err.stack); 
          }        
        }       

        function TwitterPassword_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItAccount.TwitterPassword = event.target.value
             // //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountSocialMedia.TwitterPassword_handleChange.Error:  ".err, err.stack); 
          }        
        }    

        function InstagramAccount_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItAccount.InstagramAccount = event.target.value
             // //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountSocialMedia.InstagramAccount_handleChange.Error:  ".err, err.stack); 
          }        
        }    

        function InstagramPassword_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItAccount.InstagramPassword = event.target.value
             // //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountSocialMedia.InstagramPassword_handleChange.Error:  ".err, err.stack); 
          }        
        }  

        function RedditAccount_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItAccount.RedditAccount = event.target.value
             // //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountSocialMedia.RedditAccount_handleChange.Error:  ".err, err.stack); 
          }        
        }            
      
        function RedditPassword_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItAccount.RedditPassword = event.target.value
             // //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountSocialMedia.RedditPassword_handleChange.Error:  ".err, err.stack); 
          }        
        }  
      
        function OnlyFansAccount_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItAccount.OnlyFansAccount = event.target.value
             // //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountSocialMedia.OnlyFansAccount_handleChange.Error:  ".err, err.stack); 
          }        
        }          
       
        function OnlyFansPassword_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItAccount.OnlyFansPassword = event.target.value
             // //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountSocialMedia.OnlyFansPassword_handleChange.Error:  ".err, err.stack); 
          }        
        }           
       
        function MediumAccount_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItAccount.MediumAccount = event.target.value
             // //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountSocialMedia.MediumAccount_handleChange.Error:  ".err, err.stack); 
          }        
        }               
       
        function MediumPassword_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItAccount.MediumPassword = event.target.value
             // //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountSocialMedia.MediumPassword_handleChange.Error:  ".err, err.stack); 
          }        
        }   

        function TwitterStatus_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItAccount.TwitterStatus = event.target.value
             // //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountSocialMedia.TwitterStatus_handleChange.Error:  ".err, err.stack); 
          }        
        }           

        function GmailAccount_handleChange(event, data) {
          try {  
    
              RhyteItAccount.GmailAccount = event.target.value
             // //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountSocialMedia.GmailAccount_handleChange.Error:  ".err, err.stack); 
          }        
        }           
       

        function GmailPassword_handleChange(event, data) {
          try {  
    
              RhyteItAccount.GmailPassword = event.target.value
             // //console.log("RhyteItAccountSocialMedia.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountSocialMedia.GmailPassword_handleChange.Error:  ".err, err.stack); 
          }        
        }           
       
      async  function SaveRhyteItAccount(event, data) {
          try {           
            
              setErrorMessage("")
              
              if (debugConsoleOutPut === true) {
                //console.log("RhyteItAccountSocialMedia.SaveRhyteItAccount.props.RhyteItUser: ",props.RhyteItUser)
               // //console.log("RhyteItAccountSocialMedia.SaveRhyteItAccount.RhyteItAccount: ",RhyteItAccount)
                //console.log("RhyteItAccountSocialMedia.SaveRhyteItAccount.event: ",event)
              }
    
           
    
              if (debugConsoleOutPut === true) {
                //console.log("RhyteItAccountSocialMedia.SaveRhyteItAccount.new Date().toISOString(): ",new Date().toISOString())
              }
    
              var vUpdateDateTime = new Date().toISOString()
    
              if (debugConsoleOutPut === true) {
              // console.log("RhyteItAccountSocialMedia.SaveRhyteItAccount.RhyteItAccount: ",RhyteItAccount)
             //   console.log("RhyteItAccountSocialMedia.SaveRhyteItAccount.Users: ",Users)
                //console.log("RhyteItAccountSocialMedia.SaveRhyteItAccount.RhyteItAccount.RespondedDate Compare: ",RhyteItAccount[elementPos].RespondedDate === "1970-01-01T00:00:00.000Z" )
             }
    
              RhyteItAccount.UpdateDateTime = vUpdateDateTime       
              RhyteItAccount.UpdateName = Users     
              RhyteItAccount.RedoImage = RedoImageChecked   
              RhyteItAccount.AccountStatus   = "Record saved by:  " + UserEmail    
              
              if (RhyteItAccount["MediumAccount"] === false || RhyteItAccount["MediumAccount"] === null) {
                RhyteItAccount["MediumAccount"] = ""
              }               
              if (RhyteItAccount["TwitterAccount"] === false || RhyteItAccount["TwitterAccount"] === null) {
                RhyteItAccount["TwitterAccount"] = ""
              } 
              if (RhyteItAccount["RedditAccount"] === false || RhyteItAccount["RedditAccount"] === null) {
                RhyteItAccount["RedditAccount"] = ""
              } 
              if (RhyteItAccount["OnlyFansAccount"] === false || RhyteItAccount["OnlyFansAccount"] === null) {
                RhyteItAccount["OnlyFansAccount"] = ""
              } 
              if (RhyteItAccount["InstagramAccount"] === false || RhyteItAccount["InstagramAccount"] === null) {
                RhyteItAccount["InstagramAccount"] = ""
              } 
              if (RhyteItAccount["ChromiumProfile"] === false || RhyteItAccount["ChromiumProfile"] === null) {
                RhyteItAccount["ChromiumProfile"] = ""
              } 
                  
    
              let vRhyteItAccountItem = {
                "id": RhyteItAccount.id,
                "UserId": RhyteItAccount.UserId,
                "Password": RhyteItAccount.Password,
                "FirstName": RhyteItAccount.FirstName,
                "LastName": RhyteItAccount.LastName,
                "Name": RhyteItAccount.Name,
                "NameNonSpaced": RhyteItAccount.NameNonSpaced,  
                "BirthMonth": RhyteItAccount.BirthMonth,
                "BirthDay": RhyteItAccount.BirthDay,
                "BirthYear": RhyteItAccount.BirthYear,
                "StartDate": RhyteItAccount.StartDate,
                "EndDate": RhyteItAccount.EndDate,
                "EmailAccountProvider": RhyteItAccount.EmailAccountProvider,
                "EmailAccount": RhyteItAccount.EmailAccount,
                "GmailAccount": RhyteItAccount.GmailAccount,
                "GmailPassword": RhyteItAccount.GmailPassword,
                "ChromiumProfile": RhyteItAccount.ChromiumProfile,
                "TwitterAccount": RhyteItAccount.TwitterAccount,
                "TwitterPassword": RhyteItAccount.TwitterPassword,
                "TwitterStatus": RhyteItAccount.TwitterStatus,
                "TwitterConsumerKey": RhyteItAccount.TwitterConsumerKey,
                "TwitterConsumerSecret": RhyteItAccount.TwitterConsumerSecret,
                "TwitterAccessToken": RhyteItAccount.TwitterAccessToken,
                "TwitterAccessTokenSecret": RhyteItAccount.TwitterAccessTokenSecret,
                "RedditAccount": RhyteItAccount.RedditAccount,
                "OnlyFansAccount": RhyteItAccount.OnlyFansAccount,
                "MediumAccount": RhyteItAccount.MediumAccount,
                "InstagramAccount": RhyteItAccount.InstagramAccount,
                "VerificationProvider": RhyteItAccount.VerificationProvider,
                "VerificationCode": RhyteItAccount.VerificationCode,
                "OpenAIOrgKey": RhyteItAccount.OpenAIOrgKey,
                "OpenAIapiKey": RhyteItAccount.OpenAIapiKey,
                "AccountStatus": RhyteItAccount.AccountStatus,
                "Prompt":RhyteItAccount.Prompt,
                "Model":RhyteItAccount.Model,
                "ProfileImageURL": RhyteItAccount.ProfileImageURL,
                "MageSeed": RhyteItAccount.MageSeed,
                "Industry": RhyteItAccount.Industry,
                "ProcessGroup": RhyteItAccount.ProcessGroup,
                "RedoImage": RhyteItAccount.RedoImage,
                "CreationName": RhyteItAccount.CreationName,
                "UpdateName": UserEmail,
                "CreationDateTime": RhyteItAccount.CreationDateTime,
                "UpdateDateTime": RhyteItAccount.UpdateDateTime
            }

    
            const updateEmailAccountsResponse = await client.graphql({
              query: updateEmailAccounts,
              variables: {
                input: vRhyteItAccountItem
              }
            });     

              if (debugConsoleOutPut === true) {
              
                console.log("RhyteItAccountSocialMedia.SaveRhyteItAccount.updateEmailAccountsResponse.data.updateEmailAccounts: ",updateEmailAccountsResponse.data.updateEmailAccounts)
    
              }

              setRecordUpdated(true)
              ErrorMessageStr = "Updated"
              setErrorMessage(ErrorMessageStr)
              setRhyteItAccount(updateEmailAccountsResponse.data.updateEmailAccounts)
         
    
            } catch (err) { 
              ErrorMessageStr = ("RhyteItAccountSocialMedia.SaveRhyteItAccount.Error: ", err, err.stack)
              console.error(ErrorMessageStr)
              setErrorMessage(ErrorMessageStr)
          }        
        }     
        
        async function DeleteRhyteItAccount(event,data) {
          try {
    

    
              const DeleteRhyteItAccounts = {
                id: RhyteItAccount.id,
                UserId: RhyteItAccount.UserId,
                _version: RhyteItAccount._version
              }
              
              const ReturnMessage = await client.graphql({
                query: deleteEmailAccounts,
                variables: {
                  input: DeleteRhyteItAccounts
                }
              });  

    
              setRhyteItAccount([])
    
              setErrorMessage("Deleted")         
                    
              } catch (err) { 
    
              ErrorMessageStr = "RhyteItAccountSocialMedia.DeleteRhyteItAccount.Error: " + err + " " + err.stack
    
              console.error(ErrorMessageStr); 
              
              setErrorMessage(ErrorMessageStr)         
            }               
          
        }           

        function GoToAccounts()  {

          navigate('/RhyteItAccounts', 
          {
            state: {
                RedditUser:Users
            }
          })

        } 
        
            

    return ( 
          <div className={styles.SystemAdminBackground}>
            <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                >   
                  <Flex
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      alignContent="flex-start"
                      wrap="nowrap"
                      gap="3rem"
                      className={styles.GridContainer}
                    >      
                      <Flex direction="row" alignItems="flex-start">
                        <Flex
                          direction="column"
                          alignItems="flex-start"
                          gap="10px"
                        >
                          <Flex >
                            <Button className={styles.RedditButtons}                                       
                                    onClick={ (e) => SaveRhyteItAccount(e, RhyteItAccount) }
                                    variation="primary">Save</Button>
                            <Button className={styles.RedditButtons}                                       
                                    onClick={ (e) => DeleteRhyteItAccount(e, RhyteItAccount) }
                                    variation="primary">Delete</Button>
                          </Flex>       

                          <Flex>
                            <Text size="large" className={RecordUpdated === false ? styles.Text :styles.TextUpdated }>
                              {RhyteItAccount.AccountStatus}
                            </Text>
                            <Text size="large" className={styles.Text}>
                              {new Date( RhyteItAccount.CreationDateTime).toLocaleString("en-US")}
                            </Text>
                            <Text size="large" className={RecordUpdated === false ? styles.Text :styles.TextUpdated  }>
                              {new Date( RhyteItAccount.UpdateDateTime).toLocaleString("en-US")}
                            </Text>
                          </Flex>                              
                          <div className={styles.MessageBox}>{ErrorMessage}</div>                                                            
                          <Table className={styles.Table}>
                          <TableHead>          
                            <TableRow className={styles.TableHeaderRow}>
                              <TableCell className={styles.TableHeaderRowCells} >Social Media</TableCell> 
                              <TableCell className={styles.TableHeaderRowCells}>ID</TableCell>   
                              <TableCell className={styles.TableHeaderRowCells} >Password</TableCell> 
                              <TableCell className={styles.TableHeaderRowCells} >Status</TableCell> 
                            </TableRow> 
                            <TableRow className={styles.TableHeaderRow}>
                              <TableCell className={styles.TableHeaderRowCells} colspan="4">
                                <div className={styles.headerline} />   
                                </TableCell> 
                            </TableRow>                                                                         
                          </TableHead>                                        
                            <TableBody>
                              <TableRow>
                                <TableCell className={styles.RowLabelColumn}>         
                                  <LabelText   
                                    className={styles.TextFieldLabel}
                                    >
                                      Email
                                  </LabelText>
                                </TableCell>
                                <TableCell>
                                  <TextField className={styles.TextField} 
                                    name="vRhyteItAccount.EmailAccount"                           
                                    defaultValue={RhyteItAccount.EmailAccount}
                                    onChange={ (e) => EmailAccount_handleChange(e, RhyteItAccount) } />
                                </TableCell>
                                <TableCell>
                                  <TextField className={styles.TextField} 
                                    name="vRhyteItAccount.Password"                           
                                    defaultValue={RhyteItAccount.Password}
                                    onChange={ (e) => Password_handleChange(e, RhyteItAccount) } />
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={styles.RowLabelColumn}>         
                                  <LabelText   
                                    className={styles.TextFieldLabel}
                                    >
                                      GMail
                                  </LabelText>
                                </TableCell>
                                <TableCell>
                                  <TextField className={styles.TextField} 
                                    name="vRhyteItAccount.GmailAccount"                           
                                    defaultValue={RhyteItAccount.GmailAccount}
                                    onChange={ (e) => GmailAccount_handleChange(e, RhyteItAccount) } />
                                </TableCell>
                                <TableCell>
                                  <TextField className={styles.TextField} 
                                    name="vRhyteItAccount.GmailPassword"                           
                                    defaultValue={RhyteItAccount.GmailPassword}
                                    onChange={ (e) => GmailPassword_handleChange(e, RhyteItAccount) } />
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={styles.RowLabelColumn}>         
                                  <LabelText   
                                    className={styles.TextFieldLabel}
                                    >
                                      Twitter
                                  </LabelText>
                                </TableCell>
                                <TableCell>
                                  <TextField className={styles.TextField} 
                                    name="vRhyteItAccount.TwitterAccount"                           
                                    defaultValue={RhyteItAccount.TwitterAccount}
                                    onChange={ (e) => TwitterAccount_handleChange(e, RhyteItAccount) } />
                                </TableCell>
                                <TableCell>
                                  <TextField className={styles.TextField} 
                                    name="vRhyteItAccount.TwitterPassword"                           
                                    defaultValue={RhyteItAccount.TwitterPassword}
                                    onChange={ (e) => TwitterPassword_handleChange(e, RhyteItAccount) } />
                                </TableCell>
                                <TableCell>
                                  <TextField className={styles.TextField} 
                                    name="vRhyteItAccount.TwitterStatus"                           
                                    defaultValue={RhyteItAccount.TwitterStatus}
                                    onChange={ (e) => TwitterStatus_handleChange(e, RhyteItAccount) } />
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={styles.RowLabelColumn}>         
                                  <LabelText   
                                    className={styles.TextFieldLabel}
                                    >
                                      Instagram
                                  </LabelText>
                                </TableCell>
                                <TableCell>
                                  <TextField className={styles.TextField} 
                                    name="vRhyteItAccount.InstagramAccount"                           
                                    defaultValue={RhyteItAccount.InstagramAccount}
                                    onChange={ (e) => InstagramAccount_handleChange(e, RhyteItAccount) } />
                                </TableCell>
                                <TableCell>
                                  <TextField className={styles.TextField} 
                                    name="vRhyteItAccount.InstagramPassword"     
                                    onChange={ (e) => InstagramPassword_handleChange(e, RhyteItAccount) } />
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={styles.RowLabelColumn}>         
                                  <LabelText   
                                    className={styles.TextFieldLabel}
                                    >
                                      Reddit
                                  </LabelText>
                                </TableCell>
                                <TableCell>
                                  <TextField className={styles.TextField} 
                                    name="RhyteItAccount.RedditAccount"                           
                                    defaultValue={RhyteItAccount.RedditAccount}
                                    onChange={ (e) => RedditAccount_handleChange(e, RhyteItAccount) } />
                                </TableCell>
                                <TableCell>
                                  <TextField className={styles.TextField} 
                                    name="RhyteItAccount.RedditPassword"     
                                    onChange={ (e) => RedditPassword_handleChange(e, RhyteItAccount) } />
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={styles.RowLabelColumn}>         
                                  <LabelText   
                                    className={styles.TextFieldLabel}
                                    >
                                      OnlyFans
                                  </LabelText>
                                </TableCell>
                                <TableCell>
                                  <TextField className={styles.TextField} 
                                    name="RhyteItAccount.OnlyFansAccount"                           
                                    defaultValue={RhyteItAccount.OnlyFansAccount}
                                    onChange={ (e) => OnlyFansAccount_handleChange(e, RhyteItAccount) } />
                                </TableCell>
                                <TableCell>
                                  <TextField className={styles.TextField} 
                                    name="RhyteItAccount.OnlyFansPassword"     
                                    onChange={ (e) => OnlyFansPassword_handleChange(e, RhyteItAccount) } />
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={styles.RowLabelColumn}>         
                                  <LabelText   
                                    className={styles.TextFieldLabel}
                                    >
                                      Medium
                                  </LabelText>
                                </TableCell>
                                <TableCell>
                                  <TextField className={styles.TextField} 
                                    name="vRhyteItAccount.MediumAccount"                           
                                    defaultValue={RhyteItAccount.MediumAccount}
                                    onChange={ (e) => MediumAccount_handleChange(e, RhyteItAccount) } />
                                </TableCell>
                                <TableCell>
                                  <TextField className={styles.TextField} 
                                    name="RhyteItAccount.MediumPassword"     
                                    onChange={ (e) => MediumPassword_handleChange(e, RhyteItAccount) } />
                                </TableCell>
                              </TableRow>
                              <TableRow className={styles.TableHeaderRow}>
                              <TableCell className={styles.TableHeaderRowCells} colspan="4">
                                <div className={styles.headerline} />   
                                </TableCell> 
                            </TableRow>                                                                  
                            </TableBody>
                          </Table>              
                        </Flex>                             
                      </Flex>   
                    </Flex>   
            </ErrorBoundary>
          </div>                 

    )

  }
 

export default RhyteItAccountSocialMedia;