import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';


import  { }  from '../../graphql/queries'
import  { createAudienceGeoInfo, createProducts, updateProducts, deleteProducts}  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import {ErrorBoundary} from 'react-error-boundary'
import Header from "../RhyteItComponents/Header/Header";
import {motion as m} from "framer-motion";
import { UserContext} from '../../BusinessLogic/Hooks/UserContext';
import {ValidateUser} from '../../BusinessLogic/UserManagement';
import { useNavigate, useLocation } from "react-router-dom";

import {
  Button ,
  Authenticator,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TextAreaField,
  TextField,
  Card,
  Image,
  View,
  Flex,
  Text,
  SelectField

} from '@aws-amplify/ui-react';
import styles from './ProductDetail.module.css';
import styled from 'styled-components'
import {get_uuidv4} from '../../BusinessLogic/uuidv4';


function ErrorFallback({error, resetErrorBoundary}) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

const LabelText = styled.div`
                        color: white;
                        font-size: 0.5em !important;
                        `;


const ProductDetail = () => {      

        const client = generateClient();

        const vBreadCrumbItems = [{Path: "/", Title:"Home", isCurrent:false},{Path: "/MainMenuV2", Title:"Menu", isCurrent:false},{Path: "/ProductManagement", Title:"Products", isCurrent:false},{Path: "/ProductDetails", Title:"Product", isCurrent:true}]
     
        let debugConsoleOutPut = true
        let navigate = useNavigate(); 

        const location = useLocation();
        const { vRhyteItProductManagementRow } = location.state;


        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   
        const [RedoImageChecked, setRedoImageChecked] = useState("")  
        const [vCreationDateTime, setvCreationDateTime] = useState( vRhyteItProductManagementRow.CreationDateTime.toLocaleString("en-US"))  
        const [RecordUpdated, setRecordUpdated] = useState(false)  
        const [PaymentMode, setPaymentMode] = useState(vRhyteItProductManagementRow.PaymentMode)  
        const [SuccessURL, setSuccessURL] = useState(vRhyteItProductManagementRow.SuccessURL)  
        const [CancelURL, setCancelURL] = useState(vRhyteItProductManagementRow.CancelURL)  
        const [ProductImage, setProductImage] = useState(vRhyteItProductManagementRow.ProductImage)  
        const [ProductType, setProductType] = useState(vRhyteItProductManagementRow.ProductType)  
        const [SocialMedia, setSocialMedia] = useState(vRhyteItProductManagementRow.SocialMedia)  
        const [DeliveryMethod, setDeliveryMethod] = useState(vRhyteItProductManagementRow.DeliveryMethod)  
        
        
        
 

        const {Users, setUsers} = useContext(UserContext);   
        const [RhyteItProduct, setRhyteItProduct] = useState(vRhyteItProductManagementRow)  

        useEffect(() => {

          (async function() {
            try {  


              if (Users === undefined) {                    
                //console.log("ProductManagement.useEffect[].Users:  ",Users)
                 let vIsUserSubscribed = await ValidateUser()
                 setUsers(vIsUserSubscribed)
              }
                //console.log("ProductManagement.useEffect[].Users:  ",Users)          


                } catch (err) {
                  console.error('RhyteItProducts.useEffect[].ERROR: ', err, err.stack); 
              }
            }
          )();   
      

        },[]) 

        useEffect(() => {
            
          console.log("ProductDetail.useEffect[RhyteItProduct].RhyteItProduct:  ",RhyteItProduct)       

        },[RhyteItProduct]) 
        

        function Product_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("ProductDetail.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("ProductDetail.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItProduct.Product = event.target.value
             // //console.log("ProductDetail.Subscribed_handleChange.RhyteItProduct[elementPos].Subscribed: ",RhyteItProduct[elementPos].Subscribed)
    
              setRhyteItProduct(RhyteItProduct)   
    
     
    
            } catch (err) { 
              console.error("ProductDetail.Product_handleChange.Error:  ".err, err.stack); 
          }        
        }      
        
        function Description_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("ProductDetail.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("ProductDetail.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItProduct.Description = event.target.value
             // //console.log("ProductDetail.Subscribed_handleChange.RhyteItProduct[elementPos].Subscribed: ",RhyteItProduct[elementPos].Subscribed)
    
              setRhyteItProduct(RhyteItProduct)   
    
     
    
            } catch (err) { 
              console.error("ProductDetail.Description_handleChange.Error:  ".err, err.stack); 
          }        
        }       
         
        function PriceId_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("ProductDetail.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("ProductDetail.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItProduct.PriceId = event.target.value
             // //console.log("ProductDetail.Subscribed_handleChange.RhyteItProduct[elementPos].Subscribed: ",RhyteItProduct[elementPos].Subscribed)
    
              setRhyteItProduct(RhyteItProduct)   
    
     
    
            } catch (err) { 
              console.error("ProductDetail.PriceId_handleChange.Error:  ".err, err.stack); 
          }        
        }       
        
        function ProductURLParam_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("ProductDetail.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("ProductDetail.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItProduct.ProductURLParam = event.target.value
             // //console.log("ProductDetail.Subscribed_handleChange.RhyteItProduct[elementPos].Subscribed: ",RhyteItProduct[elementPos].Subscribed)
    
              setRhyteItProduct(RhyteItProduct)   
    
     
    
            } catch (err) { 
              console.error("ProductDetail.ProductURLParam_handleChange.Error:  ".err, err.stack); 
          }        
        }       
        
        function Price_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("ProductDetail.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("ProductDetail.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItProduct.Price = event.target.value
             // //console.log("ProductDetail.Subscribed_handleChange.RhyteItProduct[elementPos].Subscribed: ",RhyteItProduct[elementPos].Subscribed)
    
              setRhyteItProduct(RhyteItProduct)   
    
     
    
            } catch (err) { 
              console.error("ProductDetail.Price_handleChange.Error:  ".err, err.stack); 
          }        
        }             

        function FileURL_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
              console.log("ProductDetail.FileURL_handleChange.event.target.value: ", event.target.value)
             //   //console.log("ProductDetail.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItProduct.FileURL = event.target.value
              console.log("ProductDetail.FileURL_handleChange.RhyteItProduct.FileURLd: ",RhyteItProduct.FileURL)
    
              setRhyteItProduct(RhyteItProduct)   
    
     
    
            } catch (err) { 
              console.error("ProductDetail.FileURL_handleChange.Error:  ".err, err.stack); 
          }        
        }          

        async function SaveRhyteItProduct(event, data) {
          try {           
            
              setErrorMessage("")
              let updateProductsResponse = "" 
              
              //console.log("ProductDetail.SaveRhyteItProduct.RhyteItProduct.FileURL: ",RhyteItProduct.FileURL)    

              var vUpdateDateTime = new Date().toISOString()
      
              RhyteItProduct.UpdateDateTime = vUpdateDateTime       
              RhyteItProduct.UpdateName = Users
  
              var DynamoUUID =  get_uuidv4()
    
              let RhyteItProductItem = {
                  "id": RhyteItProduct.id,
                  "CancelURL": RhyteItProduct.CancelURL,
                  "CreationDateTime": RhyteItProduct.CreationDateTime,
                  "CreationName": RhyteItProduct.CreationName,
                  "Description": RhyteItProduct.Description,
                  "Environment": RhyteItProduct.Environment,
                  "PaymentMode": RhyteItProduct.PaymentMode,
                  "DeliveryMethod":  RhyteItProduct.DeliveryMethod,
                  "PriceId": RhyteItProduct.PriceId,
                  "Price": RhyteItProduct.Price,
                  "Product": RhyteItProduct.Product,
                  "ProductImage": RhyteItProduct.ProductImage,
                  "ProductURLParam": RhyteItProduct.ProductURLParam,
                  "SuccessURL": RhyteItProduct.SuccessURL,
                  "UpdateDateTime": RhyteItProduct.UpdateDateTime,
                  "UpdateName": RhyteItProduct.UpdateName,
                  "UserId": RhyteItProduct.UserId,
                  "ProductType": RhyteItProduct.ProductType,
                  "FileURL": RhyteItProduct.FileURL
              }


    
              if (debugConsoleOutPut === true) {
              
                //console.log("ProductDetail.SaveRhyteItProduct.RhyteItProductItem: ",RhyteItProductItem)
    
              }


              if (RhyteItProductItem.id === "NEW") {
                RhyteItProductItem.id = DynamoUUID
                
                const updateProductsResponse = await client.graphql({
                  query: createProducts,
                  variables: {
                    input: RhyteItProductItem
                  }
                });  

                setRhyteItProduct(updateProductsResponse.data.createProducts)
                setErrorMessage(ErrorMessageStr)
    
              } else {
    

                const updateProductsResponse = await client.graphql({
                  query: updateProducts,
                  variables: {
                    input: RhyteItProductItem
                  }
                });  

                setRhyteItProduct(updateProductsResponse.data.updateProducts)
                setErrorMessage(ErrorMessageStr)
    
            }

        
              if (debugConsoleOutPut === true) {
              
                //console.log("ProductDetail.SaveRhyteItProduct.updateProductsResponse: ",updateProductsResponse)
    
              }

              setRecordUpdated(true)
              //ErrorMessageStr = "Product saved at: " + vUpdateDateTime.toLocaleString("en-US")
              setErrorMessage("")
         
    
            } catch (err) { 
              console.error(err)
              ErrorMessageStr = ("ProductDetail.SaveRhyteItProduct.Error: ", err, err.stack)
              console.error(ErrorMessageStr)
              setErrorMessage(ErrorMessageStr)
          }        
        }     
        
        async function DeleteRhyteItProduct(event,data) {
          try {
    

    
              const DeleteRhyteItProducts = {
                id: RhyteItProduct.id,
                _version: RhyteItProduct._version
              }
             
              const ReturnMessage = await client.graphql({
                query: deleteProducts,
                variables: {
                  input: DeleteRhyteItProducts
                }
              });                
    
              setRhyteItProduct([])
    
              setErrorMessage("Deleted")         
                    
              } catch (err) { 
    
              ErrorMessageStr = "ProductDetail.DeleteRhyteItProduct.Error: " + err + " " + err.stack
    
              console.error(ErrorMessageStr); 
              
              setErrorMessage(ErrorMessageStr)         
            }               
          
        }           

        function GoToAccounts()  {

          navigate('/ProductManagement', 
          {
            state: {
                RedditUser:Users
            }
          })

        }      

        function PaymentModeSelected(pPaymentMode) {
          try {
   

                    //console.log("ReportSelected.pReport: ",pReport);

                    RhyteItProduct.PaymentMode = pPaymentMode
                    setRhyteItProduct(RhyteItProduct)
                    setPaymentMode(pPaymentMode)
     
                    console.log("ReportSelected.PaymentModeSelected.pPaymentMode: ",pPaymentMode); 
    
          } catch (err) { 
                          console.error('ProductDetail.PaymentModeSelected.ERROR: ', err, err.stack); 
                      }
        }   
                
        function DeliveryMethodSelected(pDeliveryMethod) {
          try {
   

                    //console.log("ReportSelected.pReport: ",pReport);

                    RhyteItProduct.DeliveryMethod = pDeliveryMethod
                    setRhyteItProduct(RhyteItProduct)
                    setDeliveryMethod(pDeliveryMethod)
     
                    console.log("ReportSelected.DeliveryMethodSelected.pDeliveryMethod: ",pDeliveryMethod); 
    
          } catch (err) { 
                          console.error('ProductDetail.DeliveryMethodSelected.ERROR: ', err, err.stack); 
                      }
        }           
           
        function ProductTypeSelected(pProductType) {
          try {
   

                    //console.log("ReportSelected.pReport: ",pReport);

                    RhyteItProduct.ProductType = pProductType
                    setRhyteItProduct(RhyteItProduct)
                    setProductType(pProductType)
     
                    //console.log("ReportSelected.DashboardHighlevel.length: ",DashboardHighlevel.length); 
    
          } catch (err) { 
                          console.error('ProductDetail.ProductTypeSelected.ERROR: ', err, err.stack); 
                      }
        }  
        
        function SocialMediaSelected(pSocialMedia) {
          try {
   

                    //console.log("ReportSelected.pReport: ",pReport);

                    RhyteItProduct.SocialMedia = pSocialMedia
                    setRhyteItProduct(RhyteItProduct)
                    setSocialMedia(pSocialMedia)
     
                    //console.log("ReportSelected.DashboardHighlevel.length: ",DashboardHighlevel.length); 
    
          } catch (err) { 
                          console.error('ProductDetail.SocialMediaSelected.ERROR: ', err, err.stack); 
                      }
        }    
        
        function SuccessURLSelected(pSuccessURL) {
          try {
   

                    //console.log("ReportSelected.pReport: ",pReport);

                    RhyteItProduct.SuccessURL = pSuccessURL
                    setRhyteItProduct(RhyteItProduct)
                    setSuccessURL(pSuccessURL)
     
                    //console.log("ReportSelected.DashboardHighlevel.length: ",DashboardHighlevel.length); 
    
          } catch (err) { 
                          console.error('ProductDetail.SuccessURLSelected.ERROR: ', err, err.stack); 
                      }
        }       
    
        function CancelURLSelected(pCancelURL) {
          try {
   

                    //console.log("ReportSelected.pReport: ",pReport);

                    RhyteItProduct.CancelURL = pCancelURL
                    setRhyteItProduct(RhyteItProduct)
                    setCancelURL(pCancelURL)
     
                    //console.log("ReportSelected.DashboardHighlevel.length: ",DashboardHighlevel.length); 
    
          } catch (err) { 
                          console.error('ProductDetail.CancelURLSelected.ERROR: ', err, err.stack); 
                      }
        }        
        
        function ProductImageSelected(pProductImage) {
          try {
   

                   // console.log("ProductDetail.ProductImageSelected.pReport: ",pProductImage);
                    RhyteItProduct.ProductImage = pProductImage
                    setRhyteItProduct(RhyteItProduct)
                    setProductImage(pProductImage)
     
                    console.log("ProductDetail.ProductImageSelected.RhyteItProduct: ",RhyteItProduct); 
    
          } catch (err) { 
                          console.error('ProductDetail.ProductImageSelected.ERROR: ', err, err.stack); 
                      }
        }          

    return ( 
    <m.div
      key="RhyteItProductDetail"
      initial={{y: "100%"}}
      animate={{y: "0%"}}
      transition={{duration:0.75, ease: "easeOut"}}
      exit={{ opacity: 1 }}
     >
      <Authenticator  className={styles.AuthenticatorLogin} socialProviders={[]} hideSignUp={true}>
        {({ signOut, user }) => (   
          <div className={styles.SystemAdminBackground}>
            <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                >   
                    <div  className={styles.GridContainer}>       
                      <Header Title="Product" SubTitle={RhyteItProduct.Product} BreadCrumbItems ={vBreadCrumbItems} pageSource="ProductManagementDetail"/>       
                        <div className={styles.FormContainer}>
                          <View
                            className={styles.View}
                            width="100%"
                          >
                            <Card className={styles.Card} width="100%">
                              <Flex direction="row" alignItems="flex-start">
                                <Flex
                                  direction="column"
                                  alignItems="flex-start"
                                  gap="3em"
                                >
                                <Flex
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    alignContent="center"
                                    wrap="wrap" 
                                    gap="1rem"
                                    className={styles.FlexRow}
                                    >               
                                    <Text   
                                      className={styles.TextFieldHeaderLabel}
                                      >
                                        Created:
                                    </Text>
                                    <Text size="small" className={styles.Text}>
                                      {new Date( RhyteItProduct.CreationDateTime).toLocaleString("en-US")}
                                    </Text>    
                                    <Text   
                                      className={styles.TextFieldHeaderLabel}
                                      >
                                        Updated:
                                    </Text>
                                    <Text size="small" className={RecordUpdated === false ? styles.Text :styles.TextUpdated  }>
                                      {new Date( RhyteItProduct.UpdateDateTime).toLocaleString("en-US")}
                                    </Text>
                                  </Flex>
                                  <Table>
                                    <TableBody>
                                      <TableRow className={styles.TableRow}>
                                        <TableCell className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              Environment
                                          </Text>
                                        </TableCell>
                                        <TableCell>
                                        <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              {RhyteItProduct.Environment}
                                          </Text>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow className={styles.TableRow}>
                                        <TableCell className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              Product
                                          </Text>
                                        </TableCell>
                                        <TableCell>
                                          <TextField className={styles.TextField} 
                                            name="RhyteItProduct.Product"                           
                                            defaultValue={RhyteItProduct.Product}
                                            onChange={ (e) => Product_handleChange(e, RhyteItProduct) } />
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              Description
                                          </Text>   
                                        </TableCell>
                                        <TableCell>    
                                        <TextAreaField 
                                            className={styles.TextAreaField} 
                                            name="RhyteItProduct.Description"     
                                            placeholder="Enter a description of product"                   
                                            defaultValue={RhyteItProduct.Description}
                                            onChange={ (e) => Description_handleChange(e, RhyteItProduct) }
                                            rows={3}/>                                                       
                                        </TableCell>
                                      </TableRow>  
                                      <TableRow className={styles.TableRow}>
                                        <TableCell  className={styles.RowLabelColumn} >         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              Product URL Param
                                          </Text>
                                        </TableCell>
                                        <TableCell>
                                          <TextField className={styles.TextField} 
                                            name="RhyteItProduct.ProductURLParam"                           
                                            defaultValue={RhyteItProduct.ProductURLParam}
                                            onChange={ (e) => ProductURLParam_handleChange(e, RhyteItProduct) } />
                                        </TableCell>
                                      </TableRow>                                                                            
                                      <TableRow className={styles.TableRow}>
                                        <TableCell  className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              Payment Mode
                                          </Text>
                                        </TableCell>
                                        <TableCell>
                                              <SelectField 
                                                  className={styles.SelectField}
                                                  value={PaymentMode}
                                                  placeholder="Select Payment Mode"
                                                  onChange={(e) => PaymentModeSelected(e.target.value)}>
                                                  <option 
                                                  className={styles.SelectFieldOption} value="free" label="Free">
                                                    Free
                                                  </option>
                                                  <option 
                                                  className={styles.SelectFieldOption} value="payment" label="One time payment">
                                                    One time payment
                                                  </option>
                                                  <option value="subscription" label="Monthly subscription">
                                                  Monthly subscription
                                                  </option>
                                              </SelectField>                                            
                                        </TableCell>
                                      </TableRow >
                                      <TableRow className={styles.TableRow}>
                                        <TableCell >         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              Stripe Price ID
                                          </Text>
                                        </TableCell>
                                        <TableCell>
                                          <TextField className={styles.TextField} 
                                            name="RhyteItProduct.PriceId"                           
                                            defaultValue={RhyteItProduct.PriceId}
                                            onChange={ (e) => PriceId_handleChange(e, RhyteItProduct) } />
                                        </TableCell>
                                      </TableRow>
                                      <TableRow className={styles.TableRow}>
                                        <TableCell  className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              Price
                                          </Text>
                                        </TableCell>
                                        <TableCell>
                                          <TextField className={styles.TextField} 
                                            name="RhyteItProduct.Price"                           
                                            defaultValue={RhyteItProduct.Price}
                                            onChange={ (e) => Price_handleChange(e, RhyteItProduct) } />
                                        </TableCell>
                                      </TableRow>
                                      <TableRow className={styles.TableRow}>
                                        <TableCell  className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              Success URL
                                          </Text>
                                        </TableCell>
                                        <TableCell>
                                            <SelectField 
                                                  className={styles.SelectField}
                                                  value={SuccessURL}
                                                  placeholder="Select Success URL"
                                                  onChange={(e) => SuccessURLSelected(e.target.value)}>
                                                  <option value="https://www.rhyteit.com/SMMADSetup?session_id={CHECKOUT_SESSION_ID}" label="TurboFanBoost - Prod">
                                                  </option>
                                                  <option value="https://localhost:3000/SMMADSetup?session_id={CHECKOUT_SESSION_ID}" label="TurboFanBoost - Test">
                                                  </option>
                                                  <option value="https://www.rhyteit.com/DigitalMarketPlaceDetailSuccess?session_id={CHECKOUT_SESSION_ID}" label="Excel Download - Prod">
                                                  </option>
                                                  <option value="https://localhost:3000/DigitalMarketPlaceDetailSuccess?session_id={CHECKOUT_SESSION_ID}" label="Excel Download - Test">
                                                  </option>
                                              </SelectField>     
                                        </TableCell>
                                      </TableRow>
                                      <TableRow className={styles.TableRow}>
                                        <TableCell  className={styles.RowLabelColumn} >         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              Cancel URL
                                          </Text>
                                        </TableCell>
                                        <TableCell>
                                            <SelectField 
                                                  className={styles.SelectField}
                                                  value={CancelURL}
                                                  placeholder="Select Cancel URL"
                                                  onChange={(e) => CancelURLSelected(e.target.value)}>
                                                  <option value="https://www.rhyteit.com/SMMADSetup?session_id={CHECKOUT_SESSION_ID}" label="TurboFanBoost - Prod">
                                                  </option>
                                                  <option value="https://localhost:3000/SMMADSetup?session_id={CHECKOUT_SESSION_ID}" label="TurboFanBoost - Test">
                                                  </option>
                                                  <option value="https://www.rhyteit.com/DigitalMarketPlaceDetailSuccess?session_id={CHECKOUT_SESSION_ID}" label="Excel Download - Prod">
                                                  </option>
                                                  <option value="https://localhost:3000/DigitalMarketPlaceDetailSuccess?session_id={CHECKOUT_SESSION_ID}" label="Excel Download - Test">
                                                  </option>
                                              </SelectField>     
                                        </TableCell>
                                      </TableRow>
                                      <TableRow className={styles.TableRow}>
                                        <TableCell  className={styles.RowLabelColumn} >         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              Product Image
                                          </Text>
                                        </TableCell>
                                        <TableCell>
                                            <SelectField 
                                                  className={styles.SelectField}
                                                  value={ProductImage}
                                                  placeholder="Select Product Image"
                                                  onChange={(e) => ProductImageSelected(e.target.value)}>
                                                  <option value="https://rhyteiticons.s3.us-west-2.amazonaws.com/DigitalProductLogos/OFTwitterRIRankings.svg" label="OF X RI">
                                                  </option>
                                                  <option value="https://rhyteiticons.s3.us-west-2.amazonaws.com/DigitalProductLogos/TurboFanBoost.png" label="TurbFanBoost">
                                                  </option>
                                                  
                                                  <option value="https://rhyteiticons.s3.us-west-2.amazonaws.com/AllAccess.svg" label="All Access">
                                                  </option>
                                                  <option value="https://rhyteiticons.s3.us-west-2.amazonaws.com/OFEngagementScores.svg" label="OF Engagement Scores">
                                                  </option>
                                                  <option value="https://rhyteiticons.s3.us-west-2.amazonaws.com/OFTwitterAccounts.svg" label="OF Twitter Accounts">
                                                  </option>
                                                  <option value="https://rhyteiticons.s3.us-west-2.amazonaws.com/RedditLikes.svg" label="Reddit Likes">
                                                  </option>
                                                  <option value="https://rhyteiticons.s3.us-west-2.amazonaws.com/RedditFollowing.svg" label="Reddit Following">
                                                  </option>
                                                  <option value="https://rhyteiticons.s3.us-west-2.amazonaws.com/RedditReposts.svg" label="Reddit Reposts">
                                                  </option>
                                                  <option value="https://rhyteiticons.s3.us-west-2.amazonaws.com/RedditorsNSFW.svg" label="Redditors NSFW">
                                                  </option>
                                                  <option value="https://rhyteiticons.s3.us-west-2.amazonaws.com/SubredditsNSFW.svg" label="Subreddit NSFW">
                                                  </option>
                                                  <option value="https://rhyteiticons.s3.us-west-2.amazonaws.com/TwitterLikes.svg" label="X Likes">
                                                  </option>
                                                  <option value="https://rhyteiticons.s3.us-west-2.amazonaws.com/TwitterReposts.svg" label="X Reposts">
                                                  </option>
                                                  <option value="https://rhyteiticons.s3.us-west-2.amazonaws.com/TwiitterFollowing.svg" label="X Following">
                                                  </option>
                                              </SelectField> 
                                        </TableCell>
                                      </TableRow>
                                      <TableRow className={styles.TableRow}>
                                        <TableCell  className={styles.RowLabelColumn} >         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              Product Type
                                          </Text>
                                          
                                        </TableCell>
                                        <TableCell>
                                            <SelectField 
                                                  className={styles.SelectField}
                                              value={ProductType}
                                              placeholder="Select Product Type"
                                              onChange={(e) => ProductTypeSelected(e.target.value)}>
                                              <option value="All" label="All">
                                              </option>
                                              <option value="Posting" label="Posting">
                                              </option>
                                              <option value="Excel" label="Excel">
                                              </option>
                                              <option value="Following" label="Following">
                                              </option>
                                              <option value="Likes" label="Likes">
                                              </option>
                                              <option value="Reposts" label="Reposts">
                                              </option>
                                            </SelectField>                                                
                                        </TableCell>
                                      </TableRow>
                                      <TableRow className={styles.TableRow}>
                                        <TableCell  className={styles.RowLabelColumn} >         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              Delivery Method
                                          </Text>
                                          
                                        </TableCell>
                                        <TableCell>
                                            <SelectField 
                                                  className={styles.SelectField}
                                              value={DeliveryMethod}
                                              placeholder="Select Delivery Method"
                                              onChange={(e) => DeliveryMethodSelected(e.target.value)}>
                                              <option value="Email" label="Email">
                                              </option>
                                              <option value="SMS" label="SMS">
                                              </option>
                                              <option value="Website Access" label="Website Access">
                                              </option>
                                              <option value="AWS" label="AWS">
                                              </option>
                                              <option value="N/A" label="N/A">
                                              </option>
                                            </SelectField>                                                
                                        </TableCell>
                                      </TableRow>
                                      <TableRow className={styles.TableRow}>
                                        <TableCell  className={styles.RowLabelColumn} >         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              Social Media
                                          </Text>
                                          
                                        </TableCell>
                                        <TableCell>
                                            <SelectField 
                                                  className={styles.SelectField}
                                              value={SocialMedia}
                                              placeholder="Select Social Media"
                                              onChange={(e) => SocialMediaSelected(e.target.value)}>
                                              <option value="All" label="All">
                                              All
                                              </option>
                                              <option value="X/Twitter" label="X/Twitter">
                                              Twitter
                                              </option>
                                              <option value="Reddit" label="Reddit">
                                              Reddit
                                              </option>
                                              <option value="Instagram" label="Instagram">
                                              Instagram
                                              </option>
                                              <option value="Telegram" label="Telegram">
                                              Telegram
                                              </option>
                                            </SelectField>                                                
                                        </TableCell>
                                      </TableRow>
                                      <TableRow className={styles.TableRow}>
                                        <TableCell  className={styles.RowLabelColumn} >         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              File URL
                                          </Text>
                                        </TableCell>
                                        <TableCell>
                                          <TextField className={styles.TextField} 
                                            name="RhyteItProduct.FileURL"                           
                                            defaultValue={RhyteItProduct.FileURL}
                                            onChange={ (e) => FileURL_handleChange(e, RhyteItProduct) } />
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                  <Flex>
                                    <Button className={styles.RedditButtons}                                       
                                            onClick={ (e) => SaveRhyteItProduct(e, RhyteItProduct) }
                                            variation="primary">Save</Button>
                                    <Button className={styles.RedditButtons}                                       
                                            onClick={ (e) => DeleteRhyteItProduct(e, RhyteItProduct) }
                                            variation="primary">Delete</Button>
                                    <Button className={styles.RedditButtons}                                       
                                            onClick={ (e) => GoToAccounts(e, RhyteItProduct) }
                                            variation="primary">Back</Button>
                                  </Flex>                                      
                              
                                </Flex>
                              </Flex>
                            </Card>
                          </View>           
                        </div>
                        <div ><Text className={styles.MessageBox}>{ErrorMessage}</Text></div>                            
                      </div>  
            </ErrorBoundary>
          </div>   
        )}         
      </Authenticator>  
    </m.div>                      

    )

  }
 

export default ProductDetail;