import PropTypes from "prop-types";
import React, { useState, useEffect, Suspense, useRef, useMemo  } from "react";
import { motion, useAnimate } from "framer-motion";
import styles from "./LetsTalkBackgroundV2.module.css";
import useWindow from "../../../BusinessLogic/Hooks/useWindow";
import { Canvas, extend, useThree, useLoader, useFrame } from "@react-three/fiber";
import { Water } from 'three-stdlib'
import * as THREE from 'three'
import { OrbitControls, Sky } from '@react-three/drei'

const LetsTalkBackgroundV2 = (props) => {
  LetsTalkBackgroundV2.propTypes = {
    InitiateBackGround: PropTypes.bool,
    scrollYProgress: PropTypes.object,
  };

  const { dimension } = useWindow();
  var vDirection = "Forward";

  const Cube = ({ position, size, color }) => {
    const ref = useRef();

    useFrame((state, delta) => {
      ref.current.position.z = Math.sin(state.clock.elapsedTime) * 2;
    });

    return (
      <mesh position={position} ref={ref}>
        <boxGeometry args={size} />
        <meshStandardMaterial color={color} />
      </mesh>
    );
  };

  const Sphere = ({ position, size, color }) => {
    const ref = useRef();

    useFrame((state, delta) => {
      ref.current.position.x = Math.sin(state.clock.elapsedTime) * 2;
      ref.current.position.y = Math.sin(state.clock.elapsedTime) * 2;
    });

    return (
      <mesh position={position} ref={ref}>
        <sphereGeometry args={size} />
        <meshStandardMaterial color={color} />
      </mesh>
    );
  };

  const Sphere1 = ({ position, size, color }) => {
    const ref = useRef();

    useFrame((state, delta) => {
      ref.current.position.y = Math.sin(state.clock.elapsedTime);
      ref.current.position.z = Math.sin(state.clock.elapsedTime);
    });

    return (
      <mesh position={position} ref={ref}>
        <sphereGeometry args={size} />
        <meshStandardMaterial color={color} />
      </mesh>
    );
  };

  const Sphere2 = ({ position, size, color }) => {
    const ref = useRef();

    useFrame((state, delta) => {
      ref.current.position.x= Math.sin(state.clock.elapsedTime)*100;
    });

    return (
      <mesh position={position} ref={ref}>
        <sphereGeometry args={size} />
        <meshStandardMaterial color={color} />
      </mesh>
    );
  };


  const Sphere3 = ({ position, size, color }) => {
    const ref = useRef();

    useFrame((state, delta) => {
      ref.current.rotation.x += delta*2;
      ref.current.rotation.y += delta;
      ref.current.position.z = Math.sin(state.clock.elapsedTime)*100;
    });

    return (
      <mesh position={position} ref={ref}>
        <sphereGeometry args={size} />
        <meshStandardMaterial color={color} />
      </mesh>
    );
  };

  useEffect(() => {



  }, []);

  useEffect(() => {}, [dimension]);

  extend({ Water })

function Ocean() {
  const ref = useRef()
  const gl = useThree((state) => state.gl)
 // const waterNormals = useLoader(THREE.TextureLoader, './images/waternormals.jpeg')
  const waterNormals = useLoader(THREE.TextureLoader, './images/waternormals.jpeg')
  waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping
  const geom = useMemo(() => new THREE.PlaneGeometry(10000, 10000), [])
  const config = useMemo(
    () => ({
      textureWidth: 512,
      textureHeight: 512,
      waterNormals,
      sunDirection: new THREE.Vector3(),
      sunColor: 0xffffff,
      waterColor: 0x001e0f,
      distortionScale: 1.7,
      fog: true,
      format: gl.encoding
    }),
    [waterNormals]
  )
  useFrame((state, delta) => (ref.current.material.uniforms.time.value += delta))
  return <water ref={ref} args={[geom, config]} rotation-x={-Math.PI / 2} />
}


  return (
    <div className={styles.BackgroundContainer}>
      <Canvas camera={{ position: [20, 40, 100], fov: 55, near: 1, far: 20000 }}>
        <pointLight  position={[10, 10, 0]} intensity={100} color="#FFCC99" distance={100}/>   
        <pointLight  position={[-30, 20, 10]} intensity={100} color="#CCCCFF" distance={100}/>   
        <Suspense fallback={null}>
          <Ocean />
          <Sphere position={[10, 10, 0]} color="#CCCBCA" size={[3.25, 64, 32, 0, Math.PI*2, 0, Math.PI]} />
          <Sphere1 onClick={(e) => console.log("click")} position={[20, 20, 0]} color="#FFCC99" size={[3.0, 64, 32, 0, Math.PI*2, 0, Math.PI]} />
          <Sphere2 position={[30, 10, -10]} color="#CCCCFF" size={[3.0, 64, 32, 0, Math.PI*2, 0, Math.PI]} />  
          <Sphere3 
   position={[-40, 20, 0]} color="#99FFFF" size={[3.25, 64, 32, 0, Math.PI*2, 0, Math.PI]} />   
        </Suspense>
        <OrbitControls />
      </Canvas>
    </div>
  );
};

export default LetsTalkBackgroundV2;
