import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { motion, useAnimate } from "framer-motion";
import styles from "./BackgroundAlexJanvier.module.css";
import useWindow from "../../BusinessLogic/Hooks/useWindow";
import { Flex, Image } from "@aws-amplify/ui-react";

const BackgroundAlexJanvier = (props) => {
  BackgroundAlexJanvier.propTypes = {
    InitiateBackGround: PropTypes.bool,
    scrollYProgress: PropTypes.object,
  };

  var ErrorMessageStr = "";
  const { dimension } = useWindow();

  const [FramerAnimationConfigs, setFramerAnimationConfigs] = useState([]);
  const vCanvas = document.getElementById("lasercanvas");
  const [vLaserTotal, setvLaserTotal] = useState(100);

  
  const [vInitiateBackGround, setvInitiateBackGround] = useState(props.InitiateBackGround);

  let LaserLineArray = [];

  useEffect(() => {
    
  }, []);


  
  return ( 
    <div className={styles.background} > 
      <div className={styles.overlay} ></div>
      <div className={styles.content} >
          <h1>Alex Janvier Inspired Background</h1>
          <p>Experience the vibrant and swirling colors of Janvier's art</p>
      </div>
    </div>  
)
};

export default BackgroundAlexJanvier;
