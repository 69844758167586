
import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead
  } from '@aws-amplify/ui-react'; 
import SMMADBatchJobRow from './SMMADBatchJobRow';
import styles from './SMMADBatchJobsTable.module.css';


const SMMADBatchJobTable = (props) => {       

  SMMADBatchJobTable.propTypes = {
      SMBatchJobs: PropTypes.array,
      RhyteItUser: PropTypes.array
      };

    let debugConsoleOutPut = true

  

    const [SMBatchJobs, setSMBatchJobs] = useState(props.SMBatchJobs)   
    const [ShowTable, setShowTable] = useState(false);

    useEffect(() => {


    },[])  

    useEffect(() => {

      //console.log("SMMADBatchJobTable.useEffect[].props.SMBatchJobs:  ",props.SMBatchJobs)
      //console.log("SMMADBatchJobTable.useEffect[].SMBatchJobs ",SMBatchJobs) 
      //console.log("vBatchJobRows.useEffect[].props.RhyteItUser:  ",props.RhyteItUser) 
      //console.log("vBatchJobRows.useEffect[].Time:  ", BatchJobRow.PostDateTime.substr(BatchJobRow.PostDateTime.indexOf("T")+1, 5))
      //onsole.log("vBatchJobRows.useEffect[].indexOf:  ", BatchJobRow.PostDateTime.indexOf(":00"))
      if (SMBatchJobs.length > 0) {
        setShowTable(true)
      }

    },[SMBatchJobs])      



  return (  
      <Table className={styles.Table}   highlightOnHover={true}>   
          <TableHead>          
            <TableRow className={styles.TableHeaderRow}>
              <TableCell className={styles.TableHeaderRowCells} >Name</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >JobJSON</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Status</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Date/Time</TableCell> 
              <TableCell className={styles.TableHeaderRowCellsActions} colspan="2" >Actions</TableCell> 
            </TableRow>                                      
          </TableHead>       
          
              {
                ShowTable && SMBatchJobs.length > 0 &&  SMBatchJobs && SMBatchJobs.map((BatchJobRow) => (                      
                  (<SMMADBatchJobRow   key={BatchJobRow.id}  SMMADBatchJobRow={BatchJobRow}/>)        
                  ))
              }                    
        </Table>
    
  )
}

export default SMMADBatchJobTable;


