import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import {motion as m} from "framer-motion";

import {
Flex,
Image,
MenuButton,
Menu,
MenuItem,
Card,
View,
Heading,
Badge,
Text,
Button,

} from '@aws-amplify/ui-react';
import styles from './OFProfileCard.module.css';
import { SelectedProfileContext} from '../../BusinessLogic/Hooks/ACCProfileContext';
import {RhyteItLogging} from '../../BusinessLogic/UserManagement';
import { UserContext, CognitoGroupContext} from '../../BusinessLogic/Hooks/UserContext';

const OFProfileCard = (props) => {       
  
  OFProfileCard.propTypes = {
          OFProfile: PropTypes.object,
          ClickedImage: PropTypes.string,
          onClick: PropTypes.func
        };



        const [OFProfile, setOFProfile] = useState(props.OFProfile)  
        const [ClickedImage, setClickedImage] = useState(props.ClickedImage)   
        const {Users, setUsers} = useContext(UserContext);    
              

        const vSelectedProfile = useContext(SelectedProfileContext);

        useEffect(() => {

         
      

        },[]) 

        function OpenPostURL(event,pPostURL) {
          try {
    
              if (pPostURL.length > 0) {
                window.open(pPostURL, '_blank');
              }
            } catch (err) { 
    
            console.error(err); 
             
          }        
        }  


        function OpenTwitterURL(event,pTwitterHandle) {
          try {
    
         //   console.log("OFProfileCard.OpenTwitterURL.pTwitterHandle:  ",pTwitterHandle) 
    
              let vTwitterURL = "https://twitter.com/" + pTwitterHandle
              if (pTwitterHandle.length > 0) {
                window.open(vTwitterURL, '_blank');
              } 
      
              
            } catch (err) { 
    
              RhyteItLogging(Users, "OFProfileCard", "OpenTwitterURL",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                 
          }        
        }          
    return ( 
        <Flex direction="column" 
              alignItems="flex-start"               
              onClick={props.onClick}> 
              {vSelectedProfile === OFProfile.id &&
                (
                  <Heading                   
                  objectFit="initial"
                  objectPosition="50% 50%"
                  className={styles.Heading}>
                      {OFProfile.OnlyFansName}
                </Heading> 
                ) 
              }                                           
              <div
                  style={{background: 'url(' + OFProfile.OnlyFansProfilePictureURL +')'}}
                  className={styles.PhotoImage}
              />       

              {vSelectedProfile === OFProfile.id &&
                (          
              
                  <Flex direction="column" 
                      alignItems="flex-start"  > 
                          <Flex direction="row" alignItems="flex-start"> 
                            <Flex
                              direction="column"
                              alignItems="flex-start"
                              gap="1em"
                            >  
                                
                              <Badge className={styles.BadgeRanking}>
                                RhyteIt Ranking: Top {Math.round(OFProfile.Rank > 0.01 ? OFProfile.Rank*100 : 1,2)}%
                              </Badge>
                              <Badge className={styles.BadgePrice}>
                                Price: {OFProfile.PaidFree > 0 ?  "$ " + OFProfile.PaidFree : "Free"}
                              </Badge> 
                              
                              <Flex direction="row" alignItems="flex-start"> 
                                <Button  
                                  className={styles.button}
                                  onClick={(e) => OpenPostURL(e, OFProfile.OnlyFansProfileURL)} >
                                          <Image
                                            alt="OnlyFans logo"
                                            src="https://rhyteiticons.s3.us-west-2.amazonaws.com/Logos/AccLogos/OnlyFans.svg"
                                            objectFit="initial"
                                            objectPosition="50% 50%"
                                            backgroundColor="initial"
                                            opacity="100%"
                                            className={styles.ImageLogo}
                                          />
                                </Button>      
                                <Button  
                                  className={styles.button}
                                  onClick={(e) => OpenTwitterURL(e, OFProfile.Twitter)} >
                                          <Image
                                            alt="Twitter logo"
                                            src="https://rhyteiticons.s3.us-west-2.amazonaws.com/Logos/Twitter.svg"
                                            objectFit="initial"
                                            objectPosition="50% 50%"
                                            backgroundColor="initial"
                                            opacity="100%"
                                            className={styles.ImageLogo}
                                          />
                                </Button>                 
                              </Flex>            
                            </Flex>
                          </Flex>
                    </Flex>  
                ) 
              } 
        </Flex>             

    )

  }
 

export default OFProfileCard;