import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';


import  { }  from '../../../graphql/queries'
import  { createAudienceGeoInfo, createSMMADPlatforms, updateSMMADPlatforms, deleteSMMADPlatforms}  from '../../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import {ErrorBoundary} from 'react-error-boundary'
import Header from "../../RhyteItComponents/Header/Header";
import {motion as m} from "framer-motion";
import { UserContext} from '../../../BusinessLogic/Hooks/UserContext';
import {ValidateUser} from '../../../BusinessLogic/UserManagement';
import { useNavigate, useLocation } from "react-router-dom";

import {
  Button ,
  Authenticator,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TextAreaField,
  TextField,
  Card,
  Image,
  View,
  Flex,
  Text,
  SelectField

} from '@aws-amplify/ui-react';
import styles from './PlatformDetail.module.css';
import styled from 'styled-components'
import {get_uuidv4} from '../../../BusinessLogic/uuidv4';


function ErrorFallback({error, resetErrorBoundary}) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

const LabelText = styled.div`
                        color: white;
                        font-size: 0.5em !important;
                        `;


const PlatformDetail = () => {      

        const client = generateClient();

        const vBreadCrumbItems = [{Path: "/", Title:"Home", isCurrent:false},{Path: "/MainMenuV2", Title:"Menu", isCurrent:false},{Path: "/SMMADPlatforms", Title:"SMMADPlatforms", isCurrent:false},{Path: "/PlatformDetails", Title:"Platform", isCurrent:true}]
     
        let debugConsoleOutPut = true
        let navigate = useNavigate(); 

        const location = useLocation();
        const { vPlatform } = location.state;


        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   
        const [RecordUpdated, setRecordUpdated] = useState(false)    

        const {Users, setUsers} = useContext(UserContext);   
        const [SMMADPlatform, setSMMADPlatform] = useState(vPlatform)  
        const [Status, setStatus] = useState(vPlatform.SMMADPlatformsStatus)  

        useEffect(() => {

          (async function() {
            try {  


              if (Users === undefined) {                    
                //console.log("SMMADPlatforms.useEffect[].Users:  ",Users)
                 let vIsUserSubscribed = await ValidateUser()
                 setUsers(vIsUserSubscribed)
              }
                //console.log("SMMADPlatforms.useEffect[].Users:  ",Users)          


                } catch (err) {
                  console.error('RhyteItSMMADPlatforms.useEffect[].ERROR: ', err, err.stack); 
              }
            }
          )();   
      

        },[]) 

        useEffect(() => {
            
          console.log("PlatformDetail.useEffect[SMMADPlatform].SMMADPlatform:  ",SMMADPlatform)       

        },[SMMADPlatform]) 
        

        function Platform_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("PlatformDetail.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("PlatformDetail.Subscribed_handleChange.event: ",event)
              }
    
    
              SMMADPlatform.Platform = event.target.value
             // //console.log("PlatformDetail.Subscribed_handleChange.SMMADPlatform[elementPos].Subscribed: ",SMMADPlatform[elementPos].Subscribed)
    
              setSMMADPlatform(SMMADPlatform)   
    
     
    
            } catch (err) { 
              console.error("PlatformDetail.Platform_handleChange.Error:  ".err, err.stack); 
          }        
        }      
       

        async function SaveSMMADPlatform(event, data) {
          try {           
            
              setErrorMessage("")
              let updateSMMADPlatformsResponse = "" 
              
              console.log("PlatformDetail.SaveSMMADPlatform.SMMADPlatform: ",SMMADPlatform)    

              var vUpdateDateTime = new Date().toISOString()
      
              SMMADPlatform.UpdateDateTime = vUpdateDateTime       
              SMMADPlatform.UpdateName = Users
  
              var DynamoUUID =  get_uuidv4()
    
              let SMMADPlatformItem = {
                  "id": SMMADPlatform.id,
                  "Platform": SMMADPlatform.Platform,
                  "SMMADPlatformsStatus": SMMADPlatform.SMMADPlatformsStatus,
                  "CreationDateTime": SMMADPlatform.CreationDateTime,
                  "CreationName": SMMADPlatform.CreationName,
                  "UpdateDateTime": SMMADPlatform.UpdateDateTime,
                  "UpdateName": SMMADPlatform.UpdateName,
              }
    
              if (debugConsoleOutPut === true) {
              
                console.log("PlatformDetail.SaveSMMADPlatform.SMMADPlatformItem: ",SMMADPlatformItem)
    
              }


              if (SMMADPlatformItem.id === "NEW") {
                SMMADPlatformItem.id = SMMADPlatform.Platform
                
                const updateSMMADPlatformsResponse = await client.graphql({
                  query: createSMMADPlatforms,
                  variables: {
                    input: SMMADPlatformItem
                  }
                });  
                
              
                console.log("PlatformDetail.SaveSMMADPlatform.updateSMMADPlatformsResponse: ",updateSMMADPlatformsResponse)

                setSMMADPlatform(updateSMMADPlatformsResponse.data.createSMMADPlatforms)
                setErrorMessage(ErrorMessageStr)
    
              } else {
    

                const updateSMMADPlatformsResponse = await client.graphql({
                  query: updateSMMADPlatforms,
                  variables: {
                    input: SMMADPlatformItem
                  }
                });  

                
                console.log("PlatformDetail.SaveSMMADPlatform.updateSMMADPlatformsResponse: ",updateSMMADPlatformsResponse)

                setSMMADPlatform(updateSMMADPlatformsResponse.data.updateSMMADPlatforms)
                setErrorMessage(ErrorMessageStr)
    
            }

        
              if (debugConsoleOutPut === true) {
              
                //console.log("PlatformDetail.SaveSMMADPlatform.updateSMMADPlatformsResponse: ",updateSMMADPlatformsResponse)
    
              }

              setRecordUpdated(true)
              //ErrorMessageStr = "Platform saved at: " + vUpdateDateTime.toLocaleString("en-US")
              setErrorMessage("")

              navigate('/SMMADPlatforms', 
                {
                }
              );                
         
    
            } catch (err) { 
              console.error(err)
              ErrorMessageStr = ("PlatformDetail.SaveSMMADPlatform.Error: ", err, err.stack)
              console.error(ErrorMessageStr)
              setErrorMessage(ErrorMessageStr)
          }        
        }     
        
        async function DeleteSMMADPlatform(event,data) {
          try {
    

    
              const DeleteRhyteItSMMADPlatforms = {
                id: SMMADPlatform.id,
                _version: SMMADPlatform._version
              }
             
              const ReturnMessage = await client.graphql({
                query: deleteSMMADPlatforms,
                variables: {
                  input: DeleteRhyteItSMMADPlatforms
                }
              });    
                     
              navigate('/SMMADPlatforms', 
              {
              }
            );        
    
              setSMMADPlatform([])
    
              setErrorMessage("Deleted")         
                    
              } catch (err) { 
    
              ErrorMessageStr = "PlatformDetail.DeleteSMMADPlatform.Error: " + err + " " + err.stack
    
              console.error(ErrorMessageStr); 
              
              setErrorMessage(ErrorMessageStr)         
            }          
              
          
        }           

        function GoToPlatforms()  {

          navigate('/SMMADPlatforms', 
          {
            state: {
                RedditUser:Users
            }
          })

        }      

        function SMMADPlatformsStatusSelected(pStatus) {
          try {
   

              console.log("PlatformDetail.SMMADPlatformsStatusSelected.pStatus: ",pStatus);

              SMMADPlatform.SMMADPlatformsStatus = pStatus
              setStatus(pStatus)

              console.log("PlatformDetail.SMMADPlatformsStatusSelected.SMMADPlatform.SMMADPlatformsStatus: ",SMMADPlatform.SMMADPlatformsStatus); 

          } catch (err) { 
                          console.error('PlatformDetail.SMMADPlatformsStatusSelected.ERROR: ', err, err.stack); 
                      }
        }   

    return ( 
    <m.div
      key="SMMADPlatformDetail"
      initial={{y: "100%"}}
      animate={{y: "0%"}}
      transition={{duration:0.75, ease: "easeOut"}}
      exit={{ opacity: 1 }}
     >
      <Authenticator  className={styles.AuthenticatorLogin} socialProviders={[]} hideSignUp={true}>
        {({ signOut, user }) => (   
          <div className={styles.SystemAdminBackground}>
            <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                >   
                    <div  className={styles.GridContainer}>       
                      <Header Title="Platform" SubTitle={SMMADPlatform.Platform} BreadCrumbItems ={vBreadCrumbItems} pageSource="SMMADPlatformDetail"/>       
                        <div className={styles.FormContainer}>
                          <View
                            className={styles.View}
                            width="100%"
                          >
                            <Card className={styles.Card} width="100%">
                              <Flex direction="row" alignItems="flex-start">
                                <Flex
                                  direction="column"
                                  alignItems="flex-start"
                                  gap="2em"
                                >
                                  <Flex>      
                                    <Text   
                                      className={styles.TextFieldHeaderLabel}
                                      >
                                        Created:
                                    </Text>
                                    <Text size="small" className={styles.Text}>
                                      {new Date( SMMADPlatform.CreationDateTime).toLocaleString("en-US")}
                                    </Text>    
                                    <Text   
                                      className={styles.TextFieldHeaderLabel}
                                      >
                                        Updated:
                                    </Text>
                                    <Text size="small" className={RecordUpdated === false ? styles.Text :styles.TextUpdated  }>
                                      {new Date( SMMADPlatform.UpdateDateTime).toLocaleString("en-US")}
                                    </Text>
                                  </Flex>
                                  <Table>
                                    <TableBody>
                                      <TableRow className={styles.TableRow}>
                                        <TableCell className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              Platform
                                          </Text>
                                        </TableCell>
                                        <TableCell>
                                          <TextField   
                                              className={styles.TextField}
                                              onChange={ (e) => Platform_handleChange(e, SMMADPlatform) }               
                                              defaultValue={SMMADPlatform.Platform}/>                                             
                                        </TableCell>
                                      </TableRow>                                                          
                                      <TableRow className={styles.TableRow}>
                                        <TableCell  className={styles.RowLabelColumn}>         
                                          <Text   
                                            className={styles.TextFieldLabel}
                                            >
                                              Status
                                          </Text>
                                        </TableCell>
                                        <TableCell>
                                              <SelectField 
                                                  className={styles.SelectField}
                                                  value={Status}
                                                  placeholder="Select Status"
                                                  onChange={(e) => SMMADPlatformsStatusSelected(e.target.value)}>
                                                  <option 
                                                  className={styles.SelectFieldOption} value="active" label="active">
                                                    Active
                                                  </option>
                                                  <option 
                                                  className={styles.SelectFieldOption} value="inactive" label="inactive">
                                                    Inactive
                                                  </option>
                                              </SelectField>                                            
                                        </TableCell>
                                      </TableRow >
                                    </TableBody>
                                  </Table>
                                  <Flex>
                                    <Button className={styles.RedditButtons}                                       
                                            onClick={ (e) => SaveSMMADPlatform(e, SMMADPlatform) }
                                            variation="primary">Save</Button>
                                    <Button className={styles.RedditButtons}                                       
                                            onClick={ (e) => DeleteSMMADPlatform(e, SMMADPlatform) }
                                            variation="primary">Delete</Button>
                                    <Button className={styles.RedditButtons}                                       
                                            onClick={ (e) => GoToPlatforms(e, SMMADPlatform) }
                                            variation="primary">Back</Button>
                                  </Flex>                                      
                              
                                </Flex>
                              </Flex>
                            </Card>
                          </View>           
                        </div>
                        <div ><Text className={styles.MessageBox}>{ErrorMessage}</Text></div>                            
                      </div>  
            </ErrorBoundary>
          </div>   
        )}         
      </Authenticator>  
    </m.div>                      

    )

  }
 

export default PlatformDetail;