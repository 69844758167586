import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types';
import {
  Authenticator,
  Text,
  Flex,
  useAuthenticator
  } from '@aws-amplify/ui-react';
  import styles from './MainHeader.module.css';  
  import {Link, useNavigate } from 'react-router-dom';
  
  import  {createAudienceGeoInfo}  from '../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';
  import axios from 'axios';
  import {uuidv4} from "../../BusinessLogic/UtilitiesManagement";
  import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
  

const MainHeader = (props) => {

  MainHeader.propTypes = {
      pageSource: PropTypes.string
    };
    

  var vFirstTouch = true 

  const client = generateClient();
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  let navigate = useNavigate(); 

useEffect(() => {
 
  (async function() {
    try {  

      (async function() {
        try {  

                //getData()


            } catch (err) {
              console.error('Header.useEffect[].ERROR: ', err, err.stack); 
          }
        }
      )();   

      
    } catch (err) {
      console.error('Header.useEffect[].ERROR: ', err, err.stack); 
  }
}
)();   

      
          
},[])        


async function GoToMenuSignedIn() {
  try {


    let vUserNameTmp = ""
    let vCognitoGroupTmp = ""
    //console.log("LandingPageNav.GoToMenuSignedIn.authStatus:  ",authStatus); 



    if (authStatus === "unauthenticated") {       
        
        
        //console.log("LandingPageNav.GoToMenuSignedIn.unauthenticated:  ",vUsers);

        

        navigate('/AuthenticatorRhyteIt', 
        {
          state: {
            UserId:vUserNameTmp,                                
            UserName:vUserNameTmp,                                
            CognitoGroup:vCognitoGroupTmp,
            authStatus: authStatus
          }
        }
      );  
        
      } else {

    const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};

    //console.log("LandingPageNav.GoToMenuSignedIn.accessToken: ", accessToken)
    //console.log("LandingPageNav.GoToMenuSignedIn.idToken: ", idToken["payload"]["email"])
         
    if (idToken["payload"].hasOwnProperty("email")) {
      vUserNameTmp = idToken.payload.email
      //console.log("LandingPageNav.GoToMenuSignedIn.authStatus:  ",authStatus); 
    }
    if (accessToken["payload"].hasOwnProperty("cognito:groups")) {
      vCognitoGroupTmp = accessToken["payload"]["cognito:groups"][0]
      //console.log("LandingPageNav.GoToMenuSignedIn.vUserCognitoGroup:  ",vCognitoGroupTmp); 
    }
    
    
    if (authStatus === "authenticated" && vCognitoGroupTmp === "rhyteitAdmins") {

        navigate('/MainMenuV2', 
        {
          state: {
            UserId:vUserNameTmp,                                
            UserName:vUserNameTmp,                                
            CognitoGroup:vCognitoGroupTmp,
            authStatus: authStatus
          }
        }
      );  

    
    } else if (authStatus === "authenticated" && vCognitoGroupTmp !== "" && vCognitoGroupTmp !== "rhyteitAdmins") {              
        

        navigate('/MainMenuCustomer', 
        {
          state: {
            UserId:vUserNameTmp,                                
            UserName:vUserNameTmp,                                
            CognitoGroup:vCognitoGroupTmp,
            authStatus: authStatus
          }
        }
      );  
        
      }  
    }

    } catch (err) { 
        console.error('AuthenticatorRhyteIt.GoToMenuSignedIn.error: ', err, err.stack); 
  }        
}  



  return (
  <Authenticator>
    {({ signOut, user }) => (
    <div >
      <header>
        <div className={styles.NavMenuContainer}>    
                 <Flex
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      alignContent="flex-start"
                      wrap="nowrap"
                      gap="1rem"
                    >     
                        <Flex
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="stretch"
                            alignContent="flex-start"
                            wrap="nowrap"
                            gap="3rem"
                            className={styles.LogoRow}
                          >   
                    <div onClick={(e) => GoToMenuSignedIn()} className={styles.logoimgDiv} >
                      <img className={styles.logoimg} src="https://rhyteitartwork.s3.us-west-2.amazonaws.com/RhyteItFavicon2.svg" alt="RhyteIt"/>   
                    </div> 
                  <Link className={styles.logoName} isExternal={false} to="/LandingPageV4" >   
                          <Flex
                                  direction="row"
                                  justifyContent="flex-start"
                                  alignItems="center"
                                  alignContent="center"
                                  wrap="nowrap"
                                  gap="0.25rem"
                                >                       
                      <Text className={styles.TextUpperCase}>R</Text>
                      <Text className={styles.TextLowerCase}>hyte</Text>
                      <Text className={styles.TextUpperCase}>I</Text>
                      <Text className={styles.TextLowerCase}>t</Text>                  
                    </Flex>
                  </Link>         
                </Flex>  
                </Flex>  
        </div>  
      </header>
    </div> )}
    </Authenticator>   
  )
}

export default MainHeader;