
import React, { useState , useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Button,
  Image,
  TableBody,
  TableRow,
  TableCell,
  CheckboxField,
  TextAreaField,
  TextField,
  Text
  } from '@aws-amplify/ui-react';

  import  {  }  from '../../../graphql/queries'
  import {
    createBlogPrompts,
    updateBlogPrompts,
    deleteBlogPrompts
  } from "../../../graphql/mutations";
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";
  import {
    useInView,
    useScroll,
    useTransform,
    motion,
    useAnimation,
  } from "framer-motion";
  import styles from './BlogAIPromptRow.module.css';  
  import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
  
function BlogAIPromptRow(
  props
) {       

  BlogAIPromptRow.propTypes = {
    BlogPrompt: PropTypes.object,
    Users: PropTypes.string,
    };

    const client = generateClient();
  
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vBlogPrompt, setvBlogPrompt] = useState(props.BlogPrompt)  
    const [vPrompt, setvPrompt] = useState() 
    const [vPromptName, setvPromptName] = useState() 

    const [vSubRedditListString, setvSubRedditListString] = useState() 

    

    const [Users, setUsers] = useState(props.Users)   

    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);

    const [vExpandRow, setvExpandRow] = useState(false)    
    let [vBlogPromptActive, setvBlogPromptActive] = useState(false)   
    
  
        


      useEffect(() => {

        //console.log("BlogAIPromptRow.useEffect[].props.BlogPrompt:  ",props.BlogPrompt) 

      },[])  

      useEffect(() => {
      //console.log("BlogAIPromptRow.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);

        if (isInView) {
          mainControls.start("visible");
        }
      }, [isInView]);      

      useEffect(() => {

       //console.log("BlogAIPromptRow.useEffect[vBlogPrompt].vBlogPrompt: " ,vBlogPrompt);

        if (vBlogPrompt !== undefined) {

          setvPrompt(vBlogPrompt.Prompt)
          setvPromptName(vBlogPrompt.PromptName)
          setvBlogPromptActive(vBlogPrompt.Active)
          CreatevSubRedditListString()

        }

      }, [vBlogPrompt]);   
      
      useEffect(() => {
        //console.log("BlogAIPromptRow.useEffect[vExpandRow].vExpandRow: " ,vExpandRow);
        }, [vExpandRow]);     

      async function ExpandRow(pExpandRow) {
      try {
        let pExpandRowTmp = pExpandRow 
        //console.log("BlogAIPromptRow.ExpandRow.pExpandRowTmp: " ,pExpandRowTmp);
        setvExpandRow(!pExpandRowTmp)


          } catch (err) { 

          ErrorMessageStr = "BlogAIPromptRow.PlayEpisode.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }               

      }     


      async function CreatevSubRedditListString() {
        
  
        try {          

          let vSubRedditListStringTmp = ""
          
          //console.log("BlogAIPromptRow.CreatevSubRedditListString.vBlogPrompt: " ,vBlogPrompt);
          //console.log("BlogAIPromptRow.CreatevSubRedditListString.vBlogPrompt[SubRedditList]: " ,vBlogPrompt["SubRedditList"]);
          //console.log("BlogAIPromptRow.CreatevSubRedditListString.vBlogPrompt[SubRedditList].typeof: " ,typeof vBlogPrompt["SubRedditList"]);

          if (vBlogPrompt["SubRedditList"] !== null) {

            let vSubRedditListJSON = JSON.parse(vBlogPrompt["SubRedditList"]);
            //console.log("BlogAIPromptRow.CreatevSubRedditListString.vSubRedditListJSON.typeof: " ,typeof vSubRedditListJSON);
  

            vSubRedditListJSON.forEach(vSubreddit => {

              vSubRedditListStringTmp += vSubreddit.subreddit + ","
    
            })

            vSubRedditListStringTmp = vSubRedditListStringTmp.replace(/.$/, '');
         
            setvSubRedditListString(vSubRedditListStringTmp)        

          }
  
          } catch (err) { 
            RhyteItLogging(Users, "BlogAIPromptRow", "CreatevSubRedditListString",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
            console.error("BlogAIPromptRow.CreatevSubRedditListString.Error:  ".err, err.stack); 
        }        
      }    

      async function CreatevSubRedditListJSON() {
        
  
        try {          

          const jsonObject = [];
    
          // Split the string by commas to get the key-value pairs
          const pairs = vSubRedditListString.split(',');
          
          pairs.forEach(pair => {

              let vItem = {
                "subreddit": pair,
              }
              
              // Add the key and value to the JSON object
              jsonObject.push(vItem)
          });

          //console.log("BlogAIPromptRow.CreatevSubRedditListJSON.jsonObject: " ,jsonObject);
          
          return jsonObject
  
          } catch (err) { 
            RhyteItLogging(Users, "BlogAIPromptRow", "CreatevSubRedditListString",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
            console.error("BlogAIPromptRow.CreatevSubRedditListString.Error:  ".err, err.stack); 
        }        
      }   

      async function UpdateRecord(pEvent, pStatus) {
        
  
        try {           
            var today = new Date();
            setErrorMessage("")
            
            var vUpdateDateTime = new Date().toISOString()

            let vSubredditJSON = await CreatevSubRedditListJSON(vSubRedditListString)
            //console.log("BlogAIPromptRow.UpdateRecord.vSubredditJSON: ",vSubredditJSON)

            const vSubredditJSONString = JSON.stringify(vSubredditJSON);

            let BlogPromptUpdateItem = {
              "id": vBlogPrompt["id"],
              "AccountGroup": vBlogPrompt["AccountGroup"],
              "Account": vBlogPrompt["Account"],
              "Blog": vBlogPrompt["Blog"],
              "PromptName": vPromptName,
              "SubRedditList": vSubredditJSONString,
              "Prompt": vPrompt,
              "PromptJSON": null,
              "Active": vBlogPromptActive,
              "BlogPromptStatus": pStatus,
              "CreationName": vBlogPrompt["CreationName"],
              "UpdateName": Users,
              "CreationDateTime": vBlogPrompt["CreationDateTime"],
              "UpdateDateTime": vUpdateDateTime
            }
    
             console.log("BlogAIPromptRow.UpdateRecord.BlogPromptUpdateItem: ",BlogPromptUpdateItem)
    
              let updateBlogPromptsResponse = await client.graphql({                    
                      query: updateBlogPrompts,
                      variables:{input: BlogPromptUpdateItem}            
                }
              )
            
            console.log("BlogAIPromptRow.SaveBlogPromptEditionToSubscriber.updateBlogPromptsResponse: ",updateBlogPromptsResponse)
    

            setvBlogPrompt(updateBlogPromptsResponse.data.updateBlogPrompts)        
  
          } catch (err) { 
            RhyteItLogging(Users, "BlogAIPromptRow", "Unsubscribe",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
            console.error("BlogAIPromptRow.Unsubscribe.Error:  ".err, err.stack); 
        }        
      }    
  

      async function DeleteRow(event,data) {
        try {

                
                setErrorMessage("")   

                const DeleteBlogPromptRow = {
                  id: vBlogPrompt.id,
                  _version: vBlogPrompt._version
                }
                

                const vDeleteResponse = await client.graphql({
                  query: deleteBlogPrompts,
                  variables: {
                    input: DeleteBlogPromptRow
                  }
                });  

                ErrorMessageStr = vDeleteResponse
                setErrorMessage(ErrorMessageStr)  
  
                
        
            } catch (err) { 

              RhyteItLogging(Users, "BlogAIPromptRow", "DeleteRow",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
              console.error("BlogAIPromptRow.DeleteRow.Error:  ".err, err.stack);       
          }               
        
      }       

      async function vBlogPromptActiveChanged(e, data) {
        try {


            //console.log("Application.vBlogPromptActiveChanged.data: ", data)
            //console.log("Application.vBlogPromptActiveChanged.e: ", e)
            //console.log("Application.vBlogPromptActiveChanged.vBlogPromptActive: ", vBlogPromptActive)

            setvBlogPromptActive(vBlogPromptActive===false ? true : false)
            
            //console.log("Application.vBlogPromptActiveChanged.vBlogPromptActive2: ", vBlogPromptActive)

    } catch (err) { //console.log('error fetching Users')
                    console.error(err, err.stack); 
                }
    }          

    function vPrompt_handleChange(event, data) {
      try {


        let vTmp = event.target.value

        setvPrompt(vTmp);

      } catch (err) {
        RhyteItLogging(
          Users,
          "BlogAIPromptRow",
          "vPrompt_handleChange",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }            

    function vPromptName_handleChange(event, data) {
      try {


        let vTmp = event.target.value

        setvPromptName(vTmp);

      } catch (err) {
        RhyteItLogging(
          Users,
          "BlogAIPromptRow",
          "vPrompt_handleChange",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }            

    function vSubRedditListString_handleChange(event, data) {
      try {


        let vTmp = event.target.value

        setvSubRedditListString(vTmp)  

      } catch (err) {
        RhyteItLogging(
          Users,
          "BlogAIPromptRow",
          "vPrompt_handleChange",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }         
    

      return (
        <TableBody>         
          <TableRow  className={vBlogPrompt.Active === true ? styles.TableRowActive : styles.TableRowInActive}  key={vBlogPrompt.id}>    
            <TableCell className={styles.TableCellLeftAlign}> 
                <Button
                      loadingText=""
                      ariaLabel=""
                      className={styles.Button}
                      onClick={(e) => ExpandRow(vExpandRow)}
                    >
                    +
                </Button>   
                <Button
                      loadingText=""
                      ariaLabel=""
                      className={styles.Button}
                      //onClick={(e) => UpdateRecord(e)}
                      onClick={(e) => UpdateRecord(e, "UPDATED")}
                      
                    >
                    Save
                </Button>     
                <Button
                      loadingText=""
                      ariaLabel=""
                      className={styles.Button}
                      //onClick={(e) => UpdateRecord(e)}
                      onClick={(e) => UpdateRecord(e, "RUN")}
                      
                    >
                    Run
                </Button>   
                <Button
                      loadingText=""
                      ariaLabel=""
                      className={styles.Button}
                      onClick={(e) => DeleteRow(e, vBlogPrompt)}
                    >
                    Delete
                </Button>   
            </TableCell>               
            <TableCell className={styles.TableCellLeftAlign}>      
            <TextField
                className={styles.TextField}
                name="vIntroductionTitle"
                placeholder="Prompt name"
                defaultValue={vPromptName}
                onChange={(e) => vPromptName_handleChange(e, vPromptName)}
              />                                                   
            </TableCell>   
            <TableCell className={styles.TableCell}> 
              <CheckboxField className={styles.Checkbox}
                  name="subscribe-controlled"
                  checked={vBlogPromptActive}
                  size="small"
                  onClick={ (e) => vBlogPromptActiveChanged(e) } 
                  />                                            
            </TableCell>    
            <TableCell className={styles.TableCell}>  
              <Text
                        className={styles.Text}
                      >
                      {vBlogPrompt["BlogPromptStatus"]}
              </Text>                 
            </TableCell>                                        
            <TableCell className={styles.TableCell}>  
              <Text  className={styles.TextUpdate}>
              {new Date(vBlogPrompt.UpdateDateTime).toISOString().slice(0, 10) + " " + new Date(vBlogPrompt.UpdateDateTime).toISOString().slice(11, 16)}
              </Text>                           
            </TableCell>                                          
          </TableRow>      
          {
            vExpandRow === true &&  (                   
              <TableRow  className={styles.TableRowActive}  key={vBlogPrompt.CreationDateTime}>     
                <TableCell className={styles.TableCellLeftAlign} colspan="5"> 
                  <TextField
                    className={styles.TextField}
                    name="vSubRedditList"
                    placeholder="Enter subreddits (seperated by commas)"
                    defaultValue={vSubRedditListString}
                    onChange={(e) => vSubRedditListString_handleChange(e, vSubRedditListString)}
                  />                           
                </TableCell>                  
              </TableRow>  
            )
          }    
          {
            vExpandRow === true &&  (                   
              <TableRow  className={styles.TableRowActive}  key={vBlogPrompt.UpdateDateTime}>     
                <TableCell className={styles.TableCellLeftAlign} colspan="5"> 
                  <TextAreaField
                            className={styles.TextAreaField}
                            name="vPrompt"
                            placeholder="Add prompt"
                            defaultValue={ vPrompt}
                            rows={20}
                            resize="vertical"
                            autoResize
                            onChange={(e) => vPrompt_handleChange(e, vPrompt)}
                          />                        
                </TableCell>                  
              </TableRow>  
            )
          }              
        {
          ErrorMessage.length > 0 &&             
            <TableRow  className={styles.ErrorMessageRow}>              
              <TableCell 
                colSpan="3">      
                {ErrorMessage}
              </TableCell>               
            </TableRow>  
        }               
        </TableBody>
      
    )
  }
export default BlogAIPromptRow;

