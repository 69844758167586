import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {motion as m} from "framer-motion";
import styles from './BackgroundNoLogo.module.css';

import BackgroundAurora from './BackgroundAurora';

const BackgroundNoLogo = (props) => {       
  
  BackgroundNoLogo.propTypes = {
          backgroundcolor: PropTypes.string
        };

        
        const [vBGColor, setvBGColor] = useState(props.backgroundcolor);  


        useEffect(() => {

            

        },[]) 

        useEffect(() => {
        
         // //console.log("BackgroundNoLogo.useEffect[vBGColor].vBGColor: " ,vBGColor);
  
  
          },[vBGColor]) 


    return ( 
            <div className={styles.BackgroundContainer}> 
              <BackgroundAurora className={styles.Background}  GradientStopColor1="#0000" GradientStopColor2="#000"  GradientStopColor3={vBGColor}   GradientStopColor4="#8880"/>                                                                                                           
            </div>  
    )

  }
 

export default BackgroundNoLogo;