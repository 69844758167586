
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  MenuButton,
  Text,
  Menu,
  MenuItem, 
  Divider,
  Flex,
  Image,
  Button
  } from '@aws-amplify/ui-react';
  
  import { Link } from "react-router-dom";
  

  import  { deleteEmailAccounts}  from '../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';

  import styles from './RhyteItAccountRow.module.css';  
  import { UserContext} from '../../BusinessLogic/Hooks/UserContext';


const RhyteItAccountRow = (props) => {       

  RhyteItAccountRow.propTypes = {
      RhyteItAccount: PropTypes.object
      };
    
      const client = generateClient();
  
    const {Users, setUsers} = useContext(UserContext);   
    const [ErrorMessage, setErrorMessage] = useState("")
    const [ShowDetailRow, setShowDetailRow] = useState(false)
    const [vProfileImageURL, setvProfileImageURL] = useState()     
    let ErrorMessageStr = ""
    let debugConsoleOutPut = true

    const [message, setMessage] = useState('');
    const onSuccess = ({ key }) => {
    
    let fileLocation = "https://rhyteitphotostorage221339-prod.s3.us-west-2.amazonaws.com/public/" + key

      setvProfileImageURL(fileLocation)
    };

    const [vRhyteItAccounts, setvRhyteItAccounts] = useState(props.RhyteItAccount)      

    useEffect(() => {
      
      console.log("vRhyteItAccountRow.useEffect[].Users:  ",Users) 


      },[])  

    useEffect(() => {

      //console.log("vRhyteItAccount.useEffect[vRhyteItAccounts].vRhyteItAccounts[0].Subscribed:  ",vRhyteItAccounts[0].Subscribed) 

     //   console.log("vRhyteItAccount.useEffect[vRhyteItAccounts]vRhyteItAccounts:  ",vRhyteItAccounts) 
      //console.log("vRhyteItAccounts.useEffect[vRhyteItAccounts].vRhyteItAccounts:  ",props.RhyteItUser) 

      },[vRhyteItAccounts])             
    
    function RhyteItAccount_Delete_Row(event,data) {
      try {

          var elementPos = vRhyteItAccounts.map(function(x) {return x.id; }).indexOf(data.id);

          if (vRhyteItAccounts[elementPos].RhyteItAccountStatus !== "New") {

            const DeleteRhyteItAccounts = {
              id: vRhyteItAccounts[elementPos].id,
              UserId: vRhyteItAccounts[elementPos].UserId,
              _version: vRhyteItAccounts[elementPos]._version
            }
            

            const ReturnMessage = client.graphql({
              query: deleteEmailAccounts,
              variables: {
                input: DeleteRhyteItAccounts
              }
            });  

          }
        
          delete vRhyteItAccounts[elementPos];   

          setvRhyteItAccounts(vRhyteItAccounts)

          setErrorMessage("Deleted")         
                
          } catch (err) { 

          ErrorMessageStr = "vRhyteItAccounts.RhyteItAccount_Delete_Row.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }               
      
    }   

    function ClickOnProfilePicture(event) {
      try {

          setErrorMessage("") 
          console.log("RhyteItAccountRow.ClickOnProfilePicture.event:  ",event) 
  
          
        } catch (err) { 

        ErrorMessageStr = "RhyteItAccountRow.OpenPostURL Error: " + err 

        console.error(ErrorMessageStr); 
        
        setErrorMessage(ErrorMessageStr)         
      }        
    }  


  return (             
            <div  className={styles.TableRowBackground} key={vRhyteItAccounts.id}>
              <Link key={vRhyteItAccounts.id} className={styles.Link} isExternal={false} to="/RhyteItAccountTabs" state={{ vRhyteItAccount: vRhyteItAccounts, User:Users }} > 
                      <Flex
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        alignContent="flex-start"
                        wrap="nowrap"
                        gap="1rem"
                        className={styles.AccountContainer}
                      >                  
                        <Image
                            alt=""
                            src={vRhyteItAccounts.ProfileImageURL}  
                            objectFit="initial"
                            objectPosition="50% 50%"
                            className={vRhyteItAccounts.TwitterAccount === null ?  styles.PhotoImage : vRhyteItAccounts.TwitterStatus === "suspended" ?  styles.PhotoImageSuspended : vRhyteItAccounts.TwitterStatus === "active" ?  styles.PhotoImageActive : vRhyteItAccounts.TwitterStatus === "readonly" ?  styles.PhotoImageReadOnly : styles.PhotoImageTwitter}
                            onClick={(e) => ClickOnProfilePicture(e)}
                          />                         
                        <Text className={styles.Text}>{vRhyteItAccounts.id}</Text>
                      </Flex> 
                      </Link>                             
            </div>  
    
  )
}

export default RhyteItAccountRow;