import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import {ErrorBoundary} from 'react-error-boundary'
import Header from "../RhyteItComponents/Header/Header";


import  {  listEmailAccounts}  from '../../graphql/queries'
import  { deleteEmailAccounts}  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import {motion as m} from "framer-motion";
import RhyteItAccountsTable from "./RhyteItAccountsTable";

import { UserContext, CognitoGroupContext} from '../../BusinessLogic/Hooks/UserContext';
import {GetGroupRoleAccess, RhyteItLogging, ReturnUserToLandingPage} from '../../BusinessLogic/UserManagement';
import { fetchAuthSession } from 'aws-amplify/auth';

import {
  Button ,
  Authenticator,
  Autocomplete,
  Text,
  RadioGroupField,
  Radio,  
  Flex

} from '@aws-amplify/ui-react';
import styles from './RhyteItAccounts.module.css';

function ErrorFallback({error, resetErrorBoundary}) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}


const RhyteItAccounts = (props) => {       
  
  RhyteItAccounts.propTypes = {
          RedditUser: PropTypes.object
        };

        const client = generateClient();
        const [UserEmail, setUserEmail] = useState(undefined)  
        
        const vBreadCrumbItems = [{Path: "/", Title:"Home", isCurrent:false},{Path: "/MainMenuV2", Title:"Menu", isCurrent:false},{Path: "/RhyteItAccounts", Title:"RhyteIt Accounts", isCurrent:true}]
        const [RhyteItAccountRecords, setRhyteItAccountRecords] = useState()
        var RhyteItAccountRecords_bkup = []

        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   
        let debugConsoleOutPut = true

        const {Users, setUsers} = useContext(UserContext);    
        const {CognitoGroup, setCognitoGroup} = useContext(CognitoGroupContext);      
        const [RhyteItsName, setRhyteItsName] = useState([]);
        const [SearchTerm1, setSearchTerm1] = useState('');
        const [CauseUpdate, setCauseUpdate] = useState('');
        const [ShowTable, setShowTable] = useState(false);

        useEffect(() => {

             

        },[]) 
  


        useEffect(() => {
          
        (async function() {
          try {  

                  //console.log("EmailManagement.useEffect[CognitoGroup].CognitoGroup:  ",CognitoGroup);
                  if (CognitoGroup !== undefined) {

                

                              let   GetGroupRoleAccessResponse = await GetGroupRoleAccess(CognitoGroup, "rhyteitAdmins")
                              
                            // console.log("EmailManagement.useEffect[CognitoGroup].GetGroupRoleAccessResponse:  ",GetGroupRoleAccessResponse);
                              if (GetGroupRoleAccessResponse) {  
                                GetRhyteItAccountRecords()  
                              }
                        

                    } else {

                      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
                      // console.log("EmailManagement.useEffect[CognitoGroup].idToken:  ",idToken);
                      // console.log("EmailManagement.useEffect[CognitoGroup].accessToken:  ",accessToken);

                      if (idToken !== undefined) {
                        setUsers(idToken.payload.email)
                        setCognitoGroup(accessToken["payload"]["cognito:groups"])
                      } else {
                        
                        ReturnUserToLandingPage(Users, CognitoGroup)
                      }

        //    ReturnUserToLandingPage(Users, CognitoGroup)
                  }
                } catch (err) {                  
                  RhyteItLogging(Users, "RhyteItAccounts", "useEffect[CognitoGroup]",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
              }
            }
          )();   
  
          },[CognitoGroup])  


        useEffect(() => {

          if (RhyteItAccountRecords !== undefined) {

            if (Object.keys(RhyteItAccountRecords).length > 1) {
              setShowTable(true)
              let vErrorMessage = RhyteItAccountRecords.length + " accounts retrieved at: " + new Date().toLocaleString() 
              setErrorMessage(vErrorMessage)            
            } else {
              setErrorMessage("0 accounts retrieved at: " + new Date().toLocaleString())
            }
          }

        },[RhyteItAccountRecords])     
      
    
    
        useEffect(() => {
      
          //console.log("RhyteItAccounts.useEffect[SearchTerm1].Users:  ",Users)

          if (Users !== undefined) {                    
            GetRhyteItAccountRecords()
          }    

        }, [SearchTerm1]);      
          
      
       
        async function GetRhyteItAccountRecords() {
          try {
                    setErrorMessage("")
                    setRhyteItAccountRecords([])
                    if (debugConsoleOutPut === true) {
                        //console.log("RhyteItAccounts.GetRhyteItAccountRecords.Users: ", Users)
                    }


                    if (debugConsoleOutPut === true ) {
                   //   //console.log("RhyteItAccounts.GetRhyteItAccountRecords.Users[0].UserId: ",Users[0].UserId)
                    }

                    const variables = {
                      filter: {
                        UserId: {
                                eq: Users
                            }
                      }
                    };
      
                  const RhyteItAccountRecordsData  = await client.graphql({
                      query: listEmailAccounts,
                      variables: variables
                    });                   



                    if (debugConsoleOutPut === true ) {
                     // //console.log("RhyteItAccounts.GetRhyteItAccountRecords.RhyteItAccountRecords: ",RhyteItAccountRecordsData.data.listEmailAccounts.items )
                    }


                    const RhyteItAccountRecords = RhyteItAccountRecordsData.data.listEmailAccounts.items 

                    RhyteItAccountRecords_bkup = RhyteItAccountRecords
                    
                    let RhyteItAccountRecordsSorted = await SortAccountsByName(RhyteItAccountRecords)  

                    setRhyteItAccountRecords(RhyteItAccountRecordsSorted)
                    
                    let PopulateRhyteItsNamesListResponse = await PopulateRhyteItsNamesList(RhyteItAccountRecords)

                    return RhyteItAccountRecords
  
          } catch (err) {    
            RhyteItLogging(Users, "RhyteItAccounts", "GetRhyteItAccountRecords",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                      }
        } 
        
    function SortAccountsByName(pRhyteItAccounts) {
      pRhyteItAccounts.sort(GFG_sortFunction);
          return pRhyteItAccounts
      //    //console.log(JSON.stringify(pEmails));
      }

    function GFG_sortFunction(a, b) {
        let RhyteItAccountA = a.id;
        let RhyteItAccountB = b.id;
        return RhyteItAccountA > RhyteItAccountB ? 1 : -1;
    };          

        async function PopulateRhyteItsNamesList(RhyteItAccountRecords) {
          try {

              setErrorMessage("") 

              var RhyteItAccountRecordsJSON = {RhyteItAccountRecords: [{id:"none", label: "none"}]}


              if (RhyteItAccountRecords.length > 0 ) {

                RhyteItAccountRecords.forEach(RhyteItAccountRecord => 
                  {

                    let vItemAlreadyExists = false

                   // //console.log("RhyteItAccounts.PopulateRhyteItsNamesList.RhyteItAccountRecordsJSON:  ", RhyteItAccountRecordsJSON)

                    RhyteItAccountRecordsJSON.RhyteItAccountRecords.forEach(pt => 
                        {                                               
                          if (pt.label === RhyteItAccountRecord.id) {                      
                            vItemAlreadyExists = true;
                          }
                        } 
                      )     
                      
                      

                    if(vItemAlreadyExists === false) {

                        let NewRhyteItsName = {
                          id: RhyteItAccountRecord.id,
                          label: RhyteItAccountRecord.id
                        }   

                        RhyteItAccountRecordsJSON.RhyteItAccountRecords.push(NewRhyteItsName);

                      }
                })

                setRhyteItsName(RhyteItAccountRecordsJSON.RhyteItAccountRecords)

              }


              return RhyteItAccountRecordsJSON

            } catch (err) { 

              RhyteItLogging(Users, "RhyteItAccounts", "PopulateRhyteItsNamesList",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)  
          }        
        } 

        function onChangeAutocomplete(event) {
          setSearchTerm1(event.target.value);
        }

        async function FilterAccountsBySocialMedia(option) {

          
          //console.log("RhyteItAccounts.FilterAccountsBySocialMedia.option: ",option)  

          
          setRhyteItAccountRecords(RhyteItAccountRecords_bkup)

          try {

            setErrorMessage("") 

            let RhyteItAccountsFiltered = ""

            if (option === "Twitter") {

              RhyteItAccountsFiltered = await [...RhyteItAccountRecords].filter(RhyteItAccount => RhyteItAccount.TwitterAccount !==  null)            

            } else if (option === "Medium") {

              RhyteItAccountsFiltered = await [...RhyteItAccountRecords].filter(RhyteItAccount => RhyteItAccount.MediumAccount !==  null)            

            } else if (option === "Chromium") {

              RhyteItAccountsFiltered = await [...RhyteItAccountRecords].filter(RhyteItAccount => RhyteItAccount.ChromiumProfile !==  null)            

            } else if (option === "Instagram") {

              RhyteItAccountsFiltered = await [...RhyteItAccountRecords].filter(RhyteItAccount => RhyteItAccount.InstagramAccount !==  null)            

            } else if (option === "Reddit") {

              RhyteItAccountsFiltered = await [...RhyteItAccountRecords].filter(RhyteItAccount => RhyteItAccount.RedditAccount !==  null)            

            } else if (option === "OnlyFans") {

              RhyteItAccountsFiltered = await [...RhyteItAccountRecords].filter(RhyteItAccount => RhyteItAccount.OnlyFansAccount !==  null)            

            }
            
            //console.log("RhyteItAccounts.FilterAccountsBySocialMedia.RhyteItAccountsFiltered.length:  ",RhyteItAccountsFiltered.length)

            setRhyteItAccountRecords(RhyteItAccountsFiltered)
            setCauseUpdate(Date.now()) 


          } catch (err) { 

          RhyteItLogging(Users, "RhyteItAccounts", "FilterAccountsBySocialMedia",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)  
        }            
        }        

        async function onSelectAutocomplete(option) {

          
          //console.log("RhyteItAccounts.onSelectAutocomplete.option: ",option)  

          const { label } = option;

          setSearchTerm1(label);          


          try {

            setErrorMessage("") 

            let RhyteItAccountsFiltered = ""

            let fetchRhyteItAccountRecordsResponse = await GetRhyteItAccountRecords();
            //console.log("RhyteItAccounts.onSelectAutocomplete.fetchRhyteItAccountRecordsResponse:  ",fetchRhyteItAccountRecordsResponse)
            //console.log("RhyteItAccounts.onSelectAutocomplete.SearchTerm1:  ",SearchTerm1)

            RhyteItAccountsFiltered = await [...fetchRhyteItAccountRecordsResponse].filter(RhyteItAccount => RhyteItAccount.id ===  label)            
            
            //console.log("RhyteItAccounts.onSelectAutocomplete.RhyteItAccountsFiltered:  ",RhyteItAccountsFiltered)

            setRhyteItAccountRecords(RhyteItAccountsFiltered)


          } catch (err) { 

          RhyteItLogging(Users, "RhyteItAccounts", "onSelectAutocomplete",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)  
        }            
        }          

        function onClearAutocomplete() {
          setSearchTerm1("");
        }                
        
        async  function AddAccount(event, data) {
          try {           
            
              setErrorMessage("")
              
              var vUpdateDateTime = new Date().toISOString()
    
              let vRhyteItAccountItem = {
                "id": "",
                "UserId": UserEmail,
                "Password": null,
                "FirstName": null,
                "LastName": null,
                "Name": null,
                "NameNonSpaced": null,  
                "BirthMonth": Math.floor((Math.random() * 12) + 1),
                "BirthDay": Math.floor((Math.random() * 28) + 1),
                "BirthYear": 1999,
                "StartDate": vUpdateDateTime,
                "EndDate": null,
                "EmailAccountProvider": "rhyteit",
                "EmailAccount": null,
                "ChromiumProfile": null,
                "TwitterAccount": null,
                "TwitterPassword": null,
                "TwitterStatus": null,
                "TwitterConsumerKey": null,
                "TwitterConsumerSecret": null,
                "RedditAccount": null,
                "OnlyFansAccount": null,
                "MediumAccount": null,
                "InstagramAccount": null,
                "VerificationProvider": null,
                "VerificationCode": null,
                "OpenAIOrgKey": null,
                "OpenAIapiKey": null,
                "AccountStatus": "NEW",
                "Prompt": null,
                "Model":null,
                "ProfileImageURL": null,
                "MageSeed": null,
                "Industry": null,
                "ProcessGroup": null,
                "RedoImage": null,
                "CreationName": UserEmail,
                "UpdateName": UserEmail,
                "CreationDateTime": vUpdateDateTime,
                "UpdateDateTime": vUpdateDateTime
            }

              RhyteItAccountRecords[RhyteItAccountRecords.length-1] = vRhyteItAccountItem
              setRhyteItAccountRecords(RhyteItAccountRecords)
         
              setCauseUpdate(Date.now()) 
    
            } catch (err) { 
              RhyteItLogging(Users, "RhyteItAccounts", "SaveRhyteItAccount",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)  
          }        
        }  


    return ( 
          <Authenticator  className={styles.AuthenticatorLogin}  loginMechanisms={['email']} socialProviders={[]} hideSignUp={true}>
              {({ signOut, user }) => (  
                    <div className={styles.SystemAdminBackground}>
                      <ErrorBoundary
                            FallbackComponent={ErrorFallback}
                          >   
                              <Flex
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="stretch"
                                alignContent="flex-start"
                                wrap="wrap"
                                gap="1rem"
                                className={styles.GridContainer}
                              >        
                                <Header Title="RhyteIt Accounts" SubTitle="Manage RhyteIt Accounts" BreadCrumbItems ={vBreadCrumbItems} pageSource="RhyteItAccounts"/>   
                                <Flex
                                  direction="column"
                                  justifyContent="flex-start"
                                  alignItems="stretch"
                                  alignContent="flex-start"
                                  wrap="wrap"
                                  gap="1rem"
                                >   
                                  <div className={styles.ToolBar}>          
                                    <div className={styles.ToolBarRow}>                                                                                         
                                        <div className={styles.ToolBarItem}>
                                          <Button
                                            loadingText=""
                                            ariaLabel=""
                                            className={styles.RedditTitleButtons}
                                            onClick={ (e) => AddAccount(e)}
                                          >
                                         Add New
                                          </Button>                                    
                                        </div>  
                                        <div className={styles.ToolBarItem}>
                                          <Button
                                            loadingText=""
                                            ariaLabel=""
                                            className={styles.RedditTitleButtons}
                                            onClick={ (e) => GetRhyteItAccountRecords(e)}
                                          >
                                          Refresh Data
                                          </Button>                                    
                                        </div>      
                                        <div>
                                          <Autocomplete                                  
                                              label="Autocomplete"
                                              options={RhyteItsName}
                                              placeholder="Find RhyteIts Name"
                                              value={SearchTerm1}
                                              onChange={onChangeAutocomplete}
                                              onClear={onClearAutocomplete}
                                              onSelect={onSelectAutocomplete}
                                              onSubmit={onSelectAutocomplete}   
                                              className={styles.amplifyautocomplete}    
                                          />
                                        </div>                                                                                                                          
                                    </div>                                
                                    <div className={styles.MessageBox}>{ErrorMessage}</div>   
                                    <div className={styles.RadioGroupFieldContainer}>
                                      <RadioGroupField  name="row" 
                                                        onChange={(e) => FilterAccountsBySocialMedia(e.target.value)}
                                                        size="large"
                                                        className={styles.RadioGroupField}
                                                        direction="row">
                                        <Radio value="Chromium" className={styles.RadioOption}><Text className={styles.RadioOption}>Chromium</Text></Radio>
                                        <Radio value="Twitter" className={styles.RadioOption}><Text className={styles.RadioOption}>Twitter</Text></Radio>
                                        <Radio value="Instagram" className={styles.RadioOption}><Text className={styles.RadioOption}>Instagram</Text></Radio>
                                        <Radio value="Reddit" className={styles.RadioOption}><Text className={styles.RadioOption}>Reddit</Text></Radio>
                                        <Radio value="OnlyFans" className={styles.RadioOption}><Text className={styles.RadioOption}>OnlyFans</Text></Radio>
                                        <Radio value="Email" className={styles.RadioOption}><Text className={styles.RadioOption}>Email</Text></Radio>
                                      </RadioGroupField>      
                                    </div>  
                                  </div>
                                </Flex>                                                        
                                {ShowTable && (<RhyteItAccountsTable key={CauseUpdate} RhyteItAccounts={RhyteItAccountRecords}/>  )  }  
                              </Flex>                              
                      </ErrorBoundary>
                    </div> 
              )}         
          </Authenticator>  
    )

  }
 

export default RhyteItAccounts;