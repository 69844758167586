import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";

import { generateClient } from "aws-amplify/api";

import {
  listSMMADCampaigns,
  listSMMADAccounts,
  getSecrets,
} from "../../../graphql/queries";
import Header from "../../RhyteItComponents/Header/Header";
import { motion as m } from "framer-motion";
import DashboardContent from "./DashboardContent";
import SMMADBubbleChart from "./SMMADBubbleChart";
import {
  UserContext,
  CognitoGroupContext,
} from "../../../BusinessLogic/Hooks/UserContext";
import {
  ValidateUser,
  GetGroupRoleAccess,
  RhyteItLogging,
  ReturnUserToLandingPage,
} from "../../../BusinessLogic/UserManagement";
import MenuBackground from "../../Menu/MenuBackground";
import SMMADTable from "./SMMADTable";
import SMMADTextTable from "./SMMADTextTable";
import { fetchAuthSession } from "aws-amplify/auth";

import { SelectField, Flex } from "@aws-amplify/ui-react";
import styles from "./SMMADContentMetrics.module.css";
import {
  ListObjectsCommand,
  ListObjectsCommandOutput,
  GetObjectCommand,
  S3Client,
} from "@aws-sdk/client-s3";
import { fromCognitoIdentity, cognitoClient, CognitoIdentityCredentials, fromCognitoIdentityPool} from "@aws-sdk/credential-providers";

const SMMADContentMetrics = (props) => {
  SMMADContentMetrics.propTypes = {
    RedditUser: PropTypes.object,
  };

  const client = generateClient();
  const [vSMMADCampaign, setvSMMADCampaign] = useState([]);
  let [vAccountList, setvAccountList] = useState();
  const [vAccountSelected, setvAccountSelected] = useState();
  const [RhyteItDashboardContent, setRhyteItDashboardContent] = useState([]);
  const vBreadCrumbItems = [
    { Path: "/", Title: "Home", isCurrent: false },
    { Path: "/MainMenuV2", Title: "Menu", isCurrent: false },
    {
      Path: "/SMMADContentMetrics",
      Title: "SMMADContentMetrics",
      isCurrent: true,
    },
  ];

  var vS3AccessKey = undefined
  var vS3AccessKeySecret = undefined

  var ErrorMessageStr = "";
  const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr);
  let debugConsoleOutPut = true;
  let region = "us-west-2"
  const { Users, setUsers } = useContext(UserContext);
  const { CognitoGroup, setCognitoGroup } = useContext(CognitoGroupContext);
  const [ShowTable, setShowTable] = useState(false);

  const [updatedKey, setupdatedKey] = useState(Date.now());
  const [vIdToken, setvIdToken] = useState();

  const [Report, setReport] = useState("MetricsTable");

  const [objects, setObjects] = useState([]);  

  useEffect(() => {
    (async function () {
      try {
        if (Users === undefined) {
          //console.log("SMMADContentMetrics.useEffect[].Users:  ",Users)
          let vIsUserSubscribed = await ValidateUser();
          setUsers(vIsUserSubscribed);
          //console.log("SMMADContentMetrics.useEffect[].vIsUserSubscribed:  ",vIsUserSubscribed)
        }

        //console.log("SMMADContentMetrics.useEffect[].Users:  ",Users)
      } catch (err) {
        console.error(
          "SMMADContentMetrics.useEffect[].ERROR: ",
          err,
          err.stack
        );
      }
    })();

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async function () {
      try {
        if (CognitoGroup !== undefined) {
          let GetGroupRoleAccessResponse = await GetGroupRoleAccess(
            CognitoGroup,
            "RhyteItSMMADUserGroup"
          );

          if (GetGroupRoleAccessResponse) {
            GetSMMADAccounts();
          }
        } else {
          const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};

          if (idToken !== undefined) {
            setUsers(idToken.payload.email);
            setCognitoGroup(accessToken["payload"]["cognito:groups"]);
          } else {
            ReturnUserToLandingPage(Users, CognitoGroup);
          }
        }
      } catch (err) {
        RhyteItLogging(
          Users,
          "SMMADBatchJobs",
          "useEffect[CognitoGroup]",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    })();
  }, [CognitoGroup]);

  useEffect(() => {

    //console.log("SMMADContentMetrics.useEffect[objects].objects:  ",objects)
    if (objects.length > 0) {

      //console.log("SMMADContentMetrics.useEffect[objects].objects:  ",objects)

    }

  }, [objects]);

  useEffect(() => {

      if (vIdToken !== undefined) {

        GetSecrets()
        //GetS3Objects()
        
    }

  }, [vIdToken]);

  useEffect(() => {

    //console.log("SMMADContentMetrics.useEffect[vAccountSelected].vAccountSelected:  ",vAccountSelected)

    if (vAccountSelected !== undefined) {
     
      GetSecrets()
      //GetS3Objects()
    }

    //console.log("SMMADContentMetrics.useEffect[vAccountSelected].RhyteItDashboardContent:  ",RhyteItDashboardContent)
    if (RhyteItDashboardContent.length  > 0 &&  Report !== undefined) {
      setupdatedKey(Date.now())
    }


  }, [vAccountSelected]);

  useEffect(() => {
    //  //console.log("SMMADCampaign.useEffect[vAccountList].vAccountList:  ",vAccountList)

    if (vAccountList !== undefined && Users !== "edwardhawke@rhyteit.com") {
      AccountSelectedAction(vAccountList[0]["Account"]);
    }
  }, [vAccountList]);

  useEffect(() => {

    //console.log("SMMADContentMetrics.useEffect[Report].Report:  ",Report)

    if (Report !== undefined) {
      setupdatedKey(Date.now())
    }

  }, [Report]);  



  async function GetS3Objects() {
    try {
     
      if (vAccountSelected !== undefined) {

      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
      //console.log("SMMADContentMetrics.GetS3Objects.idToken:  ", idToken);
      const REGION = 'us-west-2';
  
      const client = new S3Client({
        region: REGION,
          credentials: {
            accessKeyId: vS3AccessKey,
            secretAccessKey: vS3AccessKeySecret,
          },
        }
      );

      
      //console.log("SMMADContentMetrics.GetS3Objects.client:  ", client);

      const input = {
        "Bucket": "rhyteitsmmad",
        "Key": "TimeStats/" + vAccountSelected.substring(0,vAccountSelected.indexOf("@")) + ".json"
      };
      // const command = new ListObjectsCommand({ Bucket: "rhyteitsmmad" });
       const command = new GetObjectCommand(input);
       const response = await client.send(command);
       const str = await response.Body.transformToString();
       //console.log("SMMADContentMetrics.GetS3Objects.str:  ", str);

       let vJSON = JSON.parse(str.toString('utf-8'))
       
       //console.log("SMMADContentMetrics.GetS3Objects.vJSON:  ", vJSON);

       setRhyteItDashboardContent(vJSON)
       
      // client.send(command).then(({ Contents }) => setObjects(Contents || []));  

    }
    } catch (err) {

      
      console.error(
        "SMMADContentMetrics.GetS3Objects.ERROR: ",
        err,
        err.stack
      );

      RhyteItLogging(
        Users,
        "SMMADContentMetrics",
        "GetS3Objects",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }
  
  async function GetSecrets() {
    try {
      setErrorMessage("");

      const getSecretsData2 = await client.graphql({
        query: getSecrets,
        variables: {id: "S3AccessKey"},
      });

      if (getSecretsData2.data.getSecrets !== null) {

        vS3AccessKey = getSecretsData2.data.getSecrets.Value
        vS3AccessKeySecret = getSecretsData2.data.getSecrets.SecretValue
        GetS3Objects()
      }

      //console.log("SMMADContentMetrics.GetSecrets.getSecretsData2:  ", getSecretsData2);
      //console.log("SMMADContentMetrics.GetSecrets.vS3AccessKeySecret :  ", vS3AccessKeySecret      );



    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADCampaign",
        "GetSMMADAccounts",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }


  async function GetSMMADAccounts() {
    try {
      setErrorMessage("");
      setvSMMADCampaign([]);
      let variables;
      if (Users !== "edwardhawke@rhyteit.com") {
        variables = {
          filter: {
            Account: {
              eq: Users,
            },
          },
        };
      } else {
        variables = {};
      }

      const listSMMADAccountsData2 = await client.graphql({
        query: listSMMADAccounts,
        variables: variables,
      });

      if (listSMMADAccountsData2.data.listSMMADAccounts.items.length > 0) {
        const SMMADCampaign1 =
          listSMMADAccountsData2.data.listSMMADAccounts.items;

        let vAccountListArray = [];

        SMMADCampaign1.forEach(async (Campaign) => {
          if (
            vAccountListArray.filter(
              (vcampaign) => vcampaign["Account"] === Campaign["Account"]
            ).length === 0
          ) {
            vAccountListArray.push({
              Account: Campaign["Account"],
              CampaignsActive: Campaign["CampaignsActive"],
            });
          }
        });

        let vAccountListArraySorted = SortAccountsByName(vAccountListArray);
        //console.log("SMMADCampaign.GetSMMADAccounts.vAccountListArraySorted: ", vAccountListArraySorted);
        setvAccountList(vAccountListArraySorted);
      }
    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADCampaign",
        "GetSMMADAccounts",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function SortAccountsByName(pRhyteItAccounts) {
    pRhyteItAccounts.sort(GFG_sortFunction);
    return pRhyteItAccounts;
    //    //console.log(JSON.stringify(pEmails));
  }

  function GFG_sortFunction(a, b) {
    let RhyteItAccountA = a.Account;
    let RhyteItAccountB = b.Account;
    return RhyteItAccountA > RhyteItAccountB ? 1 : -1;
  }

  function ReportSelected(pReport) {
    try {
      //console.log("ReportSelected.pReport: ",pReport);
      setShowTable(true);
      setReport(pReport);
      setupdatedKey(Date.now())

      //console.log("ReportSelected.SMMADContentMetricsHighlevel.length: ",SMMADContentMetricsHighlevel.length);
    } catch (err) {
      console.error(
        "SMMADContentMetrics.ReportSelected.ERROR: ",
        err,
        err.stack
      );
    }
  }

  async function AccountSelectedAction(pAccount) {
    try {
      //    //console.log("SMMADCampaign.AccountSelectedAction.pAccount: ", pAccount);
      let vSelectedAccount = vAccountList.filter(
        (vAccountItem) => vAccountItem["Account"] === pAccount
      );

      setvAccountSelected(pAccount);
    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADCampaign",
        "AccountSelectedAction",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  return (
    <m.div
      key="Search"
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      exit={{ opacity: 1 }}
    >
      <MenuBackground
        className={styles.MenuBackground}
        GradientStopColor1="#0000"
        GradientStopColor2="#000"
        GradientStopColor3="#cc6633"
        GradientStopColor4="#8880"
      />
      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="flex-start"
        wrap="nowrap"
        gap="3rem"
        className={styles.GridContainer}
      >
        <Header
          Title="Twitter Metrics"
          SubTitle="Visually understand your metrics"
          BreadCrumbItems={vBreadCrumbItems}
          pageSource="SMMADContentMetrics"
        />
        <Flex
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          alignContent="center"
          wrap="nowrap"
          gap="3rem"
        >
          <SelectField
            className={styles.SelectField}
            value={vAccountSelected}
            placeholder="Select account"
            onChange={(e) => AccountSelectedAction(e.target.value)}
          >
            {vAccountList !== undefined &&
              vAccountList.map((FromAccount) => (
                <option
                  key={FromAccount.Account}
                  className={styles.SelectFieldOption}
                  value={FromAccount.Account}
                  label={FromAccount.Account}
                >
                  {FromAccount.Account}
                </option>
              ))}
          </SelectField>
        </Flex>
        {vAccountSelected !== undefined && (
        <div>
          <SelectField
            value={Report}
            onChange={(e) => ReportSelected(e.target.value)}
            className={styles.SelectField}
          >
            <option value="NULL">Select Chart</option>
            <option value="BarChartBestDayOfWeek">The Best Day of Week</option>
            <option value="BarChartBestHourOfDay">The Best Hour of the Day</option>
            <option value="BubbleChartBestTimeOfWeek">The Best Time of Week</option>
            <option value="BestImages">The Best Images</option>
            <option value="BestText">The Best Text</option>
          </SelectField>
          {Report.indexOf("BarChart") > -1 && (
          <DashboardContent
            key={updatedKey}
            RhyteItDashboardContent={RhyteItDashboardContent}
            Report={Report}
          />
          )}
          {Report.indexOf("BubbleChart") > -1 && (
          <SMMADBubbleChart
            key={updatedKey}
            SMMADBubbleChartData={RhyteItDashboardContent}
            Report={Report}
          />
          )}
          {Report === "BestImages" && (
          <SMMADTable
            key={updatedKey}
            AccountSelected={vAccountSelected}
            Report={Report}
          />
          )}          
          {Report === "BestText" && (
          <SMMADTextTable
            key={updatedKey}
            AccountSelected={vAccountSelected}
            Report={Report}
          />
          )} 

        </div>
        )}
      </Flex>
    </m.div>
  );
};

export default SMMADContentMetrics;
