import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import {ErrorBoundary} from 'react-error-boundary'


import Header from "../HeaderMenu/LandingPageNavBar";
//import Header from "../RhyteItComponents/Header/Header";


import  {listProducts}  from '../../graphql/queries'
import  {createPurchase, createAudienceGeoInfo}  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import {motion as m} from "framer-motion";
import { UserContext} from '../../BusinessLogic/Hooks/UserContext';
import {ValidateUser} from '../../BusinessLogic/UserManagement';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import getStripe from '../Stripe/getStripe';
import {uuidv4} from "../../BusinessLogic/UtilitiesManagement";
import DMPDCustomerApplause from './DMPDCustomerApplause';
import Footer from '../RhyteItComponents/Footer/Footer';

import {
  Button ,
  Flex,
  TextField,
  Image,
  Text

} from '@aws-amplify/ui-react';
import { Breadcrumbs } from '@aws-amplify/ui-react';
import styles from './Product.module.css';
import { SEO } from '../SEO/SEO';


function ErrorFallback({error, resetErrorBoundary}) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

const Product = () => {      

        const client = generateClient();

        let debugConsoleOutPut = true

        const { productParam } = useParams();


        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   
        const [RecordUpdated, setRecordUpdated] = useState(false)  
        const [UserEmail, setUserEmail] = useState("")  
        
        const [InvalidEmail, setInvalidEmail] = useState(false)  

        const {Users, setUsers} = useContext(UserContext);   
        const [RhyteItProduct, setRhyteItProduct] = useState()  
        const [Environment, setEnvironment] = useState()  
        const [PaymentLine, setPaymentLine] = useState("")  
        const [DescriptionHtml, setDescriptionHtml] = useState("")  
        const [ThankYou, setThankYou] = useState(false)  

        useEffect(() => {

          (async function() {
            try {  
              if (productParam.includes("TEST") === true) {
                setEnvironment("TEST")

              } else {
                
                setEnvironment("PRODUCTION")
              }

                GetDigitalMarketPlaceComponent()

                //console.log("DigitalMarketPlace.useEffect[].productParam:  ",productParam)     


                } catch (err) {
                  console.error('RhyteItProducts.useEffect[].ERROR: ', err, err.stack); 
              }
            }
          )();   
      

        },[]) 

        useEffect(() => { 

            if (RhyteItProduct !== undefined) {
              
              console.log("Product.useEffect[RhyteItProduct].RhyteItProduct:  ",RhyteItProduct) 
              
              if (RhyteItProduct.PaymentMode === "payment" ) {

                let vPaymentDescription = "One time payment of $" + RhyteItProduct.Price

                setPaymentLine(vPaymentDescription)

              }              
            
              Dashboard(RhyteItProduct.Description)

            }
        },[RhyteItProduct]) 

        async function handleCheckout() {

          
          //console.log("Product.handleCheckout.UserEmail: ",UserEmail)
          //console.log("Product.handleCheckout.RhyteItProduct.PaymentMode: ",RhyteItProduct.PaymentMode)


          if (UserEmail !== "") {

              if (UserEmail.indexOf("@") > 2 && UserEmail.indexOf(".") > UserEmail.indexOf("@")+1 && UserEmail.indexOf(" ") < 0) {

                      if (RhyteItProduct.PaymentMode !== "free"){

                        
                        setInvalidEmail(false)

                        
                        //console.log("Product.handleCheckout.Environment: ",Environment)

                        const stripe = await getStripe(Environment);                                  
                        
                        //console.log("Product.handleCheckout.stripe: ",stripe)
                        //console.log("DigitalMarketPlace.handleCheckout.PriceId:  ",RhyteItProduct.PriceId)     

                        if (stripe !== "ERROR") {

                          const { error } = await stripe.redirectToCheckout({
                            lineItems: [
                              {
                                price: RhyteItProduct.PriceId,
                                quantity: 1,
                              },
                            ],
                            mode: RhyteItProduct.PaymentMode,
                            successUrl: RhyteItProduct.SuccessURL,
                            cancelUrl: RhyteItProduct.CancelURL,
                            customerEmail: UserEmail.replaceAll(' ', ''),
                          });

                          console.warn(error.message);

                      } else {

                        ErrorMessageStr = ("Something went wrong with checkout process. Its not you. Its us. Sigh....")
                        setErrorMessage(ErrorMessageStr)

                      }
                    } else {

                      CreateFreePurchase()

                    }
                          
              } else  {
                setInvalidEmail(true)
                let ErrorMessageStr = "Please enter a valid email"
                setUserEmail(ErrorMessageStr)
              }
            }    else  {
              setInvalidEmail(true)
              let ErrorMessageStr = "Please enter a valid email"
              setUserEmail(ErrorMessageStr)
            }  
        }              
           
        function UserEmail_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
                //console.log("AdultContentCreatorSearch.FindField_handleChange.event.target.value: ",event.target.value)
              }
    

              var ErrorMessageStr = ""   
              setErrorMessage(ErrorMessageStr)
              setUserEmail(event.target.value)
    
    
    
            } catch (err) { 
              console.error("AdultContentCreatorSearch.FindField_handleChange.Error:  ".err, err.stack); 
          }        
        }   

        async function CreateFreePurchase() {
          try {           
            
              setErrorMessage("")
              let createPurchaseResponse = ""
              

              var vUpdateDateTime = new Date().toISOString()
    
              if (debugConsoleOutPut === true) {
              // //console.log("ProductDetail.SaveRhyteItProduct.RhyteItProduct: ",RhyteItProduct)
             //   //console.log("ProductDetail.SaveRhyteItProduct.Users: ",Users)
                //console.log("ProductDetail.SaveRhyteItProduct.RhyteItProduct.RespondedDate Compare: ",RhyteItProduct[elementPos].RespondedDate === "1970-01-01T00:00:00.000Z" )
             }
    
              RhyteItProduct.UpdateDateTime = vUpdateDateTime       
              RhyteItProduct.UpdateName = UserEmail
   

              let vChargeSucceededId = await uuidv4()
              let vStripeEventId= "evt_" + await uuidv4() 
              let vStripeObjectId= "ch_" + await uuidv4()
              let vStripePaymentIntent= "pi_" + await uuidv4()

              let RhyteItChargeSucceededItem = {
                  "id": vChargeSucceededId,
                  "Email": UserEmail,
                  "StripeEventType": "charge.succeeded",
                  "StripeEventId": vStripeEventId,
                  "StripeObject": "charge",
                  "StripeObjectId": vStripeObjectId,
                  "StripePaymentIntent": vStripePaymentIntent,
                  "StripePaymentMethod": "Free",
                  "StripePaymentBrand": "Free",
                  "StripeStatus": "succeeded",
                  "ReceiptURL": "Free",
                  "Last4": "Free",
                  "PostalCode": "Free",
                  "Product": RhyteItProduct.Product,
                  "Environment": Environment,
                  "EmailVerified": "N/A",
                  "PurchaseStatus": "Charged",
                  "FileURL": "Pending",
                  "CreationName": UserEmail,
                  "UpdateName": UserEmail,
                  "CreationDateTime": vUpdateDateTime,
                  "UpdateDateTime": vUpdateDateTime
              }


              const variables = {
                input: RhyteItChargeSucceededItem
              };

        
              createPurchaseResponse  = await client.graphql({
                query: createPurchase,
                variables: variables
              });
              

                
              if (debugConsoleOutPut === true) {
              
                //console.log("Product.CreateFreePurchase.createPurchaseResponse: ",createPurchaseResponse)
    
              }

              if (debugConsoleOutPut === true) {
              
                //console.log("Product.CreateFreePurchase.createPurchaseResponse: ",createPurchaseResponse)
    
              }
                
                
                setErrorMessage(ErrorMessageStr)
    

        
              if (debugConsoleOutPut === true) {
              
                //console.log("Product.CreateFreePurchase.createPurchaseResponse.data.createPurchase: ",createPurchaseResponse.data.createPurchase)
    
              }

              setRecordUpdated(true)
              ErrorMessageStr = "Email sent to: " + createPurchaseResponse.data.createPurchase["Email"]
              setErrorMessage(ErrorMessageStr)
              setThankYou(true)
         
    
            } catch (err) { 
              console.error(err)
              ErrorMessageStr = ("Product.CreateFreePurchase.Error: ", err, err.stack)
              console.error(ErrorMessageStr)
              setErrorMessage(ErrorMessageStr)
          }        
        }    

        async function GetDigitalMarketPlaceComponent() {
          try {
                    setErrorMessage("")

                    const variables = { 
                      filter: {
                                ProductURLParam: {
                                      eq: productParam
                                  }
                              }
                    };              
                    
          
                    let getProductsData2  = await client.graphql({
                      query: listProducts,
                      variables: variables
                    });
                     
  
  
                    //console.log("GetProduct.getProductsData2: ",getProductsData2);
  
                    if (getProductsData2.data.listProducts.items.length > 0) {
                        //console.log("DigitalMarketPlaceComponentData2.data.listProduct.items.length: ",DigitalMarketPlaceComponentData2.data.listProducts.items.length);
                  
                        setRhyteItProduct( getProductsData2.data.listProducts.items[0])    
            
                  }
  
          } catch (err) { 
                          console.error('Product.GetProduct.ERROR: ', err, err.stack); 
                      }
          }            
  
          const Dashboard = (pDescription) => {  
          console.log("Product.Dashboard.pDescription: ",pDescription);

            let DescriptionArray = pDescription.split('\\n');

            let DescriptionList = [];

            DescriptionArray.forEach((Description, index) => {

              let vDescription = Description.indexOf("<br>")

              if (vDescription > -1) {
                DescriptionList.push(<br/>);

              } else {
                DescriptionList.push(<div className={styles.TextAreaField}>{Description}</div>);

              }
              
            });            

            //console.log("DigitalMarketPlaceDetail.DigitalMarketPlaceDetail.DescriptionList: ",DescriptionList[0]["props"]['children'])

            let DescriptionHtmlString = <div>{DescriptionList}</div>

            setDescriptionHtml(DescriptionHtmlString)
        }

    return ( 
    <m.div
      key="RhyteItProduct"
      initial={{y: "100%"}}
      animate={{y: "0%"}}
      transition={{duration:0.75, ease: "easeOut"}}
      exit={{ opacity: 1 }}
     >              
     <SEO
         title="products"
         description='Products to help you grow your OnlyFans page'
         name='RhyteIt'
         type='product' />               

          <div className={styles.SystemAdminBackground}>
            <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                >   
                  {
                            RhyteItProduct !== undefined &&  (
                  <Flex
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    alignContent="flex-start"
                    wrap="wrap"
                    gap="4rem"
                    className={styles.GridContainer}
                    >                              
                    
                    <Header pageSource="Product"/> 
                    <Text className={styles.TextProductTitle}>
                                    {RhyteItProduct.Product}
                                </Text>   
                    <Flex
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        alignContent="flex-start"
                        wrap="wrap"
                        gap="1rem"
                        className={styles.FlexProductColumns}
                        >                                                                    
                          <Image
                            className={styles.ProductImage}
                            src={RhyteItProduct.ProductImage}
                            objectFit="initial"
                            backgroundColor="initial"
                            onClick={() => alert('📸 Buy Now!')}
                          /> 
                    </Flex>          
                    <Flex
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          alignContent="flex-start"
                          wrap="wrap"
                          gap="3rem"
                          className={styles.FlexRowProductAttributes}
                          >   
                            <Flex
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="stretch"
                                alignContent="flex-start"
                                wrap="wrap"
                                gap="1rem"
                                className={styles.FlexRowDescription}
                                >                                                           
                              <Text>
                                {DescriptionHtml}
                              </Text> 
                            </Flex>  
                            {RhyteItProduct.PaymentMode !=="free" && (
                            <Flex
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="stretch"
                                alignContent="flex-start"
                                wrap="wrap"
                                gap="1rem"
                                className={styles.FlexRowPrice}
                                > 
                                <Text className={styles.TextFieldLabelThin}>
                                    Price ($USD):
                                </Text>                                                              
                                <Text className={styles.TextFieldThin}>
                                    {PaymentLine}
                                </Text>  
                            </Flex> 
                            )} 
                            <Flex
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="stretch"
                                alignContent="flex-start"
                                wrap="wrap" 
                                gap="1rem"
                                className={styles.FlexRowEmail}
                                >             
                              <Text className={styles.TextFieldLabelThin}>
                                  Email:
                              </Text>                                
                              <TextField label="Search" 
                                labelHidden={true} 
                                className= {InvalidEmail === false ? styles.TextField : styles.InvalidEmailField}
                                defaultValue={UserEmail}                                                
                                value={UserEmail}
                                placeholder='<enter email here>'
                                onChange={ (e) => UserEmail_handleChange(e, UserEmail) }
                                />   
                            </Flex>                                                   
                    </Flex>                              
                    <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      alignContent="flex-start"
                      wrap="wrap"
                      gap="1rem"
                      className={styles.FlexRowBuyNow}
                      >        
                      <Button 
                        className={styles.metallicbutton}                                       
                        onClick={ (e) => handleCheckout(e, RhyteItProduct) }
                        isDisabled={ThankYou}
                        variation="primary">
                        {ThankYou === false ? RhyteItProduct.PaymentMode === "free" ? "Free" : "Buy Now!": "Enjoy!"}
                      </Button>
                    {RhyteItProduct.PaymentMode !=="free" && (   
                      <Image
                        className={styles.Image}
                        src="https://cambridge-ai.center/wp-content/uploads/2022/07/logo-stripe.png"
                        objectFit="initial"
                        backgroundColor="initial"
                        onClick={() => alert('📸 BUY NOW!')}
                      />     
                      )     
                    }                               
                    </Flex>     
                    <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      alignContent="flex-start"
                      wrap="wrap"
                      gap="1rem"
                      className={styles.FlexRowEmail}
                      >  
                        <div className={styles.MessageBox}>{ErrorMessage}</div>                                             
                    </Flex>    
                    <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      alignContent="flex-start"
                      wrap="wrap"
                      gap="1rem"
                      className={styles.FlexRowCustomerApplause}
                      >  
                      {
                                RhyteItProduct !== undefined &&  (
                                  <DMPDCustomerApplause Page={RhyteItProduct.Product} ProductId={RhyteItProduct.id}/>    
                                )
                      }                                        
                    </Flex>        
                    <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      alignContent="flex-start"
                      wrap="wrap"
                      gap="1rem"
                      className={styles.FlexRowFooter}
                      >  
                      <Footer/>                                     
                    </Flex>                                                                     
                  </Flex> 
                    )
                  }    
                  <Image
                    alt="Amplify logo"
                    src="/images/bee.gif"
                    className={styles.HobnilTheBee}
                    onClick={() => alert('📸 Say cheese!')}
                  />                   
            </ErrorBoundary>
            </div>  
        )  
    </m.div>                      

    )

  }
 

export default Product;