import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext, useRef } from 'react';

import  { listSMMADHashTags  }  from '../../../graphql/queries'
import  {createSMMADHashTags }  from '../../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import {motion as m} from "framer-motion";
import HashTagTable from "./SMMADHashTagsTable";
import { UserContext} from '../../../BusinessLogic/Hooks/UserContext';
import SMMADContent from "../SMMADContent/Content";
import SMMADText from "../SMMADText/SMMADText";


import {
  Button ,   Flex, TextField, Fieldset
} from '@aws-amplify/ui-react';
import styles from './SMMADHashTags.module.css';
import {get_uuidv4} from '../../../BusinessLogic/uuidv4';

const SMMADHashTags = (props) => {       
  
  SMMADHashTags.propTypes = {
          AccountSelected: PropTypes.string,
          CampaignSelected: PropTypes.string,
          ImageToTextRelationship: PropTypes.bool
        };

        const client = generateClient();
        const [SMHashTag, setSMHashTag] = useState([]) 
        const [vAccountSelected, setvAccountSelected] = useState(props.AccountSelected)   
        const [vCampaignSelected, setvCampaignSelected] = useState(props.CampaignSelected) 
  
        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   
        let debugConsoleOutPut = true

        const {Users, setUsers} = useContext(UserContext);     
        

        const [updatedKey, setupdatedKey] = useState(Date.now())   
        const [vExecuteDataRefresh, setvExecuteDataRefresh] = useState(Date.now());
        const [vAddNewRow, setvAddNewRow] = useState(Date.now());
        const [vHashTag, setvHashTag] = useState();

        

        useEffect(() => {

          //console.log("HashTag.useEffect[].props.ImageToTextRelationship :  ",props.ImageToTextRelationship );
          

          (async function() {
            try {  

              GetHashTag()               


                } catch (err) {
                  console.error('HashTag.useEffect[].ERROR: ', err, err.stack); 
              }
            }
          )();   
      

        },[]) 


        useEffect(() => {
          //console.log("HashTag.useEffect[SMHashTag].SMHashTag:  ",SMHashTag)
          //console.log("SBSMQueries.useEffect[SBSMNewsQueryTerms].Object.keys(SBSMNewsQueryTerms).length:  ",Object.keys(SBSMNewsQueryTerms).length )
 
           if (SMHashTag.length > 0) {            
             setupdatedKey(Date.now())    
           }
 
 
         },[SMHashTag])    

         useEffect(() => {

          GetHashTag()
 
         },[vExecuteDataRefresh])    

     

              

        async function GetHashTag() {
          try {
                    setErrorMessage("")
                    setSMHashTag([])

                    const variables = {
                      filter: {
                        Account: {
                                eq: vAccountSelected
                            }
                      }
                    };

                    const HashTagData2  = await client.graphql({
                      query: listSMMADHashTags,
                      variables: variables
                    });
      
          

                    if (HashTagData2.data.listSMMADHashTags.items.length > 0) {

                        const HashTag1 = HashTagData2.data.listSMMADHashTags.items 
                        //console.log("SMMADHashTags.GetHashTag.HashTag1:  ",HashTag1)     

                        setSMHashTag(HashTag1)  
           
                  }

          } catch (err) { 
                          console.error('HashTag.GetHashTag.ERROR: ', err, err.stack); 
                      }
        }            



        function HashTag_handleChange(event,data) {
          try {
    
    
                      setvHashTag(event.target.value)
    
          } catch (err) { 
                          console.error('SMMADHashTagRow.HashTag_handleChange.ERROR: ', err, err.stack); 
                      }
        }  
        
        async function SaveRow(event, data) {
          try {           
            

          if (vHashTag.length > 0) {  
          
              let vAccountType = ""
        
              if (Users.includes("@rhyteit.com") || Users.includes("@richbi.ch"))  {

              vAccountType = "Internal"
              
            } else {
            
              vAccountType = "Customer"

              }
              setErrorMessage("")
    
              var vUpdateDateTimeNew = new Date().toISOString()
          
              let vId =  get_uuidv4

    
              let TextItem = {                
                id: vId,
                Account: vAccountSelected,
                AccountType: vAccountType,
                Campaign: vCampaignSelected,
                HashTag: vHashTag,    
                PostedCount: 0,
                Weight: 0,
                Active: true,
                SMMADHashTagStatus: "new",
                CreationName: vAccountSelected,
                UpdateName: vAccountSelected,
                CreationDateTime: vUpdateDateTimeNew,
                UpdateDateTime: vUpdateDateTimeNew

              }
    
    
                //console.log("SMMADHashTags.SaveRow.TextItem:  ",TextItem)                      
    
                  
                  const updateSMMADHashTagResponse = await client.graphql({
                    query: createSMMADHashTags,
                    variables: {
                      input: TextItem
                    }
                  });  
    
                  //console.log("SMMADHashTags.SaveRow.updateSMMADHashTagResponse:  ",updateSMMADHashTagResponse) 

                  GetHashTag()
      
            
                } else {
                  
                  setErrorMessage("Please enter a hash tag")

                }

    
            } catch (err) { 
              ErrorMessageStr = ("SMMADHashTags.SaveRow.Error: ", err, err.stack)
              console.error(ErrorMessageStr)
              setErrorMessage(ErrorMessageStr)
          }        
        }              


    return ( 
                <div>                      
                  <Flex
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      alignContent="flex-start"
                      wrap="nowrap"
                      gap="3rem"
                      className={styles.GridContainer}
                    >   
                    <Fieldset 
                              className={styles.Fieldset}
                              size="small">  
                      <legend>Hash Tags</legend>   
                      <Flex
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          alignContent="flex-start"
                          wrap="nowrap"
                          gap="3rem"
                        >   
                      <TextField 
                          className={styles.TextField} 
                          name="HashTag"     
                          placeholder="Add new hash tag"                   
                          defaultValue={vHashTag}
                          onChange={ (e) => HashTag_handleChange(e, vHashTag) }/>                             
                        <Button className={styles.StorageManagerButton}
                                      variation="primary"         
                                      onClick={ (e) => SaveRow(e)}
                                      >
                                Add HashTag
                          </Button>                                                                                                                                                                                                                                                                                            
                      </Flex>              
                {SMHashTag.length > 0 && (
                      <HashTagTable    key={updatedKey} AccountSelected={vAccountSelected} CampaignSelected={vCampaignSelected}
                     SMHashTag={SMHashTag} />      
                      )  }      
                    </Fieldset>   
           
                    {SMHashTag.length > 0 && (
                      <Fieldset 
                      className={styles.Fieldset}
                      size="small">  
                              <legend>Content</legend>      
                        {vCampaignSelected !== undefined  && (<SMMADContent key={vCampaignSelected} AccountSelected={vAccountSelected}  CampaignSelected={vCampaignSelected}  ImageToTextRelationship={props.ImageToTextRelationship} HashTagMap={SMHashTag}/>)  }                                                                                                                                                                                                                                                                                                                                         
                        
                        </Fieldset>  
                      )  }       
              
              {SMHashTag.length > 0 && props.ImageToTextRelationship === false &&(
                        <Fieldset 
                          className={styles.Fieldset}
                          size="small">  
                                  <legend>Text</legend>                                                              
                        {vCampaignSelected !== undefined  && (<SMMADText  AccountSelected={vAccountSelected}  CampaignSelected={vCampaignSelected}  HashTagMap={SMHashTag}  ContentId="NA"/>)} 
                      
                        </Fieldset>
                      )  }       
                    </Flex>   
                    </div>    

    )

  }
 

export default SMMADHashTags;