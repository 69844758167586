
import React, { useState , useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Button,
  Image,
  TableBody,
  TableRow,
  TableCell,
  Text
  } from '@aws-amplify/ui-react';

  import  {  }  from '../../../graphql/queries'
  import  { deleteContent, updateNewsletterEditionTracking, createContent, deleteNewsletterEditionTracking}  from '../../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";
  import {
    useInView,
    useScroll,
    useTransform,
    motion,
    useAnimation,
  } from "framer-motion";
  import styles from './NewsletterPublisherRow.module.css';  
  import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
  
function NewsletterPublisherRow(
  props
) {       

  NewsletterPublisherRow.propTypes = {
    Newsletter: PropTypes.object,
    Subscriber: PropTypes.object,
    Users: PropTypes.string,
    };

    const client = generateClient();
  
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vNewsletter, setvNewsletter] = useState(props.Newsletter)  
    const [vSubscriber, setvSubscriber] = useState(props.Subscriber)   
    const [Users, setUsers] = useState(props.Users)   

    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);

    const [vExpandRow, setvExpandRow] = useState(false)    
    const [vDeliveryStatus, setvDeliveryStatus] = useState(props.Subscriber["DeliveryStatus"]) 
  
        


      useEffect(() => {

        //console.log("NewsletterPublisherRow.useEffect[].props.Subscriber:  ",props.Subscriber) 

      },[])  

      useEffect(() => {
      //console.log("NewsletterPublisherRow.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);

        if (isInView) {
          mainControls.start("visible");
        }
      }, [isInView]);      

      useEffect(() => {
      //console.log("NewsletterPublisherRow.useEffect[vExpandRow].vExpandRow: " ,vExpandRow);
      }, [vExpandRow]);   
      
      useEffect(() => {
        //console.log("NewsletterPublisherRow.useEffect[vExpandRow].vExpandRow: " ,vExpandRow);
        }, [vDeliveryStatus]);    
      

      async function ExpandRow(pExpandRow) {
      try {
        let pExpandRowTmp = pExpandRow 
        //console.log("NewsletterPublisherRow.ExpandRow.pExpandRowTmp: " ,pExpandRowTmp);
        setvExpandRow(!pExpandRowTmp)


          } catch (err) { 

          ErrorMessageStr = "NewsletterPublisherRow.PlayEpisode.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }               

      }     

      async function SaveRecord(event) {
        
        let vSubStatusTmp = vDeliveryStatus
  
        //console.log("NewsletterPublisherRow.Unsubscribe.event: ",event)
        try {           
           
          if (vDeliveryStatus === "PUBLISH") {
            vSubStatusTmp = "SEND"
          } else {
            vSubStatusTmp = vDeliveryStatus
          }

          setvDeliveryStatus(vSubStatusTmp)
          UpdateRecord(vSubStatusTmp)
  
          } catch (err) { 
            RhyteItLogging(Users, "NewsletterPublisherRow", "Unsubscribe",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
            console.error("NewsletterPublisherRow.Unsubscribe.Error:  ".err, err.stack); 
        }        
      }    

      async function UpdateRecord(pStatus) {
        
  
        try {           
            var today = new Date();
            setErrorMessage("")
            
            var vUpdateDateTime = new Date().toISOString()

            let vNewsletterEditionCreateItem = {
              "id": vSubscriber["id"],
              "AccountGroup": vSubscriber["AccountGroup"],
              "CampaignName": vSubscriber["CampaignName"],
              "Newsletter": vSubscriber["Newsletter"],
              "Edition": vSubscriber["Edition"],
              "EmailHTML": vSubscriber["EmailHTML"],
              "SESMessageId": vSubscriber["SESMessageId"],
              "S3HtmlLocation": vSubscriber["S3HtmlLocation"],
              "S3Bucket": vSubscriber["S3Bucket"],
              "S3Key": vSubscriber["S3Key"],
              "RecipientEmail": vSubscriber["RecipientEmail"],
              "SendDateTime": vSubscriber["SendDateTime"],
              "FirstOpenDateTime": vSubscriber["FirstOpenDateTime"],
              "OpenDateTime": vSubscriber["OpenDateTime"],
              "LastClickDateTime": vSubscriber["LastClickDateTime"],
              "UnsubscribeDateTime": vSubscriber["UnsubscribeDateTime"],
              "SendCount": vSubscriber["SendCount"],
              "OpenCount": vSubscriber["OpenCount"],
              "ClickCount": vSubscriber["ClickCount"],
              "DeliveryStatus": pStatus,
              "BounceReason": vSubscriber["BounceReason"],
              "UnsubscribeStatus": vSubscriber["UnsubscribeStatus"],
              "AudienceSegment": vSubscriber["AudienceSegment"],
              "DeviceType": vSubscriber["DeviceType"],
              "UserAgent": vSubscriber["UserAgent"],
              "IPAddress": vSubscriber["IPAddress"],
              "City": vSubscriber["City"],
              "Country":vSubscriber["Country"],
              "ContentVersion":vSubscriber["ContentVersion"],
              "NewsletterTrackingStatus": pStatus,
              "CreationName": vSubscriber["CreationName"],
              "UpdateName": Users,
              "CreationDateTime": vSubscriber["CreationDateTime"],
              "UpdateDateTime": vUpdateDateTime
            }
    
             //console.log("NewsletterSubscriberTable.CreateNewsletterEditionTracking.vNewsletterEditionCreateItem: ",vNewsletterEditionCreateItem)
    
              let updateNewsletterEditionTrackingResponse = await client.graphql({                    
                      query: updateNewsletterEditionTracking,
                      variables:{input: vNewsletterEditionCreateItem}            
                }
              )
            
            //console.log("NewsletterSubscriberTable.SaveNewsletterEditionToSubscriber.updateNewsletterEditionTrackingResponse: ",updateNewsletterEditionTrackingResponse)
    

            setvSubscriber(updateNewsletterEditionTrackingResponse.data.updateNewsletterEditionTracking)        
  
          } catch (err) { 
            RhyteItLogging(Users, "NewsletterPublisherRow", "Unsubscribe",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
            console.error("NewsletterPublisherRow.Unsubscribe.Error:  ".err, err.stack); 
        }        
      }    
  

      async function DeleteRow(event,data) {
        try {

                
                setErrorMessage("")   

                const DeleteNewsletterEditionTrackingRow = {
                  id: vSubscriber.id,
                  _version: vSubscriber._version
                }
                

                const vDeleteResponse = await client.graphql({
                  query: deleteNewsletterEditionTracking,
                  variables: {
                    input: DeleteNewsletterEditionTrackingRow
                  }
                });  

                ErrorMessageStr = vDeleteResponse
                setErrorMessage(ErrorMessageStr)  
  
                
        
            } catch (err) { 

              RhyteItLogging(Users, "NewsletterPublisherRow", "DeleteRow",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
              console.error("NewsletterPublisherRow.DeleteRow.Error:  ".err, err.stack);       
          }               
        
      }       

      return (
        <TableBody>         
          <TableRow  className={vSubscriber.DeliveryStatus === "Delivery" ? styles.TableRowDelivery : vSubscriber.DeliveryStatus === "Bounce" ? styles.TableRowBounce :  vSubscriber.DeliveryStatus === "OPENED" ? styles.TableRowOpened : vSubscriber.DeliveryStatus === "SEND" ? styles.TableRowSend : vSubscriber.DeliveryStatus === "PUBLISH" ? styles.TableRowPublish : styles.TableRow}  key={vSubscriber.id}>    
            <TableCell className={styles.TableCell}> 
                <Button
                      loadingText=""
                      ariaLabel=""
                      className={styles.Button}
                      onClick={(e) => ExpandRow(vExpandRow)}
                    >
                    +
                </Button>   
            </TableCell>  
            <TableCell className={styles.TableCell}> 
                <Button
                      loadingText=""
                      ariaLabel=""
                      className={styles.Button}
                      onClick={(e) => DeleteRow(e, vSubscriber)}
                    >
                    Delete
                </Button>   
            </TableCell>               
            <TableCell className={styles.TableCell}>  
              <Text  className={styles.TextField}>
              {vSubscriber.RecipientEmail}
              </Text>                           
            </TableCell>   
            <TableCell className={styles.TableCell}> 
                <Button
                      loadingText=""
                      ariaLabel=""
                      className={styles.Button}
                      onClick={(e) => SaveRecord(e)}
                    >
                    {vDeliveryStatus}
                </Button>   
            </TableCell>                                    
            <TableCell className={styles.TableCell}>  
              <Text  className={styles.TextField}>
              {vSubscriber.SendDateTime !== "1999-01-01T01:00:00.000Z" ? new Date(vSubscriber.SendDateTime).toISOString().slice(0, 10) : ""}
              </Text>                           
            </TableCell>          
            <TableCell className={styles.TableCell}>  
              <Text  className={styles.TextField}>
              {vSubscriber.FirstOpenDateTime !== "1999-01-01T01:00:00.000Z" ? new Date(vSubscriber.FirstOpenDateTime).toISOString().slice(0, 10) : ""}
              </Text>                           
            </TableCell>     
            <TableCell className={styles.TableCell}>  
              <Text  className={styles.TextField}>
              {new Date(vSubscriber.UpdateDateTime).toISOString().slice(0, 10)}
              </Text>                           
            </TableCell>                                          
          </TableRow>  

          {
            vExpandRow === true &&  (                   
              <TableRow  className={styles.TableRow}  key={vSubscriber.CreationDateTime}>              
              <TableCell className={styles.TableCell}>  
                <Text  className={styles.TextField}>               
                </Text>                           
              </TableCell>  
              <TableCell colSpan="1">                   
                  <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      alignContent="center"
                      wrap="nowrap"
                      gap="1rem"
                    >                 
                    <Text  className={styles.TextFieldTitle}>
                    Send Count:
                    </Text>  
                    <Text  className={styles.SubTextField}>      
                      {vSubscriber.SendCount}
                    </Text>                             
                  </Flex>                                    
                </TableCell>             
                <TableCell className={styles.TableCell}>                  
                  <Flex
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                        gap="1rem"
                      >                 
                      <Text  className={styles.TextFieldTitle}>
                      Open Count:
                      </Text>                    
                    <Text  className={styles.SubTextField}>
                    {vSubscriber.OpenCount}
                    </Text>                              
                  </Flex>                             
                </TableCell>            
                <TableCell className={styles.TableCell}>  
                <Flex
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                        gap="1rem"
                      >                 
                      <Text  className={styles.TextFieldTitle}>
                      Click Count:
                      </Text>                       
                      <Text  className={styles.SubTextField}>
                      {vSubscriber.ClickCount}
                      </Text>                                 
                  </Flex>                          
                </TableCell>            
                <TableCell className={styles.TableCell}> 
                <Flex
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                        gap="1rem"
                      >                 
                      <Text  className={styles.TextFieldTitle}>
                      Newsletter Tracking Status:
                      </Text>                        
                  <Text  className={styles.SubTextField}>
                  {vSubscriber.NewsletterTrackingStatus}
                  </Text>                              
                </Flex>                                  
                </TableCell>    
                <TableCell className={styles.TableCell}>  
                <Flex
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                        gap="1rem"
                      >                 
                      <Text  className={styles.TextFieldTitle}>
                      Delivery Status:
                      </Text>                                  
                      <Text  className={styles.SubTextField}>
                      {vSubscriber.BounceReason}
                      </Text>                                 
                  </Flex>                          
                </TableCell>                                 
              </TableRow>  
            )
          }              
        {
          ErrorMessage.length > 0 &&             
            <TableRow  className={styles.ErrorMessageRow}>              
              <TableCell 
                colSpan="3">      
                {ErrorMessage}
              </TableCell>               
            </TableRow>  
        }               
        </TableBody>
      
    )
  }
export default NewsletterPublisherRow;

