import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import {Amplify} from 'aws-amplify';
import {ErrorBoundary} from 'react-error-boundary'
import {motion as m} from "framer-motion";
import { useNavigate, useLocation } from "react-router-dom";

import  {listAudienceGeoInfos, getOnlyFansProspectingProfiles}  from '../../../graphql/queries'
import  {deleteApplicants, createApplicants}  from '../../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import HeaderMenu from "../../HeaderMenu/MainHeader";
import { UserContext,UserAuthenticatedContext,UserSubcribedContext} from '../../../BusinessLogic/Hooks/UserContext';
import {ValidateUser} from '../../../BusinessLogic/UserManagement';
import {uuidv4} from "../../../BusinessLogic/UtilitiesManagement";

import {
  Button ,
  Authenticator,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TextAreaField,
  TextField,
  Card,
  Breadcrumbs,
  View,
  Flex,
  Text,
  SelectField,
  TableHead,
  CheckboxField

} from '@aws-amplify/ui-react';
import styles from './ApplicantDetailMeeting.module.css';


    
const client = generateClient();


const ApplicantDetailMeeting = (props) => {       

      ApplicantDetailMeeting.propTypes = {
        Meetings: PropTypes.array,
        RhyteItUser: PropTypes.array
        };

        let debugConsoleOutPut = true

        const location = useLocation();
        const { Meetings } = location.state;


        const [vMeetings, setvMeetings] = useState(props.Meetings)  

        const [vMeetingDateTime, setvMeetingDateTime] = useState()  


        useEffect(() => {

          console.log("ApplicantDetailMeeting.useEffect[].Meetings: ",Meetings)
          console.log("ApplicantDetailMeeting.useEffect[].vMeetings: ",vMeetings)

          setvMeetingDateTime(new Date(vMeetings.MeetingDateTime).toLocaleString())
    


        },[]) 


        function MeetingStatus_handleChange(event, data) {
          try {              

    
            vMeetings.MeetingStatus = event.target.value
         
             setvMeetings(vMeetings)   
    
     
    
            } catch (err) { 
              console.error("ApplicantDetailMeeting.MeetingStatus_handleChange.Error:  ", err, err.stack); 
          }        
        }      

        function MeetingDateTime_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("ApplicantDetailMeeting.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("ApplicantDetailMeeting.Subscribed_handleChange.event: ",event)
              }
    
    
              vMeetings.MeetingDateTime = event.target.value
             // //console.log("ApplicantDetailMeeting.Subscribed_handleChange.vMeetings[elementPos].Subscribed: ",vMeetings[elementPos].Subscribed)
    
              setvMeetings(vMeetings)   
    
     
    
            } catch (err) { 
              console.error("ApplicantDetailMeeting.MeetingDateTime_handleChange.Error:  ", err, err.stack); 
          }        
        }          

        function MeetingContext_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("ApplicantDetailMeeting.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("ApplicantDetailMeeting.Subscribed_handleChange.event: ",event)
              }
    
    
              vMeetings.MeetingContext = event.target.value
             // //console.log("ApplicantDetailMeeting.Subscribed_handleChange.vMeetings[elementPos].Subscribed: ",vMeetings[elementPos].Subscribed)
    
              setvMeetings(vMeetings)   
    
     
    
            } catch (err) { 
              console.error("ApplicantDetailMeeting.MeetingContext_handleChange.Error:  ", err, err.stack); 
          }        
        }     
        

    return ( 
      
             <Flex
                          direction="column"
                          alignItems="flex-start"
                          gap="2rem"
                          className={styles.GridContainer}
                        >  
                      {vMeetings !== undefined  && (
                      <Table>          
                        <TableHead>          
                          <TableRow className={styles.TableHeaderRow}>
                            <TableCell className={styles.TableHeaderRowCells} >Status</TableCell> 
                            <TableCell className={styles.TableHeaderRowCells} >Context</TableCell> 
                            <TableCell className={styles.TableHeaderRowCells} >Date Time</TableCell> 
                          </TableRow>                                      
                        </TableHead>   
                        <TableBody>
                          <TableRow className={styles.TableRow}>
                            <TableCell className={styles.TableRowData}>                                       
                            <TextField className={styles.TextField} 
                                name="vMeetings.MeetingStatus"                           
                                defaultValue={vMeetings.MeetingStatus}
                                onChange={ (e) => MeetingStatus_handleChange(e, vMeetings) } />             
                            </TableCell>
                            <TableCell className={styles.TableRowData}>                                    
                              <TextAreaField                                   
                                className={styles.TextAreaField} 
                                name="MeetingContext"                     
                                defaultValue={vMeetings.MeetingContext}
                                onChange={ (e) => MeetingContext_handleChange(e, vMeetings) }
                                rows={5}
                                columns={3}/> 
                            </TableCell>
                            <TableCell className={styles.TableRowData}>   
                              <TextField className={styles.URLField} 
                                name="vMeetings.MeetingDateTime"                           
                                defaultValue={vMeetingDateTime}
                                onChange={ (e) => MeetingDateTime_handleChange(e, vMeetings) } />
                            </TableCell>
                          </TableRow >
                        </TableBody>
                      </Table>   
        ) 
      }             
             </Flex>    

    )

  }
  

export default ApplicantDetailMeeting;