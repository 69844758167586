import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
 import styles from './NavBarClean.module.css';  
import {Link } from 'react-router-dom';
import {
  TableCell,
  TableRow,
  Table,
  TableBody
  } from '@aws-amplify/ui-react';

const NavBarClean = (props) => {

  NavBarClean.propTypes = {
      pageSource: PropTypes.string,
      User: PropTypes.object
    };


useEffect(() => {

          
},[])        

  return (
  <div className={styles.navBar}>
    <nav className={styles.nav}> 
          <Table className={styles.Table}> 
            <TableBody>
              <TableRow className={styles.TableRow}>
                <TableCell as="td" colSpan="1"  className={styles.TableCell}>  
                    <div to="/LandingPageV4"  className={styles.logoimgDiv} >
                      <img className={styles.logoimg} src="https://rhyteitartwork.s3.us-west-2.amazonaws.com/RhyteItFavicon2.svg" alt="RhyteIt"/>   
                    </div>
                </TableCell>    
                <TableCell as="td" colSpan="3"  className={styles.TableCell}>  
                    <div className={styles.logoName} to="/LandingPage">
                    RhyteIt
                    </div>
                </TableCell>           
              </TableRow>   
              <TableRow className={styles.TableRow}>  
                <TableCell  className={styles.TableCell}>  
                  <Link className={styles.navLinkDAO} to="/MoreInfo">
                    More Info
                  </Link>        
                </TableCell> 
                <TableCell className={styles.TableCell}>          
                  <Link className={styles.navLinkDAO} to="/MainMenuV2">
                    Sign In
                  </Link>        
                </TableCell> 
                <TableCell   className={styles.TableCell}>      
                  <Link className={styles.navLinkDAO} to="/Cancel">
                    Cancel
                  </Link>        
                </TableCell>                 
              </TableRow>       
            </TableBody>
          </Table>        
      
    </nav>
   </div>
  )
}

export default NavBarClean;