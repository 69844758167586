
import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead
  } from '@aws-amplify/ui-react'; 
import SBSMArticleRow from './SBSMNewsRow';  
import styles from './SBSMNewsTable.module.css';


const SBSMNewsTable = (props) => {       

  SBSMNewsTable.propTypes = {
      SBSMNewsArticles: PropTypes.array,
      RhyteItUser: PropTypes.array,
      SBSMLogos: PropTypes.object
      };

    let debugConsoleOutPut = true


    const [updatedKey, setupdatedKey] = useState(Date.now())  
    const [SBSMNewsArticles, setSBSMNewsArticles] = useState(Object.entries(props.SBSMNewsArticles))
    const [ShowTable, setShowTable] = useState(false);
    const [SBSMLogos, setSBSMLogos] = useState(props.SBSMLogos);   

    useEffect(() => {
       

        if (Object.keys(SBSMNewsArticles).length > 1) {
          setShowTable(true)
        }

      },[])  

      useEffect(() => {

        //console.log("SBSMNewsTable.useEffect[].SBSMNewsArticles:  ",SBSMNewsArticles) 
  
      },[SBSMNewsArticles])        

      useEffect(() => {

        //console.log("SBSMNewsTable.useEffect[SBSMLogos].SBSMLogos:  ",SBSMLogos) 
  
      },[SBSMLogos])          

  return ( 
    <div>
      <Table className={styles.RhyteItTable}   highlightOnHover={true}> 
              {
                ShowTable === true && Array.isArray(SBSMNewsArticles)
                ? SBSMNewsArticles.map((SBSMArticle) => (         
                <SBSMArticleRow  key={SBSMArticle[1].id} SBSMLogos={SBSMLogos} SBSMArticle={SBSMArticle[1]}/>))  : null
                 
              }                    
        </Table>
      </div> 
    
  )
}

export default SBSMNewsTable;


