
import React, { useState , useEffect, useRef, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead,
  Image,
  Button,
  Flex,
  SelectField
  } from '@aws-amplify/ui-react'; 
import NewsletterEditionRow from './NewsletterEditionRow';
import { generateClient } from 'aws-amplify/api';
import {
  useInView,
  useScroll,
  useTransform,
  motion,
  useAnimation,
} from "framer-motion";
import styles from './NewsletterEditorTable.module.css';
import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
import {
  listNewsletterEditions,
} from "../../../graphql/queries";
import {
  createNewsletterEdition, deleteNewsletterEdition
} from "../../../graphql/mutations";


const NewsletterEditorTable = (props) => {       

  NewsletterEditorTable.propTypes = {
      Newsletter: PropTypes.object,
      Users: PropTypes.string,
      };

    let debugConsoleOutPut = true
  

    const [vNewsletter, setvNewsletter] = useState(props.Newsletter)   
    const [Users, setUsers] = useState(props.Users)   
    const [vNewsletterEditions, setvNewsletterEditions] = useState()    
    const [ShowTable, setShowTable] = useState(false); 
    
    var ErrorMessageStr = ""
    const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)  
    
    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);
    const client = generateClient();
    
    const [vUpdateKey, setvUpdateKey] = useState()   
    const [vNewsletterEditionsRefresh, setvNewsletterEditionsRefresh] = useState()  
    let [vNewNewsletterEdition, setvNewNewsletterEdition] = useState();
    const [vAddNewsletterEditionsVisible, setvAddNewsletterEditionsVisible] = useState(false);
    const [updatedKey, setupdatedKey] = useState();
    const [vNewsletterEditionSelected, setvNewsletterEditionSelected] = useState({"Edition":"None"});
    let [vNewsletterEditionList, setvNewsletterEditionList] = useState();

    const [vNewsletterEditionVisible, setvNewsletterEditionVisible] = useState(false);
    var vNewEdition = 1

    useEffect(() => {

      

    },[])  

    useEffect(() => {
      ////console.log("""Hero.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);
   
       if (isInView) {
         mainControls.start("visible");
       }
     }, [isInView]);

    useEffect(() => {

     if (vNewsletterEditions !== undefined) {
        if (vNewsletterEditions.length > 0) {
          setShowTable(true)        
          setvUpdateKey(Date.now())
          setvNewsletterEditionsRefresh(Date.now)
          }
      }

    },[vNewsletterEditions])  

    useEffect(() => {
      ////console.log("""NewsletterEditorTable.useEffect[vNewsletter].vNewsletter:  ",vNewsletter)
  
      if (vNewsletter !== undefined) {
        GetNewsletterEditions()
      }
    }, [vNewsletter]);
  
    useEffect(() => {
      ////console.log("""NewsletterEditorTable.useEffect[vHostEmail].vHostEmail:  ",vHostEmail)

      if (vNewsletterEditions !== undefined) {

        setvUpdateKey(Date.now())

       }
 
     },[vNewsletterEditions])     


     async function GetNewsletterEditions() {
      try {
        setErrorMessage("");
        
        ////console.log("""NewsletterEditorTable.GetNewsletterEditions.Users: ", Users);
        let variables;
        if (Users !== "edwardhawke@rhyteit.com") {
          variables = {
            filter: {
              AccountGroup: {
                eq: vNewsletter["AccountGroup"],
              },
              Newsletter: {
                eq: vNewsletter["Newsletter"],
              },
            },
          };
        } else {
          variables = {};
        }
  
        const listNewsletterEditionsData2 = await client.graphql({
          query: listNewsletterEditions,
          variables: variables,
        });
  
        ////console.log("""NewsletterEditorTable.GetNewsletterEditions.listNewsletterEditions.data.listNewsletterEditions.items.length : ", listNewsletterEditionsData2.data.listNewsletterEditions.items.length );
  
        if (listNewsletterEditionsData2.data.listNewsletterEditions.items.length > 0) {
          const NewsletterEditorTable1 =
            listNewsletterEditionsData2.data.listNewsletterEditions.items;
            let vSortedNewsletterEditions = SortNewsletterEditionsByName(NewsletterEditorTable1)
            setvNewsletterEditions(vSortedNewsletterEditions)
            setvNewsletterEditionList(vSortedNewsletterEditions)
        } 
  
  
  
      } catch (err) {
        RhyteItLogging(
          Users,
          "NewsletterEditorTable",
          "GetNewsletterEditions",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }
  
    function SortNewsletterEditionsByName(pNewsletterEditionEmail) {
      pNewsletterEditionEmail.sort(GFG_sortFunction);
      return pNewsletterEditionEmail;
      //    ////console.log(""JSON.stringify(pEmails));
    }
  
    function GFG_sortFunction(a, b) {
      let NewsletterEditionA = a.Edition;
      let NewsletterEditionB = b.Edition;
      return NewsletterEditionA > NewsletterEditionB ? 1 : -1;
    }
  
    function NewNewsletterEdition_handleChange(event, data) {
      try {
        setvNewNewsletterEdition(event.target.value);
      } catch (err) {
        RhyteItLogging(
          Users,
          "NewsletterEditorTable",
          "NewNewsletter_handleChange",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }
  
    async function CreateNewsletterContextJSON() {
      try {

        ////console.log(""NewsletterEditorTable.CreateNewsletterContextJSON.vNewsletterEditions: ", vNewsletterEditions);



        if(vNewsletterEditions === undefined) {
          vNewEdition = 1         
        } else {
          const vEditions = [];

          vNewsletterEditions.forEach(obj => {
            if (obj.hasOwnProperty("Edition")) {
              vEditions.push(obj["Edition"]);
            }
          });
  
          vNewEdition = Math.max(...vEditions) + 1;
  
          ////console.log(""NewsletterEditorTable.CreateNewsletterContextJSON.vNewEdition: ", vNewEdition);
        }

        let vId = (vNewsletter["Newsletter"] + "-" + vNewsletter["AccountGroup"] + "-" + vNewEdition).replace(/["']/g, "").replace(/\s+/g, '');

        let vContentJSON = {id: vId, Sections:[]}
        
        ////console.log(""NewsletterEditorTable.CreateNewsletterContextJSON.vNewsletter: ", vNewsletter["TemplateJSON"]);

        let vTemplateJSON = JSON.parse(vNewsletter["TemplateJSON"])
        
        ////console.log(""NewsletterEditorTable.CreateNewsletterContextJSON.vTemplateJSON: ", vTemplateJSON);

        if (vTemplateJSON["Sections"] !== undefined) {
          
          let vTemplateSections = vTemplateJSON["Sections"]

          ////console.log(""NewsletterEditorTable.CreateNewsletterContextJSON.vTemplateSections: ", vTemplateSections);
    
            for (let i = 0; i < vTemplateSections.length; i++) {
        
              const vTemplateSection = vTemplateSections[i];
        
              ////console.log(""NewsletterEditorTable.CreateNewsletterContextJSON.vTemplateSection: ", vTemplateSection);
        
              let vSectionExists = vContentJSON["Sections"].hasOwnProperty(vTemplateSection)
              
              ////console.log(""NewsletterEditorTable.CreateNewsletterContextJSON.vSectionExists: ", vSectionExists);

              if (vSectionExists === false ) {

                let myKey = vTemplateSection;
                let vNewSection = {};
                //let vNewSectionHeader = { "vTemplateSection":vTemplateSection, "Text": "", "ButtonTitle": "", "ButtonURL": "", "ImageURL": "", "AVURL": ""}
                //let vNewSection = {Section: vTemplateSection, SectionDetails : [{Title: "", Text: "", ButtonTitle: "", ButtonURL: "", ImageURL: "", AVURL: ""}]}
                vNewSection[myKey] = { "Title": "", "Text": "", "ButtonTitle": "", "ButtonURL": "", "ImageURL": "", "AVURL": ""}

                vContentJSON["Sections"].push(vNewSection) 

              }
              
              }

        }

        

        ////console.log(""NewsletterEditorTable.CreateNewsletterContextJSON.vContentJSON: ", vContentJSON);
      return vContentJSON

      } catch (err) {

        ErrorMessageStr = ("NewsletterEditorTable.SaveRow.Error: ", err)
        console.error(ErrorMessageStr)

        RhyteItLogging(
          Users,
          "NewsletterEditorTable",
          "CreateNewsletterEdition",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }


    async function CreateNewsletterEdition() {
      try {

        ////console.log("""NewsletterEditorTable.CreateNewsletterEdition.vNewsletterEditions:  ",vNewsletterEditions);
       
        let vContentJSON = await CreateNewsletterContextJSON() 
        
        ////console.log("""NewsletterEditorTable.CreateNewsletterEdition.vContentJSON:  ",vContentJSON);

        var vUpdateDateTimeNew = new Date().toISOString();
        
        let vId = (vNewsletter["Newsletter"] + "-" + vNewsletter["AccountGroup"] + "-" + vNewEdition).replace(/["']/g, "").replace(/\s+/g, '');
        let vS3Key = "/"+ (vNewsletter["Newsletter"] + "-" + vNewsletter["AccountGroup"]).replace(/["']/g, "").replace(/\s+/g, '')+"/"+ vId+".html"
        const jsonString = JSON.stringify(vContentJSON);

        let NewsletterEditionItem = {
          id: vId,
          AccountGroup: vNewsletter["AccountGroup"],   
          CampaignName: "",     
          Newsletter: vNewsletter["Newsletter"],
          User: Users,    
          NewsletterDate: vUpdateDateTimeNew,    
          Edition:  vNewEdition,     
          ContentJSON:jsonString,    
          ContentHTML: "",
          S3HtmlLocation: "",  
          S3Bucket: "rhyteitnewsletters",  
          S3Key: vS3Key,  
          NewsletterEditionStatus: "new",
          SendCount: 0,
          OpenCount:  0,
          ClickCount: 0,
          Active: true,
          CreationName: Users,
          CreationDateTime: vUpdateDateTimeNew,
          UpdateName: Users,
          UpdateDateTime: vUpdateDateTimeNew,
        };
  
        //        ContentJSON:jsonString,
    
  
        ////console.log(""NewsletterEditorTable.SaveRow.NewsletterEditionItem:  ",NewsletterEditionItem);
  
        const createNewsletterResponse = await client.graphql({
          query: createNewsletterEdition,
          variables: {
            input: NewsletterEditionItem,
          },
        });


        ////console.log(""NewsletterEditorTable.SaveRow.createNewsletterResponse:  ",createNewsletterResponse);

        setvAddNewsletterEditionsVisible(false);
        setvNewNewsletterEdition("");
        GetNewsletterEditions();
      } catch (err) {

        ErrorMessageStr = ("NewsletterEditorTable.SaveRow.Error: ", err)
        console.error(ErrorMessageStr)

        RhyteItLogging(
          Users,
          "NewsletterEditorTable",
          "CreateNewsletterEdition",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }

    async function DeleteNewsletterEdition() {
      try {
        ////console.log("""NewsletterEditorTable.CreateNewsletterEdition.vNewsletterEditions:  ",vNewsletterEditions);
       
        const NewsletterEdition = {
          id: vNewsletterEditionSelected.id,
          _version: vNewsletterEditionSelected._version
        }
        

        const vDeleteResponse = await client.graphql({
          query: deleteNewsletterEdition,
          variables: {
            input: NewsletterEdition
          }
        });  
       
        ////console.log(""NewsletterEditorTable.DeleteNewsletterEdition.vDeleteResponse:  ",vDeleteResponse);
  
   
        GetNewsletterEditions();
      } catch (err) {

        ErrorMessageStr = ("NewsletterEditorTable.SaveRow.Error: ", err)
        console.error(ErrorMessageStr)

        RhyteItLogging(
          Users,
          "NewsletterEditorTable",
          "DeleteNewsletterEdition",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }

    async function ViewConfig(event) {
      try {
        ////console.log("""NewsletterEditorTable.ViewConfig.event: ", event);
        ////console.log("""NewsletterEditorTable.ViewConfig.vViewPostingConfig: ", vViewPostingConfig);
        setvAddNewsletterEditionsVisible(!vAddNewsletterEditionsVisible)
  
  
      } catch (err) {
        RhyteItLogging(
          Users,
          "NewsletterEditorTable",
          "ViewConfig",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }

    async function NewsletterEditionSelectedAction(pNewsletterEdition) {
      try {
        //    ////console.log("""NewsletterManager.NewsletterSelectedAction.pAccount: ", pAccount);
        let vSelectedNewsletterGroup = vNewsletterEditionList.filter(
          (vNewsletterEditionItem) => vNewsletterEditionItem["Edition"] === pNewsletterEdition
        );
  
        ////console.log("""NewsletterManager.NewsletterEditionSelectedAction.vSelectedNewsletterGroup: ",vSelectedNewsletterGroup);
        ////console.log("""NewsletterManager.NewsletterSelectedAction.vSelectedNewsletter[0]: ",vSelectedNewsletter[0]);
        setvNewsletterEditionVisible(true);
        
        //setvNewsletterSelected(pAccount);
        setvNewsletterEditionSelected(vSelectedNewsletterGroup[0]);
      } catch (err) {
        RhyteItLogging(
          Users,
          "NewsletterManager",
          "NewsletterEditionSelectedAction",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }    

  return (   
    <section ref={ref} className = {styles.Section}>
      {
        vNewsletter !== undefined  && (                      
        <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
            className={styles.FlexLeftColumn}>             

        <motion.div 
              className = {styles.NewsletterTitleDiv}
              style={{
                  transform: isInView ? "none" : "translateX(-200px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                }}> 
            <motion.h1  className = {styles.NewsletterTitle}>
              {vNewsletter["Newsletter"]}
            </motion.h1>   
        </motion.div>  
        <Flex
               direction="row"
               justifyContent="flex-start"
               alignItems="center"
               alignContent="center"
               wrap="nowrap"
               gap="1rem"
               className={styles.FlexRow}
             >        
        <SelectField
                className={styles.SelectField}
                value={vNewsletterEditionSelected.Edition}
                placeholder="Select newsletter edition"
                onChange={(e) => NewsletterEditionSelectedAction(e.target.value)}
              >
                {vNewsletterEditionList !== undefined &&
                  vNewsletterEditionList.map((NewsletterEdition) => (
                    <option
                      key={NewsletterEdition.Edition}
                      className={styles.SelectFieldOption}
                      value={NewsletterEdition.Edition}
                      label={NewsletterEdition.Edition}
                    >
                      {NewsletterEdition.Edition}
                    </option>
                  ))}
        </SelectField>  
        <Button
                className={styles.Button}
                variation="primary"
                onClick={(e) => CreateNewsletterEdition(e)}
              >
                Create New Edition
            </Button>     
            <Button
                className={styles.Button}
                variation="primary"
                onClick={(e) => DeleteNewsletterEdition(e)}
              >
                Delete Edition
            </Button>                     
        </Flex>                   
        <motion.div 
              className = {styles.NewsletterDescriptionDiv}
              style={{
                  transform: isInView ? "none" : "translateX(-200px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                }}> 
            <motion.h1  className = {styles.NewsletterDescription}>
              {vNewsletter["Description"]}
            </motion.h1>   
        </motion.div>  
        <motion.div 
              className = {styles.NewsletterEditionsDiv}
              style={{
                  transform: isInView ? "none" : "translateX(-200px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                }}> 
            <motion.h1  className = {styles.NewsletterEditions}>
              Edition:  {vNewsletterEditionSelected["Edition"]}
            </motion.h1>     
     
        </motion.div>           

          
              {
                vNewsletterEditionSelected["Edition"] !== "None" && Users !== undefined && vNewsletter !== undefined && (                      
                  (<NewsletterEditionRow   key={vNewsletterEditionSelected.id}  Users={Users} Newsletter={vNewsletter} NewsletterEdition={vNewsletterEditionSelected}/>)        
                  )
              }    
        </Flex>                 
      )}      
    </section>
  )
}

export default NewsletterEditorTable;


