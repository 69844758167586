
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Text,
  TableBody,
  TextField,  
  Button,
  Image
  } from '@aws-amplify/ui-react';

  import  {  }  from '../../graphql/queries'
  import  { deleteProducts}  from '../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";

  import styles from './ProductManagementRow.module.css';  
  import { UserContext} from '../../BusinessLogic/Hooks/UserContext';  

const ProductManagementRow = (props) => {       

  ProductManagementRow.propTypes = {
      RhyteItProductManagementRow: PropTypes.object
      };

    const client = generateClient();
  
    const {Users, setUsers} = useContext(UserContext);   
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""
    let debugConsoleOutPut = true

    const [message, setMessage] = useState('');

    const [vRhyteItProductManagementRows, setvRhyteItProductManagementRows] = useState([props.RhyteItProductManagementRow])      

    useEffect(() => {

       // //console.log("ProductManagementRow.useEffect[].props.RhyteItProductManagementRow:  ",props.RhyteItProductManagementRow) 
      
      },[])  


    function EditProductManagementRow(event, data) {
      try {           
        
          setErrorMessage("")


        } catch (err) { 
          ErrorMessageStr = ("vRhyteItProductManagementRows.EditProductManagementRow.Error: ", err, err.stack)
          console.error(ErrorMessageStr)
          setErrorMessage(ErrorMessageStr)
      }        
    }      
    
    async function DeleteRow(event,data) {
      try {

            console.log("ProductManagmentRow.Delete_Row.Users:  ",Users   ) 

            if (Users === "edwardhawke@rhyteit.com") {

              var elementPos = vRhyteItProductManagementRows.map(function(x) {return x.id; }).indexOf(data.id);                      
                              
              console.log("ProductManagementRow.DeleteRow.vRhyteItProductManagementRows[elementPos].id:  ",vRhyteItProductManagementRows[elementPos].id  ) 

              const DeleteProductManagementRow = {
                id: vRhyteItProductManagementRows[elementPos].id,
                _version: vRhyteItProductManagementRows[elementPos]._version
              }
              

              const vDeleteResponse = await client.graphql({
                query: deleteProducts,
                variables: {
                  input: DeleteProductManagementRow
                }
              });  

              console.log("ProductManagementRow.DeleteRow.vDeleteResponse:  ",vDeleteResponse  ) 
              setvRhyteItProductManagementRows(vRhyteItProductManagementRows => vRhyteItProductManagementRows.filter((data)=> data.id !== vRhyteItProductManagementRows[elementPos].id ))
              ErrorMessageStr = vDeleteResponse
              setErrorMessage(ErrorMessageStr)      
 
              
          } else {

            ErrorMessageStr = "You do not have permission to delete this Product"
            setErrorMessage(ErrorMessageStr)  

          }
      
          } catch (err) { 

          ErrorMessageStr = "ProductManagementRow.DeleteRow.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }               
      
    }       

  return (
      <TableBody>  
      {
          vRhyteItProductManagementRows.length > 0 && vRhyteItProductManagementRows.map((vRhyteItProductManagementRow) => (  

          
          <TableRow  className={styles.TableRowBackground} key={vRhyteItProductManagementRow.id}>
            <TableCell className={styles.TableRowHeadingData} >  
                <Text  className={styles.TextField}>
                                  {vRhyteItProductManagementRow.Product}
                </Text>  
              </TableCell>                                        
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {vRhyteItProductManagementRow.Environment}
                </Text>  
            </TableCell>       
            <TableCell as="td"  className={styles.RhyteItMenuCell}> 
            <Link key={vRhyteItProductManagementRow.id} isExternal={false} to="/ProductManagementDetail" state={{ vRhyteItProductManagementRow: vRhyteItProductManagementRow }} >   
                  <Button
                    loadingText=""
                    ariaLabel=""
                    className={styles.RowButtons}
                    onClick={(e) => EditProductManagementRow(e, vRhyteItProductManagementRow)}
                  >
                  E
                  </Button> 
              </Link>                 
            </TableCell>   
            <TableCell as="td"  className={styles.RhyteItMenuCell}> 
                  <Button
                    loadingText=""
                    ariaLabel=""
                    className={styles.RowButtons}
                    onClick={(e) => DeleteRow(e, vRhyteItProductManagementRow)}
                  >
                  D
                  </Button>                 
            </TableCell>                                                        
          </TableRow>  
        ))
      }     
      {
        ErrorMessage.length > 0 &&             
          <TableRow  >              
            <TableCell 
              colSpan="7">   
              <Text className={styles.ErrorMessageRow}>  
              {ErrorMessage}
              </Text> 
            </TableCell>               
          </TableRow>  
      }                        
      </TableBody>
    
  )
}

export default ProductManagementRow;

