
import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead,
  Flex
  } from '@aws-amplify/ui-react'; 
import RhyteItAccountRow from './RhyteItAccountRow';  
import styles from './RhyteItAccountsTable.module.css';


const RhyteItAccountsTable = (props) => {       

  RhyteItAccountsTable.propTypes = {
      RhyteItAccounts: PropTypes.array,
      RhyteItUser: PropTypes.array
      };

    let debugConsoleOutPut = true

  

    useEffect(() => {

      //console.log("RhyteItAccountsTable.useEffect[].props.RhyteItAccounts.length:  ",props.RhyteItAccounts.length) 
      //console.log("vRhyteItAccounts.useEffect[].props.RhyteItUser:  ",props.RhyteItUser) 
      //console.log("vRhyteItAccounts.useEffect[].Time:  ", RhyteItAccount.PostDateTime.substr(RhyteItAccount.PostDateTime.indexOf("T")+1, 5))
      //onsole.log("vRhyteItAccounts.useEffect[].indexOf:  ", RhyteItAccount.PostDateTime.indexOf(":00"))


      },[])  

  return (  
            <Flex
              direction="row"
              wrap="wrap"
              gap="1rem"
            >   
              {
                props.RhyteItAccounts && props.RhyteItAccounts.map((RhyteItAccount) => (         
                <RhyteItAccountRow  key={RhyteItAccount.id} RhyteItAccount={RhyteItAccount}/>  
                  ))
              }                    
        </Flex>
    
  )
}

export default RhyteItAccountsTable;


