import React, { useState, useEffect, useContext, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./Hero.module.css";

import {
  useInView,
  useScroll,
  useTransform,
  motion,
  useAnimation,
} from "framer-motion";
import { Button, Flex, Text } from "@aws-amplify/ui-react";
import {
  UserContext,
  CognitoGroupContext,
} from "../../../BusinessLogic/Hooks/UserContext";
import Background from "../../Backgrounds/Background";
import TurboFanBoostButton from "../../Buttons/TurboFanBoostButton";
import { useNavigate, Link } from "react-router-dom";

const Hero = (props) => {
  Hero.propTypes = {
    scrollYProgress: PropTypes.object,
  };

  const scale = useTransform(props.scrollYProgress, [0, 0.2], [1, 0.8]);
  const rotate = useTransform(props.scrollYProgress, [0, 0.2], [0, -10]);
  const opacity = useTransform(props.scrollYProgress, [0, 0.1, 0.15], [1,1,0]);

  const [vNavBarMenuClicked, setvNavBarMenuClicked] = useState(false);
  const [vHomeImageVisible, setvHomeImageVisible] = useState(false);

  const ref = useRef(null);
  const isInView = useInView(ref);

  const mainControls = useAnimation();
  const TurboButtonFocusRef = useRef();

  useEffect(() => {
    TurboButtonFocusRef.current?.focus();
  }, []);

  useEffect(() => {
   // console.log("Hero.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);

    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);



  useEffect(() => {}, [vHomeImageVisible]);

  return (
    <motion.div style={{ scale, rotate, opacity }} className={styles.Section}>
      <section ref={ref}>
        <Background className={styles.Background} backgroundcolor="#00b1b1" />
        <Flex
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          alignContent="flex-start"
          wrap="wrap"
          className={styles.TextFlexColumn}
        >
          <Flex
            direction="row"
            justifyContent="left"
            alignItems="center"
            alignContent="flex-start"
            wrap="wrap"
            className={styles.TextRow}
          >
            <motion.div className={styles.MayanNumber}>
              <motion.h1
                style={{
                  transform: isInView ? "none" : "translateX(-200px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                }}
                className={styles.MayanDot}
              >
                &#x2022;
              </motion.h1>
            </motion.div>
            <motion.div>
              <motion.h1
                style={{
                  transform: isInView ? "none" : "translateY(-200px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                }}
                className={styles.HeaderText}
              >
                Adult Content Creator Automation
              </motion.h1>
            </motion.div>
          </Flex>
          <Flex
            direction="row"
            justifyContent="left"
            alignItems="center"
            alignContent="flex-start"
            wrap="nowrap"
            className={styles.SubTextRow}
          >
            <Flex
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              alignContent="flex-start"
              wrap="wrap"
              gap="0.25em"
            >
              <motion.h1
                style={{
                  transform: isInView ? "none" : "translateY(100px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                }}
                className={styles.Text}
              >
                Automate your social media marketing at an affordable cost
              </motion.h1>
            </Flex>
          </Flex>

          <Link className={styles.ApplicationLink} to="/TurboFanBoost">
            <TurboFanBoostButton
              ref={TurboButtonFocusRef}
              className={styles.TurboFanBoostButton}
            ></TurboFanBoostButton>
          </Link>
          <div className={styles.ScrollContainer}>
            <span className={styles.ScrollMessage}>[SCROLL TO EXPLORE]</span>
          </div>
        </Flex>
      </section>
    </motion.div>
  );
};

export default Hero;
