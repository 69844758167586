import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styles from './SBSMLogoTickerHorizontal.module.css';
import  { }  from '../../graphql/mutations'
import {
  Image, Flex
} from '@aws-amplify/ui-react';

const SBSMLogoTickerHorizontal = (props) => {       
  
  SBSMLogoTickerHorizontal.propTypes = {
          RedditUser: PropTypes.object
        };

        const [SBSMLogosData, setSBSMLogosData] = useState()              
              

        useEffect(() => {

          (async function() {
            try {  

              GetSBSMLogosFromS3()


                } catch (err) {
                  console.error('SBSMLogoTickerHorizontal.useEffect[].ERROR: ', err, err.stack); 
              }
            }
          )();   
      

        },[]) 

        useEffect(() => {

        
       //   console.log("SBSMLogoTickerHorizontal.useEffect[SBSMLogosData].SBSMLogosData: " ,SBSMLogosData);
      

        },[SBSMLogosData])         
   
        
        async function GetSBSMLogosFromS3() {
          try {


                    const url = `https://rhyteitfiles.s3.us-west-2.amazonaws.com/ScollingData/SBSMLogos.json`

                    const fetchResponse = await fetch(url);
                    let SBSMLogosData1 = await fetchResponse.json();

                    setSBSMLogosData(SBSMLogosData1)                    


  
          } catch (err) { 
                          console.error('TreeGrowth.GetSBSMLogos.ERROR: ', err, err.stack); 
                      }
        }          



    return ( 
      <div
              className={styles.FlexRow}
            >               
            {
                    SBSMLogosData !== undefined && SBSMLogosData.map((vLogo) => (  
                      <div className={styles.ImageContainer} key={vLogo.id}> 
                          <img
                          alt="Cjro"
                            src={vLogo.LogImageFileLocation}
                            className={styles.PhotoImage}
                          />
                      </div>  

                  ))
            }    
      </div>  
      )

}

export default SBSMLogoTickerHorizontal;