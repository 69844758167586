import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import RSSFeedMessageBox from "../../ui-components/StatusMessageBox";
import {ErrorBoundary} from 'react-error-boundary'
import Header from "../RhyteItComponents/Header/Header";
import {motion as m} from "framer-motion";
import SBSMRSSTable from "./SBSMRSSTable";
import {get_uuidv4} from '../../BusinessLogic/uuidv4';
import { UserContext, CognitoGroupContext} from '../../BusinessLogic/Hooks/UserContext';
import {GetGroupRoleAccess, RhyteItLogging, ReturnUserToLandingPage} from '../../BusinessLogic/UserManagement';
import { fetchAuthSession } from 'aws-amplify/auth';

import  { listSBSMNewsRSSES }  from '../../graphql/queries'
import  { }  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import {
  Button ,
  Authenticator,
  Autocomplete,
  Flex
} from '@aws-amplify/ui-react';
import styles from './SBSMRSS.module.css';

function ErrorFallback({error, resetErrorBoundary}) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}


const SBSMRSS = (props) => {       
  
  SBSMRSS.propTypes = {
          reddituser: PropTypes.object
        };


        const client = generateClient();

        const [SBSMRSSFeeds, setSBSMRSSFeeds] = useState([{id: '',
              UserId: '',
              RSSFeed:  '',
              RSSFeedStatus:  '',
              CreationName:  '',
              UpdateName:  '',
              CreationDateTime:  '',
              UpdateDateTime:  '' }])
        
        const vBreadCrumbItems = [{Path: "/", Title:"Home", isCurrent:false},{Path: "/MainMenuV2", Title:"Menu", isCurrent:false},{Path: "/SBSMRSS", Title:"RSS Feeds", isCurrent:true}]
            

        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   
        const [updatedKey, setupdatedKey] = useState(Date.now())   
        let debugConsoleOutPut = true

        const {Users, setUsers} = useContext(UserContext);    
        const {CognitoGroup, setCognitoGroup} = useContext(CognitoGroupContext);       
        const [RSSFeed, setRSSFeed] = useState([]);
        const [SearchTerm1, setSearchTerm1] = useState('');
        const [ShowTable, setShowTable] = useState(false);

        useEffect(() => {

        },[]) 
     
           useEffect(() => {
             
            (async function() {
              try {  
  
                      if (CognitoGroup !== undefined) {
  
                        let   GetGroupRoleAccessResponse = await GetGroupRoleAccess(CognitoGroup, "rhyteitAdmins")
                        
                        if (GetGroupRoleAccessResponse) {  
                          GetSBSMRSS()  
                        }
                            
  
                      } else {
  
                        const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
  
                        if (idToken !== undefined) {
                          setUsers(idToken.payload.email)
                          setCognitoGroup(accessToken["payload"]["cognito:groups"])
                        } else {
                          
                          ReturnUserToLandingPage(Users, CognitoGroup)
                        }
  
                      }
                    } catch (err) {                  
                      RhyteItLogging(Users, "SBSMRSS", "useEffect[CognitoGroup]",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                  }
                }
              )();   
      
             },[CognitoGroup])                
            

        useEffect(() => {

          if (Object.keys(SBSMRSSFeeds).length > 0) {
            setShowTable(true)
          }

          setErrorMessage("Query terms retrieved at: " + new Date().toISOString())

        },[SBSMRSSFeeds])    


        useEffect(() => {

          //console.log("SBSMRSS.useEffect[RSSFeed].RSSFeed: ", RSSFeed)

        }, [RSSFeed]);   

          
        useEffect(() => {

          if (Object.keys(SBSMRSSFeeds).length > 1) {
            setShowTable(true)
          }


        }, [updatedKey]);      
  

        async function GetSBSMRSS() {
          try {
                    setErrorMessage("")
                    if (debugConsoleOutPut === true) {
                        //console.log("SBSMRSS.GetSBSMRSS.Users[0].UserId: ", Users[0].UserId)
                    }


                    const variables = {
                      filter: {
                        UserId: {
                          eq: Users
                      }
                      }
                    };
        
                    const SBSMNewsRSSData  = await client.graphql({
                        query: listSBSMNewsRSSES,
                        variables: variables
                      });        
        
      
      
                    const SBSMNewsRSS = SBSMNewsRSSData.data.listSBSMNewsRSSFeeds.items 
      
                    setSBSMRSSFeeds(SBSMNewsRSS)
                                        
                    let PopulateRSSListResponse = await PopulateRSSList(SBSMNewsRSS)

                    setupdatedKey(Date.now())

                    return SBSMNewsRSS

  
          } catch (err) { 
            RhyteItLogging(Users, "SBSMRSS", "GetSBSMRSS",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                      }
        }       
            

        async function PopulateRSSList(SBSMRSSFeeds) {
          try {

              setErrorMessage("") 


              var SBSMRSSFeedsJSON = {SBSMRSSFeeds: [{id:"none", label: "none"}]}


              if (Object.keys(SBSMRSSFeeds).length > 0 ) {

                const arr = Object.entries(SBSMRSSFeeds);


                arr.forEach(RSSFeed => 
                  {
                    let vItemAlreadyExists = false                      

                    SBSMRSSFeedsJSON.SBSMRSSFeeds.forEach(pt => 
                        {                             
                                          
                          if (pt.label === RSSFeed[1].id) {                      
                            vItemAlreadyExists = true;
                          }
                        } 
                      )     
                      
                      

                    if(vItemAlreadyExists === false) {

                        let NewRSSFeed = {
                          id: RSSFeed[1].id,
                          label: RSSFeed[1].id
                        }   

                        SBSMRSSFeedsJSON.SBSMRSSFeeds.push(NewRSSFeed);

                      }
                })


                setRSSFeed(SBSMRSSFeedsJSON.SBSMRSSFeeds)

              }


              return SBSMRSSFeedsJSON

            } catch (err) { 

              RhyteItLogging(Users, "SBSMRSS", "PopulateRSSList",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)  
          }        
        } 

       
        function AddRSSFeed(event) {
            try {              
    
                var DynamoUUID =  get_uuidv4()
    
                var vUpdateDateTime = new Date().toISOString()
    
                if (debugConsoleOutPut === true) {
                    //console.log("SBSMRSS.AddRSSFeedGenerator.Users:  ", Users)
                }
    

                let NewProfile = {
                      id: DynamoUUID,
                      UserId: Users[0].UserId,
                      RSSLink: '',
                      InfoType: 'Podcast',   
                      URL: '',  
                      Status: 'NEW',  
                      CreationName: Users[0].UserId,
                      UpdateName: Users[0].UserId,
                      CreationDateTime: vUpdateDateTime,
                      UpdateDateTime: vUpdateDateTime
                }    

              if (debugConsoleOutPut === true) {
                  //console.log("SBSMRSS.AddRSSFeed.SBSMRSSFeeds:  ", SBSMRSSFeeds)
                  //console.log("SBSMRSS.AddRSSFeed.SBSMRSSFeeds.isArray:  ", Array.isArray(SBSMRSSFeeds))
                  //console.log("SBSMRSS.AddRSSFeed.Object.keys(SBSMRSSFeeds).length:  ", Object.keys(SBSMRSSFeeds).length)
                  
              }          

              let vArraySpot = Object.keys(SBSMRSSFeeds).length

              SBSMRSSFeeds[vArraySpot] = NewProfile;

              if (debugConsoleOutPut === true) {
                //console.log("SBSMRSS.AddRSSFeed.SBSMRSSFeeds 2:  ", SBSMRSSFeeds)
                
            }     

    
              setSBSMRSSFeeds(SBSMRSSFeeds)       
              setupdatedKey(Date.now())

              } catch (err) { 
                RhyteItLogging(Users, "SBSMRSS", "AddRSSFeed",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)  
            }        
        }   
        
        function onChangeAutocomplete(event) {
          setSearchTerm1(event.target.value);
        }

        async function onSelectAutocomplete(option) {

          
          //console.log("SBSMRSS.onSelectAutocomplete.option: ",option)  

          const { label } = option;

          setSearchTerm1(label);          


          try {

            setErrorMessage("") 

            let RSSFeedsFiltered = ""

            let fetchSBSMRSSFeedsResponse = await GetSBSMRSS();
            //console.log("SBSMRSS.onSelectAutocomplete.fetchSBSMRSSFeedsResponse:  ",fetchSBSMRSSFeedsResponse)
            //console.log("SBSMRSS.onSelectAutocomplete.SBSMRSSFeeds.TypeOf:  ", typeof SBSMRSSFeeds)
            //console.log("SBSMRSS.onSelectAutocomplete.Array.isArray(SBSMRSSFeeds):  ", Array.isArray(SBSMRSSFeeds))

           //RSSFeedsFiltered = await [...fetchSBSMRSSFeedsResponse].filter(SBSMRSSFeed => SBSMRSSFeed.RSSFeed ===  label)          
           
            RSSFeedsFiltered = Object.values(fetchSBSMRSSFeedsResponse).filter(SBSMRSSFeed => SBSMRSSFeed.RSSFeed ===  label)  

            const entries = Object.entries(RSSFeedsFiltered);
            
            //console.log("SBSMRSS.onSelectAutocomplete.entries:  ",entries)
            //console.log("SBSMRSS.onSelectAutocomplete.entries[0][1]:  ",entries[0][1])
            var JsonObject = JSON.parse(JSON.stringify(entries[0][1]));
            //console.log("SBSMRSS.onSelectAutocomplete.JsonObject:  ",JsonObject)
            //console.log("SBSMRSS.onSelectAutocomplete.[JsonObject]:  ",[JsonObject])
            setSBSMRSSFeeds([JsonObject])
            setupdatedKey(Date.now())


          } catch (err) { 

            RhyteItLogging(Users, "SBSMRSS", "onSelectAutocomplete",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)  
        }            
        }        

        function onClearAutocomplete() {
          setSearchTerm1("");
        }                
        

    return ( 
    <m.div
      key="SalesMonitor"
      initial={{y: "100%"}}
      animate={{y: "0%"}}
      transition={{duration:0.75, ease: "easeOut"}}
      exit={{ opacity: 1 }}
     >
      <Authenticator  className={styles.AuthenticatorLogin} socialProviders={[]} hideSignUp={true}>
        {({ signOut, user }) => (   
          <div className={styles.SystemAdminBackground}>
            <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                >   
                   <Flex
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      alignContent="flex-start"
                      wrap="nowrap"
                      gap="3rem"
                      className={styles.GridContainer}
                    >      
                      <Header Title="RSS Feeds" SubTitle="Manage SBSM RSS Feed" BreadCrumbItems ={vBreadCrumbItems} pageSource="SBSMRSS"/>  
                      <Flex
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          alignContent="flex-start"
                          wrap="nowrap"
                          gap="3rem"
                        >     
                                  <Button
                                    loadingText=""
                                    ariaLabel=""
                                    className={styles.RedditTitleButtons}
                                    onClick={ (e) => AddRSSFeed(e)}
                                  >
                                  Add RSS Feed
                                  </Button>   
                                  <Button
                                    loadingText=""
                                    ariaLabel=""
                                    className={styles.RedditTitleButtons}
                                    onClick={ (e) => GetSBSMRSS(e)}
                                  >
                                  Refresh Data
                                  </Button>    
                                  <Autocomplete                                  
                                      label="Autocomplete"
                                      options={RSSFeed}
                                      placeholder="Find query term"
                                      value={SearchTerm1}
                                      onChange={onChangeAutocomplete}
                                      onClear={onClearAutocomplete}
                                      onSelect={onSelectAutocomplete}
                                      onSubmit={onSelectAutocomplete}   
                                      className={styles.amplifyautocomplete}    
                                  />  
                              <div className={styles.TableRowData}>                               
                                <RSSFeedMessageBox ErrorMessage={ErrorMessage}>{ErrorMessage}</RSSFeedMessageBox>  
                              </div> 
                          </Flex>           
                          {ShowTable && (<SBSMRSSTable  key={updatedKey} SBSMRSSFeeds={SBSMRSSFeeds}/>)  }
                    </Flex>     
            </ErrorBoundary>
          </div>   
        )}         
      </Authenticator>  
    </m.div>                      

    )

  }
 

export default SBSMRSS;