
import { API , graphqlOperation, Auth} from 'aws-amplify';
import React, { useState, useEffect } from 'react'
//import './SignUp.css'; 
import {
    Table, 
    TableBody,
    Text,
    TableColumn,
    TableRow,
    TableCell,
    Image
  } from '@aws-amplify/ui-react';
import PropTypes from 'prop-types';
import styles from './Videos.module.css';  
import * as Queries from '../../graphql/queries'
import * as Mutations from '../../graphql/mutations'
import StatusMessageBox from "../../ui-components/StatusMessageBox";
import HeaderMenu from "../HeaderMenu/LandingPageNavBar";
import MenuBackground from '../Menu/MenuBackground';
import {motion as m} from "framer-motion";
import { SEO } from '../SEO/SEO';




const Videos = (props) => {

    Videos.propTypes = {
        UserEmail: PropTypes.string,
        UserId: PropTypes.string,
        UserName: PropTypes.string
      };


    var ErrorMessageStr = ""
    const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)  

    useEffect(() => {

        console.log('SignUp.useEffect[].props.UserId:', props.UserId);
                
    },[])      

    

    return (
        <m.div
                key="Videos"
                initial={{y: "100%"}}
                animate={{y: "0%"}}
                transition={{duration:0.75, ease: "easeOut"}}
                exit={{ opacity: 1 }}
               >            
               <SEO
               title="Automate your brand awareness"
               description='Use RhyteIt to grow your brand awareness through AI automation.'
               name='RhyteIt'
               type='article' />      
            <MenuBackground className={styles.MenuBackground} GradientStopColor1="#0000" GradientStopColor2="#000"  GradientStopColor3="#777"   GradientStopColor4="#8880"/> 
            <HeaderMenu pageSource="Application"/> 
            <div>
                <div>
                <Table className={styles.RhyteItTable}   highlightOnHover={true}>   
                    <TableBody>  
                        <TableRow  className={styles.TableRow}>           
                            <TableCell  className={styles.TableCellIcon}> 
                            <a href="./Overview" target="_blank" rel="noreferrer">          
                                <Image
                                    alt=""
                                    src="https://rhyteiticons.s3.us-west-2.amazonaws.com/ImproveIcon.svg"
                                    objectFit="initial"
                                    className={styles.Icon}
                                    /> 
                            </a>    
                            </TableCell>          
                            <TableCell  className={styles.TableCell}>  
                                Overview
                            </TableCell>                      
                            <TableCell  className={styles.TableCellDescription}> 
                                An overview of what RhyteIt can do for you.
                            </TableCell>                                                                      
                        </TableRow> 
                        <TableRow  className={styles.TableRow}>           
                            <TableCell  className={styles.TableCellIcon}> 
                            <a href="./Processes" target="_blank" rel="noreferrer">          
                                <Image
                                    alt=""
                                    src="https://rhyteiticons.s3.us-west-2.amazonaws.com/RepeatIcon.svg"
                                    objectFit="initial"
                                    objectPosition="50% 50%"
                                    className={styles.Icon}
                                    /> 
                            </a>    
                            </TableCell>          
                            <TableCell  className={styles.TableCell}>  
                                Processes
                            </TableCell>                      
                            <TableCell  className={styles.TableCellDescription}> 
                                An overview of the workflow and processes that RhyteIt covers
                            </TableCell>                                                                      
                        </TableRow> 
                        <TableRow  className={styles.TableRow}>           
                            <TableCell  className={styles.TableCellIcon}> 
                            <a href="./Demo" target="_blank" rel="noreferrer">          
                                <Image
                                    alt=""
                                    src="https://rhyteiticons.s3.us-west-2.amazonaws.com/RhyteItDashboardIcon.svg"
                                    objectFit="initial"
                                    objectPosition="50% 50%"
                                    className={styles.Icon}
                                    /> 
                            </a>    
                            </TableCell>          
                            <TableCell  className={styles.TableCell}>  
                                Demo
                            </TableCell>                      
                            <TableCell  className={styles.TableCellDescription}> 
                                This video takes you through all the various screens and functionality of RhyteIt.
                            </TableCell>                                                                      
                        </TableRow> 
                    </TableBody>                
                </Table>
                </div>
                <StatusMessageBox ErrorMessage={ErrorMessage}></StatusMessageBox> 

            </div>
  
        </m.div>
                
    );
}


export default Videos;    