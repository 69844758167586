import React  from "react";
import PropTypes from 'prop-types';
import {
  Text
  } from '@aws-amplify/ui-react';
  import styles from './StatusMessageBox.module.css';  

const StatusMessageBox = (props) => {

  StatusMessageBox.propTypes = {
      ErrorMessage: PropTypes.string
    };

  return (
    <div>
      <Text className = {styles.StatusMessageBox}>
          {props.ErrorMessage}
      </Text>
    </div>
  )
}

export default StatusMessageBox;