
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Text,
  TableBody,
  TextField,  
  Button,
  Image
  } from '@aws-amplify/ui-react';

  import { Link } from "react-router-dom";
  
  import styles from './DigitalMarketPlaceRow.module.css';  
  import { UserContext,UserAuthenticatedContext,UserSubcribedContext} from '../../BusinessLogic/Hooks/UserContext';  
  import DigitalMarketPlaceDetail from "./DigitalMarketPlaceDetail";

const DigitalMarketPlaceRow = (props) => {       

  DigitalMarketPlaceRow.propTypes = {
      RhyteItDigitalMarketPlaceRow: PropTypes.object,
      Environment: PropTypes.string
      };

    const {Users, setUsers} = useContext(UserContext);   
    const [ErrorMessage, setErrorMessage] = useState("")   
    const [vEnvironment, setvEnvironment] = useState(props.Environment)   
    let ErrorMessageStr = ""
    let debugConsoleOutPut = true

    const [message, setMessage] = useState('');

    const [vRhyteItDigitalMarketPlaceRows, setvRhyteItDigitalMarketPlaceRows] = useState([props.RhyteItDigitalMarketPlaceRow])      

    useEffect(() => {

       // console.log("DigitalMarketPlaceRow.useEffect[].props.RhyteItDigitalMarketPlaceRow:  ",props.RhyteItDigitalMarketPlaceRow) 
      
      },[])  


    function EditDigitalMarketPlaceRow(event, data) {
      try {           
        
          setErrorMessage("")


        } catch (err) { 
          ErrorMessageStr = ("vRhyteItDigitalMarketPlaceRows.EditDigitalMarketPlaceRow.Error: ", err, err.stack)
          console.error(ErrorMessageStr)
          setErrorMessage(ErrorMessageStr)
      }        
    }     
    
    function PurchaseDigitalMarketPlaceRow(event, data) {
      try {           
        
          setErrorMessage("")


        } catch (err) { 
          ErrorMessageStr = ("vRhyteItDigitalMarketPlaceRows.EditDigitalMarketPlaceRow.Error: ", err, err.stack)
          console.error(ErrorMessageStr)
          setErrorMessage(ErrorMessageStr)
      }        
    }       
 

  return (
      <TableBody>  
      {
          vRhyteItDigitalMarketPlaceRows.length > 0 && vRhyteItDigitalMarketPlaceRows.map((vRhyteItDigitalMarketPlaceRow) => (  

          
          <TableRow  className={styles.TableRowBackground} key={vRhyteItDigitalMarketPlaceRow.id}>
            <TableCell className={styles.TableRowData} >  
                <Image
                  className={styles.Image}
                  src={vRhyteItDigitalMarketPlaceRow.ProductImage}
                  objectFit="initial"
                  backgroundColor="initial"
                  height="8vh"
                  width="5vw"
                  onClick={() => alert('📸 Say cheese!')}
                />
            </TableCell>     
            <TableCell className={styles.TableRowData} >  
                <Text  className={styles.TextField}>
                                  {vRhyteItDigitalMarketPlaceRow.Product}
                </Text>  
              </TableCell>                               
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  ${vRhyteItDigitalMarketPlaceRow.Price}
                </Text>  
            </TableCell>     
            <TableCell as="td"  className={styles.RhyteItMenuCell}> 
            <Link key={vRhyteItDigitalMarketPlaceRow.id} isExternal={false} to="/DigitalMarketPlaceDetail" state={{ vRhyteItDigitalMarketPlaceRow: vRhyteItDigitalMarketPlaceRow, vEnvironment: vEnvironment }} >   
                  <Button
                    loadingText=""
                    ariaLabel=""
                    className={styles.RowButtons}
                    onClick={(e) => EditDigitalMarketPlaceRow(e, vRhyteItDigitalMarketPlaceRow)}
                  >
                  Details
                  </Button> 
              </Link>       
              <Link key={vRhyteItDigitalMarketPlaceRow.id} isExternal={false} to="/DigitalMarketPlaceDetail" state={{ vRhyteItDigitalMarketPlaceRow: vRhyteItDigitalMarketPlaceRow }} >   
                  <Button
                    loadingText=""
                    ariaLabel=""
                    className={styles.RowButtons}
                    onClick={(e) => PurchaseDigitalMarketPlaceRow(e, vRhyteItDigitalMarketPlaceRow)}
                  >
                  BUY NOW
                  </Button> 
              </Link>                                        
            </TableCell>                                                      
          </TableRow>  
        ))
      }     
      {
        ErrorMessage.length > 0 &&             
          <TableRow  className={styles.ErrorMessageRow}>              
            <TableCell 
              colSpan="7">      
              {ErrorMessage}
            </TableCell>               
          </TableRow>  
      }                        
      </TableBody>
    
  )
}

export default DigitalMarketPlaceRow;

