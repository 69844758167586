import { createGlobalStyle } from 'styled-components';

import ConigenRegularWoff from './Conigen-Regular.woff';
import ConigenRegularWoff2 from './Conigen-Regular.woff2';
import ConigenRegularTtf from './Conigen-Regular.ttf';

import ConigenExtraBoldWoff from './Conigen-ExtraBold.woff';
import ConigenExtraBoldWoff2 from './Conigen-ExtraBold.woff2';
import ConigenExtraBoldTtf from './Conigen-ExtraBold.ttf';

import RunaltoWoff from './Runalto.woff';
import RunaltoOtf from './Runalto.otf';
import RunaltoTtf from './Runalto.ttf';


import BeautyLuxuryWoff from './BeautyLuxury/BeautyLuxury-Regular.woff';
import BeautyLuxuryOtf from './BeautyLuxury/BeautyLuxury-Regular.otf';
import BeautyLuxuryTtf from './BeautyLuxury/BeautyLuxury-Regular.ttf';
import BeautyLuxuryWoff2 from './BeautyLuxury/BeautyLuxury-Regular.woff2';

export default createGlobalStyle`
    @font-face {
        font-family: 'ConigenExtBd';
        src: url(${ConigenExtraBoldWoff}) format('woff'), 
        url(${ConigenExtraBoldWoff2}) format('woff2'), 
        url(${ConigenExtraBoldTtf}) format('truetype'); 
        font-style: normal;
        font-weight: normal;
        text-rendering: optimizeLegibility;
    };
    @font-face {
        font-family: 'ConigenReg';
        src: url(${ConigenRegularWoff}) format('woff'), 
        url(${ConigenRegularWoff2}) format('woff2'), 
        url(${ConigenRegularTtf}) format('truetype'); 
        font-style: normal;
        font-weight: normal;
        text-rendering: optimizeLegibility;
    };    
    /* Webfont: Runalto */
    @font-face {
        font-family: 'Runalto';
        src: url(${RunaltoOtf}) format('opentype'), 
        url(${RunaltoWoff}) format('woff'),
        url(${RunaltoTtf}) format('truetype');
        font-style: normal;
        font-weight: normal;
        text-rendering: optimizeLegibility;
    };  
    /* Webfont: BeautyLuxury */
    @font-face {
        font-family: 'BeautyLuxury';
        src: url(${BeautyLuxuryOtf}) format('opentype'), 
        url(${BeautyLuxuryWoff}) format('woff'),
        url(${BeautyLuxuryWoff2}) format('woff2'),
        url(${BeautyLuxuryTtf}) format('truetype');
        font-style: normal;
        font-weight: normal;
        text-rendering: optimizeLegibility;
    };
    
    
    
    
    
`;