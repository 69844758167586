
import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead
  } from '@aws-amplify/ui-react'; 
import SBSMArticleRow from './SBSMNewsAdminRow';  
import styles from './SBSMNewsAdminTable.module.css';


const SBSMNewsAdminTable = (props) => {       

  SBSMNewsAdminTable.propTypes = {
      SBSMNewsArticles: PropTypes.array,
      RhyteItUser: PropTypes.array
      };

    let debugConsoleOutPut = true


    const [updatedKey, setupdatedKey] = useState(Date.now())  
    const [SBSMNewsArticles, setSBSMNewsArticles] = useState(Object.entries(props.SBSMNewsArticles))
    const [ShowTable, setShowTable] = useState(false);

    useEffect(() => {

     //   console.log("SBSMNewsAdminTable.useEffect[].props.SBSMArticles:  ",props.SBSMNewsArticles) 
        //console.log("SBSMNewsAdminTable.useEffect[].Object.keys(SBSMNewsArticles).length:  ",Object.keys(SBSMNewsArticles).length)
       // console.log("SBSMNewsAdminTable.useEffect[].Object.Array.isArray(SBSMNewsArticles):  ",Array.isArray(SBSMNewsArticles))
         

        if (Object.keys(SBSMNewsArticles).length > 1) {
          setShowTable(true)
        }

      },[])  

      useEffect(() => {

        //console.log("SBSMNewsAdminTable.useEffect[].SBSMNewsArticles:  ",SBSMNewsArticles) 
  
      },[SBSMNewsArticles])        

  return ( 
    <div>
      <Table className={styles.RhyteItTable}   highlightOnHover={true}> 
              {
                ShowTable === true && Array.isArray(SBSMNewsArticles)
                ? SBSMNewsArticles.map((SBSMArticle) => (         
                <SBSMArticleRow  key={SBSMArticle[1].id} SBSMArticle={SBSMArticle[1]}/>))  : null
                 
              }                    
        </Table>
      </div> 
    
  )
}

export default SBSMNewsAdminTable;


