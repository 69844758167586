
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Text,
  TableBody,
  TextField,  
  CheckboxField ,
  Flex,
  Button,
  Image,
  TextAreaField,  
  SelectField,
  Fieldset,
  Table,
  TableHead,
  } from '@aws-amplify/ui-react';

  import  {  listSMMADContentMetrics }  from '../../../graphql/queries'
  import  { deleteSMMADContent, updateSMMADAccounts, createSMMADAccounts}  from '../../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";

  import styles from './SMMADPostings.module.css';  
  import { UserContext} from '../../../BusinessLogic/Hooks/UserContext';  
  import {get_uuidv4} from '../../../BusinessLogic/uuidv4';
  
  import  SMMADPostingRow  from './SMMADPostingRow'

  import {
    GetGroupRoleAccess,
    RhyteItLogging,
    ReturnUserToLandingPage,
  } from "../../../BusinessLogic/UserManagement";

const SMMADPostings = (props) => {       

  SMMADPostings.propTypes = {
      AccountSelected: PropTypes.object
      };

    const client = generateClient();
  
    const {Users, setUsers} = useContext(UserContext);   
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vAccountSelected, setvAccountSelected] = useState(props.AccountSelected);  
 
    const [nextToken, setnextToken] = useState(undefined) 
    const [IsLoading, setIsLoading] = useState(false)

    let [vPostList, setvPostList] = useState();
    
    const [vPostsSortedByDate, setvPostsSortedByDate] = useState()

    useEffect(() => {

       

      },[])  

      useEffect(() => {

        const fetch1 = async () => {


          try {

                if(nextToken !== null) {
                  setIsLoading(true)

                    var datetime = new Date();                                                
                    let limit = 10000000
                    //console.log("SMMADTable.useEffect[NextToken].nextToken: ", nextToken);   
                    
                    let variables;
                    variables = {
                      nextToken,
                      filter: {
                        Account: {
                          eq: vAccountSelected.Account,
                        },
                      },
                    }; 
                    
                    const vlistSMMADContentMetrics = await client.graphql({
                      query: listSMMADContentMetrics,
                      variables: variables,
                    });
              
                    //console.log("Content.GetContent.ContentData2.data.listSMMADContents.nextToken:  ",ContentData2.data.listSMMADContents.nextToken)

                    let vPostListTmp = vPostList                   
                          
          
                    vlistSMMADContentMetrics.data.listSMMADContentMetrics.items.forEach(item => 
                      {   
                    
                        if ([...vPostListTmp].filter(Post => item.id === Post.id)["length"] === 0) {  
                          vPostListTmp.push(item) 
                        }
  
                      })

                        setvPostList(vPostListTmp)  
                        setnextToken(vlistSMMADContentMetrics.data.listSMMADContentMetrics.nextToken)  
                    
                } else if (nextToken === null){       
                 // console.log("SMMADPostings.useEffect[nextToken].nextToken:  ",nextToken) 
                  setIsLoading(false)                    
                }

          } catch (err) {
            //console.log(err)
          } finally {
            setIsLoading(false)
          }
        }
      
        fetch1()

      }, [nextToken])   
      
      useEffect(() => {

        //console.log("SMMADPostings.useEffect[vAccountSelected].vAccountSelected:  ",vAccountSelected) 
        GetSMMADPostings()

      }, [vAccountSelected])     
      
      useEffect(() => {

        if(vPostsSortedByDate !== undefined) {

        //  console.log("SMMADPostings.useEffect[vPostsSortedByDate].vPostsSortedByDate:  ",vPostsSortedByDate) 
        //  console.log("SMMADPostings.useEffect[vPostsSortedByDate].vPostList:  ",vPostList) 

        }  

      }, [vPostsSortedByDate])         
      
      useEffect(() => {

        if(vPostList !== undefined) {
        //  console.log("SMMADPostings.useEffect[IsLoading].len(vPostList)2: ", vPostList.length, " - nextToken: ", nextToken, " - IsLoading: ", IsLoading);   
        }

        if(vPostList !== undefined && nextToken === null && IsLoading === false) {

        //  console.log("SMMADPostings.useEffect[IsLoading].len(vPostList): ", vPostList.length, " - nextToken: ", nextToken, " - IsLoading: ", IsLoading);   
  
          
          let vSorted = SortPostingsByDate(vPostList)
          vSorted = vSorted.slice(0, 10);
          setvPostsSortedByDate(vSorted)  
        }

      },[IsLoading])   


      async function GetSMMADPostings() {
        try {

         // console.log("SMMADPostings.GetSMMADPostings.vAccountSelected.Account:  ",vAccountSelected.Account) 
    

          setErrorMessage("");
          let variables;
          variables = {
            filter: {
              Account: {
                eq: vAccountSelected.Account,
              },
            },
          }; 
          
          const vlistSMMADContentMetrics = await client.graphql({
            query: listSMMADContentMetrics,
            variables: variables,
          });

        //  console.log("SMMADPostings.GetSMMADPostings.vlistSMMADContentMetrics:  ",vlistSMMADContentMetrics.data.listSMMADContentMetrics.items.length) 
    
          if (vlistSMMADContentMetrics.data.listSMMADContentMetrics.items.length > 0) {

                const vPosts1 = vlistSMMADContentMetrics.data.listSMMADContentMetrics.items

                setvPostList(vPosts1)  
                
                setnextToken(vlistSMMADContentMetrics.data.listSMMADContentMetrics.nextToken)  
   
          }
        } catch (err) {
          RhyteItLogging(
            Users,
            "SMMADPostings",
            "GetSMMADPostings",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }

      function SortPostingsByDate(pPost) {
       // console.log("SMMADPostings.SortPostingsByDate.pPost: ",pPost);  
        pPost.sort(DateSortFunction);

        let pPostInt = 1
        let pPostLength = pPost.length 

        const modifiedEmployees = pPost.map(obj => {

          if (pPostInt === pPostLength) {

            obj.TimeDiff = time_convert(new Date(obj.CreationDateTime), new Date(obj.CreationDateTime))

            obj.TimeDiffInt = (new Date(obj.CreationDateTime) -  new Date(obj.CreationDateTime))

          } else {
         //   console.log("SMMADPostings.SortPostingsByDate.pPost[pPostInt].CreationDateTime: ", pPost[pPostInt].CreationDateTime);   
          
         
            obj.TimeDiff = time_convert(new Date(obj.CreationDateTime), new Date(pPost[pPostInt].CreationDateTime))
            obj.TimeDiffInt = (new Date(obj.CreationDateTime) - new Date(pPost[pPostInt].CreationDateTime))

          }

          //console.log("SMMADPostings.SortPostingsByDate.obj: ",obj);  

          pPostInt += 1

          return obj;
        });

     //   console.log("SMMADPostings.SortPostingsByDate.modifiedEmployees: ", modifiedEmployees);   
        
        return pPost;
        //    //console.log(JSON.stringify(pEmails));
      }
    
      function DateSortFunction(a, b) {
        
        //console.log("SMMADPostings.DateSortFunction.a: ",a);  
        let vCreationDateTimeA = new Date(a.CreationDateTime);
        let vCreationDateTimeB = new Date(b.CreationDateTime);
        let vReturnOrder = vCreationDateTimeA < vCreationDateTimeB ? 1 : -1;
        //console.log("SMMADTable.WeightSortFunction.vWeightA: ",vWeightA, " < vWeightB: ",vWeightB, " = vReturnOrder: ",vReturnOrder);  
        return vReturnOrder;
      }

      function time_convert2(num)
      { 
       // Calculate the number of hours by dividing num by 60 and rounding down
       var hours = Math.floor(num / 3600);  
     
       // Calculate the remaining minutes by taking the remainder when dividing num by 60
       var minutes = num % 60;
     
       // Return the result as a string in the format "hours:minutes"
       return hours + ":" + minutes;         
     }

      function time_convert(pDateFuture, pDatePast)
      { 
  // get total seconds between the times
        var delta = Math.abs(pDateFuture - pDatePast) / 1000;

        // calculate (and subtract) whole days
        var days = Math.floor(delta / 86400);
        delta -= days * 86400;

        // calculate (and subtract) whole hours
        var hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;

        // calculate (and subtract) whole minutes
        var minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;

        return days + " days " + hours + " hrs " + minutes + " min";         
      }


  return (
        
    <Table className={styles.RhyteItTable} highlightOnHover={true}>

    <TableHead>          
        <TableRow className={styles.TableHeaderRow}>
          <TableCell className={styles.TableHeaderRowCell} >Post Date</TableCell> 
          <TableCell className={styles.TableHeaderRowCell} >Post Time</TableCell> 
          <TableCell className={styles.TableHeaderRowCell} >Last Post</TableCell> 
          <TableCell className={styles.TableHeaderRowCell}>Follower Growth</TableCell> 
          <TableCell className={styles.TableHeaderRowCell}>Weight</TableCell> 
        </TableRow>                                      
      </TableHead>         
      <TableBody>    
          {
                vPostsSortedByDate && vPostsSortedByDate.map((vPost) => (         
                  <SMMADPostingRow    key={vPost.id} vSMMADPostingRow={vPost} />
              ))
          }           
    </TableBody>    
  </Table>
    
  )
}

export default SMMADPostings;

