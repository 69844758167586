
import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TableRow,
  Table,
  TableHead
  } from '@aws-amplify/ui-react'; 
import styles from './SMMADBubbleChart.module.css';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

ChartJS.register(
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend, 
  TimeScale
);

const SMMADBubbleChart = (props) => {       

  SMMADBubbleChart.propTypes = {
      SMMADBubbleChartData: PropTypes.array,
      Report: PropTypes.string,
      RhyteItUser: PropTypes.array
      };

    let debugConsoleOutPut = true

    const data1 = {
      labels: [],
      datasets: [
          {
            label: 'Weight',
            data: [],
            borderColor: "rgba(75,192,192,0.2)",
            backgroundColor: "rgba(75,192,192,1)",
            borderWidth: 2
          }
      ]
    }    


    const options1 = {
      scales: {
        y:
          {
            min: -1,
            max: 1,
            stepSize: 0.1,
          },
        xAxes: [
          {
            gridLines: {
              color: "rgba(0, 204, 204, 1)"
            }
          }
        ]
      }
    };       

    const [SMMADBubbleChartData, setSMMADBubbleChartData] = useState(props.SMMADBubbleChartData)   
    const [vEnvironment, setvEnvironment] = useState(props.Environment)   
    const [ShowTable, setShowTable] = useState(false);
    const [ProfilesReturned, setProfilesReturned] = useState(0);
    const [Labels, setLabels] = useState([]);
    const [PageViewDataSet, setPageViewDataSet] = useState(data1);
    const [GraphOptions, setGraphOptions] = useState(options1);
    const [Report, setReport] = useState(props.Report);
    

    useEffect(() => {
      try {        
        
          //console.log("SMMADBubbleChart.useEffect[].props.SMMADBubbleChartData: ",props.SMMADBubbleChartData);
          //console.log("SMMADBubbleChart.useEffect[].Report: ",Report);

          if (props.Report === "BubbleChartBestTimeOfWeek") {
            CreateDayOfWeekBubbleChart()
          } 
        } catch (err) {
          console.error('SMMADBubbleChart.useEffect[].ERROR: ', err, err.stack); 
      }      

    },[])  

    useEffect(() => {


        
      //console.log("SMMADBubbleChart.useEffect[Report].Report: ",Report);
      

    },[Report])  

    async function CreateDayOfWeekBubbleChart() {
      try {

                let vTimeJSON =  ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
                let vDayWeightDataSet =  []

                let vProfileTotal = SMMADBubbleChartData.length
                //console.log("SMMADBubbleChart.CreateDayOfWeekBubbleChart.vProfileTotal: ",vProfileTotal);
                setProfilesReturned(vProfileTotal.toLocaleString())
                for (var vTimeRecord in SMMADBubbleChartData[0]["DayHour"]) {
                  //console.log("SMMADBubbleChart.CreateDayOfWeekBubbleChart.vTimeRecord: " ,SMMADBubbleChartData[0]["Day"][vTimeRecord]["Weight"]);
                  //console.log("SMMADBubbleChart.CreateDayOfWeekBubbleChart.vTimeRecord[vTimeRecord]: " ,SMMADBubbleChartData[0]["Day"][vTimeRecord]["DayOfWeekName"]);
                  
                  var vDayOfWeekName = SMMADBubbleChartData[0]["DayHour"][vTimeRecord]["DayName"]
                  var vDayOfWeek = SMMADBubbleChartData[0]["DayHour"][vTimeRecord]["Day"]
                  let vWeight = SMMADBubbleChartData[0]["DayHour"][vTimeRecord]["Weight"]
                  let vHour = SMMADBubbleChartData[0]["DayHour"][vTimeRecord]["Hour"]
                  

                //console.log("SMMADBubbleChart.CreateDayOfWeekBubbleChart.window.innerHeight: ",window.innerHeight);
                let vItem = null
                  if (window.innerHeight < 2000) {
                     vItem = { x : vDayOfWeekName, y : parseInt(vHour), r :Math.round(parseFloat(vWeight)*10,2)}

                  } else {
                     vItem = { x : vDayOfWeekName, y : parseInt(vHour), r :Math.round(parseFloat(vWeight)*100,2)}
                  }

                  //console.log("SMMADBubbleChart.CreateDayOfWeekBubbleChart.vItem: " ,vItem)
                  vDayWeightDataSet[vTimeRecord] = vItem
                  
                }

                //console.log("SMMADBubbleChart.CreateDayOfWeekBubbleChart.vTimeJSON: ",vTimeJSON);
                //console.log("SMMADBubbleChart.CreateDayOfWeekBubbleChart.vDayWeightDataSet: ",vDayWeightDataSet);
                const data = {
                  labels: vTimeJSON,
                  datasets: [
                      {
                        label: "Weights",
                        data: vDayWeightDataSet,
                        borderColor: "rgba(0, 204, 204, 1)",
                        backgroundColor: "rgba(153, 255, 255, 1)",
                        borderWidth: 1
                      }
                  ]
                }
                //console.log("SMMADBubbleChart.CreateDayOfWeekBubbleChart.data: ",data);

                const options = {
                  pointStyle: "bubble",
                  datalabels: {
                      display: true,
                  },
                  scales: {
                    x: {
                      display: true,
                      type: 'category',
                      gridLines: { color: "#9999CC" },
                      title: {
                        display: true,
                        text: 'Day'
                      },
                      ticks: {
                        color: '#CCCBCA',
                        min: "Monday",
                        max: "Sunday"
                      },
                      grid: {
                        display: true
                      },
                      border: {
                        color: '#9999CC',
                      }
                    },
                    y: {
                      beginAtZero: false,
                      gridLines: { color: "#9999CC" },
                      title: {
                        display: true,
                        text: 'Hour'
                      },
                      ticks: {
                        color: '#CCCBCA'
                      },
                      grid: {
                        display: true
                      },
                      border: {
                        color: '#9999CC',
                      }                      
                    }
                  }
                };                

                setGraphOptions(options)
                setSMMADBubbleChartData(SMMADBubbleChartData)  
                setPageViewDataSet(data)

                //console.log("GetOFStatsFromS3.vTimeJSON: " ,vTimeJSON);
                setLabels(vTimeJSON)
                 //let PopulateOnlyFansNamesListResponse = await PopulateOnlyFansNamesList(OnlyFansProspectingProfilesData1)                      
  


      } catch (err) { 
                      console.error('SalesMonitor.GetOnlyFansProspectingProfiles.ERROR: ', err, err.stack); 
                  }
    }      

  

  return (  
      <div className={styles.Table} >   
            {PageViewDataSet.labels.length > 0 && ( 
              <Bubble
                datasetIdKey='id'
                data={PageViewDataSet}
                options={GraphOptions}
                className={styles.LineGraph}
              />
            )}             
      </div>
    
  )
}

export default SMMADBubbleChart;


