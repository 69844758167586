import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';

import  { listProducts }  from '../../graphql/queries'
import  { }  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import Header from "../RhyteItComponents/Header/Header";
import {ValidateUser, RedirectToHomePage} from '../../BusinessLogic/UserManagement';
import {motion as m} from "framer-motion";
import ProductManagementTable from "./ProductManagementTable";
import { UserContext, CognitoGroupContext} from '../../BusinessLogic/Hooks/UserContext';
import {GetGroupRoleAccess, RhyteItLogging, ReturnUserToLandingPage} from '../../BusinessLogic/UserManagement';
import {Link, useNavigate } from 'react-router-dom';


import {
  Button , SwitchField, Text, Flex
} from '@aws-amplify/ui-react';
import styles from './ProductManagement.module.css';

const ProductManagement = (props) => {       
  
  ProductManagement.propTypes = {
          RedditUser: PropTypes.object
        };

        const client = generateClient();
        const [RhyteItProductManagement, setRhyteItProductManagement] = useState([]) 
        const vBreadCrumbItems = [{Path: "/", Title:"Home", isCurrent:false},{Path: "/MainMenuV2", Title:"Menu", isCurrent:false},{Path: "/ProductManagement", Title:"Products", isCurrent:true}]
     
        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   
        let debugConsoleOutPut = true

        let navigate = useNavigate(); 
        const {Users, setUsers} = useContext(UserContext);    
        const {CognitoGroup, setCognitoGroup} = useContext(CognitoGroupContext);      
                
        const [ProductsReturned, setProductsReturned] = useState(0);
        const [ShowTable, setShowTable] = useState(false);
        

        const [updatedKey, setupdatedKey] = useState(Date.now())   

        const [isChecked, setIsChecked] = React.useState(false);
        const [Environment, setEnvironment] = React.useState(process.env.REACT_APP_ENV);

        

        useEffect(() => {

        },[]) 

        useEffect(() => {

 
         },[Users]) 
         
         
         useEffect(() => {

          if (CognitoGroup !== undefined ) {

              const vCognitoGroup = Object.values(CognitoGroup).filter(cg => {
                return cg ===  "rhyteitAdmins";
              }).length;
          
              if (vCognitoGroup === 0) { 
                window.location.href = "https://www.rhyteit.com";
              }          
              else if (vCognitoGroup > 0) {
                  GetProductManagement()            
              } 
          
          }
         },[CognitoGroup])           

        useEffect(() => {
          //console.log("ProductManagement.useEffect[RhyteItProductManagement].RhyteItProductManagement:  ",RhyteItProductManagement)
          //console.log("SBSMQueries.useEffect[SBSMNewsQueryTerms].Object.keys(SBSMNewsQueryTerms).length:  ",Object.keys(SBSMNewsQueryTerms).length )
 
           if (Object.keys(RhyteItProductManagement).length > 0) {
             setShowTable(true)
           }
 
           setErrorMessage("Products retrieved at: " + new Date().toLocaleString())
 
         },[RhyteItProductManagement])     
   
        useEffect(() => {
        //console.log("ProductManagement.useEffect[RhyteItProductManagement].RhyteItProductManagement:  ",RhyteItProductManagement)
        //console.log("SBSMQueries.useEffect[SBSMNewsQueryTerms].Object.keys(SBSMNewsQueryTerms).length:  ",Object.keys(SBSMNewsQueryTerms).length )

          if (isChecked) {
          setEnvironment("PRODUCTION")
          } else {
          setEnvironment("TEST")
          }

          setErrorMessage("Products retrieved at: " + new Date().toLocaleString())

        },[isChecked])     
   

        async function GetProductManagement() {
          try {
                    setErrorMessage("")
                    setRhyteItProductManagement([])
                    if (debugConsoleOutPut === true) {
                        //console.log("ProductManagement.GetProductManagement.Users: ", Users)
                    }


                    if (debugConsoleOutPut === true ) {
                      //console.log("ProductManagement.GetProductManagement.Users[0].UserId: ",Users[0].UserId)
                    }


                    const ProductManagementData2  = await client.graphql({
                      query: listProducts
                    });
      


                    if (ProductManagementData2.data.listProducts.items.length > 0) {
                        //console.log("ProductManagementData2.data.listProductManagement.items.length: ",ProductManagementData2.data.listProducts.items.length);
                        setProductsReturned(ProductManagementData2.data.listProducts.items.length)
                        const ProductManagement1 = ProductManagementData2.data.listProducts.items 

                        setRhyteItProductManagement(ProductManagement1)       
                        setupdatedKey(Date.now())            
                        setShowTable(true)
           
                  }

          } catch (err) { 
                          console.error('ProductManagement.GetProductManagement.ERROR: ', err, err.stack); 
                      }
        }            

        async function AddProductRow() {
          try {              
              
            if (debugConsoleOutPut === true) {
              //console.log("ProductManagement.AddProductRow.Users:  ", Users)
           }
    
            var vUpdateDateTime = new Date().toISOString()

            let NewProduct = {
                  id: "NEW",
                  UserId: Users[0].UserId,
                  Product: 'Product A',
                  Description:'<Enter Product Description>', 
                  PaymentMode: 'payment',    
                  PriceId: '<Enter Price Id>',   
                  Price: '<Enter Price>', 
                  SuccessURL: '<Enter Success URL>',   
                  CancelURL:  '<Enter Cancel URL>',   
                  ProductImage:  '<Enter Image URL>',  
                  SocialMedia:  '<Select SocialMedia>',  
                  Likes:  0,  
                  Reposts:  0,  
                  Follow:  0,  
                  Environment: Environment,        
                  CreationName: Users[0].UserId,
                  UpdateName: Users[0].UserId,
                  CreationDateTime: vUpdateDateTime,
                  UpdateDateTime: vUpdateDateTime
            }    


          if (debugConsoleOutPut === true) {
              //console.log("ProductManagement.AddProductRow.NewProduct:  ", NewProduct)
              //console.log("ProductManagement.AddQueryTerm.SBSMNewsQueryTerms.isArray:  ", Array.isArray(SBSMNewsQueryTerms))
              //console.log("ProductManagement.AddQueryTerm.Object.keys(SBSMNewsQueryTerms).length:  ", Object.keys(SBSMNewsQueryTerms).length)
              
          }          

          let vArraySpot = Object.keys(RhyteItProductManagement).length

          RhyteItProductManagement[vArraySpot] = NewProduct;

            if (debugConsoleOutPut === true) {
              //console.log("ProductManagement.AddProductRow.RhyteItProductManagement:  ", RhyteItProductManagement)            
            }

            setRhyteItProductManagement(RhyteItProductManagement)           
            setupdatedKey(Date.now())            
            setShowTable(true)
    
    
            } catch (err) { 
              console.error("ProductManagement.AddProductRow.Error:  ".err, err.stack); 
          }        
        }        

    return ( 
    <m.div
      key="Search"
      initial={{x: "100%"}}
      animate={{x: "0%"}}
      transition={{duration:0.75, ease: "easeOut"}}
      exit={{ opacity: 1 }}
     >
                          
                  <Flex
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      alignContent="flex-start"
                      wrap="nowrap"
                      gap="3rem"
                      className={styles.GridContainer}
                    >     
                      <Header Title="Product Management" SubTitle="Manage RhyteIt Digital Products" BreadCrumbItems ={vBreadCrumbItems} pageSource="ProductManagement"/>  
                      <Flex
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          alignContent="flex-start"
                          wrap="nowrap"
                          gap="3rem"
                        >                                
                              <Button
                                loadingText=""
                                ariaLabel=""
                                className={styles.RedditTitleButtons}
                                onClick={ (e) => AddProductRow(e)}
                              > Add Row 
                              </Button>  
                              <SwitchField
                                      className={styles.SwitchField}
                                      isChecked={isChecked}
                                      onChange={(e) => {
                                        setIsChecked(e.target.checked);
                                      }}
                                >
                              </SwitchField>   
                              <Text  className={styles.EnvironmentText}>
                              {Environment}
                              </Text>                                                                                                                                                                                                     
                      </Flex>                                
                      <div className={styles.MessageBox}>{ErrorMessage} Products retrieved: {ProductsReturned}</div>  
                      {ShowTable && RhyteItProductManagement.length > 0 && (<ProductManagementTable   key={updatedKey}  RhyteItProductManagement={RhyteItProductManagement}/>)  }                                                                                                              
                    </Flex>    
    </m.div>                      

    )

  }
 

export default ProductManagement;