import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {motion as m} from "framer-motion";
import styles from './Background.module.css';



import BackgroundAurora from './BackgroundAurora';
import Logo3D from "../LandingPage/BackgroundSpringCanvas";
import {
  Flex,
  Image
} from '@aws-amplify/ui-react';

const Background = (props) => {       
  
  Background.propTypes = {
    backgroundcolor: PropTypes.string
        };

        
        const [vBGColor, setvBGColor] = useState(props.backgroundcolor);  
              

        useEffect(() => {

            

        },[]) 

      


    return ( 
            <div className={styles.BackgroundContainer}> 
              <BackgroundAurora className={styles.BackgroundAurora} GradientStopColor1="#0000" GradientStopColor2="#000"  GradientStopColor3={vBGColor}   GradientStopColor4="#8880"/>                                                                     
              <div className = {styles.Logo3D}>
                <Logo3D />     
              </div>                          
            </div>  
    )

  }
 

export default Background;