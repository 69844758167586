import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  forwardRef,
} from "react";
import PropTypes from "prop-types";
import styles from "./OurStory.module.css";

import {
  useInView,
  useScroll,
  useTransform,
  motion,
  useAnimation,
} from "framer-motion";
//import {useInView} from "react-intersection-observer";

import { Button, Flex, Text, Image } from "@aws-amplify/ui-react";
import Background from "../../Backgrounds/BackgroundNoLogo";
import SBSMLogoTickerHorizontal from "../../Experiments/SBSMLogoTickerHorizontal";
import TurboFanBoostButton from "../../Buttons/TurboFanBoostButton";
import { Link } from "react-router-dom";

const OurStory = (props) => {
  OurStory.propTypes = {
    scrollYProgress: PropTypes.object,
  };

  const scale = useTransform(props.scrollYProgress, [0.11, 0.50,0.85, 0.9], [0.2, 1, 1, 0.1]);
  const rotate = useTransform(props.scrollYProgress, [0.11, 0.50,0.85, 0.9], [0, 0, 0, -10 ]);
  const opacity = useTransform(props.scrollYProgress, [0.11, 0.50,0.85, 0.95], [0,1, 1, 0 ]);

  const ref1 = useRef(null);
  const isInView = useInView(ref1);

  useEffect(() => {
    //console.log("OurStory.useEffect[].props.id: " ,props.id);
    //console.log("OurStory.useEffect[].ref: " ,ref);
  }, []);

  useEffect(() => {
    //    console.log("OurStory.useEffect[isInView].isInView: " ,isInView);
  }, [isInView]);

  return (
    <motion.section style={{ scale, rotate, opacity }}  ref={ref1} className={styles.SectionContainer}>
      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        alignContent="flex-start"
        wrap="wrap"
        className={styles.TextFlexColumn}
      >
        <Flex
          direction="row"
          justifyContent="left"
          alignItems="center"
          alignContent="flex-start"
          wrap="wrap"
          className={styles.HeaderTextRow}
        >
          <motion.div className={styles.MayanNumber}>
            <img
              alt=""
              src="https://rhyteiticons.s3.us-west-2.amazonaws.com/MenuGraphics/MenuLineBlue.svg"
              className={styles.MayanLine}
            />
          </motion.div>
          <motion.div>
            <motion.h1
              style={{
                transform: isInView ? "none" : "translateY(-200px)",
                opacity: isInView ? 1 : 0,
                transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
              }}
              className={styles.HeaderText}
            >
              Our Story
            </motion.h1>
          </motion.div>
        </Flex>
        <Flex
          direction="row"
          justifyContent="left"
          alignItems="center"
          alignContent="flex-start"
          wrap="nowrap"
          className={styles.HeaderSubTextRow}
        >
          <motion.h1
            style={{
              transform: isInView ? "none" : "translateY(100px)",
              opacity: isInView ? 1 : 0,
              transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
            }}
            className={styles.HeaderSubText}
          >
            We understand the frustration of feeling like you're constantly
            chasing after social media success.
          </motion.h1>
        </Flex>
        <Flex
          direction="row"
          justifyContent="left"
          alignItems="center"
          alignContent="flex-start"
          wrap="nowrap"
          className={styles.HeaderSubTextRow}
        >
          <motion.h1
            style={{
              transform: isInView ? "none" : "translateY(100px)",
              opacity: isInView ? 1 : 0,
              transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
            }}
            className={styles.HeaderSubText}
          >
            But we believe it's time to put you back in the driver's seat of
            your brand
          </motion.h1>
        </Flex>
        <Link className={styles.ApplicationLink} to="/TurboFanBoost">
          <TurboFanBoostButton
            className={styles.TurboFanBoostButton}
          ></TurboFanBoostButton>
        </Link>
      </Flex>
      <motion.div
        style={{
          transform: isInView ? "none" : "translateX(-200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
        className={styles.Background}
      >
        <SBSMLogoTickerHorizontal className={styles.SBSMLogoTickerHorizontal} />
        <Background backgroundcolor="#CC6633" />
      </motion.div>
    </motion.section>
  );
};

export default OurStory;
