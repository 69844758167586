import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { motion as m } from "framer-motion";
import styles from "./BackgroundStandOut.module.css";

import {
  useInView,
  motion,
} from "framer-motion";
import { Button, Flex, Text, Image } from "@aws-amplify/ui-react";
import useMousePosition from "../../BusinessLogic/Hooks/useMousePosition";

const BackgroundStandOut = (props) => {
  BackgroundStandOut.propTypes = {
    backgroundcolor: PropTypes.string,
  };

  const ref = useRef(null);
  const isInView = useInView(ref);

  const [mousePosition, setMousePosition] = useState({ x: 100, y: 100 });

  const [mouseCoords, handleCursorMovement] = useMousePosition(true);

  const [vBenefitButtonVisible, setvBenefitButtonVisible] = useState(false);
  const [vBenefitButtonClicked, setvBenefitButtonClicked] = useState(false);

  useEffect(() => {
    const handleMouseMove = (event) => {
      // Update mouse position state with the current mouse position
      setMousePosition({ x: event.pageX, y: event.pageY });
    };

    // Add mouse move event listener
    document.addEventListener("mousemove", handleMouseMove);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useEffect(() => {


    if (
      mouseCoords.x > 400 &&
      mouseCoords.x < 1100 &&
      mouseCoords.y > 10 &&
      mouseCoords.y < 1200
    ) {
      setvBenefitButtonVisible(true);
    } else {
      setvBenefitButtonVisible(false);
    }
  }, [mouseCoords]);

  function handleClick() {
    console.log("BackgroundStandOut.handleClick: " ,vBenefitButtonClicked);
    //
    //<h1  className={styles.HeaderText}>x: {Math.round(mouseCoords.x)} and y: {Math.round(mouseCoords.y)}</h1>
  }

  return (
    <div className={styles.BackgroundContainer}>

      {vBenefitButtonVisible === true && (
        <motion.div className={styles.BenefitDev}>
            <div>
              <motion.h1
                initial={{ y: 300, x: 50, opacity: 0, scale: 0 }}
                whileInView={{ y: 300, x: 50, opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 1 }}
                className={styles.BenefitText}
              >
                More time for creating killer content
              </motion.h1>
              <motion.h1
                initial={{ y: 350, x: 25, opacity: 0, scale: 0 }}
                whileInView={{ y: 350, x: 25, opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 1.5 }}
                className={styles.BenefitText}
              >
                Boost your fan engagement and loyalty
              </motion.h1>
              <motion.h1
                initial={{ y: 350, x: 1150, opacity: 0, scale: 0 }}
                whileInView={{ y: 350, x: 1150, opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 1.5 }}
                className={styles.BenefitText}
              >
                Grow your subscriber base effortlessly
              </motion.h1>
              <motion.h1
                initial={{ y: 150, x: 1150, opacity: 0, scale: 0 }}
                whileInView={{ y: 150, x: 1150, opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                className={styles.BenefitText}
              >
                Save money with our affordable solution
              </motion.h1>
              <motion.h1
                initial={{ y: 0, x: 400, opacity: 0, scale: 0 }}
                whileInView={{ y: -100, x: 400, opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                className={styles.BenefitText}
              >
                Feel empowered with a thriving online presence
              </motion.h1>
            </div>
          <motion.div
            initial={{ y: 0, x: 700, opacity: 0 }}
            whileInView={{ y: 0, x: 700, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            className={styles.CircleFollowMouse}
          >
            <div className={styles.Circle}>
              <h1 className={styles.ButtonText}>Benefits</h1>
            </div>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

export default BackgroundStandOut;
