import { useEffect, useState } from "react";

const useMousePosition = (pGlobal) => {

  const [mouseCoords, setMouseCoords] = useState({
    x: 0,
    y: 0,
  });

  const handleCursorMovement = (event) => {
    
    //console.log("useMousePosition.handleCursorMovement.event.clientX:  ",event.clientX) 

    //@ts-ignore
    let rect = event.target.getBoundingClientRect();
    setMouseCoords({
      x: (event.clientX - rect.left)-10,
      y: (event.clientY - rect.top)-10,
    });
  };

  useEffect(() => {

    if (pGlobal) {
      window.addEventListener("mousemove", handleCursorMovement);

      return () => {
        window.removeEventListener("mousemove", handleCursorMovement);
      };
    }
  }, [pGlobal]);

  return [mouseCoords, handleCursorMovement];
};

export default useMousePosition;
