import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import styles from './MenuBackground.module.css';

const MenuBackground  = (props) => {
 
    MenuBackground.propTypes = {
    UserId: PropTypes.string,
    UserName: PropTypes.string,
    GradientStopColor1: PropTypes.string,
    GradientStopColor2: PropTypes.string,
    GradientStopColor3: PropTypes.string,
    GradientStopColor4: PropTypes.string
  };


  useEffect(() => {

     
    },[])     


  return (
 
            <div className = {styles.LandingPageContainer} >    
                    <div className={styles.menuCover}></div>
                    <div className={styles.menuBg}>                         
                        <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice" className={styles.menuBgSVG}>
                            <defs>
                                <radialGradient id="Gradient1" cx="50%" cy="50%" fx="10%" fy="50%" r=".5">
                                    <animate attributeName="fx" dur="64s" values="0%;3%;0%" repeatCount="indefinite"></animate>
                                    <stop offset="0%" stopColor={props.GradientStopColor3}></stop>
                                    <stop offset="100%" stopColor={props.GradientStopColor1}></stop>
                                </radialGradient>
                                <radialGradient id="Gradient2" cx="50%" cy="50%" fx="10%" fy="50%" r=".5">
                                    <animate attributeName="fx" dur="47s" values="0%;3%;0%" repeatCount="indefinite"></animate>
                                    <stop offset="0%" stopColor={props.GradientStopColor2}></stop>
                                    <stop offset="100%" stopColor={props.GradientStopColor4}></stop>
                                </radialGradient>
                                <radialGradient id="Gradient3" cx="50%" cy="50%" fx="50%" fy="50%" r=".5">
                                    <animate attributeName="fx" dur="43s" values="0%;3%;0%" repeatCount="indefinite"></animate>
                                    <stop offset="0%" stopColor={props.GradientStopColor2}></stop>
                                    <stop offset="100%" stopColor={props.GradientStopColor1}></stop>
                                </radialGradient>
                            </defs>  
  
                            <rect x="0" y="0" width="100%" height="100%" fill="url(#Gradient1)">
                                <animate attributeName="x" dur="40s" values="25%;0%;25%" repeatCount="indefinite"></animate>
                                <animate attributeName="y" dur="42s" values="0%;25%;0%" repeatCount="indefinite"></animate>
                                <animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="34s" repeatCount="indefinite"></animateTransform>
                            </rect>
                            <rect x="0" y="0" width="100%" height="100%" fill="url(#Gradient2)">
                                <animate attributeName="x" dur="46s" values="-25%;0%;-25%" repeatCount="indefinite"></animate>
                                <animate attributeName="y" dur="48s" values="0%;50%;0%" repeatCount="indefinite"></animate>
                                <animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="36s" repeatCount="indefinite"></animateTransform>
                            </rect>

                            <rect x="0" y="0" width="100%" height="100%" fill="url(#Gradient3)">
                                <animate attributeName="x" dur="50s" values="0%;25%;0%" repeatCount="indefinite"></animate>
                                <animate attributeName="y" dur="52s" values="0%;25%;0%" repeatCount="indefinite"></animate>
                                <animateTransform attributeName="transform" type="rotate" from="360 50 50" to="0 50 50" dur="38s" repeatCount="indefinite"></animateTransform>
                            </rect>
                        </svg>                
                    </div>            
            </div>   

        );
  }

export default MenuBackground