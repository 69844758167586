import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  Label,
  Badge,
  Text,
  Flex,
  SelectField,
  Button,
  Fieldset,
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHead,
} from "@aws-amplify/ui-react";
import styles from "./SMMADDeleteCampaigns.module.css";
import {
  listSMMADCampaigns,
  listSMMADContents,
} from "../../../graphql/queries";
import {
  updateSMMADCampaigns,
  updateSMMADContent,
} from "../../../graphql/mutations";
import { generateClient } from "aws-amplify/api";
import SMMADOrganizeNewField from "./SMMADOrganizeNewField";
import { UserContext } from "../../../BusinessLogic/Hooks/UserContext";

import DeleteCampaignRow from "./DeleteCampaignRow";

const SMMADDeleteCampaigns = (props) => {
  SMMADDeleteCampaigns.propTypes = {
    AccountSelected: PropTypes.string,
  };

  let debugConsoleOutPut = true;

  var ErrorMessageStr = "";
  const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr);

  const client = generateClient();
  const [vSMMADCampaigns, setvSMMADCampaigns] = useState([]);
  const [vAccountSelected, setvAccountSelected] = useState(
    props.AccountSelected
  );
  const [vCampaignSelectedFrom, setvCampaignSelectedFrom] = useState();
  const [vCampaignSelectedTo, setvCampaignSelectedTo] = useState();
  const [vCampaignToShow, setvCampaignToShow] = useState();

  const [CampaignActivateButtonText, setCampaignActivateButtonText] =
    useState("Start Posting");
  const [vContentTextActive, setvContentTextActive] = useState(true);
  const [SMHashTag, setSMHashTag] = useState([]);

  const [vCampaign, setvCampaign] = useState("");
  const [vAddNewCampaignVisible, setvAddNewCampaignVisible] = useState(false);
  const [vCampaignHelpBadgeHovered, setvCampaignHelpBadgeHovered] =
    useState(false);

  const [SMContent, setSMContent] = useState([]);
  const [vCampaignSelected, setvCampaignSelected] = useState(
    props.CampaignSelected
  );

  const [nextToken, setnextToken] = useState(undefined);


  const [IsLoading, setIsLoading] = useState(false);
  const { Users, setUsers } = useContext(UserContext);

  useEffect(() => {
    //console.log("SMMADDeleteCampaigns.useEffect[].props.AccountSelected:  ",props.AccountSelected);

    (async function () {
      try {
        GetSMMADCampaigns();
      } catch (err) {
        console.error("SMMADOrganize.useEffect[].ERROR: ", err, err.stack);
      }
    })();
  }, []);

  useEffect(() => {
    if (vSMMADCampaigns !== undefined) {
      if (vSMMADCampaigns.length > 0) {
        //  //console.log("SMMADDeleteCampaigns.useEffect[vSMMADCampaigns].vSMMADCampaigns:  ",vSMMADCampaigns)
        //console.log("SMMADDeleteCampaigns.useEffect[vSMMADCampaigns].vAccountSelected:  ",vAccountSelected)

        if (vAccountSelected !== "edwardhawke@rhyteit.com") {
          setvCampaignSelectedFrom("Default");
        }
      }
    }
  }, [vSMMADCampaigns]);


  async function GetSMMADCampaigns() {
    try {
      setErrorMessage("");
      setvSMMADCampaigns([]);

      //console.log("SMMADDeleteCampaigns.GetSMMADCampaigns.vAccountSelected:  ",vAccountSelected)
      let variables;

      variables = {
        filter: {
          Account: {
            eq: vAccountSelected,
          },
        },
      };

      const SMMADOrganizeData2 = await client.graphql({
        query: listSMMADCampaigns,
        variables: variables,
      });

      if (SMMADOrganizeData2.data.listSMMADCampaigns.items.length > 0) {
        const SMMADOrganize1 = SMMADOrganizeData2.data.listSMMADCampaigns.items;

        console.log("SMMADDeleteCampaigns.GetSMMADCampaigns.SMMADOrganizeData2.data.listSMMADCampaigns.items:  ",SMMADOrganizeData2.data.listSMMADCampaigns.items)

        setvSMMADCampaigns(SMMADOrganize1);
      }
    } catch (err) {
      console.error("SMMADOrganize.GetSMMADOrganize.ERROR: ", err, err.stack);
    }
  }



  const handleMouseOver = () => {
    let vCampaignHelpBadgeHoveredtmp =
      vCampaignHelpBadgeHovered === true ? false : true;

    setvCampaignHelpBadgeHovered(vCampaignHelpBadgeHoveredtmp);
  };


  return (
    <Flex
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      wrap="nowrap"
      gap="1rem"
      className={styles.GridContainer}
      key={Date.now() + 1}
    >
      <Badge
        variation="info"
        size="small"
        className={
          vCampaignHelpBadgeHovered === false
            ? styles.BadgeHelp
            : styles.BadgeHelphover
        }
        onClick={handleMouseOver}
      >
        <Flex
          direction="row"
          justify="center"
          alignItems="center"
          align="flex-start"
          wrap="nowrap"
          gap="1rem"
        >
          {vCampaignHelpBadgeHovered === false && (
            <Text className={styles.BadgeHelpQuestionMark}>?</Text>
          )}
          {vCampaignHelpBadgeHovered === true && (
            <Flex
              direction="row"
              justify="center"
              alignItems="center"
              align="flex-start"
              wrap="nowrap"
              gap="1rem"
            >
              <Text className={styles.BadgeHelpQuestionMark}>?</Text>
              <Flex
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                wrap="nowrap"
                gap="0.25rem"
                className={styles.BadgeHelpFlexColumnText}
              >
                <Text
                  className={
                    vCampaignHelpBadgeHovered === true
                      ? styles.BadgeHelpText.transition
                      : styles.BadgeHelpText
                  }
                >
                  You can group your posts into campaigns.
                </Text>
                <Text
                  className={
                    vCampaignHelpBadgeHovered === true
                      ? styles.BadgeHelpText.transition
                      : styles.BadgeHelpText
                  }
                >
                  If you do not want a campaign to run for awhile, you can
                  deactivate it.
                </Text>
                <Text
                  className={
                    vCampaignHelpBadgeHovered === true
                      ? styles.BadgeHelpText.transition
                      : styles.BadgeHelpText
                  }
                >
                  You can also delete the campaign and all its posts.
                </Text>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Badge>
      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        wrap="nowrap"
        gap="1rem"
        className={styles.FlexColumnContentText}
        key={Date.now() + 1}
      >
        {
          vSMMADCampaigns !== undefined && (
            <Fieldset className={styles.Fieldset} size="small">
              <Text className={styles.Instruction}>
                * Click on a campaign to delete the campaign and all content and text associated with it
              </Text>
                  <Table
                    className={styles.RhyteItTable}
                    highlightOnHover={true}
                  >
                    <TableHead className={styles.TableHead}>
                      <TableRow className={styles.TableHeaderRow}>
                        <TableCell className={styles.TableHeaderRowCells}>
                        Campaign
                        </TableCell>
                        <TableCell className={styles.TableHeaderRowCells}>
                        Status
                        </TableCell>
                        <TableCell className={styles.TableHeaderRowCells}>
                        Text Rule
                        </TableCell>
                        <TableCell className={styles.TableHeaderRowCells}>
                        # Images/Videos 
                        </TableCell>
                        <TableCell className={styles.TableHeaderRowCells}>
                        # Texts
                        </TableCell>
                        <TableCell className={styles.TableHeaderRowCells}>
                          Posted #
                        </TableCell>
                        <TableCell className={styles.TableHeaderRowCells}>
                          Weight
                        </TableCell>
                        <TableCell className={styles.TableHeaderRowCells}>
                          Last Post
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {vSMMADCampaigns.map((SMMADCampaignRow) => {
                           return <DeleteCampaignRow
                              key={SMMADCampaignRow.id}
                              SMMADCampaignRow={SMMADCampaignRow}
                            />
                        })}
                    </TableBody>
                  </Table>    
            </Fieldset>
          )}
      </Flex>
    </Flex>
  );
};

export default SMMADDeleteCampaigns;
