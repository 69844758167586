import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {motion as m} from "framer-motion";
import Marquee from "react-fast-marquee";
import styles from './Background.module.css';

import SubredditTicker from './SubredditTicker';
import SBSMLogoTicker from './SBSMLogoTicker';
import TreeGrowth from './TreeGrowth';
import SBSMNewsTicker from './SBSMNewsTicker';
import ProductReviewsTicker from './ProductReviewsTicker';


import MenuBackground from '../Menu/MenuBackground';
import Logo3D from "../LandingPage/BackgroundSpringCanvas";
import {
  Flex,
  Image
} from '@aws-amplify/ui-react';

const Background = (props) => {       
  
  Background.propTypes = {
          RedditUser: PropTypes.object
        };
        const [SBSMNewsQueryTerms, setSBSMNewsQueryTerms] = useState()


        const [OnlyFansProspectingProfilesData, setOnlyFansProspectingProfilesData] = useState([])              
              

        useEffect(() => {

            

        },[]) 

      


    return ( 
            <div className={styles.MarqueeContainer}> 

              <Logo3D className = {styles.Logo3D}></Logo3D>  
              <div className={styles.TreeGrowth}> 
                <TreeGrowth />
              </div>  
              <div className={styles.SBSMLogoTicker}> 
                <SBSMLogoTicker />
              </div>  
              <div className={styles.SubredditTicker}> 
                <SubredditTicker />
              </div>  
              <div className={styles.SBSMNewsTicker}> 
                <SBSMNewsTicker />
              </div>                   
              <MenuBackground className={styles.MenuBackground} GradientStopColor1="#0000" GradientStopColor2="#000"  GradientStopColor3="#00b1b1"   GradientStopColor4="#8880"/>                                                                                              
            </div>  
    )

  }
 

export default Background;