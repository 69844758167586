
import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead
  } from '@aws-amplify/ui-react'; 
import SBSMRSSRow from './SBSMRSSRow';  
import styles from './SBSMRSSTable.module.css';


const SBSMRSSTable = (props) => {       

  SBSMRSSTable.propTypes = {
      SBSMRSSFeeds: PropTypes.array,
      RhyteItUser: PropTypes.array
      };

    let debugConsoleOutPut = true


    const [updatedKey, setupdatedKey] = useState(Date.now())  
    const [SBSMRSSFeeds, setSBSMRSSFeeds] = useState(Object.entries(props.SBSMRSSFeeds))
    const [ShowTable, setShowTable] = useState(false);

    useEffect(() => {

        //console.log("SBSMRSSTable.useEffect[].props.SBSMRSSFeeds:  ",props.SBSMRSSFeeds) 
        //console.log("SBSMRSSTable.useEffect[].Object.keys(SBSMRSSFeeds).length:  ",Object.keys(SBSMRSSFeeds).length)
        //console.log("SBSMRSSTable.useEffect[].Object.Array.isArray(SBSMRSSFeeds):  ",Array.isArray(SBSMRSSFeeds))
         

        if (Object.keys(SBSMRSSFeeds).length > 0) {
          setShowTable(true)
        }

      },[])  

      useEffect(() => {

        //console.log("SBSMRSSTable.useEffect[].SBSMRSSFeeds:  ",SBSMRSSFeeds) 
  
      },[SBSMRSSFeeds])        

  return ( 
    <div>
      <Table className={styles.RhyteItTable}   highlightOnHover={true}>   
          <TableHead>          
            <TableRow className={styles.TableHeaderRow}>
              <TableCell className={styles.TableHeaderRowCells}>RSS</TableCell>   
              <TableCell className={styles.TableHeaderRowCells} >RSS URL</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Info Type</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Source URL</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} colSpan="1" >Actions</TableCell> 
            </TableRow>                                      
          </TableHead>       
              {
                ShowTable === true && Array.isArray(SBSMRSSFeeds)
                ? SBSMRSSFeeds.map((SBSMRSSFeed) => (         
                <SBSMRSSRow  key={SBSMRSSFeed.id} SBSMRSSFeedRow={SBSMRSSFeed}/>))  : null
                 
              }                    
        </Table>
      </div> 
    
  )
}

export default SBSMRSSTable;


