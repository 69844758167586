import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';

import {ErrorBoundary} from 'react-error-boundary'
import Header from "../RhyteItComponents/Header/Header";

import { generateClient } from 'aws-amplify/api';

import {ValidateUser} from '../../BusinessLogic/UserManagement';
import RhyteItAccountDetail from './RhyteItAccountDetail';
import RhyteItAccountSocialMedia from './RhyteItAccountSocialMedia';
import RhyteItAccountSD from './RhyteItAccountSD';
import RhyteItAccountTwitter from './RhyteItAccountTwitter';


import {motion as m} from "framer-motion";
import { UserContext} from '../../BusinessLogic/Hooks/UserContext';
import { useNavigate, useLocation } from "react-router-dom";

import {
  Authenticator,
  Image,
  Flex,
  Tabs

} from '@aws-amplify/ui-react';
import styles from './RhyteItAccountTabs.module.css';
import styled from 'styled-components'


function ErrorFallback({error, resetErrorBoundary}) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

const LabelText = styled.div`
                        color: white;
                        font-size: 1.5em !important;
                        `;


const RhyteItAccountTabs = () => {      



        const client = generateClient();
        let debugConsoleOutPut = true
        let navigate = useNavigate(); 

        const location = useLocation();
        const { vRhyteItAccount, User } = location.state;


        const vBreadCrumbItems = [{Path: "/", Title:"Home", isCurrent:false},{Path: "/MainMenuV2", Title:"Menu", isCurrent:false},{Path: "/RhyteItAccounts", Title:"RhyteIt Accounts", isCurrent:false},{Path: "/RhyteItAccountTabs", Title:"RhyteIt Account", isCurrent:true}]
        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   
        const [RedoImageChecked, setRedoImageChecked] = useState(vRhyteItAccount.RedoImage)  
        const [RecordUpdated, setRecordUpdated] = useState(false)  
        
 

        let {Users} = useContext(UserContext);   
        const [RhyteItAccount, setRhyteItAccount] = useState(vRhyteItAccount) 
        const [UserEmail, setUserEmail] = useState(User)  



 
        useEffect(() => {

          (async function() {
            try {  


              console.log("RhyteItAccountTabs.useEffect[].UserEmail:  ",UserEmail)


                } catch (err) {
                  console.error('RhyteItAccountTabs.useEffect[].ERROR: ', err, err.stack); 
              }
            }
          )();   
      

        },[])   
                 
           

    return ( 
    <m.div
      key="RhyteItAccounts"
      initial={{y: "100%"}}
      animate={{y: "0%"}}
      transition={{duration:0.75, ease: "easeOut"}}
      exit={{ opacity: 1 }}
     >
      <Authenticator  className={styles.AuthenticatorLogin} socialProviders={[]} hideSignUp={true}>
        {({ signOut, user }) => (   
          <div className={styles.SystemAdminBackground}>
            <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                >   
              <Flex
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      alignContent="flex-start"
                      wrap="nowrap"
                      gap="3rem"
                      className={styles.GridContainer}
                    >           
                      <Header Title="RhyteIt Account" SubTitle={RhyteItAccount.Name} BreadCrumbItems ={vBreadCrumbItems} pageSource="RhyteItAccountTabs"/>   
                      
                      <Image
                          alt=""
                          src={RhyteItAccount.ProfileImageURL}
                          className={styles.ProfileImage}
                        />
                      <Flex direction="column" gap="2rem">
                        
                        <Tabs.Container defaultValue="Details">
                          <Tabs.List justifyContent="left">
                            <Tabs.Item value="Details" className={styles.TabItem}>Details</Tabs.Item>
                            <Tabs.Item value="Social Media" className={styles.TabItem}>Social Media</Tabs.Item>
                            <Tabs.Item value="Stable Diffusion" className={styles.TabItem}>Stable Diffusion</Tabs.Item>
                            <Tabs.Item value="Twitter" className={styles.TabItem}>Twitter</Tabs.Item>
                          </Tabs.List>
                          <Tabs.Panel value="Details"><RhyteItAccountDetail vRhyteItAccount={RhyteItAccount} UserEmail={UserEmail}/></Tabs.Panel>
                          <Tabs.Panel value="Social Media"><RhyteItAccountSocialMedia vRhyteItAccount={RhyteItAccount} UserEmail={UserEmail}/></Tabs.Panel>
                          <Tabs.Panel value="Stable Diffusion"><RhyteItAccountSD vRhyteItAccount={RhyteItAccount} UserEmail={UserEmail}/></Tabs.Panel>
                          <Tabs.Panel value="Twitter"><RhyteItAccountTwitter vRhyteItAccount={RhyteItAccount} UserEmail={UserEmail}/></Tabs.Panel>
                        </Tabs.Container>   
                      </Flex>
                    </Flex>   
            </ErrorBoundary>
          </div>   
        )}         
      </Authenticator>  
    </m.div>                      

    )

  }
 

export default RhyteItAccountTabs;