import PropTypes from "prop-types";
import React, { useState, useEffect, useContext, useRef } from "react";

import { listSMMADAccountGroups, listSMMADAccounts } from "../../../graphql/queries";
import { updateSMMADAccountGroups, createSMMADAccountGroups, updateSMMADAccounts } from "../../../graphql/mutations";
import { generateClient } from "aws-amplify/api";

import Header from "../../RhyteItComponents/Header/Header";
import {
  UserContext,
  CognitoGroupContext,
} from "../../../BusinessLogic/Hooks/UserContext";
import {
  GetGroupRoleAccess,
  RhyteItLogging,
  ReturnUserToLandingPage,
} from "../../../BusinessLogic/UserManagement";
import { fetchAuthSession } from "aws-amplify/auth";
import { motion as m } from "framer-motion";
import MenuBackground from "../../Menu/MenuBackground";

import {
  Button,
  Flex,
  Divider,
  Label,
  TextField,
  SelectField,
  Text,
  TableCell,
  Table,
  TableBody,
  TableRow,
  Fieldset,
  CheckboxField,  
  Radio, RadioGroupField
} from "@aws-amplify/ui-react";
import styles from "./SMMADAGManager.module.css";
import SMMADNewAccountGroup from "./SMMADNewAccountGroup";
import SMMADNewAccountSetup from "../SMMADPro/SMMADNewAccountSetup"
import SMMADAGAccounts from "./SMMADAGAccounts";
import  SMMADAGParentMgr  from './SMMADAGParentMgr'


const SMMADAGManager = (props) => {
  SMMADAGManager.propTypes = {
    RedditUser: PropTypes.object,
  };

  const client = generateClient();
  const [vSMMADAGManager, setvSMMADAGManager] = useState([]);
  const vBreadCrumbItems = [
    { Path: "/", Title: "Home", isCurrent: false },
    { Path: "/MainMenuCustomer", Title: "Menu", isCurrent: false },
    { Path: "/SMMADAGManager", Title: "TurboFanBoost", isCurrent: true },
  ];

  var ErrorMessageStr = "";
  const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr);

  const [vAccountGroupType, setvAccountGroupType] = useState(false);

  const [vAccountGroupSelected, setvAccountGroupSelected] = useState();
  const [vParentAccount, setvParentAccount] = useState();
  const [vParentAccountId, setvParentAccountId] = useState();
  const [vAccountGroup, setvAccountGroup] = useState();
  
  const [vAccountGroupAccounts, setvAccountGroupAccounts] = useState()
  const [vAccountGroupAccounData, setvAccountGroupAccounData] = useState()

  const [vNewAccountGroup, setvNewAccountGroup] = useState();

  const { Users, setUsers } = useContext(UserContext);
  const { CognitoGroup, setCognitoGroup } = useContext(CognitoGroupContext);

  const [vCreateNewAccountGroup, setvCreateNewAccountGroup] = useState(false);
  const [vCreateParentAccount, setvCreateParentAccount] = useState(false);

  const [vSwitchParentAccountVisible, setvSwitchParentAccountVisible] = useState(false)
  const [vSwitchParentText, setvSwitchParentText] = useState("Switch Parent Account")
  const [vRefreshSMMADAGAccounts, setvRefreshSMMADAGAccounts] = useState()
  

  
  const [vAccountSortedData, setvAccountSortedData] = useState()
      
  const [RecordsReturned, setRecordsReturned] = useState(0)    
  const [nextToken, setnextToken] = useState(undefined) 
  const [IsLoading, setIsLoading] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async function () {
      try {
        if (CognitoGroup !== undefined) {
          let GetGroupRoleAccessResponse = await GetGroupRoleAccess(
            CognitoGroup,
            "RhyteItSMMADUserGroup"
          );

          if (GetGroupRoleAccessResponse) {
            GetSMMADAccountGroups();
            GetSMMADAccounts()
          }
        } else {
          const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};

          if (idToken !== undefined) {
            setUsers(idToken.payload.email);
            setCognitoGroup(accessToken["payload"]["cognito:groups"]);
          } else {
            ReturnUserToLandingPage(Users, CognitoGroup);
          }
        }
      } catch (err) {
        RhyteItLogging(
          Users,
          "SMMADAGManager",
          "useEffect[CognitoGroup]",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    })();
  }, [CognitoGroup]);

  useEffect(() => {    
     //console.log("SMMADAGManager.useEffect[vAccountGroup].vAccountGroup: ", vAccountGroup);  

    if(vAccountGroup !== undefined) {
      setIsLoading(true)
    }

  },[vAccountGroup]) 

  useEffect(() => {    
    //console.log("SMMADAGManager.useEffect[vAccountGroup].vAccountGroup: ", vAccountGroup);  

   if(vAccountGroup !== undefined && IsLoading === false && vAccountGroupAccounData === undefined) {
     setIsLoading(true)
     GetAGAccounts()
   }

 },[vAccountGroup]) 
 
  useEffect(() => {

    
    //console.log("SMMADAGManager.useEffect[vAccountSortedData].vAccountSortedData: ", vAccountSortedData);  

    if(vAccountSortedData !== undefined) {
    //  //console.log("SMMADAGManager.useEffect[vAccountSortedData].len(vAccountSortedData): ", vAccountSortedData.length);  
      
      setRecordsReturned(vAccountSortedData.length)

      for (let index = 0; index < vAccountSortedData.length; index++) {
          let  vSMMADAccount = vAccountSortedData[index];   
          if (vSMMADAccount.MasterAccount === true) {
            
            //console.log("SMMADAGManager.useEffect[vAccountSortedData].vSMMADAccount.MasterAccount: ", vSMMADAccount.MasterAccount);
            setvParentAccount(vSMMADAccount)
          }
        }

        setvRefreshSMMADAGAccounts(Date.now());

  
    }

  },[vAccountSortedData]) 

  useEffect(() => {
    //console.log("SMMADAGManager.useEffect[vParentAccount].vParentAccount: ", vParentAccount);  

    if (vParentAccount !== undefined) {
      
      //console.log("SMMADAGManager.useEffect[vParentAccount].vParentAccount.id: ", vParentAccount.id);  
      setvParentAccountId(vParentAccount.id)
    }

  },[vParentAccount])   

  useEffect(() => {

    
    //console.log("SMMADAGManager.useEffect[RecordsReturned].RecordsReturned: ", RecordsReturned);  


  },[RecordsReturned])   

  useEffect(() => {

    const fetch1 = async () => {


      try {

            if(nextToken !== null) {
              setIsLoading(true)

                var datetime = new Date();                                                
                let limit = 10000000 
                
                let  variables = {
                  nextToken,
                  filter: {
                    AccountGroup: {
                          eq: vAccountGroup.AccountGroup
                        },        
              }
                };   
                
                const AccountGroupAccounData2  = await client.graphql({
                  query: listSMMADAccounts,
                  variables: variables
                });
  
      
      
              //console.log("Content.GetAGAccounts.AccountGroupAccounData2.data.listSMMADAccounts.nextToken:  ",AccountGroupAccounData2.data.listSMMADAccounts.nextToken)

              let AccountGroupAccounData = vAccountGroupAccounData                   
                    
    
              AccountGroupAccounData2.data.listSMMADAccounts.items.forEach(item => 
                {   
              
                  if ([...AccountGroupAccounData].filter(Content => item.id === Content.id)["length"] === 0) {  
                    AccountGroupAccounData.push(item) 
                  }

                })


                  //console.log("SMMADAGManager.useEffect[NextToken].elseif.SMMADAGAccountsRecordsData2.data.listEmailAccountStats.nextToken: ", SMMADAGAccountsRecordsData2.data.listEmailAccountStats.nextToken);   
                  //console.log("SMMADAGManager.useEffect[NextToken].AccountGroupAccounData.length: ", AccountGroupAccounData.length);  
                  //console.log("SMMADAGManager.useEffect[NextToken].elseif.SMMADAGAccountsRecordsData2.items: ", SMMADAGAccountsRecordsData2.data.listEmailAccountStats.items);  

                  setvAccountGroupAccounts(AccountGroupAccounData)  
                  setnextToken(AccountGroupAccounData2.data.listSMMADAccounts.nextToken)  

                                      

                
            } else if (nextToken === null){          
              
              
              
              setIsLoading(false)

            }

      } catch (err) {
        //console.log(err)
      } finally {
        setIsLoading(false)
      }
    }
  
    fetch1()

  }, [nextToken])         
  
  useEffect(() => {      

    //console.log("SMMADAGManager.useEffect[IsLoading].IsLoading: ", IsLoading, " - nextToken: ", nextToken, " - vAccountGroupAccounData: ", vAccountGroupAccounData);  

    if(vAccountGroupAccounData !== undefined && nextToken === null && IsLoading === false) {

      let vSorted = SortAccountGroupAccountsByAccount(vAccountGroupAccounData)
      setvAccountSortedData(vSorted)  
    }

    if(vAccountGroupAccounData !== undefined && nextToken === undefined && IsLoading === false) {

      let vSorted = SortAccountGroupAccountsByAccount(vAccountGroupAccounData)
      setvAccountSortedData(vSorted)  
    }

  },[IsLoading])      

  async function GetSMMADAccounts() {
    try {
      setErrorMessage("");
      let variables;
      if (Users !== "edwardhawke@rhyteit.com") {
        variables = {
          filter: {
            Account: {
              eq: Users,
            },
          },
        };
      } else {
        variables = {};
      }

      const listSMMADAccountsData2 = await client.graphql({
        query: listSMMADAccounts,
        variables: variables,
      });

      //console.log("SMMADParentManager.GetSMMADAccounts.listSMMADAccountsData2.data.listSMMADAccounts.items.length : ", listSMMADAccountsData2.data.listSMMADAccounts.items.length );

      if (listSMMADAccountsData2.data.listSMMADAccounts.items.length > 0) {
        const SMMADParentManager1 =
          listSMMADAccountsData2.data.listSMMADAccounts.items;

    


        setvParentAccount(SMMADParentManager1[0]);
        setvCreateParentAccount(false)

      } else {
        setvCreateParentAccount(true)
      }



    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADParentManager",
        "GetSMMADAccounts",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  useEffect(() => {
 
    
    if (vSwitchParentAccountVisible === true) {

      setvSwitchParentText("Manage Accounts")

    } else {

      setvSwitchParentText("Switch Parent Account")

    }

  },[vSwitchParentAccountVisible])       


  async function GetAGAccounts() {
    try { 
              
              setErrorMessage("")
              setvAccountGroupAccounData([])
              
          // //console.log("SMMADAGManager.GetAGAccounts.vAccountGroup:  ",vAccountGroup.AccountGroup)
           //console.log("Content.GetAGAccounts.vCampaignSelected:  ",vCampaignSelected)
             

             let  variables = {
              filter: {
                AccountGroup: {
                      eq: vAccountGroup.AccountGroup
                    },    
                }
              };

              const AccountGroupAccounData2  = await client.graphql({
                query: listSMMADAccounts,
                variables: variables
              });

    
              //console.log("SMMADAGManager.GetAGAccounts.AccountGroupAccounData2.data.listSMMADAccounts.items.length:  ",AccountGroupAccounData2.data.listSMMADAccounts.items.length)

              if (AccountGroupAccounData2.data.listSMMADAccounts.items.length > 0) {
                  const Content1 = AccountGroupAccounData2.data.listSMMADAccounts.items 

                  setvAccountGroupAccounData(Content1)  
                  
                  //console.log("SMMADAGManager.GetAGAccounts.AccountGroupAccounData2.data.listSMMADAccounts.nextToken: ", AccountGroupAccounData2.data.listSMMADAccounts.nextToken);  

                  if (AccountGroupAccounData2.data.listSMMADAccounts.nextToken === null){                  
              
                    setIsLoading(false)
      
                  } else {

                    setnextToken(AccountGroupAccounData2.data.listSMMADAccounts.nextToken)  

                  }

     
            }

    } catch (err) { 
                    console.error('Content.GetAGAccounts.ERROR: ', err, err.stack); 
                }
  }

  async function GetSMMADAccountGroups() {
    try {
      setErrorMessage("");
      setvSMMADAGManager([]);
      let variables;
      if (Users !== "edwardhawke@rhyteit.com") {
        variables = {
          filter: {
            ParentAccount: {
              eq: Users,
            },
          },
        };
      } else {
        variables = {};
      }

      const listSMMADAccountGroupsData2 = await client.graphql({
        query: listSMMADAccountGroups,
        variables: variables,
      });

      //console.log("SMMADAGManager.GetSMMADAccountGroups.listSMMADAccountGroupsData2.data.listSMMADAccountGroups.items.length : ", listSMMADAccountGroupsData2.data.listSMMADAccountGroups.items.length );

      if (listSMMADAccountGroupsData2.data.listSMMADAccountGroups.items.length > 0) {
        const SMMADAGManager1 =
          listSMMADAccountGroupsData2.data.listSMMADAccountGroups.items;

        setvAccountGroup(SMMADAGManager1[0]);
        setvCreateNewAccountGroup(false)
      } else {
        setvCreateNewAccountGroup(true)
      }



    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADAGManager",
        "GetSMMADAccountGroups",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  async function SaveAccountGroup(pSMMADAccountStatus) {
    try {
      var vUpdateDateTimeNew = new Date().toISOString();
      var vCreationDateTime = new Date().toISOString();

      let AccountItem = {
        id: Users + "_" + vNewAccountGroup,
        AccountGroup: vNewAccountGroup,
        AccountType: "Customer",
        SyncAccounts: false,
        ParentAccount: Users,
        TwitterPostedCount: 0,
        TwitterWeight: 0,
        TwitterZScore: 0,
        TwitterLastPostDate: vCreationDateTime,
        SMMADAccountGroupStatus: "new",
        Active: true,
        CreationName: Users,
        CreationDateTime: vCreationDateTime,
        UpdateName: Users,
        UpdateDateTime: vUpdateDateTimeNew,
      };

      //console.log("SMMADAGManager.SaveRow.AccountItem:  ",AccountItem);

      const createSMMADAccountGroupsResponse = await client.graphql({
        query: createSMMADAccountGroups,
        variables: {
          input: AccountItem,
        },
      });

      
      //console.log("SMMADAGManager.SaveRow.createSMMADAccountGroupsResponse:  ",createSMMADAccountGroupsResponse.data.createSMMADAccountGroups);
      setvAccountGroup(createSMMADAccountGroupsResponse.data.createSMMADAccountGroups);
      setvCreateNewAccountGroup(false)

    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADAGManager",
        "SaveAccountGroup",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  async function UpdateAccountGroup(pSMMADAccountStatus, pActive, pSyncAccounts) {
    try {
      var vUpdateDateTimeNew = new Date().toISOString();
      var vCreationDateTime = new Date().toISOString();

      let AccountItem = {
        id: vAccountGroup["id"],
        AccountGroup: vAccountGroup["AccountGroup"],
        AccountType: vAccountGroup["AccountType"],
        SyncAccounts: pSyncAccounts,
        ParentAccount: vAccountGroup["ParentAccount"],
        TwitterPostedCount: vAccountGroup["TwitterPostedCount"],
        TwitterWeight: vAccountGroup["TwitterWeight"],
        TwitterZScore: vAccountGroup["TwitterZScore"],
        TwitterLastPostDate: vAccountGroup["TwitterLastPostDate"],
        SMMADAccountGroupStatus: pSMMADAccountStatus,
        Active: pActive,
        CreationName: vAccountGroup["CreationName"],
        CreationDateTime: vAccountGroup["CreationDateTime"],
        UpdateName: Users,
        UpdateDateTime: vUpdateDateTimeNew,
      };

      //console.log("SMMADAGManager.SaveRow.AccountItem:  ",AccountItem);

      const updateSMMADAccountGroupsResponse = await client.graphql({
        query: updateSMMADAccountGroups,
        variables: {
          input: AccountItem,
        },
      });

      
     // console.log("SMMADAGManager.SaveRow.updateSMMADAccountGroupsResponse:  ",updateSMMADAccountGroupsResponse.data.updateSMMADAccountGroups);
      setvAccountGroup(updateSMMADAccountGroupsResponse.data.updateSMMADAccountGroups);
      setvCreateNewAccountGroup(false)

    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADAGManager",
        "UpdateAccountGroup",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function AccountGroup_handleChange(event, data) {
    try {


      let vAccountGroupTmp = event.target.value;
     

      setvNewAccountGroup(vAccountGroupTmp);
    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADAGManager",
        "AccountGroup_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  async function OnClickSwitchParentAccount(event) {
    try {

      if (vSwitchParentAccountVisible === true) {

        setvSwitchParentAccountVisible(false)

      } else {

        setvSwitchParentAccountVisible(true)

      }

      


    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADAGManager",
        "OnClickSwitchParentAccount",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }   

  async function setSyncAccounts(event) {
    try {

      UpdateAccountGroup("Updated SyncAccounts", vAccountGroup.Active, event)

    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADAGManager",
        "setSyncAccounts",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }   

  async function setAGActive(event) {
    try {

      UpdateAccountGroup("Updated SyncAccounts", event, vAccountGroup.SyncAccounts)

    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADAGManager",
        "setAGActive",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }   

  
  
  async function ChangeParentAccount(event) {

    try {

      
      //console.log("SMMADAGManager.ChangeParentAccount.event:  ",event);
        
      //console.log("SMMADAGManager.ChangeParentAccount.vNewParentAccount:  ",event, " - vParentAccountId:  ",vParentAccountId);
      //console.log("SMMADAGManager.ChangeParentAccount.vAccountSortedData:  ",vAccountSortedData);



      if (event !== vParentAccountId) {
        

          let vNewParentAccount = vAccountSortedData.find(({ id }) => id === event);
          let vOldParentAccount = vAccountSortedData.find(({ id }) => id === vParentAccountId);

          
          //console.log("SMMADAGManager.ChangeParentAccount.vNewParentAccount:  ",vNewParentAccount)
          //console.log("SMMADAGManager.ChangeParentAccount.vOldParentAccount:  ",vOldParentAccount)

          vNewParentAccount.MasterAccount = true
          vOldParentAccount.MasterAccount = false
          vNewParentAccount.SMMADAccountStatus = "New Parent Account"
          vOldParentAccount.SMMADAccountStatus = "No longer Parent Account"

          SaveAccount(vNewParentAccount)
          SaveAccount(vOldParentAccount)

          setvParentAccountId(event)
          setvParentAccount(vNewParentAccount)


      }

    } catch (err) {


      console.error("SMMADAGManager.SaveAccount.err:  ", err);

      RhyteItLogging(
        Users,
        "SMMADAGManager",
        "ChangeParentAccount",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }  
  
  function GetAccount(pAccount) {
    return pAccount.name === "cherries";
  }

  async function SaveAccount(pAccount) {
    try {


      var vUpdateDateTimeNew = new Date().toISOString();

      let AccountItem = {
        id: pAccount["id"],
        Account: pAccount["Account"],
        AccountType: "Customer",
        MasterAccount: pAccount["MasterAccount"],
        AccountGroup: pAccount["AccountGroup"],
        CampaignsActive: pAccount["CampaignsActive"],
        SMMADAccountStatus: pAccount["SMMADAccountStatus"],
        Active: pAccount["Active"],
        Weight: pAccount["Weight"],
        PostedCount: pAccount["PostedCount"],
        TwitterLastPostDate: pAccount["TwitterLastPostDate"],
        TwitterPostingFrequencyHours:
          pAccount["TwitterPostingFrequencyHours"],
        ZScore: pAccount["ZScore"],
        PurchaseId: pAccount["PurchaseId"],
        ProductId: pAccount["ProductId"],
        TwitterAccount: pAccount["TwitterAccount"],
        TwitterPassword: pAccount["TwitterPassword"],
        TwitterConsumerKey: pAccount["TwitterConsumerKey"],
        TwitterConsumerSecret: pAccount["TwitterConsumerSecret"],
        TwitterBearerToken: pAccount["TwitterBearerToken"],
        TwitterAccessToken: pAccount["TwitterAccessToken"],
        TwitterAccessTokenSecret:
          pAccount["TwitterAccessTokenSecret"],
        TwitterClientId: pAccount["TwitterClientId"],
        TwitterClientSecret: pAccount["TwitterClientSecret"],
        TwitterAppName: pAccount["TwitterAppName"],
        TwitterAppId: pAccount["TwitterAppId"],
        TwitterStatus: pAccount["TwitterStatus"],
        CreationName: pAccount["CreationName"],
        CreationDateTime: pAccount["CreationDateTime"],
        UpdateName: Users,
        UpdateDateTime: vUpdateDateTimeNew,
      };

     // //console.log("SMMADNewAccountSetup.SaveRow.AccountItem:  ", AccountItem);

      
        const UpdateSMMADAccountsResponse = await client.graphql({
          query: updateSMMADAccounts,
          variables: {
            input: AccountItem,
          },
        });
         //console.log("SMMADAGManager.SaveAccount.UpdateSMMADAccountsResponse:  ", UpdateSMMADAccountsResponse);

    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADAGManager",
        "SaveAccount",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  async function CreateNewAccount() {
    try {

      var vUpdateDateTimeNew = new Date().toISOString();

      let AccountItem = {
        id: "new",
        Account: "",
        AccountType: "Customer",
        MasterAccount: false,
        AccountGroup: vAccountGroup.AccountGroup,
        CampaignsActive: true,
        SMMADAccountStatus: "new",
        Active: true,
        PostedCount: 0,
        TwitterLastPostDate: vUpdateDateTimeNew ,
        TwitterPostingFrequencyHours: 3,
        Weight:0,
        ZScore:0,
        PurchaseId: "",
        ProductId: "",
        TwitterAccount: "",
        TwitterPassword: "",
        TwitterConsumerKey: "",
        TwitterConsumerSecret: "",
        TwitterBearerToken: "",
        TwitterAccessToken: "",
        TwitterAccessTokenSecret: "",
        TwitterClientId: "",
        TwitterClientSecret: "",
        TwitterAppName: "",
        TwitterAppId: "",
        TwitterStatus: "active",
        CreationName: Users,
        CreationDateTime: vUpdateDateTimeNew,
        UpdateName: Users,
        UpdateDateTime: vUpdateDateTimeNew,
      };


     //console.log("SMMADAGManager.CreateNewAccount.AccountItem:  ",AccountItem);
     //console.log("SMMADAGManager.CreateNewAccount.vAccountSortedData:  ",vAccountSortedData);

    let vAGAccountsTmp = vAccountSortedData
    
    //console.log("SMMADAGManager.CreateNewAccount.vAGAccountsTmp:  ",vAGAccountsTmp);

    setvAccountSortedData([
      ...vAccountSortedData,
      AccountItem
    ]);


    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADAGManager",
        "CreateNewAccount",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }   
    
  
  function SortAccountGroupAccountsByAccount(pAccountGroupAccounData) {
    pAccountGroupAccounData.sort(AccountSortFunction);
    return pAccountGroupAccounData;
    //    //console.log(JSON.stringify(pEmails));
  }

  function AccountSortFunction(a, b) {
    
    //console.log("SMMADAGManager.AccountSortFunction.a: ",a.Account, " < b: ",b.Account);  
    let vAccountA = a.Account;
    let vAccountB = b.Account;
    let vReturnOrder = vAccountA < vAccountB ? 1 : -1;
    //console.log("SMMADAGManager.AccountSortFunction.vAccountA: ",vAccountA, " < vAccountB: ",vAccountB, " = vReturnOrder: ",vReturnOrder);  
    return vReturnOrder;
  }


  return (
    <m.div
      key="Search"
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      exit={{ opacity: 1 }}
    >
      <MenuBackground
        className={styles.MenuBackground}
        GradientStopColor1="#0000"
        GradientStopColor2="#000"
        GradientStopColor3="#00CCCC"
        GradientStopColor4="#8880"
      />

      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="center"
        wrap="nowrap"
        gap="1rem"
        className={styles.FlexColumn}
      >
        <Header
          key={Date.now()}
          Title="TurbFanBoost"
          SubTitle="Account Group Manager"
          BreadCrumbItems={vBreadCrumbItems}
          pageSource="TurbFanBoost"
        />
          <Flex
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            alignContent="center"
            wrap="nowrap"
            gap="3rem"
          >
        {vAccountGroup !== undefined && vCreateParentAccount === true && (
              <div className={styles.Progress}>Create Account Group</div> 
            )}               
        {vAccountGroup !== undefined && vCreateParentAccount === true && (
              <div className={styles.Progress}>Create Parent Account</div> 
            )}               
          </Flex>    
          <Fieldset className={styles.Fieldset} size="small">
            <legend className={styles.legend}>Account Group</legend>
            {vAccountGroup !== undefined && (
            <Flex
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                alignContent="center"
                wrap="nowrap"
                gap="1rem"
                className={styles.FlexColumn}
              >
              <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
                gap="3rem"
              >              
                <Text  className={styles.Title}>{vAccountGroup.AccountGroup}</Text>  
              </Flex> 
              <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              gap="3rem"
            >              
              <Text  className={styles.ActiveGroupOptionText}>Sync child accounts with parent account</Text>  
              <CheckboxField
                className={styles.CheckboxField}
                name="Active"
                value={vAccountGroup.SyncAccounts}
                checked={vAccountGroup.SyncAccounts}
                size="small"
                onChange={(e) => setSyncAccounts(e.target.checked)}
              />   
              <Text  className={styles.ActiveGroupOptionText}>Active</Text>       
              <CheckboxField
                className={styles.CheckboxField}
                name="Active"
                value={vAccountGroup.Active}
                checked={vAccountGroup.Active}
                size="small"
                onChange={(e) => setAGActive(e.target.checked)}
              />                                
            </Flex>   
            </Flex>            
       
          )}     

                                   
          </Fieldset>  
          <Fieldset className={styles.Fieldset} size="small">
            <legend className={styles.legend}>Parent Account</legend>
            {vParentAccount !== undefined && (
            <Text  className={styles.Title}>{vParentAccount.Account}</Text>  
          )}                  
          </Fieldset>      
          
        {vAccountSortedData !== undefined && vAccountGroup !== undefined && vCreateParentAccount === false &&  vCreateNewAccountGroup === false && (      
          <Flex
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            alignContent="center"
                            wrap="nowrap"
                            gap="3rem"
                            className={styles.FlexRow}
                          >
                           {vSwitchParentAccountVisible  === false && vAccountSortedData !== undefined && vAccountGroup !== undefined && vCreateParentAccount === false &&  vCreateNewAccountGroup === false && (      
                          <Button
                              className={styles.Button}
                              variation="primary"
                              onClick={(e) => CreateNewAccount(e)}
                            >
                              Create Child Account
                            </Button>
           
                            )}                      
                            <Button
                              className={styles.Button}
                              variation="primary"
                              onClick={(e) => OnClickSwitchParentAccount(e)}
                            >
                              {vSwitchParentText}
                            </Button>                            
                        </Flex>   
           
          )}                            
          {vCreateNewAccountGroup === true && vCreateParentAccount === true && (
          <m.div
            key="Search"
            initial={{ x: "100%" }}
            animate={{ x: "0%" }}
            transition={{ duration: 1, ease: "easeOut" }}
            exit={{ opacity: 1 }}
          >
        
            
            <Flex
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              alignContent="center"
              wrap="nowrap"
              gap="1rem"
              className={styles.FlexColumn}
            >
                <legend className={styles.Title}>Time to create an account group!</legend>
                <Text className={styles.legend}>An account group will allow you to group multiple social media accounts under a parent social media account.</Text>          
                <Text className={styles.legend}>You then upload all your content to the parent RhyteIt account and it will publish the content to the children accounts.</Text>
                <Table className={styles.Table}>
                  <TableBody>   
                    <TableRow>
                      <TableCell className={styles.RowLabelColumn}>
                        <Label className={styles.Label}>Account Group</Label>
                      </TableCell>
                      <TableCell>
                        <TextField
                          className={styles.TextField}
                          name="vAccountGroup.AccountGroup"
                          defaultValue={vAccountGroup}
                          onChange={(e) =>
                            AccountGroup_handleChange(e, vAccountGroup)
                          }
                        />
                      </TableCell>
                    </TableRow>  
                  </TableBody>
                </Table>
                <Flex
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  alignContent="center"
                  wrap="nowrap"
                  gap="3rem"
                >
                  <Button
                    className={styles.Button}
                    variation="primary"
                    onClick={(e) => SaveAccountGroup("Saved")}
                  >
                    Save
                  </Button>                  
                </Flex>
            </Flex>
          </m.div> 
            )}                
          {vSwitchParentAccountVisible === false && vAccountGroup !== undefined && vCreateNewAccountGroup === false && vCreateParentAccount === true && (
              <SMMADNewAccountSetup
                className={styles.Button}
                AccountGroup={vAccountGroup}
                ParentAccount={true}
              />
            )}  
          {vParentAccountId  !== undefined && vSwitchParentAccountVisible === true && vAccountSortedData !== undefined && (                            
                  <Fieldset className={styles.Fieldset} size="small">
                    <legend className={styles.legend}>Change Parent Account</legend>
                    <RadioGroupField defaultValue={vParentAccountId}
                            value={vParentAccountId}
                            onChange={(e) => ChangeParentAccount(e.target.value)}
                    > 
                       {
                      vAccountSortedData && vAccountSortedData.map((vAGAccount) => (       
                              <Radio  className={styles.Radio} value={vAGAccount.id}>{vAGAccount.id}</Radio>
                            ))
                          }   
                      </RadioGroupField>             
                  </Fieldset>  
                )}   
  
          {vSwitchParentAccountVisible === false && RecordsReturned > 0 && vAccountGroup !== undefined && vAccountSortedData !== undefined && vCreateNewAccountGroup === false && vCreateParentAccount === false && (
              <SMMADAGAccounts
                key={vRefreshSMMADAGAccounts}
                className={styles.Button}
                AccountGroup={vAccountGroup}
                AGAccounts={vAccountSortedData}
                Users={Users}
                RecordsReturned={RecordsReturned}
              />
            )}                         
      </Flex>
    </m.div>
  );
};

export default SMMADAGManager;
