import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import {motion as m} from "framer-motion";
import { UserContext} from '../../BusinessLogic/Hooks/UserContext';
import { useNavigate, useLocation } from "react-router-dom";

import {ValidateUser} from '../../BusinessLogic/UserManagement';

import  {  getSMMADAccounts}  from '../../graphql/queries'
import  { updateEmailAccounts, deleteEmailAccounts, updateSMMADAccounts}  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';


import {
  Button ,
  Authenticator,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  Card,
  Image,
  View,
  Flex,
  Text

} from '@aws-amplify/ui-react';
import styles from './RhyteItAccountTwitter.module.css';
import styled from 'styled-components'


function ErrorFallback({error, resetErrorBoundary}) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

const LabelText = styled.div`
                        color: white;
                        font-size: 1.5em !important;
                        `;


 
  const RhyteItAccountTwitter = (props) => {      


    RhyteItAccountTwitter.propTypes = {
      vRhyteItAccount: PropTypes.object,
      UserEmail: PropTypes.string
    }; 

        const client = generateClient();


        let debugConsoleOutPut = true
        let navigate = useNavigate(); 

        const location = useLocation();
        const { vRhyteItAccount } = location.state;


        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   
        const [RecordUpdated, setRecordUpdated] = useState(false)  

        let {Users} = useContext(UserContext);   
        const [RhyteItAccount, setRhyteItAccount] = useState(vRhyteItAccount)  
        const [vSMMADAccount, setvSMMADAccount] = useState()  
        const [UserEmail, setUserEmail] = useState(props.UserEmail)   

        useEffect(() => {

          (async function() {
            try {  
              

              if (Users === undefined) {                    
                //console.log("ProductManagement.useEffect[].Users:  ",Users)
                 let vIsUserSubscribed = await ValidateUser()
                 setUserEmail(vIsUserSubscribed)
                //console.log("ProductManagement.useEffect[].Users:  ",Users)

              } else if (Users.length > 0 && Users !== undefined) {

                //console.log("Prod

              }                     


                } catch (err) {
                  console.error('RhyteItAccounts.useEffect[].ERROR: ', err, err.stack); 
              }
            }
          )();   
      

        },[]) 
   
        function TwitterAccount_handleChange(event, data) {
          try {              
      
              RhyteItAccount.TwitterAccount = event.target.value
             // //console.log("RhyteItAccountTwitter.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
    
            } catch (err) { 
              console.error("RhyteItAccountTwitter.TwitterAccount_handleChange.Error:  ",err, err.stack); 
          }        
        }      
        
        function TwitterPassword_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("RhyteItAccountTwitter.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("RhyteItAccountTwitter.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItAccount.TwitterPassword = event.target.value
             // //console.log("RhyteItAccountTwitter.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountTwitter.TwitterPassword_handleChange.Error:  ",err, err.stack); 
          }        
        }       
        
        function TwitterConsumerKey_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("RhyteItAccountTwitter.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("RhyteItAccountTwitter.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItAccount.TwitterConsumerKey = event.target.value
             // //console.log("RhyteItAccountTwitter.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountTwitter.TwitterConsumerKey_handleChange.Error:  ",err, err.stack); 
          }        
        }         
           
        function TwitterConsumerSecret_handleChange(event, data) {
          try {              
   
    
              RhyteItAccount.TwitterConsumerSecret = event.target.value
             // //console.log("RhyteItAccountTwitter.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountTwitter.TwitterConsumerSecret_handleChange.Error:  ",err, err.stack); 
          }        
        }       
        
        function TwitterAccessToken_handleChange(event, data) {
          try {              
              
   
              RhyteItAccount.TwitterAccessToken = event.target.value
             // //console.log("RhyteItAccountTwitter.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
            } catch (err) { 
              console.error("RhyteItAccountTwitter.TwitterAccessToken_handleChange.Error:  ",err, err.stack); 
          }        
        }             

        function TwitterAccessTokenSecret_handleChange(event, data) {
          try {              
    
              RhyteItAccount.TwitterAccessTokenSecret = event.target.value
             // //console.log("RhyteItAccountTwitter.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountTwitter.TwitterAccessTokenSecret_handleChange.Error:  ", err, err.stack); 
          }        
        }   
        
        async function GetSMMADAccount(pAccount) {
          try {
                    setErrorMessage("")
                    
                    console.log("RhyteItAccountTwitter.GetSMMADAccount.pAccount: ",pAccount)

 
      
                  const RhyteItAccountRecordsData  = await client.graphql({
                      query: getSMMADAccounts,
                      variables: { id: pAccount }
                    });                   



                    if (debugConsoleOutPut === true ) {
                     console.log("RhyteItAccounts.GetSMMADAccount.RhyteItAccountRecords: ",RhyteItAccountRecordsData.data.getSMMADAccounts)
                    }

                    let vSMMADAccountTmp = RhyteItAccountRecordsData.data.getSMMADAccounts
                                       
                    if (vSMMADAccountTmp !== null) {

                      
                          var vUpdateDateTime = new Date().toISOString()

                          let vRhyteItAccountItem = {
                            "id": vSMMADAccountTmp.id,
                            "Account": vSMMADAccountTmp.Account,
                            "AccountType": vSMMADAccountTmp.AccountType,
                            "SMMADAccountStatus": vSMMADAccountTmp.SMMADAccountStatus,
                            "CampaignsActive": vSMMADAccountTmp.CampaignsActive,
                            "PostedCount": vSMMADAccountTmp.PostedCount,
                            "Weight": vSMMADAccountTmp.Weight,
                            "PurchaseId": vSMMADAccountTmp.PurchaseId,
                            "ProductId": vSMMADAccountTmp.ProductId,
                            "TwitterAccount": RhyteItAccount.TwitterAccount,
                            "TwitterPassword": RhyteItAccount.TwitterPassword,
                            "TwitterConsumerKey": RhyteItAccount.TwitterConsumerKey,
                            "TwitterConsumerSecret": RhyteItAccount.TwitterConsumerSecret,
                            "TwitterAccessToken": RhyteItAccount.TwitterAccessToken,
                            "TwitterAccessTokenSecret": RhyteItAccount.TwitterAccessTokenSecret,
                            "Active": vSMMADAccountTmp.Active,
                            "TwitterStatus": RhyteItAccount.TwitterStatus,
                            "CreationName": vSMMADAccountTmp.CreationName,
                            "UpdateName": Users,
                            "CreationDateTime": vSMMADAccountTmp.CreationDateTime,
                            "UpdateDateTime": vUpdateDateTime
                        }

                        const updateSMMADAccountResponse = await client.graphql({
                          query: updateSMMADAccounts,
                          variables: {
                            input: vRhyteItAccountItem
                          }
                        });                
          


                      }

  


          } catch (err) { 
                          console.error('RhyteItAccounts.GetRhyteItAccountRecords.ERROR: ', err, err.stack); 
                      }
        } 
        

        async  function SaveRhyteItAccount(event, data) {
          try {           
            
              setErrorMessage("")

              var vUpdateDateTime = new Date().toISOString()

    
              RhyteItAccount.UpdateDateTime = vUpdateDateTime       
              RhyteItAccount.UpdateName = Users       
              RhyteItAccount.AccountStatus   = "Record saved by:  " + UserEmail
              
              if (RhyteItAccount["MediumAccount"] === false || RhyteItAccount["MediumAccount"] === null) {
                RhyteItAccount["MediumAccount"] = ""
              }               
              if (RhyteItAccount["TwitterAccount"] === false || RhyteItAccount["TwitterAccount"] === null) {
                RhyteItAccount["TwitterAccount"] = ""
              } 
              if (RhyteItAccount["RedditAccount"] === false || RhyteItAccount["RedditAccount"] === null) {
                RhyteItAccount["RedditAccount"] = ""
              } 
              if (RhyteItAccount["OnlyFansAccount"] === false || RhyteItAccount["OnlyFansAccount"] === null) {
                RhyteItAccount["OnlyFansAccount"] = ""
              } 
              if (RhyteItAccount["InstagramAccount"] === false || RhyteItAccount["InstagramAccount"] === null) {
                RhyteItAccount["InstagramAccount"] = ""
              } 
              if (RhyteItAccount["ChromiumProfile"] === false || RhyteItAccount["ChromiumProfile"] === null) {
                RhyteItAccount["ChromiumProfile"] = ""
              } 
                  
    
              let vRhyteItAccountItem = {
                "id": RhyteItAccount.id,
                "UserId": RhyteItAccount.UserId,
                "Password": RhyteItAccount.Password,
                "FirstName": RhyteItAccount.FirstName,
                "LastName": RhyteItAccount.LastName,
                "Name": RhyteItAccount.Name,
                "NameNonSpaced": RhyteItAccount.NameNonSpaced,  
                "BirthMonth": RhyteItAccount.BirthMonth,
                "BirthDay": RhyteItAccount.BirthDay,
                "BirthYear": RhyteItAccount.BirthYear,
                "StartDate": RhyteItAccount.StartDate,
                "EndDate": RhyteItAccount.EndDate,
                "EmailAccountProvider": RhyteItAccount.EmailAccountProvider,
                "EmailAccount": RhyteItAccount.EmailAccount,
                "ChromiumProfile": RhyteItAccount.ChromiumProfile,
                "TwitterAccount": RhyteItAccount.TwitterAccount,
                "TwitterPassword": RhyteItAccount.TwitterPassword,
                "TwitterConsumerKey": RhyteItAccount.TwitterConsumerKey,
                "TwitterConsumerSecret": RhyteItAccount.TwitterConsumerSecret,
                "TwitterAccessToken": RhyteItAccount.TwitterAccessToken,
                "TwitterAccessTokenSecret": RhyteItAccount.TwitterAccessTokenSecret,
                "RedditAccount": RhyteItAccount.RedditAccount,
                "OnlyFansAccount": RhyteItAccount.OnlyFansAccount,
                "MediumAccount": RhyteItAccount.MediumAccount,
                "InstagramAccount": RhyteItAccount.InstagramAccount,
                "VerificationProvider": RhyteItAccount.VerificationProvider,
                "VerificationCode": RhyteItAccount.VerificationCode,
                "OpenAIOrgKey": RhyteItAccount.OpenAIOrgKey,
                "OpenAIapiKey": RhyteItAccount.OpenAIapiKey,
                "AccountStatus": RhyteItAccount.AccountStatus,
                "Prompt":RhyteItAccount.Prompt,
                "Model":RhyteItAccount.Model,
                "ProfileImageURL": RhyteItAccount.ProfileImageURL,
                "MageSeed": RhyteItAccount.MageSeed,
                "Industry": RhyteItAccount.Industry,
                "ProcessGroup": RhyteItAccount.ProcessGroup,
                "RedoImage": RhyteItAccount.RedoImage,
                "CreationName": RhyteItAccount.CreationName,
                "UpdateName": UserEmail,
                "CreationDateTime": RhyteItAccount.CreationDateTime,
                "UpdateDateTime": RhyteItAccount.UpdateDateTime
            }

    
              if (debugConsoleOutPut === true) {
              
                console.log("RhyteItAccountSD.SaveRhyteItAccount.vRhyteItAccountItem: ",vRhyteItAccountItem)
    
              }


              const updateEmailAccountsResponse = await client.graphql({
                query: updateEmailAccounts,
                variables: {
                  input: vRhyteItAccountItem
                }
              });                

              if (debugConsoleOutPut === true) {
              
                console.log("RhyteItAccountSD.SaveRhyteItAccount.updateEmailAccountsResponse.data.updateEmailAccounts: ",updateEmailAccountsResponse.data.updateEmailAccounts)
    
              }

              setRecordUpdated(true)
              ErrorMessageStr = "Updated"
              setErrorMessage(ErrorMessageStr)
              setRhyteItAccount(updateEmailAccountsResponse.data.updateEmailAccounts)
              GetSMMADAccount(RhyteItAccount.EmailAccount)
    
            } catch (err) { 
              ErrorMessageStr = ("RhyteItAccountSD.SaveRhyteItAccount.Error: ", err, err.stack)
              console.error(ErrorMessageStr)
              setErrorMessage(ErrorMessageStr)
          }        
        }     

        async function DeleteRhyteItAccount(event,data) {
          try {
    

    
              const DeleteRhyteItAccounts = {
                id: RhyteItAccount.id,
                UserId: RhyteItAccount.UserId,
                _version: RhyteItAccount._version
              }
              
              const ReturnMessage = await client.graphql({
                query: deleteEmailAccounts,
                variables: {
                  input: DeleteRhyteItAccounts
                }
              });  
    
              setRhyteItAccount([])
    
              setErrorMessage("Deleted")         
                    
              } catch (err) { 
    
              ErrorMessageStr = "RhyteItAccountTwitter.DeleteRhyteItAccount.Error: " + err + " " + err.stack
    
              console.error(ErrorMessageStr); 
              
              setErrorMessage(ErrorMessageStr)         
            }               
          
        }           
 
 

    return ( 
      <div  className={styles.GridContainer}>              
        <div className={styles.Container}>
          <div className={styles.AccountDetailContainer}>
            <View
              className={styles.View}
            >
              <Card className={styles.Card}>

                <Flex direction="row" alignItems="flex-start">
                  <Flex
                    direction="column"
                    alignItems="flex-start"
                    gap="5vh"
                  >
                    <Flex
                    direction="row"
                    alignItems="flex-start"
                    gap="10px">
                      <Button className={styles.RedditButtons}                                       
                              onClick={ (e) => SaveRhyteItAccount(e, RhyteItAccount) }
                              variation="primary">Save</Button>
                      <Button className={styles.RedditButtons}                                       
                              onClick={ (e) => DeleteRhyteItAccount(e, RhyteItAccount) }
                              variation="primary">Delete</Button>
                    </Flex>  
                    
                    <div className={styles.headerline} />                                                                         
                    <Flex>
                      <Text size="large" className={RecordUpdated === false ? styles.Text :styles.TextUpdated }>
                        {RhyteItAccount.AccountStatus}
                      </Text>
                      <Text size="large" className={styles.Text}>
                        {new Date( RhyteItAccount.CreationDateTime).toLocaleString("en-US")}
                      </Text>
                      <Text size="large" className={RecordUpdated === false ? styles.Text :styles.TextUpdated  }>
                        {new Date( RhyteItAccount.UpdateDateTime).toLocaleString("en-US")}
                      </Text>
                      <Text size="large" className={styles.Text}>
                        {RhyteItAccount.UpdateName}
                      </Text>
                    </Flex>                                     
                    <div className={styles.headerline} />   
                    <Text size="large" className={styles.Text}>
                      Twitter Credentials
                    </Text>
                    <Table>          
                      <TableHead>          
                        <TableRow className={styles.TableHeaderRow}>
                          <TableCell className={styles.TableHeaderRowCells} >Attribute</TableCell> 
                          <TableCell className={styles.TableHeaderRowCells} >UserId</TableCell> 
                          <TableCell className={styles.TableHeaderRowCells} >Password</TableCell> 
                          <TableCell className={styles.TableHeaderRowCells} >Status</TableCell> 
                        </TableRow>                                      
                      </TableHead>  
                      <TableBody>
                        <TableRow>
                          <TableCell className={styles.RowLabelColumn}>         
                            <LabelText   
                              className={styles.TextFieldLabel}
                              >
                                Login
                            </LabelText>
                          </TableCell>
                          <TableCell>
                            <TextField className={styles.TextField} 
                              name="vRhyteItAccount.TwitterAccount"                           
                              defaultValue={vRhyteItAccount.TwitterAccount}
                              onChange={ (e) => TwitterAccount_handleChange(e, vRhyteItAccount) } />
                          </TableCell>
                          <TableCell>
                            <TextField className={styles.TextField} 
                              name="vRhyteItAccount.TwitterPassword"                           
                              defaultValue={vRhyteItAccount.TwitterPassword}
                              onChange={ (e) => TwitterPassword_handleChange(e, vRhyteItAccount) } />
                          </TableCell>   
                          <TableCell>
                            <TextField className={styles.TextField} 
                              name="vRhyteItAccount.TwitterStatus"                           
                              defaultValue={vRhyteItAccount.TwitterStatus}
                              onChange={ (e) => TwitterPassword_handleChange(e, vRhyteItAccount) } />
                          </TableCell>                                          
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Text size="large" className={styles.Text}>
                      API
                    </Text>
                    <Table>          
                      <TableHead>          
                        <TableRow className={styles.TableHeaderRow}>
                          <TableCell className={styles.TableHeaderRowCells} >Attribute</TableCell> 
                          <TableCell className={styles.TableHeaderRowCells} >Value</TableCell> 
                          <TableCell className={styles.TableHeaderRowCells} >Secret</TableCell> 
                        </TableRow>                                      
                      </TableHead>  
                      <TableBody>
                        <TableRow>
                          <TableCell className={styles.RowLabelColumn}>         
                            <LabelText   
                              className={styles.TextFieldLabel}
                              >
                                consumer_key
                            </LabelText>
                          </TableCell>
                          <TableCell>
                            <TextField className={styles.TextField} 
                              name="vRhyteItAccount.TwitterConsumerKey"                           
                              defaultValue={vRhyteItAccount.TwitterConsumerKey}
                              onChange={ (e) => TwitterConsumerKey_handleChange(e, vRhyteItAccount) } />
                          </TableCell>
                          <TableCell>
                            <TextField className={styles.TextField} 
                              name="vRhyteItAccount.TwitterConsumerSecret"                           
                              defaultValue={vRhyteItAccount.TwitterConsumerSecret}
                              onChange={ (e) => TwitterConsumerSecret_handleChange(e, vRhyteItAccount) } />
                          </TableCell>                                        
                        </TableRow>
                        <TableRow>
                          <TableCell className={styles.RowLabelColumn}>         
                            <LabelText   
                              className={styles.TextFieldLabel}
                              >
                                access_token
                            </LabelText>
                          </TableCell>
                          <TableCell>
                            <TextField className={styles.TextField} 
                              name="vRhyteItAccount.TwitterAccessToken"                           
                              defaultValue={vRhyteItAccount.TwitterAccessToken}
                              onChange={ (e) => TwitterAccessToken_handleChange(e, vRhyteItAccount) } />
                          </TableCell>
                          <TableCell>
                            <TextField className={styles.TextField} 
                              name="vRhyteItAccount.TwitterAccessTokenSecret"                           
                              defaultValue={vRhyteItAccount.TwitterAccessTokenSecret}
                              onChange={ (e) => TwitterAccessTokenSecret_handleChange(e, vRhyteItAccount) } />
                          </TableCell>                                        
                        </TableRow>
                      </TableBody>
                    </Table>                    
                    </Flex>
                </Flex>
              </Card>
            </View>           
          </div>
          <div className={styles.MessageBox}>{ErrorMessage}</div>                            
        </div>     
      </div>                     

    )

  }
 

export default RhyteItAccountTwitter;