import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';

import { Amplify } from 'aws-amplify';
import  {listPurchases}  from '../../graphql/queries'
import  {createAudienceGeoInfo, SendCustomerEmailVerification, updatePurchase}  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import {ErrorBoundary} from 'react-error-boundary'
import HeaderMenu from "../HeaderMenu/LandingPageNavBar";
import {get_uuidv4} from '../../BusinessLogic/uuidv4';
import {ValidateUser} from '../../BusinessLogic/UserManagement';
import {motion as m} from "framer-motion";
import CustomerPurchasesTable from "./CustomerPurchasesTable";
import { UserContext} from '../../BusinessLogic/Hooks/UserContext';
import axios from 'axios';
import {uuidv4} from "../../BusinessLogic/UtilitiesManagement";


import {
  Button ,  
  Flex, 
  TextField,
  Table,
  TableBody,
  TableRow,
  TableCell
} from '@aws-amplify/ui-react';
import styles from './CustomerPurchases.module.css';

const CustomerPurchases = (props) => {       
  
  CustomerPurchases.propTypes = {
          RedditUser: PropTypes.object
        };

        const client = generateClient();

        const [RhyteItCustomerPurchases, setRhyteItCustomerPurchases] = useState([])
        
              
        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   
        let debugConsoleOutPut = true

        const {Users, setUsers} = useContext(UserContext);       
        const [CustomerPurchasesReturned, setCustomerPurchasesReturned] = useState(0);
        const [ShowTable, setShowTable] = useState(false);      

        const [updatedKey, setupdatedKey] = useState(Date.now())   

        const [FirstTouch, setFirstTouch] = useState(true);
        const [ip, setIP] = useState('');
        const [isChecked, setIsChecked] = React.useState(false);
        const [Environment, setEnvironment] = React.useState(process.env.REACT_APP_ENV);    
        const [CustomerEmail, setCustomerEmail] = useState('');    
        const [CustomerEmailVerified, setCustomerEmailVerified] = useState("");   
        const [SendVerificationEmailButtonTitle, setSendVerificationEmailButtonTitle]  = useState("Send verification email") 
        const [EmailVerificationDisabled, setEmailVerificationDisabled] = useState(false)  
        

        useEffect(() => {


        },[]) 

        useEffect(() => {
        //console.log("CustomerPurchases.useEffect[RhyteItCustomerPurchases].RhyteItCustomerPurchases:  ",RhyteItCustomerPurchases)
        //console.log("SBSMQueries.useEffect[SBSMNewsQueryTerms].Object.keys(SBSMNewsQueryTerms).length:  ",Object.keys(SBSMNewsQueryTerms).length )

          setErrorMessage("Products retrieved at: " + new Date().toLocaleString())

        },[Users])             

        useEffect(() => {
        //console.log("CustomerPurchases.useEffect[RhyteItCustomerPurchases].RhyteItCustomerPurchases:  ",RhyteItCustomerPurchases)
        //console.log("SBSMQueries.useEffect[SBSMNewsQueryTerms].Object.keys(SBSMNewsQueryTerms).length:  ",Object.keys(SBSMNewsQueryTerms).length )

          if (Object.keys(RhyteItCustomerPurchases).length > 0) {
            setShowTable(true)
          }


        },[RhyteItCustomerPurchases])     

        useEffect(() => {
        //console.log("CustomerPurchases.useEffect[RhyteItCustomerPurchases].RhyteItCustomerPurchases:  ",RhyteItCustomerPurchases)
        //console.log("SBSMQueries.useEffect[SBSMNewsQueryTerms].Object.keys(SBSMNewsQueryTerms).length:  ",Object.keys(SBSMNewsQueryTerms).length )


          setErrorMessage("Products retrieved at: " + new Date().toLocaleString())

        },[isChecked])     

   

        async function GetCustomerPurchases(event) {
        try {
                  setErrorMessage("")
                  setRhyteItCustomerPurchases([])

                  const variables = {
                    filter: {
                      Email: {
                              eq: CustomerEmail 
                          },
                      Environment: {
                            eq: Environment
                        }
                    }
                  };
            
                  const CustomerPurchasesData2  = await client.graphql({
                      query: listPurchases,
                      variables: variables
                    });
  


                  if (CustomerPurchasesData2.data.listPurchases.items.length > 0) {
                      //console.log("CustomerPurchasesData2.data.listCustomerPurchases.items: ",CustomerPurchasesData2.data.listPurchases.items);
                      setCustomerPurchasesReturned(CustomerPurchasesData2.data.listPurchases.items.length)
                      const CustomerPurchases1 = CustomerPurchasesData2.data.listPurchases.items 
                      setCustomerEmailVerified(CustomerPurchasesData2.data.listPurchases.items[0]["EmailVerified"])
                      setRhyteItCustomerPurchases(CustomerPurchases1)       
                      setupdatedKey(Date.now())            
                      setShowTable(true)

                        setSendVerificationEmailButtonTitle("Email verified")
                        setEmailVerificationDisabled(true)
                        setErrorMessage("")
          
                }

                if (debugConsoleOutPut === true ) {
                  //console.log("CustomerPurchases.GetCustomerPurchases.updatedKey: ",updatedKey)
                  //console.log("CustomerPurchases.GetCustomerPurchases.RhyteItCustomerPurchases: ",RhyteItCustomerPurchases)
                  //console.log("CustomerPurchases.GetCustomerPurchases.Environment: ",Environment)
                }


        } catch (err) { 
                        console.error('CustomerPurchases.GetCustomerPurchases.ERROR: ', err, err.stack); 
                    }
        }  

        function Email_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
                //console.log("AdultContentCreatorSearch.FindField_handleChange.event.target.value: ",event.target.value)
              }
    
              setCustomerEmail(event.target.value)
    
    
    
            } catch (err) { 
              console.error("AdultContentCreatorSearch.FindField_handleChange.Error:  ".err, err.stack); 
          }        
        }          

    return ( 
    <m.div
      key="Search"
      initial={{x: "100%"}}
      animate={{x: "0%"}}
      transition={{duration:0.75, ease: "easeOut"}}
      exit={{ opacity: 1 }}
     >
  
          <div className={styles.SystemAdminBackground}>
          <Flex direction="column"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          alignContent="flex-start"
                          wrap="wrap"
                          gap="4rem"
                          className={styles.GridContainer}>          
                      <HeaderMenu  className={styles.RhyteItHeaderMenu}  pageSource="CustomerPurchases"/>
                      <div className={styles.Headings} >
                          <h1 className={styles.h1}>Your purchases</h1>  
                          <div className={styles.headerline} />
                          <h2 className={styles.h2}>Manage and download purchases and subscriptions</h2>  
                      </div> 
                      <div className={styles.ToolBar}>                           
                          <Table className={styles.Table}> 
                            <TableBody>
                              <TableRow className={styles.TableRow}>  
                                <TableCell className={styles.TableCell}>      
                                  <TextField label="Email" 
                                            labelHidden={true} 
                                            className={styles.TextField}
                                            defaultValue={CustomerEmail}
                                            value={CustomerEmail}
                                            placeholder='Enter your email'
                                            onChange={ (e) => Email_handleChange(e, CustomerEmail) }
                                            />        
                                </TableCell>    
                                <TableCell  className={styles.TableCell}>      
                                  <Button
                                      loadingText=""
                                      ariaLabel=""
                                      className={styles.RedditTitleButtons}
                                      onClick={ (e) => GetCustomerPurchases(e)} 
                                    >
                                    Find
                                    </Button>          
                                </TableCell>      
                              </TableRow>                                  
                          </TableBody>
                        </Table>     
                          <div className={styles.MessageBox}>{ErrorMessage}</div>  
                      </div>            
                      {ShowTable && RhyteItCustomerPurchases.length > 0 && (<CustomerPurchasesTable   key={updatedKey}  RhyteItCustomerPurchases={RhyteItCustomerPurchases} Environment={Environment} CustomerEmailVerified={CustomerEmailVerified}/>)  }                                                                                        
                
                    </Flex>     
          </div>
    </m.div>                      

    )

  }
 

export default CustomerPurchases;