
import React, { useState , useEffect, useRef, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Button,
  SelectField,
  Flex,
  Autocomplete, Loader 
  } from '@aws-amplify/ui-react'; 
import BlogAIPromptRow from './BlogAIPromptRow';
import { generateClient } from 'aws-amplify/api';
import {
  useInView,
  useScroll,
  useTransform,
  motion,
  useAnimation,
} from "framer-motion";
import styles from './BlogAIPromptTable.module.css';
import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
import {
  listBlogPrompts,
  listSBSMSubReddits
} from "../../../graphql/queries";
import {
  createBlogPrompts,
  deleteBlogPrompts
} from "../../../graphql/mutations";


const BlogAIPromptTable = (props) => {       

  BlogAIPromptTable.propTypes = {
      Blog: PropTypes.object,
      Users: PropTypes.string,
      };

    let debugConsoleOutPut = true
  

    const [vBlog, setvBlog] = useState(props.Blog)   
    const [Users, setUsers] = useState(props.Users)   
    const [ShowTable, setShowTable] = useState(false); 
    
    var ErrorMessageStr = ""
    const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)  
    
    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);
    const client = generateClient();
    
    const [vUpdateKey, setvUpdateKey] = useState()   
    const [updatedKey, setupdatedKey] = useState();
    const [BlogPromptSelected, setBlogPromptSelected] = useState({"Edition":"None"});
    const [BlogPromptVisible, setBlogPromptVisible] = useState(false);
    const [vBlogPrompts, setvBlogPrompts] = useState()   

    
    const [vContentHTML, setvContentHTML] = useState('');
    const [vTemplateJSON, setvTemplateJSON] = useState()  
    const [vContentJSON, setvContentJSON] = useState()     

    const [vSubReddits, setvSubReddits] = useState()  
    const [vBlogPromptName, setvBlogPromptName] = React.useState();
    
    const [vAddBlogPromptVisible, setvAddBlogPromptVisible] = useState(false);

    useEffect(() => {
      //console.log("BlogAIPromptTable.useEffect[isInView].props.Blog: " ,props.Blog);
 

    },[])  

    useEffect(() => {
       if (isInView) {
         mainControls.start("visible");
       }
     }, [isInView]);



    useEffect(() => {
      //console.log("BlogAIPromptTable.useEffect[vBlog].vBlog:  ",vBlog)
  
      if (vBlog !== undefined) {

       
        GetBlogPrompts()
      }
    }, [vBlog]);

     async function GetBlogPrompts() {
      try {
        setErrorMessage("");
        
        //console.log("BlogPromptEditorTable.GetBlogPrompts.vBlog: ", vBlog);
        let variables;
        if (Users !== "edwardhawke@rhyteit.com") {
          variables = {
            filter: {
              AccountGroup: {
                eq: vBlog["AccountGroup"],
              },
              Blog: {
                eq: vBlog["Blog"],
              },
            },
          };
        } else {
          variables = {};
        }
  
        const listBlogPromptsData2 = await client.graphql({
          query: listBlogPrompts,
          variables: variables,
        });
  
        //console.log("BlogPromptEditorTable.GetBlogPrompts.listBlogPrompts.data.listBlogPrompts.items.length : ", listBlogPromptsData2.data.listBlogPrompts.items.length );
  
        if (listBlogPromptsData2.data.listBlogPrompts.items.length > 0) {
          const BlogPromptEditorTable1 =
            listBlogPromptsData2.data.listBlogPrompts.items;
            let vSortedBlogPrompts = SortBlogPromptsByName(BlogPromptEditorTable1)
            setvBlogPrompts(vSortedBlogPrompts)
        } 
  
  
  
      } catch (err) {

        console.error("BlogAIPromptTable.GetBlogPrompts.Error.message:  ", err["errors"][0]["message"].toString()); 
        
        RhyteItLogging(
          Users,
          "BlogAIPromptTable",
          "GetBlogPrompts",
          err["errors"][0]["message"].toString(),
          '{"ERROR": "' + err["errors"][0]["message"].toString() + '"}',
          0
        );
        


      }
    }
  
    function SortBlogPromptsByName(pBlogPromptEditionEmail) {
      pBlogPromptEditionEmail.sort(GFG_sortFunction);
      return pBlogPromptEditionEmail;
      //    //console.log(""JSON.stringify(pEmails));
    }
  
    function GFG_sortFunction(a, b) {
      let BlogPromptEditionA = a.Edition;
      let BlogPromptEditionB = b.Edition;
      return BlogPromptEditionA > BlogPromptEditionB ? 1 : -1;
    }

    function convertToISO(datetimeStr) {
      // Create a new Date object from the provided string
      let date = new Date(datetimeStr.replace(' ', 'T') + 'Z'); // Replace the space with 'T' to make it compatible
      
      // Convert it to ISO string
      return date.toISOString();
    }  

    async function PublishBlogPrompt(event) {        
  
      try {           

        } catch (err) { 
          RhyteItLogging(Users, "BlogPromptSubRedditTable", "PublishBlogPrompt",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          console.error("BlogPromptSubRedditTable.PublishBlogPrompt.Error:  ".err, err.stack); 
      }        
    }  

    async function SendBlogPrompt() {        
  
      try {           


        } catch (err) { 
          RhyteItLogging(Users, "BlogPromptSubRedditTable", "SendBlogPrompt",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          console.error("BlogPromptSubRedditTable.PublishBlogPrompt.Error:  ".err, err.stack); 
      }        
    }  

    async function CreateBlogPrompt() {
      try {

        //console.log("BlogPromptSubRedditTable.CreateBlogPrompt.pSubReddit:  ",pSubReddit);
        var vUpdateDateTime = new Date().toISOString()

        let vId = (vBlog["AccountGroup"] + "-" + vBlog["Blog"] + "-" + vUpdateDateTime).replace(/["']/g, "").replace(/\s+/g, '').replace(/[^\w]/g, '');
              
        let BlogPromptCreateItem = {
          "id": vId,
          "AccountGroup": vBlog["AccountGroup"],
          "Account": vBlog["Account"],
          "Blog": vBlog["Blog"],
          "PromptName": "",
          "SubRedditList": null,
          "Prompt": "",
          "PromptJSON": null,
          "Active": true,
          "BlogPromptStatus": "NEW",
          "CreationName": Users,
          "UpdateName": Users,
          "CreationDateTime": vUpdateDateTime,
          "UpdateDateTime": vUpdateDateTime
        }

         //console.log("BlogPromptSubRedditTable.CreateBlogPrompt.BlogPromptCreateItem: ",BlogPromptCreateItem)

          let createBlogPromptsResponse = await client.graphql({                    
                  query: createBlogPrompts,
                  variables:{input: BlogPromptCreateItem}            
            }
          )
        
        //console.log("BlogPromptSubRedditTable.CreateBlogPrompt.createBlogPromptsResponse: ",createBlogPromptsResponse)
        
        GetBlogPrompts()

      } catch (err) {
        console.error("BlogPromptSubRedditTable.CreateBlogPrompt.Error:  ", err, err.stack); 
        
        RhyteItLogging(
          Users,
          "BlogPromptSubRedditTable",
          "CreateSubReddit",
          err["errors"][0]["message"].toString(),
          '{"ERROR": "' + err["errors"][0]["message"].toString() + '"}',
          0
        );
        
        console.error("BlogPromptSubRedditTable.CreateBlogPrompt.Error.message:  ", err["errors"][0]["message"].toString()); 
      }
    }

    async function ReplaceTemplateVariables() {
        
  
      try {           
       
        let vContentHTMLTmp = vContentHTML


        vContentHTMLTmp = vContentHTMLTmp.replace(/<BlogPromptTitle>/g, vBlog["BlogPrompt"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<PixelTrackerAPIURL>/g, vTemplateJSON["PixelTrackerAPIURL"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<BlogPromptBannerURL>/g, vTemplateJSON["BlogPromptBannerURL"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<IntroductionTitle>/g,vContentJSON["Sections"][5]["Introduction"]["Title"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<IntroductionText>/g,vContentJSON["Sections"][5]["Introduction"]["Text"].replace(/\n/g, "<br/>"))
        vContentHTMLTmp = vContentHTMLTmp.replace(/<IntroductionButtonURL>/g,vContentJSON["Sections"][5]["Introduction"]["ButtonURL"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<IntroductionButtonTitle>/g,vContentJSON["Sections"][5]["Introduction"]["ButtonTitle"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<InThisIssueText>/g,vContentJSON["Sections"][0]["In This Issue"]["Text"].replace(/\n/g, "<br/>"))
        vContentHTMLTmp = vContentHTMLTmp.replace(/<PodcastTitle>/g,vContentJSON["Sections"][4]["Podcast"]["Title"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<PodcastAVURL>/g,vContentJSON["Sections"][4]["Podcast"]["AVURL"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<PodcastImageURL>/g,vContentJSON["Sections"][4]["Podcast"]["ImageURL"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<PodcastButtonURL>/g,vContentJSON["Sections"][4]["Podcast"]["ButtonURL"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<PodcastButtonTitle>/g,vContentJSON["Sections"][4]["Podcast"]["ButtonTitle"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<FeaturedArticleTitle>/g,vContentJSON["Sections"][1]["Featured Article"]["Title"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<FeaturedArticleText>/g,vContentJSON["Sections"][1]["Featured Article"]["Text"].replace(/\n/g, "<br/>"))
        vContentHTMLTmp = vContentHTMLTmp.replace(/<QuickTipsTitle>/g,vContentJSON["Sections"][2]["Quick Tips"]["Title"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<QuickTipsText>/g,vContentJSON["Sections"][2]["Quick Tips"]["Text"].replace(/\n/g, "<br/>"))
        vContentHTMLTmp = vContentHTMLTmp.replace(/<SuccessStoryTitle>/g,vContentJSON["Sections"][3]["Success Story"]["Title"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<SuccessStoryText>/g,vContentJSON["Sections"][3]["Success Story"]["Text"].replace(/\n/g, "<br/>"))
        vContentHTMLTmp = vContentHTMLTmp.replace(/<SuccessStoryButtonURL>/g,vContentJSON["Sections"][3]["Success Story"]["ButtonURL"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<SuccessStoryButtonTitle>/g,vContentJSON["Sections"][3]["Success Story"]["ButtonTitle"])

        
        vContentHTMLTmp = vContentHTMLTmp.replace(/<UnsubscriberURL>/g, vTemplateJSON["UnsubscriberURL"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<BlogPromptEmail>/g, vTemplateJSON["BlogPromptEmail"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<Copyright>/g, vTemplateJSON["Copyright"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<Address>/g, vTemplateJSON["Address"])
        
        
       

        setvContentHTML(vContentHTMLTmp);

        } catch (err) { 
          RhyteItLogging(Users, "BlogPromptSubRedditTable", "ReplaceTemplateVariables",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          console.error("BlogPromptSubRedditTable.ReplaceTemplateVariables.Error:  ".err, err.stack); 
      }        
    }  

    async function DeleteAllRows(event) {
      try {

        
        vSubReddits.forEach(vBlogSubReddit => {
        
          DeleteRow(vBlogSubReddit) 

          });

          GetBlogPrompts()
      
          } catch (err) { 

            RhyteItLogging(Users, "BlogPromptSubRedditTable", "DeleteAllRows",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
            console.error("BlogPromptSubRedditTable.DeleteAllRows.Error:  ".err, err.stack);       
        }               
      
    }      

    async function DeleteRow(pSubsciber) {
      try {

              
              setErrorMessage("")   

              const DeleteBlogPromptRow = {
                id: pSubsciber.id,
                _version: pSubsciber._version
              }
              

              const vDeleteResponse = await client.graphql({
                query: deleteBlogPrompts,
                variables: {
                  input: DeleteBlogPromptRow
                }
              });  

              ErrorMessageStr = vDeleteResponse
              setErrorMessage(ErrorMessageStr)  

              
      
          } catch (err) { 

            RhyteItLogging(Users, "BlogPromptSubRedditTable", "DeleteRow",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
            console.error("BlogPromptSubRedditTable.DeleteRow.Error:  ".err, err.stack);       
        }               
      
    }   

  return (   
    <section ref={ref} className = {styles.Section}>
      {
        vBlog !== undefined  && (                      
        <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
            className={styles.FlexLeftColumn}>   
            

        <motion.div 
              className = {styles.BlogPromptTitleDiv}
              style={{
                  transform: isInView ? "none" : "translateX(-200px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                }}> 
            <motion.h1  className = {styles.BlogPromptTitle}>
              {vBlog["Blog"]}
            </motion.h1>   
        </motion.div>             
        <motion.div 
              className = {styles.BlogPromptDescriptionDiv}
              style={{
                  transform: isInView ? "none" : "translateX(-200px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                }}> 
            <motion.h1  className = {styles.BlogPromptDescription}>
              {vBlog["Description"]}
            </motion.h1>   
        </motion.div>  
        <Flex
               direction="row"
               justifyContent="flex-start"
               alignItems="center"
               alignContent="center"
               wrap="nowrap"
               gap="1rem"
               className={styles.FlexRow}>    
               <Button
                    className={styles.Button}
                    variation="primary"
                    onClick={(e) => CreateBlogPrompt(e)}
                  >
                    Add
                </Button>             
              <Button
                className={styles.Button}
                variation="primary"
                onClick={(e) => GetBlogPrompts(e)}
              >
                Refresh
            </Button> 
        </Flex>                
        <Table className={styles.Table}   highlightOnHover={true}>   
          <TableHead>          
            <TableRow className={styles.TableHeaderRow}>
              <TableCell className={styles.TableHeaderRowCells} >Actions</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Name</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Active</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Status</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Updated</TableCell> 
            </TableRow>                                      
          </TableHead>       
          
              {
               vBlogPrompts !== undefined && vBlog !== undefined && vBlogPrompts.map((vBlogPrompt) => (                      
                  (<BlogAIPromptRow   key={vBlogPrompt.UpdateDateTime}  Users={Users} BlogPrompt={vBlogPrompt}/>)        
                  ))
              }                    
        </Table>   
        </Flex>                 
      )}      
    </section>
  )
}

export default BlogAIPromptTable;


