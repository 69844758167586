import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {motion as m} from "framer-motion";
import Marquee from "react-fast-marquee";
import styles from './SBSMLogoTicker.module.css';
import  { listSBSMNewsQueryTerms }  from '../../graphql/queries'
import  { }  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';
import {GetSBSMNewsQueryTermsAd} from '../../BusinessLogic/SBSMQueryTermsManagement';
import {
  Image, Text
} from '@aws-amplify/ui-react';

const SBSMLogoTicker = (props) => {       
  
  SBSMLogoTicker.propTypes = {
          RedditUser: PropTypes.object
        };
        const [SBSMNewsQueryTerms, setSBSMNewsQueryTerms] = useState()


        const [SBSMLogosData, setSBSMLogosData] = useState()              
              

        useEffect(() => {

          (async function() {
            try {  

              GetSBSMLogosFromS3()


                } catch (err) {
                  console.error('SBSMLogoTicker.useEffect[].ERROR: ', err, err.stack); 
              }
            }
          )();   
      

        },[]) 

        async function GetSBSMNewsQueryTerms() {
          try {

                      const client = generateClient();

                      const SBSMNewsQueryTermsData  = await client.graphql({
                          query: listSBSMNewsQueryTerms
                        });        
          
                      let SBSMNewsQueryTerms = SBSMNewsQueryTermsData.data.listSBSMNewsQueryTerms.items 
                      
                      setSBSMNewsQueryTerms(SBSMNewsQueryTerms)

  
          } catch (err) { 
                          console.error('SBSMLogoTicker.GetSBSMNewsQueryTerms.ERROR: ', err, err.stack); 
                      }
        }       
        
        async function GetSBSMLogosFromS3() {
          try {


                    const url = `https://rhyteitfiles.s3.us-west-2.amazonaws.com/ScollingData/SBSMLogos.json`

                    const fetchResponse = await fetch(url);
                    let SBSMLogosData1 = await fetchResponse.json();

                    setSBSMLogosData(SBSMLogosData1)                    


  
          } catch (err) { 
                          console.error('TreeGrowth.GetSBSMLogos.ERROR: ', err, err.stack); 
                      }
        }          



    return ( 
      <div className={styles.MarqueeWrapper}> 
        <div
          className={styles.MarqueeBlock}
        >        
          <div
            className={styles.MarqueeInner}
          >                
          {
                    SBSMLogosData !== undefined && SBSMLogosData.map((vLogo) => (  
                      <div className={styles.ImageContainer}> 
                          <Image
                            src={vLogo.LogImageFileLocation}
                            className={styles.PhotoImage}
                          />                             
                        </div>  

                  ))
                }   
                </div>   
              </div>    
            </div>  
    )

  }
 

export default SBSMLogoTicker;