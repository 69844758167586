
import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead
  } from '@aws-amplify/ui-react'; 
import PrivateKey from './ApplicantManagementRow';
import styles from './ApplicantManagementTable.module.css';


const ApplicantManagementTable = (props) => {       

  ApplicantManagementTable.propTypes = {
      RhyteItApplicantManagement: PropTypes.array,
      RhyteItUser: PropTypes.array
      };

    let debugConsoleOutPut = true

  

    const [RhyteItApplicantManagementTable, setRhyteItApplicantManagementTable] = useState(props.RhyteItApplicantManagement)   
    const [ShowTable, setShowTable] = useState(false);

    useEffect(() => {

     // console.log("ApplicantManagementTable.useEffect[].props.RhyteItApplicantManagement:  ",props.RhyteItApplicantManagement) 
      setRhyteItApplicantManagementTable(props.RhyteItApplicantManagement)
      //console.log("ApplicantManagementTable.useEffect[].RhyteItApplicantManagementTable ",RhyteItApplicantManagementTable) 
      //console.log("vPrivateKeys.useEffect[].props.RhyteItUser:  ",props.RhyteItUser) 
      //console.log("vPrivateKeys.useEffect[].Time:  ", PrivateKey.PostDateTime.substr(PrivateKey.PostDateTime.indexOf("T")+1, 5))
      //onsole.log("vPrivateKeys.useEffect[].indexOf:  ", PrivateKey.PostDateTime.indexOf(":00"))
      if (Object.keys(RhyteItApplicantManagementTable).length > 0) {
        setShowTable(true)
      }



    },[])  



  return (  
      <Table className={styles.Table}   highlightOnHover={true}>   
          <TableHead>          
            <TableRow className={styles.TableHeaderRow}>
              <TableCell className={styles.TableHeaderRowCells} >Applicant</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >SocialMediaProfile</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >OF Stats</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Status</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Created</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Updated</TableCell> 
              <TableCell className={styles.TableHeaderRowCellsActions} colSpan="2" >Actions</TableCell> 
            </TableRow>                                      
          </TableHead>       
          
              {
                ShowTable && RhyteItApplicantManagementTable.length > 0 &&  RhyteItApplicantManagementTable && RhyteItApplicantManagementTable.map((RhyteItApplicantManagementRow) => (                      
                  (<PrivateKey   key={RhyteItApplicantManagementRow.id}  RhyteItApplicantManagementRow={RhyteItApplicantManagementRow}/>)        
                  ))
              }                    
        </Table>
    
  )
}

export default ApplicantManagementTable;


