import PropTypes from "prop-types";
import React, { useState, useEffect, useContext, useRef } from "react";

import {
  listBlogs,
} from "../../../graphql/queries";
import  { createNewsletterSubscribers}  from '../../../graphql/mutations'

import { generateClient } from "aws-amplify/api";

import {
  RhyteItLogging,
} from "../../../BusinessLogic/UserManagement";
import { motion as m } from "framer-motion";
import { useNavigate } from "react-router-dom";


import BlogEntries from './BlogEntries';
import BlogCategoryPosts from './BlogCategoryPosts';
import BlogMenuIcon from './BlogMenuIcon';

import {
  Button,
  Fieldset,
  SelectField,
  Flex,
  Text,
  TextField,
  Card,
  Badge,
  Image,
  Heading,
  Link,
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  TextAreaField,
  SearchField
} from "@aws-amplify/ui-react";
import styles from "./Blog.module.css";
import { SEO } from '../../SEO/SEO';


function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return width;
}

const Blog = (props) => {
  Blog.propTypes = {
    RedditUser: PropTypes.object,
  };

  const width = useWindowWidth();

  const client = generateClient();
  const [vBlog, setvBlog] = useState([]);
  const navigate = useNavigate();
  

  const vBreadCrumbItems = [
    { Path: "/", Title: "Home", isCurrent: false },
    { Path: "/MainMenuV2", Title: "Menu", isCurrent: false },
    { Path: "/Blog", Title: "Blog", isCurrent: true },
  ];

  var ErrorMessageStr = "";
  const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr);


  const [vBlogSelected, setvBlogSelected] = useState();
  const [vNewsletterEmail, setvNewsletterEmail] = useState();
  const [vSearchTerm, setvSearchTerm] = useState();
  const inputRef = React.useRef(null);
  const searchButtonRef = React.useRef(null);
  const [vUpdateEntries, setvUpdateEntries] = useState();
  const [vSubscribed, setvSubscribed] = useState(false);
  const [vBlogCategoryData, setvBlogCategoryData] = useState(null);
  const [vBlogCategories, setvBlogCategories] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [vCategoryClicked, setvCategoryClicked] = useState(false);
  const [vCategorySelected, setvCategorySelected] = useState();
  const [vCategoryPosts, setvCategoryPosts] = useState();
  const [vBlogEntriesViewable, setvBlogEntriesViewable] = useState(false);
  const [vSideBarViewable, setvSideBarViewable] = useState(false);
  const [vCellPhone, setvCellPhone] = useState(false);
  
  useEffect(() => {
    window.scrollTo(0, 0);
    GetBlogs()

    if (width < 800) {
      setvCellPhone(true)
    }

    //console.log("Blog.useEffect[].width: ", width);

      const fetchData = async () => {
        try {
            const response = await fetch('https://s3.us-west-2.amazonaws.com/blog.rhyteit.com/BlogCategories.json');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();            
            setvBlogCategoryData(jsonData);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    fetchData();

  }, []);


  useEffect(() => {



    setvUpdateEntries(Date.now());

  }, [vBlogSelected]);

  useEffect(() => {
    
    //console.log("Blog.useEffect[vCellPhone].vCellPhone: ", vCellPhone);

    if (vCellPhone === false) {
      setvBlogEntriesViewable(true)
      setvSideBarViewable(true)
    } else {
      setvBlogEntriesViewable(true)
      setvSideBarViewable(false)
    }

  }, [vCellPhone]);

  useEffect(() => {

    //console.log("Blog.useEffect[vBlogEntriesViewable].vBlogEntriesViewable: ", vBlogEntriesViewable);

  }, [vBlogEntriesViewable]);

  

  useEffect(() => {

    if (vBlogCategoryData !== undefined && vBlogCategoryData !== null) {
      //console.log("Blog.useEffect[vBlogCategoryData].vBlogCategoryData: ", vBlogCategoryData);
      
      let vSortedBlogCategories = SortBlogCategoriesByPost(vBlogCategoryData["BlogCategories"])
      setvBlogCategories(vSortedBlogCategories)
    }

  }, [vBlogCategoryData]);  

  useEffect(() => {

    window.scrollTo(0, 0);

  }, [vCategoryPosts]); 

  

  async function GetBlogs() {
    try {
      setErrorMessage("");
      setvCategoryClicked(false)
      setvCategorySelected()
      
      //console.log("Blog.GetBlogs.Users: ", Users);
      let variables = {
          filter: {
            id: {
              eq: "Adult Content Creator Corner",
            },
          },
        };

      const listBlogsData2 = await client.graphql({
        query: listBlogs,
        variables: variables,
      });

      //console.log("Blog.GetBlogs.listBlogs.data.listBlogs.items.length : ", listBlogsData2.data.listBlogs.items.length );

      if (listBlogsData2.data.listBlogs.items.length > 0) {
        const Blog1 =
          listBlogsData2.data.listBlogs.items;
          setvBlogSelected(Blog1[0])
      } 



    } catch (err) {
      RhyteItLogging(
        "NA",
        "Blog",
        "GetBlogs",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  async function vSearchTermChanged(event, data) {
    try {

      let vTmp = event.target.value

        setvSearchTerm(vTmp);
    
  } catch (err) {
    RhyteItLogging(
      "NA",
      "Blog",
      "vSearchTermChanged",
      err.toString(),
      '{"ERROR": "' + err.toString() + '"}',
      0
    );
  }
}

async function GetSearch(event, data) {
  try {

    setvCategoryClicked(false)
    setvCategorySelected()
    //console.log("Blog.GetSearch.event: ", event );
  
} catch (err) {
  RhyteItLogging(
    "NA",
    "Blog",
    "vSearchTermChanged",
    err.toString(),
    '{"ERROR": "' + err.toString() + '"}',
    0
  );
}
}

async function vClearSearchBar(event) {
  try {

    setvCategoryClicked(false)
    
    //console.log("Blog.vClearSearchBar.event: ", event );
    setvSearchTerm()
  
} catch (err) {
  RhyteItLogging(
    "NA",
    "Blog",
    "vSearchTermChanged",
    err.toString(),
    '{"ERROR": "' + err.toString() + '"}',
    0
  );
}
}

async function NewsletterSubscriberField(event, data) {
  try {

    let vTmp = event.target.value

      setvNewsletterEmail(vTmp);
  
} catch (err) {
  RhyteItLogging(
    "NA",
    "Blog",
    "NewsletterSubscriberField",
    err.toString(),
    '{"ERROR": "' + err.toString() + '"}',
    0
  );
}
}


const onClick = React.useCallback(() => {
  inputRef.current.focus();
  //console.log("Blog.onClick.inputRef.current.value: ", inputRef.current.value );
  setvSearchTerm(inputRef.current.value);
  setvUpdateEntries(Date.now());
}, []);

React.useEffect(() => {
  const searchButtonRefCurrent = searchButtonRef.current;
  if (searchButtonRef && searchButtonRefCurrent) {
    // Note: this example is contrived to demonstrate using refs.
    // Use the `onSubmit` prop on `SearchField` instead which
    // responds to input field `Enter` keypresses and Submit button clicks.
    searchButtonRefCurrent.addEventListener('click', onClick, false);
    return () => {
      searchButtonRefCurrent.removeEventListener('click', onClick, false);
    };
  }
}, [onClick]);

function convertToISO(datetimeStr) {
  // Create a new Date object from the provided string
  let date = new Date(datetimeStr.replace(' ', 'T') + 'Z'); // Replace the space with 'T' to make it compatible
  
  // Convert it to ISO string
  return date.toISOString();
}  

async function CreateNewsletterSubscriber() {
        
  
  try {           
      setErrorMessage("")
               
      var vUpdateDateTimeNew = new Date().toISOString();
  
      let vId = "TheAdultContentCreatorsEdge-RhyteItAccountGroup2-" + vNewsletterEmail

      let datetimeStr = "1999-01-01 01:00:00";
      let isoDate = convertToISO(datetimeStr);
      const vSubscriptionPreferencesJSONString = JSON.stringify({"frequency": "weekly", "content": "news"});   

      let SubscriberItem = {
        id: vId,
        Newsletter: "The Adult Content Creator's Edge",
        AccountGroup: "RhyteIt Account Group 2",
        SubscriberEmail: vNewsletterEmail,
        SubscriptionStatus: "subscribed",
        SubscribedDate: vUpdateDateTimeNew,
        SubscriberSegment: "",
        LastEmailSentDate: isoDate,
        LastEmailOpenedDate: isoDate,
        SendCount: 0,
        OpenCount:  0,
        ClickCount: 0,
        UnsubscribeReason: "",
        SubscriptionSource: "BlogPage",
        Geolocation: "Unknown",
        SubscriptionPreferences: vSubscriptionPreferencesJSONString,
        BounceReason: "",
        Active: true,
        CreationName: vNewsletterEmail,
        CreationDateTime: vUpdateDateTimeNew,
        UpdateName: vNewsletterEmail,
        UpdateDateTime: vUpdateDateTimeNew,
      };


      //console.log("Blog.CreateNewsletterSubscriber.SubscriberItem    : ", SubscriberItem );
  
      const createNewsletterResponse = await client.graphql({
        query: createNewsletterSubscribers,
        variables: {
          input: SubscriberItem,
        },
      });

      setvSubscribed(true)
      setvNewsletterEmail("")

    } catch (err) { 
      console.error("Blog.CreateNewsletterSubscriber.Error:  ", err, err.stack); 
      RhyteItLogging("NA", "Blog", "CreateNewsletterSubscriber",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
  }        
}    

function SortBlogCategoriesByPost(pBlogCategories) {
  pBlogCategories.sort(GFG_sortFunction);
  return pBlogCategories;
  //    //console.log(""JSON.stringify(pEmails));
}

function GFG_sortFunction(a, b) {
  let BlogPostCountA = a.BlogPostCount;
  let BlogPostCountB = b.BlogPostCount;
  return BlogPostCountA < BlogPostCountB ? 1 : -1;
}

async function CategoryClickHandler(event, pCategory) {
  try {
      //console.log("Blog.CategoryClickHandler.pCategory: ", pCategory );
      setvCategoryClicked(true)
      setvCategorySelected(pCategory)

      if (vCellPhone === true) {
        setvBlogEntriesViewable(true)
        setvSideBarViewable(false)
      } 

      if (pCategory !== "All") {
        let filteredArray = filterByCategory(pCategory);
        setvCategoryPosts(filteredArray)

      } else {

        let vBlogIndividalPosts = GetAllIndividualPosts(vBlogCategoryData["BlogCategoryPosts"])

        //console.log("Blog.CategoryClickHandler.vBlogIndividalPosts: ", vBlogIndividalPosts);
        setvCategoryPosts(vBlogIndividalPosts)
      }
  
} catch (err) {
  RhyteItLogging(
    "NA",
    "Blog",
    "CategoryClickHandler",
    err.toString(),
    '{"ERROR": "' + err.toString() + '"}',
    0
  );
}
}

const filterByCategory = (pCategory) => {
  return vBlogCategoryData["BlogCategoryPosts"].filter(item => item.Category === pCategory);
};

const GetAllIndividualPosts = (pBlogCategoryPosts) =>  {
  try {
    
  
    //console.log("Blog.GetAllIndividualPosts.Start");
  
    let vBlogPostsIndividual = []

    pBlogCategoryPosts.forEach(obj => {

    if (vBlogPostsIndividual !== undefined) {

        let vBlogIndividalItemExists = vBlogPostsIndividual.filter(item => item.Title === obj.Title)

        if (vBlogIndividalItemExists.length === 0) {
          vBlogPostsIndividual.push(obj)
        }
    } else {

      vBlogPostsIndividual.push(obj)

    }

  });

  //console.log("Blog.GetAllIndividualPosts.vBlogPostsIndividual: ", vBlogPostsIndividual.length );

  return vBlogPostsIndividual
  
} catch (error) {
  console.error("Blog.GetAllIndividualPosts.Error:  ", error); 
}

}

async function MenuClickHandler(event, pvBlogEntriesViewable) {
  try {
      let vTmp = event.target.value

      setvBlogEntriesViewable(vBlogEntriesViewable === true ? false : true)
      setvSideBarViewable(vSideBarViewable === true ? false : true)
  
} catch (err) {
  RhyteItLogging(
    "NA",
    "Blog",
    "CategoryClickHandler",
    err.toString(),
    '{"ERROR": "' + err.toString() + '"}',
    0
  );
}
}



  return (
    <m.div
      key="Search"
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      exit={{ opacity: 1 }}
      className={styles.Background}
    >    
      <SEO
          title="RhyteIt Blog: Strategies for Adult Content Creators to Boost Profits"
          description='Explore RhyteIt Blog for expert insights and actionable tips designed to help adult content creators maximize their profits, enhance client satisfaction, and thrive in the subscription-based landscape.'
          name='RhyteIt'
          type='article' />      
      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="center"
        wrap="nowrap"
        gap="1rem"
        className={styles.MainColumn}
      >
    {vBlogSelected !== undefined &&  vCellPhone === true &&(
      <section className={styles.HeaderSection}>
        <Flex
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          alignContent="flex-start"
          wrap="nowrap"
          gap="1rem"
          className={styles.LogoTitleRow}
        >
          <img className={styles.logoimg} src="https://rhyteitartwork.s3.us-west-2.amazonaws.com/RhyteItFavicon2.svg" alt="RhyteIt"/>
          <h1 className={styles.Title}>{vBlogSelected.Blog}</h1>          
          <BlogMenuIcon
            className={styles.Hamburger}
            NavBarMenuClicked={vBlogEntriesViewable}
            onClick={(e) => MenuClickHandler(e, vBlogEntriesViewable)}
            />    
            
        </Flex>   
      </section>
    )}
    {vBlogSelected !== undefined && vCellPhone === false &&    (
      <section className={styles.HeaderSection}>
        <Flex
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="flex-start"
          wrap="nowrap"
          gap="1rem"
          className={styles.LogoTitleRow}
        >
          <img className={styles.logoimg} src="https://rhyteitartwork.s3.us-west-2.amazonaws.com/RhyteItFavicon2.svg" alt="RhyteIt"/>
          <h1 className={styles.Title}>{vBlogSelected.Blog}</h1>         
        </Flex>   
      </section>
    )}    
    {vBlogSelected !== undefined &&  (
      <Flex
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          alignContent="center"
          wrap="nowrap"
          gap="1rem"
          className={styles.FlexColumn}
        >      
         {vSideBarViewable === true &&  (
            <Flex
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              alignContent="center"
              wrap="nowrap"
              gap="1rem"
              className={styles.SideBarFlexColumn}
            >
              <h3
              className={styles.Description}>{vBlogSelected.Description}</h3>
              <Text className={styles.Label}>Search</Text>
              <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      alignContent="center"
                      wrap="nowrap"
                      gap="1rem"
                      className={styles.SubscribeRow}
                    >                  
                    <SearchField
                      placeholder="Search here..."
                      hasSearchIcon={true}
                      labelHidden={true}
                      hasSearchButton={false}
                      onClear={(e) => vClearSearchBar(e)}
                      onChange={(e) => vSearchTermChanged(e, vSearchTerm)}
                      onClick={(e) => GetSearch(e, vSearchTerm)}
                      value={vSearchTerm}
                      className={styles.SearchField}
                      ref={inputRef}
                      searchButtonRef={searchButtonRef}
                    />
                    <Button
                              className={styles.Button}
                              onClick={(e) => GetSearch(e, vSearchTerm)}
                              
                            >Search</Button> 

              </Flex>                            
              <Text className={styles.Label}>Get the weekly newsletter</Text>
              <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      alignContent="center"
                      wrap="nowrap"
                      gap="1rem"
                      className={styles.SubscribeRow}
                    >                 
                  <TextField
                      className={styles.SubscribeField}
                      name="vNewsletterEmail"
                      placeholder="Type your email..."
                      defaultValue={vNewsletterEmail}
                      onChange={(e) => NewsletterSubscriberField(e, vNewsletterEmail)}
                    />                    
                    <Button
                        className={styles.Button}
                        onClick={(e) => CreateNewsletterSubscriber(e)}
                        
                      >Subscribe</Button> 
              </Flex>      
            {vSubscribed === true &&  (
                  <Text>Thank you for subscribing! :)</Text>   
            )}   
              <Flex
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  alignContent="flex-start"
                  wrap="wrap"
                  gap="1rem"
                  className={styles.CategoryRow}>  
                    {
                    vBlogCategoryData !== undefined && (       
                  <Button  className={styles.ButtonCategory} onClick={(e) => CategoryClickHandler(e,"All")}>
                              All: {vBlogCategoryData["BlogPostCount"]} 
                  </Button>)
                    }                
                    {
                    vBlogCategories !== undefined && vBlogCategories.map((vBlogCategory) => (                      
                        (
                          <Button className={styles.ButtonCategory} onClick={(e) => CategoryClickHandler(e, vBlogCategory["Category"])}>
                              {vBlogCategory["Category"]}: {vBlogCategory["BlogPostCount"]} 
                          </Button>
                        )        
                      ))
                    }    
              </Flex>                              
            </Flex>             
          )}
        {vCategoryClicked === false && vCategorySelected === undefined && vBlogEntriesViewable === true && (   
        <Flex
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          alignContent="center"
          wrap="nowrap"
          gap="1rem"
          className={styles.FlexColumnBlogEntries}
        >
          <BlogEntries Blog={vBlogSelected} key={vUpdateEntries} SearchTerm={vSearchTerm}/>
        </Flex>  
        )}  

      {vCategoryClicked === true && vCategorySelected !== undefined&& vBlogEntriesViewable === true && (   
        <Flex
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          alignContent="center"
          wrap="nowrap"
          gap="1rem"
          className={styles.FlexColumnBlogEntries}
        >
         <Text className={styles.CategoryTitle}>{vCategorySelected}</Text>
         <BlogCategoryPosts Blog={vBlogSelected} key={vCategorySelected}  Category={vCategorySelected}  CategoryBlogPosts={vCategoryPosts}/>
         
      </Flex>  
        )}          
      </Flex>   
    )}       
      
    </Flex>         
    </m.div>
  );
};

export default Blog;
