
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Text,
  TableBody,
  Table,
  TableHead,
  Image,
  CheckboxField
  } from '@aws-amplify/ui-react';

  import  {  }  from '../../../graphql/queries'
  import  { deleteSMMADContent, updateSMMADContent, createSMMADContent}  from '../../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";

  import styles from './SCMContentRow.module.css';  
  import { UserContext} from '../../../BusinessLogic/Hooks/UserContext';  
  import { SelectedImageContext} from '../../../BusinessLogic/Hooks/SMMADImageContext';
  import SMMADText from "../SMMADText/SMMADText";
  
function SCMContentRow({
  SMMADSCMContentRow,
  HashTagMap,
  ImageToTextRelationship,
  AccountSelected,
  CampaignSelected,
  ClickedImage,
  onClick
}) {       

  SCMContentRow.propTypes = {
      SMMADSCMContentRow: PropTypes.object,
      };

    const client = generateClient();
  
    const {Users, setUsers} = useContext(UserContext);   
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vSCMContentRow, setvSCMContentRow] = useState(SMMADSCMContentRow)   

    const [vCategory, setvCategory] = useState()   
    const [vActive, setvActive] = useState(SMMADSCMContentRow.Active)   
    
    const [vAddText, setvAddText] = useState(false)   
        
    const vSelectedImage = useContext(SelectedImageContext);

    //console.log("SCMContentRow.useEffect[].vSelectedImage:  ",vSelectedImage) 

    useEffect(() => {

       //console.log("SCMContentRow.useEffect[].vSelectedImage:  ",vSelectedImage) 
       //console.log("SCMContentRow.useEffect[].ClickedImage:  ",ClickedImage) 
       //console.log("SCMContentRow.useEffect[].onClick:  ",onClick) 
      
      },[])  

      useEffect(() => {

       //console.log("SCMContentRow.useEffect[vActive].vActive:  ",vActive) 

       if (vSCMContentRow.Active !== vActive) {
        SaveRow()
       }

       
       },[vActive])      



      async function SaveRow() {
          try {           
            
              setErrorMessage("")

              var vUpdateDateTimeNew = new Date().toISOString()
          
              vSCMContentRow.UpdateDateTime = vUpdateDateTimeNew       
              vSCMContentRow.UpdateName = Users  

              
              console.log("SCMContentRow.SaveRow.vActive:  ",vActive)    
              console.log("SCMContentRow.SaveRow.vActive.typeof:  ",typeof vActive)              
              console.log("SCMContentRow.SaveRow.vSCMContentRow.Active:  ",vSCMContentRow.Active)  
              console.log("SCMContentRow.SaveRow.vSCMContentRow.Active.typeof:  ",typeof vSCMContentRow.Active)  
              vSCMContentRow.Active = vActive
              
              console.log("SCMContentRow.SaveRow.vSCMContentRow.Active2:  ",vSCMContentRow.Active)  


              let ContentItem = {
                  "id": vSCMContentRow.id,
                  "Account": vSCMContentRow.Account,
                  "AccountType": vSCMContentRow.AccountType,
                  "Campaign": vSCMContentRow.Campaign,
                  "Category": vCategory,
                  "ContentType": vSCMContentRow.ContentType,
                  "Text": vSCMContentRow.Text,
                  "URL": vSCMContentRow.URL,
                  "S3Bucket": vSCMContentRow.S3Bucket,
                  "S3Key": vSCMContentRow.S3Key,
                  "PostedCount":  vSCMContentRow.PostedCount,
                  "Active":  vSCMContentRow.Active,
                  "Weight":  vSCMContentRow.Weight,
                  "ZScore":  vSCMContentRow.ZScore,
                  "SMMADContentStatus":  "Active Status Updated",
                  "CreationName": vSCMContentRow.CreationName,
                  "CreationDateTime": vSCMContentRow.CreationDateTime,
                  "UpdateName": vSCMContentRow.UpdateName,
                  "UpdateDateTime": vSCMContentRow.UpdateDateTime,
              }


              // //console.log("SCMContentRow.SaveRow.ContentItem:  ",ContentItem)                      

                const updateSMMADContentResponse = await client.graphql({
                  query: updateSMMADContent,
                  variables: {
                    input: ContentItem
                  }
                });  

            } catch (err) { 
              ErrorMessageStr = ("SCMContentRow.SaveRow.Error: ", err, err.stack)
              console.error(ErrorMessageStr)
              setErrorMessage(ErrorMessageStr)
          }        
        }      



  return (
                
            <TableRow key={vSCMContentRow.id}  className={styles.TableRow}>  
            <TableCell className={styles.TableCellHeader}>            
            <Image
                alt=""
                src={vSCMContentRow.URL}  
                objectFit="initial"
                objectPosition="50% 50%"
                className={styles.PhotoImage}
              />        
            </TableCell>         
            <TableCell className={styles.TableCellHeader}>      
            <Text className={styles.Text}>
            {vSCMContentRow.PostedCount}  
              </Text>          
            </TableCell>      
            <TableCell className={styles.TableCellHeader}>      
            <Text className={styles.Text}>
            {vSCMContentRow.ZScore}  
              </Text>          
            </TableCell>      
            <TableCell className={styles.TableCellHeader}>              
              <CheckboxField
                  className={styles.CheckboxField}
                  name="Active"
                  value={vActive}
                  checked={vActive}
                  size="medium"
                  onChange={(e) => setvActive(e.target.checked)}
                />                                         
            </TableCell>     
          </TableRow> 
  )
}

export default SCMContentRow;

