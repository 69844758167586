import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import QueryTermMessageBox from "../../ui-components/StatusMessageBox";
import {ErrorBoundary} from 'react-error-boundary'
import Header from "../RhyteItComponents/Header/Header";

import { UserContext, CognitoGroupContext} from '../../BusinessLogic/Hooks/UserContext';
import {GetGroupRoleAccess, RhyteItLogging, ReturnUserToLandingPage} from '../../BusinessLogic/UserManagement';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useNavigate, useLocation } from "react-router-dom";
import  { }  from '../../graphql/queries'
import  { deleteSBSMNewsQueryTerms}  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import {GetSBSMNewsQueryTermsAd} from '../../BusinessLogic/SBSMQueryTermsManagement';
import {motion as m} from "framer-motion";
import SBSMQueryTermTable from "./SBSMQueryTermTable";
import {get_uuidv4} from '../../BusinessLogic/uuidv4';

import {
  Button ,
  Authenticator,
  Autocomplete,
  Flex
} from '@aws-amplify/ui-react';
import styles from './SBSMQueries.module.css';

function ErrorFallback({error, resetErrorBoundary}) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}


const SBSMQueries = (props) => {       
  
  SBSMQueries.propTypes = {
          reddituser: PropTypes.object
        };



        const [SBSMNewsQueryTerms, setSBSMNewsQueryTerms] = useState([{id: '',
              UserId: '',
              QueryTerm:  '',
              QueryTermStatus:  '',
              CreationName:  '',
              UpdateName:  '',
              CreationDateTime:  '',
              UpdateDateTime:  '' }])
              
        const vBreadCrumbItems = [{Path: "/", Title:"Home", isCurrent:false},{Path: "/MainMenuV2", Title:"Menu", isCurrent:false},{Path: "/SBSMQueries", Title:"SBSM Queries", isCurrent:true}]              

        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   
        const [RhyteItUser, setRhyteItUser] = useState([])    
        const [updatedKey, setupdatedKey] = useState(Date.now())   
        let debugConsoleOutPut = true

        let navigate = useNavigate(); 
        const {Users, setUsers} = useContext(UserContext);    
        const {CognitoGroup, setCognitoGroup} = useContext(CognitoGroupContext);       
        const [QueryTerm, setQueryTerm] = useState([]);
        const [SearchTerm1, setSearchTerm1] = useState('');
        const [ShowTable, setShowTable] = useState(false);
        const client = generateClient();


          useEffect(() => {

           
  
          },[])         
  

             useEffect(() => {
             
              (async function() {
                try {  
    
                        if (CognitoGroup !== undefined) {
    
                          let   GetGroupRoleAccessResponse = await GetGroupRoleAccess(CognitoGroup, "rhyteitAdmins")
                          
                          if (GetGroupRoleAccessResponse) {  
                            GetSBSMNewsQueryTerms()  
                          }
                              
    
                        } else {
    
                          const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
    
                          if (idToken !== undefined) {
                            setUsers(idToken.payload.email)
                            setCognitoGroup(accessToken["payload"]["cognito:groups"])
                          } else {
                            
                            ReturnUserToLandingPage(Users, CognitoGroup)
                          }
    
                        }
                      } catch (err) {                  
                        RhyteItLogging(Users, "SBSMQueries", "useEffect[CognitoGroup]",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                    }
                  }
                )();   
        
               },[CognitoGroup])               


        useEffect(() => {

          if (SBSMNewsQueryTerms !== undefined) {

          if (Object.keys(SBSMNewsQueryTerms).length > 0) {
            setShowTable(true)
          }

          setErrorMessage("Query terms retrieved at: " + new Date().toISOString())

        }

        },[SBSMNewsQueryTerms])     
        
        useEffect(() => {

        //console.log("SBSMQueries.useEffect[ShowTable].ShowTable: ", ShowTable)

        }, [ShowTable]);  

        useEffect(() => {

          //console.log("SBSMQueries.useEffect[QueryTerm].QueryTerm: ", QueryTerm)

        }, [QueryTerm]);   

        useEffect(() => {

        //console.log("SBSMQueries.useEffect[SearchTerm1].Users:  ",Users)

        if (Users !== undefined) {                    
          GetSBSMNewsQueryTerms()
        }    

        }, [SearchTerm1]);      
          
        useEffect(() => {
      
          //console.log("SBSMQueries.useEffect[updatedKey].updatedKey:  ",updatedKey)
          //console.log("SBSMQueries.useEffect[updatedKey].ShowTable:  ",ShowTable)
          //consif (SBSMNewsQueryTerms !== undefined) {ole.log("SBSMQueries.useEffect[updatedKey].Object.keys(SBSMNewsQueryTerms).length:  ",Object.keys(SBSMNewsQueryTerms).length)
          if (SBSMNewsQueryTerms !== undefined) {
            if (Object.keys(SBSMNewsQueryTerms).length > 1) {
              setShowTable(true)
            }
          }

        }, [updatedKey]);      
  

        async function GetSBSMNewsQueryTerms() {
          try {
                    setErrorMessage("")

                    const vSBSMNewsQueryTerms = await GetSBSMNewsQueryTermsAd(Users)

                    
                    //console.log("SBSMQueries.GetSBSMNewsQueryTerms.vSBSMNewsQueryTerms:  ",vSBSMNewsQueryTerms)

                    setSBSMNewsQueryTerms(vSBSMNewsQueryTerms)
                                        
                    let PopulateQueryTermsListResponse = await PopulateQueryTermsList(vSBSMNewsQueryTerms)

                    setupdatedKey(Date.now())

                    return vSBSMNewsQueryTerms

  
          } catch (err) {        
            RhyteItLogging(Users, "SBSMQueries", "GetSBSMNewsQueryTerms",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                      }
        }       
 
        async function PopulateQueryTermsList(SBSMNewsQueryTerms) {
          try {

              setErrorMessage("") 


              var SBSMNewsQueryTermsJSON = {SBSMNewsQueryTerms: [{id:"none", label: "none"}]}


              if (SBSMNewsQueryTerms !== undefined) {

                const arr = Object.entries(SBSMNewsQueryTerms);


                arr.forEach(QueryTerm => 
                  {
                    let vItemAlreadyExists = false                      

                    SBSMNewsQueryTermsJSON.SBSMNewsQueryTerms.forEach(pt => 
                        {                             
                                          
                          if (pt.label === QueryTerm[1].QueryTerm) {                      
                            vItemAlreadyExists = true;
                          }
                        } 
                      )     
                      
                      

                    if(vItemAlreadyExists === false) {

                        let NewQueryTerm = {
                          id: QueryTerm[1].QueryTerm,
                          label: QueryTerm[1].QueryTerm
                        }   

                        SBSMNewsQueryTermsJSON.SBSMNewsQueryTerms.push(NewQueryTerm);

                      }
                })

                setQueryTerm(SBSMNewsQueryTermsJSON.SBSMNewsQueryTerms)

              }


              return SBSMNewsQueryTermsJSON

            } catch (err) { 

              RhyteItLogging(Users, "SBSMQueries", "PopulateQueryTermsList",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          }        
        } 

        function DeleteAll() {
          try {

            if (debugConsoleOutPut === true) {
                //console.log("SBSMQueries.DeleteAllSchedules.SalesMonitors:  ",SalesMonitors) 
              }
              SBSMNewsQueryTerms.forEach(QueryTerm => 
                {


                  const QueryTermRecord = {
                    id: SBSMNewsQueryTerms.id,
                    _version: SBSMNewsQueryTerms._version
    
                  }    
          
                  const ReturnMessage = client.graphql({
                    query: deleteSBSMNewsQueryTerms,
                    variables: {
                      input: QueryTermRecord
                    }
                  });  


                        
                        var elementPos = SBSMNewsQueryTerms.map(function(x) {return x.id; }).indexOf(QueryTerm.id);
          
                        delete SBSMNewsQueryTerms[elementPos];
          
                      })  
                      
                      GetSBSMNewsQueryTerms() 
                      
                      ErrorMessageStr = "Term deleted successfully"
                      setErrorMessage(ErrorMessageStr)         
                
          } catch (err) { 

          ErrorMessageStr = "SBSMQueries.DeleteAll.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }        
        }   
                  
        function AddQueryTerm(event) {
            try {              
    
                var DynamoUUID =  get_uuidv4()
    
                var vUpdateDateTime = new Date().toISOString()
    
    
                let NewProfile = {
                      id: DynamoUUID,
                      UserId: Users,
                      QueryTerm: '',
                      QueryTermStatus: 'NEW',     
                      CreationName: Users,
                      UpdateName: Users,
                      CreationDateTime: vUpdateDateTime,
                      UpdateDateTime: vUpdateDateTime
                }    

   

              let vArraySpot = Object.keys(SBSMNewsQueryTerms).length

              SBSMNewsQueryTerms[vArraySpot] = NewProfile;
 
    
              setSBSMNewsQueryTerms(SBSMNewsQueryTerms)       
              setupdatedKey(Date.now())

              } catch (err) { 
                RhyteItLogging(Users, "SBSMQueries", "AddQueryTerm",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
            }        
        }   
        
        function onChangeAutocomplete(event) {
          setSearchTerm1(event.target.value);
        }

        async function onSelectAutocomplete(option) {

          
          //console.log("SBSMQueries.onSelectAutocomplete.option: ",option)  

          const { label } = option;

          setSearchTerm1(label);          


          try {

            setErrorMessage("") 

            let QueryTermsFiltered = ""

            let fetchSBSMNewsQueryTermsResponse = await GetSBSMNewsQueryTerms();
            //console.log("SBSMQueries.onSelectAutocomplete.fetchSBSMNewsQueryTermsResponse:  ",fetchSBSMNewsQueryTermsResponse)
            //console.log("SBSMQueries.onSelectAutocomplete.SBSMNewsQueryTerms.TypeOf:  ", typeof SBSMNewsQueryTerms)
            //console.log("SBSMQueries.onSelectAutocomplete.Array.isArray(SBSMNewsQueryTerms):  ", Array.isArray(SBSMNewsQueryTerms))

           //QueryTermsFiltered = await [...fetchSBSMNewsQueryTermsResponse].filter(SBSMQueryTerm => SBSMQueryTerm.QueryTerm ===  label)          
           
            QueryTermsFiltered = Object.values(fetchSBSMNewsQueryTermsResponse).filter(SBSMQueryTerm => SBSMQueryTerm.QueryTerm ===  label)  

            const entries = Object.entries(QueryTermsFiltered);
            
            //console.log("SBSMQueries.onSelectAutocomplete.entries:  ",entries)
            //console.log("SBSMQueries.onSelectAutocomplete.entries[0][1]:  ",entries[0][1])
            var JsonObject = JSON.parse(JSON.stringify(entries[0][1]));
            //console.log("SBSMQueries.onSelectAutocomplete.JsonObject:  ",JsonObject)
            //console.log("SBSMQueries.onSelectAutocomplete.[JsonObject]:  ",[JsonObject])
            setSBSMNewsQueryTerms([JsonObject])
            setupdatedKey(Date.now())


          } catch (err) { 


          RhyteItLogging(Users, "SBSMQueries", "onSelectAutocomplete",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
        }            
        }        

        function onClearAutocomplete() {
          setSearchTerm1("");
        }                
        

    return ( 
    <m.div
      key="SalesMonitor"
      initial={{y: "100%"}}
      animate={{y: "0%"}}
      transition={{duration:0.75, ease: "easeOut"}}
      exit={{ opacity: 1 }}
     >
      <Authenticator  className={styles.AuthenticatorLogin} socialProviders={[]} hideSignUp={true}>
        {({ signOut, user }) => (   
          <div className={styles.SystemAdminBackground}>
            <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                >   
                <Flex
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  alignContent="flex-start"
                  wrap="wrap"
                  gap="1rem"
                  className={styles.GridContainer}
                >        
                      <Header Title="SBSM Queries" SubTitle="Query Google News for the following terms" BreadCrumbItems ={vBreadCrumbItems} pageSource="SBSMQueries"/>  
                      <div className={styles.ToolBar}>                        
                        <Flex
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                alignContent="center"
                                wrap="nowrap"
                                gap="3rem"
                                className={styles.ToolBarRow}
                              >                            
                                <div className={styles.ToolBarItem}>
                                  <Button
                                    loadingText=""
                                    ariaLabel=""
                                    className={styles.RedditTitleButtons}
                                    onClick={ (e) => AddQueryTerm(e)}
                                  >
                                  Add Query Term
                                  </Button>                                    
                                </div>   
                                <div className={styles.ToolBarItem}>
                                  <Button
                                    loadingText=""
                                    ariaLabel=""
                                    className={styles.RedditTitleButtons}
                                    onClick={ (e) => GetSBSMNewsQueryTerms(e)}
                                  >
                                  Refresh Data
                                  </Button>                                    
                                </div>      
                                <div  className={styles.ToolBarItem}>
                                  <Autocomplete                                  
                                      label="Autocomplete"
                                      options={QueryTerm}
                                      placeholder="Find query term"
                                      value={SearchTerm1}
                                      onChange={onChangeAutocomplete}
                                      onClear={onClearAutocomplete}
                                      onSelect={onSelectAutocomplete}
                                      onSubmit={onSelectAutocomplete}   
                                      className={styles.amplifyautocomplete}    
                                  />
                                </div>                                                                                                                          
                                                                                                                                                                                                                                                             
                          </Flex>           
                              <div className={styles.TableRowData}>                               
                                <QueryTermMessageBox ErrorMessage={ErrorMessage}>{ErrorMessage}</QueryTermMessageBox>  
                              </div> 
                      </div>                        
                      {ShowTable && SBSMNewsQueryTerms !== undefined && (<SBSMQueryTermTable  key={updatedKey} SBSMNewsQueryTerms={SBSMNewsQueryTerms}/>)  }
                </Flex>     
            </ErrorBoundary>
          </div>   
        )}         
      </Authenticator>  
    </m.div>                      

    )

  }
 

export default SBSMQueries;