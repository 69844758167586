
import React, { useState , useEffect, useContext,useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Text,
  TableBody,
  Table,
  TableHead,
  Image,
  CheckboxField
  } from '@aws-amplify/ui-react';
  import styles from './SMMADTable.module.css';  
  
import { generateClient } from 'aws-amplify/api';
import  { listSMMADContents }  from '../../../graphql/queries'
import  SCMContentRow  from './SCMContentRow'
  

const SMMADTable = (props) => {       
  

  SMMADTable.propTypes = {
      AccountSelected: PropTypes.string,
      Report: PropTypes.string
      };


      const [ErrorMessage, setErrorMessage] = useState("") 

      const [vContentData, setvContentData] = useState()
      const [vContentSortedData, setvContentSortedData] = useState()
      
      const [RecordsReturned, setRecordsReturned] = useState(0)    
      const [nextToken, setnextToken] = useState(undefined) 
      const [IsLoading, setIsLoading] = useState(false)
      const [vAccountSelected, setvAccountSelected] = useState(props.AccountSelected)
      const client = generateClient();
      

      useEffect(() => {


        //console.log("SMMADTable.useEffect[].props.Report: ",props.Report); 
        (async function() {
              try {      

              
              //console.log("SMMADTable.useEffect[].props.AccountSelected: ",props.AccountSelected); 
              //console.log("SMMADTable.useEffect[].props.Report: ",props.Report); 
              GetContent()
          
              } catch (err) {
                console.error('PageViewMap.useEffect[].ERROR: ', err, err.stack); 
            }
          }
        )();           


      },[]) 

      useEffect(() => {

        const fetch1 = async () => {


          try {

                if(nextToken !== null) {
                  setIsLoading(true)

                    var datetime = new Date();                                                
                    let limit = 10000000
                    //console.log("SMMADTable.useEffect[NextToken].nextToken: ", nextToken);   
                    
                    let  variables = {
                      nextToken,
                      filter: {
                        Account: {
                                eq: vAccountSelected
                            },
                        PostedCount: {
                                gt: 0
                            },
                        Weight: {
                                ne: 0
                          },                            
                  }
                    };   
                    
                    const ContentData2  = await client.graphql({
                      query: listSMMADContents,
                      variables: variables
                    });
      
          
          
                  //console.log("Content.GetContent.ContentData2.data.listSMMADContents.nextToken:  ",ContentData2.data.listSMMADContents.nextToken)

                  let ContentData = vContentData                   
                        
        
                  ContentData2.data.listSMMADContents.items.forEach(item => 
                    {   
                  
                      if ([...ContentData].filter(Content => item.id === Content.id)["length"] === 0) {  
                        ContentData.push(item) 
                      }

                    })


                      //console.log("SMMADTable.useEffect[NextToken].elseif.SMMADTableRecordsData2.data.listEmailAccountStats.nextToken: ", SMMADTableRecordsData2.data.listEmailAccountStats.nextToken);   
                      //console.log("SMMADTable.useEffect[NextToken].elseif.SMMADTableRecordsData2.length: ", SMMADTableRecordsData2.data.listEmailAccountStats.items.length);  
                      //console.log("SMMADTable.useEffect[NextToken].elseif.SMMADTableRecordsData2.items: ", SMMADTableRecordsData2.data.listEmailAccountStats.items);  

                      setvContentData(ContentData)  
                      setnextToken(ContentData2.data.listSMMADContents.nextToken)  

                                          

                    
                } else if (nextToken === null){          
                  
                 
                  
                  setIsLoading(false)
  
                }

          } catch (err) {
            //console.log(err)
          } finally {
            setIsLoading(false)
          }
        }
      
        fetch1()

      }, [nextToken])         
      
      useEffect(() => {

        

        if(vContentData !== undefined && nextToken === null && IsLoading === false) {
          //console.log("SMMADTable.useEffect[IsLoading].len(vContentData): ", vContentData.length, " - nextToken: ", nextToken, " - IsLoading: ", IsLoading);   
          let vSorted = SortContentsByWeight(vContentData)
          setvContentSortedData(vSorted)  
        }

      },[IsLoading])       

      useEffect(() => {

        

        if(vContentSortedData !== undefined) {
          //console.log("SMMADTable.useEffect[vContentSortedData].len(vContentSortedData): ", vContentSortedData.length);  
          //console.log("SMMADTable.useEffect[vContentSortedData].vContentSortedData: ", vContentSortedData);  
        }

      },[vContentSortedData])   

      async function GetContent() {
        try {
                  setErrorMessage("")
                  setvContentData([])
                  
               //console.log("SMMADTable.GetContent.vAccountSelected:  ",vAccountSelected)
               //console.log("Content.GetContent.vCampaignSelected:  ",vCampaignSelected)
                 

                 let  variables = {
                  filter: {
                    Account: {
                            eq: vAccountSelected
                        },
                    PostedCount: {
                            gt: 0
                        }     
                    }
                  };

                  const ContentData2  = await client.graphql({
                    query: listSMMADContents,
                    variables: variables
                  });
    
        
                  //console.log("SMMADTable.GetContent.ContentData2:  ",ContentData2)

                  if (ContentData2.data.listSMMADContents.items.length > 0) {
                      const Content1 = ContentData2.data.listSMMADContents.items 

                      setvContentData(Content1)  
                      
                      setnextToken(ContentData2.data.listSMMADContents.nextToken)  
         
                }

        } catch (err) { 
                        console.error('Content.GetContent.ERROR: ', err, err.stack); 
                    }
      }   

      function SortContentsByWeight(pContentData) {
        pContentData.sort(WeightSortFunction);
        return pContentData;
        //    //console.log(JSON.stringify(pEmails));
      }
    
      function WeightSortFunction(a, b) {
        
        //console.log("SMMADTable.WeightSortFunction.a: ",a.Weight, " < b: ",b.Weight);  
        let vWeightA = a.Weight;
        let vWeightB = b.Weight;
        let vReturnOrder = vWeightA < vWeightB ? 1 : -1;
        //console.log("SMMADTable.WeightSortFunction.vWeightA: ",vWeightA, " < vWeightB: ",vWeightB, " = vReturnOrder: ",vReturnOrder);  
        return vReturnOrder;
      }
    

  return (
      <div>
                        <div className={styles.MessageBox}>{ErrorMessage} Records retrieved: {RecordsReturned}</div>  
                        <div className={styles.ToolBar}> 
                        
                        {vContentData !== undefined && (                            
                            <Table className={styles.Table}> 
                            <TableHead>          
                              <TableRow className={styles.TableHeaderRow}>
                                <TableCell className={styles.TableHeaderRowCell} >Image</TableCell> 
                                <TableCell className={styles.TableHeaderRowCell} ># Posts</TableCell> 
                                <TableCell className={styles.TableHeaderRowCell} >Weight</TableCell> 
                                <TableCell className={styles.TableHeaderRowCell}>Active</TableCell> 
                              </TableRow>                                      
                            </TableHead>                               
                              <TableBody>
                              {
                                    vContentSortedData && vContentSortedData.map((vContent) => (         
                                      <SCMContentRow    key={vContent.id} SMMADSCMContentRow={vContent} />
                                  ))
                              }                                                                               
                            </TableBody>
                          </Table>   
                          )}   
                         
                        </div>  
      </div>
      
  )
}

export default SMMADTable;

