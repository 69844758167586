import PropTypes from "prop-types";
import React, { useState, useEffect, useContext, useRef } from "react";

import {
  listNewsletters,
  listSMMADAccounts,
  listSMMADAccountGroups,
} from "../../../graphql/queries";
import {
  createNewsletter,updateNewsletter
} from "../../../graphql/mutations";
import { generateClient } from "aws-amplify/api";

import Header from "../../RhyteItComponents/Header/Header";
import {
  UserContext,
  CognitoGroupContext,
} from "../../../BusinessLogic/Hooks/UserContext";
import {
  GetGroupRoleAccess,
  RhyteItLogging,
  ReturnUserToLandingPage,
} from "../../../BusinessLogic/UserManagement";
import { fetchAuthSession } from "aws-amplify/auth";
import { motion as m } from "framer-motion";
import MenuBackground from "../../Menu/MenuBackground";
import { get_uuidv4 } from "../../../BusinessLogic/uuidv4";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Fieldset,
  SelectField,
  Flex,
  Text,
  TextField,
  Card,
  Badge,
  Image,
  Heading,
  Link,
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  TextAreaField
} from "@aws-amplify/ui-react";
import styles from "./NewsletterManager.module.css";

const NewsletterManager = (props) => {
  NewsletterManager.propTypes = {
    RedditUser: PropTypes.object,
  };

  const client = generateClient();
  const [vNewsletterManager, setvNewsletterManager] = useState([]);
  const navigate = useNavigate();
  

  const vBreadCrumbItems = [
    { Path: "/", Title: "Home", isCurrent: false },
    { Path: "/MainMenuV2", Title: "Menu", isCurrent: false },
    { Path: "/NewsletterManager", Title: "NewsletterManager", isCurrent: true },
  ];

  var ErrorMessageStr = "";
  const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr);

  const { Users, setUsers } = useContext(UserContext);
  const { CognitoGroup, setCognitoGroup } = useContext(CognitoGroupContext);

  const [vCampaignsActive, setvCampaignsActive] = useState(false);
  const [vAccountType, setvAccountType] = useState(false);

  const [updatedKey, setupdatedKey] = useState();
  const [vViewPostingConfig, setvViewPostingConfig] = useState(false);
  
  const [vAccountGroup, setvAccountGroup] = useState();
  
  const [vAccountGroupSelected, setvAccountGroupSelected] = useState({"AccountGroup":"None"});
  let [vAccountGroupList, setvAccountGroupList] = useState();

  const [vNewsletterSelected, setvNewsletterSelected] = useState({"Newsletter":"None"});
  let [vNewsletterList, setvNewsletterList] = useState();  
  const [vAddNewletterVisible, setvAddNewletterVisible] = useState(false);
  const [vNewsletterVisible, setvNewsletterVisible] = useState(false);
  
  let [vNewNewsletter, setvNewNewsletter] = useState();
  let [vNewNewsletterDescription, setvNewNewsletterDescription] = useState();

  let [vViewNewsletterTemplateSection, setvViewNewsletterTemplateSection] = useState(false);
  let [vNewsletterTitle, setvNewsletterTitle] = useState();
  let [vNewsletterDescription, setvNewsletterDescription] = useState();
  let [vPixelTrackerAPIURL, setvPixelTrackerAPIURL] = useState();
  let [vNewsletterBannerURL, setvNewsletterBannerURL] = useState();
  let [vUnsubscriberURL, setvUnsubscriberURL] = useState();
  let [vCopyright, setvCopyright] = useState();
  let [vAddress, setvAddress] = useState();
  let [vPodcastBanner, setvPodcastBanner] = useState();
  let [vPodcastEmail, setvPodcastEmail] = useState();
  let [vNewsletterEmail, setvNewsletterEmail] = useState();
  let [vUnsubscribeEmail, setvUnsubscribeEmail] = useState();
  
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async function () {
      try {
        if (CognitoGroup !== undefined) {
          let GetGroupRoleAccessResponse = await GetGroupRoleAccess(
            CognitoGroup,
            "RhyteItSMMADUserGroup"
          );

          if (GetGroupRoleAccessResponse) {
            GetSMMADAccountGroups();
           
          }
        } else {
          const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};

          if (idToken !== undefined) {
            setUsers(idToken.payload.email);
            setCognitoGroup(accessToken["payload"]["cognito:groups"]);
          } else {
            ReturnUserToLandingPage(Users, CognitoGroup);
          }
        }
      } catch (err) {
        RhyteItLogging(
          Users,
          "NewsletterManager",
          "useEffect[CognitoGroup]",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    })();
  }, [CognitoGroup]);

  useEffect(() => {

    if (vAccountGroupSelected.AccountGroup !== "None") {
    //console.log("NewsletterManager.useEffect[vAccountGroupSelected].vAccountGroupSelected:  ",vAccountGroupSelected)
  
     GetNewsletters(vAccountGroupSelected.AccountGroup);
    }
  }, [vAccountGroupSelected]);
  
  useEffect(() => {

   //console.log("NewsletterManager.useEffect[vNewsletterSelected].vNewsletterSelected:  ",vNewsletterSelected)

   if (vNewsletterSelected["Newsletter"] !== "None") {
    setvNewsletterTitle(vNewsletterSelected["Newsletter"])

   if ("Description" in vNewsletterSelected) {
    setvNewsletterDescription(vNewsletterSelected["Description"])
   }

   if ("TemplateJSON" in vNewsletterSelected) {
    const vTemplateJSON = JSON.parse(vNewsletterSelected["TemplateJSON"]);  
    
    //console.log("NewsletterManager.useEffect[vNewsletterSelected].vTemplateJSON:  ",vTemplateJSON)

    if ("PixelTrackerAPIURL" in vTemplateJSON) {
      setvPixelTrackerAPIURL(vTemplateJSON["PixelTrackerAPIURL"])
    }
    
    if ("NewsletterBannerURL" in vTemplateJSON) {
      setvNewsletterBannerURL(vTemplateJSON["NewsletterBannerURL"])
    }

    if ("UnsubscriberURL" in vTemplateJSON) {
      setvUnsubscriberURL(vTemplateJSON["UnsubscriberURL"])
    }

    if ("Copyright" in vTemplateJSON) {
      setvCopyright(vTemplateJSON["Copyright"])
    }

    if ("Address" in vTemplateJSON) {
      setvAddress(vTemplateJSON["Address"])
    }

    if ("PodcastBanner" in vTemplateJSON) {
      setvPodcastBanner(vTemplateJSON["PodcastBanner"])
    }

    if ("PodcastEmail" in vTemplateJSON) {
      setvPodcastEmail(vTemplateJSON["PodcastEmail"])
    }
    
    if ("NewsletterEmail" in vTemplateJSON) {
      setvNewsletterEmail(vTemplateJSON["NewsletterEmail"])
    }

    if ("UnsubscribeEmail" in vTemplateJSON) {
      setvUnsubscribeEmail(vTemplateJSON["UnsubscribeEmail"])
    }
  }
    
  }

   

  }, [vNewsletterSelected]);

  async function GetNewsletters(pAccountGroup) {
    try {
      setErrorMessage("");
      
      //console.log("NewsletterManager.GetNewsletters.Users: ", Users);
      let variables;
      if (Users !== "edwardhawke@rhyteit.com") {
        variables = {
          filter: {
            AccountGroup: {
              eq: pAccountGroup,
            },
          },
        };
      } else {
        variables = {};
      }

      const listNewslettersData2 = await client.graphql({
        query: listNewsletters,
        variables: variables,
      });

      //console.log("NewsletterManager.GetNewsletters.listNewsletters.data.listNewsletters.items.length : ", listNewslettersData2.data.listNewsletters.items.length );

      if (listNewslettersData2.data.listNewsletters.items.length > 0) {
        const NewsletterManager1 =
          listNewslettersData2.data.listNewsletters.items;
          SortNewsletterByName(NewsletterManager1)
          setvNewsletterList(NewsletterManager1);
      } 



    } catch (err) {
      RhyteItLogging(
        Users,
        "NewsletterManager",
        "GetNewsletters",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  async function GetSMMADAccountGroups() {
    try {
      setErrorMessage("");
      
      //console.log("NewsletterManager.GetNewsletters.Users: ", Users);
      let variables;
      if (Users !== "edwardhawke@rhyteit.com") {
        variables = {
          filter: {
            ParentAccount: {
              eq: Users,
            },
          },
        };
      } else {
        variables = {};
      }

      const listSMMADAccountGroupsData2 = await client.graphql({
        query: listSMMADAccountGroups,
        variables: variables,
      });

      //console.log("NewsletterManager.GetSMMADAccountGroups.listSMMADAccountGroupsData2.data.listSMMADAccountGroups.items.length : ", listSMMADAccountGroupsData2.data.listSMMADAccountGroups.items.length );

      if (listSMMADAccountGroupsData2.data.listSMMADAccountGroups.items.length > 0) {
        const NewsletterManager1 =
          listSMMADAccountGroupsData2.data.listSMMADAccountGroups.items;

          setvAccountGroupList(NewsletterManager1);
      } 



    } catch (err) {
      RhyteItLogging(
        Users,
        "NewsletterManager",
        "GetSMMADAccountGroups",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function SortNewsletterByName(pNewsletter) {
    pNewsletter.sort(GFG_sortFunction);
    return pNewsletter;
    //    //console.log(JSON.stringify(pEmails));
  }

  function GFG_sortFunction(a, b) {
    let NewsletterA = a.Newsletter;
    let NewsletterB = b.Newsletter;
    return NewsletterA > NewsletterB ? 1 : -1;
  }

  async function CreateNewletter() {
    try {
      //console.log("NewsletterManagerTable.CreateNewletter.vNewNewsletter:  ",vNewNewsletter);

      var vUpdateDateTimeNew = new Date().toISOString();

        const vTemplateJSON = {
            id: vNewNewsletter,
            Description: "",
            PixelTrackerAPIURL: "",
            NewsletterBannerURL: "",
            Sections: ["In This Issue", "Featured Article", "Quick Tips", "Success Story", "Podcast", "Introduction", "Footer"],
            UnsubscriberURL: "",
            Copyright: "",
            Address: "",
            PodcastBanner: "",
            PodcastEmail: "",
            NewsletterEmail: "",
            UnsubscribeEmail: ""
        };

        const vTemplateJSONString = JSON.stringify(vTemplateJSON);      

      let NewletterItem = {
        id: vNewNewsletter,
        Newsletter: vNewNewsletter,
        AccountGroup: vAccountGroupSelected["AccountGroup"],
        NewsletterDate: vUpdateDateTimeNew,
        User: vAccountGroupSelected["ParentAccount"],
        TemplateJSON: vTemplateJSONString,
        Edition: "0",
        Content: "",
        S3Bucket: "",
        S3Key: "",
        NewsletterStatus: "new",
        Active: true,
        CreationName: Users,
        CreationDateTime: vUpdateDateTimeNew,
        UpdateName: Users,
        UpdateDateTime: vUpdateDateTimeNew,
      };

      //console.log("NewsletterManagerTable.SaveRow.CampaignItem:  ",CampaignItem);

      const createNewsletterResponse = await client.graphql({
        query: createNewsletter,
        variables: {
          input: NewletterItem,
        },
      });
      setvAddNewletterVisible(false);
      setvNewNewsletter("");
      GetNewsletters();
    } catch (err) {
      RhyteItLogging(
        Users,
        "NewsletterManager",
        "CreateNewletter",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  async function UpdateNewsletter() {
    try {
      
      //console.log("NewsletterManager.UpdateNewsletter.vNewsletterSelected:  ",vNewsletterSelected);
      //console.log("NewsletterManager.UpdateNewsletter.vNewsletterTitle:  ",vNewsletterTitle);

      var vUpdateDateTimeNew = new Date().toISOString();

      vNewsletterSelected["Newsletter"] = vNewsletterTitle
      vNewsletterSelected["Description"] = vNewsletterDescription

      const vTemplateJSON = {
        id: vNewsletterSelected["id"],
        PixelTrackerAPIURL: vPixelTrackerAPIURL,
        NewsletterBannerURL: vNewsletterBannerURL,
        Sections: ["In This Issue", "Featured Article", "Quick Tips", "Success Story", "Podcast", "Introduction", "Footer"],
        UnsubscriberURL: vUnsubscriberURL,
        Copyright: vCopyright,
        Address: vAddress,
        PodcastBanner: vPodcastBanner,
        PodcastEmail: vPodcastEmail,
        NewsletterEmail: vNewsletterEmail,
        UnsubscribeEmail: vUnsubscribeEmail,
      };

      const vTemplateJSONString = JSON.stringify(vTemplateJSON);     

      let NewletterItem = {
        id: vNewsletterSelected["id"],
        Newsletter: vNewsletterSelected["Newsletter"],
        Description: vNewsletterSelected["Description"],
        User: vNewsletterSelected["User"],
        TemplateJSON: vTemplateJSONString,
        AccountGroup: vNewsletterSelected["AccountGroup"],
        NewsletterDate: vNewsletterSelected["NewsletterDate"],
        Edition:vNewsletterSelected["Edition"],
        Content: vNewsletterSelected["Content"],
        S3Bucket: vNewsletterSelected["S3Bucket"],
        S3Key: vNewsletterSelected["S3Key"],
        SendCount: vNewsletterSelected["SendCount"],
        OpenCount: vNewsletterSelected["OpenCount"],
        ClickCount: vNewsletterSelected["ClickCount"],
        NewsletterStatus: "Updated template values",
        Active:vNewsletterSelected["Active"],
        CreationName: vNewsletterSelected["CreationName"],
        CreationDateTime: vNewsletterSelected["CreationDateTime"],
        UpdateName: Users,
        UpdateDateTime: vUpdateDateTimeNew,
      };

      //console.log("NewsletterManagerTable.SaveRow.CampaignItem:  ",NewletterItem);

      const updateNewsletterResponse = await client.graphql({
        query: updateNewsletter,
        variables: {
          input: NewletterItem,
        },
      });

      
      //console.log("NewsletterManager.UpdateNewsletter.updateNewsletterResponse:  ",updateNewsletterResponse);

      setvNewsletterSelected(updateNewsletterResponse.data.updateNewsletter)

    } catch (err) {
      RhyteItLogging(
        Users,
        "NewsletterManager",
        "UpdateNewsletter",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }


  async function NewsletterSelectedAction(pNewsletter) {
    try {
      //console.log("NewsletterManager.NewsletterSelectedAction.pNewsletter: ", pNewsletter);
      let vSelectedNewsletter = vNewsletterList.filter(
        (vNewletterItem) => vNewletterItem["Newsletter"] === pNewsletter
      );

      //console.log("NewsletterManager.NewsletterSelectedAction.vSelectedNewsletter: ",vSelectedNewsletter);
      //console.log("NewsletterManager.NewsletterSelectedAction.vSelectedNewsletter[0]: ",vSelectedNewsletter[0]);
      setvNewsletterVisible(true);
      //setvNewsletterSelected(pAccount);
      setvNewsletterSelected(vSelectedNewsletter[0]);
    } catch (err) {
      RhyteItLogging(
        Users,
        "NewsletterManager",
        "NewsletterSelectedAction",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  async function AccountGroupSelectedAction(pAccountGroup) {
    try {
      //    //console.log("NewsletterManager.NewsletterSelectedAction.pAccount: ", pAccount);
      let vSelectedNewsletterGroup = vAccountGroupList.filter(
        (vAccountGroupItem) => vAccountGroupItem["AccountGroup"] === pAccountGroup
      );

      //console.log("NewsletterManager.AccountGroupSelectedAction.vSelectedNewsletterGroup: ",vSelectedNewsletterGroup);
      //console.log("NewsletterManager.NewsletterSelectedAction.vSelectedNewsletter[0]: ",vSelectedNewsletter[0]);
      setvNewsletterVisible(true);
      //setvNewsletterSelected(pAccount);
      setvAccountGroupSelected(vSelectedNewsletterGroup[0]);
    } catch (err) {
      RhyteItLogging(
        Users,
        "NewsletterManager",
        "AccountGroupSelectedAction",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  async function ViewConfig(event) {
    try {
      //console.log("NewsletterManager.ViewConfig.event: ", event);
      //console.log("NewsletterManager.ViewConfig.vViewPostingConfig: ", vViewPostingConfig);
      setvAddNewletterVisible(!vAddNewletterVisible)


    } catch (err) {
      RhyteItLogging(
        Users,
        "NewsletterManager",
        "ViewConfig",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  const handleNavigateToNewsletterSubscribers = () => {
    navigate("/NewsletterSubscribers", { state: { NewsletterSelected: vNewsletterSelected }});
  };

  const handleNavigateToNewsletterEditor = () => {
   // //console.log("NewsletterManager.handleNavigateToNewsletterEditor.vNewsletterSelected: ", vNewsletterSelected);
    navigate("/NewsletterEditor", { state: { NewsletterSelected: vNewsletterSelected }});
  };

  const handleNavigateToNewsletterPublisher = () => {
    // //console.log("NewsletterManager.handleNavigateToNewsletterEditor.vNewsletterSelected: ", vNewsletterSelected);
     navigate("/NewsletterPublisher", { state: { NewsletterSelected: vNewsletterSelected }});
   };
 

  const handleNavigateToNewsletterTemplate = () => {

    let vViewNewsletterTemplateSectionTmp = !vViewNewsletterTemplateSection

    // //console.log("NewsletterManager.handleNavigateToNewsletterEditor.vNewsletterSelected: ", vNewsletterSelected);
    setvViewNewsletterTemplateSection(vViewNewsletterTemplateSectionTmp)
   };  
  
   function NewNewsletter_handleChange(event, data) {
    try {
      setvNewNewsletter(event.target.value);
    } catch (err) {
      RhyteItLogging(
        Users,
        "NewsletterManager",
        "NewNewsletter_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function Newsletter_handleChange(event, data) {
    try {
      setvNewsletterTitle(event.target.value);
    } catch (err) {
      RhyteItLogging(
        Users,
        "NewsletterManager",
        "Newsletter_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function NewsletterDescription_handleChange(event, data) {
    try {
      setvNewsletterDescription(event.target.value);
    } catch (err) {
      RhyteItLogging(
        Users,
        "NewsletterManager",
        "NewsletterDescription_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function PixelTrackerAPIURL_handleChange(event, data) {
    try {
      setvPixelTrackerAPIURL(event.target.value);
    } catch (err) {
      RhyteItLogging(
        Users,
        "NewsletterManager",
        "PixelTrackerAPIURL_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function UnsubscriberURL_handleChange(event, data) {
    try {
      setvUnsubscriberURL(event.target.value);
    } catch (err) {
      RhyteItLogging(
        Users,
        "NewsletterManager",
        "UnsubscriberURL_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function NewsletterBannerURL_handleChange(event, data) {
    try {
      setvNewsletterBannerURL(event.target.value);
    } catch (err) {
      RhyteItLogging(
        Users,
        "NewsletterManager",
        "NewsletterBannerURL_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }
  
  function Copyright_handleChange(event, data) {
    try {
      setvCopyright(event.target.value);
    } catch (err) {
      RhyteItLogging(
        Users,
        "NewsletterManager",
        "Copyright_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function Address_handleChange(event, data) {
    try {
      setvAddress(event.target.value);
    } catch (err) {
      RhyteItLogging(
        Users,
        "NewsletterManager",
        "Address_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function PodcastEmail_handleChange(event, data) {
    try {
      setvPodcastEmail(event.target.value);
    } catch (err) {
      RhyteItLogging(
        Users,
        "NewsletterManager",
        "PodcastEmail_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }
  function NewsletterEmail_handleChange(event, data) {
    try {
      setvNewsletterEmail(event.target.value);
    } catch (err) {
      RhyteItLogging(
        Users,
        "NewsletterManager",
        "NewsletterEmail_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }
  function UnsubscribeEmail_handleChange(event, data) {
    try {
      setvUnsubscribeEmail(event.target.value);
    } catch (err) {
      RhyteItLogging(
        Users,
        "NewsletterManager",
        "UnsubscribeEmail_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }
  
  
  function PodcastBanner_handleChange(event, data) {
    try {
      setvPodcastBanner(event.target.value);
    } catch (err) {
      RhyteItLogging(
        Users,
        "NewsletterManager",
        "PodcastBanner_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  return (
    <m.div
      key="Search"
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      exit={{ opacity: 1 }}
    >
      <MenuBackground
        className={styles.MenuBackground}
        GradientStopColor1="#0000"
        GradientStopColor2="#000"
        GradientStopColor3="#00CCCC"
        GradientStopColor4="#8880"
      />

      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="center"
        wrap="nowrap"
        gap="1rem"
        className={styles.FlexColumn}
      >
        <Header
          key={Date.now()}
          Title="Newsletter Manager"
          SubTitle="Manage your newsletter"
          BreadCrumbItems={vBreadCrumbItems}
          pageSource="NewsletterManager"
        />
        {vAccountGroupList !== undefined &&  (
        <Fieldset className={styles.Fieldset} size="small">
          <legend className={styles.legend}>Account Group</legend>
          <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
          >
          <SelectField
            className={styles.SelectField}
            value={vAccountGroupSelected.AccountGroup}
            placeholder="Select account"
            onChange={(e) => AccountGroupSelectedAction(e.target.value)}
          >
            {vAccountGroupList !== undefined &&
              vAccountGroupList.map((AccountGroup) => (
                <option
                  key={AccountGroup.AccountGroup}
                  className={styles.SelectFieldOption}
                  value={AccountGroup.AccountGroup}
                  label={AccountGroup.AccountGroup}
                >
                  {AccountGroup.AccountGroup}
                </option>
              ))}
          </SelectField>  
          {vAccountGroupSelected["AccountGroup"] !== "None" &&  (
                      <Flex
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      alignContent="flex-start"
                      wrap="nowrap"
                      gap="0.25rem"
                    >
              <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              gap="1rem"
              className={styles.FlexRow}
            >
              <Text  className={styles.AccountGroup}>{vAccountGroupSelected.AccountGroup}</Text>  
            </Flex> 
              <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              gap="1rem"
              className={styles.FlexRow}
            >
              <Text  className={styles.AccountGroupLabel}>Parent Account:</Text> 
              <Text  className={styles.AccountGroup}>{vAccountGroupSelected.ParentAccount}</Text>
              <Text  className={styles.AccountGroupLabel}>Sync On:</Text> 
              <Text  className={styles.AccountGroup}>{vAccountGroupSelected.SyncAccounts.toString()}</Text>
            </Flex> 
            </Flex>              
          )}
          </Flex>         
        </Fieldset>        
        )}   
        {vAccountGroupSelected["AccountGroup"] !== "None" &&  (
        <Fieldset className={styles.Fieldset} size="small">
          <legend className={styles.legend}>Newsletters</legend>
          <Flex
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            alignContent="center"
            wrap="nowrap"
            gap="3rem"
          >
            <SelectField
              className={styles.SelectField}
              value={vNewsletterSelected.Account}
              placeholder="Select Newsletter"
              onChange={(e) => NewsletterSelectedAction(e.target.value)}
            >
              {vNewsletterList !== undefined &&
                vNewsletterList.map((Newsletter) => (
                  <option
                    key={Newsletter.Newsletter}
                    className={styles.SelectFieldOption}
                    value={Newsletter.Newsletter}
                    label={Newsletter.Newsletter}
                  >
                    {Newsletter.Newsletter}
                  </option>
                ))}
            </SelectField>
            <Button
                className={styles.Button}
                variation="primary"
                onClick={(e) => ViewConfig(e)}
              >
                Add Newsletter
              </Button>            
          </Flex>
          {vAddNewletterVisible === true && (
            <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              gap="3rem"
              className={styles.NewNewsletterRow}
            >
              <TextField
                className={styles.CampaignField}
                name="Account"
                placeholder="Add new Newsletter"
                defaultValue={vNewNewsletter}
                onChange={(e) => NewNewsletter_handleChange(e, vNewNewsletter)}
              />
              <Button
                className={styles.Button}
                variation="primary"
                onClick={(e) => CreateNewletter(e)}
              >
                Create Newsletter
              </Button>
            </Flex>
          )}
          {vNewsletterSelected["Newsletter"] !== "None" &&  (
            <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              gap="3rem"
              className={styles.NewNewsletterRow}
            >
              <Card 
                className={styles.Card}
                onClick={handleNavigateToNewsletterTemplate}>
                  <Flex
                    direction="column"
                    gap="1rem"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                  >
                    <Text className={styles.Heading1}>
                    Template
                    </Text>
                    <Text  className={styles.Heading2}>
                    Edit Newsletter Template
                    </Text>
                </Flex>
              </Card>               
              <Card 
                className={styles.Card}
                onClick={handleNavigateToNewsletterSubscribers}>
                  <Flex
                    direction="column"
                    gap="1rem"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                  >
                    <Text className={styles.Heading1}>
                      Subscribers
                    </Text>
                    <Text  className={styles.Heading2}>
                    Manage newsletter subscribers
                    </Text>
                </Flex>
              </Card>           
              <Card 
                className={styles.Card}
                onClick={handleNavigateToNewsletterEditor}>
                  <Flex
                    direction="column"
                    gap="1rem"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                  >
                    <Text className={styles.Heading1}>
                      Editions
                    </Text>
                    <Text  className={styles.Heading2}>
                      Manage newsletter editions
                    </Text>
                </Flex>
              </Card>              
              <Card 
                onClick={handleNavigateToNewsletterPublisher}
                className={styles.Card}>
                  <Flex
                    direction="column"
                    gap="1rem"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                  >
                    <Text className={styles.Heading1}>
                      Publishing
                    </Text>
                    <Text  className={styles.Heading2}>
                      Publish and track email deliveries
                    </Text>
                </Flex>
              </Card>
            </Flex>
          )}          
        </Fieldset>             
        )}             
      </Flex>
      {vViewNewsletterTemplateSection === true &&  (
      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="center"
        wrap="nowrap"
        gap="1rem"
        className={styles.FlexColumn}
      >
          <Flex
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            alignContent="center"
            wrap="nowrap"
            gap="3rem"
            className={styles.NewNewsletterRow}
          >
              <Text className={styles.SectionHeader}>
                Edit Template
              </Text>  
              <Button
                    className={styles.Button}
                    variation="primary"
                    onClick={(e) => UpdateNewsletter(e)}
                  >
                    Save Values
              </Button>  
              <Text className={styles.SectionHeader}>                
                {new Date(vNewsletterSelected["UpdateDateTime"]).toISOString().slice(0, 10)} {new Date(vNewsletterSelected["UpdateDateTime"]).toISOString().slice(11, 16)}
              </Text>                          
          </Flex>      
          <Table className={styles.Table}   highlightOnHover={true} key={vNewsletterSelected.id}>   
          <TableHead>          
            <TableRow className={styles.TableHeaderRow}>
              <TableCell className={styles.TableHeaderRowCells} >Parameter</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Value</TableCell> 
            </TableRow>                                      
          </TableHead>       
          <TableBody>         
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
                Title
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextField
                className={styles.CampaignField}
                name="vNewsletterTitle"
                placeholder="Enter newsletter title"
                defaultValue={vNewsletterTitle}
                onChange={(e) => Newsletter_handleChange(e, vNewNewsletter)}
              />              
            </TableCell>                                          
          </TableRow>             
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
                Description
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextAreaField
                className={styles.TextAreaField}
                name="vNewsletterDescription"
                placeholder="Enter newsletter Description"
                defaultValue={vNewsletterDescription}
                rows={5}
                onChange={(e) => NewsletterDescription_handleChange(e, vNewNewsletter)}
              />              
            </TableCell>                                          
          </TableRow>         
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
              Pixel Tracker AP IURL
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextField
                className={styles.CampaignField}
                name="vPixelTrackerAPIURL"
                placeholder="Enter Pixel Tracker API URL"
                defaultValue={vPixelTrackerAPIURL}
                onChange={(e) => PixelTrackerAPIURL_handleChange(e, vPixelTrackerAPIURL)}
              />              
            </TableCell>                                          
          </TableRow>           
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
              Newsletter Banner URL
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextField
                className={styles.CampaignField}
                name="vNewsletterBannerURL"
                placeholder="Enter  Banner URL"
                defaultValue={vNewsletterBannerURL}
                onChange={(e) => NewsletterBannerURL_handleChange(e, vNewsletterBannerURL)}
              />              
            </TableCell>                                          
          </TableRow>           
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
              Unsubscribe URL
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextField
                className={styles.CampaignField}
                name="vUnsubscriberURL"
                placeholder="Enter Unsubscribe URL"
                defaultValue={vUnsubscriberURL}
                onChange={(e) => UnsubscriberURL_handleChange(e, vUnsubscriberURL)}
              />              
            </TableCell>                                          
          </TableRow>                
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
                Copyright
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextField
                className={styles.CampaignField}
                name="vCopyright"
                placeholder="Enter Copyright"
                defaultValue={vCopyright}
                onChange={(e) => Copyright_handleChange(e, vCopyright)}
              />              
            </TableCell>                                          
          </TableRow>                    
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
                Address
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextField
                className={styles.CampaignField}
                name="vAddress"
                placeholder="Enter Address"
                defaultValue={vAddress}
                onChange={(e) => Address_handleChange(e, vAddress)}
              />              
            </TableCell>                                          
          </TableRow>                     
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
                Podcast Banner
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextField
                className={styles.CampaignField}
                name="vPodcastBanner"
                placeholder="Enter Podcast Banner"
                defaultValue={vPodcastBanner}
                onChange={(e) => PodcastBanner_handleChange(e, vPodcastBanner)}
              />              
            </TableCell>                                          
          </TableRow>                      
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
                Podcast Email
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextField
                className={styles.CampaignField}
                name="vPodcastEmail"
                placeholder="Enter Podcast Email"
                defaultValue={vPodcastEmail}
                onChange={(e) => PodcastEmail_handleChange(e, vPodcastEmail)}
              />              
            </TableCell>                                          
          </TableRow>                       
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
                Newsletter Email
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextField
                className={styles.CampaignField}
                name="vNewsletterEmail"
                placeholder="Enter Newsletter Email"
                defaultValue={vNewsletterEmail}
                onChange={(e) => NewsletterEmail_handleChange(e, vNewsletterEmail)}
              />              
            </TableCell>                                          
          </TableRow>                           
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
                Unsubscribe Email
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextField
                className={styles.CampaignField}
                name="vUnsubscribeEmail"
                placeholder="Enter Unsubscribe Email"
                defaultValue={vUnsubscribeEmail}
                onChange={(e) => UnsubscribeEmail_handleChange(e, vUnsubscribeEmail)}
              />              
            </TableCell>                                          
          </TableRow>                    
          </TableBody>                    
        </Table>                        
      </Flex>         
        )}             
    </m.div>
  );
};

export default NewsletterManager;
