
import React, { useState, useEffect, useRef } from 'react'
//import isUrlHttp from 'is-url-http';
//import './CreateYourOwnPodcast.css'; 
import {
    TextField, 
    Text,
    CheckboxField,
    TextAreaField,
    Button,
    Flex,
    SelectField
  } from '@aws-amplify/ui-react';
  import {
    useInView,
    useScroll,
    useTransform,
    motion,
    useAnimation,
  } from "framer-motion";
import PropTypes from 'prop-types';
import styles from './CreateYourOwnPodcast.module.css';  


const CreateYourOwnPodcast = (props) => {

    CreateYourOwnPodcast.propTypes = {
        UserEmail: PropTypes.string,
        UserId: PropTypes.string,
        UserName: PropTypes.string
      };

              

    const ref = useRef(null);
    const isInView = useInView(ref);
    const mainControls = useAnimation();

    var ErrorMessageStr = ""
    const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)  

    useEffect(() => {

        (async function() {
            try {  
    
            } catch (err) {
              console.error('CreateYourOwnPodcast.useEffect[].ERROR: ', err, err.stack); 
          }
        }
      )();   
                
    },[])   

    useEffect(() => {
        //console.log("Hero.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);
     
         if (isInView) {
           mainControls.start("visible");
         }
       }, [isInView]);
     


    return (
        <motion.div
                key="CreateYourOwnPodcast"
                style={{
                    transform: isInView ? "none" : "translateX(-200px)",
                    opacity: isInView ? 1 : 0,
                    transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                  }}
                className = {styles.Background}
               >
                
               <div className = {styles.BackgroundSquare}/>
               <section ref={ref} className = {styles.Section}>
                <Flex
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        alignContent="flex-start"
                        wrap="wrap"
                        gap="3rem"
                        className = {styles.FormDiv}>                      

                    <Flex
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        alignContent="flex-start"
                        wrap="wrap"
                        gap="1rem"
                        className = {styles.FormDiv}>                      
                    <motion.div 
                        className = {styles.TextDiv}
                        key="CreateYourOwnPodcast"
                        style={{
                            transform: isInView ? "none" : "translateY(-300px)",
                            opacity: isInView ? 1 : 0,
                            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                          }}>
                    <motion.h1  className = {styles.Title}>
                      Looking to add Podcaster to your adult content marketing?
                    </motion.h1>  
                    </motion.div>   
                    <motion.div 
                        className = {styles.SubTitleDiv}
                        style={{
                            transform: isInView ? "none" : "translateX(-200px)",
                            opacity: isInView ? 1 : 0,
                            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                          }}>
                    <motion.h1  className = {styles.SubTitle}>                    
                      Create your own podcast and host it on RhyteIt
                    </motion.h1>  
                    </motion.div>   
                    <motion.div 
                        className = {styles.TextDiv}
                        style={{
                            transform: isInView ? "none" : "translateY(-200px)",
                            opacity: isInView ? 1 : 0,
                            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                          }}>
                    <motion.h1  className = {styles.Info}>
                         Expand your sales funnel and connect with your fans without having to worry about being censured, cancelled or removed due to being too explicit.
                    </motion.h1>  
                    </motion.div>                  
                    <a className = {styles.ButtonLinkText} href="mailto:podcast@rhyteit.com?subject=I%20would%20like%20to%20host%20my%20own%20podcast&body=Hey%0D%0A%0D%0AJust%20wanted%20to%20let%20you%20know%20I%20am%20interested!%0D%0A%0D%0ACheers,%0D%0AYour%20name%20%3A%29">                            
                    <Button className = {styles.Button}    >   
                       I am interested                   
                    </Button> 
                    </a>       
                    </Flex>     
                </Flex>          
                </section> 
            </motion.div>
                
    );
}

export default CreateYourOwnPodcast;    