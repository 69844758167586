import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import { UserContext} from '../../../BusinessLogic/Hooks/UserContext';

import {
  Flex, Breadcrumbs
} from '@aws-amplify/ui-react';
import styles from './HeaderBreadCrumbs.module.css';
import {get_uuidv4} from '../../../BusinessLogic/uuidv4';

function ErrorFallback({error, resetErrorBoundary}) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}


const HeaderBreadCrumbs = (props) => {       
  
  HeaderBreadCrumbs.propTypes = {
          BreadCrumbItems: PropTypes.array
        };
          
       
        const [BreadCrumbItems, setBreadCrumbItems] = useState(props.BreadCrumbItems)  
        const [updatedKey, setupdatedKey] = useState(Date.now())   

        useEffect(() => {

          
            //    console.log("HeaderBreadCrumbs.useEffect[].BreadCrumbItems: ",BreadCrumbItems)
        

        },[]) 
 

    

    return ( 
          <div className={styles.BreadcrumbsContainer}  key={get_uuidv4} >                    
            <Breadcrumbs.Container className={styles.Breadcrumbs}>  
            {
              BreadCrumbItems.length > 0 && BreadCrumbItems.map((BreadCrumbItem) => (   
                BreadCrumbItem.isCurrent  === false && (                         
                      <Breadcrumbs.Item>
                        <Breadcrumbs.Link className={styles.BreadcrumbsLink} href={BreadCrumbItem.Path}>{BreadCrumbItem.Title}</Breadcrumbs.Link>
                        <Breadcrumbs.Separator />
                      </Breadcrumbs.Item>  
                  )  
                  
              ))
            }     
            {
              BreadCrumbItems.length > 0 && BreadCrumbItems.map((BreadCrumbItem) => (   
                BreadCrumbItem.isCurrent  === true && (                         
                      <Breadcrumbs.Item>
                        <Breadcrumbs.Link className={styles.BreadcrumbsLink} isCurrent href={BreadCrumbItem.Path}>{BreadCrumbItem.Title}</Breadcrumbs.Link>
                      </Breadcrumbs.Item>  
                  )  
                  
              ))
            }                
            </Breadcrumbs.Container>  
          </div>                  

    )

  }
 

export default HeaderBreadCrumbs;