import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';

import  { listSMMADPlatforms }  from '../../../graphql/queries'
import  { }  from '../../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import Header from "../../RhyteItComponents/Header/Header";
import {motion as m} from "framer-motion";
import PlatformsTable from "./PlatformsTable";

import { UserContext, CognitoGroupContext} from '../../../BusinessLogic/Hooks/UserContext';
import {GetGroupRoleAccess, RhyteItLogging, ReturnUserToLandingPage} from '../../../BusinessLogic/UserManagement';
import { fetchAuthSession } from 'aws-amplify/auth';

import {
  Button , SwitchField, Text, Flex
} from '@aws-amplify/ui-react';
import styles from './Platforms.module.css';

const Platforms = (props) => {       
  
  Platforms.propTypes = {
          RedditUser: PropTypes.object
        };

        const client = generateClient();
        const [SMPlatforms, setSMPlatforms] = useState([]) 
        const vBreadCrumbItems = [{Path: "/", Title:"Home", isCurrent:false},{Path: "/MainMenuV2", Title:"Menu", isCurrent:false},{Path: "/Platforms", Title:"Platforms", isCurrent:true}]
     
        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   
        let debugConsoleOutPut = true

        const {Users, setUsers} = useContext(UserContext);    
        const {CognitoGroup, setCognitoGroup} = useContext(CognitoGroupContext);          
        const [PlatformsReturned, setPlatformsReturned] = useState(0);
        const [ShowTable, setShowTable] = useState(false);
        

        const [updatedKey, setupdatedKey] = useState(Date.now())   

        

        useEffect(() => {

        

        },[]) 

          
           useEffect(() => {
             
            (async function() {
              try {  
  
                      if (CognitoGroup !== undefined) {
  
                        let   GetGroupRoleAccessResponse = await GetGroupRoleAccess(CognitoGroup, "rhyteitAdmins")
                        
                        if (GetGroupRoleAccessResponse) {  
                          GetPlatforms()
                        }
                            
  
                      } else {
  
                        const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
  
                        if (idToken !== undefined) {
                          setUsers(idToken.payload.email)
                          setCognitoGroup(accessToken["payload"]["cognito:groups"])
                        } else {
                          
                          ReturnUserToLandingPage(Users, CognitoGroup)
                        }
  
                      }
                    } catch (err) {                  
                      RhyteItLogging(Users, "Platforms", "useEffect[CognitoGroup]",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                  }
                }
              )();   
      
             },[CognitoGroup])               
            
        useEffect(() => {
 
           if (SMPlatforms.length > 0) {
             setShowTable(true)                  
             setupdatedKey(Date.now())    
           }
 
           setErrorMessage("Platforms retrieved at: " + new Date().toLocaleString())
 
         },[SMPlatforms])     
   

        async function GetPlatforms() {
          try {
                    setErrorMessage("")
                    setSMPlatforms([])
                    if (debugConsoleOutPut === true) {
                        //console.log("Platforms.GetPlatforms.Users: ", Users)
                    }


                    if (debugConsoleOutPut === true ) {
                      //console.log("Platforms.GetPlatforms.Users[0].UserId: ",Users[0].UserId)
                    }


                    const PlatformsData2  = await client.graphql({
                      query: listSMMADPlatforms
                    });
      


                    if (PlatformsData2.data.listSMMADPlatforms.items.length > 0) {
                        //console.log("PlatformsData2.data.listPlatforms.items.length: ",PlatformsData2.data.listSMMADPlatforms.items.length);
                        setPlatformsReturned(PlatformsData2.data.listSMMADPlatforms.items.length)
                        const Platforms1 = PlatformsData2.data.listSMMADPlatforms.items 

                        setSMPlatforms(Platforms1)  
           
                  }

          } catch (err) {                
            RhyteItLogging(Users, "Platforms", "GetPlatforms",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                      }
        }            

        async function AddPlatformRow() {
          try {              
              
    
            var vUpdateDateTime = new Date().toISOString()

            let NewPlatform = {
                  id: "NEW",
                  Platform: 'Platform A',
                  SMMADPlatformsStatus: "active",        
                  CreationName: Users,
                  UpdateName: Users,
                  CreationDateTime: vUpdateDateTime,
                  UpdateDateTime: vUpdateDateTime
            }    


          let vArraySpot = Object.keys(SMPlatforms).length
        
            setSMPlatforms(currInputs => [...currInputs, NewPlatform]);
            //setSMPlatforms(SMPlatforms)     
    
    
            } catch (err) { 
              RhyteItLogging(Users, "Platforms", "AddPlatformRow",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          }        
        }        

    return ( 
    <m.div
      key="Search"
      initial={{x: "100%"}}
      animate={{x: "0%"}}
      transition={{duration:0.75, ease: "easeOut"}}
      exit={{ opacity: 1 }}
     >
                          
                  <Flex
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      alignContent="flex-start"
                      wrap="nowrap"
                      gap="3rem"
                      className={styles.GridContainer}
                    >     
                      <Header Title="Platform Management" SubTitle="Manage SMMAD Platforms" BreadCrumbItems ={vBreadCrumbItems} pageSource="SMMADPlatforms"/>  
                      <Flex
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          alignContent="flex-start"
                          wrap="nowrap"
                          gap="3rem"
                        >                                
                              <Button
                                loadingText=""
                                ariaLabel=""
                                className={styles.RedditTitleButtons}
                                onClick={ (e) => AddPlatformRow(e)}
                              > Add Row 
                              </Button>                                                                                                                                                                                                  
                      </Flex>                                
                      <div className={styles.MessageBox}>{ErrorMessage} Platforms retrieved: {PlatformsReturned}</div>  
                      {ShowTable && SMPlatforms.length > 0  && SMPlatforms !== undefined && (<PlatformsTable   key={updatedKey}  SMPlatforms={SMPlatforms}/>)  }                                                                                                              
                    </Flex>    
    </m.div>                      

    )

  }
 

export default Platforms;