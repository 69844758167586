import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import styles from './LandingPageV4.module.css';
import { SEO } from '../SEO/SEO';
import {useNavigate,Link } from 'react-router-dom';
import axios from 'axios';

import  { createAudienceGeoInfo}  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import {uuidv4} from "../../BusinessLogic/UtilitiesManagement";
//import Background from "./LandingPageV3";
import Background from "./BackgroundSpringCanvas";
import HeaderMenu from "../HeaderMenu/NavBarClean";
import {motion as m} from "framer-motion";
import Footer from '../RhyteItComponents/Footer/FooterClean';
import OverviewLP from '../Videos/Overview/OverviewLP';
import { isMobile , useDeviceData} from 'react-device-detect';
import {
    Button
  } from '@aws-amplify/ui-react';
import $ from 'jquery'; 
import './LandingPageV4.css';


const LandingPageV4  = (props) => {
 
    LandingPageV4.propTypes = {
    UserId: PropTypes.string,
    UserName: PropTypes.string
  };

  let ErrorMessageStr = ""


  let vIPAddress = ""
  const [FirstTouch, setFirstTouch] = useState(true);
  const SignUp = 'https://rhyteitartwork.s3.us-west-2.amazonaws.com/RhyteItLogo3.svg';
  const [ip, setIP] = useState('');
  const [ErrorMessage, setErrorMessage] = useState("")  
  const sleep = ms => new Promise(r => setTimeout(r, ms));
  let wordflickRunning = false;  
  const deviceData = useDeviceData();
  const [Latitude, setLatitude] = useState();
  const [Longitude, setLongitude] = useState();
  const client = generateClient();

  useEffect(() => {

    (async function() {
        try {
      

            getData()
        if (wordflickRunning === false) {
            
        // console.log("LandingPageV4.useEffect[].Start")
            await sleep(5000)
            wordflick()

        }

        } catch (err) {
            console.error('PhotoGenerator.useEffect[].ERROR: ', err, err.stack); 
        }
    })(); 

    },[]) 

  const getData = async () => {
      //console.log("LandingPageV4.handleMouseOver.FirstTouch - Before: ", FirstTouch);
      

            if (FirstTouch === true) {
                //console.log("LandingPageV4.handleMouseOver.FirstTouch: ", FirstTouch);
                    setFirstTouch(false);    
                    //console.log("LandingPageV4.handleMouseOver.FirstTouch2: ", FirstTouch);

                    const res = await axios.get('https://geolocation-db.com/json/')
                    //console.log("LandingPageV4.getData.res.data: ",res.data );
                    //console.log("LandingPageV4.getData.res: ",res );
                    setIP(res.data.IPv4)

                    let DynamoUUID =  await uuidv4()

                    vIPAddress = res.data.IPv4

                if (res.data.IPv4 !== "98.199.173.171") {

                let SaveSubReddit = {
                    id: DynamoUUID,
                    UserId: "N/A",
                    Page: "LandingPageClean",
                    IPv4: res.data.IPv4,
                    city: res.data.city,
                    country_code: res.data.country_code,
                    country_name: res.data.country_name,
                    latitude: res.data.latitude,
                    longitude: res.data.longitude,
                    postal: res.data.postal,
                    state: res.data.state,
                    CreationName:  "N/A",
                    UpdateName: "N/A"
                }                      

                //console.log("LandingPageV4.getData.SaveSubReddit: " ,SaveSubReddit)

                try{

                    let createAudienceGeoInfoResponse = await client.graphql({                    
                        query: createAudienceGeoInfo,
                        variables:{input: {SaveSubReddit
                        }
                      }
                    });          
                    
                    //console.log("LandingPageV4.getData.createAudienceGeoInfoResponse: " ,createAudienceGeoInfoResponse)

                } catch (err)  {
                ErrorMessageStr = 'LandingPageV4.getData.ERROR: ' + err + err.stack
                setErrorMessage(ErrorMessageStr)
                console.error(ErrorMessageStr); 
                }

                }
            }

  }  

  var words = ['A marketing consulting firm that adds value through:',
                 'Tailored Strategic Planning', 
                 'Data-Driven Decision Making', 
                 'Multi-Channel Expertise', 
                 'ROI Focus', 
                 'Continuous Innovation', 
                 'Transparent Reporting', 
                 '$10/month'],
    part,
    i = 0,
    offset = 0,
    len = words.length,
    forwards = true,
    skip_count = 0,
    skip_delay = 15,
    speed = 70;

  //  function wordflick() {
    var wordflick = function () {
        wordflickRunning = true;
        setInterval(function () {
            

      //  console.log("LandingPageV4.wordflick.Start")
        if (forwards) {
            if (offset >= words[i].length) {
            ++skip_count;
            if (skip_count === skip_delay) {
                forwards = false;
                skip_count = 0;
            }
            }
        }
        else {
            if (offset === 0) {
            forwards = true;
            i++;
            offset = 0;
            if (i >= len) {
                i = 0;
            }
            }
        }
        part = words[i].substr(0, offset);
        if (skip_count === 0) {
            if (forwards) {
            offset++;
            }
            else {
            offset--;
            }
        }
        $('.ValuePropAlerting').text(part);
        },speed);

        };


  return (

            <m.div
                key="LandingPageV4"
                initial={{y: "100%"}}
                animate={{y: "0%"}}
                transition={{duration:0.75, ease: "easeOut"}}
                exit={{ opacity: 1 }}                
                className = {styles.TopContainer}  

                >  
                <SEO
                    title="Let us help you build your digital marketing capabilities"
                    description='RhyteIt has helped hundreds of companies become digital marketing trendsetters'
                    name='RhyteIt'
                    type='article' />               
                <Background className = {styles.Background}></Background> 
                <div className={styles.MasterContainer} >
                    <HeaderMenu pageSource="LandingPage" className = {styles.HeaderMenu}/> 
                    <div className={styles.MainSection}> 
                        <div className={styles.HeroSection}> 
                            <h1 className={styles.h1}>
                                Join the top 1% of Social Media Marketers
                            </h1>
                        </div>                
                        <div className={styles.headerline} />
                        <div className={styles.SubHeroSection}> 
                            <h2>Revolutionize your content creation with cutting-edge AI advancements.</h2>
                        </div>
                        <div className={styles.ValuePropAlerting}></div>
                        <div class="ValuePropAlerting"></div>
                        <div className={styles.ApplicationContainer}>
                            <Button className={styles.ApplicationButton}>
                                <Link className={styles.ApplicationLink} to="/Application">
                                    Inquiries
                                </Link> 
                            </Button> 
                        </div>               
                        <div className={styles.ApplicationContainer}>
                            <Footer/>
                        </div>
                    </div>
                </div>
            </m.div>   
            

        );
  }

export default LandingPageV4