
import React, { useState , useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Button,
  Image,
  TableBody,
  TableRow,
  TableCell,
  CheckboxField,
  TextAreaField,
  TextField,
  Text
  } from '@aws-amplify/ui-react';

  import  {  }  from '../../../graphql/queries'
  import {
    createBlogPosts,
    updateBlogPosts,
    deleteBlogPosts
  } from "../../../graphql/mutations";
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";
  import {
    useInView,
    useScroll,
    useTransform,
    motion,
    useAnimation,
  } from "framer-motion";
  import styles from './BlogEntry.module.css';  
  import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
  
function BlogEntry(
  props
) {       

  BlogEntry.propTypes = {
    BlogPost: PropTypes.object,
    };

    const client = generateClient();
  
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vBlogPost, setvBlogPost] = useState(props.BlogPost)  
    const [vPrompt, setvPrompt] = useState() 
    const [vPromptName, setvPromptName] = useState() 

    const [vSubRedditListString, setvSubRedditListString] = useState() 

    
    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);

    const [vExpandRow, setvExpandRow] = useState(false)    
    let [vBlogPostActive, setvBlogPostActive] = useState(false)   
    const [vBlogPostHTMLString, setvBlogPostHTMLString] = useState()    
    
    
      useEffect(() => {

        //console.log("BlogEntry.useEffect[].props.BlogPost:  ",props.BlogPost) 

      },[])  

      useEffect(() => {
      //console.log("BlogEntry.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);

        if (isInView) {
          mainControls.start("visible");
        }
      }, [isInView]);      

      useEffect(() => {

      // //console.log("BlogEntry.useEffect[vBlogPost].vBlogPost: " ,vBlogPost.BlogHTMLBody);

        if (vBlogPost !== undefined) {

          let vBlogPostTmp = vBlogPost.BlogHTMLBody.replaceAll("<h1>", "<h1 style=\"color: black; font-size: 2rem !important; font-weight: 600;\">");
          
          vBlogPostTmp = vBlogPostTmp.replaceAll("<h3>", "<h3  style=\"color: black; font-size: 1.5rem; font-weight: 600;\">");

          setvBlogPostHTMLString(vBlogPostTmp)

        }

      }, [vBlogPost]);   

      return (
        <div className={styles.Background}>
        <Flex
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          alignContent="flex-start"
          wrap="nowrap"
          gap="1rem"
          className={styles.FlexLeftColumn}>   
         {
          vBlogPostHTMLString !== undefined  && (          
            <div 
              dangerouslySetInnerHTML={{ __html: vBlogPostHTMLString }} 
            />
          )}
          <div
          className={styles.DateLine} >
            <Text
          className={styles.Date} >
             {new Date(vBlogPost.CreationDateTime).toLocaleDateString('en-US',{year: 'numeric',month: 'long',day: 'numeric'})}
             </Text>
          </div>

        </Flex>
      </div>
      
    )
  }
export default BlogEntry;

