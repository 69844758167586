
import { Amplify } from 'aws-amplify';
import React, { useState, useEffect, useRef } from 'react'
//import isUrlHttp from 'is-url-http';
//import './Podcast.css'; 
import {
    TextField, 
    Text,
    CheckboxField,
    TextAreaField,
    Button,
    Flex,
    SelectField
  } from '@aws-amplify/ui-react';
  import {
    useInView,
    useScroll,
    useTransform,
    motion,
    useAnimation,
  } from "framer-motion";
import PropTypes from 'prop-types';
import styles from './Podcast.module.css';  
import {listApplicants, getSecrets} from '../../graphql/queries'
import {createAudienceGeoInfo, GetRSSFeedJSON}  from '../../graphql/mutations'
import StatusMessageBox from "../../ui-components/StatusMessageBox";
import HeaderMenu from "../HeaderMenu/LandingPageNavBar";
import {uuidv4} from "../../BusinessLogic/UtilitiesManagement"; 

import { generateClient } from 'aws-amplify/api';
import Footer from '../RhyteItComponents/Footer/Footer';
import { SEO } from '../SEO/SEO';
import {RhyteItLogging} from '../../BusinessLogic/UserManagement';
import PodcastEpisodes from './PodcastEpisodes';
import InterviewApplicant from './InterviewApplicant';
import CreateYourOwnPodcast from './CreateYourOwnPodcast';


const Podcast = (props) => {

    Podcast.propTypes = {
        UserEmail: PropTypes.string,
        UserId: PropTypes.string,
        UserName: PropTypes.string
      };

    
      var vFirstTouch = true
    const client = generateClient();

    const [ip, setIP] = useState("17.22.237.146");  

    const ref = useRef(null);
    const isInView = useInView(ref);
    const mainControls = useAnimation();

    var ErrorMessageStr = ""
    const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)  
    const [vEpisodes, setvEpisodes] = useState()  
    const [vShow, setvShow] = useState()  
    const [vEpisodesRefresh, setvEpisodesRefresh] = useState()  

    useEffect(() => {

        (async function() {
            try {  
                window.scrollTo(0, 0);
                getData()
               // GetRSSFeedJSONv2()
            } catch (err) {
              console.error('Podcast.useEffect[].ERROR: ', err, err.stack); 
          }
        }
      )();   
                
    },[])   

    useEffect(() => {
        // //console.log("Hero.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);
        
         if (isInView) {
           mainControls.start("visible");
         }
       }, [isInView]);
   
    useEffect(() => {

        //console.log('Podcast.useEffect[ip].ip:', ip);
        //setErrorMessage(ip)
                
    },[ip])       

    const getData = async () => {
       
      if (vFirstTouch === true && process.env.REACT_APP_ENV === "PRODUCTION") {
            //console.log("LandingPageV4.handleMouseOver.FirstTouch: ", FirstTouch);
               vFirstTouch = false;    
      
      
         //   const res = await axios.get('https://geolocation-db.com/json/')
      
            let DynamoUUID =  await uuidv4()
      
        //  if (res.data.IPv4 !== "98.199.173.171") {
      
            let AudienceGeoItem = {
              id: DynamoUUID,
              UserId: "Podcast",
              Page: "Podcast",
              IPv4: "17.22.237.146",
              city: "N/A",
              country_code: "N/A",
              country_name:"N/A",
              latitude: "37.751" ,
              longitude:"-97.822",
              postal: null,
              state: null,
              CreationName:  "Podcast",
              UpdateName: "Podcast"
            }                      
      
            //console.log("LandingPageNavBar.getData.AudienceGeoItem: " ,AudienceGeoItem)
      
            try{
      
              let createAudienceGeoInfoResponse = await client.graphql({                    
                query: createAudienceGeoInfo,
                variables:{
                    input: AudienceGeoItem
                  }
            });         
                
      
            } catch (err)  {
              RhyteItLogging("NA", "Podcast", "getData",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
           }
      
          }
       // }
      
      }    
      




    return (
        <motion.div
                key="Podcast"
                style={{
                    transform: isInView ? "none" : "translateX(-200px)",
                    opacity: isInView ? 0.8 : 0,
                    transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                  }}
                className = {styles.Background}
               >      
              <div className = {styles.fuzzyoverlay} />
              <div className = {styles.girl} />          
               <section ref={ref} className = {styles.Section}>
               <SEO
                   title="Join the RhyteIt Podcast: Share Your Story and Insights"
                   description='Explore the RhyteIt Podcast, where we interview adult content creators. Share your experiences, insights, and tips for success in the industry while connecting with a community that celebrates creativity and entrepreneurship.'
                   name='RhyteIt'
                   type='article' />                    
                <Flex
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        alignContent="flex-start"
                        wrap="wrap"
                        gap="3rem"
                        className = {styles.FormDiv}>                  
                    <motion.div 
                        className = {styles.HeaderMenuDiv}
                        key="Podcast"
                        style={{
                            transform: isInView ? "none" : "translateX(-200px)",
                            opacity: isInView ? 1 : 0,
                            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                            }}>
                    <HeaderMenu className = {styles.HeaderMenu} pageSource="Application"/>  
                    </motion.div>                      
                      <PodcastEpisodes key={vEpisodesRefresh}/>                      
                      <InterviewApplicant />
                      <CreateYourOwnPodcast />
                      <Footer/>   
                </Flex>        
                </section> 
            </motion.div>
                
    );
}

export default Podcast;    