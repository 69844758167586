
import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TableRow,
  Table,
  TableHead
  } from '@aws-amplify/ui-react'; 
import PrivateKey from './DashboardChart';
import styles from './DashboardContent.module.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DashboardContent = (props) => {       

  DashboardContent.propTypes = {
      RhyteItDashboardContent: PropTypes.array,
      Report: PropTypes.string,
      RhyteItUser: PropTypes.array
      };

    let debugConsoleOutPut = true

    const data1 = {
      labels: [],
      datasets: [
          {
            label: 'OFProfilesCumm',
            data: [],
            borderColor: "rgba(75,192,192,0.2)",
            backgroundColor: "rgba(75,192,192,1)",
            borderWidth: 1
          }
      ]
    }    


    const options1 = {
      scales: {
        yAxes: [
          {
            gridLines: {
              color: "rgba(204, 102, 51, 1)"
            }
          }
        ],
        xAxes: [
          {
            gridLines: {
              color: "rgba(0, 204, 204, 1)"
            }
          }
        ]
      }
    };       

    const [RhyteItDashboardContent, setRhyteItDashboardContent] = useState(props.RhyteItDashboardContent)   
    const [vEnvironment, setvEnvironment] = useState(props.Environment)   
    const [ShowTable, setShowTable] = useState(false);
    const [ProfilesReturned, setProfilesReturned] = useState(0);
    const [Labels, setLabels] = useState([]);
    const [PageViewDataSet, setPageViewDataSet] = useState(data1);
    const [GraphOptions, setGraphOptions] = useState(options1);
    const [Report, setReport] = useState(props.Report);
    

    useEffect(() => {
      try {        
        
          //console.log("DashboardContent.useEffect[].RhyteItDashboardContent: ",RhyteItDashboardContent);
          //console.log("DashboardContent.useEffect[].Report: ",Report);
          CreateLineGraph()
          

        } catch (err) {
          console.error('DashboardContent.useEffect[].ERROR: ', err, err.stack); 
      }      

    },[])  

    useEffect(() => {


      //console.log("DashboardContent.useEffect[PageViewDataSet].PageViewDataSet: ",PageViewDataSet);
      //console.log("DashboardContent.useEffect[PageViewDataSet].PageViewDataSet.labels: ",PageViewDataSet.labels);
      //console.log("DashboardContent.useEffect[PageViewDataSet].PageViewDataSet.length : ",PageViewDataSet.length );
      

    },[PageViewDataSet])  

    useEffect(() => {


      //console.log("DashboardContent.useEffect[PageViewDataSet].GraphOptions: ",GraphOptions);
     // //console.log("DashboardContent.useEffect[PageViewDataSet].GraphOptions.length : ",GraphOptions.length );
      

    },[GraphOptions])  


    async function CreateLineGraph() {
      try {

                let vOFJSON =  []
                let vPageViewDataSet =  []

                let vProfileTotal = RhyteItDashboardContent.length
                setProfilesReturned(vProfileTotal.toLocaleString())
                //console.log("OnlyFansProspectingProfilesData1: ",OnlyFansProspectingProfilesData1[1]);
                for (var OFRRecord in RhyteItDashboardContent) {
                        //console.log("GetOFStatsFromS3.RhyteItOnlyFansGrowth[OFRRecord][StatDate]: " ,RhyteItDashboardContent[OFRRecord]["StatDate"]);
                        RhyteItDashboardContent[OFRRecord]["label"] = RhyteItDashboardContent[OFRRecord]["StatDate"]
                        vOFJSON[OFRRecord] = RhyteItDashboardContent[OFRRecord]["StatDate"]
                        vPageViewDataSet[OFRRecord] = RhyteItDashboardContent[OFRRecord][Report]
                }

                console.log("GetOFStatsFromS3.vPageViewDataSet: ",vPageViewDataSet);
                const data = {
                  labels: vOFJSON,
                  datasets: [
                      {
                        label: Report,
                        data: vPageViewDataSet,
                        borderColor: "rgba(0, 204, 204, 1)",
                        backgroundColor: "rgba(204, 102, 51, 1)",
                        borderWidth: 1,                        
                        pointRadius: 2,
                        pointHoverRadius: 3
                      }
                  ]
                }
                //console.log("GetOFStatsFromS3.data: ",data);

                const options = {
                  responsive: true,
                  plugins: {
                    title: {
                      display: true,
                      text: 'The Cummulative # of OF Profiles in RhyteIt'
                    },
                  },
                  scales: {
                    x: {
                      display: true,
                      gridLines: { color: "#9999CC" },
                      title: {
                        display: true,
                        text: 'Date'
                      },
                      ticks: {
                        color: '#CCCBCA'
                      },
                      grid: {
                        display: true
                      },
                      border: {
                        color: '#9999CC',
                      }
                    },
                    y: {
                      display: true,
                      title: {
                        display: true,
                        text: 'OF Profiles'
                      },
                      ticks: {
                        color: '#CCCBCA'
                      },
                      grid: {
                        display: true
                      },
                      border: {
                        color: '#9999CC',
                      }
                    }
                  }
                };                

                setGraphOptions(options)
                setRhyteItDashboardContent(RhyteItDashboardContent)  
                setPageViewDataSet(data)

                //console.log("GetOFStatsFromS3.vOFJSON: " ,vOFJSON);
                setLabels(vOFJSON)
                 //let PopulateOnlyFansNamesListResponse = await PopulateOnlyFansNamesList(OnlyFansProspectingProfilesData1)                      
  


      } catch (err) { 
                      console.error('SalesMonitor.GetOnlyFansProspectingProfiles.ERROR: ', err, err.stack); 
                  }
    }      

  

  return (  
      <div className={styles.Table} >   
            {PageViewDataSet.labels.length > 0 && ( 
              <Line
                datasetIdKey='id'
                data={PageViewDataSet}
                options={GraphOptions}
                className={styles.LineGraph}
              />
            )}             
      </div>
    
  )
}

export default DashboardContent;


