import PropTypes from "prop-types";
import React, { useState, useEffect, useContext, useRef } from "react";

import { listSMMADAccounts } from "../../../graphql/queries";
import { updateSMMADAccounts } from "../../../graphql/mutations";
import { generateClient } from "aws-amplify/api";

import Header from "../../RhyteItComponents/Header/Header";
import {
  UserContext,
  CognitoGroupContext,
} from "../../../BusinessLogic/Hooks/UserContext";
import {
  GetGroupRoleAccess,
  RhyteItLogging,
  ReturnUserToLandingPage,
} from "../../../BusinessLogic/UserManagement";
import { fetchAuthSession } from "aws-amplify/auth";
import { motion as m } from "framer-motion";
import MenuBackground from "../../Menu/MenuBackground";

import {
  Button,
  Flex,
  Divider,
  Label,
  TextField,
  SelectField,
  Text,
  TableCell,
  Table,
  TableBody,
  TableRow,
  Fieldset,
  CheckboxField,
} from "@aws-amplify/ui-react";
import styles from "./SMMADParentManager.module.css";
import SMMADNewAccountSetup from "./SMMADNewAccountSetup";

const SMMADParentManager = (props) => {
  SMMADParentManager.propTypes = {
    RedditUser: PropTypes.object,
  };

  const client = generateClient();
  const [vSMMADParentManager, setvSMMADParentManager] = useState([]);
  const vBreadCrumbItems = [
    { Path: "/", Title: "Home", isCurrent: false },
    { Path: "/MainMenuCustomer", Title: "Menu", isCurrent: false },
    { Path: "/SMMADParentManager", Title: "TurboFanBoost", isCurrent: true },
  ];

  var ErrorMessageStr = "";
  const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr);

  const [vCampaignsActive, setvCampaignsActive] = useState(false);
  const [vAccountType, setvAccountType] = useState(false);

  const [vAccountSelected, setvAccountSelected] = useState();
  const [vParentSelected, setvParentSelected] = useState();
  const [vTurbFanAccounts, setvTurbFanAccounts] = useState();
  const [vTurbFanAccount, setvTurbFanAccount] = useState();
  let [vAccountList, setvAccountList] = useState();
  let [vParentAccountList, setvParentAccountList] = useState();
  let [vNewAccount, setvNewAccount] = useState();

  const { Users, setUsers } = useContext(UserContext);
  const { CognitoGroup, setCognitoGroup } = useContext(CognitoGroupContext);

  const [vCampaignsVisible, setvCampaignsVisible] = useState(false);
  const [vCreateNewParentAccount, setvCreateNewParentAccount] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async function () {
      try {
        if (CognitoGroup !== undefined) {
          let GetGroupRoleAccessResponse = await GetGroupRoleAccess(
            CognitoGroup,
            "RhyteItSMMADUserGroup"
          );

          if (GetGroupRoleAccessResponse) {
            GetSMMADAccounts();
          }
        } else {
          const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};

          if (idToken !== undefined) {
            setUsers(idToken.payload.email);
            setCognitoGroup(accessToken["payload"]["cognito:groups"]);
          } else {
            ReturnUserToLandingPage(Users, CognitoGroup);
          }
        }
      } catch (err) {
        RhyteItLogging(
          Users,
          "SMMADParentManager",
          "useEffect[CognitoGroup]",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    })();
  }, [CognitoGroup]);

  useEffect(() => {
    if (vTurbFanAccounts !== undefined) {
      let vAccount = vTurbFanAccounts.filter(
        (TurbFanAccount) => TurbFanAccount["Account"] === vAccountSelected
      );

      setvTurbFanAccount(vAccount[0]);
    }
  }, [vAccountSelected]);

  useEffect(() => {
    if (vAccountList !== undefined && Users !== "edwardhawke@rhyteit.com") {
      AccountSelectedAction(vAccountList[0]["Account"]);
    }
  }, [vAccountList]);

  useEffect(() => {
    if (vParentAccountList !== undefined && Users !== "edwardhawke@rhyteit.com") {
      ParentAccountSelected(vParentAccountList[0]["Account"]);
    }
  }, [vParentAccountList]);

  useEffect(() => {
    if (vTurbFanAccount !== undefined) {
      let vAccountTypeTmp =
        vTurbFanAccount.AccountType === "Internal" ? true : false;

      setvAccountType(vAccountTypeTmp);
      setvCampaignsActive(vTurbFanAccount.CampaignsActive);
    }
  }, [vTurbFanAccount]);

  async function GetSMMADAccounts() {
    try {
      setErrorMessage("");
      setvSMMADParentManager([]);
      let variables;
      if (Users !== "edwardhawke@rhyteit.com") {
        variables = {
          filter: {
            Account: {
              eq: Users,
            },
          },
        };
      } else {
        variables = {};
      }

      const listSMMADAccountsData2 = await client.graphql({
        query: listSMMADAccounts,
        variables: variables,
      });

      console.log("SMMADParentManager.GetSMMADAccounts.listSMMADAccountsData2.data.listSMMADAccounts.items.length : ", listSMMADAccountsData2.data.listSMMADAccounts.items.length );

      if (listSMMADAccountsData2.data.listSMMADAccounts.items.length > 0) {
        const SMMADParentManager1 =
          listSMMADAccountsData2.data.listSMMADAccounts.items;

        let vAccountListArray = [];
        let vParentAccountListArray = [];

        SMMADParentManager1.forEach(async (Campaign) => {
          if (
            vAccountListArray.filter(
              (vcampaign) => vcampaign["Account"] === Campaign["Account"]
            ).length === 0
          ) {
            if (Campaign["Account"] !== "RhyteIt") {
              vAccountListArray.push(Campaign);
            }
            if (Campaign["MasterAccount"] === true) {
              vParentAccountListArray.push(Campaign);
            }

          }
        });

        //console.log(          "SMMADParentManager.GetSMMADAccounts.vAccountListArray: ",          vAccountListArray        );

        let vAccountListArraySorted = SortAccountsByName(vAccountListArray);
        //console.log("SMMADParentManager.GetSMMADAccounts.vAccountListArraySorted: ", vAccountListArraySorted);
        setvAccountList(vAccountListArraySorted);

        let vParentAccountListArraySorted = SortAccountsByName(vParentAccountListArray);
        //console.log("SMMADParentManager.GetSMMADAccounts.vAccountListArraySorted: ", vAccountListArraySorted);
        setvParentAccountList(vParentAccountListArraySorted);



        setvTurbFanAccounts(SMMADParentManager1);
      } else {
        setvCreateNewParentAccount(true)
      }



    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADParentManager",
        "GetSMMADAccounts",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function SortAccountsByName(pRhyteItAccounts) {
    pRhyteItAccounts.sort(GFG_sortFunction);
    return pRhyteItAccounts;
    //    //console.log(JSON.stringify(pEmails));
  }

  function GFG_sortFunction(a, b) {
    let RhyteItAccountA = a.Account;
    let RhyteItAccountB = b.Account;
    return RhyteItAccountA > RhyteItAccountB ? 1 : -1;
  }

  async function AccountSelectedAction(pAccount) {
    try {
      //console.log("SMMADParentManager.AccountSelectedAction.pAccount: ", pAccount);
      //console.log("SMMADParentManager.AccountSelectedAction.vAccountList: ", vAccountList);
      let vSelectedAccount = vParentAccountList.filter(
        (vAccountItem) => vAccountItem["Account"] === pAccount
      );
      //console.log("SMMADParentManager.AccountSelectedAction.vSelectedAccount[CampaignsActive]: ", vSelectedAccount["CampaignsActive"]);
      setvCampaignsVisible(vSelectedAccount["CampaignsActive"]);
      setvAccountSelected(pAccount);
    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADParentManager",
        "AccountSelectedAction",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  async function ParentAccountSelected(pAccount) {
    try {
      //console.log("SMMADParentManager.AccountSelectedAction.pAccount: ", pAccount);
      //console.log("SMMADParentManager.AccountSelectedAction.vAccountList: ", vAccountList);
      let vSelectedAccount = vAccountList.filter(
        (vAccountItem) => vAccountItem["Account"] === pAccount
      );
      //console.log("SMMADParentManager.AccountSelectedAction.vSelectedAccount[CampaignsActive]: ", vSelectedAccount["CampaignsActive"]);
      setvCampaignsVisible(vSelectedAccount["CampaignsActive"]);
      setvParentSelected(pAccount);
    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADParentManager",
        "AccountSelectedAction",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  async function ViewSMMADNewAccountSetup(event, data) {
    try {

      console.log("SMMADParentManager.ViewSMMADNewAccountSetup.vCreateNewParentAccount: ",vCreateNewParentAccount)


      let vTmp = !vCreateNewParentAccount
      setvCreateNewParentAccount(vTmp);

    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADParentManager",
        "ViewSMMADNewAccountSetup",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  return (
    <m.div
      key="Search"
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      exit={{ opacity: 1 }}
    >
      <MenuBackground
        className={styles.MenuBackground}
        GradientStopColor1="#0000"
        GradientStopColor2="#000"
        GradientStopColor3="#00CCCC"
        GradientStopColor4="#8880"
      />

      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="center"
        wrap="nowrap"
        gap="1rem"
        className={styles.FlexColumn}
      >
        <Header
          key={Date.now()}
          Title="TurbFanBoost"
          SubTitle="Social Media Marketing Automation"
          BreadCrumbItems={vBreadCrumbItems}
          pageSource="TurbFanBoost"
        />
        <Fieldset className={styles.Fieldset} size="small">
          <legend className={styles.legend}>Parent Accounts</legend>
          <Flex
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            alignContent="center"
            wrap="nowrap"
            gap="3rem"
          >
            <SelectField
              className={styles.SelectField}
              value={vParentSelected}
              placeholder="Select parent account"
              onChange={(e) => ParentAccountSelected(e.target.value)}
            >
              {vParentAccountList !== undefined &&
                vParentAccountList.map((FromAccount) => (
                  <option
                    key={FromAccount.Account}
                    className={styles.SelectFieldOption}
                    value={FromAccount.Account}
                    label={FromAccount.Account}
                  >
                    {FromAccount.Account}
                  </option>
                ))}
            </SelectField>
            {Users === "edwardhawke@rhyteit.com" && (
              <Button
                className={styles.Button}
                variation="primary"
                onClick={ (e) => ViewSMMADNewAccountSetup(e, true)}
              >
                Create new Parent Account
              </Button>   
            )}         
          </Flex>
        </Fieldset>  
        {vCreateNewParentAccount === true && (
              <SMMADNewAccountSetup
                className={styles.Button}
              />
            )}                  
      </Flex>
    </m.div>
  );
};

export default SMMADParentManager;
