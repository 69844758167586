/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 RhyteItBlob.gltf --transform
*/

import React, { useRef,useEffect,useState } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import { useThree, useFrame, extend } from '@react-three/fiber'
import { MeshDistortMaterial } from '@react-three/drei'
import { useSpring, animated } from '@react-spring/three'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import JSONfont from "../../fonts/Roboto.json";


const AnimatedMeshDistortMaterial = animated(MeshDistortMaterial)  
extend({ TextGeometry })

  const Button3D  = (props) => {
  const font = new FontLoader().parse(JSONfont);

  const { nodes, materials } = useGLTF("/RhyteItLogo.glb");
  let XPosition = 0
  const [BlobColor, setBlobColor] = useState("#CC6633");
  const [CubeColor, setCubeColor] = useState("#00CCCC");
  const [TorusColor, setTorusColor] = useState("#CC6633");
  
  const RhyteItLogoRef = useRef();
  const RhyteItTorusRef = useRef();
  const RhyteItCubeRef = useRef();
  let i = 0;
  let FirstTime = true
  const { camera, gl } = useThree();
  const [hovered, setHover] = useState(false);

  useEffect(() => {


  },[])   

  useEffect(() => {
    
  //  console.log("RhyteItTorusRef.position.x(", RhyteItTorusRef.current.position.x.toFixed(2), "), y(", RhyteItTorusRef.current.position.y.toFixed(2), "), z(", RhyteItTorusRef.current.position.z.toFixed(2),  ")")
  //  console.log("RhyteItTorusRef.rotation.x(", RhyteItTorusRef.current.rotation.x.toFixed(2), "), y(", RhyteItTorusRef.current.rotation.y.toFixed(2), "), z(", RhyteItTorusRef.current.rotation.z.toFixed(2),  ")")   


  },[BlobColor])   

  useFrame((state, delta, xrFrame) => {

    //RhyteItLogoRef.current.rotation.x += 0.001*props.RotationFactor;
    RhyteItLogoRef.current.rotation.y += 0.01*props.RotationFactor;
    //RhyteItLogoRef.current.rotation.z += 0.001*props.RotationFactor;

    camera.position.x = 3

   while (i < 1) {
  //  console.log("i: ", 1)
  //  console.log("RhyteItTorusRef.position.x(", RhyteItTorusRef.current.position.x.toFixed(2), "), y(", RhyteItTorusRef.current.position.y.toFixed(2), "), z(", RhyteItTorusRef.current.position.z.toFixed(2),  ")")
  //  console.log("RhyteItTorusRef.rotation.x(", RhyteItTorusRef.current.rotation.x.toFixed(2), "), y(", RhyteItTorusRef.current.rotation.y.toFixed(2), "), z(", RhyteItTorusRef.current.rotation.z.toFixed(2),  ")") 
    i += 1;
   }
     
    if (RhyteItTorusRef.current.position.x.toFixed(2) > 0) {
      setBlobColor("#d17547")
    } else if (RhyteItTorusRef.current.position.x.toFixed(2) <= 0) {
      setBlobColor("#9999CC")
    }

    if (RhyteItTorusRef.current.position.y.toFixed(2) > 0) {
      setCubeColor("#333333")
    } else if (RhyteItTorusRef.current.position.y.toFixed(2) <= 0) {
      setCubeColor("#00CCCC")
    }    


    if (RhyteItTorusRef.current.position.z.toFixed(2) > 0) {
      setTorusColor("#CC6633")
    } else if (RhyteItTorusRef.current.position.z.toFixed(2) <= 0) {
      setTorusColor("#9999CC")
    }    

   

   // Right Side Down
    
    if (
        RhyteItTorusRef.current.position.x.toFixed(2) == -0.30 &&
        RhyteItTorusRef.current.position.y.toFixed(2) <= 0.89 &&
        RhyteItTorusRef.current.position.y.toFixed(2) > -0.65 && 
        RhyteItTorusRef.current.position.z.toFixed(2) >= -1.18 && 
        RhyteItTorusRef.current.rotation.x.toFixed(2) == 0.00 && 
        RhyteItTorusRef.current.rotation.y.toFixed(2) == 0.00 && 
        RhyteItTorusRef.current.rotation.z.toFixed(2) == 0.00 
        )  {

     //  console.log("RhyteItLogo.useFrame.RhyteItTorusRef.Line 1b - Right Side Down")

       //-0.3, 0.89, -1.23
        

        RhyteItTorusRef.current.position.y -= 0.01*props.RotationFactor;
        RhyteItTorusRef.current.position.z += 0.0005;


    //    console.log("RhyteItTorusRef.position.x(", RhyteItTorusRef.current.position.x.toFixed(2), "), y(", RhyteItTorusRef.current.position.y.toFixed(2), "), z(", RhyteItTorusRef.current.position.z.toFixed(2),  ")")
    //    console.log("RhyteItTorusRef.rotation.x(", RhyteItTorusRef.current.rotation.x.toFixed(2), "), y(", RhyteItTorusRef.current.rotation.y.toFixed(2), "), z(", RhyteItTorusRef.current.rotation.z.toFixed(2),  ")")    

    } 
   


    // Right Bottom Corner

    if (  RhyteItTorusRef.current.position.x.toFixed(2) >= -0.30 && 
          RhyteItTorusRef.current.position.x.toFixed(2) <= -0.27 &&  
            RhyteItTorusRef.current.position.y.toFixed(2) <= -0.65 && 
            RhyteItTorusRef.current.position.y.toFixed(2) >= -0.88 && 
          RhyteItTorusRef.current.position.z.toFixed(2) >= -1.23 && 
          RhyteItTorusRef.current.position.z.toFixed(2) <= -1.07 && 
            RhyteItTorusRef.current.rotation.x.toFixed(2) <= -0.00 && 
            RhyteItTorusRef.current.rotation.x.toFixed(2) >= -1.55 && 
          RhyteItTorusRef.current.rotation.y.toFixed(2) == 0.00 &&  
            RhyteItTorusRef.current.rotation.z.toFixed(2) >= 0.00 &&
            RhyteItTorusRef.current.rotation.z.toFixed(2) <= 0.30 ) {

             // RhyteItTorusRef.position.x( -0.30 ), y( -0.65 ), z( -1.23 )
             // RhyteItTorusRef.rotation.x( 0.00 ), y( 0.00 ), z( 0.00 )
   //       console.log("RhyteItLogo.useFrame.RhyteItTorusRef.Line 6 - Right Bottom Corner")


          if (RhyteItTorusRef.current.position.x.toFixed(2) <= -0.27) {
            RhyteItTorusRef.current.position.x += 0.0005;

            if (RhyteItTorusRef.current.position.x.toFixed(2) > -0.27) {
              RhyteItTorusRef.current.position.x = -0.27;
            } 

          }

          if (RhyteItTorusRef.current.position.y.toFixed(2) >= -0.88) {
            RhyteItTorusRef.current.position.y-= 0.003;

            if (RhyteItTorusRef.current.position.y.toFixed(2) < -0.88) {
              RhyteItTorusRef.current.position.y = -0.88;
            } 

          }

          if (RhyteItTorusRef.current.position.z.toFixed(2) <=  -1.07 ) {
            RhyteItTorusRef.current.position.z += 0.005;

            if (RhyteItTorusRef.current.position.z.toFixed(2) > -1.07 ) {
              RhyteItTorusRef.current.position.z = -1.07
            }           

          } 

          

          if (RhyteItTorusRef.current.rotation.x.toFixed(2) >= -1.55) {
            RhyteItTorusRef.current.rotation.x -= 0.02;

            if (RhyteItTorusRef.current.rotation.x.toFixed(2) < -1.55) {
              RhyteItTorusRef.current.rotation.x = -1.55;
            } 

          }          

          if (RhyteItTorusRef.current.rotation.z.toFixed(2) <= 0.30) {
            RhyteItTorusRef.current.rotation.z += 0.02;

            if (RhyteItTorusRef.current.rotation.z.toFixed(2) > 0.30) {
              RhyteItTorusRef.current.rotation.z = 0.30;
            } 

          }               
      

      //    console.log("RhyteItTorusRef.position.x(", RhyteItTorusRef.current.position.x.toFixed(2), "), y(", RhyteItTorusRef.current.position.y.toFixed(2), "), z(", RhyteItTorusRef.current.position.z.toFixed(2),  ")")
     //     console.log("RhyteItTorusRef.rotation.x(", RhyteItTorusRef.current.rotation.x.toFixed(2), "), y(", RhyteItTorusRef.current.rotation.y.toFixed(2), "), z(", RhyteItTorusRef.current.rotation.z.toFixed(2),  ")")       

    }     

    // Bottom Line

    if ( RhyteItTorusRef.current.position.x.toFixed(2) >= -0.27 &&
          RhyteItTorusRef.current.position.x.toFixed(2) <= 0.20 &&
            RhyteItTorusRef.current.position.y.toFixed(2) == -0.88 &&
          RhyteItTorusRef.current.position.z.toFixed(2) >= -1.07 && 
          RhyteItTorusRef.current.position.z.toFixed(2) <= 0.45 &&
            RhyteItTorusRef.current.rotation.x.toFixed(2) == -1.55 && 
          RhyteItTorusRef.current.rotation.y.toFixed(2) == 0.00 &&
            RhyteItTorusRef.current.rotation.z.toFixed(2) == 0.30) {

 //     console.log("RhyteItLogo.useFrame.RhyteItTorusRef.Line 7 - Bottom Line")
   
      
        if (RhyteItTorusRef.current.position.x < 0.20) {
          RhyteItTorusRef.current.position.x += 0.003;
        }

        if (RhyteItTorusRef.current.position.z <= 0.45) {
          RhyteItTorusRef.current.position.z += 0.01*props.RotationFactor;
        }


      RhyteItTorusRef.current.rotation.y = 0
      RhyteItTorusRef.current.rotation.z = 0.30;

      
  //  console.log("RhyteItTorusRef.position.x(", RhyteItTorusRef.current.position.x.toFixed(2), "), y(", RhyteItTorusRef.current.position.y.toFixed(2), "), z(", RhyteItTorusRef.current.position.z.toFixed(2),  ")")
   //   console.log("RhyteItTorusRef.rotation.x(", RhyteItTorusRef.current.rotation.x.toFixed(2), "), y(", RhyteItTorusRef.current.rotation.y.toFixed(2), "), z(", RhyteItTorusRef.current.rotation.z.toFixed(2),  ")") 

    }          

 
    
    //Left Bottom Corner"
 
    if (
        RhyteItTorusRef.current.position.x.toFixed(2) >= 0.19 &&
        RhyteItTorusRef.current.position.x.toFixed(2) < 0.28 && 
          RhyteItTorusRef.current.position.y.toFixed(2) >= -0.89 &&
          RhyteItTorusRef.current.position.y.toFixed(2) <= -0.53 &&
        RhyteItTorusRef.current.position.z.toFixed(2) >= 0.45 && 
        RhyteItTorusRef.current.position.z.toFixed(2) <= 0.60 && 
          RhyteItTorusRef.current.rotation.x.toFixed(2) <= -1.55 && 
          RhyteItTorusRef.current.rotation.x.toFixed(2) >= -3.15 && 
        RhyteItTorusRef.current.rotation.y.toFixed(2) == 0.00 &&
          RhyteItTorusRef.current.rotation.z.toFixed(2) <= 0.30 &&
          RhyteItTorusRef.current.rotation.z.toFixed(2) >= 0.00) {

      
         // RhyteItTorusRef.position.x( 0.20 ), y( -0.89 ), z( 0.45 )
         // RhyteItTorusRef.rotation.x( -1.60 ), y( 0.00 ), z( 0.30 )

         // RhyteItTorusRef.position.x( 0.19 ), y( -0.88 ), z( 0.46 )
         // RhyteItTorusRef.rotation.x( -1.55 ), y( 0.00 ), z( 0.30 )         

   //   console.log("RhyteItLogo.useFrame.RhyteItTorusRef.Line 9 - Left Bottom Corner")

      if (RhyteItTorusRef.current.position.x.toFixed(2)  < 0.27 ) {

          RhyteItTorusRef.current.position.x += 0.0045;     

      } else {
            
          RhyteItTorusRef.current.position.x -= 0.0045;     

      }
      
      if (RhyteItTorusRef.current.rotation.z.toFixed(2)  < 0.18 ) {
        
        RhyteItTorusRef.current.position.y += 0.003;  

      } 
      
      //RhyteItTorusRef.current.position.z += 0.0002;
      RhyteItTorusRef.current.position.z += 0.0007;

      if (RhyteItTorusRef.current.rotation.x.toFixed(2) >= -3.15) {
        RhyteItTorusRef.current.rotation.x -= 0.008;
        if  (RhyteItTorusRef.current.rotation.x.toFixed(2) < -3.15) {
          RhyteItTorusRef.current.rotation.x = -3.15;

        }
      }
        
      RhyteItTorusRef.current.rotation.z -= 0.0015;
     // RhyteItTorusRef.current.rotation.z
      

      
    //  console.log("RhyteItTorusRef.position.x(", RhyteItTorusRef.current.position.x.toFixed(2), "), y(", RhyteItTorusRef.current.position.y.toFixed(2), "), z(", RhyteItTorusRef.current.position.z.toFixed(2),  ")")
    //  console.log("RhyteItTorusRef.rotation.x(", RhyteItTorusRef.current.rotation.x.toFixed(2), "), y(", RhyteItTorusRef.current.rotation.y.toFixed(2), "), z(", RhyteItTorusRef.current.rotation.z.toFixed(2),  ")")    

    }            

    //Left Side Going Up

    if (RhyteItTorusRef.current.position.x.toFixed(2) >= 0.26 && 
          RhyteItTorusRef.current.position.y.toFixed(2) >= -0.53 &&
          RhyteItTorusRef.current.position.y.toFixed(2) <= 0.87 &&
        RhyteItTorusRef.current.position.z.toFixed(2) >= 0.59 && 
          RhyteItTorusRef.current.rotation.x.toFixed(2) == -3.15 && 
        RhyteItTorusRef.current.rotation.y.toFixed(2) == 0.00 &&
          RhyteItTorusRef.current.rotation.z.toFixed(2) == 0.00) {

//      console.log("RhyteItLogo.useFrame.RhyteItTorusRef.Line 10 - Left Side Going Up")  


        RhyteItTorusRef.current.position.y += 0.008;  


   //   console.log("RhyteItTorusRef.position.x(", RhyteItTorusRef.current.position.x.toFixed(2), "), y(", RhyteItTorusRef.current.position.y.toFixed(2), "), z(", RhyteItTorusRef.current.position.z.toFixed(2),  ")")
   //   console.log("RhyteItTorusRef.rotation.x(", RhyteItTorusRef.current.rotation.x.toFixed(2), "), y(", RhyteItTorusRef.current.rotation.y.toFixed(2), "), z(", RhyteItTorusRef.current.rotation.z.toFixed(2),  ")")    

    }     

     // RhyteItLogoRef.current.rotation.z += 0.01*props.RotationFactor;
    // RhyteItTorusRef.position.x( -0.30 ), y( 0.78 ), z( -1.23 )
    // RhyteItTorusRef.rotation.x( 0.00 ), y( 0.00 ), z( 0.00 ) 

    //console.log("Position.x <= 0.26: ", RhyteItTorusRef.current.position.x.toFixed(2) <= 0.26)
    //console.log("Position.x >= 0.40: ", RhyteItTorusRef.current.position.x.toFixed(2) >= 0.40)
    //console.log("Rotation.z == 0.00: ", RhyteItTorusRef.current.rotation.z.toFixed(2) == 0.00)

//Left Top Corner

     if ( RhyteItTorusRef.current.position.x.toFixed(2) <= 0.27 && 
          RhyteItTorusRef.current.position.x.toFixed(2)  >= 0.17 && 
       RhyteItTorusRef.current.position.y.toFixed(2) >= 0.87 &&
       RhyteItTorusRef.current.position.y.toFixed(2) <= 1.00 &&
     RhyteItTorusRef.current.position.z.toFixed(2) <= 0.60 && 
     RhyteItTorusRef.current.position.z.toFixed(2) >= -1.23 && 
        RhyteItTorusRef.current.rotation.x.toFixed(2) <= -3.15 && 
        RhyteItTorusRef.current.rotation.x.toFixed(2) >= -4.72 && 
     RhyteItTorusRef.current.rotation.y.toFixed(2) <= 0.00 &&
       RhyteItTorusRef.current.rotation.z.toFixed(2) >= -0.22) {

   //   console.log("RhyteItLogo.useFrame.RhyteItTorusRef.Line 11 - Left Top Corner")  

      //RhyteItTorusRef.position.x( 0.27 ), y( 0.88 ), z( 0.60 )
      //RhyteItTorusRef.rotation.x( -3.15 ), y( 0.00 ), z( -0.00 )


      if (RhyteItTorusRef.current.position.x >= 0.17) {
           RhyteItTorusRef.current.position.x -= 0.0034;
       }

      if (RhyteItTorusRef.current.position.y < 1.00) {
      RhyteItTorusRef.current.position.y += 0.003;
      }

      RhyteItTorusRef.current.rotation.x -= 0.03;

      if (RhyteItTorusRef.current.rotation.z >= -0.22) {

        RhyteItTorusRef.current.rotation.z -= 0.005;

      }

      RhyteItTorusRef.current.position.z -= 0.0077;


      // RhyteItTorusRef.current.rotation.z



    //  console.log("RhyteItTorusRef.position.x(", RhyteItTorusRef.current.position.x.toFixed(2), "), y(", RhyteItTorusRef.current.position.y.toFixed(2), "), z(", RhyteItTorusRef.current.position.z.toFixed(2),  ")")
    //  console.log("RhyteItTorusRef.rotation.x(", RhyteItTorusRef.current.rotation.x.toFixed(2), "), y(", RhyteItTorusRef.current.rotation.y.toFixed(2), "), z(", RhyteItTorusRef.current.rotation.z.toFixed(2),  ")")    

      }        
  
// Top Side

    if (RhyteItTorusRef.current.position.x.toFixed(2) <= 0.17 && 
        RhyteItTorusRef.current.position.x.toFixed(2) >= -0.80 && 
        RhyteItTorusRef.current.position.y.toFixed(2) <= 1.10 &&
      RhyteItTorusRef.current.position.z.toFixed(2) <= 0.19 && 
      RhyteItTorusRef.current.position.z.toFixed(2) >= -1.06 && 
        RhyteItTorusRef.current.rotation.x.toFixed(2) == -4.74 && 
      RhyteItTorusRef.current.rotation.y.toFixed(2) == 0.00 &&
        RhyteItTorusRef.current.rotation.z.toFixed(2) == -0.22) {

  //    console.log("RhyteItLogo.useFrame.RhyteItTorusRef.Line 12 - Top Side")  

      if (RhyteItTorusRef.current.position.x > -0.22) {
        RhyteItTorusRef.current.position.x -= 0.003;  
      }

      if (RhyteItTorusRef.current.position.y < 1.05) {
        RhyteItTorusRef.current.position.y += 0.003;  
      }




      RhyteItTorusRef.current.position.z -= 0.01;  


 //     console.log("RhyteItTorusRef.position.x(", RhyteItTorusRef.current.position.x.toFixed(2), "), y(", RhyteItTorusRef.current.position.y.toFixed(2), "), z(", RhyteItTorusRef.current.position.z.toFixed(2),  ")")
  //    console.log("RhyteItTorusRef.rotation.x(", RhyteItTorusRef.current.rotation.x.toFixed(2), "), y(", RhyteItTorusRef.current.rotation.y.toFixed(2), "), z(", RhyteItTorusRef.current.rotation.z.toFixed(2),  ")")    

    }     

    //position={[-0.3, 0.89, -1.23]}
    //rotation={[0, 0, 0]}

// Right Uppper Corner

    if (  RhyteItTorusRef.current.position.x.toFixed(2) <= -0.21 && 
          RhyteItTorusRef.current.position.x.toFixed(2)  >= -0.30 && 
        RhyteItTorusRef.current.position.y.toFixed(2) <= 1.05 &&
        RhyteItTorusRef.current.position.y.toFixed(2) >= 0.89 &&
          RhyteItTorusRef.current.position.z.toFixed(2) <= -1.07 && 
          RhyteItTorusRef.current.position.z.toFixed(2) >= -1.18 && 
        RhyteItTorusRef.current.rotation.x.toFixed(2) >= -4.74 && 
        RhyteItTorusRef.current.rotation.x.toFixed(2) <= 0.00 && 
          RhyteItTorusRef.current.rotation.y.toFixed(2) == 0.00 &&
        RhyteItTorusRef.current.rotation.z.toFixed(2) >= -0.22  &&       
        RhyteItTorusRef.current.rotation.z.toFixed(2) <=  0.30) {

   // console.log("RhyteItLogo.useFrame.RhyteItTorusRef.Line 13 - Right Uppper Corner")  

    //RhyteItTorusRef.position.x( -0.30 ), y( 0.89 ), z( -1.23 )
    //RhyteItTorusRef.rotation.x( 0.00 ), y( 0.00 ), z( 0.00 )

   

  if ( FirstTime === true) {
   // console.log("b RhyteItTorusRef.position.x(", RhyteItTorusRef.current.position.x.toFixed(2), "), y(", RhyteItTorusRef.current.position.y.toFixed(2), "), z(", RhyteItTorusRef.current.position.z.toFixed(2),  ")")
   // console.log("b RhyteItTorusRef.rotation.x(", RhyteItTorusRef.current.rotation.x.toFixed(2), "), y(", RhyteItTorusRef.current.rotation.y.toFixed(2), "), z(", RhyteItTorusRef.current.rotation.z.toFixed(2),  ")") 
  } 

  if (RhyteItTorusRef.current.rotation.x.toFixed(2) == -4.74) {

    RhyteItTorusRef.current.rotation.x = -1.55;  
    RhyteItTorusRef.current.rotation.y = 0.00; 
    RhyteItTorusRef.current.rotation.z = 0.30; 
  
  }        


    if (RhyteItTorusRef.current.position.x >= -0.30) {
        RhyteItTorusRef.current.position.x -= 0.0034;
    }

    if (RhyteItTorusRef.current.position.y > 0.89) {
      RhyteItTorusRef.current.position.y -= 0.003;
    }

    if (RhyteItTorusRef.current.position.z > -1.18) {
      RhyteItTorusRef.current.position.z -= 0.003;
    }

    if (RhyteItTorusRef.current.rotation.x < 0.00) {
  
      
      if (RhyteItTorusRef.current.rotation.x >= -3.15 && RhyteItTorusRef.current.rotation.x <= -1.55) {  

        RhyteItTorusRef.current.rotation.x -= 0.03;

        
        if (RhyteItTorusRef.current.rotation.x.toFixed(2) < -3.15) {
          RhyteItTorusRef.current.rotation.x = 0.00;

          
        }         

        if (RhyteItTorusRef.current.rotation.x.toFixed(2) == -3.15) {
          RhyteItTorusRef.current.rotation.x = 0.00;     
        } 

      }         
     

      if (RhyteItTorusRef.current.rotation.x > 0.00) {
        RhyteItTorusRef.current.rotation.x = 0.00;
      }

    }

    if (RhyteItTorusRef.current.rotation.z >= 0.00 && RhyteItTorusRef.current.rotation.x < -1.55 ) {

      RhyteItTorusRef.current.rotation.z -= 0.03;

      if (RhyteItTorusRef.current.rotation.z < 0.00) {
        RhyteItTorusRef.current.rotation.z = 0.00;
      }


    }    
   

    if ( FirstTime === true) {
     // console.log("a RhyteItTorusRef.position.x(", RhyteItTorusRef.current.position.x.toFixed(2), "), y(", RhyteItTorusRef.current.position.y.toFixed(2), "), z(", RhyteItTorusRef.current.position.z.toFixed(2),  ")")
    //  console.log("a RhyteItTorusRef.rotation.x(", RhyteItTorusRef.current.rotation.x.toFixed(2), "), y(", RhyteItTorusRef.current.rotation.y.toFixed(2), "), z(", RhyteItTorusRef.current.rotation.z.toFixed(2),  ")") 
    } 

    FirstTime = false

 //   console.log("RhyteItTorusRef.position.x(", RhyteItTorusRef.current.position.x.toFixed(2), "), y(", RhyteItTorusRef.current.position.y.toFixed(2), "), z(", RhyteItTorusRef.current.position.z.toFixed(2),  ")")
 //   console.log("RhyteItTorusRef.rotation.x(", RhyteItTorusRef.current.rotation.x.toFixed(2), "), y(", RhyteItTorusRef.current.rotation.y.toFixed(2), "), z(", RhyteItTorusRef.current.rotation.z.toFixed(2),  ")")  

}            


     

  })

  async function  ChangeBlobColor(event) {
    try {             
      

     if (BlobColor === "#CC6633") {

      setBlobColor("#b85c2e");

     } else if (BlobColor === "#b85c2e") {

      setBlobColor("#d17547");

     } else {

      setBlobColor("#CC6633");

     }
      
    } catch (error) {
        console.error('RhyteItBlob.ChaneBlobColor.Error: ', error, error.stack);
    }
  }

  const SphereTexture = useTexture ({
    map:  "./textures/Concrete_019_BaseColor.jpg",
    normalMap: "./textures/Concrete_019_Normal.jpg",
    roughnessMap: "./textures/Concrete_019_Roughness.jpg",
    aoMap: "./textures/Concrete_019_AmbientOcclusion.jpg"
  });


  // configure font mesh
  const textOptions = {
    font,
    size: 1,
    height: 0
  };  

  return (
    <group {...props} dispose={null}>
    <group ref={RhyteItLogoRef}  name="Scene">
      <mesh
        name="Cube"
        castShadow
        receiveShadow
        ref={RhyteItCubeRef}
        geometry={nodes.Cube.geometry}
        material={materials.Material}        
        position={[0, 0.1, -0.32]}
        rotation={[0, 0.3, 0]}
        scale={[0.16, 1, 1]}>
        <meshStandardMaterial color={CubeColor} />
        </mesh>
      <mesh
        name="Button"
        position={[0, 0, .45]}
        rotation={[0, 1.8, 0]}
        scale={[0.45, 0.44, 0.44]}
        onClick={ (e) => ChangeBlobColor(e) } >
        <textGeometry attach="geometry" args={["three", textOptions]} />
        <meshStandardMaterial color={BlobColor}/>
        </mesh>
        
        <mesh
          name="Torus"
          ref={RhyteItTorusRef} 
          castShadow
          receiveShadow
          geometry={nodes.Torus.geometry}
          material={materials.RhyteItOrange}
          position={[-0.3, 0.89, -1.18]}
          rotation={[0, 0, 0]}
          scale={[0.35, 0.38, 0.33]}>
          <meshStandardMaterial color={TorusColor} />
          </mesh>
    </group>
  </group>   
  )
}


useGLTF.preload("/RhyteItLogo.glb");

export default Button3D