import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { uploadData } from 'aws-amplify/storage';

import  { }  from '../../graphql/queries'
import  { createAudienceGeoInfo, createRhyteItEmails, updateRhyteItEmails, deleteRhyteItEmails}  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import {ErrorBoundary} from 'react-error-boundary'
import Header from "../RhyteItComponents/Header/Header";
import {motion as m} from "framer-motion";
import { UserContext, CognitoGroupContext} from '../../BusinessLogic/Hooks/UserContext';
import {GetGroupRoleAccess, RhyteItLogging, ReturnUserToLandingPage} from '../../BusinessLogic/UserManagement';
import { fetchAuthSession } from 'aws-amplify/auth';

import { useNavigate, useLocation } from "react-router-dom";
import EmailAttachementUploadTable from './EmailAttachementUploadTable';

import {
  Button ,
  Authenticator,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TextAreaField,
  VisuallyHidden,
  Card,
  TextField,
  View,
  Flex,
  Text,
  DropZone

} from '@aws-amplify/ui-react';
import styles from './EmailCompose.module.css';
import styled from 'styled-components'
import {get_uuidv4} from '../../BusinessLogic/uuidv4';


function ErrorFallback({error, resetErrorBoundary}) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

const LabelText = styled.div`
                        color: white;
                        font-size: 0.5em !important;
                        `;


const EmailCompose = (props) => {      

        const client = generateClient();

        const vBreadCrumbItems = [{Path: "/", Title:"Home", isCurrent:false},{Path: "/MainMenuV2", Title:"Menu", isCurrent:false},{Path: "/EmailManagement", Title:"EmailManagement", isCurrent:false},{Path: "/EmailCompose", Title:"Email", isCurrent:true}]
     
        let debugConsoleOutPut = true
        let navigate = useNavigate(); 

        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)    
        const [RecordUpdated, setRecordUpdated] = useState(false)  
 

        const {Users, setUsers} = useContext(UserContext);    
        const {CognitoGroup, setCognitoGroup} = useContext(CognitoGroupContext);   

        const [Email, setEmail] = useState()  
        const [ReplyEmail, setReplyEmail] = useState()  

        const [EmailAttachements, setEmailAttachements] = useState([]);
        const hiddenInput = useRef(null);

        const [ShowEmailAttachementsUploadTable, setShowEmailAttachementsUploadTable] = useState(false)  
        const [EmailAttachementUpdateKey, setEmailAttachementUpdateKey] = useState(false)  
        

        useEffect(() => {
          
          
        },[]) 

        useEffect(() => {
                
              (async function() {
                try {  

                      if (CognitoGroup !== undefined) {

                          let   GetGroupRoleAccessResponse = await GetGroupRoleAccess(CognitoGroup, "rhyteitAdmins")
                      
                          if (GetGroupRoleAccessResponse) {      
                                    NewEmail()                                
                                  }
                                  
                      } else {

                          const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
                        // console.log("EmailManagement.useEffect[CognitoGroup].idToken:  ",idToken);
                        // console.log("EmailManagement.useEffect[CognitoGroup].accessToken:  ",accessToken);

                          if (idToken !== undefined) {
                            setUsers(idToken.payload.email)
                            setCognitoGroup(accessToken["payload"]["cognito:groups"])
                          } else {                        
                            ReturnUserToLandingPage(Users, CognitoGroup)
                          }
                      }
                  } catch (err) {
                    RhyteItLogging(Users, "EmailCompose", "useEffect[CognitoGroup]",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                }
              }
            )();   

        },[CognitoGroup])             


        useEffect(() => {
            
         // console.log("EmailCompose.useEffect[ReplyEmail].ReplyEmail:  ",ReplyEmail)       

        },[ReplyEmail]) 
        
        useEffect(() => {
            
          //console.log("EmailCompose.useEffect[EmailAttachements].EmailAttachements:  ",EmailAttachements) 
          ProcessAttachements()      

        },[EmailAttachements]) 

        async function NewEmail() {
          try {           
            
              setErrorMessage("")              
    
              var vUpdateDateTime = new Date().toISOString()

              var DynamoUUID =  get_uuidv4()
    
              let EmailItem = {
                  "id": DynamoUUID,
                  "EmailAccount": "support@rhyteit.com",
                  "From": "support@rhyteit.com",
                  "To": null,
                  "Subject": null,
                  "S3Bucket": "rhyteitsess3receiving",
                  "S3Key": null,
                  "EmailText":  "",
                  "ReplyText":  "",
                  "EmailHtml": null,
                  "EmailRawText": null,
                  "EmailStatus": "new",
                  "ContentType": "text/html",
                  "Size": 1,
                  "SizeUnits": "bytes",
                  "ContentTransferEncoding": "quoted-printable",
                  "InReplyTo": "",
                  "MessageID": DynamoUUID,
                  "CharSet": "utf-8",
                  "CreationName": Users,
                  "CreationDateTime": vUpdateDateTime,
                  "UpdateName": Users,
                  "UpdateDateTime": vUpdateDateTime,
              }
        
                //console.log("EmailManagement.NewEmail.EmailItem:  ",EmailItem)  

                setEmail(EmailItem)
    
            } catch (err) { 
              console.error(err)
              ErrorMessageStr = ("EmailManagement.NewEmail.Error: ", err, err.stack)
              console.error(ErrorMessageStr)
              setErrorMessage(ErrorMessageStr)
          }        
        }       
                

        const onFilePickerChange = (event) => {
          console.log("EmailCompose.onFilePickerChange.event.target:  ",event.target) 
          
          const { files  } = event.target;
          
          console.log("EmailCompose.onFilePickerChange.files :  ",files ) 

          if (!files  || files.length === 0) {
            return;
          }
          
          console.log("EmailCompose.onFilePickerChange.Array.from(vEmailAttachements):  ",Array.from(files )) 

          setEmailAttachements(Array.from(files ));
          
        };
      
        function EmailText_handleChange(event, data) {
          try {              
                  
              Email.EmailText = event.target.value
              // //console.log("EmailCompose.Subscribed_handleChange.RhyteItProduct[elementPos].Subscribed: ",RhyteItProduct[elementPos].Subscribed)
     
              setEmail(Email) 
     
    
            } catch (err) { 
              console.error("EmailCompose.EmailText_handleChange.Error:  ".err, err.stack); 
          }        
        }       
        
        function Subject_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("EmailCompose.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("EmailCompose.Subscribed_handleChange.event: ",event)
              }
    
    
              Email.Subject = event.target.value
              // //console.log("EmailCompose.Subscribed_handleChange.RhyteItProduct[elementPos].Subscribed: ",RhyteItProduct[elementPos].Subscribed)
     
              setEmail(Email) 
     
    
            } catch (err) { 
              console.error("EmailCompose.Subject_handleChange.Error:  ".err, err.stack); 
          }        
        }         
        
        function From_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("EmailCompose.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("EmailCompose.Subscribed_handleChange.event: ",event)
              }
    
    
              Email.From = event.target.value
              // //console.log("EmailCompose.Subscribed_handleChange.RhyteItProduct[elementPos].Subscribed: ",RhyteItProduct[elementPos].Subscribed)
     
              setEmail(Email) 
     
    
            } catch (err) { 
              console.error("EmailCompose.From_handleChange.Error:  ".err, err.stack); 
          }        
        }            
                
        function To_handleChange(event, data) {
          try {              
    
              Email.To = event.target.value

              setEmail(Email)      
    
            } catch (err) { 
              console.error("EmailCompose.To_handleChange.Error:  ".err, err.stack); 
          }        
        }      

        async function SaveEmail(event, data) {
          try {           
            
              setErrorMessage("")
              let updateRhyteItEmailsResponse = "" 
              

              var vUpdateDateTime = new Date().toISOString()
      
              Email.UpdateDateTime = vUpdateDateTime       
              Email.UpdateName = Users
  
              var DynamoUUID =  get_uuidv4()
    
              let EmailItem = {
                  "id": Email.id,
                  "EmailAccount": Email.EmailAccount,
                  "From": Email.From,
                  "To": Email.To,
                  "Subject": Email.Subject,
                  "S3Bucket": Email.S3Bucket,
                  "S3Key": Email.S3Key,
                  "EmailText":  Email.EmailText,
                  "ReplyText":  Email.ReplyText,
                  "EmailHtml": Email.EmailHtml,
                  "EmailRawText": Email.EmailRawText,
                  "EmailStatus": "draft",
                  "ContentType": Email.ContentType,
                  "Size": Email.Size,
                  "SizeUnits": Email.SizeUnits,
                  "ContentTransferEncoding": Email.ContentTransferEncoding,
                  "InReplyTo": "",
                  "MessageID": Email.MessageID,
                  "CharSet": Email.CharSet,
                  "CreationName": Email.CreationName,
                  "CreationDateTime": Email.CreationDateTime,
                  "UpdateName": Email.UpdateName,
                  "UpdateDateTime": Email.UpdateDateTime,
              }


                console.log("EmailCompose.SendEmail.EmailItem:  ",EmailItem)                      

                const createRhyteItEmailsResponse = await client.graphql({
                  query: createRhyteItEmails,
                  variables: {
                    input: EmailItem
                  }
                });  


                console.log("EmailCompose.SendEmail.createRhyteItEmailsResponse:  ",createRhyteItEmailsResponse)  

              navigate('/EmailManagement', 
                {
                }
              );                
         
    
            } catch (err) { 
              console.error(err)
              ErrorMessageStr = ("EmailCompose.SendEmail.Error: ", err, err.stack)
              console.error(ErrorMessageStr)
              setErrorMessage(ErrorMessageStr)
          }        
        }     
        
        async function SendEmail(event, data) {
          try {           
            
              setErrorMessage("")
              let updateRhyteItEmailsResponse = "" 
              

              var vUpdateDateTime = new Date().toISOString()
      
              Email.UpdateDateTime = vUpdateDateTime       
              Email.UpdateName = Users
  
              var DynamoUUID =  get_uuidv4()
    
              let EmailItem = {
                  "id": Email.id,
                  "EmailAccount": Email.EmailAccount,
                  "From": Email.From,
                  "To": Email.To,
                  "Subject": Email.Subject,
                  "S3Bucket": Email.S3Bucket,
                  "S3Key": Email.S3Key,
                  "EmailText":  Email.EmailText,
                  "ReplyText":  Email.ReplyText,
                  "EmailHtml": Email.EmailHtml,
                  "EmailRawText": Email.EmailRawText,
                  "EmailStatus": "SEND",
                  "ContentType": Email.ContentType,
                  "Size": Email.Size,
                  "SizeUnits": Email.SizeUnits,
                  "ContentTransferEncoding": Email.ContentTransferEncoding,
                  "InReplyTo": Email.InReplyTo,
                  "MessageID": Email.MessageID,
                  "CharSet": Email.CharSet,
                  "CreationName": Email.CreationName,
                  "CreationDateTime": Email.CreationDateTime,
                  "UpdateName": Email.UpdateName,
                  "UpdateDateTime": Email.UpdateDateTime,
              }


                console.log("EmailCompose.SendEmail.EmailItem:  ",EmailItem)                      

                const createRhyteItEmailsResponse = await client.graphql({
                  query: createRhyteItEmails,
                  variables: {
                    input: EmailItem
                  }
                });  


                console.log("EmailCompose.SendEmail.createRhyteItEmailsResponse:  ",createRhyteItEmailsResponse)  

              navigate('/EmailManagement', 
                {
                }
              );                
         
    
            } catch (err) { 
              console.error(err)
              ErrorMessageStr = ("EmailCompose.SendEmail.Error: ", err, err.stack)
              console.error(ErrorMessageStr)
              setErrorMessage(ErrorMessageStr)
          }        
        }     
        
        async function DeleteEmail(event,data) {
          try {
    

    
              const DeleteEmails = {
                id: Email.id,
                _version: Email._version
              }
             
              const ReturnMessage = await client.graphql({
                query: deleteRhyteItEmails,
                variables: {
                  input: DeleteEmails
                }
              });                
    
              setEmail([])
    
              setErrorMessage("Deleted")         
                    
              } catch (err) { 
    
              ErrorMessageStr = "EmailCompose.DeleteEmail.Error: " + err + " " + err.stack
    
              console.error(ErrorMessageStr); 
              
              setErrorMessage(ErrorMessageStr)         
            }               
          
        }           

        function GoToEmailManagement()  {

          navigate('/EmailManagement', 
          {
            state: {
                RedditUser:Users
            }
          })

        }      

        async function ProcessAttachements() {

          try {

            if (EmailAttachements.length > 0) {

              EmailAttachements.map((file) => (             
        
                UploadAttachement(file)           
              
              ))               
            }
          } catch (error) {
            console.log('Error : ', error);
          }
          
          //console.log("EmailCompose.ProcessAttachements.pFile: "); 
          setEmailAttachementUpdateKey(new Date().toISOString())
          setShowEmailAttachementsUploadTable(true)
          
        }   

        async function UploadAttachement(pFile) {
          
          console.log("EmailCompose.UploadAttachement.pFile: ", pFile);  

          try {

            let vFileName = "EmailAttachements/" + Email.id + '_' + pFile.name

            const result = await uploadData({
              key: vFileName,
              data: pFile
            }).result;
            console.log('Succeeded: ', result);
          } catch (error) {
            console.log('Error : ', error);
          }
        }     

    return ( 
    <m.div
      key="RhyteItEmailCompose"
      initial={{y: "100%"}}
      animate={{y: "0%"}}
      transition={{duration:0.75, ease: "easeOut"}}
      exit={{ opacity: 1 }}
     >
      <Authenticator  className={styles.AuthenticatorLogin} socialProviders={[]} hideSignUp={true}>
        {({ signOut, user }) => (   
          <div className={styles.SystemAdminBackground}>
            <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                >   
                    <div  className={styles.GridContainer}>       
                      <Header Title="Email" SubTitle="New" BreadCrumbItems ={vBreadCrumbItems} pageSource="EmailCompose"/>       
                        <div className={styles.FormContainer}>
                          <View
                            className={styles.View}
                            width="100%"
                          >
                            <Card className={styles.Card} width="100%">
                            {Email !== undefined && (    
                              <Flex direction="row" alignItems="flex-start">
                                <Flex
                                  direction="column"
                                  alignItems="flex-start"
                                  gap="3em"
                                >
                                <Flex>
                                  <Button className={styles.RedditButtons}                                       
                                          onClick={ (e) => SendEmail(e, Email) }
                                          variation="primary">Send</Button>
                                  <Button className={styles.RedditButtons}                                       
                                          onClick={ (e) => DeleteEmail(e, Email) }
                                          variation="primary">Delete</Button>
                                  <Button className={styles.RedditButtons}                                       
                                          onClick={ (e) => SaveEmail(e, Email) }
                                          variation="primary">Save</Button>
                                  <Button className={styles.RedditButtons}                                       
                                          onClick={ (e) => GoToEmailManagement(e, Email) }
                                          variation="primary">Back</Button>
                                  <Button className={styles.RedditButtons}                                       
                                          onClick={ (e) => GoToEmailManagement(e, Email) }
                                          variation="primary">Add Attachement</Button>
                                </Flex>   
                                
                               
                                <Flex>      
                                      <Text   
                                        className={styles.TextFieldHeaderLabel}
                                        >
                                          Created:
                                      </Text>
                                      <Text size="small" className={styles.Text}>
                                        {new Date( Email.CreationDateTime).toLocaleString("en-US")}
                                      </Text>    
                                      <Text   
                                        className={styles.TextFieldHeaderLabel}
                                        >
                                          Updated:
                                      </Text>
                                      <Text size="small" className={RecordUpdated === false ? styles.Text :styles.TextUpdated  }>
                                        {new Date( Email.UpdateDateTime).toLocaleString("en-US")}
                                      </Text>
                                    </Flex>
                                    <Table>
                                      <TableBody>                                 
                                        <TableRow className={styles.TableRow}>
                                          <TableCell  className={styles.RowLabelColumn}>         
                                            <Text   
                                              className={styles.TextFieldLabel}
                                              >
                                                MessageID
                                            </Text>
                                          </TableCell>
                                          <TableCell className={styles.RowData}  colspan="3">
                                          <Text   
                                              className={styles.TextField}
                                              >
                                                {Email.MessageID}
                                            </Text>
                                          </TableCell>
                                        </TableRow >                                     
                                        <TableRow className={styles.TableRow}>
                                          <TableCell className={styles.RowLabelColumn}>         
                                            <Text   
                                              className={styles.TextFieldLabel}
                                              >
                                                From
                                            </Text>
                                          </TableCell>
                                          <TableCell className={styles.RowData}  colspan="3">     
                                            <TextField   
                                              className={styles.TextField}
                                              onChange={ (e) => From_handleChange(e, Email) }               
                                              defaultValue={Email.From}/>    
                                          </TableCell>
                                        </TableRow >                                     
                                        <TableRow className={styles.TableRow}>
                                          <TableCell className={styles.RowLabelColumn}>         
                                            <Text   
                                              className={styles.TextFieldLabel}
                                              >
                                                To
                                            </Text>
                                          </TableCell>
                                          <TableCell className={styles.RowData}  colspan="3">     
                                            <TextField   
                                              className={styles.TextField}
                                              onChange={ (e) => To_handleChange(e, Email) }               
                                              defaultValue={Email.To}/>   
                                          </TableCell>
                                        </TableRow>                                      
                                        <TableRow className={styles.TableRow}>
                                          <TableCell className={styles.RowLabelColumn} >         
                                            <Text   
                                              className={styles.TextFieldLabel}
                                              >
                                                Subject
                                            </Text>
                                          </TableCell>
                                          <TableCell colspan="3">
                                          <TextField   
                                              className={styles.TextField}
                                              onChange={ (e) => Subject_handleChange(e, Email) }               
                                              defaultValue={Email.Subject}/>    
                                          </TableCell>
                                        </TableRow>                                 
                                        <TableRow>
                                          <TableCell className={styles.RowLabelColumn}>         
                                            <Text   
                                              className={styles.TextFieldLabel}
                                              >
                                                Text
                                            </Text>   
                                          </TableCell>
                                          <TableCell colspan="3"> 
                                          <TextAreaField 
                                              className={styles.TextAreaField} 
                                              name="Email"     
                                              placeholder="Enter email text"                   
                                              defaultValue={Email.EmailText}
                                              onChange={ (e) => EmailText_handleChange(e, Email) }
                                              rows={5}
                                              columns={3}/>                                                       
                                          </TableCell>
                                        </TableRow>                                                                               
                                        <TableRow className={styles.TableRow}>
                                          <TableCell  className={styles.RowLabelColumn}>         
                                            <Text   
                                              className={styles.TextFieldLabel}
                                              >
                                                EmailStatus
                                            </Text>
                                          </TableCell>
                                          <TableCell className={styles.RowData}>
                                          <Text   
                                              className={styles.TextField}
                                              >
                                                {Email.EmailStatus}
                                            </Text>
                                          </TableCell>
                                          <TableCell  className={styles.RowLabelColumn}>         
                                            <Text   
                                              className={styles.TextFieldLabel}
                                              >
                                                ContentType
                                            </Text>
                                          </TableCell>
                                          <TableCell className={styles.RowData}>
                                          <Text   
                                              className={styles.TextField}
                                              >
                                                {Email.ContentType}
                                            </Text>
                                          </TableCell>
                                        </TableRow >                                        
                                        <TableRow className={styles.TableRow}>
                                          <TableCell  className={styles.RowLabelColumn}>         
                                            <Text   
                                              className={styles.TextFieldLabel}
                                              >
                                                Size
                                            </Text>
                                          </TableCell>
                                          <TableCell className={styles.RowData}>
                                          <Text   
                                              className={styles.TextField}
                                              >
                                                {Email.Size}
                                            </Text>
                                          </TableCell>
                                          <TableCell  className={styles.RowLabelColumn}>         
                                            <Text   
                                              className={styles.TextFieldLabel}
                                              >
                                                SizeUnits
                                            </Text>
                                          </TableCell>
                                          <TableCell className={styles.RowData}>
                                          <Text   
                                              className={styles.TextField}
                                              >
                                                {Email.SizeUnits}
                                            </Text>
                                          </TableCell>
                                        </TableRow >                                       
                                        <TableRow className={styles.TableRow}>
                                          <TableCell  className={styles.RowLabelColumn}>         
                                            <Text   
                                              className={styles.TextFieldLabel}
                                              >
                                                ContentTransferEncoding
                                            </Text>
                                          </TableCell>
                                          <TableCell className={styles.RowData}>
                                          <Text   
                                              className={styles.TextField}
                                              >
                                                {Email.ContentTransferEncoding}
                                            </Text>
                                          </TableCell>
                                          <TableCell  className={styles.RowLabelColumn}>         
                                            <Text   
                                              className={styles.TextFieldLabel}
                                              >
                                                CharSet
                                            </Text>
                                          </TableCell>
                                          <TableCell className={styles.RowData}>
                                          <Text   
                                              className={styles.TextField}
                                              >
                                                {Email.CharSet}
                                            </Text>
                                          </TableCell>
                                        </TableRow >    
                                      </TableBody>
                                    </Table>                                    
                                    <div >
                                      <Text className={styles.MessageBox}>{ErrorMessage}</Text>
                                    </div>  
                                    {ShowEmailAttachementsUploadTable && EmailAttachements.length > 0 && (<EmailAttachementUploadTable   key={EmailAttachementUpdateKey}  EmailAttachements={EmailAttachements}/>)  }                                                                                                      
                                    <DropZone
                                        onDropComplete={({ acceptedFiles, rejectedFiles }) => {
                                          setEmailAttachements(acceptedFiles);
                                        }}
                                      >
                                        <Flex direction="column" alignItems="center">
                                          <Text>Drag images here or</Text>
                                          <Button size="small" onClick={() => hiddenInput.current.click()}>
                                            Browse
                                          </Button>
                                        </Flex>
                                        <VisuallyHidden>
                                          <input
                                            type="file"
                                            tabIndex={-1}
                                            ref={hiddenInput}
                                            onChange={onFilePickerChange}
                                            multiple={true}
                                          />
                                        </VisuallyHidden>
                                    </DropZone>   
                                </Flex>                                                  
                              </Flex>
                                  )  }      
                            </Card>
                          </View>           
                        </div>                  
                      </div>  
            </ErrorBoundary>
          </div>   
        )}         
      </Authenticator>  
    </m.div>                      

    )

  }
 

export default EmailCompose;