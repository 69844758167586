
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Text,
  TableBody,
  TextField,  
  Button,
  Image
  } from '@aws-amplify/ui-react';
  import { API , graphqlOperation} from 'aws-amplify';
  import { Link } from "react-router-dom";
  import * as Mutations from '../../../graphql/mutations';  
  import styles from './ApplicantPageViewRow.module.css';  
  import { UserContext,UserAuthenticatedContext,UserSubcribedContext} from '../../../BusinessLogic/Hooks/UserContext';  
  import ApplicantDetail from "./ApplicantDetail";
  import moment from 'moment';

const ApplicantPageViewRow = (props) => {       

  ApplicantPageViewRow.propTypes = {
      RhyteItApplicantPageViewRow: PropTypes.object
      };
  
    const {Users, setUsers} = useContext(UserContext);   
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""
    let debugConsoleOutPut = true

    const [message, setMessage] = useState('');

    const [vRhyteItApplicantPageViewRows, setvRhyteItApplicantPageViewRows] = useState([props.RhyteItApplicantPageViewRow])      

    useEffect(() => {

       // //console.log("ApplicantPageViewRow.useEffect[].props.RhyteItApplicantPageViewRow:  ",props.RhyteItApplicantPageViewRow) 
      
      },[])  




  return (
      <TableBody>  
      {
          vRhyteItApplicantPageViewRows.length > 0 && vRhyteItApplicantPageViewRows.map((vRhyteItApplicantPageViewRow) => (  

          
          <TableRow  className={styles.TableRowBackground} key={vRhyteItApplicantPageViewRow.id}>
            <TableCell className={styles.TableRowData} >  
                <Text  className={styles.TextField}>
                                  {vRhyteItApplicantPageViewRow.IPv4
                  }
                </Text>  
              </TableCell>                                        
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {vRhyteItApplicantPageViewRow.Page}
                </Text>  
            </TableCell>                                        
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {vRhyteItApplicantPageViewRow.city}
                </Text>  
            </TableCell>                                         
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {vRhyteItApplicantPageViewRow.state}
                </Text>  
            </TableCell>                                         
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {vRhyteItApplicantPageViewRow.country_name}
                </Text>  
            </TableCell>                                         
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {vRhyteItApplicantPageViewRow.latitude}
                </Text>  
            </TableCell>                                        
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {vRhyteItApplicantPageViewRow.longitude}
                </Text>  
            </TableCell>                                      
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {moment(vRhyteItApplicantPageViewRow.CreationDateTime).utc().format("YYYY-MM-DD").toLocaleString()}
                </Text>  
            </TableCell>                                                            
          </TableRow>  
        ))
      }                
      </TableBody>
    
  )
}

export default ApplicantPageViewRow;

