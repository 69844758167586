
import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead
  } from '@aws-amplify/ui-react'; 
import PrivateKey from './CustomerPurchaseRow';
import styles from './CustomerPurchasesTable.module.css';


const CustomerPurchasesTable = (props) => {       

  CustomerPurchasesTable.propTypes = {
      RhyteItCustomerPurchases: PropTypes.array,
      Environment: PropTypes.string,
      RhyteItUser: PropTypes.array,
      CustomerEmailVerified: PropTypes.string
      };

    let debugConsoleOutPut = true

  

    const [RhyteItCustomerPurchasesTable, setRhyteItCustomerPurchasesTable] = useState(props.RhyteItCustomerPurchases)   
    const [vEnvironment, setvEnvironment] = useState(props.Environment)   
    const [ShowTable, setShowTable] = useState(false);
    const [CustomerEmailVerified, setCustomerEmailVerified] = useState(props.CustomerEmailVerified);   

    useEffect(() => {
      try {        
        
          if (debugConsoleOutPut === true ) {
            console.log("CustomerPurchasesTable.useEffect[].RhyteItCustomerPurchase: ",props.RhyteItCustomerPurchases)
            //console.log("CustomerPurchasesTable.useEffect[].Environment: ",props.Environment)
          }  

          if (Object.keys(RhyteItCustomerPurchasesTable).length > 0) {
            setShowTable(true)
          } 
        

        } catch (err) {
          console.error('CustomerPurchasesTable.useEffect[].ERROR: ', err, err.stack); 
      }      

    },[])  



  return (  
      <Table className={styles.Table}   highlightOnHover={true}>   
          <TableHead>          
            <TableRow className={styles.TableHeaderRow}>
              <TableCell className={styles.TableHeaderRowCellsWide} >Product</TableCell> 
              <TableCell className={styles.TableHeaderRowCellsActions} colSpan="1" >Actions</TableCell> 
            </TableRow>                                      
          </TableHead>       
          
              {
                ShowTable && RhyteItCustomerPurchasesTable.length > 0 &&  RhyteItCustomerPurchasesTable && RhyteItCustomerPurchasesTable.map((RhyteItCustomerPurchaseRow) => (                      
                  (<PrivateKey   key={RhyteItCustomerPurchaseRow.id}  RhyteItCustomerPurchaseRow={RhyteItCustomerPurchaseRow} Environment={vEnvironment}  CustomerEmailVerified={CustomerEmailVerified}/>)        
                  ))
              }                    
        </Table>
    
  )
}

export default CustomerPurchasesTable;


