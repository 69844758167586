
import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead,
  Text,
  Flex
  } from '@aws-amplify/ui-react'; 
import SBSMArticleRow from './SBSMNewsRow';  
import styles from './SBSMNewsInfoSection.module.css';
import {RhyteItLogging} from '../../BusinessLogic/UserManagement';

const SBSMNewsInfoSection = (props) => {       

  SBSMNewsInfoSection.propTypes = {
      };

    let debugConsoleOutPut = true


    const [updatedKey, setupdatedKey] = useState(Date.now())  
    const [vArticleCount, setvArticleCount] = useState()
    const [vSourceCount, setvSourceCount] = useState()
    const [vPlatformCount, setvPlatformCount] = useState()
    const [ShowTable, setShowTable] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
          

      (async function() {
        try {  

              const fetchData = async () => {
                try {
                    const response = await fetch('https://s3.us-west-2.amazonaws.com/blog.rhyteit.com/ACCNewsStats.json');
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const jsonData = await response.json();            
                    setvArticleCount(jsonData["ArticleCount"]);       
                    setvSourceCount(jsonData["SourceCount"]);       
                    setvPlatformCount(jsonData["PlatformCount"]);
                } catch (error) {
                    setError(error);
                } finally {
                    setLoading(false);
                }
            };
        
            fetchData();

            } catch (err) {              
              RhyteItLogging("NA", "SBSMNews", "useEffect[]",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          }
        }
      )();   
  
      
    },[]) 


    useEffect(() => {
 
      if (ShowTable !== undefined) {
        setShowTable(true)
      }

    }, [vPlatformCount]);      

    
      

  return ( 
    <div>
    {
      ShowTable === true && vSourceCount !== undefined &&(
        <Flex
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          alignContent="flex-start"
          wrap="wrap"
          gap="2rem"
          className = {styles.MasterContainer}>                   
          <Flex
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
            className={styles.LogoTitleRow}>
              <Text className = {styles.Text}>News Sources Monitored:</Text> 
              <Text className = {styles.Highlight}>{vSourceCount.toLocaleString()}</Text>
          </Flex> 
          <Flex
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
            className={styles.LogoTitleRow}>
              <Text className = {styles.Text}>Adult Content Platforms Monitored:</Text> 
              <Text className = {styles.Highlight}>{vPlatformCount}</Text>
          </Flex> 
          <Flex
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
            className={styles.LogoTitleRow}>
              <Text className = {styles.Text}>Articles indexed and analyzed:</Text> 
              <Text className = {styles.Highlight}>{vArticleCount.toLocaleString()}</Text>
          </Flex>   
        </Flex>                 
                )
              } 
    </div>
  )
}

export default SBSMNewsInfoSection;


