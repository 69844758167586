

import {listUsers} from '../graphql/queries'
import {createUsers}  from '../graphql/mutations'
import { generateClient } from 'aws-amplify/api';
import { fetchUserAttributes } from 'aws-amplify/auth';

import {get_uuidv4} from './uuidv4';

import {
    fetchAuthSession,
    getCurrentUser
  } from 'aws-amplify/auth';
  import  { createRhyteItLog}  from '../graphql/mutations'


var SystemUserInfo = ""
let debugConsoleOutPut = true
const client = generateClient();

export async function  GetUserInfo() {
    try {

    
        let SystemUserInfo = ""

        SystemUserInfo = async () => {
            const {
              username,
              authFlowType: authenticationFlowType
            } = await getCurrentUser();
          
            const {
              tokens: session
            } = await fetchAuthSession();
          
            // Note that session will no longer contain refreshToken and clockDrift
            return {
              username,
              session,
              authenticationFlowType
            };
          }

        return SystemUserInfo

} catch (err) {
    console.error('UserManagement.GetUserInfo.Error2', err)    
  }
}   

export async function fetchUserId() {
    try {    
    
        const client = generateClient();

        //console.log("UserManagement.fetchUserId.Start")

        let Users = ""
        let SystemUserInfo = await GetUserInfo()

        const {
          username
        } = await getCurrentUser();        

        
      //  //console.log("UserManagement.fetchUserId.username: ", username)

        if (SystemUserInfo !== undefined) {

            const variables = {
                filter: {
                    UserId: {
                        eq: SystemUserInfo
                    }
                }
              };

            const UserData  = await client.graphql({
                query: listUsers,
                variables: variables
              });            


            Users = UserData.data.listUsers.items                    
    
            if (debugConsoleOutPut === true) {

                //console.log("UserManagement.fetchUserId.Users: ", Users)

            }

            var NewUser = Users.length 

      //  }
        
        if (debugConsoleOutPut === true) {
            //console.log("UserManagement.fetchUserId.NewUser: ", NewUser)
            
        }

    }

        return Users                           

    } catch (err) { 
             console.error("UserManagement.fetchUserId.Error:   ",err, err.stack); 
             }
}      
    
export async function DoesUserExist(pUserAuthenticateId) {
    try {
    
        const client = generateClient();


        const variables = {
            filter: {
                UserId: {
                    eq: pUserAuthenticateId
                }
            }
          };

        const UserData  = await client.graphql({
            query: listUsers,
            variables: variables
          });            



        let UserCount = UserData.data.listUsers.items.length
        
        
    if (debugConsoleOutPut === true) {

        //console.log("UserManagement.DoesUserExist.UserCount: ", UserCount)
        
    }
    
        return UserCount                           

    } catch (err) { console.error(err, err.stack); 
                }
}       

async function User_Add() {
try {
    var pAwsDateTime = new Date(Date.now()).toISOString()
    
    const client = generateClient();

    var DynamoUUID =    get_uuidv4() 

    let SaveUser = {
        id: DynamoUUID ,
        UserId: SystemUserInfo,
        CreationName: SystemUserInfo,
        CreationDateTime: pAwsDateTime,
        UpdateName: SystemUserInfo,
        UpdateDateTime: pAwsDateTime,
        Active: true
    }      

    
    if (debugConsoleOutPut === true) {
        
        //console.log("SaveUser: " ,SaveUser)
            
    }


    const ReturnMessage = await client.graphql({
        query: createUsers,
        variables: {
          input: SaveUser
        }
      });    

    return SaveUser

    } catch (err) { 
    
    console.error(err, err.stack); 
    }        
}   

export async function IsUserSubscribed(vUserId) {
    try {

        const attributes = await fetchUserAttributes();
        //console.log("UserManagement.IsUserSubscribed.attributes:  ",attributes)

        return attributes.email                           

    } catch (err) { 
                    console.error("UserManagement.IsUserSubscribed.err: ", err); 
                    const jsonError = JSON.stringify(err);
                  //  //console.log("UserManagement.IsUserSubscribed.err.jsonError: ", jsonError); 
                  //  //console.log("UserManagement.IsUserSubscribed.err.err.name: ", err.name); 
                  //  //console.log("UserManagement.IsUserSubscribed.err.err.name: ", err["name"]); 
                  //  //console.log("UserManagement.IsUserSubscribed.err.err.$metadata.httpStatusCode: ", err["$metadata"]["httpStatusCode"]); 

                  if (err.name === "NotAuthorizedException") {
                    return "UserNotAuthorized"
                  }
                }

}    

export async function ValidateUser() {
    try {

        let vUserEmail = undefined
        const UserEmail = await GetUserInfo() 
       // //console.log("UserManagement.ValidateUser.UserEmail:  ",UserEmail)

        if (UserEmail !== undefined) {
          vUserEmail = await IsUserSubscribed(UserEmail)
        //console.log("UserManagement.ValidateUser.UserSubscribed:  ",UserSubscribed)
        } 

        return vUserEmail

} catch (err) {
    console.error('UserManagement.ValidateUser.Error', err)   
    return err 
  }
} 

export async function GetGroupRoleAccess(pCognitoGroup, pAccessGroup) {
  try {

    let vReturnResponse = false    
    //console.log("UserManagement.GetGroupRoleAccess.pCognitoGroup:  ",pCognitoGroup)
    //console.log("UserManagement.GetGroupRoleAccess.pAccessGroup:  ",pAccessGroup)
        
    if (pCognitoGroup !== undefined ) {    

      const vCognitoGroup = Object.values(pCognitoGroup).filter(cg => {
        return cg ===  pAccessGroup;
      }).length;
  
      if (vCognitoGroup === 0) { 
        vReturnResponse = false
        
        if (process.env.REACT_APP_ENV === "PRODUCTION") {
          window.location.href = "https://www.rhyteit.com";
        } else {
          window.location.href = "https://localhost:3000";
        }
      }          
      else if (vCognitoGroup > 0) {
          vReturnResponse = true         
      } 
  
  } else {

    vReturnResponse = false
    
    if (process.env.REACT_APP_ENV === "PRODUCTION") {
      window.location.href = "https://www.rhyteit.com";
    } else {
      window.location.href = "https://localhost:3000";
    }

  }
  
  return vReturnResponse 
    

} catch (err) {
  RhyteItLogging(undefined, "UserManagement", "GetGroupRoleAccess",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
  return false 
}
} 

export async function ReturnUserToLandingPage(pUser, pCognitoGroup) {
  try {

   // console.log("UserManagement.ReturnUserToLandingPage.pCognitoGroup:  ",pCognitoGroup)
        

    if (process.env.REACT_APP_ENV === "PRODUCTION") {
      window.location.href = "https://www.rhyteit.com";
    } else {
      window.location.href = "https://localhost:3000";
    }


} catch (err) {
  RhyteItLogging(pUser, "UserManagement", "ReturnUserToLandingPage",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
}
} 

export async function RhyteItLogging(pUser, pPage, pFunction, pErrorMessage, pErrorJSON, pErrorLine) {
  try {

          var vUpdateDateTime = new Date().toISOString()

            
            let vId = process.env.REACT_APP_ENV + "_" + pUser + "_" + pPage + "_" + vUpdateDateTime
            

            const RhyteItLogItem = {
              id: vId,
              Environment: process.env.REACT_APP_ENV,
              ipaddress: "NA",
              User: pUser,
              Page: pPage,
              Function: pFunction,
              ErrorMessage: pErrorMessage,
              ErrorJSON: pErrorJSON,
              ErrorLine: pErrorLine,
              CreationName: pUser,
              UpdateName: pUser,
              CreationDateTime: vUpdateDateTime,
              UpdateDateTime: vUpdateDateTime
            };            
            
          // //console.log("UserManagement.RhyteItLogging.RhyteItLogItem: ",RhyteItLogItem)

            const ReturnMessage = client.graphql({
                           query: createRhyteItLog,
                           variables: {
                             input: RhyteItLogItem
                           }
                         });  

            
    } catch (err) {
      console.error('UserManagement.RhyteItLogging.Error', err)   
      return false 
    }
} 