
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Text,
  TableBody,
  TextField,  
  Button,
  Image
  } from '@aws-amplify/ui-react';

  import  {  }  from '../../graphql/queries'
  import  { deleteRhyteItEmails, updateRhyteItEmails}  from '../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";

  import styles from './EmailAttachementRow.module.css';  
  import { UserContext} from '../../BusinessLogic/Hooks/UserContext';  

const EmailAttachementRow = (props) => {       

  EmailAttachementRow.propTypes = {
    EmailAttachement: PropTypes.object
      };

    const client = generateClient();
  
    const {Users, setUsers} = useContext(UserContext);   
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""
    let debugConsoleOutPut = true

    const [message, setMessage] = useState('');

    const [EmailAttachement, setEmailAttachement] = useState(props.EmailAttachement)      

    useEffect(() => {

       // //console.log("EmailAttachementRow.useEffect[].props.Email:  ",props.Email) 
      
      },[])  


      function OpenAttachementURL(event,pS3Key) {
        try {
  
            setErrorMessage("") 
  
            let vAttachementURL = "https://rhyteitsess3receiving.s3.us-west-2.amazonaws.com/" + pS3Key
            //console.log("SubReddittvOnlyFansProfiles.OpenPostURL.pPostURL:  ",pPostURL) 
            if (pS3Key.length > 0) {
              window.open(vAttachementURL, '_blank');
            } else {                
              setErrorMessage("")  
            }
    
            
          } catch (err) { 
  
          ErrorMessageStr = "AccProfile.OpenTwitterURL Error: " + err + " " + err.stack
  
          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }        
      }        


  return (
      <TableBody>  
        
          <TableRow  className={EmailAttachement.EmailAttachmentStatus === "Processed" ?  styles.TableRowProcessed : EmailAttachement.EmailAttachmentStatus === "read" ?  styles.TableRowRead : EmailAttachement.EmailAttachmentStatus === "replied" ?  styles.TableRowReplied : EmailAttachement.EmailAttachmentStatus === "SEND" ?  styles.TableRowSend : EmailAttachement.EmailAttachmentStatus === "delete" ?  styles.TableRowDelete : styles.TableRowBackground} key={EmailAttachement.id}
             
             onClick={ (e) => OpenAttachementURL(e, EmailAttachement.S3Key) } >
            <TableCell className={styles.TableRowHeadingData}         >  
                <Text  className={styles.TextField}>
                                  {EmailAttachement.id}
                </Text>  
              </TableCell>                                      
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {EmailAttachement.S3Key}
                </Text>  
            </TableCell>                                          
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {EmailAttachement.ContentType}
                </Text>  
            </TableCell>                    
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextFieldRecieved}>
                                  {EmailAttachement.Size}
                </Text>  
            </TableCell>                                                    
          </TableRow>  
      {
        ErrorMessage.length > 0 &&             
          <TableRow  >              
            <TableCell 
              colSpan="7">   
              <Text className={styles.ErrorMessageRow}>  
              {ErrorMessage}
              </Text> 
            </TableCell>               
          </TableRow>  
      }                        
      </TableBody>
    
  )
}

export default EmailAttachementRow;

