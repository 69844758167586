import React, { useState, useEffect, useContext, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./Benefits.module.css";

import {
  useInView,
  useScroll,
  useTransform,
  motion,
  useAnimation,
} from "framer-motion";
//import {useInView} from "react-intersection-observer";

import { Button, Flex, Text, Image } from "@aws-amplify/ui-react";
import Foreground from "../../Backgrounds/ForegroundCanvasErase";
//import Foreground from "../../Backgrounds/BackgroundAnimationTester";
import Background from "../../Backgrounds/BackgroundSVGAPI";
import TurboFanBoostButton from "../../Buttons/TurboFanBoostButton";
import {Link } from 'react-router-dom';

const Benefits = (props) => {
  Benefits.propTypes = {
    scrollYProgress: PropTypes.object,
  };

  const refBenefitsSection = useRef(null);
  const isInView = useInView(refBenefitsSection);
  const [vBackgrounKey, setvBackgrounKey] = useState(Date.now());

  useEffect(() => {}, []);

  useEffect(() => {
  //  console.log("Benefits.useEffect[isInView].isInView: ", isInView);
    setvBackgrounKey(Date.now())

  }, [isInView]);

  return (
    <section  className={styles.SectionContainer} ref={refBenefitsSection}>
    <Link className={styles.ApplicationLink} to="/TurboFanBoost">          
        <TurboFanBoostButton  className={styles.TurboFanBoostButton} >  
        </TurboFanBoostButton>
    </Link>  
      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        alignContent="flex-start"
        wrap="wrap"
        className={styles.TextFlexColumn}
      >
        <Flex
          direction="row"
          justifyContent="left"
          alignItems="center"
          alignContent="flex-start"
          wrap="wrap"
          className={styles.HeaderTextRow}
        >
        <motion.div 
            className={styles.MayanNumber}>   
              <h1
              className={styles.MayanDot}
              >
              &#x2022;
              </h1>
              <img
                  alt=""
              src="https://rhyteiticons.s3.us-west-2.amazonaws.com/MenuGraphics/MenuLineBlue.svg"
              className={styles.MayanLine}
              />
          </motion.div >
          <div>
            <h1
              className={styles.HeaderText}
            >
              How to succeed as an Adult Content Creator?
            </h1>
          </div>
        </Flex>
        <Flex
          direction="row"
          justifyContent="left"
          alignItems="center"
          alignContent="flex-start"
          wrap="nowrap"
          className={styles.HeaderSubTextRow}
        >
          <h1
            className={styles.HeaderSubText}
          >
            At RhyteIt, we help our customers get to the top 1% of Adult Content
            Creator Revenue Earners
          </h1>
        </Flex>
        <Flex
          direction="row"
          justifyContent="left"
          alignItems="center"
          alignContent="flex-start"
          wrap="nowrap"
          className={styles.HeaderSubTextRow}
        >
          <h1
            className={styles.SubSubText}
          >
          Marketing your adult content is a numbers game
          </h1>
        </Flex>
        <Flex
          direction="row"
          justifyContent="left"
          alignItems="center"
          alignContent="flex-start"
          wrap="nowrap"
          className={styles.HeaderSubTextRow}
        >
          <h1
            className={styles.SubSubText}
          >
            It can be automated
          </h1>
        </Flex>      
        <Flex
          direction="row"
          justifyContent="left"
          alignItems="center"
          alignContent="flex-start"
          wrap="nowrap"
          className={styles.HeaderSubTextRow}
        >
          <h1
            className={styles.SubSubText}
          >
            And affordable
          </h1>
        </Flex>  
      </Flex>

 
      <div className={styles.Background}
      >
        <Background key={vBackgrounKey} InitiateBackGround={isInView} scrollYProgress={props.scrollYProgress}/>
      </div>         
    </section>
  );
};

export default Benefits;
