import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext, useRef } from 'react';

import  { listSMMADCampaigns, listSMMADAccounts }  from '../../../graphql/queries'
import  {createSMMADAccounts , createSMMADCampaigns}  from '../../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import Header from "../../RhyteItComponents/Header/Header";
import { UserContext, CognitoGroupContext} from '../../../BusinessLogic/Hooks/UserContext';
import {GetGroupRoleAccess, RhyteItLogging, ReturnUserToLandingPage} from '../../../BusinessLogic/UserManagement';
import { fetchAuthSession } from 'aws-amplify/auth';
import {motion as m} from "framer-motion";
import SMMADOrganizeTable from "./SMMADOrganizeTable";
import MenuBackground from '../../Menu/MenuBackground';
import {get_uuidv4} from '../../../BusinessLogic/uuidv4';

import SMMADDeleteCampaigns from "./SMMADDeleteCampaigns";


import {
  Button , Flex, Divider, TextField, SelectField,Text, Fieldset, CheckboxField
} from '@aws-amplify/ui-react';
import styles from './SMMADOrganize.module.css';

const SMMADOrganize = (props) => {       
  
  SMMADOrganize.propTypes = {
          RedditUser: PropTypes.object
        };

        const client = generateClient();
        const [vSMMADOrganize, setvSMMADOrganize] = useState([]) 
        const vBreadCrumbItems = [{Path: "/", Title:"Home", isCurrent:false},{Path: "/MainMenuV2", Title:"Menu", isCurrent:false},{Path: "/SMMADOrganize", Title:"TurboFanBoost", isCurrent:true}]
     
        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr) 

        const {Users, setUsers} = useContext(UserContext);    
        const {CognitoGroup, setCognitoGroup} = useContext(CognitoGroupContext);      

        const [vCampaignsActive, setvCampaignsActive] = useState(false)   
        const [vAccountType, setvAccountType] = useState(false)     
        
        const [vAccountSelected, setvAccountSelected] = useState();       
        let [vAccountList, setvAccountList] = useState();     
        let [vNewAccount, setvNewAccount] = useState();  
        const [vAddNewAccountVisible, setvAddNewAccountVisible] = useState(false)   
        const [vCampaignMigrationVisible, setvCampaignMigrationVisible] = useState(false)   
        const [vDeleteCampaignsVisible, setvDeleteCampaignsVisible] = useState(false)   
        
        const [vUpdateKey, setvUpdateKey] = useState(false)  
        


        const [updatedKey, setupdatedKey] = useState()   

        useEffect(() => {

          window.scrollTo(0, 0);

        },[]) 


        useEffect(() => {

                       
          (async function() {
            try {  

                    if (CognitoGroup !== undefined) {

                      let   GetGroupRoleAccessResponse = await GetGroupRoleAccess(CognitoGroup, "RhyteItSMMADUserGroup")
                      
                      if (GetGroupRoleAccessResponse) {  
                        GetSMMADAccounts()  
                      }
                          

                    } else {

                      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};

                      if (idToken !== undefined) {
                        setUsers(idToken.payload.email)
                        setCognitoGroup(accessToken["payload"]["cognito:groups"])
                      } else {
                        
                        ReturnUserToLandingPage(Users, CognitoGroup)
                      }

                    }
                  } catch (err) {                  
                    RhyteItLogging(Users, "SMMADBatchJobs", "useEffect[CognitoGroup]",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                }
              }
            )();   
    
           },[CognitoGroup]) 

         useEffect(() => {
  
          //console.log("SMMADOrganize.useEffect[vAccountSelected].vAccountSelected:  ",vAccountSelected)

          if (vAccountSelected !== undefined) {
            setupdatedKey(Date.now())              
            setvDeleteCampaignsVisible(false)
            setvCampaignMigrationVisible(false)
          }

        },[vAccountSelected])  

        useEffect(() => {
        //  //console.log("SMMADOrganize.useEffect[vAccountList].vAccountList:  ",vAccountList)

          if (vAccountList !== undefined && Users !== "edwardhawke@rhyteit.com") {
  
            AccountSelectedAction(vAccountList[0]["Account"])

          }

        },[vAccountList])           
                   

        
                
        useEffect(() => {

        //  //console.log("SMMADOrganize.useEffect[vCampaignMigrationVisible].vCampaignMigrationVisible:  ",vCampaignMigrationVisible)
          if (vCampaignMigrationVisible !== undefined) {
          }

        },[vCampaignMigrationVisible])           
      
        async function GetSMMADAccounts() {
          try {
                    setErrorMessage("")
                    setvSMMADOrganize([])
                    let variables 
                if (Users !== "edwardhawke@rhyteit.com") {

                    variables = {
                      filter: {
                        Account: {
                                eq: Users
                            }
                      }
                    };
                }
                  else {
                    variables = {};

                  }

                    const listSMMADAccountsData2  = await client.graphql({
                      query: listSMMADAccounts,
                      variables: variables
                    });
      
          

                    if (listSMMADAccountsData2.data.listSMMADAccounts.items.length > 0) {

                        const SMMADOrganize1 = listSMMADAccountsData2.data.listSMMADAccounts.items 

                        let vAccountListArray = []
                      
                        SMMADOrganize1.forEach(async (Campaign) => {

                          if (vAccountListArray.filter((vcampaign) => vcampaign["Account"] === Campaign["Account"]).length === 0) {

                            vAccountListArray.push({"Account": Campaign["Account"], "CampaignsActive": Campaign["CampaignsActive"]})  

                          }
                        });

                        

                        let vAccountListArraySorted  = SortAccountsByName(vAccountListArray)
                        //console.log("SMMADOrganize.GetSMMADAccounts.vAccountListArraySorted: ", vAccountListArraySorted);
                        setvAccountList(vAccountListArraySorted)
           
                    }

          } catch (err) { 
            RhyteItLogging(Users, "SMMADOrganize", "GetSMMADAccounts",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                      }
        }   

        function SortAccountsByName(pRhyteItAccounts) {
          pRhyteItAccounts.sort(GFG_sortFunction);
              return pRhyteItAccounts
          //    //console.log(JSON.stringify(pEmails));
          }
    
        function GFG_sortFunction(a, b) {
            let RhyteItAccountA = a.Account;
            let RhyteItAccountB = b.Account;
            return RhyteItAccountA > RhyteItAccountB ? 1 : -1;
        };     

        async function AccountSelectedAction(pAccount) {
          try {           
            //    //console.log("SMMADOrganize.AccountSelectedAction.pAccount: ", pAccount);                
                let vSelectedAccount = vAccountList.filter((vAccountItem) => vAccountItem["Account"] === pAccount)
                 
          //      //console.log("SMMADOrganize.AccountSelectedAction.vSelectedAccount[CampaignsActive]: ", vSelectedAccount[0]["CampaignsActive"]);   
                setvCampaignMigrationVisible(vSelectedAccount[0]["CampaignsActive"])
                setvAccountSelected(pAccount)
    
            } catch (err) { 
              RhyteItLogging(Users, "SMMADOrganize", "AccountSelectedAction",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          }        
        }      
            
        function ButtonChoice(pButtonChoice) {

          if (pButtonChoice === "DeleteCampaigns") {
            setvDeleteCampaignsVisible(true)
            setvCampaignMigrationVisible(false)
          } else if (pButtonChoice === "CampaignMigration") {
            setvDeleteCampaignsVisible(false)
            setvCampaignMigrationVisible(true)
          } else {
            setvDeleteCampaignsVisible(false)
            setvCampaignMigrationVisible(false)

          }
        }
    
    
    return ( 
    <m.div
      key="Search"
      initial={{x: "100%"}}
      animate={{x: "0%"}}
      transition={{duration:0.75, ease: "easeOut"}}
      exit={{ opacity: 1 }}
     >
     <MenuBackground className={styles.MenuBackground} GradientStopColor1="#0000" GradientStopColor2="#000"  GradientStopColor3="#00CCCC"   GradientStopColor4="#8880"/> 
                                 
                  <Flex
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      alignContent="center"
                      wrap="nowrap"
                      gap="1rem"
                      className={styles.FlexColumn}
                    >     
                      <Header key={Date.now()} Title="Campaign Organizer" SubTitle="Organize your campaigns" BreadCrumbItems ={vBreadCrumbItems} pageSource="TurbFanBoost"/>  
                      <Fieldset 
                              className={styles.Fieldset}
                              size="small">  
                      <legend
                          className={styles.legend}>Accounts</legend>   
                      <Flex
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          alignContent="center"
                          wrap="nowrap"
                          gap="3rem"
                        >      
                      <SelectField
                          className={styles.SelectField}
                          value={vAccountSelected}
                          placeholder="Select account"
                          onChange={(e) => AccountSelectedAction(e.target.value)}>
                        {
                          vAccountList !== undefined && vAccountList.map((FromAccount) => (                      
                            ( <option 
                              key={FromAccount.Account} className={styles.SelectFieldOption} value={FromAccount.Account} label={FromAccount.Account}>
                                {FromAccount.Account}
                              </option>)        
                            ))
                        }   
                      </SelectField>                                                                                                                                                                                                                                                                                                                                                                                               
                      </Flex>     
                      <Flex
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          alignContent="center"
                          wrap="nowrap"
                          gap="3rem"
                          className={styles.ButtonFlexRow}
                        >      
                      <Button className={vDeleteCampaignsVisible === false ? styles.Button: styles.ButtonSelected}
                              variation="primary"         
                              onClick={ (e) => ButtonChoice("DeleteCampaigns")}
                              >
                            Delete Campaigns
                      </Button>     
                      <Button className={vCampaignMigrationVisible === false ? styles.Button: styles.ButtonSelected}
                              variation="primary"         
                              onClick={ (e) => ButtonChoice("CampaignMigration")}
                              >
                            Organize Campaign Content
                      </Button>                                                                                                                                                                                                                                                                                                                                                                                                              
                      </Flex>                                                                                     
                      </Fieldset> 

                      {
                          vCampaignMigrationVisible === true && vAccountSelected  !== undefined && (                                 
                          <Fieldset 
                                  className={styles.Fieldset}
                                  size="small">  
                            <legend>Campaign migration</legend>  
                            <Flex
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                alignContent="center"
                                wrap="nowrap"
                                gap="3rem"
                                className={styles.FlexRow}
                              >                                  
                              {vAccountSelected !== undefined && <SMMADOrganizeTable   key={updatedKey}  AccountSelected={vAccountSelected}/>  }                                                                                                                                                                                                                   
                                                                                                                                                                                                                                                            
                            </Flex>   
                          </Fieldset> 
                      )}     
                      {
                          vDeleteCampaignsVisible === true && vAccountSelected  !== undefined && (                                 
                          <Fieldset 
                                  className={styles.Fieldset}
                                  size="small">  
                            <legend>Delete campaigns</legend>  
                            <Flex
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                alignContent="center"
                                wrap="nowrap"
                                gap="3rem"
                                className={styles.FlexRow}
                              >                                  
                              {vAccountSelected !== undefined && <SMMADDeleteCampaigns   key={updatedKey}  AccountSelected={vAccountSelected}/>  }                                                                                                                                                                                                                   
                                                                                                                                                                                                                                                            
                            </Flex>   
                          </Fieldset> 
                      )}                                           
                    </Flex>    
    </m.div>                      

    )

  }
 

export default SMMADOrganize;