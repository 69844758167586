
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  TableBody,
  TextField,  
  Button , SwitchField,Image, Flex
  } from '@aws-amplify/ui-react';

  import  { }  from '../../graphql/queries'
  import  { createAudienceGeoInfo, deleteSBSMNewsQueryTerms, createSBSMNewsQueryTerms, updateSBSMNewsQueryTerms}  from '../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';
  
  import styles from './SBSMQueryTermRow.module.css';  
  import { UserContext, CognitoGroupContext} from '../../BusinessLogic/Hooks/UserContext';
  import {GetGroupRoleAccess, RhyteItLogging, ReturnUserToLandingPage} from '../../BusinessLogic/UserManagement';

const SBSMQueryTermRow = (props) => {       

  SBSMQueryTermRow.propTypes = {
      SBSMQueryTerm: PropTypes.object
      };
  
    const client = generateClient();
    const {Users, setUsers} = useContext(UserContext);   
    const [ErrorMessage, setErrorMessage] = useState("")
    let ErrorMessageStr = ""
    let debugConsoleOutPut = true

    const [vQueryTerms, setvQueryTerms] = useState([props.SBSMQueryTerm])   
    const [vActive, setvActive] = useState(props.SBSMQueryTerm.QueryTermStatus)     
    const [vChecked, setvChecked] = useState(false)     
     

    useEffect(() => {


      },[])  

    useEffect(() => {

          //console.log("SBSMQueryTermRow.useEffect[].vQueryTerms:  ",vQueryTerms) 
          setErrorMessage("")

      },[vQueryTerms])     
      
      useEffect(() => {


        //console.log("SBSMQueryTermRow.useEffect[vActive].vActive:  ",vActive) 
        let vCheckedTmp = vActive === "Active" ? true : false
        setvChecked(vCheckedTmp) 

    },[vActive])    
    
      
    useEffect(() => {


      //console.log("SBSMQueryTermRow.useEffect[vChecked].vChecked:  ",vChecked) 

  },[vChecked])          
      
    
    function SBSMQueryTermRow_Delete_Row(event,data) {
      try {

          var today = new Date();
          var elementPos = vQueryTerms.map(function(x) {return x.id; }).indexOf(data.id);

          if (vQueryTerms[elementPos].SBSMQueryTermRowStatus !== "New") {

            const DeleteSBSMNewsQueryTerms = {
              id: vQueryTerms[elementPos].id,
              UserId: vQueryTerms[elementPos].UserId,
              _version: vQueryTerms[elementPos]._version
            }
            

            const ReturnMessage = client.graphql({
              query: deleteSBSMNewsQueryTerms,
              variables: {
                input: DeleteSBSMNewsQueryTerms
              }
            });  

          }
        
          delete vQueryTerms[elementPos];   

          setvQueryTerms(vQueryTerms)
          ErrorMessageStr = "query term deleted at: " + today.toLocaleString("en-US")
          setErrorMessage(ErrorMessageStr)
       
                
          } catch (err) { 

          RhyteItLogging(Users, "SBSMQueryTermRow", "SBSMQueryTermRow_Delete_Row",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
           
        }               
      
    }   

    function QueryTerm_handleChange(event, data) {
      try {              
          
          if (debugConsoleOutPut === true) {
            //console.log("vQueryTerms.PostDate_handleChange.event.target.value: ",event.target.value)
          }
          var elementPos = vQueryTerms.map(function(x) {return x.id; }).indexOf(data.id);      

          vQueryTerms[elementPos].QueryTerm = event.target.value

          setvQueryTerms(vQueryTerms)  

          //console.log("vQueryTerms.QueryTerm_handleChange.vQueryTerms[elementPos].QueryTerm: ",vQueryTerms[elementPos].QueryTerm)

        } catch (err) { 
          RhyteItLogging(Users, "SBSMQueryTermRow", "QueryTerm_handleChange",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
      }        
    }  

    function SaveSBSMQueryTermRow(event, data) {
      try {           
          var today = new Date();
          setErrorMessage("")
          
          //console.log("vQueryTerms.SaveSBSMQueryTermRow.Users: ",Users)

          var elementPos = vQueryTerms.map(function(x) {return x.id; }).indexOf(data.id);            

          var vUpdateDateTime = new Date().toISOString()

          vQueryTerms[elementPos].UpdateDateTime = vUpdateDateTime       
          vQueryTerms[elementPos].UpdateName = Users      

          console.log("vQueryTerms.SaveSBSMQueryTermRow.vQueryTerms[elementPos]: ",vQueryTerms[elementPos])

          if (vQueryTerms[elementPos].QueryTermStatus === "NEW") {
            
            vQueryTerms[elementPos].QueryTermStatus = "Active"        
            vQueryTerms[elementPos].CreationName = Users          
            vQueryTerms[elementPos].CreationDateTime = vUpdateDateTime 
            vQueryTerms[elementPos].UserId = Users         
            

            const SBSMNewsQueryTermItem = {
              id: vQueryTerms[elementPos].QueryTerm,
              UserId: vQueryTerms[elementPos].UserId,
              QueryTerm: vQueryTerms[elementPos].QueryTerm,
              QueryTermStatus: vQueryTerms[elementPos].QueryTermStatus,
              LogImageFileLocation: vQueryTerms[elementPos].LogImageFileLocation,
              CreationName: vQueryTerms[elementPos].CreationName,
              UpdateName: vQueryTerms[elementPos].UpdateName,
              CreationDateTime: vQueryTerms[elementPos].CreationDateTime,
              UpdateDateTime: vQueryTerms[elementPos].UpdateDateTime
            };            
            
           console.log("vQueryTerms.SaveSBSMQueryTermRow.Create.SBSMNewsQueryTermItem: ",SBSMNewsQueryTermItem)

            const ReturnMessage = client.graphql({
                           query: createSBSMNewsQueryTerms,
                           variables: {
                             input: SBSMNewsQueryTermItem
                           }
                         });  

            ErrorMessageStr = "query term saved at: " + today.toLocaleString("en-US")
            setErrorMessage(ErrorMessageStr)

          } else {

            const SBSMNewsQueryTermItem = {
              id: vQueryTerms[elementPos].id,
              UserId: vQueryTerms[elementPos].UserId,
              QueryTerm: vQueryTerms[elementPos].QueryTerm,
              QueryTermStatus: vQueryTerms[elementPos].QueryTermStatus,
              LogImageFileLocation: vQueryTerms[elementPos].LogImageFileLocation,
              CreationName: vQueryTerms[elementPos].CreationName,
              UpdateName: vQueryTerms[elementPos].UpdateName,
              CreationDateTime: vQueryTerms[elementPos].CreationDateTime,
              UpdateDateTime: vQueryTerms[elementPos].UpdateDateTime
            };

            console.log("vQueryTerms.SaveSBSMQueryTermRow.Update.SBSMNewsQueryTermItem: ",SBSMNewsQueryTermItem)

            const ReturnMessage = client.graphql({
              query: updateSBSMNewsQueryTerms,
              variables: {
                input: SBSMNewsQueryTermItem
              }
            });  
            ErrorMessageStr = "query term saved at: " + today.toLocaleString("en-US")
            setErrorMessage(ErrorMessageStr)

        }
          
          setvQueryTerms(vQueryTerms) 
          setErrorMessage(ErrorMessageStr)
     

        } catch (err) { 
          RhyteItLogging(Users, "SBSMQueryTermRow", "SaveSBSMQueryTermRow",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
      }        
    }      
     

    function QueryTermStatus_handleChange(event, data) {
      try {              
          
        
       // console.log("vQueryTerms.QueryTermStatus_handleChange.event : ",event )
       // console.log("vQueryTerms.QueryTermStatus_handleChange.data: ",data )
          if (vQueryTerms[0].QueryTermStatus !== "NEW") {
            vQueryTerms[0].QueryTermStatus = (event === true ? "Active" : "Disabled")
           }

          setvChecked(event)
         // //console.log("vQueryTerms.Subscribed_handleChange.vQueryTerms[elementPos].Subscribed: ",vQueryTerms[elementPos].Subscribed)

          setvQueryTerms(vQueryTerms)   

 

        } catch (err) { 
          console.error("vQueryTerms.PostTitle_handleChange.Error:  ".err, err.stack); 
      }        
    }        

    function LogImageFileLocation_handleChange(event, data) {
      try {              
          

          
          var elementPos = vQueryTerms.map(function(x) {return x.id; }).indexOf(data.id);
          vQueryTerms[elementPos].LogImageFileLocation = event.target.value
         // //console.log("vQueryTerms.Subscribed_handleChange.vQueryTerms[elementPos].Subscribed: ",vQueryTerms[elementPos].Subscribed)

          setvQueryTerms(vQueryTerms)   


 

        } catch (err) { 
          console.error("vQueryTerms.LogImageFileLocation_handleChange.Error:  ".err, err.stack); 
      }        
    }      
  
    


  return (
      <TableBody>  
      {
        vQueryTerms.map && vQueryTerms.map((vQueryTerm) => (  
          
          <TableRow  className={styles.TableRowBackground} key={vQueryTerm.id}>              
            <TableCell as="td">    
            <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
                gap="1rem"
              >      
            <Image
                alt=""
                src={vQueryTerm.LogImageFileLocation}  
                objectFit="initial"
                objectPosition="50% 50%"
                className={ styles.PhotoImage}
              
              />                   
              <TextField className={styles.TextField} 
                          name="vQueryTerm.QueryTerm"        
                          defaultValue={vQueryTerm.QueryTerm}
                          onChange={ (e) => QueryTerm_handleChange(e, vQueryTerm) } />                                                                                                                                                                                                                                                                                                                                                                                            
            </Flex>  
            </TableCell>  
                       
            <TableCell as="td">   
            <SwitchField
                                      className={styles.SwitchField}
                                      isChecked={vChecked}
                                      onChange={(e) => {
                                        QueryTermStatus_handleChange(e.target.checked)
                                      }}
                                >
                              </SwitchField>      
            </TableCell>                       
            <TableCell as="td">      
              <TextField className={styles.TextField} 
                          name="vQueryTerm.LogImageFileLocation"                           
                          defaultValue={vQueryTerm.LogImageFileLocation}
                          onChange={ (e) => LogImageFileLocation_handleChange(e, vQueryTerm) } />
            </TableCell> 
            <TableCell as="td"  className={styles.RhyteItMenuCell}> 
              <Button
                  loadingText=""
                  ariaLabel=""
                  title="Save Record"
                  className={styles.RedditTitleButtons}
                  onClick={(e) => SaveSBSMQueryTermRow(e, vQueryTerm)}
                >
                S
                </Button>   
                <Button
                  loadingText=""
                  ariaLabel=""
                  className={styles.RedditTitleButtons}
                  onClick={(e) => SBSMQueryTermRow_Delete_Row(e, vQueryTerm)}
                >
                D
                </Button>                 
          </TableCell>                         
          </TableRow>  
        ))
      }     
      {
        ErrorMessage.length > 0 &&             
          <TableRow  className={styles.ErrorMessageRow}>              
            <TableCell 
              colSpan="3">      
              {ErrorMessage}
            </TableCell>               
          </TableRow>  
      }               
      </TableBody>
    
  )
}

export default SBSMQueryTermRow;