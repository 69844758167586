import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  TableCell,
  TableRow,
  Text,
  TextField,
  CheckboxField,
  Fieldset,
  Button,
  Radio, RadioGroupField
} from "@aws-amplify/ui-react";

import {} from "../../../graphql/queries";
import {
  deleteSMMADAccounts,
  updateSMMADAccounts,
  createSMMADAccounts,
} from "../../../graphql/mutations";
import { generateClient } from "aws-amplify/api";

import { Link } from "react-router-dom";

import styles from "./SMMADAGParentMgr.module.css";
import { UserContext } from "../../../BusinessLogic/Hooks/UserContext";
import { SelectedImageContext } from "../../../BusinessLogic/Hooks/SMMADImageContext";


import {
  GetGroupRoleAccess,
  RhyteItLogging,
  ReturnUserToLandingPage,
} from "../../../BusinessLogic/UserManagement";

function SMMADAGParentMgr({ AGAccounts }) {
  SMMADAGParentMgr.propTypes = {
    AGAccounts: PropTypes.array,
  };

  const client = generateClient();

  const { Users, setUsers } = useContext(UserContext);
  const [ErrorMessage, setErrorMessage] = useState("");
  let ErrorMessageStr = "";

  const [vSMMADAGAccounts, setvSMMADAGAccounts] = useState(AGAccounts);
  const [vAccountSaved, setvAccountSaved] = useState(false);
  const [vRowClicked, setvRowClicked] = useState(false);
  const [vRowRefresh, setvRowRefresh] = useState(Date.now());

  //console.log("SMMADAGParentMgr.useEffect[].vSelectedImage:  ",vSelectedImage)

  useEffect(() => {
    console.log("SMMADAGParentMgr.useEffect[].AGAccounts:  ",AGAccounts)
    //console.log("SMMADAGParentMgr.useEffect[].ClickedImage:  ",ClickedImage)
    //console.log("SMMADAGParentMgr.useEffect[].onClick:  ",onClick)
  }, []);


  useEffect(() => {
    //   console.log("SMMADAGParentMgr.useEffect[vRowClicked].vRowClicked:  ",vRowClicked)
    setvRowRefresh(Date.now());
  }, [vRowClicked]);

  async function SaveAccount() {
    try {
      var vUpdateDateTimeNew = new Date().toISOString();
      var vCreationDateTime = new Date().toISOString();

      let AccountItem = {
        id: vSMMADAGAccounts["id"],
        Account: vSMMADAGAccounts["Account"],
        AccountType: "Customer",
        MasterAccount: vSMMADAGAccounts["MasterAccount"],
        AccountGroup: vSMMADAGAccounts["AccountGroup"],
        CampaignsActive: vSMMADAGAccounts["CampaignsActive"],
        SMMADAccountStatus: vSMMADAGAccounts["SMMADAccountStatus"],
        Active: vSMMADAGAccounts["Active"],
        Weight: vSMMADAGAccounts["Weight"],
        PostedCount: vSMMADAGAccounts["PostedCount"],
        TwitterLastPostDate: vSMMADAGAccounts["TwitterLastPostDate"],
        TwitterPostingFrequencyHours:
          vSMMADAGAccounts["TwitterPostingFrequencyHours"],
        ZScore: vSMMADAGAccounts["ZScore"],
        PurchaseId: vSMMADAGAccounts["PurchaseId"],
        ProductId: vSMMADAGAccounts["ProductId"],
        TwitterAccount: vSMMADAGAccounts["TwitterAccount"],
        TwitterPassword: vSMMADAGAccounts["TwitterPassword"],
        TwitterConsumerKey: vSMMADAGAccounts["TwitterConsumerKey"],
        TwitterConsumerSecret: vSMMADAGAccounts["TwitterConsumerSecret"],
        TwitterBearerToken: vSMMADAGAccounts["TwitterBearerToken"],
        TwitterAccessToken: vSMMADAGAccounts["TwitterAccessToken"],
        TwitterAccessTokenSecret:
          vSMMADAGAccounts["TwitterAccessTokenSecret"],
        TwitterClientId: vSMMADAGAccounts["TwitterClientId"],
        TwitterClientSecret: vSMMADAGAccounts["TwitterClientSecret"],
        TwitterAppName: vSMMADAGAccounts["TwitterAppName"],
        TwitterAppId: vSMMADAGAccounts["TwitterAppId"],
        TwitterStatus: vSMMADAGAccounts["TwitterStatus"],
        CreationName: vSMMADAGAccounts["CreationName"],
        CreationDateTime: vSMMADAGAccounts["CreationDateTime"],
        UpdateName: Users,
        UpdateDateTime: vUpdateDateTimeNew,
      };

      console.log("SMMADNewAccountSetup.SaveRow.AccountItem:  ", AccountItem);

      if (AccountItem["id"] === "new") {
        vSMMADAGAccounts["id"] = vSMMADAGAccounts["Account"];

        const createSMMADAccountsResponse = await client.graphql({
          query: createSMMADAccounts,
          variables: {
            input: AccountItem,
          },
        });

        console.log(
          "SMMADNewAccountSetup.SaveRow.createSMMADAccountsResponse:  ",
          createSMMADAccountsResponse
        );
      } else {
        const UpdateSMMADAccountsResponse = await client.graphql({
          query: updateSMMADAccounts,
          variables: {
            input: AccountItem,
          },
        });

        console.log(
          "SMMADNewAccountSetup.SaveRow.UpdateSMMADAccountsResponse:  ",
          UpdateSMMADAccountsResponse
        );
      }
    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADAGParentMgr",
        "SaveAccount",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }


  return (
    <div>  
    <Fieldset className={styles.Fieldset} size="small">
      <legend className={styles.legend}>Change Parent Account</legend>
      <RadioGroupField legend="Animal" name="animal" defaultValue="Dog">  {
        vSMMADAGAccounts && vSMMADAGAccounts.map((vAGAccount) => (       
                <Radio  className={styles.Radio} value={vAGAccount.MasterAccount}>{vAGAccount.id}</Radio>
              ))
            }   
        </RadioGroupField>             
    </Fieldset>  
</div>
)

}



export default SMMADAGParentMgr;
