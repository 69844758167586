
import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  Flex, ToggleButton, Divider,Text
  } from '@aws-amplify/ui-react'; 
import styles from './SMMADHashTagsTable.module.css';
import SMMADContent from "../SMMADContent/Content";


const SMMADHashTagTable = (props) => {       

  SMMADHashTagTable.propTypes = {
      SMHashTag: PropTypes.array,
      SMCampaigns: PropTypes.array,
      AccountSelected: PropTypes.string,
      CampaignSelected: PropTypes.string
      };

    let debugConsoleOutPut = true

  

    const [SMHashTag, setSMHashTag] = useState(props.SMHashTag)     
    const [SMCampaigns, setSMCampaigns] = useState(props.SMCampaigns)   
    const [ShowTable, setShowTable] = useState(false);
    const [vAccountSelected, setvAccountSelected] = useState(props.AccountSelected)   
    const [vCampaignSelected, setvCampaignSelected] = useState(props.CampaignSelected) 
    const [isPressed, setIsPressed] = React.useState(false);

    useEffect(() => {


    },[])  

    useEffect(() => {

      //console.log("SMMADHashTagTable.useEffect[].props.SMHashTag:  ",props.SMHashTag)
      //console.log("SMMADHashTagTable.useEffect[].SMHashTag ",SMHashTag) 
      //console.log("vSMMADHashTagRows.useEffect[].props.RhyteItUser:  ",props.RhyteItUser) 
      //console.log("vSMMADHashTagRows.useEffect[].Time:  ", SMMADHashTagRow.PostDateTime.substr(SMMADHashTagRow.PostDateTime.indexOf("T")+1, 5))
      //onsole.log("vSMMADHashTagRows.useEffect[].indexOf:  ", SMMADHashTagRow.PostDateTime.indexOf(":00"))
      if (SMHashTag.length > 0) {
        setShowTable(true)
      }

    },[SMHashTag])      



  return (  
                            
            <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            alignContent="flex-start"
            wrap="nowrap"
            gap="3rem"
            className={styles.GridContainer}
          >     
                    
            <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
              alignContent="flex-start"
              wrap="nowrap"
              gap="1rem"
            >   
              {
                ShowTable && SMHashTag.length > 0 &&  SMHashTag && SMHashTag.map((SMMADHashTag) => (                      
                  (<ToggleButton  className={styles.ToggleButton}  
                        size="small"  
                        key={SMMADHashTag.id} >
                          {SMMADHashTag.HashTag}
                  </ToggleButton>)        
                  ))
              }   
               </Flex>    
                          
        </Flex>
    
  )
}

export default SMMADHashTagTable;


