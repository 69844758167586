import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
 import styles from './LogoName.module.css';  
import {Link, useNavigate } from 'react-router-dom';
import {
  Flex,
  Text,
  useAuthenticator,
Image
  } from '@aws-amplify/ui-react';
  import axios from 'axios';
  import {uuidv4} from "../../BusinessLogic/UtilitiesManagement"; 
  
import  {createAudienceGeoInfo}  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';
import { UserContext, CognitoGroupContext} from '../../BusinessLogic/Hooks/UserContext';
import {GetGroupRoleAccess, RhyteItLogging} from '../../BusinessLogic/UserManagement';
import { signOut } from 'aws-amplify/auth';

const LogoName = (props) => {

  LogoName.propTypes = {
    };


useEffect(() => {


  
          
},[])   




  return (
                        
                      <Link className={styles.logoName} isExternal={false} to="/LandingPageV5" >   
                          <Flex
                                  direction="row"
                                  justifyContent="flex-start"
                                  alignItems="center"
                                  alignContent="center"
                                  wrap="nowrap"
                                  gap="0.25rem"
                                >                         
                            <Text className={styles.TextUpperCase}>R</Text>
                            <Text className={styles.TextLowerCase}>hyte</Text>
                            <Text className={styles.TextUpperCase}>I</Text>
                            <Text className={styles.TextLowerCase}>t</Text>                
                          </Flex>
                      </Link>   
   
  )
}

export default LogoName;