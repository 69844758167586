import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import {Amplify} from 'aws-amplify';
import {ErrorBoundary} from 'react-error-boundary'
import {motion as m} from "framer-motion";
import { useNavigate, useLocation } from "react-router-dom";

import  {listAudienceGeoInfos, getOnlyFansProspectingProfiles}  from '../../../graphql/queries'
import  {deleteApplicants, createApplicants}  from '../../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import HeaderMenu from "../../HeaderMenu/MainHeader";
import { UserContext,UserAuthenticatedContext,UserSubcribedContext} from '../../../BusinessLogic/Hooks/UserContext';
import {ValidateUser} from '../../../BusinessLogic/UserManagement';
import {uuidv4} from "../../../BusinessLogic/UtilitiesManagement";

import {
  Button ,
  Authenticator,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TextAreaField,
  TextField,
  Card,
  Breadcrumbs,
  View,
  Flex,
  Text,
  SelectField,
  TableHead,
  CheckboxField

} from '@aws-amplify/ui-react';
import styles from './ApplicantDetailTable.module.css';
import styled from 'styled-components'
import {get_uuidv4} from '../../../BusinessLogic/uuidv4';


    
const client = generateClient();


const ApplicantDetailTable = (props) => {       

      ApplicantDetailTable.propTypes = {
          RhyteItApplicant: PropTypes.object
          };

        let debugConsoleOutPut = true

        const location = useLocation();
        const { vRhyteItApplicantManagementRow } = location.state;
    

        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   
        const [RecordUpdated, setRecordUpdated] = useState(false)  
        const [EmailTemplate, setEmailTemplate] = useState(vRhyteItApplicantManagementRow.EmailTemplate)  



        const {Users, setUsers} = useContext(UserContext);   
        const [RhyteItApplicant, setRhyteItApplicant] = useState(props.RhyteItApplicant)  

        useEffect(() => {


        },[]) 


        function SocialMediaProfile_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("ApplicantDetailTable.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("ApplicantDetailTable.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItApplicant.SocialMediaProfile = event.target.value
             // //console.log("ApplicantDetailTable.Subscribed_handleChange.RhyteItApplicant[elementPos].Subscribed: ",RhyteItApplicant[elementPos].Subscribed)
    
              setRhyteItApplicant(RhyteItApplicant)   
    
     
    
            } catch (err) { 
              console.error("ApplicantDetailTable.SocialMediaProfile_handleChange.Error:  ", err, err.stack); 
          }        
        }      

        function Role_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("ApplicantDetailTable.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("ApplicantDetailTable.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItApplicant.Role = event.target.value
             // //console.log("ApplicantDetailTable.Subscribed_handleChange.RhyteItApplicant[elementPos].Subscribed: ",RhyteItApplicant[elementPos].Subscribed)
    
              setRhyteItApplicant(RhyteItApplicant)   
    
     
    
            } catch (err) { 
              console.error("ApplicantDetailTable.Role_handleChange.Error:  ", err, err.stack); 
          }        
        }          

        

        function UserFirstName_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("ApplicantDetailTable.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("ApplicantDetailTable.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItApplicant.UserFirstName = event.target.value
             // //console.log("ApplicantDetailTable.Subscribed_handleChange.RhyteItApplicant[elementPos].Subscribed: ",RhyteItApplicant[elementPos].Subscribed)
    
              setRhyteItApplicant(RhyteItApplicant)   
    
     
    
            } catch (err) { 
              console.error("ApplicantDetailTable.UserFirstName_handleChange.Error:  ", err, err.stack); 
          }        
        }     
        
        function UserLastName_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("ApplicantDetailTable.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("ApplicantDetailTable.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItApplicant.UserLastName = event.target.value
             // //console.log("ApplicantDetailTable.Subscribed_handleChange.RhyteItApplicant[elementPos].Subscribed: ",RhyteItApplicant[elementPos].Subscribed)
    
              setRhyteItApplicant(RhyteItApplicant)   
    
     
    
            } catch (err) { 
              console.error("ApplicantDetailTable.UserLastName_handleChange.Error:  ", err, err.stack); 
          }        
        }    

        async function SaveRhyteItApplicant(event, data) {
          try {           
            
              setErrorMessage("")
              let updateApplicantsResponse = ""

              var vUpdateDateTime = new Date().toISOString()
      
              RhyteItApplicant.UpdateDateTime = vUpdateDateTime       
              RhyteItApplicant.UpdateName = Users[0].UserId
  
              var DynamoUUID =  get_uuidv4()
    
              let RhyteItApplicantItem = {
                  "id": RhyteItApplicant.id,
                  "UserId": RhyteItApplicant.UserId,
                  "UserEmail": RhyteItApplicant.UserEmail,
                  "UserLastName": RhyteItApplicant.UserLastName,
                  "UserFirstName": RhyteItApplicant.UserFirstName,
                  "UserDialCode": RhyteItApplicant.UserDialCode,
                  "UserPhone": RhyteItApplicant.UserPhone,
                  "SocialMediaProfile": RhyteItApplicant.SocialMediaProfile,
                  "ipAddress": RhyteItApplicant.ipAddress,
                  "PrivacyAcknowledgement": RhyteItApplicant.PrivacyAcknowledgement,
                  "VerificationCode": RhyteItApplicant.VerificationCode,
                  "VerificationStatus": RhyteItApplicant.VerificationStatus,
                  "Application": RhyteItApplicant.Application,
                  "EmailTemplate": RhyteItApplicant.EmailTemplate,
                  "InOnlyFansProspectingProfile": RhyteItApplicant.InOnlyFansProspectingProfile,
                  "Status": RhyteItApplicant.Status,
                  "Active": true,
                  "CreationName": RhyteItApplicant.CreationName,
                  "UpdateName": RhyteItApplicant.UpdateName,
                  "CreationDateTime": RhyteItApplicant.CreationDateTime,
                  "UpdateDateTime": RhyteItApplicant.UpdateDateTime
              }

              if (debugConsoleOutPut === true) {
              
                //console.log("ApplicantDetailTable.SaveRhyteItApplicant.RhyteItApplicantItem: ",RhyteItApplicantItem)
    
              }

              
              const variables = {
                input: RhyteItApplicantItem
              };


              if (RhyteItApplicantItem.id === "NEW") {
                RhyteItApplicantItem.id = DynamoUUID


                const updateApplicantsResponse  = await client.graphql({
                    query: createApplicants,
                    variables: variables
                  });


                  setRhyteItApplicant(updateApplicantsResponse.data.createApplicants)
                  setErrorMessage(ErrorMessageStr)
    
              } else {
    
                const updateApplicantsResponse  = await client.graphql({
                  query: createApplicants,
                  variables: variables
                });

                setRhyteItApplicant(updateApplicantsResponse.data.updateApplicants)
                setErrorMessage(ErrorMessageStr)
    
            }

        
              if (debugConsoleOutPut === true) {
              
                //console.log("ApplicantDetailTable.SaveRhyteItApplicant.updateApplicantsResponse: ",updateApplicantsResponse)
    
              }

              setRecordUpdated(true)
              //ErrorMessageStr = "Applicant saved at: " + vUpdateDateTime.toLocaleString("en-US")
              setErrorMessage("")
         
    
            } catch (err) { 
              console.error(err)
              ErrorMessageStr = ("ApplicantDetailTable.SaveRhyteItApplicant.Error: ", err, err.stack)
              console.error(ErrorMessageStr)
              setErrorMessage(ErrorMessageStr)
          }        
        }     
        
       async function DeleteRhyteItApplicant(event,data) {
          try {
    

    
              const DeleteRhyteItApplicants = {
                id: RhyteItApplicant.id,
                UserId: RhyteItApplicant.UserId,
                _version: RhyteItApplicant._version
              }

              const variables = {
                input: DeleteRhyteItApplicants
              };              
              
    
              const DeleteApplicantsResponse  = await client.graphql({
                query: deleteApplicants,
                variables: variables
              });

      
              setRhyteItApplicant([])
    
              setErrorMessage("Deleted")         
                    
              } catch (err) { 
    
              ErrorMessageStr = "ApplicantDetailTable.DeleteRhyteItApplicant.Error: " + err + " " + err.stack
    
              console.error(ErrorMessageStr); 
              
              setErrorMessage(ErrorMessageStr)         
            }               
          
        }           

  

        function EmailTemplateSelected(pEmailTemplate) {
          try {
   

                    //console.log("ReportSelected.pReport: ",pReport);

                    RhyteItApplicant.EmailTemplate = pEmailTemplate
                    setRhyteItApplicant(RhyteItApplicant)
                    setEmailTemplate(pEmailTemplate)
     
                    //console.log("ReportSelected.EmailTemplateSelected.pEmailTemplate: ",pEmailTemplate); 
    
          } catch (err) { 
                          console.error('ApplicantDetailTable.EmailTemplateSelected.ERROR: ', err, err.stack); 
                      }
        }                 
        

    return ( 
          <div>
                 <Flex
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      alignContent="flex-start"
                      wrap="nowrap"
                      gap="2rem"
                      className={styles.GridContainer}
                    >
                      <Flex
                          direction="row"
                          alignItems="flex-start"
                          gap="2rem"
                        >  
                          <Text   
                            className={styles.TextFieldHeaderLabel}
                            >
                              Created:
                          </Text>
                          <Text size="small" className={styles.Text}>
                            {new Date( RhyteItApplicant.CreationDateTime).toLocaleString("en-US")}
                          </Text>    
                          <Text   
                            className={styles.TextFieldHeaderLabel}
                            >
                              Updated:
                          </Text>
                          <Text size="small" className={RecordUpdated === false ? styles.Text :styles.TextUpdated  }>
                            {new Date( RhyteItApplicant.UpdateDateTime).toLocaleString("en-US")}
                          </Text>
                      </Flex>
                      <Table>          
                        <TableHead>          
                          <TableRow className={styles.TableHeaderRow}>
                            <TableCell className={styles.TableHeaderRowCells} >First Name</TableCell> 
                            <TableCell className={styles.TableHeaderRowCells} >Last Name</TableCell> 
                            <TableCell className={styles.TableHeaderRowCells} >Profile URL</TableCell> 
                            <TableCell className={styles.TableHeaderRowCells} >Email Template</TableCell> 
                            <TableCell className={styles.TableHeaderRowCells} >OF Profile</TableCell> 
                          </TableRow>                                      
                        </TableHead>   
                        <TableBody>
                          <TableRow className={styles.TableRow}>
                            <TableCell className={styles.TableRowData}>                                       
                            <TextField className={styles.TextField} 
                                name="RhyteItApplicant.UserFirstName"                           
                                defaultValue={RhyteItApplicant.UserFirstName}
                                onChange={ (e) => UserFirstName_handleChange(e, RhyteItApplicant) } />             
                            </TableCell>
                            <TableCell className={styles.TableRowData}>   
                            <TextField className={styles.TextField} 
                                name="RhyteItApplicant.UserLastName"                           
                                defaultValue={RhyteItApplicant.UserLastName}
                                onChange={ (e) => UserLastName_handleChange(e, RhyteItApplicant) } />                                         
                            
                            </TableCell>
                            <TableCell className={styles.TableRowData}>   
                              <TextField className={styles.URLField} 
                                name="RhyteItApplicant.SocialMediaProfile"                           
                                defaultValue={RhyteItApplicant.SocialMediaProfile}
                                onChange={ (e) => SocialMediaProfile_handleChange(e, RhyteItApplicant) } />
                            </TableCell>
                            <TableCell className={styles.TableRowData}>   
                              <TextField className={styles.TextField} 
                                name="RhyteItApplicant.Role"                           
                                defaultValue={RhyteItApplicant.Role}
                                onChange={ (e) => Role_handleChange(e, RhyteItApplicant) } />
                            </TableCell>                         
                          <TableCell className={styles.TableRowData}>   
                              <CheckboxField
                                  className={styles.CheckboxField}
                                  name="subscribe-controlled"
                                  checked={RhyteItApplicant.InOnlyFansProspectingProfile}
                                />                        
                          </TableCell>   
                          </TableRow >
                        </TableBody>
                      </Table>
                      <Flex
                          direction="row"
                          alignItems="flex-start"
                          gap="2rem"
                        >  
                        <Button className={styles.metallicbutton}                                       
                                onClick={ (e) => SaveRhyteItApplicant(e, RhyteItApplicant) }
                                variation="primary">Save</Button>
                        <Button className={styles.metallicbutton}                                       
                                onClick={ (e) => DeleteRhyteItApplicant(e, RhyteItApplicant) }
                                variation="primary">Delete</Button>
                      </Flex>   
                  </Flex>
    </div>                      

    )

  }
  

export default ApplicantDetailTable;