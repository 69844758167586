import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  TableCell,
  TableRow,
  Text,
  TextField,
  CheckboxField,
  TableBody,
  Button,
} from "@aws-amplify/ui-react";

import {} from "../../../graphql/queries";
import {
  deleteSMMADAccounts,
  updateSMMADAccounts,
  createSMMADAccounts,
} from "../../../graphql/mutations";
import { generateClient } from "aws-amplify/api";

import { Link } from "react-router-dom";

import styles from "./SMMADAGAccountRow.module.css";
import { UserContext } from "../../../BusinessLogic/Hooks/UserContext";
import { SelectedImageContext } from "../../../BusinessLogic/Hooks/SMMADImageContext";

import SMMADAGAccountRowDetail from "./SMMADAGAccountRowDetail";
import {
  GetGroupRoleAccess,
  RhyteItLogging,
  ReturnUserToLandingPage,
} from "../../../BusinessLogic/UserManagement";

function SMMADAGAccountRow({ AGAccount }) {
  SMMADAGAccountRow.propTypes = {
    AGAccount: PropTypes.object,
  };

  const client = generateClient();

  const { Users, setUsers } = useContext(UserContext);
  const [ErrorMessage, setErrorMessage] = useState("");
  let ErrorMessageStr = "";

  const [vSMMADAGAccountRow, setvSMMADAGAccountRow] = useState(AGAccount);

  const [vMasterAccount, setvMasterAccount] = useState(AGAccount.MasterAccount);
  const [vActive, setvActive] = useState(AGAccount.Active);

  const [vAccountSaved, setvAccountSaved] = useState(false);
  const [vRowClicked, setvRowClicked] = useState(false);
  const [vRowRefresh, setvRowRefresh] = useState(Date.now());

  //console.log("SMMADAGAccountRow.useEffect[].vSelectedImage:  ",vSelectedImage)

  useEffect(() => {
    //console.log("SMMADAGAccountRow.useEffect[].vSelectedImage:  ",vSelectedImage)
    //console.log("SMMADAGAccountRow.useEffect[].ClickedImage:  ",ClickedImage)
    //console.log("SMMADAGAccountRow.useEffect[].onClick:  ",onClick)
  }, []);

  useEffect(() => {
    //console.log("SMMADAGAccountRow.useEffect[vActive].vActive:  ",vActive)

    if (vSMMADAGAccountRow.Active !== vActive) {
      SaveAccount();
    }
  }, [vActive]);

  useEffect(() => {
    //   console.log("SMMADAGAccountRow.useEffect[vRowClicked].vRowClicked:  ",vRowClicked)
    setvRowRefresh(Date.now());
  }, [vRowClicked]);

  async function SaveAccount() {
    try {
      var vUpdateDateTimeNew = new Date().toISOString();
      var vCreationDateTime = new Date().toISOString();

      let AccountItem = {
        id: vSMMADAGAccountRow["id"],
        Account: vSMMADAGAccountRow["Account"],
        AccountType: "Customer",
        MasterAccount: vSMMADAGAccountRow["MasterAccount"],
        AccountGroup: vSMMADAGAccountRow["AccountGroup"],
        CampaignsActive: vSMMADAGAccountRow["CampaignsActive"],
        SMMADAccountStatus: vSMMADAGAccountRow["SMMADAccountStatus"],
        Active: vActive,
        Weight: vSMMADAGAccountRow["Weight"],
        PostedCount: vSMMADAGAccountRow["PostedCount"],
        TwitterLastPostDate: vSMMADAGAccountRow["TwitterLastPostDate"],
        TwitterPostingFrequencyHours:
          vSMMADAGAccountRow["TwitterPostingFrequencyHours"],
        ZScore: vSMMADAGAccountRow["ZScore"],
        PurchaseId: vSMMADAGAccountRow["PurchaseId"],
        ProductId: vSMMADAGAccountRow["ProductId"],
        TwitterAccount: vSMMADAGAccountRow["TwitterAccount"],
        TwitterPassword: vSMMADAGAccountRow["TwitterPassword"],
        TwitterConsumerKey: vSMMADAGAccountRow["TwitterConsumerKey"],
        TwitterConsumerSecret: vSMMADAGAccountRow["TwitterConsumerSecret"],
        TwitterBearerToken: vSMMADAGAccountRow["TwitterBearerToken"],
        TwitterAccessToken: vSMMADAGAccountRow["TwitterAccessToken"],
        TwitterAccessTokenSecret:
          vSMMADAGAccountRow["TwitterAccessTokenSecret"],
        TwitterClientId: vSMMADAGAccountRow["TwitterClientId"],
        TwitterClientSecret: vSMMADAGAccountRow["TwitterClientSecret"],
        TwitterAppName: vSMMADAGAccountRow["TwitterAppName"],
        TwitterAppId: vSMMADAGAccountRow["TwitterAppId"],
        TwitterStatus: vSMMADAGAccountRow["TwitterStatus"],
        CreationName: vSMMADAGAccountRow["CreationName"],
        CreationDateTime: vSMMADAGAccountRow["CreationDateTime"],
        UpdateName: Users,
        UpdateDateTime: vUpdateDateTimeNew,
      };

     // console.log("SMMADNewAccountSetup.SaveRow.AccountItem:  ", AccountItem);

      if (AccountItem["id"] === "new") {


        AccountItem["id"] = AccountItem["Account"];

        const createSMMADAccountsResponse = await client.graphql({
          query: createSMMADAccounts,
          variables: {
            input: AccountItem,
          },
        });

        console.log(
          "SMMADNewAccountSetup.SaveRow.createSMMADAccountsResponse:  ",
          createSMMADAccountsResponse
        );
      } else {
        const UpdateSMMADAccountsResponse = await client.graphql({
          query: updateSMMADAccounts,
          variables: {
            input: AccountItem,
          },
        });

    //    console.log(
    //      "SMMADNewAccountSetup.SaveRow.UpdateSMMADAccountsResponse:  ",
    //      UpdateSMMADAccountsResponse
    //    );
      }
    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADAGAccountRow",
        "SaveAccount",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function TwitterAccount_handleChange(event, data) {
    try {
      vSMMADAGAccountRow.TwitterAccount = event.target.value;

      setvSMMADAGAccountRow(vSMMADAGAccountRow);
    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADAGAccountRow",
        "TwitterAccount_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function RhyteItAccount_handleChange(event, data) {
    try {
      vSMMADAGAccountRow.Account = event.target.value;

      setvSMMADAGAccountRow(vSMMADAGAccountRow);
    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADAGAccountRow",
        "RhyteItAccount_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function TwitterPassword_handleChange(event, data) {
    try {
      vSMMADAGAccountRow.TwitterPassword = event.target.value;
      // //console.log("RhyteItAccountDetail.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)

      setvSMMADAGAccountRow(vSMMADAGAccountRow);
    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADAGAccountRow",
        "TwitterPassword_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function TwitterConsumerKey_handleChange(event, data) {
    try {
      vSMMADAGAccountRow.TwitterConsumerKey = event.target.value;
      // //console.log("RhyteItAccountDetail.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)

      setvSMMADAGAccountRow(vSMMADAGAccountRow);
    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADAGAccountRow",
        "TwitterConsumerKey_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function TwitterConsumerSecret_handleChange(event, data) {
    try {
      vSMMADAGAccountRow.TwitterConsumerSecret = event.target.value;
      // //console.log("RhyteItAccountDetail.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)

      setvSMMADAGAccountRow(vSMMADAGAccountRow);
    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADAGAccountRow",
        "TwitterConsumerSecret_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function TwitterAccessToken_handleChange(event, data) {
    try {
      vSMMADAGAccountRow.TwitterAccessToken = event.target.value;
      // //console.log("RhyteItAccountDetail.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)

      setvSMMADAGAccountRow(vSMMADAGAccountRow);
    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADAGAccountRow",
        "TwitterAccessToken_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function TwitterAccessTokenSecret_handleChange(event, data) {
    try {
      vSMMADAGAccountRow.TwitterAccessTokenSecret = event.target.value;
      // //console.log("RhyteItAccountDetail.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)

      setvSMMADAGAccountRow(vSMMADAGAccountRow);
    } catch (err) {
      RhyteItLogging(
        Users,
        "SMMADAGAccountRow",
        "TwitterAccessTokenSecret_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function TwitterPostingFrequencyHours_handleChange(event, data) {
    try {
      console.log(
        "SMMADNewAccountSetup.TwitterPostingFrequencyHours_handleChange.vSMMADAGAccountRow: ",
        vSMMADAGAccountRow
      );
      console.log(
        "SMMADNewAccountSetup.TwitterPostingFrequencyHours_handleChange.event.target.value: ",
        event.target.value
      );
      vSMMADAGAccountRow.TwitterPostingFrequencyHours = parseInt(
        event.target.value
      );
      setvSMMADAGAccountRow(vSMMADAGAccountRow);
    } catch (err) {
      console.error(
        "SMMADNewAccountSetup.TwitterPostingFrequencyHours_handleChange.ERROR: ",
        err,
        err.stack
      );
    }
  }

  return (
    <TableBody key={vRowRefresh}>
      <TableRow key={vSMMADAGAccountRow.id} className={styles.TableRow}>
        <TableCell
          className={styles.TableCellHeader}
          onClick={(e) => setvRowClicked(!vRowClicked)}
        >
          <Text
            className={
              vRowClicked === false
                ? styles.ExpandIcon
                : styles.ExpandIconHidden
            }
          >
            &#10133;
          </Text>
          <Text
            className={
              vRowClicked === true
                ? styles.CollapseIcon
                : styles.CollapseIconHidden
            }
          >
            &#10134;
          </Text>
        </TableCell>
        <TableCell className={styles.TableCellHeader}>
          <CheckboxField
            className={styles.CheckboxField}
            name="Active"
            value={vMasterAccount}
            checked={vMasterAccount}
            isDisabled
            size="small"
            onChange={(e) => setvMasterAccount(e.target.checked)}
          />
        </TableCell>
        <TableCell className={styles.TableCellHeader}>
          <Text className={styles.Text}>{vSMMADAGAccountRow.Account}</Text>
        </TableCell>
        <TableCell className={styles.TableCellHeader}>
          <Text className={styles.Text}>
            {vSMMADAGAccountRow.TwitterAccount}
          </Text>
        </TableCell>
        <TableCell className={styles.TableCellHeader}>
          <Text className={styles.Text}>
            {vSMMADAGAccountRow.TwitterStatus}
          </Text>
        </TableCell>
        <TableCell className={styles.TableCellHeader}>
          <Text className={styles.Text}>
            {vSMMADAGAccountRow.SMMADAccountStatus}
          </Text>
        </TableCell>
        <TableCell className={styles.TableCellHeader}>
          <TextField
            className={styles.TextFieldInteger}
            name="vSMMADAGAccountRow.TwitterPostingFrequencyHours"
            defaultValue={vSMMADAGAccountRow.TwitterPostingFrequencyHours}
            onChange={(e) =>
              TwitterPostingFrequencyHours_handleChange(e, vSMMADAGAccountRow)
            }
          />
        </TableCell>
        <TableCell className={styles.TableCellHeader}>
          <CheckboxField
            className={styles.CheckboxField}
            name="Active"
            value={vActive}
            checked={vActive}
            size="small"
            onChange={(e) => setvActive(e.target.checked)}
          />
        </TableCell>
        <TableCell className={styles.TableCellHeader}>
          <Button
            className={styles.Button}
            variation="primary"
            onClick={(e) => SaveAccount("Saved")}
          >
            Save
          </Button>
        </TableCell>
      </TableRow>

      {vRowClicked === true && (
        <TableRow key={vSMMADAGAccountRow.id}>
          <TableCell colSpan={9}>
            <TableRow key={vSMMADAGAccountRow.id} className={styles.TableRow}>
              <TableCell className={styles.TextCell} colspan={1}>
                <Text className={styles.Text}>RhyteIt Account</Text>
              </TableCell>
              <TableCell className={styles.TextFieldCell}>
                <TextField
                  className={styles.TextField}
                  name="vSMMADAGAccountRow.Account"
                  defaultValue={vSMMADAGAccountRow.Account}
                  onChange={(e) =>
                    RhyteItAccount_handleChange(e, vSMMADAGAccountRow)
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow key={vSMMADAGAccountRow.id} className={styles.TableRow}>
              <TableCell className={styles.TextCell} colspan={1}>
                <Text className={styles.Text}>X Account</Text>
              </TableCell>
              <TableCell className={styles.TextFieldCell}>
                <TextField
                  className={styles.TextField}
                  name="vSMMADAGAccountRow.TwitterAccount"
                  defaultValue={vSMMADAGAccountRow.TwitterAccount}
                  onChange={(e) =>
                    TwitterAccount_handleChange(e, vSMMADAGAccountRow)
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow key={vSMMADAGAccountRow.id} className={styles.TableRow}>
              <TableCell className={styles.TextCell}>
                <Text className={styles.Text}>X Password</Text>
              </TableCell>
              <TableCell className={styles.TextFieldCell}>
                <TextField
                  className={styles.TextField}
                  name="vSMMADAGAccountRow.TwitterPassword"
                  defaultValue={vSMMADAGAccountRow.TwitterPassword}
                  onChange={(e) =>
                    TwitterPassword_handleChange(e, vSMMADAGAccountRow)
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow key={vSMMADAGAccountRow.id} className={styles.TableRow}>
              <TableCell className={styles.TextCell}>
                <Text className={styles.Text}>Consumer Key</Text>
              </TableCell>
              <TableCell className={styles.TextFieldCell}>
                <TextField
                  className={styles.TextField}
                  name="vSMMADAGAccountRow.TwitterConsumerKey"
                  defaultValue={vSMMADAGAccountRow.TwitterConsumerKey}
                  onChange={(e) =>
                    TwitterConsumerKey_handleChange(e, vSMMADAGAccountRow)
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow key={vSMMADAGAccountRow.id} className={styles.TableRow}>
              <TableCell className={styles.TextCell}>
                <Text className={styles.Text}>Consumer Secret</Text>
              </TableCell>
              <TableCell className={styles.TextFieldCell}>
                <TextField
                  className={styles.TextField}
                  name="vSMMADAGAccountRow.TwitterConsumerSecret"
                  defaultValue={vSMMADAGAccountRow.TwitterConsumerSecret}
                  onChange={(e) =>
                    TwitterConsumerSecret_handleChange(e, vSMMADAGAccountRow)
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow key={vSMMADAGAccountRow.id} className={styles.TableRow}>
              <TableCell className={styles.TextCell}>
                <Text className={styles.Text}>Access Token</Text>
              </TableCell>
              <TableCell className={styles.TextFieldCell}>
                <TextField
                  className={styles.TextField}
                  name="vSMMADAGAccountRow.TwitterAccessToken"
                  defaultValue={vSMMADAGAccountRow.TwitterAccessToken}
                  onChange={(e) =>
                    TwitterAccessToken_handleChange(e, vSMMADAGAccountRow)
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow key={vSMMADAGAccountRow.id} className={styles.TableRow}>
              <TableCell className={styles.TextCell}>
                <Text className={styles.Text}>Access Token Secret</Text>
              </TableCell>
              <TableCell className={styles.TextFieldCell}>
                <TextField
                  className={styles.TextField}
                  name="vSMMADAGAccountRow.TwitterAccessTokenSecret"
                  defaultValue={vSMMADAGAccountRow.TwitterAccessTokenSecret}
                  onChange={(e) =>
                    TwitterAccessTokenSecret_handleChange(e, vSMMADAGAccountRow)
                  }
                />
              </TableCell>
            </TableRow>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
}

export default SMMADAGAccountRow;
